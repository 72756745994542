import { PropsWithChildren, RefObject } from 'react'

export enum ColumnTypes {
  ONE_COLUMN = 'oneColumn',
  TWO_COLUMN = 'twoColumn',
  THREE_COLUMN = 'threeColumn',
  THREE_COLUMN_WIDE_FILTER = 'threeColumnWideFilter'
}

export type ColumnProps = PropsWithChildren<{
  type?: ColumnTypes
  leftGridArea?: string
  className?: string
}>

export type ColumnBaseProps<
  SpecificLayoutProps extends Record<string, any> = Record<string, never>
> = PropsWithChildren<
  {
    pageTitle?: {
      text: string
      titleSurfaceColor?: string
    }
    refContent?: RefObject<HTMLDivElement>
    buttonBack?: {
      to: string | null
    }
  } & Omit<SpecificLayoutProps, 'pageTitle' | 'refContent' | 'buttonBack'>
>

import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { LayoutEventsTable } from 'layouts/events-table'
import { LineContainer } from 'entities/line'
import { getFetchLineReqData } from 'entities/line/model/utils'
import {
  EventsPageHeader,
  EventsPageHeaderTypes
} from 'widgets/events-page-header'

import { LineEvents } from './ui/line'
import { StyledLineEvents } from './page.styled'

export const PageLine = () => (
  <LayoutEventsTable>
    <LineContainer>
      <StyledLineEvents>
        <EventsPageHeader type={EventsPageHeaderTypes.Line} />
        <LineEvents />
      </StyledLineEvents>
    </LineContainer>
  </LayoutEventsTable>
)

export const pageLinePrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLine(
      getFetchLineReqData({ tournamentId: getRouteParamAtIndex(req.params, 2) })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
}

export default PageLine

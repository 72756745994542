import { FC } from 'react'
import { BetStatus } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { BetHistoryTagWrapper } from './bet-history-card-legacy.styled'
import { BetStatusText } from './bet-history-card-legacy.constants'

export const BetHistoryTagLegacy: FC<{
  status: BetStatus
  className?: string
}> = ({ status, className }) => {
  const { t } = useTranslation()

  return (
    <BetHistoryTagWrapper className={className} status={status}>
      {t(BetStatusText[status])}
    </BetHistoryTagWrapper>
  )
}

import { FC, memo } from 'react'

import * as CellStyles from '../cell/cell.styled'

import { CellWithProbabilityProps } from './cell-with-probability.types'

export const CellWithProbability: FC<CellWithProbabilityProps> = memo(
  ({
    isDisabled,
    isInBasket,
    oddMode,
    value,
    probabilityOdd,
    isMerged,
    isLast,
    isFirst,
    isCompact,
    withParam,
    paramCellWidth,
    oddCellWidth,
    prefixValue,
    onClick,
    OddModeStatus
  }) => {
    return (
      <CellStyles.CellWrapper
        isActive={isInBasket}
        isCompact={isCompact}
        isDisabled={isDisabled}
        isMerged={isMerged}
        mode={oddMode}
      >
        {withParam && (
          <CellStyles.BlockParamCell
            cellWidth={paramCellWidth}
            isCompact={isCompact}
            isFirst
          >
            {prefixValue}

            {value}
          </CellStyles.BlockParamCell>
        )}

        <CellStyles.BlockOddCell
          cellWidth={oddCellWidth}
          isCompact={isCompact}
          isFirst={isFirst && !withParam}
          isLast={isLast}
          onClick={onClick}
        >
          {probabilityOdd}

          {OddModeStatus}
        </CellStyles.BlockOddCell>
      </CellStyles.CellWrapper>
    )
  }
)

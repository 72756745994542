import { FC } from 'react'

import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { useEvents } from 'entities/basket'

import { CardDependentList } from '../../card-dependent'
import { Styles } from '../../basket'
import { CardExpress } from '../card'
import { ExpressProps } from '../express/express.types'

export const CardListExpress: FC<ExpressProps> = ({ outcomes }) => {
  const { events, dependentEvents } = useEvents(outcomes)

  return (
    <Styles.StyledBasketBodyList>
      <Styles.StyledBetGroupsList>
        <BasketTooltipProvider>
          <CardDependentList dependentEvents={dependentEvents} />

          <Styles.StyledBetCardList>
            {events.map((outcome) => (
              <CardExpress key={outcome.id} outcome={outcome} />
            ))}
          </Styles.StyledBetCardList>
        </BasketTooltipProvider>
      </Styles.StyledBetGroupsList>
    </Styles.StyledBasketBodyList>
  )
}

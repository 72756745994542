import { FC } from 'react'

import { useEventMode } from 'entities/event-probability/lib/odd-mode'
import { OddModes } from 'entities/event-probability/model/odd-mode'

import { CoefficientCouponProps } from './coefficent-coupon.types'
import * as S from './coefficient-coupon.styled'

// TODO: transfer to entity basket
export const CoefficientCoupon: FC<CoefficientCouponProps> = ({
  eventProbability,
  eventId
}) => {
  const { mode } = useEventMode({ eventProbability, eventId })

  return (
    <S.CoefficientCouponWrapper mode={mode}>
      {mode === OddModes.Increase && <S.IncreaseIcon size={8} />}
      {mode === OddModes.Decrease && <S.DecreaseIcon size={8} />}

      <S.ProbabilityOdd>
        {eventProbability.odd.toFixed(2) || '–'}
      </S.ProbabilityOdd>
    </S.CoefficientCouponWrapper>
  )
}

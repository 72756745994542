import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M15.722 10.3945C17.7877 11.3199 20.4345 12.5056 20.1296 13.7975C20.048 14.1434 19.7467 14.4115 19.2799 14.6155C18.955 14.7575 18.5248 14.8486 18.0184 14.9019V15.7646H16.4263V14.9632C15.1802 14.9518 13.7377 14.8385 12.3307 14.7279H12.3306C11.8304 14.6886 11.3347 14.6497 10.8539 14.6158C9.7604 14.5387 8.79175 14.5254 7.92106 14.5134H7.92103H7.921C7.47483 14.5072 7.05439 14.5014 6.65605 14.4876V15.7646H5.06396V14.3484C4.06604 14.1144 3.78382 12.8754 4.00373 11.0091C4.03811 10.7172 4.17069 10.2759 4.32686 9.75607C4.58062 8.91138 4.89667 7.85935 4.95491 6.90446C4.95491 6.16103 10.5298 5.71255 10.5298 6.21004C10.5298 8.04647 11.7946 8.60718 13.3293 9.28754L13.3294 9.28755C13.671 9.43901 14.026 9.5964 14.3835 9.77511C14.7635 9.96515 15.226 10.1723 15.722 10.3945ZM3 15.7646C2.44772 15.7646 2 16.2124 2 16.7646V17.3201C2 17.4306 2.08954 17.5201 2.2 17.5201H21.8C21.9105 17.5201 22 17.4306 22 17.3201V16.7646C22 16.2124 21.5523 15.7646 21 15.7646H3Z"
      fill={theme.tokens.colors['sport-icon']['sport-shortTrack']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M10.9776 6.90771C12.6301 7.64801 14.7476 8.59659 14.5037 9.63006C14.4384 9.90678 14.1973 10.1213 13.8238 10.2845C13.564 10.3981 13.2198 10.471 12.8147 10.5136V11.2038H11.541V10.5626C10.5442 10.5535 9.39009 10.4629 8.26447 10.3744L8.26445 10.3744L8.26443 10.3744C7.86427 10.343 7.4677 10.3118 7.08307 10.2847C6.20828 10.2231 5.43336 10.2124 4.73681 10.2028H4.73681H4.7368H4.7368H4.73679C4.37983 10.1979 4.04344 10.1932 3.72475 10.1822V11.2038H2.45108V10.0708C1.65278 9.88355 1.42701 8.89237 1.60294 7.39933C1.63044 7.16587 1.73651 6.81282 1.86144 6.39695L1.86144 6.39695C2.06445 5.72119 2.31729 4.87957 2.36389 4.11566C2.36389 3.52091 6.82379 3.16213 6.82379 3.56012C6.82379 5.02926 7.83564 5.47783 9.06342 6.02212L9.06343 6.02213C9.33675 6.1433 9.62078 6.26921 9.90672 6.41218C10.2108 6.56421 10.5808 6.72996 10.9776 6.90771ZM0.999954 11.2038C0.447669 11.2038 -4.57764e-05 11.6515 -4.57764e-05 12.2038V12.4082C-4.57764e-05 12.5186 0.0894971 12.6082 0.199954 12.6082H15.8C15.9104 12.6082 16 12.5186 16 12.4082V12.2038C16 11.6515 15.5522 11.2038 15 11.2038H0.999954Z"
      fill={theme.tokens.colors['sport-icon']['sport-shortTrack']}
      fillRule="evenodd"
    />
  )
}

export const IconSportShortTrack: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

import { RadioProps } from '../Radio/types'

export const StyledRadio = styled.label<RadioProps>`
  height: 16px;
  width: 16px;

  ${({ theme, checked }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-default-opacity']};
    border: 1px solid ${theme.tokens.colors.border['border-primary']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-round'
    ]}px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all 0.3s;

    &:hover {
      background: ${theme.tokens.colors.surface['surface-hover']};
    }

    ${checked &&
    css`
      background: #7845e7;
      border: none;

      &:hover {
        background: #9973ed;
      }
    `}
  `}
`

export const StyledRing = styled.div<RadioProps>`
  height: 6px;
  width: 6px;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.icon['icon-invert']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-round'
    ]}px;
  `}
`

export const StyledRadioInput = styled.input`
  display: none;
`

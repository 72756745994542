import React, { FC, useState } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { Typography } from 'shared/ui/typography'
import { TournamentsList } from 'widgets/tournaments-list/tournaments-list'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

import {
  LineTournamentGroupSportProps,
  LineTournamentsGroupListProps,
  LineTournamentsGroupsListProps
} from './line-tournaments-groups.types'
import {
  StyledLineTournamentGroup,
  StyledLineTournamentGroupSport
} from './line-tournaments-groups.styled'

export const LineTournamentsGroups: FC<LineTournamentsGroupsListProps> = ({
  groupedTournaments,
  showHeader
}) => {
  return (
    <>
      {groupedTournaments.map((group) => (
        <LineTournamentGroup
          group={group}
          key={group.id}
          showHeader={showHeader}
        />
      ))}
    </>
  )
}

const LineTournamentGroup: FC<LineTournamentsGroupListProps> = ({
  group,
  showHeader
}) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <StyledLineTournamentGroup key={group.id}>
      {showHeader && (
        <LineTournamentGroupHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          sport={group}
        />
      )}
      {isOpen && <TournamentsList tournaments={group.tournaments} />}
    </StyledLineTournamentGroup>
  )
}

const LineTournamentGroupHeader: FC<LineTournamentGroupSportProps> = ({
  sport,
  isOpen,
  setIsOpen
}) => {
  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }
  return (
    <StyledLineTournamentGroupSport onClick={handleClick}>
      <SportIcon size="m" sport={sport.code as ESportsCodes} />
      <Typography font="Body / 14 Bold">{sport.name}</Typography>
      <IconChevronDown
        colorToken="icon-secondary-2"
        size={16}
        twist={isOpen ? 0 : 180}
      />
    </StyledLineTournamentGroupSport>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M21.9999 7.5C21.9999 10.4207 19.7233 12.8097 16.8478 12.9892C16.9476 13.4772 17 13.9825 17 14.5C17 18.6421 13.6421 22 9.5 22C5.35786 22 2 18.6421 2 14.5C2 10.3579 5.35786 7 9.5 7C10.0175 7 10.5227 7.05241 11.0107 7.15222C11.1902 4.27668 13.5792 2 16.4999 2C19.5375 2 21.9999 4.46243 21.9999 7.5ZM17.532 10.1518V4.83789H16.3396L14.8542 5.67079V6.66715L16.2283 5.90431H16.2634V10.1518H17.532ZM9.5 20.6C12.8689 20.6 15.6 17.8689 15.6 14.5C15.6 11.1311 12.8689 8.4 9.5 8.4C6.13106 8.4 3.4 11.1311 3.4 14.5C3.4 17.8689 6.13106 20.6 9.5 20.6ZM7.99699 18.1994C8.42345 18.3998 8.909 18.5 9.45364 18.5C10.0394 18.5 10.5532 18.3831 10.9951 18.1493C11.4395 17.913 11.785 17.5893 12.0317 17.1782C12.2809 16.7646 12.4042 16.2919 12.4016 15.7601C12.4042 15.254 12.2976 14.807 12.0818 14.4191C11.866 14.0286 11.5718 13.7216 11.1993 13.4981C10.8268 13.2746 10.4029 13.1628 9.92762 13.1628C9.5808 13.1628 9.26738 13.227 8.98735 13.3555C8.70733 13.4839 8.49795 13.6458 8.35922 13.841H8.31298L8.51722 11.8642H11.9662V10.5H7.1415L6.7677 14.6618L8.25132 14.9277C8.37207 14.7633 8.54034 14.6336 8.75614 14.5385C8.97451 14.4435 9.1993 14.396 9.43051 14.396C9.69513 14.3985 9.93019 14.4589 10.1357 14.5771C10.3412 14.6952 10.5018 14.8597 10.6174 15.0703C10.7356 15.281 10.7947 15.525 10.7947 15.8025C10.7947 16.0748 10.7369 16.3163 10.6213 16.527C10.5082 16.7351 10.3502 16.8982 10.1473 17.0164C9.94689 17.132 9.71568 17.1898 9.45364 17.1898C9.1248 17.1898 8.84092 17.0947 8.602 16.9046C8.36308 16.7145 8.23462 16.4666 8.21664 16.1609H6.59814C6.60842 16.6156 6.73816 17.0189 6.98735 17.3709C7.23655 17.7229 7.5731 17.999 7.99699 18.1994Z"
      fill={theme.tokens.colors['sport-icon']['sport-bingo']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M15.9999 4.4C15.9999 6.73659 14.1786 8.64778 11.8782 8.79134C11.9581 9.18176 12 9.58598 12 10C12 13.3137 9.31371 16 6 16C2.68629 16 0 13.3137 0 10C0 6.68629 2.68629 4 6 4C6.414 4 6.8182 4.04193 7.20859 4.12177C7.35214 1.82135 9.26334 0 11.5999 0C14.03 0 15.9999 1.96995 15.9999 4.4ZM12.4256 6.52145V2.27031H11.4717L10.2834 2.93663V3.73372L11.3826 3.12345H11.4107V6.52145H12.4256ZM6 14.6C8.54051 14.6 10.6 12.5405 10.6 10C10.6 7.45949 8.54051 5.4 6 5.4C3.45949 5.4 1.4 7.45949 1.4 10C1.4 12.5405 3.45949 14.6 6 14.6ZM4.79759 12.9595C5.13876 13.1198 5.5272 13.2 5.96291 13.2C6.4315 13.2 6.84255 13.1065 7.19605 12.9195C7.5516 12.7304 7.82803 12.4714 8.02534 12.1426C8.22469 11.8117 8.32335 11.4335 8.32129 11.0081C8.32335 10.6032 8.23805 10.2456 8.06541 9.93526C7.89277 9.62286 7.65745 9.37726 7.35944 9.19846C7.06143 9.01965 6.72232 8.93025 6.3421 8.93025C6.06464 8.93025 5.8139 8.98163 5.58988 9.08439C5.36586 9.18715 5.19836 9.31664 5.08738 9.47283H5.05038L5.21378 7.89133H7.97293V6.8H4.1132L3.81416 10.1295L5.00106 10.3422C5.09765 10.2107 5.23227 10.1069 5.40491 10.0308C5.57961 9.95479 5.75944 9.91676 5.94441 9.91676C6.1561 9.91882 6.34415 9.96712 6.50857 10.0617C6.67299 10.1562 6.80144 10.2877 6.89393 10.4563C6.98847 10.6248 7.03574 10.82 7.03574 11.042C7.03574 11.2599 6.9895 11.4531 6.89701 11.6216C6.80658 11.7881 6.68019 11.9186 6.51782 12.0131C6.35751 12.1056 6.17254 12.1518 5.96291 12.1518C5.69984 12.1518 5.47274 12.0758 5.2816 11.9237C5.09046 11.7716 4.9877 11.5733 4.97331 11.3287H3.67852C3.68674 11.6925 3.79053 12.0152 3.98988 12.2967C4.18924 12.5783 4.45848 12.7992 4.79759 12.9595Z"
      fill={theme.tokens.colors['sport-icon']['sport-bingo']}
      fillRule="evenodd"
    />
  )
}

export const IconSportBingo: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { useCallback } from 'react'
import { selectBroadcastAvailabilityByEventId } from 'astra-core/containers/BroadcastProvider'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { IconBroadcast } from 'shared/ui/Icon/General/IconBroadcast'
import { Typography } from 'shared/ui/typography'
import { IconTime } from 'shared/ui/Icon/General/icon-time'
import { FavoriteButton } from 'features/favorite-button'
import { selectIsActiveBroadcastsEventId } from 'entities/match-broadcast/model'
import { RootState } from 'shared/types/store'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { useTimerCountdown } from 'shared/lib/events/timer-countdown'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './stats-cell.styled'

export const LiveStatsCell = ({ event }) => {
  const dispatch = useAppDispatch()
  const isHasBroadcast = useAppSelector((state) =>
    selectBroadcastAvailabilityByEventId(state, event.id)
  )
  const isActiveBroadcastsEventId = useAppSelector((state: RootState) =>
    selectIsActiveBroadcastsEventId(state, +event.id)
  )

  const isFeatureFlagEnabled = useFeatureFlag(EFeatureFlags.BROADCAST_ENABLED)
  const isBroadcastEnabled =
    event.live && isHasBroadcast && isFeatureFlagEnabled

  const handleClickBroadcast = useCallback(
    (e) => {
      e.stopPropagation()

      if (isActiveBroadcastsEventId) {
        dispatch(
          matchBroadcastModel.liveMatchBroadcastActions.removeBroadcast({
            eventId: event.id
          })
        )
      } else {
        dispatch(
          matchBroadcastModel.liveMatchBroadcastActions.addBroadcast({
            eventId: event.id
          })
        )
      }
    },
    [dispatch, event.id, isActiveBroadcastsEventId]
  )

  return (
    <S.LiveContentStatsCell>
      <S.AdditionalBlockWrapper>
        <TimeStats event={event} />

        <S.IconsWrapper>
          {isBroadcastEnabled && (
            <S.IconVideoFilledWrapper isActive={isActiveBroadcastsEventId}>
              <IconBroadcast
                colorToken="icon-secondary-3"
                size={12}
                onClick={handleClickBroadcast}
              />
            </S.IconVideoFilledWrapper>
          )}

          <FavoriteButton eventId={event.id} />
        </S.IconsWrapper>
      </S.AdditionalBlockWrapper>
    </S.LiveContentStatsCell>
  )
}

const TimeStats = ({ event }) => {
  const matchPhaseStat = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.MatchPhase
  )

  const matchPhase = useAppSelector((state) =>
    selectMatchPhase(state, matchPhaseStat ? +matchPhaseStat!.value! : 0)
  )

  const { hasTime, value } = useTimerCountdown({ event })
  const showTimeBlock = hasTime || !!matchPhase

  return showTimeBlock ? (
    <S.TimeStatsWrapper>
      {hasTime && (
        <S.TimeWrapper>
          <IconTime size={12} />

          <Typography color="text-primary-2" font="Hint / 10 Bold">
            {value}
          </Typography>
        </S.TimeWrapper>
      )}

      {matchPhase && (
        <Typography color="text-secondary-3" font="Hint / 10 SemiBold">
          {matchPhase.name}
        </Typography>
      )}
    </S.TimeStatsWrapper>
  ) : null
}

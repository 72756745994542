import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconTrashFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.84 2.58L12.37 2.11C12.27 2.01 12.12 1.95 11.98 1.95H10.97V1H4.97V1.95H4.02C3.87 1.95 3.73 2.01 3.63 2.11L3.16 2.58C3.06 2.68 3 2.83 3 2.97V4H13V2.97C13 2.83 12.94 2.68 12.84 2.58Z"
        fill="currentColor"
      />
      <path
        d="M4.005 5C4.005 5 4.005 5.02004 4.005 5.03007V12.1459C4.005 12.4165 4.115 12.667 4.305 12.8575L5.165 13.7094C5.355 13.8998 5.615 14 5.885 14H10.115C10.385 14 10.645 13.8998 10.835 13.7094L11.695 12.8575C11.885 12.667 11.995 12.4065 11.995 12.1459V5.03007C11.995 5.03007 11.995 5.01002 11.995 5H4.005Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

import { getEventHomeAwayCompetitors } from 'astra-core'
import { FC } from 'react'
import { Event } from 'betweb-openapi-axios'

import { EventRowCompetitorsInfo } from 'entities/event-row'
import { SearchHighlighter } from 'features/global-search' // TODO: wrong FSD rule
import { getCompetitorsNames } from 'shared/lib/events'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { useOpenEventPage } from 'entities/event'

export const CompetitorsInfo: FC<{
  event: Event
  isTitleTeamWithSearchHighlight: boolean
}> = ({ event, isTitleTeamWithSearchHighlight }) => {
  const { homeCompetitors, awayCompetitors } =
    getEventHomeAwayCompetitors(event)

  const link = useOpenEventPage({ event })

  const currentServer = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.CurrentServer
  )

  const [homeCompetitorRef, isHomeCompetitorOverflowing] =
    useTextOverflow<HTMLSpanElement>()
  const [awayCompetitorRef, isAwayCompetitorOverflowing] =
    useTextOverflow<HTMLSpanElement>()

  return (
    <EventRowCompetitorsInfo
      teams={[
        {
          name: getCompetitorsNames(homeCompetitors),
          isCommonTooltip: !isHomeCompetitorOverflowing,
          titleNameRef: homeCompetitorRef,
          isDisplayCurrentServerPoint: currentServer?.value === '1',
          CustomTitleName: isTitleTeamWithSearchHighlight && (
            <SearchHighlighter
              font="Body / 12 SemiBold"
              ref={homeCompetitorRef}
              text={getCompetitorsNames(homeCompetitors)}
            />
          )
        },
        {
          name: getCompetitorsNames(awayCompetitors),
          isCommonTooltip: !isAwayCompetitorOverflowing,
          titleNameRef: awayCompetitorRef,
          isDisplayCurrentServerPoint: currentServer?.value === '2',
          CustomTitleName: isTitleTeamWithSearchHighlight && (
            <SearchHighlighter
              font="Body / 12 SemiBold"
              ref={awayCompetitorRef}
              text={getCompetitorsNames(awayCompetitors)}
            />
          )
        }
      ]}
      linkToEventPage={link} // Replace to onClickTitleTeam
    />
  )
}

import styled from 'styled-components'

import {
  oneColumnLayout,
  threeColumnLayout,
  threeColumnWideFilterContainerLayout,
  twoColumnLayout
} from './column.mixin.styled'

export const ThreeColumnLayout = styled.div<{
  hideHeader: boolean
  leftGridArea: string
}>`
  ${(p) => threeColumnLayout(p.leftGridArea, p.hideHeader)}
  position: relative;
`

export const ThreeColumnWideFilterLayout = styled.div<{
  hideHeader: boolean
  leftGridArea: string
}>`
  ${(p) => threeColumnWideFilterContainerLayout(p.leftGridArea, p.hideHeader)}
  position: relative;
`

export const TwoColumnLayout = styled.div<{
  hideHeader: boolean
  leftGridArea: string
}>`
  ${(p) => twoColumnLayout(p.leftGridArea, p.hideHeader)}
  position: relative;
`

export const OneColumnLayout = styled.div<{
  hideHeader: boolean
}>`
  ${(p) => oneColumnLayout(p.hideHeader)}
  position: relative;
`

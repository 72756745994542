import React, { FC } from 'react'

import { RadioProps } from './types'
import {
  StyledRadioWrapper,
  StyledRadioLabel,
  StyledRadio,
  StyledRadioInput,
  StyledRing
} from './styled'

// Revision is required
export const Radio: FC<RadioProps> = ({
  checked = false,
  icon: Icon = null,
  label,
  ...props
}) => {
  return (
    <StyledRadioWrapper checked={checked}>
      {Icon && <Icon />}

      <StyledRadio checked={checked}>
        <StyledRing checked={checked} />
        <StyledRadioInput checked={checked} type="radio" {...props} />
      </StyledRadio>

      {!!label && (
        <StyledRadioLabel checked={checked}>{label}</StyledRadioLabel>
      )}
    </StyledRadioWrapper>
  )
}

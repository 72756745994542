import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { LayoutEventsTable } from 'layouts/events-table'
import { LineTournamentsContainer } from 'entities/line-tournaments'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { getFetchLineTournamentsReqData } from 'entities/line-tournaments/model/utils'

import { LineTournaments } from './ui'

export const PageLineTournament = () => (
  <LayoutEventsTable>
    <LineTournamentsContainer>
      <LineTournaments />
    </LineTournamentsContainer>
  </LayoutEventsTable>
)

export const pageLineTournamentsPrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLineTournaments(
      getFetchLineTournamentsReqData({
        sportId: getRouteParamAtIndex(req.params, 1)
      })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
}

export default PageLineTournament

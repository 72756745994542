import {
  selectBasketBetTypeTab,
  selectBasketMakeBetStatus,
  selectBasketOutcomes
} from 'astra-core/containers/BasketProvider'
import isEqual from 'react-fast-compare'

import { CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT } from 'entities/basket/lib'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  BET_STATUS_MAP,
  BET_TYPE_CONTENT_MAP
} from '../../model/basket-content-map'
import { useCollapseBasket } from '../../lib'

import { ContentInstance } from './content'

export const Basket = () => {
  const currentBetStatus = useAppSelector(selectBasketMakeBetStatus)
  const outcomes = useAppSelector(selectBasketOutcomes, isEqual)
  const currentBetTypeTab = useAppSelector(selectBasketBetTypeTab)

  useCollapseBasket({
    currentBetStatus,
    delayToClose: CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT
  })

  return (
    BET_STATUS_MAP[currentBetStatus] || (
      <ContentInstance>
        {BET_TYPE_CONTENT_MAP[currentBetTypeTab](outcomes)}
      </ContentInstance>
    )
  )
}

import styled, { css } from 'styled-components'

import { Typography } from 'shared/ui/typography'

export const ContentTitleWrapper = styled.div<{ background?: string }>`
  ${({ theme, background }) => css`
    ${background &&
    css`
      background: ${theme.tokens.colors.surface[background]};
    `}

    padding: ${theme.tokens.alias.space['space-xl-24']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-s-12']}px;
    position: sticky;
    top: 48px;
    z-index: 20;
  `}
`

export const ContentTitle = styled(Typography)`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'
import { accountProviderActions } from 'astra-core/containers/AccountProvider/slice'
import { basketProviderActions } from 'astra-core/containers/BasketProvider/slice'
import {
  EFeatureFlags,
  isFeatureFlagEnabled
} from 'astra-core/containers/ConfigProvider'
import { type AxiosError, type AxiosInstance } from 'axios'
import { type Account } from 'betweb-openapi-axios'
import { type Store } from '@reduxjs/toolkit'

import { type RootState } from 'shared/types/store/root-state'
import {
  isOnVirtualGamePage,
  isResponseFromVirtualGames
} from 'shared/lib/virtual-games'
import {
  removeLocalStorageItem,
  STORAGE_KEYS_TO_REMOVE_AFTER_LOGOUT
} from 'shared/lib/storage'

export const addLogoutInterceptor = <T extends Store<RootState>>(
  axiosInstance: AxiosInstance,
  store: T,
  virtualGamesRoutes: Set<string>
): void => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      try {
        const is403 = error?.response?.status === 403
        const flagEnabled = isFeatureFlagEnabled(
          EFeatureFlags.LOGOUT_AFTER_403_ENABLED
        )
        const state: RootState = store.getState()
        const currentUser: Account | undefined = selectAccount(state)

        if (is403 && flagEnabled && currentUser !== undefined) {
          const { pathname } = window.location

          const userOnVirtualGamesPage = isOnVirtualGamePage(
            pathname,
            virtualGamesRoutes
          )
          const responseFromVirtualGames = isResponseFromVirtualGames(
            error.config.url
          )

          if (!responseFromVirtualGames && !userOnVirtualGamesPage) {
            store.dispatch(basketProviderActions.resetState())
            store.dispatch(accountProviderActions.resetUser())
            store.dispatch(accountProviderActions.getUser({}))
            STORAGE_KEYS_TO_REMOVE_AFTER_LOGOUT.map(removeLocalStorageItem)
          }
        }
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.error(`Cannot handle logout: ${e}`)
      }
      return await Promise.reject(error)
    }
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { ERoutes } from 'shared/types/routes'
import { Button } from 'shared/ui/Button'
import { IconBetsHistory } from 'shared/ui/Icon/General/IconBetsHistory'
import { useHandleRedirectList } from 'widgets/header/components/PersonalData/components/ComponentOfAuthorized/hooks'

export const HistoryBetsButton = () => {
  const [t] = useTranslation()
  const handleRedirectList = useHandleRedirectList()

  return (
    <Button
      icon={IconBetsHistory}
      view="ghost"
      onClick={handleRedirectList(ERoutes.BetsHistory)}
    >
      {t('history bets')}
    </Button>
  )
}

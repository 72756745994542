import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconFavoritesFilled16: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.28375 5.80088C1.39191 5.45292 1.70214 5.21733 2.05217 5.21733H5.11945C5.46949 5.21733 5.77971 4.98175 5.88788 4.63379L6.83607 1.58355C6.94424 1.23559 7.25446 1 7.6045 1H8.39387C8.7439 1 9.05413 1.23559 9.1623 1.58355L10.1105 4.63379C10.2187 4.98175 10.5289 5.21733 10.8789 5.21733H13.9482C14.2982 5.21733 14.6085 5.45292 14.7166 5.80088L14.9604 6.58503C15.0686 6.933 14.9501 7.31418 14.6669 7.52923L12.1838 9.41493C11.9006 9.62998 11.7821 10.0112 11.8903 10.3591L12.8385 13.4097C12.9467 13.7576 12.8282 14.1388 12.545 14.3539L11.9066 14.8387C11.6234 15.0538 11.2399 15.0538 10.9567 14.8387L8.4741 12.9534C8.19091 12.7383 7.80745 12.7383 7.52427 12.9534L5.04264 14.8379C4.75946 15.053 4.376 15.053 4.09282 14.8379L3.45372 14.3526C3.17054 14.1376 3.05204 13.7564 3.16021 13.4084L4.10811 10.3591C4.21627 10.0112 4.09778 9.62998 3.81459 9.41493L1.33311 7.53047C1.04993 7.31542 0.931433 6.93423 1.0396 6.58627L1.28375 5.80088Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

import { useMemo, useState } from 'react'
import { selectLoyaltyCouponCreatedStatus } from 'astra-core/containers/BasketProvider'

import {
  BasketBonusCouponCanNotUseList,
  useGetSuitableCouponsForBetTypeTab
} from 'entities/basket-bonus-coupon'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { BonusCouponCanNotUse } from './bonus-coupon-can-not-use'

// List of bonus coupons which exist but can't use
export const BonusCouponCanNotUseList = () => {
  const availableCoupons = useAppSelector(selectLoyaltyCouponCreatedStatus) // all coupons
  const suitableCoupons = useGetSuitableCouponsForBetTypeTab() // list of coupons which user can apply
  const [isExpanded, setIsExpanded] = useState(false)

  const disabledCoupons = useMemo(() => {
    const suitableIds = suitableCoupons.map((item) => item.id)

    return availableCoupons.filter((item) => !suitableIds.includes(item.id))
  }, [availableCoupons, suitableCoupons])

  return disabledCoupons.length ? (
    <BasketBonusCouponCanNotUseList
      CouponList={disabledCoupons.map((coupon) => (
        <BonusCouponCanNotUse {...coupon} key={coupon.id} />
      ))}
      FirstCoupon={<BonusCouponCanNotUse {...disabledCoupons[0]} />}
      isExpanded={isExpanded}
      onExpand={(newIsExpanded) => setIsExpanded(newIsExpanded)}
    />
  ) : null
}

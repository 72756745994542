import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const LiveContentScoreCell = styled.td`
  padding-right: 8px;
  white-space: nowrap;
`

export const ScoresStatistics = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.tokens.alias.space['space-xxxxs-2']}px;
  justify-content: flex-end;
`

export const EventScore = styled.div`
  ${typography['Body / 12 Bold']};
  display: flex;
  flex-direction: column;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-primary']};
    padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const EventScoreByPeriods = styled.div`
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const EventScoreByPeriodsColumn = styled.div`
  ${typography['Body / 12 Regular']};
  display: flex;
  flex-direction: column;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
    padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

import { FC } from 'react'

import { FooterExpress } from '../footer'
import { CardListExpress } from '../card-list'

import { ExpressProps } from './express.types'

export const Express: FC<ExpressProps> = ({ outcomes }) => (
  <>
    <CardListExpress outcomes={outcomes} />

    <FooterExpress />
  </>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M3.0571 1H13.6013C14.3001 1.00989 14.9687 1.27685 15.465 1.74157L22.0893 8.20809C22.6767 8.78157 23.0009 9.55281 23.0009 10.3537V20.0634L19.6583 16.7708V8.51461L16.3158 5.15281L11.9806 9.5627L3.0571 1Z"
        fill="url(#paint0_linear_2220_7191)"
      />
      <path
        d="M21.3599 23H10.3903C9.69143 22.9901 9.02293 22.7133 8.52661 22.2288L1.91245 15.604C1.32498 15.0108 1.00085 14.2099 1.00085 13.3892V3.43236L4.51557 6.84359V15.3173L7.8581 18.7977V10.1164L12.0413 14.111L16.2955 10.1164V18.0067L21.3599 23Z"
        fill="url(#paint1_linear_2220_7191)"
      />
      <path
        d="M17.7539 1L22.9905 6.11191V1H17.7539Z"
        fill="url(#paint2_linear_2220_7191)"
      />
      <path
        d="M6.23748 23L1.00085 17.898V23H6.23748Z"
        fill="url(#paint3_linear_2220_7191)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2220_7191"
          x1="12.0009"
          x2="12.0009"
          y1="1"
          y2="23"
        >
          <stop stopColor="#DBB679" />
          <stop offset="1" stopColor="#7D552C" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2220_7191"
          x1="12.0009"
          x2="12.0009"
          y1="1"
          y2="23"
        >
          <stop stopColor="#DBB679" />
          <stop offset="1" stopColor="#7D552C" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_2220_7191"
          x1="12.0009"
          x2="12.0009"
          y1="1"
          y2="23"
        >
          <stop stopColor="#DBB679" />
          <stop offset="1" stopColor="#7D552C" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2220_7191"
          x1="12.0009"
          x2="12.0009"
          y1="1"
          y2="23"
        >
          <stop stopColor="#DBB679" />
          <stop offset="1" stopColor="#7D552C" />
        </linearGradient>
      </defs>
    </>
  ),
  s: () => (
    <>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.03965 0.666626H9.06909C9.53502 0.673218 9.98069 0.851195 10.3116 1.16101L14.7278 5.47202C15.1194 5.85434 15.3355 6.3685 15.3355 6.90243V13.3755L13.1071 11.1805V5.67636L10.8788 3.43516L7.98868 6.37509L2.03965 0.666626Z"
          fill="url(#paint0_linear_2220_7190)"
        />
        <path
          d="M14.2415 15.3333H6.92847C6.46254 15.3267 6.01687 15.1421 5.68599 14.8191L1.27656 10.4027C0.884906 10.0071 0.668823 9.47322 0.668823 8.9261V2.2882L3.01197 4.56235V10.2115L5.24032 12.5318V6.74423L8.02914 9.4073L10.8652 6.74423V12.0044L14.2415 15.3333Z"
          fill="url(#paint1_linear_2220_7190)"
        />
        <path
          d="M11.8375 0.666626L15.3286 4.07457V0.666626H11.8375Z"
          fill="url(#paint2_linear_2220_7190)"
        />
        <path
          d="M4.15991 15.3333L0.668823 11.9319V15.3333H4.15991Z"
          fill="url(#paint3_linear_2220_7190)"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_2220_7190"
            x1="8.00216"
            x2="8.00216"
            y1="0.666626"
            y2="15.3333"
          >
            <stop stopColor="#DBB679" />
            <stop offset="1" stopColor="#7D552C" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_2220_7190"
            x1="8.00216"
            x2="8.00216"
            y1="0.666626"
            y2="15.3333"
          >
            <stop stopColor="#DBB679" />
            <stop offset="1" stopColor="#7D552C" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint2_linear_2220_7190"
            x1="8.00216"
            x2="8.00216"
            y1="0.666626"
            y2="15.3333"
          >
            <stop stopColor="#DBB679" />
            <stop offset="1" stopColor="#7D552C" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint3_linear_2220_7190"
            x1="8.00216"
            x2="8.00216"
            y1="0.666626"
            y2="15.3333"
          >
            <stop stopColor="#DBB679" />
            <stop offset="1" stopColor="#7D552C" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export const IconCybersportMobileLegends: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useEventsPoll } from 'entities/event/api'
import { useFetchBroadcastsAvailability } from 'hooks'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { selectFilterIsTop, selectLayoutIsLoading } from './selectors'
import { getFetchLiveReqData, LIVE_POLLING_INTERVAL } from './utils'
import { LiveEventsParams } from './types'
import { liveContainerActions } from './slice'

export const useLiveContainerData = () => {
  const { sportId } = useParams<LiveEventsParams>()
  const dispatch = useAppDispatch()
  const top = useAppSelector(selectFilterIsTop)
  const isLoading = useAppSelector(selectLayoutIsLoading)

  const fetchLive = useCallback(
    ({ sportId, tagIds }) => {
      if (sportId) {
        dispatch(
          liveContainerActions.fetchLive(
            getFetchLiveReqData({
              sportId,
              tagIds
            })
          )
        )
      }
    },
    [dispatch]
  )

  useFetchBroadcastsAvailability()

  useEffect(() => {
    return () => {
      dispatch(liveContainerActions.resetFilterIsBroadcast())
    }
  }, [dispatch])

  useEventsPoll({
    sportId: +sportId,
    top,
    isLoading,
    fetch: fetchLive,
    pollingInterval: LIVE_POLLING_INTERVAL
  })
}

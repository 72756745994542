import styled from 'styled-components'

export const StyledBetCardElementWrapper = styled.div`
  position: relative;
`

export const StyledInputWrapper = styled.div`
  padding: 4px 16px;
  position: relative;
`

import React, { FC, memo, PropsWithChildren } from 'react'
import omit from 'lodash/omit'
import isEqual from 'react-fast-compare'

import { IconProps } from './types'
import { StyledIcon } from './styled'

export const Icon: FC<PropsWithChildren<IconProps>> = memo(
  ({ children, size = 16, testId, onClick, ...props }) => {
    return (
      <StyledIcon id={testId} size={size} onClick={onClick} {...props}>
        {children}
      </StyledIcon>
    )
  },
  (prevProps, nextProps) =>
    isEqual(omit(prevProps, 'children'), omit(nextProps, 'children'))
)

import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useTranslation } from 'react-i18next'
import isEqual from 'react-fast-compare'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { RootState } from 'shared/types/store'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { liveContainerActions } from 'entities/live/model/slice'
import { SHORTNAMED_SPORT_CODES } from 'shared/lib/locales/sports'
import { Skeleton } from 'shared/ui/skeleton'
import { Filters } from 'entities/line-tournaments/model/types'
import { selectSportsCountersListCurrent } from 'entities/sport-counters'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { SportMenuListLoader } from './sport-menu-list.loader'
import {
  ISportsMenuListProps,
  SportsMenuItemProps
} from './sports-menu-list.types'
import {
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './sports-menu-list.styled'
import { getMenuTabSportsCounterType, isActiveSport } from './utils'

export const SportsMenuList: FC<ISportsMenuListProps> = ({
  currentTabMenu
}) => {
  const [hasDataLoadedOnce, setHasDataLoadedOnce] = useState(false)
  const { pathname } = useLocation()
  const sportsCountersListByTop = useAppSelector(
    (state: RootState) =>
      selectSportsCountersListCurrent(
        state,
        getMenuTabSportsCounterType(currentTabMenu)
      ),
    isEqual
  )

  useEffect(() => {
    if (sportsCountersListByTop.length > 0) {
      setHasDataLoadedOnce(true)
    }
  }, [sportsCountersListByTop])

  const isLoading = !hasDataLoadedOnce && !sportsCountersListByTop.length

  return (
    <StyledSportsMenuList>
      <Skeleton isLoading={isLoading} styledFallback={<SportMenuListLoader />}>
        {sportsCountersListByTop.map((sport) => (
          <SportsMenuItem
            currentTabMenu={currentTabMenu}
            isActive={isActiveSport(pathname, currentTabMenu, sport.id)}
            key={sport.id}
            sportCode={sport.code}
            sportCount={sport.count}
            sportId={sport.id}
            sportName={sport.name}
          />
        ))}
      </Skeleton>
    </StyledSportsMenuList>
  )
}

const SportsMenuItem: FC<SportsMenuItemProps> = memo(
  ({ sportCode, sportCount, sportId, sportName, currentTabMenu, isActive }) => {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const handleClick = useCallback(() => {
      dispatch(
        lineTournamentsContainerActions.resetFilters({
          excludedFilters: [Filters.IS_TOP, Filters.PERIOD]
        })
      )
      dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: false }))
      dispatch(liveContainerActions.resetFilterIsBroadcast())
    }, [dispatch])

    const name =
      SHORTNAMED_SPORT_CODES[sportCode] &&
      i18n.exists(SHORTNAMED_SPORT_CODES[sportCode])
        ? t(SHORTNAMED_SPORT_CODES[sportCode])
        : sportName

    return (
      <StyledSportsMenuItem
        $isActive={isActive}
        sport={sportCode as ESportsCodes}
        to={`/${currentTabMenu}/${sportId}`}
        onClick={handleClick}
      >
        <SportIcon sport={sportCode as ESportsCodes} />
        <StyledSportsMenuItemText>{name}</StyledSportsMenuItemText>
        <StyledSportsMenuItemCount>{sportCount}</StyledSportsMenuItemCount>
      </StyledSportsMenuItem>
    )
  }
)

import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { StyledIcon } from 'shared/ui/Icon'

import { StyledTabsItemProps, TabsProps } from './TabsDeprecated.types'

export const StyledTabs = styled.div<TabsProps>`
  display: flex;

  ${(props) => `
    ${
      props.variant === 1
        ? `
        position: relative;
        &:before {
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid ${props.theme.colors.default.primary[10]};
          position: absolute;
          border-radius: 1px;
        }
    `
        : ''
    }
    ${
      props.variant === 2
        ? `
        ${
          props.combined
            ? `
          position: relative;
          border-radius: 4px;        
        `
            : ''
        }
    `
        : ''
    }
    ${
      props.variant === 4
        ? `
        position: relative;
        border: 1px solid ${props.theme.colors.custom.primary[4]};
        border-radius: 6px;
    `
        : ''
    }
    ${
      props.variant === 5
        ? `
        position: relative;
        &:before {
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid ${props.theme.colors.custom.primary[8]};
          position: absolute;
          border-radius: 1px;
        }
    `
        : ''
    }
  
  `}
`
export const StyledTabsItem = styled.div<StyledTabsItemProps>`
  ${(props) => css`
    align-items: center;
    cursor: pointer;

    display: flex;
    justify-content: center;
    position: relative;

    ${props.variant === 1 &&
    css`
      color: ${props.theme.colors.default.primary[50]};
      font-size: 15px;
      font-weight: ${props.theme.fonts.weight.normal};
      line-height: 20px;
      padding: 5px 16px 7px;

      &:after {
        border-bottom: 2px solid ${props.theme.colors.default.primary[10]};
        border-radius: 1px;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
      }

      &:hover {
        color: ${props.theme.colors.default.primary[90]};
      }

      ${props.active &&
      css`
        color: ${props.theme.colors.default.primary[90]};
        &:after {
          border-bottom-color: ${props.theme.colors.default.primary[90]};
          color: ${props.theme.colors.default.primary[90]};
        }
      `}
    `}

    ${props.variant === 2 &&
    css`
      border-radius: 4px;
      color: ${props.theme.colors.default.primary[50]};
      font-size: ${props.theme.fonts.size.s};
      font-weight: ${props.theme.fonts.weight.normal};
      line-height: 16px;
      padding: 4px 8px;

      ${props.active
        ? css`
            background-color: ${props.theme.colors.custom.primary[1]};
            border: 1px solid ${props.theme.colors.custom.primary[1]};
            color: ${props.theme.colors.fixed.primary[0]};
          `
        : css`
            &:hover {
              color: ${props.theme.colors.default.primary[90]};
            }
          `}

      ${!props.combined &&
      css`
        border: 1px solid ${props.theme.colors.custom.primary[4]};
        margin-right: 4px;
      `}
    `}
    
    ${props.variant === 3 &&
    css`
      color: ${props.theme.colors.default.primary[50]};
      font-size: ${props.theme.fonts.size.s};
      font-weight: ${props.theme.fonts.weight.normal};
      line-height: 16px;
      padding: 4px 8px;

      &:hover {
        color: ${props.theme.colors.default.primary[90]};
      }

      ${props.active &&
      css`
        &,
        &:hover {
          color: ${props.theme.colors.default.primary[0]};
        }

        &:before {
          background-color: ${props.theme.colors.default.primary[90]};
          border-radius: 4px;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
      `}
    `}
    
    ${props.variant === 4 &&
    css`
      color: ${props.theme.colors.default.primary[90]};
      font-size: ${props.theme.fonts.size.s};
      font-weight: ${props.theme.fonts.weight.semiBold};
      line-height: 20px;
      padding: 5px;
      text-align: center;
      text-transform: uppercase;
      width: 50%;

      ${StyledIcon} {
        margin-right: 4px;
      }

      &:hover {
        background-color: ${props.theme.colors.default.primary[5]};
      }

      ${props.type === 'line' &&
      css`
        ${StyledIcon} {
          color: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
            ? props.theme.colors.fixed.green[4]
            : props.active
            ? props.theme.colors.default.primary[0]
            : props.theme.colors.fixed.green[4]} !important;
        }
      `}

      ${props.type === 'live' &&
      css`
        ${StyledIcon} {
          color: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
            ? props.theme.colors.fixed.red[0]
            : props.active
            ? props.theme.colors.default.primary[0]
            : props.theme.colors.fixed.red[0]} !important;
        }
      `}
        
        ${props.active &&
      css`
        &,
        &:hover {
          border-radius: 4px;
          color: ${props.theme.colors.fixed.primary[0]};

          ${props.type === 'line' &&
          css`
            background-color: ${props.theme.colors.custom.green[0]};

            ${StyledIcon} {
              color: ${props.theme.colors.default.primary[0]};
            }
          `}

          ${props.type === 'live' &&
          css`
            background-color: ${props.theme.colors.custom.red[0]};

            ${StyledIcon} {
              color: ${props.theme.colors.default.primary[0]};
            }
          `}
        }
      `}
    `}
    
    ${props.variant === 5 &&
    css`
      color: ${props.active
        ? props.theme.colors.default.primary[90]
        : props.theme.colors.custom.primary[17]};
      font-size: ${(props) => props.theme.fonts.size.m};
      font-weight: ${props.theme.fonts.weight.normal};
      line-height: 20px;
      margin-right: 5px;
      padding: 11px 5px 15px 5px;

      &:after {
        border-bottom: 2px solid
          ${props.active
            ? props.theme.colors.default.primary[90]
            : 'transparent'};
        border-radius: 1px;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
      }

      &:hover {
        color: ${props.theme.colors.default.primary[90]};
      }
    `}
  `}
`

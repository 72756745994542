import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatAmountLocaleString, useGetCurrencyIcon } from 'astra-core'
import {
  betsHistoryProviderActions,
  selectCashoutByBetId,
  selectCashoutError,
  selectCurrentCashout,
  selectIsMakeCashoutLoading
} from 'astra-core/containers/BetsHistoryProvider'

import { RootState } from 'shared/types/store'
import { setItemLocalStorage } from 'shared/lib/@system'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { DISABLE_HOVER_ON_CHILD } from '../../../constants'

import { StyledCurrentCashoutWrapper } from './CurrentCashoutModal.styled'
import { getCurrentCashoutModalContent, NEW_MAKE_CASHOUT_LS } from './constants'

export const CurrentCashoutModal = ({ number }) => {
  const [t] = useTranslation()
  const currentCashout = useAppSelector(selectCurrentCashout)!
  const cashout = useAppSelector((state: RootState) =>
    selectCashoutByBetId(state, currentCashout.betId)
  )
  const cashoutError = useAppSelector(selectCashoutError)
  const isMakeCashoutLoading = useAppSelector(selectIsMakeCashoutLoading)

  const currencyIcon = useGetCurrencyIcon()
  const dispatch = useAppDispatch()

  const amount =
    currentCashout.amount === cashout?.amount
      ? currentCashout.amount
      : cashout?.amount

  const setCurrentCashout = () => {
    dispatch(
      betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
    )

    setItemLocalStorage(NEW_MAKE_CASHOUT_LS, {
      betId: number,
      amount
    })
  }

  const cashoutAmount = `${t('sell for')} ${formatAmountLocaleString({
    value: amount,
    currency: currencyIcon
  })}`

  const isCashoutAmountHasChanged = currentCashout.amount !== cashout?.amount

  const sellingBetIdText = `${t('selling a bet')} #${number}`

  const sellBetText = `${sellingBetIdText}. ${cashoutAmount}`

  return (
    <StyledCurrentCashoutWrapper
      className={DISABLE_HOVER_ON_CHILD}
      isMakeCashoutLoading={isMakeCashoutLoading}
    >
      {getCurrentCashoutModalContent({
        cashoutError,
        cashout,
        isCashoutAmountHasChanged,
        setCurrentCashout,
        cashoutAmount: sellBetText,
        number
      })}
    </StyledCurrentCashoutWrapper>
  )
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const CompetitorName = styled.span`
  ${typography['Body / 14 Bold']}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
`

export const StyledBannerContentLive = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxl-32']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledBannerContentColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledBannerTeamLive = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
    display: grid;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    grid-template-columns: auto minmax(0, 1fr);

    & svg {
      height: 32px;
      width: 32px;

      & path {
        fill: ${theme.tokens.colors.text['text-alwaysWhite']};
        fill-opacity: 50%;
      }
    }
  `}
`

export const StyledBannerTeamLiveWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledBannerContentLine = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledBannerTeamLine = styled(StyledBannerTeamLive)<{
  isLeft?: boolean
}>`
  width: 100%;

  & svg {
    height: 48px;
    width: 48px;
  }

  ${({ isLeft }) => css`
    ${isLeft &&
    css`
      grid-template-columns: minmax(0, 1fr) auto;
      justify-items: end;
      text-align: right;
    `}
  `}
`

export const StyledBannerEventDate = styled.div`
  ${typography['Body / 12 Medium']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite-opacity70']};
    text-align: right;
  `}
`

export const StyledBannerEventDateWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    justify-content: center;
    min-width: 90px;
    overflow: visible;
    white-space: nowrap;
  `}
`

export const StyledBannerEventTime = styled.div`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
    text-align: right;
  `}
`

export const StyledStatistics = styled.div`
  display: flex;
`

export const StyledStatiscticsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledStatisticsName = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  font-weight: 800;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  text-transform: uppercase;
  width: 52px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite-opacity50']};
  `}
`

export const StyledStatisticsValue = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  line-height: 20px;
  width: 52px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
  `}
`

export const StyledBannerCompact = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  position: absolute;
  width: 100%;
`

export const StyledBannerCompactTeams = styled.div`
  ${typography['Body / 12 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
  `}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledBannerCompactScore = styled.div`
  ${typography['Body / 14 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
  `}
`

export const StyledBannerCompactInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`

export const StyledBannerCompactStats = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

export const StyledBannerCompactDate = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite-opacity70']};
  `}
`

export const StyledBannerCompactTime = styled.div`
  ${typography['Body / 14 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
  `}
`

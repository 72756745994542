import { FC } from 'react'

import { LiveContentProps } from './live-content.types'
import { LiveScoreCell, LiveStatsCell } from './ui'

export const LiveContent: FC<LiveContentProps> = ({ event }) => {
  return (
    <>
      <LiveScoreCell eventStatistics={event.statistics} />
      <LiveStatsCell event={event} />
    </>
  )
}

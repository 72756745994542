import { baseLegacyApi } from '../api'

import { GetUserInfoFetch } from './types'

export const userApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserIdentificationStatus: builder.query<
      GetUserInfoFetch['modifiedResponse'],
      GetUserInfoFetch['request']
    >({
      query: () => ({
        method: 'POST',
        url: 'user/info',
        body: { session: '__session__', platforma: 'NEWOLIMPBET' }
      }),
      transformResponse: (res: GetUserInfoFetch['response']) => ({
        ...res,
        data: { identificationStatus: res.data.ident_status }
      })
    })
  })
})

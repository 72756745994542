import { useCallback, useEffect } from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { useInterval } from 'hooks'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { SPORTS_COUNTERS_POLLING_INTERVAL } from '../constants'

export const useFetchSportsCounters = (currentTabMenu: ESportsMenuTabTypes) => {
  const dispatch = useAppDispatch()

  const fetchSportsCounters = useCallback(() => {
    dispatch(sportCountersProviderActions.fetchLiveSportCounters({}))
    dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
  }, [dispatch])

  useEffect(() => {
    fetchSportsCounters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentTabMenu])

  useInterval(fetchSportsCounters, SPORTS_COUNTERS_POLLING_INTERVAL)
}

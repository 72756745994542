import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledLiveWrapper = styled.div<{ hasTime: boolean }>`
  ${({ theme, hasTime }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${hasTime
        ? theme.tokens.alias.space['space-xxxxs-2']
        : theme.tokens.alias.space['space-xxxs-4']}px
      0px;

    & > div:first-child {
      ${hasTime &&
      css`
        background: rgba(0, 0, 0, 0.2);
      `};
    }
  `}
`

export const StyledLiveIndicator = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
  `}
`

export const StyledLivePinWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
`

export const StyledLivePinGhost = styled.div`
  @keyframes livePinAnimation {
    41%,
    100% {
      display: none;
    }
    0% {
      transform: scale(1);
    }
    20%,
    40% {
      transform: scale(2.4);
    }
  }

  animation: livePinAnimation 1.4s infinite;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  content: '';
  height: 5px;
  position: absolute;
  width: 5px;
`

export const StyledLivePin = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.icon['icon-alwaysWhite']};
    border-radius: 50%;
    height: 5px;
    width: 5px;
  `}
`

export const StyledLiveTime = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-xxxxs-2']}px 0px;
  `}
`

export const StyledLivePhase = styled.div`
  ${typography['Body / 12 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
  `}
`

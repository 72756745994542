export enum OddModes {
  Increase = 'Increase',
  Decrease = 'Decrease',
  Default = 'Default'
}

export type UseOddHighlightType = {
  mode: OddModes
  prevOdd?: number
}

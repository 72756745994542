import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconInfoCircleSFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.94829 12.6063C6.20183 12.8599 6.53545 13 6.88907 13H9.09758C9.45121 13 9.79149 12.8599 10.0384 12.6063L11.6063 11.045C11.8599 10.7915 12 10.4579 12 10.1043V7.89575C12 7.54212 11.8599 7.20184 11.6063 6.95496L10.045 5.39366C9.79149 5.14012 9.45788 5 9.10425 5L6.89575 5C6.54212 5 6.20184 5.14012 5.95496 5.39366L4.39366 6.95496C4.14012 7.20851 4 7.54212 4 7.89575L4 10.1043C4 10.4579 4.14012 10.7982 4.39366 11.045L5.94829 12.6063ZM8.66667 7C8.66667 7.36819 8.36819 7.66667 8 7.66667C7.63181 7.66667 7.33333 7.36819 7.33333 7C7.33333 6.63181 7.63181 6.33333 8 6.33333C8.36819 6.33333 8.66667 6.63181 8.66667 7ZM8.66667 11C8.66667 11.3682 8.36819 11.6667 8 11.6667C7.63181 11.6667 7.33333 11.3682 7.33333 11V9C7.33333 8.63181 7.63181 8.33333 8 8.33333C8.36819 8.33333 8.66667 8.63181 8.66667 9V11Z"
        fill="#A7AFBE"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react'
import {
  eventsProviderActions,
  selectEventStatistics
} from 'astra-core/containers/EventsProvider'
import { selectSport } from 'astra-core/containers/CommonDataProvider'

import { getEventHomeAwayCompetitors } from 'shared/lib/events'
import { useInterval } from 'hooks'
import { RootState } from 'shared/types/store'
import { EventContext, IEventContext } from 'widgets/Event/Event.context'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { LayoutContext } from 'layouts/event/model/context'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { EVENT_STATISTICS_POLLING_INTERVAL } from './constants'
import { getBannerScore } from './utils'

export const useEventBannerData = () => {
  const { event } = useContext(EventContext) as IEventContext
  const containerRef = useRef(null)
  const { getCoordsEventBanner } = useContext(LayoutContext) || {}

  useLayoutEffect(() => {
    getCoordsEventBanner?.(containerRef)
  }, [getCoordsEventBanner])

  const competitors = useMemo(
    () => getEventHomeAwayCompetitors(event!),
    [event]
  )

  const eventStatistics = useAppSelector((state: RootState) =>
    selectEventStatistics(state, event.id)
  )

  const bannerScore = useMemo(
    () => getBannerScore(eventStatistics, event.live),
    [event.live, eventStatistics]
  )

  const currentServer = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.CurrentServer
  )

  const sport = useAppSelector(
    (state: RootState) => selectSport(state, event.tournament.sportId)!
  )

  return { bannerScore, competitors, containerRef, currentServer, sport }
}

export const useEventBannerLiveStatistics = () => {
  const dispatch = useAppDispatch()
  const { event } = useContext(EventContext) as IEventContext

  const fetchEventStatistics = useCallback(() => {
    if (event.live) {
      dispatch(
        eventsProviderActions.fetchEventStatistics({ eventId: event.id })
      )
    }
  }, [dispatch, event.id, event.live])

  useInterval(
    fetchEventStatistics,
    event.live ? EVENT_STATISTICS_POLLING_INTERVAL : null,
    true
  )
}

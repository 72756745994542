import styled from 'styled-components'

export const StyledComponentOfAuthorized = styled.div`
  align-items: center;
  display: flex;
  gap: ${(p) => p.theme.tokens.alias.space['space-xs-8']}px;
  position: relative;
`

export const StyledOnboardingBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

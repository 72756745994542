import styled from 'styled-components'

export const StyledAppLayout = styled.div`
  background: ${({ theme }) => theme.pageBackground};
  height: 100%;
  overflow-x: hidden;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledHeaderWrapper = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M13.6772 2.54016C12.8219 1.17775 10.759 1.3422 10.1794 2.81897C9.80615 3.77005 10.238 4.69583 10.9832 5.1481C9.24441 5.58997 8.12622 7.15988 8.12622 9.02869V9.32516L15.8738 9.32516V9.02869C15.8738 7.16298 14.7594 5.59522 13.0255 5.15033C13.8992 4.62134 14.2222 3.40834 13.6772 2.54016ZM5.84601 12.5709C5.84601 12.8036 6.03467 12.9923 6.2674 12.9923H7L7.72111 19.8933H6.10059C5.86786 19.8933 5.6792 20.082 5.6792 20.3147V21.5788C5.6792 21.8116 5.86786 22.0002 6.10059 22.0002H17.8995C18.1322 22.0002 18.3208 21.8116 18.3208 21.5788V20.3147C18.3208 20.082 18.1322 19.8933 17.8995 19.8933H16.2789L17 12.9923H17.7327C17.9655 12.9923 18.1541 12.8036 18.1541 12.5709V10.8334C18.1541 10.6007 17.9655 10.4121 17.7327 10.4121H6.2674C6.03467 10.4121 5.84601 10.6007 5.84601 10.8334V12.5709ZM12 13.0591C11.5869 13.0591 11.252 13.394 11.252 13.8071V19.8841H12.748V13.8071C12.748 13.394 12.4131 13.0591 12 13.0591Z"
      fill={theme.tokens.colors['sport-icon']['sport-global']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M9.31596 0.731086C8.64492 -0.337899 7.02626 -0.208865 6.5715 0.949849C6.27865 1.69609 6.61746 2.42248 7.20223 2.77735C5.83789 3.12405 4.96053 4.35584 4.96053 5.82217V6.05479L11.0395 6.05479V5.82217C11.0395 4.35828 10.1651 3.12817 8.80464 2.7791C9.49016 2.36403 9.74357 1.41228 9.31596 0.731086ZM3.17141 8.60146C3.17141 8.78407 3.31944 8.93209 3.50204 8.93209H4.07686L4.64267 14.3468H3.37116C3.18856 14.3468 3.04053 14.4949 3.04053 14.6775V15.6694C3.04053 15.852 3.18856 16 3.37116 16H12.6289C12.8115 16 12.9595 15.852 12.9595 15.6694V14.6775C12.9595 14.4949 12.8115 14.3468 12.6289 14.3468H11.3574L11.9231 8.93209H12.4981C12.6807 8.93209 12.8287 8.78407 12.8287 8.60146V7.23823C12.8287 7.05562 12.6807 6.90759 12.4981 6.90759H3.50204C3.31944 6.90759 3.17141 7.05562 3.17141 7.23823V8.60146ZM8 8.98452C7.67585 8.98452 7.41306 9.2473 7.41306 9.57146V14.3396H8.58694V9.57146C8.58694 9.2473 8.32416 8.98452 8 8.98452Z"
      fill={theme.tokens.colors['sport-icon']['sport-global']}
      fillRule="evenodd"
    />
  )
}

export const IconSportPolitics: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC, useContext } from 'react'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'
import { ShortStatistics } from 'betweb-openapi-axios'

import { EventContext, IEventContext } from 'widgets/Event/Event.context'
import { getEventStatisticsByCode } from 'shared/lib/statistics'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { RootState } from 'shared/types/store'
import { useTimerCountdown } from 'shared/lib/events/timer-countdown'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledLiveIndicator,
  StyledLivePhase,
  StyledLivePin,
  StyledLivePinGhost,
  StyledLivePinWrapper,
  StyledLiveTime,
  StyledLiveWrapper
} from './LiveIndicator.styled'

export const LiveIndicator: FC = () => {
  const { event } = useContext(EventContext) as IEventContext

  const matchPhase = getEventStatisticsByCode(
    event,
    EEventStatisticsCodes.MatchPhase
  )

  const { hasTime, value } = useTimerCountdown({ event })

  return (
    <StyledLiveWrapper hasTime={hasTime}>
      <StyledLiveIndicator>
        <StyledLivePinWrapper>
          <StyledLivePinGhost />
          <StyledLivePin />
        </StyledLivePinWrapper>

        {hasTime && <StyledLiveTime>{value}</StyledLiveTime>}
      </StyledLiveIndicator>
      {!!matchPhase && <MatchPhaseValue statisticsItem={matchPhase} />}
    </StyledLiveWrapper>
  )
}

const MatchPhaseValue: FC<{
  statisticsItem: ShortStatistics
}> = ({ statisticsItem }) => {
  const matchPhase = useAppSelector((state: RootState) =>
    selectMatchPhase(state, Number(statisticsItem.value))
  )
  return <StyledLivePhase>{matchPhase?.name}</StyledLivePhase>
}

import {
  createContext,
  useContext,
  useState,
  useCallback,
  PropsWithChildren
} from 'react'

import { StyledTooltipContext } from './BasketTooltipContext.styled'

const BasketTooltipContext = createContext<DOMRect | null>(null)

export const BasketTooltipProvider = ({ children }: PropsWithChildren) => {
  const [rect, setRect] = useState<DOMRect | null>(null)

  const handleOnMouseEnter = useCallback((e: React.MouseEvent) => {
    const clientRect: DOMRect = e.currentTarget.getBoundingClientRect()
    setRect(clientRect)
  }, [])

  return (
    <BasketTooltipContext.Provider value={rect}>
      <StyledTooltipContext onMouseEnter={handleOnMouseEnter}>
        {children}
      </StyledTooltipContext>
    </BasketTooltipContext.Provider>
  )
}

export const useBasketTooltipContext = () => useContext(BasketTooltipContext)

import { useEffect } from 'react'

import { useAppDispatch } from 'shared/lib/@reduxjs'

import { BalanceOperationsTypes, paymentProviderActions } from '../../model'

export const useReset = (balanceOperationType: BalanceOperationsTypes) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      paymentProviderActions.reset({
        balanceOperationsType: balanceOperationType
      })
    )
  }, [balanceOperationType, dispatch])
}

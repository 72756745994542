import { FC, ReactNode } from 'react'

import * as S from './form.styled'

export const Form: FC<{
  CardPayments: ReactNode[]
  PaymentMethodsTabs: ReactNode
  title: string
  description?: string
  children: ReactNode
  subtitle: string
}> = ({
  title,
  CardPayments,
  PaymentMethodsTabs,
  description,
  subtitle,
  children
}) => (
  <S.Form>
    <S.Sidebar>
      <S.SidebarHeader>
        {PaymentMethodsTabs}

        <S.TextSelectPayment>{subtitle}</S.TextSelectPayment>
      </S.SidebarHeader>

      <S.ContainerCards>{CardPayments}</S.ContainerCards>
    </S.Sidebar>

    <S.ContainerContent>
      <S.TextsContainer>
        <S.Title>{title}</S.Title>
        {!!description && <S.Description>{description}</S.Description>}
      </S.TextsContainer>

      <S.Content>{children}</S.Content>
    </S.ContainerContent>
  </S.Form>
)

import React from 'react'

import { IconTikTok } from 'shared/ui/Icon/General/IconTikTok'
import { IconButton } from 'shared/ui/buttons/IconButton'

import { LINK_TIKTOK } from '../lib'

export const OpenTikTok = () => (
  <IconButton
    as="a"
    href={LINK_TIKTOK}
    icon={IconTikTok}
    rel="noreferrer"
    target="_blank"
    view="fill-l"
  />
)

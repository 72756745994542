import styled, { css } from 'styled-components'

export const StyledWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    border-radius: ${tokens.alias['border-radius']['border-radius-m-8']}px;
  `}
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  max-height: 100%;
  overflow: hidden;
  position: relative;
`

export const StyledScrollContainer = styled.div<{ showTab: boolean }>`
  -ms-overflow-style: none;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-top: 49px;
  scrollbar-width: none;

  ${({ showTab }) => css`
    ${showTab &&
    css`
      padding-top: 89px;

      ${StyledTabsWrapper} {
        padding-bottom: 0;
      }
    `}
  `}
`

export const StyledTabsWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    backdrop-filter: blur(24px);
    background: ${tokens.colors.surface['surface-default-opacity']};
    display: flex;
    flex-direction: column;
    gap: 8px;
    left: 0;
    margin-bottom: 1px;
    padding: ${tokens.alias.space['space-xs-8']}px 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  `}
`

export const StyledSportsMenu = styled.div<{ withSearch?: boolean }>`
  display: grid;
  grid-area: sports-menu;
  ${({ theme: { tokens }, withSearch }) => css`
    box-shadow: inset -2px 0px 0px -1px
      ${tokens.colors.border['border-primary']};
    gap: ${tokens.alias.space['space-xs-8']}px;
    grid-template-rows: ${withSearch && 'auto'} minmax(0, 1fr);
    padding: ${tokens.alias.space['space-xs-8']}px;
  `};
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M0.938354 10.7204V8.63911C0.938354 8.63911 1.04148 7.70161 2.07273 7.53286L10.6696 7.65474L10.0509 9.49224H7.96023L8.20398 8.26411L6.85398 11.0766L9.91961 14.011L12.254 7.41099C12.254 7.41099 12.6665 5.81724 14.3446 5.93911L20.9727 5.81724C20.9727 5.81724 22.6134 5.36724 23.0634 4.96411L21.3477 8.38599L14.8415 8.26411L14.1009 10.2235L12.1415 10.5891L19.1352 10.711L18.2727 12.6704H13.3602L11.4009 19.036L2.80398 10.3547C2.80398 10.3547 1.71648 9.53911 0.938354 10.7204Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M0.625854 7.14695V5.75945C0.625854 5.75945 0.694605 5.13445 1.3821 5.02195L7.11336 5.1032L6.70085 6.3282H5.30711L5.4696 5.50945L4.5696 7.38445L6.61336 9.3407L8.1696 4.9407C8.1696 4.9407 8.4446 3.8782 9.56335 3.95945L13.9821 3.8782C13.9821 3.8782 15.0759 3.5782 15.3759 3.30945L14.2321 5.5907L9.89461 5.50945L9.40085 6.8157L8.0946 7.05945L12.7571 7.1407L12.1821 8.44695H8.9071L7.60085 12.6907L1.8696 6.9032C1.8696 6.9032 1.1446 6.35945 0.625854 7.14695Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportCrossFire: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { getIsEventEnded, getIsEventPending, getEventTitle } from 'astra-core'
import { basketProviderActions } from 'astra-core/containers/BasketProvider'
import {
  ESportsCodes,
  getOutcomeTypeById,
  selectOutcomeCategoryById,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { FC, useState, useMemo, useCallback, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { CoefficientCoupon } from 'features/coefficient-coupon'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { RootState } from 'shared/types/store'
import {
  BasketCard,
  BasketCardEventStatus,
  useIsEventCanceled
} from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { BetCardCouponProps } from './card.types'
import { StyledEventCouponError } from './card.styled'

export const Card: FC<PropsWithChildren<BetCardCouponProps>> = ({
  outcome,
  children
}) => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()

  const {
    event,
    probability,
    event: { status: eventStatus, id: eventId },
    probability: { tradingStatus },
    id: outcomeId,
    status
  } = outcome

  const outcomeType = useAppSelector((state: RootState) =>
    getOutcomeTypeById(state, probability.outcomeTypeId)
  )
  const outcomeCategory = useAppSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcomeType?.categoryId)
  )
  const sport = useAppSelector(
    (state: RootState) => selectSport(state, event.tournament.sportId)!
  )

  const isEventCanceled = useIsEventCanceled({
    isLive: event.live,
    eventDate: event.eventDate
  })

  const [isHover, setIsHover] = useState<boolean>(false)
  const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()
  const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
  const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLDivElement>()

  const isEventEnded = useMemo(
    () => getIsEventEnded(eventStatus, tradingStatus),
    [eventStatus, tradingStatus]
  )

  const isEventPending = useMemo(
    () => getIsEventPending(status, eventStatus, tradingStatus),
    [eventStatus, status, tradingStatus]
  )

  const toggleIsHover = useCallback(
    (flag: boolean) => {
      if (!isEventEnded) {
        setIsHover(flag)
      }
    },
    [isEventEnded]
  )

  const onMouseEnter = useCallback(() => {
    toggleIsHover(true)
  }, [toggleIsHover])

  const onMouseLeave = useCallback(() => {
    toggleIsHover(false)
  }, [toggleIsHover])

  const onDeleteOutcome = useCallback(() => {
    dispatch(basketProviderActions.deleteBasketOutcome({ outcomeId }))
  }, [dispatch, outcomeId])

  const eventTitle = getEventTitle(event)

  const basketOutcomeName = useMemo(() => {
    return outcomeType
      ? getBasketOutcomeName({
          probWithOutcome: {
            ...probability,
            outcomeTypeData: outcomeType
          },
          event,
          outcomeCategory
        })
      : ''
  }, [event, outcomeCategory, outcomeType, probability])

  const getStatus = () => {
    if (isEventPending) {
      return BasketCardEventStatus.PENDING
    }

    if (isEventCanceled) {
      return BasketCardEventStatus.CANCELLED
    }

    if (isEventEnded) {
      return BasketCardEventStatus.ENDED
    }
  }

  const CoefficientCouponComponent = useMemo(() => {
    const isHiddenCoefficientCoupon = !!(isEventEnded || isEventCanceled)

    return (
      !isHiddenCoefficientCoupon && (
        <CoefficientCoupon eventId={eventId} eventProbability={probability} />
      )
    )
  }, [eventId, isEventCanceled, isEventEnded, probability])

  const InputComponent = useMemo(() => {
    return (
      <>
        {children}

        {isEventPending && (
          <StyledEventCouponError>
            {t('event suspended')}
          </StyledEventCouponError>
        )}
      </>
    )
  }, [children, isEventPending, t])

  return (
    <BasketCard
      CoefficientCoupon={CoefficientCouponComponent}
      eventStatus={getStatus()}
      Input={InputComponent}
      isCardHover={isHover}
      isTooltipCommonOutcomeName={!isOutcomeOverflowing}
      isTooltipCommonTeamsName={!isTeamsOverflowing}
      isTooltipCommonTournamentName={!isNamesOverflowing}
      outcomeName={basketOutcomeName}
      outcomeNameRef={outcomeRef}
      sportCode={sport?.code as ESportsCodes}
      teamsName={eventTitle}
      teamsNameRef={teamsRef}
      tournamentName={event.tournament.name}
      tournamentNameRef={namesRef}
      onDeleteOutcome={onDeleteOutcome}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}

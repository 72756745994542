import {
  basketProviderActions,
  EBetTypes
} from 'astra-core/containers/BasketProvider'
import { FC, memo, useCallback } from 'react'

import { BetTypeNavigation as BetTypeNavigationEntity } from 'entities/basket'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { BetTypeNavigationItem } from '../bet-type-navigation-item'

import { BetTypesLocale } from './bet-type-navigation.types'

const betTypesLocale: BetTypesLocale[] = [
  { name: EBetTypes.Ordinar, locale: 'ordinar' },
  { name: EBetTypes.Express, locale: 'express' },
  { name: EBetTypes.System, locale: 'system' }
]

export const BetTypeNavigation: FC = memo(() => {
  const dispatch = useAppDispatch()

  const onCloseBasket = useCallback(() => {
    dispatch(basketProviderActions.deleteBasket())
  }, [dispatch])

  return (
    <BetTypeNavigationEntity onCloseBasket={onCloseBasket}>
      {betTypesLocale.map(({ name, locale }) => (
        <BetTypeNavigationItem key={name} locale={locale} name={name} />
      ))}
    </BetTypeNavigationEntity>
  )
})

import styled, { css } from 'styled-components'

export const StyledTooltipContext = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    position: relative;
  `}
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M2.62825 5.57362C3.16373 3.96646 4.32824 2.77766 5.73155 2.20451C5.68693 2.77697 5.77395 3.37868 5.94585 3.95257C6.26784 5.0275 6.92164 6.13963 7.83039 7.09213C8.73946 8.04495 9.80129 8.73074 10.8386 9.06815C11.4541 9.26837 12.0971 9.35569 12.7024 9.27329C12.0852 11.1137 10.6424 12.4036 8.96196 12.8458C8.41184 11.6623 7.58916 10.4405 6.50209 9.30111C5.27338 8.01327 3.94422 7.07946 2.68105 6.51204C2.59246 6.47225 2.50061 6.45171 2.40975 6.44864C2.45924 6.15586 2.53171 5.86339 2.62825 5.57362Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
      <path
        d="M2.36719 8.08927C2.60505 10.1395 3.92146 11.9725 5.90746 12.6994C6.38304 12.8735 6.86625 12.9729 7.34543 13.0035C6.88393 12.1488 6.25053 11.2626 5.4406 10.4137C4.429 9.3534 3.36194 8.58174 2.36719 8.08927Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
      <path
        d="M9.42684 2.13658C11.6712 2.95807 13.0604 5.19205 13.0043 7.55593C12.6254 7.76373 12.0357 7.81009 11.2835 7.56542C10.5069 7.31282 9.65071 6.77489 8.89188 5.97955C8.13275 5.18388 7.61903 4.28561 7.3781 3.48127C7.15672 2.74219 7.19038 2.18275 7.3473 1.83238C8.03287 1.78896 8.73785 1.88439 9.42684 2.13658Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
      <path
        d="M15.3206 17.546C15.9136 16.6008 17.4385 14.8823 17.8621 13.8512C18.9635 11.2734 22.2675 3.96965 22.2675 3.96965C22.5216 3.45409 22.2675 2.8526 21.7592 2.68075L20.2342 1.99334C19.7259 1.73556 19.1329 1.99334 18.9635 2.5089C18.9635 2.5089 15.7442 9.81264 14.6428 12.2186C14.1345 13.2497 14.0498 15.6556 13.7109 16.8586C13.5415 17.3742 13.0332 18.5771 12.6096 19.3505C12.186 19.1786 11.593 19.3505 11.4235 19.866C11.2541 20.2957 11.4235 20.8971 11.8471 21.069L13.2873 21.7564C13.7109 21.9283 14.304 21.7564 14.4734 21.3268C14.6428 20.8971 14.4734 20.2957 14.0498 20.1238C14.5581 19.1786 15.0664 18.0616 15.3206 17.546Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M0.208853 3.00108C0.637236 1.71536 1.56885 0.764318 2.69149 0.305794C2.6558 0.763763 2.72541 1.24513 2.86293 1.70424C3.12052 2.56419 3.64356 3.45389 4.37057 4.21589C5.09782 4.97815 5.94728 5.52678 6.7771 5.7967C7.26952 5.95688 7.7839 6.02674 8.26817 5.96082C7.77442 7.43315 6.62015 8.46509 5.27582 8.81879C4.83572 7.87205 4.17758 6.8946 3.30792 5.98308C2.32496 4.9528 1.26163 4.20576 0.251092 3.75182C0.180216 3.71999 0.106735 3.70355 0.0340497 3.7011C0.0736408 3.46688 0.131614 3.2329 0.208853 3.00108Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
      <path
        d="M0 5.01361C0.19029 6.65382 1.24342 8.12018 2.83222 8.70173C3.21268 8.84099 3.59925 8.9205 3.98259 8.94497C3.61339 8.26121 3.10667 7.55227 2.45873 6.87314C1.64945 6.0249 0.795801 5.40758 0 5.01361Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
      <path
        d="M5.64772 0.25145C7.44319 0.908646 8.55457 2.69583 8.50967 4.58693C8.20654 4.75317 7.73479 4.79026 7.13306 4.59453C6.51181 4.39244 5.82681 3.9621 5.21976 3.32582C4.61245 2.68929 4.20147 1.97068 4.00873 1.3272C3.83162 0.73594 3.85855 0.288389 3.98409 0.00809547C4.53255 -0.0266475 5.09653 0.0496978 5.64772 0.25145Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
      <path
        d="M10.3627 12.579C10.8371 11.8228 12.0571 10.448 12.396 9.62313C13.277 7.5609 15.9202 1.71791 15.9202 1.71791C16.1236 1.30546 15.9202 0.824271 15.5136 0.686789L14.2936 0.136861C13.887 -0.0693625 13.4126 0.136862 13.277 0.549309C13.277 0.549309 10.7016 6.3923 9.82051 8.31705C9.41387 9.14194 9.34609 11.0667 9.07499 12.0291C8.93944 12.4415 8.5328 13.4039 8.19392 14.0226C7.85505 13.8851 7.38063 14.0226 7.24508 14.435C7.10953 14.7787 7.24508 15.2599 7.58395 15.3974L8.73612 15.9473C9.07499 16.0848 9.54942 15.9473 9.68497 15.6036C9.82052 15.2599 9.68497 14.7787 9.34609 14.6412C9.75274 13.8851 10.1594 12.9914 10.3627 12.579Z"
        fill={theme.tokens.colors['sport-icon']['sport-baseball']}
      />
    </>
  )
}

export const IconSportBaseball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

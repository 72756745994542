import styled, { createGlobalStyle, css } from 'styled-components'

import { ButtonDeprecated } from 'shared/ui/ButtonDeprecated'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { typography } from 'shared/lib/theme/typography'

export const StyledSuccessPageWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.tokens.colors.surface['surface-modal']};
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 1001;
`

export const GlobalStyleHideLiveChatPatch = createGlobalStyle`
  #chat-widget-container {
    display: none;
  }
`

export const StyledSuccessPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
`

export const StyledSuccessPageTitle = styled.h2`
  ${typography['Body / 16 Bold']};
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledSuccessPageSubtitle = styled.h3`
  ${typography['Body / 14 SemiBold']};
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`

export const StyledSuccessPageButton = styled(ButtonDeprecated)`
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: auto;
  line-height: 16px;
  margin-top: 20px;
  padding: 4px 8px;
`

export const StyledSuccessPageModalHeaderWrapper = styled.div`
  position: absolute;
  right: 32px;
  top: 40px;
`

export const StyledSuccessPageModalHeaderExit = styled(IconCloseOutline16)`
  cursor: pointer;
  margin-right: 20px;
`

import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconArrowCircleFilled12: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7115 2.23022L6.27504 6.66669H8.00171C8.36838 6.66669 8.66838 6.96669 8.66838 7.33336C8.66838 7.70003 8.36838 8.00003 8.00171 8.00003H4.66171C4.49504 8.00003 4.32171 7.93336 4.19504 7.80669C4.13504 7.74003 4.08171 7.66669 4.05504 7.58669C4.02171 7.50669 4.00171 7.42003 4.00171 7.33336V4.00003C4.00171 3.63336 4.30171 3.33336 4.66838 3.33336C5.03504 3.33336 5.33504 3.63336 5.33504 4.00003V5.72669L9.77008 1.28665L9.07171 0.586667C8.6915 0.206667 8.19122 0 7.66426 0H4.34908C3.82212 0 3.3085 0.206667 2.94163 0.586667L0.586993 2.93333C0.206782 3.31333 0 3.81333 0 4.34667V7.65333C0 8.18667 0.206782 8.69333 0.586993 9.06667L2.92829 11.4067C3.3085 11.7867 3.80878 12 4.34241 12H7.65092C8.18455 12 8.6915 11.7867 9.06504 11.4067L11.4063 9.06667C11.7865 8.68667 12 8.18667 12 7.65333V4.34C12 3.80667 11.7865 3.3 11.4063 2.92667L10.7115 2.23022Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

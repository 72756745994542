import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { Typography } from 'shared/ui/typography'

import { BonusPanelInfoProps } from './bonus-panel-info.types'
import {
  StyledBonus,
  StyledBonusContent,
  StyledBonusPercent,
  StyledBonusSum,
  StyledBonusText,
  StyledBonusWrapper
} from './bonus-panel-info.styled'

export const BonusPanelInfo: FC<BonusPanelInfoProps> = memo(
  ({ isDisabled, bonusPercent, currencyIcon, bonusSum, bonusRestriction }) => {
    const [t] = useTranslation()

    return (
      <StyledBonusWrapper>
        <StyledBonus disabled={isDisabled}>
          <StyledBonusContent>
            <StyledBonusText>{t('express bonus')}</StyledBonusText>

            <StyledBonusPercent>{bonusPercent}%</StyledBonusPercent>

            <ExpressBonusModal>
              <IconInfoCircleFilled size={16} />
            </ExpressBonusModal>
          </StyledBonusContent>

          <StyledBonusSum>
            {bonusSum}

            <div>{currencyIcon}</div>
          </StyledBonusSum>
        </StyledBonus>

        {isDisabled ? (
          <Typography color="text-description" font="Body / 12 Regular">
            {t('disabled express bonus')}
          </Typography>
        ) : (
          Number(bonusRestriction) > 0 && (
            <Typography color="text-description" font="Body / 12 Regular">
              {t('limited express bonus 1')}{' '}
              <Typography color="text-description" font="Body / 12 SemiBold">
                {bonusRestriction} {currencyIcon}
              </Typography>{' '}
              {t('limited express bonus 2')}
            </Typography>
          )
        )}
      </StyledBonusWrapper>
    )
  }
)

import { FC } from 'react'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { lineTournamentsContainerSaga } from './saga'
import { useLineTournamentsContainerData } from './hooks'
import { reducerLine, sliceKeyLine } from './slice'
import { LineTournamentsProps } from './types'

export const LineTournamentsContainer: FC<LineTournamentsProps> = ({
  children
}) => {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  useLineTournamentsContainerData()

  return <>{children}</>
}

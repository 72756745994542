export const onFormatSystemCombinationsCount = ({
  combinations
}: {
  combinations: Array<{
    value: number
    outcomesLength: number
    combinationsCount: number
  }>
}) => {
  return combinations.map((combination) => ({
    value: combination.value,
    label: `${combination.value}/${combination.outcomesLength}`
  }))
}

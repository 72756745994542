export enum StorageKeys {
  isQuickBetActive = 'isQuickBetActive',
  shouldConfirmQuickBet = 'shouldConfirmQuickBet',
  quickBetSaveAnyStatus = 'quickBetSaveAnyStatus'
}

export const STORAGE_KEYS_TO_REMOVE_AFTER_LOGOUT: StorageKeys[] = [
  StorageKeys.shouldConfirmQuickBet,
  StorageKeys.isQuickBetActive,
  StorageKeys.quickBetSaveAnyStatus
]

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M12.3643 14.7554L12.3704 15.0026C12.3704 15.0026 12.6042 15.0467 12.6327 15.2057L12.5453 15.2962C12.5472 15.294 12.2952 14.8835 11.7443 15.4221V16.4707H12.7302V15.0182L12.592 14.7555L12.3643 14.7554Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M13.5352 8.20564C13.5352 8.20564 11.3948 8.15708 11.285 8.901L11.2627 5.16142C11.2627 5.16142 11.9477 4.32261 12.8298 5.18566L12.8075 7.41529H17.0009V4.58529C17.0009 4.58529 16.9906 0.79937 12.0635 1.0135C12.0615 1.0135 7.37828 0.582944 7.00228 4.58529V19.517C7.00228 19.517 6.78077 23.0888 11.8583 22.9674C11.8583 22.9674 16.4257 23.5657 17.0009 19.6362V10.146C17.0009 10.146 16.6472 8.20564 13.5352 8.20564ZM14.9316 14.3493C13.5758 14.3493 12.8441 14.7576 12.8441 14.7576V16.6207C12.0026 16.5059 11.6387 17.0247 11.6387 17.0247V20.5899H11.5046V20.74H10.4984V20.6054H10.1631V20.2367H10.2912V13.3824L10.2871 13.2875H10.1651V13.0093H10.2851V12.7576H10.533V12.6384H11.6368C11.9193 13.5302 13.2568 12.7355 13.2568 12.7355C14.4276 11.8657 14.9317 12.6449 14.9317 12.6449L14.9316 14.3493Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M8.2652 10.0039L8.26964 10.1837C8.26964 10.1837 8.43962 10.2158 8.46035 10.3314L8.3968 10.3973C8.39822 10.3957 8.21491 10.0971 7.8143 10.4888V11.2514H8.53125V10.1951L8.43074 10.004L8.2652 10.0039Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M9.11671 5.2405C9.11671 5.2405 7.56009 5.20519 7.48026 5.74622L7.46403 3.02654C7.46403 3.02654 7.96219 2.41649 8.60374 3.04416L8.58752 4.66571H11.6372V2.60753C11.6372 2.60753 11.6297 -0.145851 8.04641 0.00987733C8.04493 0.00987733 4.63899 -0.303251 4.36554 2.60753V13.467C4.36554 13.467 4.20444 16.0646 7.89716 15.9763C7.89716 15.9763 11.2189 16.4114 11.6372 13.5536V6.65171C11.6372 6.65171 11.38 5.2405 9.11671 5.2405ZM10.1323 9.70857C9.14627 9.70857 8.61411 10.0056 8.61411 10.0056V11.3605C8.00211 11.277 7.73748 11.6543 7.73748 11.6543V14.2472H7.63993V14.3564H6.90818V14.2585H6.66428V13.9904H6.75744V9.00538L6.75448 8.93636H6.66576V8.73406H6.753V8.551H6.93335V8.46429H7.73606C7.94152 9.11293 8.91427 8.53492 8.91427 8.53492C9.76573 7.90236 10.1323 8.46905 10.1323 8.46905L10.1323 9.70857Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportRainbowSix: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

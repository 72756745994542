import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M19.0356 4.9643C19.2582 5.1869 19.4258 5.55855 19.4291 6.17232C19.4325 6.79249 19.264 7.58584 18.8919 8.5123C18.1492 10.3616 16.6702 12.5556 14.6137 14.6121C12.5572 16.6687 10.3632 18.1476 8.51388 18.8903C7.58742 19.2624 6.79406 19.4309 6.17389 19.4275C5.56012 19.4242 5.18848 19.2567 4.96588 19.0341C4.74328 18.8115 4.57574 18.4398 4.57239 17.826C4.569 17.2059 4.73753 16.4125 5.10961 15.4861C5.85232 13.6367 7.33128 11.4428 9.38782 9.38624C11.4444 7.3297 13.6383 5.85075 15.4876 5.10803C16.4141 4.73596 17.2074 4.56742 17.8276 4.57081C18.4414 4.57416 18.813 4.7417 19.0356 4.9643Z"
        fill={theme.tokens.colors['sport-icon']['sport-rugby']}
      />
      <path
        d="M20.3826 6.82163C20.087 9.1673 18.2277 12.4052 15.3172 15.3156C12.4046 18.2282 9.1641 20.0882 6.81802 20.3817C9.56903 20.8801 13.7562 19.8246 16.7905 16.7903C19.8234 13.7574 20.8793 9.5727 20.3826 6.82163Z"
        fill={theme.tokens.colors['sport-icon']['sport-rugby']}
      />
      <path
        d="M3.61826 17.1818C3.11998 14.4308 4.17543 10.2437 7.20967 7.20944C10.2425 4.17661 14.4271 3.12073 17.1782 3.61733C14.8325 3.91299 11.5947 5.77231 8.6843 8.68272C5.77174 11.5953 3.91183 14.8357 3.61826 17.1818Z"
        fill={theme.tokens.colors['sport-icon']['sport-rugby']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M14.6217 1.37816C14.8312 1.58766 14.9888 1.93745 14.992 2.51511C14.9952 3.0988 14.8366 3.84549 14.4864 4.71745C13.7874 6.45801 12.3954 8.52289 10.4598 10.4585C8.52426 12.394 6.45938 13.786 4.71883 14.485C3.84687 14.8352 3.10018 14.9938 2.51649 14.9906C1.93882 14.9875 1.58904 14.8298 1.37953 14.6203C1.17003 14.4108 1.01235 14.061 1.00919 13.4833C1.006 12.8996 1.16462 12.1529 1.51481 11.281C2.21383 9.54043 3.60579 7.47555 5.54136 5.53999C7.47693 3.60442 9.5418 2.21246 11.2824 1.51343C12.1543 1.16325 12.901 1.00462 13.4847 1.00781C14.0624 1.01097 14.4121 1.16865 14.6217 1.37816Z"
        fill={theme.tokens.colors['sport-icon']['sport-rugby']}
      />
      <path
        d="M15.8894 3.12623C15.6112 5.33392 13.8612 8.38133 11.122 11.1206C8.38068 13.8619 5.3308 15.6124 3.12273 15.8886C5.71191 16.3577 9.65281 15.3643 12.5086 12.5085C15.3631 9.65404 16.3569 5.71547 15.8894 3.12623Z"
        fill={theme.tokens.colors['sport-icon']['sport-rugby']}
      />
      <path
        d="M0.111188 12.877C-0.357786 10.2878 0.635579 6.34699 3.49133 3.49123C6.34576 0.636806 10.2842 -0.356966 12.8735 0.110426C10.6658 0.388694 7.61843 2.13864 4.87922 4.87785C2.13799 7.61908 0.387489 10.6689 0.111188 12.877Z"
        fill={theme.tokens.colors['sport-icon']['sport-rugby']}
      />
    </>
  )
}

export const IconSportRugby: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

import { probabilityModel } from 'entities/event-probability'

export const CellWrapper = styled.div<{
  isCompact?: boolean
  isMerged?: boolean
  mode?: probabilityModel.OddModes
  isActive?: boolean
  isDisabled?: boolean
}>`
  ${({ theme, mode, isActive, isMerged, isDisabled, isCompact }) => css`
    display: flex;
    flex-direction: ${isCompact ? 'column' : 'row'};
    gap: 1px;

    ${isActive
      ? css`
          ${BlockParamCell} {
            color: ${theme.tokens.colors.text['text-description']};
          }

          ${isMerged
            ? css`
                ${BlockOddCell} {
                  background-color: ${theme.tokens.colors.surface[
                    'surface-active2'
                  ]};
                  color: ${theme.tokens.colors.text['text-invert']};

                  &:hover {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-active2-hover'
                    ]};
                  }
                }
              `
            : css`
                ${BlockCell} {
                  background-color: ${theme.tokens.colors.surface[
                    'surface-active2'
                  ]};
                }
                ${BlockOddCell} {
                  color: ${theme.tokens.colors.text['text-invert']};
                }
                &:hover {
                  ${BlockCell} {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-active2-hover'
                    ]};
                  }
                }
              `}

          ${mode === probabilityModel.OddModes.Increase &&
          css`
            ${BlockOddCell} {
              color: ${theme.tokens.colors.text['text-green']};
            }
          `}

          ${mode === probabilityModel.OddModes.Decrease &&
          css`
            ${BlockOddCell} {
              color: ${theme.tokens.colors.text['text-error']};
            }
          `}
        `
      : css`
          ${isMerged
            ? css`
                ${BlockOddCell}:hover {
                  background-color: ${theme.tokens.colors.surface[
                    'surface-hover'
                  ]};
                }
              `
            : css`
                &:hover {
                  ${BlockCell} {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-hover'
                    ]};
                  }
                }
              `}

          ${mode === probabilityModel.OddModes.Increase &&
          css`
            ${isMerged
              ? css`
                  ${BlockOddCell} {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-positiveLight'
                    ]};
                    &:hover {
                      background-color: ${theme.tokens.colors.surface[
                        'surface-positiveLight-hover'
                      ]};
                    }
                  }
                `
              : css`
                  ${BlockCell} {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-positiveLight'
                    ]};
                  }
                  &:hover {
                    ${BlockCell} {
                      background-color: ${theme.tokens.colors.surface[
                        'surface-positiveLight-hover'
                      ]};
                    }
                  }
                `}
          `}

          ${mode === probabilityModel.OddModes.Decrease &&
          css`
            ${isMerged
              ? css`
                  ${BlockOddCell} {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-negativeLight'
                    ]};
                    &:hover {
                      background-color: ${theme.tokens.colors.surface[
                        'surface-negativeLight-hover'
                      ]};
                    }
                  }
                `
              : css`
                  ${BlockCell} {
                    background-color: ${theme.tokens.colors.surface[
                      'surface-negativeLight'
                    ]};
                  }
                  &:hover {
                    ${BlockCell} {
                      background-color: ${theme.tokens.colors.surface[
                        'surface-negativeLight-hover'
                      ]};
                    }
                  }
                `}
          `}
        `}

    ${isDisabled &&
    css`
      ${BlockCell} {
        background-color: ${theme.tokens.colors.surface[
          'surface-disabled'
        ]} !important;

        color: ${theme.tokens.colors.text['text-disabled']} !important;

        &:hover {
          background-color: ${theme.tokens.colors.surface[
            'surface-disabled'
          ]} !important;
          color: ${theme.tokens.colors.text['text-disabled']} !important;
        }
      }

      pointer-events: none;
    `}
  `}
`

export const BlockCell = styled.div<{
  isFirst?: boolean
  isLast?: boolean
  isCompact?: boolean
  cellWidth?: number
}>`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  line-height: 16px;
  transition: 0.5s ease background-color, color;
  width: 36px;
  ${({ isCompact, isFirst, isLast, cellWidth, theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-default']};
    color: ${theme.tokens.colors.text['text-primary']};
    ${isCompact
      ? css`
          ${isFirst &&
          css`
            border-top-left-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
            border-top-right-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
            line-height: 15px;
          `}
          ${isLast &&
          css`
            border-bottom-left-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
            border-bottom-right-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
            padding: ${theme.tokens.alias.space['space-xxs-6']}px;
          `}
        `
      : css`
          padding: ${theme.tokens.alias.space['space-xs-8']}px;
          ${isFirst &&
          css`
            border-bottom-left-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
            border-top-left-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
          `}
          ${isLast &&
          css`
            border-bottom-right-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
            border-top-right-radius: ${theme.tokens.alias['border-radius'][
              'border-radius-xs-4'
            ]}px;
          `}
        `}
    ${cellWidth &&
    css`
      width: ${cellWidth}px;
    `}
  `}
`

export const BlockParamCell = styled(BlockCell)`
  ${({ theme, isCompact, cellWidth }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};

    width: 36px;
    ${!isCompact &&
    css`
      width: 40px;
    `}
    ${cellWidth &&
    css`
      width: ${cellWidth}px;
    `}
  `}
`

export const BlockOddCell = styled(BlockCell)`
  cursor: pointer;
  position: relative;
`

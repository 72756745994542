import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M11.2096 20.9966C11.5095 21.023 11.7994 20.8951 12.0009 20.6721C12.1012 20.5611 12.2047 20.4475 12.3014 20.3424C12.5943 20.024 12.834 19.7445 12.834 19.7214C12.834 19.6983 12.5842 19.3443 12.279 18.9348C11.9738 18.5253 11.5979 17.9953 11.4438 17.757C11.2897 17.5187 11.1419 17.2969 11.1155 17.2641C11.0891 17.2314 10.8521 17.1883 10.5887 17.1683C10.0445 17.1269 9.22217 16.9902 8.29189 16.7863C7.94141 16.7094 7.62434 16.6466 7.58717 16.6466C7.55001 16.6466 7.17961 17.0299 6.76407 17.4984L6.17428 18.1632C6.07172 18.2789 6.03901 18.4404 6.08855 18.5866C6.10221 18.6269 6.11632 18.6671 6.13087 18.707C6.18645 18.8596 6.28505 18.9926 6.41276 19.0933C7.75551 20.1514 9.40655 20.8378 11.2096 20.9966Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M4.76018 17.3981C4.83624 17.4999 4.94237 17.5913 5.06952 17.5854C5.34168 17.5728 5.6004 17.2647 6.3679 16.3506C6.43312 16.273 6.50202 16.1909 6.57491 16.1043C6.68252 15.9764 6.70355 15.797 6.62841 15.6479L6.61364 15.6186C6.23683 14.8708 5.90516 13.992 5.61436 12.971C5.60765 12.9475 5.60133 12.925 5.5953 12.9035C5.54589 12.7277 5.51607 12.6216 5.44919 12.5517C5.34735 12.4451 5.15957 12.4223 4.68581 12.3648C4.64786 12.3602 4.60807 12.3554 4.56634 12.3502C4.18124 12.303 3.73112 12.2487 3.56604 12.2297L3.54173 12.2268C3.37082 12.207 3.20212 12.2787 3.09801 12.4153C3.02766 12.5076 2.99225 12.622 3.00143 12.7375C3.13955 14.4766 3.77262 16.0768 4.76018 17.3981Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M3.08921 10.566C3.08254 10.6071 3.08672 10.6493 3.10133 10.6883L3.12239 10.7446C3.21486 10.9916 3.44121 11.1638 3.70457 11.1876C3.94155 11.2091 4.46201 11.2675 4.86112 11.3176L5.3653 11.3808C5.5025 11.398 5.63858 11.3415 5.72289 11.2322C6.19934 10.6146 7.10474 9.49482 7.51091 9.02081C7.91352 8.55086 7.97971 8.44757 7.93355 8.3609C7.80682 8.12273 7.60387 7.08621 7.54473 6.3749L7.53704 6.28236C7.50409 5.88583 7.20717 5.56127 6.81416 5.4922C6.67772 5.46823 6.51805 5.44388 6.35655 5.42186C6.07162 5.383 5.78408 5.47625 5.58197 5.68025C4.29119 6.98315 3.39563 8.67618 3.08921 10.566Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M7.49461 4.20591C7.2692 4.3359 7.34423 4.66208 7.60209 4.69824L7.80054 4.72603C7.99988 4.75394 8.20302 4.72152 8.38365 4.63296L8.59852 4.52761C9.48814 4.09147 10.6478 3.71014 11.3609 3.61925C11.6373 3.58398 11.6398 3.58193 12.0231 3.06702C12.0283 3.05999 12.0335 3.05298 12.0387 3.046C12.0528 3.0269 12.0392 3 12.0154 3C10.3686 3 8.82454 3.43895 7.49461 4.20591Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M17.412 19.1652C17.4378 19.1914 17.4346 19.2342 17.405 19.2561C16.3397 20.0458 15.094 20.6073 13.7408 20.8679C13.4634 20.9214 13.306 20.6075 13.5074 20.41L13.7836 20.139C13.8933 20.0314 14.0264 19.9504 14.1725 19.9021L14.975 19.6372C15.5386 19.4512 16.2266 19.2026 16.5039 19.0848C16.5443 19.0676 16.5818 19.0516 16.6167 19.0366C16.8825 18.9227 16.9991 18.8727 17.0962 18.8982C17.1721 18.9182 17.2361 18.9841 17.35 19.1016C17.3692 19.1213 17.3897 19.1425 17.412 19.1652Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M20.6168 12.6587C20.8579 12.7581 21.0082 13.0026 20.9725 13.2603C20.7168 15.1074 19.8999 16.7758 18.6989 18.089C18.5887 18.2095 18.4101 18.2178 18.295 18.102C18.1052 17.9111 18.0133 17.6443 18.0454 17.3774L18.0838 17.0581C18.1328 16.6502 18.1929 15.9372 18.2171 15.4737L18.2417 15.0045C18.2543 14.7636 18.3537 14.5353 18.5216 14.3615L18.6091 14.271C19.0156 13.8506 19.4323 13.3524 19.8059 12.8406C19.8234 12.8165 19.8398 12.7939 19.8552 12.7726C19.9688 12.6156 20.0286 12.533 20.1062 12.5088C20.1922 12.482 20.3001 12.5269 20.5278 12.6217C20.5556 12.6333 20.5853 12.6456 20.6168 12.6587Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M20.9981 10.9733C21.0213 11.174 20.8234 11.3239 20.632 11.2577C20.2298 11.1186 20.2153 11.1075 20.1829 10.9147C20.0639 10.2045 19.7319 9.20551 19.3175 8.31063C19.3047 8.2829 19.2924 8.25654 19.2808 8.23144C19.1329 7.91291 19.08 7.79901 19.1035 7.70452C19.1166 7.65193 19.1534 7.60536 19.2106 7.53286C19.2187 7.52256 19.2273 7.51174 19.2362 7.50031C19.3212 7.39176 19.4428 7.19936 19.5066 7.07272C19.5206 7.04499 19.5591 7.04276 19.5762 7.06869C20.3322 8.21484 20.833 9.54315 20.9981 10.9733Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.9383 3.52708C13.121 3.25099 13.446 3.10602 13.7715 3.1699C15.5495 3.51879 17.1405 4.3873 18.3788 5.61023C18.6375 5.86577 18.7109 6.25227 18.5307 6.56779C18.47 6.67399 18.4098 6.76752 18.3618 6.82469C18.2769 6.92589 18.1341 6.95722 18.0144 6.90096L17.7082 6.75704C17.1545 6.49688 16.6167 6.32707 15.7512 6.13927L15.4852 6.08154C15.2954 6.04035 15.1217 5.9452 14.985 5.80761L14.4047 5.22332C13.8791 4.69417 13.4661 4.33126 13.1699 4.13848L12.8688 3.9425C12.7895 3.89086 12.7675 3.78467 12.8198 3.70591C12.8621 3.64232 12.9015 3.58278 12.9383 3.52708Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.4891 4.55058C13.0587 4.98082 14.3426 6.22876 14.341 6.35068C14.3386 6.53523 12.4577 8.94931 12.3131 8.95326C12.2784 8.95424 11.9634 8.87234 11.6129 8.77126C11.2625 8.67024 10.5637 8.5221 10.0601 8.44209C9.55654 8.36209 9.09172 8.28631 9.0272 8.27369C8.62367 8.19472 8.23146 5.6436 8.58198 5.37775C9.05547 5.01858 10.3133 4.48645 11.1374 4.2967C11.8817 4.12532 11.9409 4.13648 12.4891 4.55058Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M16.9229 7.43758C17.2068 7.52604 17.633 7.67916 17.8699 7.77791L17.9523 7.8122C18.177 7.90578 18.3599 8.07753 18.4671 8.29551L18.5795 8.52389C18.8781 9.13114 19.2112 10.0827 19.4071 10.8882L19.4477 11.0553C19.4991 11.2666 19.4621 11.4896 19.3454 11.6732C19.1112 12.0413 18.5391 12.7572 18.0175 13.335L17.8646 13.5043C17.7036 13.6826 17.4671 13.7741 17.2276 13.7507C15.6626 13.5976 14.4835 13.2391 14.3908 12.8881C14.2314 12.2843 13.7546 10.9634 13.4659 10.3255L13.3753 10.1253C13.2333 9.8115 13.2872 9.44398 13.5133 9.18379C13.7173 8.94909 13.9505 8.6736 14.0315 8.57166C14.6213 7.82866 14.7628 7.64109 14.9741 7.32188C15.1165 7.10665 15.3809 7.00611 15.631 7.07207L15.8096 7.11919C16.138 7.20586 16.639 7.34913 16.9229 7.43758Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M9.89855 9.30745C10.4675 9.40782 11.9031 9.75839 12.0401 9.83037C12.2633 9.94775 12.9563 11.5843 13.3208 12.855L13.4487 13.3006C13.5006 13.4813 13.4681 13.6757 13.3604 13.83C12.7959 14.6383 11.4949 16.1185 11.2176 16.2677C11.0213 16.3733 9.13023 16.0788 8.14808 15.7896L8.0599 15.7637C7.79003 15.6842 7.56593 15.4956 7.44214 15.2436L7.35832 15.073C7.02832 14.4014 6.47667 12.9354 6.3438 12.377C6.28364 12.1242 6.33264 11.8579 6.47888 11.6428L6.49531 11.6187C6.90379 11.018 7.48538 10.3134 8.06088 9.72188L8.32385 9.45159C8.50258 9.26788 8.75386 9.17272 9.00985 9.19181C9.23203 9.20838 9.63194 9.26044 9.89855 9.30745Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M14.6305 14.3967C15.201 14.5702 16.3024 14.79 16.8413 14.8378C17.0797 14.8589 17.2597 15.0626 17.2507 15.3011L17.2328 15.7746C17.1983 16.6895 17.0302 18.0274 16.938 18.12C16.8569 18.2016 15.5758 18.6823 14.6383 18.9828L14.3677 19.0696C14.0069 19.1853 13.6116 19.0825 13.3535 18.806C12.8945 18.3144 12.4704 17.7332 12.1862 17.2062C12.0786 17.0066 12.1109 16.7608 12.2666 16.5956L12.4568 16.3937C12.9377 15.8834 13.588 15.0818 13.9336 14.5735C14.0496 14.4029 14.1552 14.2633 14.1682 14.2633C14.1812 14.2633 14.3892 14.3234 14.6305 14.3967Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M7.2974 15.997C7.56397 16.0204 7.82173 15.9068 8.00084 15.7085C8.08999 15.6099 8.18196 15.5089 8.2679 15.4154C8.52825 15.1324 8.7413 14.884 8.7413 14.8635C8.7413 14.8429 8.5193 14.5283 8.24799 14.1643C7.97667 13.8003 7.64259 13.3292 7.50557 13.1173C7.3686 12.9055 7.23727 12.7084 7.21381 12.6792C7.19035 12.6502 6.9796 12.6118 6.74553 12.594C6.26174 12.5573 5.53082 12.4358 4.7039 12.2545C4.39237 12.1862 4.11052 12.1303 4.07749 12.1303C4.04445 12.1303 3.71521 12.471 3.34584 12.8874L2.82159 13.4784C2.73041 13.5812 2.70134 13.7248 2.74538 13.8548C2.75752 13.8906 2.77007 13.9263 2.783 13.9618C2.8324 14.0974 2.92004 14.2157 3.03357 14.3051C4.22712 15.2457 5.69471 15.8558 7.2974 15.997Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M1.5646 12.7983C1.63221 12.8888 1.72655 12.9701 1.83957 12.9648C2.0815 12.9536 2.31146 12.6797 2.99369 11.8672C3.05166 11.7982 3.11291 11.7252 3.1777 11.6483C3.27335 11.5346 3.29205 11.3751 3.22525 11.2426L3.21213 11.2165C2.87718 10.5518 2.58236 9.77063 2.32388 8.86314C2.31791 8.84218 2.31229 8.82219 2.30693 8.80313C2.26301 8.64689 2.2365 8.55258 2.17706 8.49037C2.08653 8.39564 1.91962 8.37538 1.4985 8.32426C1.46476 8.32016 1.42939 8.31587 1.3923 8.31132C1.04999 8.26934 0.649885 8.2211 0.503144 8.20415L0.481536 8.20164C0.329615 8.18404 0.17966 8.24771 0.0871183 8.36912C0.0245825 8.45117 -0.00688969 8.55287 0.00126929 8.65559C0.124048 10.2015 0.686769 11.6239 1.5646 12.7983Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M0.0792956 6.72532C0.0733705 6.76186 0.077084 6.79938 0.0900683 6.83406L0.108793 6.88408C0.190987 7.10364 0.392187 7.25675 0.626282 7.27788C0.836938 7.29695 1.29957 7.3489 1.65433 7.39344L2.10249 7.44964C2.22445 7.46493 2.3454 7.41465 2.42035 7.31751C2.84386 6.76858 3.64866 5.77317 4.0097 5.35183C4.36757 4.9341 4.42641 4.84228 4.38538 4.76524C4.27273 4.55354 4.09233 3.63218 4.03976 2.99991L4.03293 2.91766C4.00364 2.56518 3.73971 2.27669 3.39037 2.21529C3.26909 2.19398 3.12715 2.17234 2.9836 2.15276C2.73032 2.11822 2.47473 2.20111 2.29509 2.38244C1.14773 3.54057 0.351669 5.04549 0.0792956 6.72532Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M3.99521 1.07192C3.79485 1.18747 3.86154 1.4774 4.09075 1.50954L4.26715 1.53425C4.44433 1.55906 4.62491 1.53024 4.78547 1.45152L4.97646 1.35788C5.76723 0.970193 6.79801 0.631233 7.43189 0.550441C7.67759 0.519097 7.67979 0.517267 8.02053 0.0595748C8.02518 0.0533234 8.02981 0.0470925 8.0344 0.0408846C8.04697 0.0239133 8.03487 0 8.01371 0C6.54984 0 5.17737 0.390178 3.99521 1.07192Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.8107 14.3691C12.8336 14.3924 12.8307 14.4304 12.8045 14.4499C11.8575 15.1518 10.7502 15.6509 9.54739 15.8826C9.30079 15.9301 9.16085 15.6511 9.3399 15.4755L9.58545 15.2347C9.68297 15.139 9.80122 15.067 9.93108 15.0241L10.6445 14.7886C11.1454 14.6233 11.757 14.4023 12.0035 14.2976C12.0394 14.2823 12.0727 14.2681 12.1037 14.2548C12.34 14.1535 12.4436 14.1091 12.53 14.1318C12.5974 14.1495 12.6543 14.2081 12.7556 14.3125C12.7726 14.3301 12.7909 14.3489 12.8107 14.3691Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M15.6594 8.58548C15.8737 8.67391 16.0073 8.89118 15.9756 9.12023C15.7483 10.7621 15.0221 12.2452 13.9546 13.4124C13.8566 13.5195 13.6979 13.5269 13.5955 13.424C13.4268 13.2543 13.3451 13.0171 13.3737 12.7799L13.4078 12.4961C13.4514 12.1335 13.5048 11.4997 13.5263 11.0877L13.5482 10.6707C13.5594 10.4565 13.6477 10.2536 13.797 10.0991L13.8748 10.0187C14.2361 9.64497 14.6065 9.20215 14.9385 8.7472C14.9542 8.72582 14.9687 8.70568 14.9824 8.68676C15.0834 8.54719 15.1365 8.4738 15.2055 8.45229C15.2819 8.42845 15.3779 8.4684 15.5803 8.55264C15.605 8.56293 15.6313 8.57389 15.6594 8.58548Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M15.9984 7.08739C16.0189 7.26575 15.843 7.39898 15.6729 7.34014C15.3154 7.21649 15.3025 7.20662 15.2737 7.03527C15.1679 6.404 14.8728 5.51601 14.5045 4.72056C14.493 4.69591 14.4822 4.67248 14.4718 4.65017C14.3403 4.36703 14.2933 4.26578 14.3142 4.1818C14.3259 4.13505 14.3586 4.09365 14.4094 4.02921C14.4167 4.02005 14.4243 4.01044 14.4322 4.00027C14.5077 3.90379 14.6159 3.73277 14.6725 3.6202C14.685 3.59555 14.7192 3.59357 14.7344 3.61662C15.4064 4.63542 15.8515 5.81613 15.9984 7.08739Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M8.83406 0.468517C8.99646 0.223101 9.2853 0.0942427 9.57464 0.151018C11.1551 0.461145 12.5693 1.23316 13.67 2.32021C13.9 2.54735 13.9653 2.8909 13.8051 3.17137C13.7511 3.26577 13.6976 3.34891 13.6549 3.39973C13.5794 3.48968 13.4526 3.51753 13.3461 3.46752L13.0739 3.33959C12.5818 3.10834 12.1037 2.95739 11.3344 2.79047L11.0979 2.73915C10.9293 2.70253 10.7748 2.61796 10.6533 2.49566L10.1375 1.97629C9.67034 1.50593 9.30318 1.18334 9.03991 1.01199L8.77227 0.83778C8.70174 0.791873 8.68219 0.697484 8.72871 0.627477C8.76628 0.570949 8.8013 0.518029 8.83406 0.468517Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M8.4348 1.37829C8.94109 1.76073 10.0823 2.87001 10.0809 2.97839C10.0788 3.14243 8.40684 5.28827 8.27834 5.29179C8.24751 5.29265 7.96743 5.21986 7.65595 5.13001C7.34442 5.04022 6.72332 4.90853 6.27568 4.83742C5.82803 4.7663 5.41486 4.69895 5.35751 4.68773C4.99882 4.61753 4.65018 2.34987 4.96176 2.11356C5.38264 1.79429 6.50074 1.32129 7.2332 1.15262C7.8948 1.00029 7.94747 1.0102 8.4348 1.37829Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.3759 3.94452C12.6282 4.02314 13.0071 4.15926 13.2177 4.24703L13.2909 4.27751C13.4906 4.36069 13.6533 4.51336 13.7486 4.70712L13.8484 4.91012C14.1139 5.44991 14.4099 6.29572 14.5841 7.01172L14.6202 7.16026C14.6659 7.34807 14.633 7.54633 14.5292 7.70953C14.321 8.03674 13.8125 8.67311 13.3489 9.18665L13.213 9.33713C13.0698 9.49567 12.8596 9.57696 12.6467 9.55613C11.2557 9.42012 10.2076 9.10143 10.1252 8.78938C9.98345 8.25268 9.55965 7.0786 9.30303 6.51156L9.22251 6.33363C9.09627 6.05467 9.14415 5.72798 9.34517 5.49671C9.52652 5.28808 9.73378 5.0432 9.80574 4.95259C10.33 4.29215 10.4558 4.12541 10.6436 3.84168C10.7703 3.65035 11.0053 3.56098 11.2275 3.61962L11.3863 3.66151C11.6782 3.73854 12.1235 3.86589 12.3759 3.94452Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M6.13205 5.60663C6.63777 5.69584 7.91391 6.00746 8.03561 6.07144C8.23401 6.17578 8.85003 7.63047 9.17406 8.75996L9.28774 9.15611C9.33383 9.31674 9.30501 9.48952 9.20925 9.62662C8.7075 10.3452 7.551 11.6609 7.30454 11.7935C7.13008 11.8874 5.44909 11.6256 4.57607 11.3686L4.49769 11.3455C4.2578 11.2749 4.05861 11.1072 3.94857 10.8832L3.87406 10.7316C3.58072 10.1345 3.09038 8.83146 2.97227 8.33511C2.91879 8.11041 2.96235 7.87365 3.09234 7.68251L3.10694 7.66104C3.47003 7.12713 3.987 6.50078 4.49856 5.975L4.73231 5.73475C4.89118 5.57145 5.11455 5.48687 5.34209 5.50383C5.53958 5.51856 5.89506 5.56483 6.13205 5.60663Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M10.3382 10.1304C10.8454 10.2847 11.8243 10.48 12.3033 10.5225C12.5153 10.5413 12.6753 10.7223 12.6673 10.9343L12.6514 11.3552C12.6207 12.1685 12.4712 13.3577 12.3894 13.44C12.3172 13.5126 11.1785 13.9398 10.3452 14.207L10.1046 14.2841C9.78393 14.3869 9.43257 14.2956 9.20307 14.0498C8.79512 13.6128 8.41814 13.0961 8.16554 12.6278C8.06985 12.4503 8.09861 12.2318 8.23696 12.085L8.40603 11.9056C8.83351 11.4519 9.41157 10.7394 9.71874 10.2876C9.82187 10.1359 9.91571 10.0118 9.92729 10.0118C9.93883 10.0118 10.1238 10.0652 10.3382 10.1304Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
    </>
  )
}

export const IconSportHandball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC } from 'react'
import { Event } from 'betweb-openapi-axios'
import dayjs from 'dayjs'

import { Typography } from 'shared/ui/typography'

import { LineAdditionalContentWrapper } from './search-event-item.styled'

interface Props {
  event: Event
}

export const LineInfo: FC<Props> = ({ event }) => {
  return (
    <LineAdditionalContentWrapper>
      <Typography color="text-primary-2" font="Hint / 10 Bold" lineClamp={1}>
        {dayjs(event.eventDate).format('HH:mm')}
      </Typography>
      <Typography color="text-secondary-3" font="Hint / 10 Bold" lineClamp={1}>
        {dayjs(event.eventDate).format('DD MMMM')}
      </Typography>
      <Typography color="text-secondary-3" font="Hint / 10 SemiBold">
        •
      </Typography>
      <Typography
        color="text-secondary-2"
        font="Hint / 10 SemiBold"
        lineClamp={1}
      >
        {event.tournament.name}
      </Typography>
    </LineAdditionalContentWrapper>
  )
}

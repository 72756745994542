import { FC, useMemo } from 'react'
import { EventStatus, ParameterType } from 'betweb-openapi-axios'
import { selectEventById } from 'astra-core/containers/EventsProvider'

import { useEventMode } from 'entities/event-probability/lib/odd-mode'
import {
  PROBABILITY_LABEL_TEXT,
  PROBABILITY_ODD_TEXT
} from 'features/probability-cell/lib/probability-cell-style'
import { OddModes } from 'entities/event-probability/model/odd-mode'
import { useGetEventProbability } from 'entities/event-probability/lib/main-outcome-probability'
import { useHandleAddCoefficientToBasket } from 'entities/event-probability'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { HotProbabilityCellProps } from './hot-probabilities.types'
import * as S from './hot-probability-cell.styled'

export const HotProbabilityCell: FC<HotProbabilityCellProps> = ({
  eventId,
  label,
  mainOutcomeTypes,
  withParameter,
  withParameterSign
}) => {
  const event = useAppSelector((state) => selectEventById(state, eventId))
  const { eventProbability } = useGetEventProbability({
    event,
    outcomeTypesName: mainOutcomeTypes
  })

  const parameterValue = useMemo(() => {
    const parameter = eventProbability?.parameters.find(
      (param) => param.type === ParameterType.Value
    )
    let parameterValue = `${parameter?.value}`

    if (!parameter || !withParameter) {
      return null
    }

    if (!withParameterSign) {
      return +parameterValue
    }

    if (+parameter.value > 0) {
      parameterValue = `+${+parameterValue}`
    } else {
      parameterValue = `${+parameterValue}`
    }

    return parameterValue
  }, [eventProbability?.parameters, withParameter, withParameterSign])

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket({
    eventStatus: event.status,
    eventProbability,
    eventId
  })
  const { mode, isInBasket } = useEventMode({ eventProbability, eventId })

  return (
    <S.HotProbabilityCellWrapper
      isEmpty={!eventProbability?.odd || event.status === EventStatus.Suspended}
      isInBasket={isInBasket}
      mode={mode}
      onClick={handleAddOutcomeToBasket}
    >
      <S.HotProbabilityCellLabel className={PROBABILITY_LABEL_TEXT}>
        {label} {parameterValue}
      </S.HotProbabilityCellLabel>
      <S.HotProbabilityCellOdd className={PROBABILITY_ODD_TEXT}>
        {eventProbability?.odd.toFixed(2) ?? '–'}
      </S.HotProbabilityCellOdd>
      {mode === OddModes.Increase && <S.IncreaseIcon size={8} />}
      {mode === OddModes.Decrease && <S.DecreaseIcon size={8} />}
    </S.HotProbabilityCellWrapper>
  )
}

import {
  CategoryMarketInfo,
  Event,
  MarketCategory,
  EventProbability,
  OutcomeType,
  Competitor
} from 'betweb-openapi-axios'
import { EventProbabilityOutcome } from 'astra-core/utils/outcomes/types'
import { MarketCategoriesInfosByEventItem } from 'astra-core/containers/EventsProvider'

import { TScrollValues } from 'widgets/Event/Event.types'

export interface PanelHandle {
  open: () => void
  close: () => void
}

export interface PanelProps {
  title?: string
}

export enum OutcomesComponentType {
  EVENT_PAGE = 'event page',
  EVENTS_LIST = 'events list'
}

export interface OutcomesProps {
  eventId: Event['id']
  type: OutcomesComponentType
  isLoading?: boolean
  outcomesRef?: React.RefObject<OutcomeCategoriesHandle>
  onScrollCallback?: (values: TScrollValues) => void
}

export interface OutcomeCategoriesHandle {
  openAll: () => void
  closeAll: () => void
}

export interface OutcomesCategoriesProps {
  eventId: Event['id']
  columnsCount?: number
}

export interface MemoizedMasonryProps {
  eventId: Event['id']
  eventMarketCategoriesInfos: Array<MarketCategoriesInfosByEventItem>
  columnsCount: number
}

export interface OutcomesCategoryProps {
  eventId: Event['id']
  marketCategoryId: MarketCategory['id']
  marketCategoryName: MarketCategory['name']
  categoryMarketInfos: Array<CategoryMarketInfo>
}

export interface TemplatedOutcomeProps {
  outcomes: Array<EventProbabilityOutcome>
  eventId: Event['id']
  categoryMarketInfo: CategoryMarketInfo
  isFirst: boolean
  isMerged: boolean
  isLast: boolean
  homeCompetitors: Array<Competitor>
  awayCompetitors: Array<Competitor>
}

export interface OutcomesTableProps {
  eventId: Event['id']
  categoryMarketInfo: CategoryMarketInfo
  isFirst: boolean
  isLast: boolean
  isMerged: boolean
  nextIsMerged: boolean
}

export interface OutcomeOddProps {
  eventProbability: EventProbability & { eventId: Event['id'] } & {
    outcomeTypeData: OutcomeType
  } & {
    labelInButton?: boolean
  }

  withParam?: boolean
}

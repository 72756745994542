import { EBetTypes } from 'astra-core/containers/BasketProvider'
import React, { FC, ReactElement } from 'react'

import { BasketFooter } from '../../footer'
import { TabsCombinations } from '../tabs-combinations'

export const FooterSystem: FC<{ InputBetSum: ReactElement }> = ({
  InputBetSum
}) => (
  <BasketFooter betType={EBetTypes.System}>
    <TabsCombinations />

    {InputBetSum}
  </BasketFooter>
)

import { PropsWithChildren, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ESelectView,
  OptionType,
  SelectProps
} from 'shared/ui/select/select.types'
import {
  DropdownContainer,
  DropdownItem,
  SelectWrapper,
  StyledLabelWrapper
} from 'shared/ui/select/select.styled'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { useClickOutside } from 'shared/lib/click-outside/click-outside'

import { IconCheckOutlineBig16 } from '../Icon/General/IconCheckOutlineBig16'
import { Typography } from '../typography'

export const Select = <IDType,>({
  options,
  value,
  placeholder,
  label,
  onChange,
  isDisabled = false,
  leftIcon,
  className,
  view = ESelectView.PRIMARY
}: PropsWithChildren<SelectProps<IDType>>) => {
  const [t] = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const iconColor = isDisabled ? 'icon-opacity' : 'icon-secondary-2'

  const handleChange = (value: OptionType<IDType>) => () => onChange(value)
  const handleClick = () => setIsOpen(!isOpen)

  const handleClickOutside = useCallback(() => setIsOpen(false), [])
  const ref = useClickOutside<HTMLDivElement>(handleClickOutside)
  const labelColor = isDisabled ? 'text-disabled' : 'text-secondary-3'
  const valueColor = isDisabled ? 'text-disabled' : 'text-primary-2'

  return (
    <SelectWrapper
      className={className}
      isActive={isOpen}
      isDisabled={isDisabled}
      leftIcon={leftIcon}
      ref={ref}
      view={view}
      withLabel={!!label}
      onClick={handleClick}
    >
      {leftIcon}

      {label ? (
        <StyledLabelWrapper>
          <Typography color={labelColor} font="Hint / 11 Medium">
            {t(label)}
          </Typography>

          <Typography color={valueColor} font="Body / 14 Medium">
            {value ? value.label : placeholder}
          </Typography>
        </StyledLabelWrapper>
      ) : (
        <Typography color={valueColor}>
          {value ? value.label : placeholder}
        </Typography>
      )}

      <IconChevronDown
        colorToken={iconColor}
        size={16}
        twist={isOpen ? 180 : 0}
      />

      {isOpen && (
        <DropdownContainer>
          {options.map((option) => {
            const isShownIcon = option.label === value?.label

            return (
              <DropdownItem
                active={option.label === value?.label}
                key={option.value as string}
                onClick={handleChange(option)}
              >
                {option.label}
                {isShownIcon && (
                  <IconCheckOutlineBig16 colorToken="icon-secondary-2" />
                )}
              </DropdownItem>
            )
          })}
        </DropdownContainer>
      )}
    </SelectWrapper>
  )
}

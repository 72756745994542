import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  selectFilterIsTop,
  selectLayoutIsLoading
} from 'entities/line-tournaments/model/selectors'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import { useIsEventListHasTop } from 'entities/event'
import { useTournamentsGroupedBySubSport } from 'entities/line-tournaments/model/hooks'
import { LineTournamentsParams } from 'entities/line-tournaments/model/types'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const LineTournamentsTopFilter = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isTop = useAppSelector(selectFilterIsTop)
  const layoutIsLoading = useAppSelector(selectLayoutIsLoading)
  const { sportId } = useParams<LineTournamentsParams>()

  const { groupedTournaments } = useTournamentsGroupedBySubSport({
    sportId: +sportId
  })

  const lineTournaments = useMemo(() => {
    return groupedTournaments[0]?.tournaments || []
  }, [groupedTournaments])

  const { isEventListHasTop } = useIsEventListHasTop(lineTournaments)

  const toggleFilterTop = useCallback(
    () => dispatch(lineTournamentsContainerActions.toggleFilterIsTop()),
    [dispatch]
  )

  return (
    <Button
      disabled={!isEventListHasTop || layoutIsLoading}
      icon={IconTop}
      isActive={isTop}
      view="filter"
      onClick={toggleFilterTop}
    >
      {t('only top')}
    </Button>
  )
}

import { getEntityTagName, selectEntityTags } from 'entities/event'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const useGetEntityTagName = (
  props: Pick<Parameters<typeof getEntityTagName>[0], 'tags'>
) => {
  const entityTags = useAppSelector(selectEntityTags)

  return getEntityTagName(props, entityTags)
}

import styled, { css } from 'styled-components'
import { BetStatus } from 'betweb-openapi-axios'

import { typography } from 'shared/lib/theme/typography'
import { StyledTooltipContext } from 'shared/ui/Tooltip/basket-tooltip/BasketTooltipContext.styled'
import { BetHistoryTag } from 'features/bet-history-card/bet-history-tag'

export const StyledBasketHistoryItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledBetHistoryTag = styled(BetHistoryTag)`
  ${({ theme, status }) => css`
    ${status === BetStatus.Sold &&
    css`
      background-color: #0d80f2;
      color: ${theme.tokens.colors.text['text-invert']};
    `}
  `}
`

export const StyledBasketHistoryItemWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-l-12'
    ]}px;
    display: flex;
    flex-direction: column;
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;
  `}
`

export const StyledBasketHistoryItemMain = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
`

export const StyledBasketHistoryItemRow = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    gap: 4px;
    justify-content: space-between;
  `}
`

export const StyledBasketHistoryTextCoupon = styled.div`
  ${typography['Body / 12 Medium']}
  align-items: center;
  color: #7845e7;
  display: flex;
  gap: 4px;
`

export const StyledBasketHistorySumCoupon = styled.div`
  ${typography['Body / 12 Medium']}
  color: #7845e7;
`

export const StyledBasketHistoryTextBonus = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;

  & svg {
    height: 12px !important;
    width: 12px !important;
  }
`

export const StyledBasketHistoryTextSecondary = styled.div`
  ${typography['Body / 12 Medium']}
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-secondary-2']};
    display: flex;
    gap: 4px;

    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-3']};
      height: 10.67px;
      width: 10.67px;
    }
  `}
`

export const StyledBasketHistoryBonusWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const StyledBasketHistoryOutcomes = styled.div`
  display: flex;
  flex-direction: column;

  ${StyledTooltipContext} {
    gap: unset;
  }
`

export const StyledBasketHistoryItemBottom = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 6px;
  margin: 0 12px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    border-top: none;
  `}
`

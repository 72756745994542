import styled, { css } from 'styled-components'

import { BASKET_CONTAINER_MIN_HEIGHT } from 'entities/basket/lib'
import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'

export const StyledEmptyBasketWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${BASKET_CONTAINER_MIN_HEIGHT}px;
  justify-content: center;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
    padding: 0px ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledEmptyBasketStatus = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`
export const StyledEmptyBasketContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledEmptyBasketIcon = styled.div`
  font-size: 0px;
`

export const StyledEmptyBasketTitle = styled.div`
  ${typography['Body / 16 SemiBold']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledEmptyBasketText = styled.div`
  ${typography['Body / 14 SemiBold']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    text-align: center;
  `}
`

export const StyledEmptyBasketTextContainer = styled.div`
  max-width: 200px;
`

export const StyledLink = styled(AppLink)`
  color: ${({ theme }) => theme.tokens.colors.text['text-linkAccent-default']};

  &:hover {
    color: ${({ theme }) => theme.tokens.colors.text['text-linkAccent-hover']};
  }
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.regular};
    line-height: 20px;
  `}
`

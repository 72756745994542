import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M20.9336 17.0704L12.0006 22.1409L3.06766 17.0704V7.00277L12.0006 1.9323L20.9336 7.00277V17.0704Z"
        fill="#418AF2"
      />
      <path
        d="M12.0009 1L2.00085 6.47905V17.479L12.0009 23L22.0009 17.479V6.47905L12.0009 1Z"
        fill="#36AEEA"
      />
      <path
        d="M3.87089 11.141C4.51969 5.91862 9.17384 4.50434 9.17384 4.50434C2.954 10.9943 10.3964 15.6143 10.3964 15.6143L5.35615 16.6934C5.39368 16.6986 3.6028 14.6086 3.87089 11.141Z"
        fill="white"
      />
      <path
        d="M15.206 19.4224C9.86016 21.3238 6.73415 18.0028 6.73415 18.0028C15.4741 20.1662 16.0854 11.5128 16.0854 11.5128L19.4419 15.279C19.4419 15.279 18.4875 17.9662 15.206 19.4224Z"
        fill="white"
      />
      <path
        d="M19.9794 13.4928C17.3092 4.95467 9.78641 9.01943 9.78641 9.01943L11.3896 4.05896C11.3896 4.05896 14.5961 3.65039 17.4969 6.07039C21.0465 9.09277 19.9794 13.4928 19.9794 13.4928Z"
        fill="white"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M14.4975 11.6876L8.00067 15.3752L1.5039 11.6876V4.36557L8.00067 0.677919L14.4975 4.36557V11.6876Z"
        fill="#418AF2"
      />
      <path
        d="M8.00083 -0.00012207L0.728027 3.98468V11.9848L8.00083 16L15.2736 11.9848V3.98468L8.00083 -0.00012207Z"
        fill="#36AEEA"
      />
      <path
        d="M2.08807 7.37523C2.55992 3.57709 5.9448 2.54851 5.9448 2.54851C1.42123 7.26856 6.83391 10.6286 6.83391 10.6286L3.16827 11.4134C3.19556 11.4172 1.89309 9.89716 2.08807 7.37523Z"
        fill="white"
      />
      <path
        d="M10.3319 13.3981C6.44394 14.781 4.17046 12.3657 4.17046 12.3657C10.5268 13.9391 10.9714 7.64566 10.9714 7.64566L13.4126 10.3847C13.4126 10.3847 12.7184 12.339 10.3319 13.3981Z"
        fill="white"
      />
      <path
        d="M13.8035 9.08562C11.8615 2.87603 6.39031 5.83225 6.39031 5.83225L7.5563 2.2246C7.5563 2.2246 9.88828 1.92745 11.998 3.68747C14.5795 5.88559 13.8035 9.08562 13.8035 9.08562Z"
        fill="white"
      />
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        clipRule="evenodd"
        d="M12.0017 1.00012L2.00171 6.47912V17.479L12.0017 22.9999L22.0018 17.479V6.47912L12.0017 1.00012ZM9.80048 8.99803C9.80048 8.99803 17.3233 4.93331 19.9935 13.4713C19.9935 13.4713 21.0606 9.07136 17.511 6.04901C14.6101 3.62903 11.4037 4.0376 11.4037 4.0376L9.80048 8.99803ZM15.2199 19.4009C9.87405 21.3023 6.74804 17.9814 6.74804 17.9814C15.488 20.1447 16.0993 11.4914 16.0993 11.4914L19.4558 15.2576C19.4558 15.2576 18.5014 17.9447 15.2199 19.4009ZM9.57703 4.63093C9.57703 4.63093 5.13799 5.67764 4.11175 11.2579C3.7321 14.8279 5.58203 16.998 5.58203 16.998L10.5219 15.9742C10.5219 15.9742 3.33354 11.1182 9.57703 4.63093Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  s: () => (
    <>
      <path
        clipRule="evenodd"
        d="M8.00182 -0.000976562L0.729004 3.98379V11.9838L8.00182 15.999L15.2746 11.9838V3.98379L8.00182 -0.000976562ZM6.40089 5.81574C6.40089 5.81574 11.8721 2.85955 13.8141 9.06907C13.8141 9.06907 14.5901 5.86907 12.0086 3.67098C9.89886 1.91098 7.56688 2.20812 7.56688 2.20812L6.40089 5.81574ZM10.3423 13.3815C6.4544 14.7644 4.18091 12.3491 4.18091 12.3491C10.5373 13.9225 10.9819 7.62913 10.9819 7.62913L13.423 10.3682C13.423 10.3682 12.7289 12.3225 10.3423 13.3815ZM6.23838 2.63964C6.23838 2.63964 3.00996 3.40089 2.26359 7.45933C1.98748 10.0557 3.33289 11.6339 3.33289 11.6339L6.92559 10.8894C6.92559 10.8894 1.69762 7.35771 6.23838 2.63964Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  )
}

export const IconCybersportHeroesOfTheStorm: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

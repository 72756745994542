import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { css } from 'styled-components'

import { MAIN_HEADER_HEIGHT } from 'shared/lib/layout'
import { typography } from 'shared/lib/theme/typography'

export const SettingsWindow = styled.div`
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
`

export const StyledSettingsList = styled.div<{ isAuthorized: boolean }>`
  ${({ theme, isAuthorized }) => css`
    background-color: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;

    box-shadow: ${theme.typeLayoutTheme === LayoutTheme.Dark
      ? '0px 6px 7px -4px #00000033, 0px 11px 15px 1px #00000024, 0px 4px 20px 3px #0000001f'
      : '0px 5px 5px -3px #6c799333, 0px 8px 10px 1px #6c799324, 0px 3px 14px 2px #6c79931f'};
    outline: 1px solid ${theme.tokens.colors.border['border-primary']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-l-20']}px;
    position: fixed;
    right: ${isAuthorized ? '56px' : '58px'};
    top: ${MAIN_HEADER_HEIGHT - 4}px;
    width: 300px;
    z-index: 9999;
  `}
`

export const StyledSettingsListTitle = styled.h3`
  ${typography['Body / 16 Bold']};
  ${({ theme }) => css`
    align-items: flex-start;
    color: ${theme.tokens.colors.text['text-primary']};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledSettingsListItems = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { selectCurrencyMinBet } from 'astra-core/containers/CommonDataProvider'
import { useGetCurrencyIcon } from 'astra-core'
import { useTranslation } from 'react-i18next'
import { useIMask } from 'react-imask'
import {
  basketProviderActions,
  selectBasketAppliedCoupon,
  selectBasketBonusWallet
} from 'astra-core/containers/BasketProvider'
import { selectLoyaltyById } from 'astra-core/containers/AccountProvider'

import { TextInput } from 'shared/ui/text-input'
import { Typography } from 'shared/ui/typography'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { RootState } from 'shared/types/store'
import { Alert, EAlertViev } from 'shared/ui/alert'
import { TagMax } from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledAlertWraper,
  StyledCouponSum,
  StyledIconWrapper,
  StyledMaxSum,
  StyledWinSum,
  StyledWinSumContainer
} from './input-bet-sum.styled'
import { ISumInput } from './input-bet-sum.types'
import useMaxWinSum from './lib/useMaxWinSum'

export const InputBetSum: FC<ISumInput> = memo(
  ({
    winSum,
    defaultValue,
    onChange,
    background = 'white',
    errorMessage,
    size,
    maxValue,
    disabled,
    isOrdinar,
    onKeyDown
  }) => {
    const [t] = useTranslation()
    const dispatch = useAppDispatch()
    const currencyMinBet = useAppSelector(selectCurrencyMinBet)
    const bonusWallet = useAppSelector(selectBasketBonusWallet)
    const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)
    const appliedCouponData = useAppSelector((state: RootState) =>
      selectLoyaltyById(state, appliedCoupon || '')
    )
    const currencyIcon = useGetCurrencyIcon()

    const [focused, setFocused] = useState(false)

    const { ref, unmaskedValue } = useIMask({
      mask: 'num',
      radix: ',',
      blocks: {
        num: {
          mask: Number,
          thousandsSeparator: ' '
        }
      }
    })

    useEffect(() => {
      if ((unmaskedValue || bonusWallet) && appliedCoupon) {
        dispatch(basketProviderActions.setAppliedCoupon(null))
      }
    }, [unmaskedValue, bonusWallet]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (appliedCouponData) {
        ref.current.value = ''
      }
    }, [appliedCouponData]) // eslint-disable-line react-hooks/exhaustive-deps

    const { maxSum, showMaxTag } = useMaxWinSum(winSum, unmaskedValue)

    const errorMessageCustom = useMemo(() => {
      if (
        errorMessage === 'amount must exceed min bet' ||
        errorMessage === 'exceeded maximum rate'
      ) {
        return `${t(errorMessage)}. ${`${formatMonetaryAmount(
          currencyMinBet
        )}-${formatMonetaryAmount(maxValue)}`}`
      }

      return errorMessage
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, currencyMinBet, maxValue, formatMonetaryAmount])

    const handleInput = useCallback(
      () => onChange(unmaskedValue),
      [unmaskedValue, onChange]
    )

    return (
      <>
        <TextInput
          background={
            bonusWallet ? 'yellow' : appliedCouponData ? 'purple' : background
          }
          callbackOnClick={() => {
            setFocused(true)
          }}
          endAdornment={
            unmaskedValue || appliedCouponData ? (
              <StyledWinSum isCouponApplied={!!appliedCouponData}>
                {!isOrdinar && (
                  <Typography color="text-secondary-3" font="Hint / 11 Medium">
                    {t('possible gain short')}
                  </Typography>
                )}

                <StyledWinSumContainer>
                  <StyledMaxSum>
                    {isOrdinar && '->'}

                    {appliedCouponData ? (
                      <StyledCouponSum>
                        {formatMonetaryAmount(appliedCouponData?.coupon.amount)}
                      </StyledCouponSum>
                    ) : (
                      formatMonetaryAmount(+maxSum)
                    )}
                  </StyledMaxSum>

                  {showMaxTag && (
                    <TagMax
                      tooltipText={t('maximum possible winning amount')}
                    />
                  )}
                </StyledWinSumContainer>
              </StyledWinSum>
            ) : null
          }
          placeholder={
            appliedCouponData
              ? ''
              : `${formatMonetaryAmount(currencyMinBet) || '20'}-${
                  formatMonetaryAmount(maxValue) || '40 000 000'
                }`
          }
          startAdornment={
            appliedCouponData ? (
              <></>
            ) : (
              <StyledIconWrapper>
                {bonusWallet ? (
                  <IconBonus size={12} />
                ) : (
                  <Typography color="text-primary-2" font="Body / 14 Medium">
                    {currencyIcon}
                  </Typography>
                )}
              </StyledIconWrapper>
            )
          }
          defaultValue={defaultValue}
          disabled={disabled}
          errorMessage={errorMessageCustom}
          ref={ref}
          size={size}
          touched
          onBlur={(e) => {
            e.stopPropagation()
            setFocused(false)
          }}
          onInput={handleInput}
          onKeyDown={onKeyDown}
        />

        {focused && appliedCoupon && (
          <StyledAlertWraper>
            <Alert size={isOrdinar ? 's' : 'm'} view={EAlertViev.INFO}>
              {t(
                'when you enter your bet amount, your bonus coupon will be reset'
              )}
            </Alert>
          </StyledAlertWraper>
        )}
      </>
    )
  }
)

import {
  formatAmountLocaleString,
  getBonusCouponPossibleWinSum,
  getCommonPossibleWinSum,
  getIsHideAmountForTypes
} from 'astra-core'
import {
  ECoefficientType,
  ICountPossibleWinSum,
  IGetBetAmountFormatted
} from 'astra-core/utils/bets/types'
import { Bet, BetStatus, BetType } from 'betweb-openapi-axios'

import {
  getBetStatusFromLegacy,
  getBetTypeFromLegacy
} from '../../legacy-api/lib/utils'

import {
  ICountPossibleWinSumLegacy,
  IGetLegacyBetAmountFormatted
} from './types'

import { DISABLED_STATUS } from '.'

export const getExpressBonusSum = (bet: Bet) => {
  const expressBonus = +(
    ((bet.coefMultiplier ?? 1) - 1) *
    bet.bet *
    (bet.coefficient ?? 1)
  ).toFixed(2)

  return expressBonus
}

export const getBetBonusAmountFormatted = ({
  bet,
  couponLoyalty,
  currencyIcon,
  loyaltyCurrencyRestriction
}: IGetBetAmountFormatted) => {
  const isHideAmountForTypes = getIsHideAmountForTypes(bet)
  const expressBonus = DISABLED_STATUS.includes(bet.status)
    ? 0
    : getExpressBonusSum(bet)

  if (isHideAmountForTypes[bet.betType]) {
    return null
  } else {
    return [BetStatus.Current].includes(bet.status)
      ? countPossibleWinSumBonus({
          couponLoyalty,
          bet,
          currencyIcon,
          loyaltyCurrencyRestriction,
          expressBonus
        })
      : formatAmountLocaleString({
          value: bet.amount + expressBonus,
          currency: currencyIcon
        })
  }
}

export const countPossibleWinSumBonus = ({
  couponLoyalty,
  bet,
  currencyIcon,
  loyaltyCurrencyRestriction,
  expressBonus
}: ICountPossibleWinSum & { expressBonus: number }) => {
  const coefficientValue = bet.coefficient!
  const isBonusWallet = bet.bonusWallet

  const COEFFICIENTS = {
    [ECoefficientType.BONUS_WALLET]:
      isBonusWallet && coefficientValue > 0
        ? coefficientValue - 1
        : coefficientValue,
    [ECoefficientType.DEFAULT]: coefficientValue
  }

  const resultCoefficientValue =
    COEFFICIENTS[
      isBonusWallet ? ECoefficientType.BONUS_WALLET : ECoefficientType.DEFAULT
    ]

  const amount =
    bet?.appliedCouponId && couponLoyalty
      ? getBonusCouponPossibleWinSum(
          bet.bet,
          resultCoefficientValue,
          loyaltyCurrencyRestriction
        )[couponLoyalty.coupon.payout_type] + expressBonus
      : getCommonPossibleWinSum({
          coefficient: resultCoefficientValue,
          bet: bet.bet
        }) + expressBonus

  return formatAmountLocaleString({ value: amount, currency: currencyIcon })
}

export const getLegacyBetBonusAmountFormatted = ({
  bet,
  currencyIcon
}: IGetLegacyBetAmountFormatted) => {
  const status = getBetStatusFromLegacy(bet)
  const betType = getBetTypeFromLegacy(bet)
  const expressBonus = DISABLED_STATUS.includes(status) ? 0 : bet.bonus_exp
  const amount = bet.total_bet * bet.final_odd

  if (betType === BetType.System) {
    return null
  } else {
    return [BetStatus.Current].includes(status)
      ? countPossibleWinSumBonusLegacy({
          bet,
          currencyIcon,
          expressBonus
        })
      : formatAmountLocaleString({
          value: amount + expressBonus,
          currency: currencyIcon
        })
  }
}

export const countPossibleWinSumBonusLegacy = ({
  bet,
  currencyIcon,
  expressBonus
}: ICountPossibleWinSumLegacy & { expressBonus: number }) => {
  const coefficientValue = bet.final_odd!
  const isBonusWallet = bet.bonus

  const COEFFICIENTS = {
    [ECoefficientType.BONUS_WALLET]:
      isBonusWallet && coefficientValue > 0
        ? coefficientValue - 1
        : coefficientValue,
    [ECoefficientType.DEFAULT]: coefficientValue
  }

  const resultCoefficientValue =
    COEFFICIENTS[
      isBonusWallet ? ECoefficientType.BONUS_WALLET : ECoefficientType.DEFAULT
    ]

  const amount =
    getCommonPossibleWinSum({
      coefficient: resultCoefficientValue,
      bet: bet.total_bet
    }) + expressBonus

  return formatAmountLocaleString({ value: amount, currency: currencyIcon })
}

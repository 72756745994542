import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M10.6844 2.06646C10.3612 2.18087 10.0263 2.56219 9.93368 2.92103C9.90278 3.04092 9.3005 7.0802 9.21531 7.73882C9.19321 7.90991 9.22349 7.90581 9.81532 7.65743C10.3974 7.41312 10.9429 7.11123 11.4489 6.75333C11.6504 6.61081 11.9143 6.44476 12.0355 6.38432C13.2373 5.78489 14.7364 6.48744 15.151 7.84447C15.2367 8.12494 15.8592 11.9595 15.9952 13.0452C16.0932 13.8269 16.0409 15.3084 15.8884 16.0703L15.8626 16.1996L16.4842 15.8771C19.0759 14.5327 20.4993 12.7888 21.1727 10.133C21.3702 9.35442 22.032 4.86495 21.9988 4.52984C21.9092 3.62746 21.3043 3.31322 20.2742 3.63402C17.4349 4.51831 14.3984 4.08477 11.9756 2.44926C11.2929 1.98843 11.0816 1.92577 10.6844 2.06646ZM12.8425 7.3378C12.821 7.34499 12.7583 7.3607 12.7031 7.37274C12.6479 7.38478 12.3244 7.57937 11.9841 7.80519C9.45258 9.48526 6.57167 9.91098 3.67026 9.03377C2.6919 8.73798 2.09087 9.06684 2.00083 9.94726C1.97239 10.2257 2.68509 14.7928 2.86947 15.5134C3.44007 17.7437 4.82522 19.6627 6.63605 20.7315C9.45035 22.3928 10.1727 22.4101 11.7835 20.855C12.678 19.9914 13.0354 19.6017 13.3817 19.1129C14.4583 17.5931 15.0208 15.4783 14.862 13.5481C14.81 12.9158 14.1022 8.37922 14.0194 8.14734C13.8399 7.64461 13.2139 7.214 12.8425 7.3378ZM11.9445 11.622C12.9013 11.7988 13.4718 12.4897 13.0538 12.9655C12.8137 13.2387 12.5558 13.2396 12.143 12.9686C11.6281 12.6306 11.2099 12.7121 10.8437 13.2215C10.3646 13.8879 9.46944 13.3741 9.83437 12.6422C10.1948 11.9194 11.1236 11.4704 11.9445 11.622ZM6.64293 12.579C6.94059 12.6797 7.41907 13.0125 7.50911 13.1814C7.80701 13.7405 7.15854 14.2935 6.64278 13.9201C6.08614 13.5172 5.6686 13.56 5.26428 14.0616C4.68421 14.7812 3.76879 14.2117 4.26033 13.4371C4.75896 12.6514 5.77491 12.2855 6.64293 12.579ZM11.3931 16.2006C11.4743 16.3165 11.4746 16.8782 11.3936 17.1889C10.8139 19.4129 7.67422 19.4504 7.02961 17.2411C6.88489 16.7452 6.96887 16.6753 7.51854 16.8345C8.68399 17.172 9.98042 16.9521 10.9699 16.2491C11.1907 16.0922 11.3077 16.0788 11.3931 16.2006Z"
      fill={theme.tokens.colors['sport-icon']['sport-culture']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M13.062 1.01298C13.0396 1.01664 12.9752 1.02214 12.9189 1.02522C12.8625 1.02831 12.5124 1.16868 12.1408 1.33718C9.37606 2.59084 6.46664 2.5516 3.74466 1.22395C2.8268 0.77627 2.18156 1.00485 1.95243 1.85889C1.88001 2.12899 1.85509 6.74732 1.9221 7.48752C2.12945 9.77826 3.18984 11.8918 4.80559 13.2347C7.3167 15.3218 8.02647 15.454 9.86305 14.1768C10.883 13.4676 11.2977 13.1402 11.7171 12.7133C13.0212 11.3858 13.9131 9.38954 14.0641 7.46039C14.1136 6.82833 14.1385 2.24094 14.0937 1.99903C13.9968 1.47456 13.448 0.950063 13.062 1.01298ZM11.4934 5.09561C12.4089 5.42244 12.8615 6.19483 12.3734 6.59748C12.0931 6.82869 11.8386 6.78847 11.4746 6.45541C11.0206 6.04 10.5951 6.05366 10.1527 6.49778C9.57395 7.07873 8.7729 6.42924 9.2495 5.76549C9.72017 5.11003 10.7079 4.81517 11.4934 5.09561ZM6.11171 5.19458C6.38925 5.34134 6.80816 5.74582 6.87006 5.92677C7.07477 6.52575 6.34702 6.96781 5.89781 6.51732C5.413 6.03118 4.99433 6.00689 4.51559 6.43716C3.82876 7.05448 3.01659 6.3469 3.62489 5.66118C4.24193 4.96564 5.30232 4.76668 6.11171 5.19458ZM10.2198 9.52379C10.2814 9.65102 10.1922 10.2051 10.0628 10.4986C9.1366 12.5999 6.03376 12.1365 5.75008 9.8546C5.68638 9.34237 5.78034 9.2869 6.29713 9.5315C7.39287 10.0501 8.70665 10.0399 9.79466 9.50412C10.0375 9.38455 10.155 9.39 10.2198 9.52379Z"
      fill={theme.tokens.colors['sport-icon']['sport-culture']}
      fillRule="evenodd"
    />
  )
}

export const IconSportCulture: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

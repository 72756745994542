import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  accountProviderActions,
  TTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import { EAuthPagesAndModal } from 'astra-core/constants/auth'
import {
  useGetPostAuthServiceMessage,
  EPostMessageIdEvent
} from 'astra-core/containers/AuthMessageProvider'

import { SettingsButton } from 'features/settings-button'
import { useHashParam } from 'hooks/useHashParams'
import { Button } from 'shared/ui/Button'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { StyledComponentOfUnauthorized } from './ComponentOfUnauthorized.styled'

export const ComponentOfUnauthorized = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()

  const openAuthModal = useCallback(
    (types: TTypeAuthModal) => () =>
      dispatch(accountProviderActions.setTypeAuthModal(types)),
    [dispatch]
  )

  const { exists: regmeParamExists } = useHashParam('regme')

  useEffect(() => {
    if (regmeParamExists) {
      openAuthModal(EAuthPagesAndModal.SignIn)()
    }
  }, [openAuthModal, regmeParamExists])

  useGetPostAuthServiceMessage(
    EPostMessageIdEvent.open,
    openAuthModal(EAuthPagesAndModal.SignIn)
  )

  useGetPostAuthServiceMessage(
    EPostMessageIdEvent.signUp,
    openAuthModal(EAuthPagesAndModal.FastSignUp)
  )

  useGetPostAuthServiceMessage(
    EPostMessageIdEvent.fullSignUp,
    openAuthModal(EAuthPagesAndModal.FullSignUp)
  )

  return (
    <StyledComponentOfUnauthorized>
      <SettingsButton />
      <Button
        view="secondary-large"
        onClick={openAuthModal(EAuthPagesAndModal.SignIn)}
      >
        {t('log in')}
      </Button>
      <Button onClick={openAuthModal(EAuthPagesAndModal.FastSignUp)}>
        {t('sign up')}
      </Button>
    </StyledComponentOfUnauthorized>
  )
}

import {
  DragDropContext,
  DragStart,
  DragUpdate,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import React, { useState } from 'react'

import { LiveMatchBroadcast } from 'widgets/live-match-broadcast'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  DROPPABLE_PLACEHOLDER_DEFAULT_VALUE,
  getClientNodeValues,
  getDraggedDestinationElementPosition,
  getDraggedDom,
  IDragDropContextEvent
} from '../../lib'

import * as S from './basket-broadcast-event.styled'

const { selectBroadcastsEventIds, selectBroadcastsEventIdsLength } =
  matchBroadcastModel

export const BasketBroadcastEvent = () => {
  const dispatch = useAppDispatch()
  const [placeholderProps, setPlaceholderProps] =
    useState<IDragDropContextEvent>(DROPPABLE_PLACEHOLDER_DEFAULT_VALUE)
  const broadcastsEventIds = useAppSelector(selectBroadcastsEventIds)
  const broadcastsEventIdsLength = useAppSelector(
    selectBroadcastsEventIdsLength
  )

  const onDragEnd = (result: DropResult) => {
    setPlaceholderProps(DROPPABLE_PLACEHOLDER_DEFAULT_VALUE)

    if (!result.destination) return

    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.updateBroadcastList({
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index
      })
    )
  }

  const handleDragStart = (event: DragStart) => {
    const draggedDOM = getDraggedDom(event.draggableId)

    if (!draggedDOM) {
      return
    }

    const parentNode = draggedDOM.parentNode as Element
    const childrenArray = Array.from(parentNode.children)
    const sourceIndex = event.source.index

    const values = getClientNodeValues({
      draggedDOM,
      parentNode,
      childrenArray,
      index: sourceIndex
    })

    setPlaceholderProps({
      ...values,
      destinationIndex: sourceIndex
    })
  }

  const handleDragUpdate = (event: DragUpdate) => {
    const draggedDOM = getDraggedDom(event.draggableId)

    if (!event.destination || !draggedDOM) {
      return
    }

    const parentNode = draggedDOM.parentNode as Element
    const childrenArray = Array.from(parentNode.children)
    const destinationIndex = event.destination.index

    const sourceIndex = event.source.index
    const movedItem = childrenArray[sourceIndex]
    childrenArray.splice(sourceIndex, 1)

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1)
    ]

    const values = getClientNodeValues({
      draggedDOM,
      parentNode,
      childrenArray: updatedArray,
      index: destinationIndex
    })

    setPlaceholderProps({
      ...values,
      destinationIndex
    })
  }

  return broadcastsEventIdsLength ? (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId="numberList">
        {(provided, snapshot) => (
          <S.StyledLiveMatchBroadcastWrapper
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {broadcastsEventIds.map((broadcastId, index) => (
              // TODO: remove LiveMatchBroadcast. Reason: feature can't use widget directly
              <LiveMatchBroadcast
                broadcastId={broadcastId}
                index={index}
                key={broadcastId}
              />
            ))}

            {provided.placeholder}

            {snapshot.isDraggingOver && (
              <S.StyledDraggedDestinationElement
                destinationIndex={placeholderProps.destinationIndex}
                style={getDraggedDestinationElementPosition(placeholderProps)}
              />
            )}
          </S.StyledLiveMatchBroadcastWrapper>
        )}
      </Droppable>
    </DragDropContext>
  ) : null
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { SportIcon } from 'shared/ui/Icon/sport-icon'

export const StyledBasketHistoryOutcome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;

  ${({ theme }) => css`
    box-shadow: inset 0px 1px 0px 0px
      ${theme.tokens.colors.border['border-default-gray']};
  `}
`

export const StyledBasketHistoryOutcomeTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
  `}
`

export const StyledSportIcon = styled(SportIcon)`
  width: 12px;
`

export const StyledBasketHistoryOutcomeTitleText = styled.div`
  ${typography['Hint / 11 SemiBold']}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const StyledBasketHistoryOutcomeName = styled.span`
  ${typography['Body / 12 SemiBold']}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledBasketHistoryOutcomeCompetitors = styled.span`
  ${typography['Body / 12 SemiBold']}
  -moz-box-orient: vertical;
  -moz-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: box;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledBasketHistoryOutcomeEvent = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledBasketHistoryOutcomeCoefficient = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
`

export const StyledBasketHistoryOutcomeCoefficientValue = styled.div`
  ${typography['Body / 12 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

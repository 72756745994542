import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'

export const StyledTournamentLeft = styled.div`
  ${typography['Body / 14 Medium']};
  align-items: flex-start;
  flex-grow: 1;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const TopTagWrapper = styled.div`
  display: flex;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const StyledTournamentText = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledTournamentAdditional = styled.span`
  ${typography['Body / 14 Regular']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const StyledTournament = styled(AppLink)<{
  entityTagColor: string
  bgImageUrl: string
}>`
  display: flex;
  gap: 8px;
  transition: background-color 0.25s ease;

  ${({ theme, entityTagColor, bgImageUrl }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px ${theme.tokens.colors.divider['divider-primary']};
    padding: 10px ${theme.tokens.alias.space['space-m-16']}px;

    ${entityTagColor &&
    css`
      background-image: linear-gradient(
          90deg,
          ${entityTagColor}1F 0%,
          rgba(13, 128, 242, 0) 100%
        ),
        url(${bgImageUrl});
      background-repeat: no-repeat;
      background-size: 400px auto, contain;

      &:hover {
        background-image: linear-gradient(
            90deg,
            ${entityTagColor}26 0%,
            rgba(13, 128, 242, 0) 100%
          ),
          url(${bgImageUrl});
      }
    `}
  `}
`

export const StyledLoadingIndicatorWrapper = styled.div`
  padding-top: 25%;
`

export const StyledFavoriteWrapper = styled.div`
  align-items: flex-start;
  padding-top: 2px;
`

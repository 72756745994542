import { FC } from 'react'

import { OddModes } from '../../model'

import * as S from './trend-mode-icon.styled'

export const TrendModeIcon: FC<{
  trendMode: OddModes
}> = ({ trendMode }) => {
  const dic = {
    [OddModes.Increase]: <S.IncreaseIcon size={8} />,
    [OddModes.Decrease]: <S.DecreaseIcon size={8} />
  }

  return dic[trendMode] || null
}

import {
  selectInputSearch,
  selectSearchEventsItems,
  selectSearchTournamentsItems
} from 'astra-core/containers/SearchProvider'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { Typography } from 'shared/ui/typography'
import {
  SearchTournamentItem,
  SearchEventItem,
  PopularQueries,
  Hotkey
} from 'features/global-search'
import { IconChevronRight } from 'shared/ui/Icon/General/IconChevronRight'
import { Scrollable } from 'shared/ui/scrollable'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  AllResultsBottom,
  EmptyWrapper,
  SearchItemBlockTitle,
  SearchItemsBlock,
  SearchValueBlock
} from './search-items.styled'

export const SearchItems = () => {
  const { t } = useTranslation()
  const events = useAppSelector(selectSearchEventsItems)
  const tournaments = useAppSelector(selectSearchTournamentsItems)
  const valueSearch = useAppSelector(selectInputSearch)
  const ref = useRef<HTMLDivElement>(null)

  const liveEvents = useMemo(() => events.filter((e) => e.live), [events])
  const lineEvents = useMemo(() => events.filter((e) => !e.live), [events])

  const totalResult = events.length + tournaments.length

  const handleArrowsClick = useCallback((event) => {
    if (!document.activeElement) return

    const focusables: Element[] = Array.from(
      ref.current?.querySelectorAll('a') ?? []
    )
    const currentFocusIndex = focusables.indexOf(document.activeElement)
    if (event.key === 'ArrowDown') {
      if (currentFocusIndex < focusables.length - 1) {
        const nextElement = focusables[
          currentFocusIndex + 1
        ] as HTMLAnchorElement
        nextElement.focus()
      }
    }
    if (event.key === 'ArrowUp') {
      if (currentFocusIndex > 0) {
        const prevElement = focusables[
          currentFocusIndex - 1
        ] as HTMLAnchorElement
        prevElement.focus()
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleArrowsClick)
    return () => {
      document.removeEventListener('keydown', handleArrowsClick)
    }
  }, [handleArrowsClick])

  if (!totalResult) {
    return (
      <div>
        <EmptyWrapper>
          <Typography font="Body / 16 SemiBold">
            {t('nothing found')}
          </Typography>
          <Typography color="text-secondary-3" font="Body / 14 Medium">
            {t('check if there are any typos or try another query')}
          </Typography>
        </EmptyWrapper>
        <PopularQueries />
      </div>
    )
  }

  return (
    <Scrollable>
      <div ref={ref}>
        <div>
          {!!liveEvents.length && (
            <SearchItemsBlock>
              <SearchItemBlockTitle>
                {t('live')} ({liveEvents.length})
              </SearchItemBlockTitle>
              {liveEvents.map((event) => (
                <SearchEventItem event={event} key={event.id} />
              ))}
            </SearchItemsBlock>
          )}
          {!!lineEvents.length && (
            <SearchItemsBlock>
              <SearchItemBlockTitle>
                {t('line')} ({lineEvents.length})
              </SearchItemBlockTitle>
              {lineEvents.map((event) => (
                <SearchEventItem event={event} key={event.id} />
              ))}
            </SearchItemsBlock>
          )}
          {!!tournaments.length && (
            <SearchItemsBlock>
              <SearchItemBlockTitle>
                {t('tournaments')} ({tournaments.length})
              </SearchItemBlockTitle>
              {tournaments.map((tournament) => (
                <SearchTournamentItem
                  key={tournament.id}
                  tournament={tournament}
                />
              ))}
            </SearchItemsBlock>
          )}
        </div>
        <AllResultsBottom>
          <Hotkey />
          <SearchValueBlock>
            <Typography color="text-secondary-3" font="Body / 12 Medium">
              {t('all results for query')}
            </Typography>
            <Typography font="Body / 12 SemiBold">
              &quot;{valueSearch}&quot;
            </Typography>
          </SearchValueBlock>
          <Typography color="text-secondary-3" font="Hint / 10 SemiBold">
            {t('results amount', { count: totalResult })}
          </Typography>
          <IconChevronRight colorToken="icon-secondary-3" size={16} />
        </AllResultsBottom>
      </div>
    </Scrollable>
  )
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import { AlertPassIdentification as AlertPassIdentificationEntity } from 'entities/basket'
import { ERoutes } from 'shared/types/routes'

export const AlertPassIdentification = () => {
  const history = useHistory()

  return (
    <AlertPassIdentificationEntity
      onClickToPassIdentificationLink={() => history.push(ERoutes.TopUp)}
    />
  )
}

import { useCallback, useEffect, useRef } from 'react'

export const useClickOutside = <T extends HTMLElement>(
  callback: () => void
) => {
  const targetRef = useRef<T>(null)
  const handleClick = useCallback(
    (e) => {
      if (targetRef.current && !targetRef.current.contains(e.target)) {
        callback()
      }
    },
    [callback]
  )

  useEffect(() => {
    setTimeout(() => document.addEventListener('click', handleClick), 0)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  return targetRef
}

import styled from 'styled-components'

import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'

export const StyledTypeformModalHeaderWrapper = styled.div`
  position: absolute;
  right: 23px;
  top: 32px;
  z-index: 10002;
`

export const StyledTypeformModalHeaderExit = styled(IconCloseOutline16)`
  cursor: pointer;
  margin-right: 20px;
`

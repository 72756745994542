import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M13.3438 14.5633L11.8024 16.4715C8.60841 15.9391 5.82995 16.4481 3.68506 17.3687L10.1996 11.7119C9.94016 10.3019 10.0353 8.47282 10.9167 6.66767C11.8225 4.81204 13.9605 3.67659 16.8422 2.95125C16.8662 2.94523 16.8898 2.93784 16.9135 2.93046C16.9421 2.92152 16.9708 2.91258 16.9999 2.90605C17.2002 2.86113 17.4103 2.96266 17.5 3.15573C17.601 3.37289 17.515 3.6298 17.3126 3.75777C17.0507 3.8875 16.7972 4.00749 16.5529 4.12311L16.5528 4.12314L16.5527 4.12321L16.5527 4.12322C15.1062 4.80784 13.9841 5.33897 13.3763 6.82794C12.9928 7.76756 12.8345 8.69867 12.9745 9.52734C13.1117 10.343 13.6642 10.9897 14.3993 11.6791C15.953 13.136 17.0426 14.8062 17.3876 16.4525C17.4762 16.8757 17.4555 17.3003 17.3747 17.7036C16.8806 17.6245 16.3748 17.5199 15.8414 17.3981C15.7699 16.9684 15.6338 16.5721 15.428 16.1994C14.795 15.053 13.3438 14.5633 13.3438 14.5633ZM16.3045 11.766C17.07 11.4758 17.4201 10.2932 17.0861 9.12425C16.7523 7.95545 15.8611 7.24296 15.0957 7.53274C14.3302 7.82298 13.9803 9.00572 14.3141 10.1745C14.6478 11.3438 15.539 12.0563 16.3045 11.766ZM1.17729 20.9141C0.827505 21.0151 1.04104 20.7957 1.42125 20.4398C3.29462 18.6861 7.3194 16.8372 12.5109 18.0391C13.05 18.1639 13.5625 18.2901 14.056 18.4117C17.1052 19.1627 19.4315 19.7357 22.8478 18.6959C23.0188 18.6439 23.1146 18.6836 22.7527 18.9348C19.2303 21.3793 15.6261 21.5702 12.0536 20.4398C8.48112 19.3093 5.51999 19.6592 1.17729 20.9141Z"
      fill={theme.tokens.colors['sport-icon']['sport-swimming']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M8.97749 9.86408L7.85652 11.2517C5.53387 10.8645 3.51335 11.2345 1.95347 11.9039L6.69074 7.79032C6.50209 6.76489 6.57132 5.43463 7.21231 4.1218C7.86881 2.77691 9.41526 1.95218 11.5001 1.42444C11.5197 1.41948 11.5391 1.41348 11.5585 1.40748C11.5914 1.39729 11.6244 1.3871 11.6583 1.38197C11.7972 1.36098 11.9382 1.43429 12.0002 1.56767C12.0736 1.7256 12.011 1.91245 11.8638 2.00552C11.6733 2.09989 11.4889 2.18719 11.3112 2.2713C10.2592 2.7692 9.4431 3.15547 9.00107 4.23835C8.72216 4.92172 8.60706 5.59888 8.70884 6.20155C8.80867 6.79472 9.21044 7.26508 9.74511 7.76646C10.875 8.82603 11.6675 10.0407 11.9184 11.238C11.9828 11.5457 11.9678 11.8545 11.909 12.1478C11.5497 12.0903 11.1818 12.0142 10.7938 11.9256C10.7418 11.6131 10.6429 11.325 10.4932 11.0539C10.0329 10.2202 8.97749 9.86408 8.97749 9.86408ZM11.1307 7.82969C11.6874 7.61861 11.942 6.75851 11.6991 5.90839C11.4564 5.05836 10.8082 4.54019 10.2516 4.75093C9.69485 4.96202 9.44036 5.82219 9.68311 6.67223C9.9258 7.5226 10.5739 8.04077 11.1307 7.82969ZM0.128937 14.4826C-0.125451 14.5561 0.0298454 14.3965 0.306364 14.1377C1.66881 12.8623 4.59591 11.5176 8.37156 12.3917C8.76362 12.4825 9.13632 12.5743 9.49525 12.6627C11.7128 13.2089 13.4046 13.6256 15.8892 12.8694C16.0136 12.8316 16.0833 12.8604 15.8201 13.0431C13.2584 14.821 10.6371 14.9598 8.03895 14.1377C5.44079 13.3155 3.28725 13.57 0.128937 14.4826Z"
      fill={theme.tokens.colors['sport-icon']['sport-swimming']}
      fillRule="evenodd"
    />
  )
}

export const IconSportSwimming: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC, PropsWithChildren } from 'react'

import * as S from './scroll.styled'

export const Scroll: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className
}) => {
  return <S.ScrollWrapper className={className}>{children}</S.ScrollWrapper>
}

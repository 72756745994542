import { getCurrentLng } from 'astra-core'

import { i18nLocalToLegacyLang } from 'shared/lib/locale'

import { baseLegacyApi } from '../api'

import {
  GetNotificationsCountRequest,
  GetNotificationsCountResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  ReadNotificationRequest,
  ReadNotificationResponse
} from './notifications.types'

export const notificationsApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      GetNotificationsResponse,
      GetNotificationsRequest | void
    >({
      providesTags: (_r, _e, args) =>
        args?.type
          ? [{ type: 'Notifications', id: args.type }, 'Notifications']
          : ['Notifications'],
      query: (args = {}) => ({
        method: 'POST',
        url: '/user/messages',
        body: {
          ...args,
          lang_id: i18nLocalToLegacyLang(getCurrentLng()),
          session: '__session__',
          platforma: 'NEWOLIMPBET'
        }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled

          dispatch(resetNotificationsCount())
        } catch (e) {
          /* empty */
        }
      }
    }),
    getNotificationsCount: builder.query<
      GetNotificationsCountResponse,
      GetNotificationsCountRequest | void
    >({
      providesTags: () => ['NotificationsCount'],
      query: (args = {}) => ({
        method: 'POST',
        url: '/user/messages/count',
        body: {
          ...args,
          lang_id: i18nLocalToLegacyLang(getCurrentLng()),
          session: '__session__',
          platforma: 'NEWOLIMPBET'
        }
      })
    }),
    readNotification: builder.mutation<
      ReadNotificationResponse,
      ReadNotificationRequest
    >({
      query: (args) => ({
        method: 'POST',
        url: '/user/messages/read',
        body: {
          ...args,
          lang_id: i18nLocalToLegacyLang(getCurrentLng()),
          session: '__session__',
          platforma: 'NEWOLIMPBET'
        }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled

          dispatch(
            notificationsApi.util.updateQueryData(
              'getNotificationsCount',
              undefined,
              (draft) => {
                if (!draft?.data) {
                  return
                }

                draft.data.notread -= 1
              }
            )
          )
        } catch (e) {
          /* empty */
        }
      }
    })
  })
})

export const resetNotifications = (type?: GetNotificationsRequest['type']) =>
  notificationsApi.util.invalidateTags(
    type ? [{ type: 'Notifications', id: type }] : ['Notifications']
  )

export const resetNotificationsCount = () =>
  notificationsApi.util.invalidateTags(['NotificationsCount'])

import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'

import { SettingsButton } from 'features/settings-button'
import { UserAccountBalance } from 'features/user-account-balance'
import { HistoryBetsButton } from 'features/history-bets-button'
import { UserAccountBonus } from 'widgets/user-account-bonus'
import { RootState } from 'shared/types/store'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { useOnboardingAttrs } from './hooks'
import {
  FavouritesButton,
  NotificationsButton,
  PersonalAccountButton
} from './components'
import {
  StyledComponentOfAuthorized,
  StyledOnboardingBlock
} from './ComponentOfAuthorized.styled'
import { BonusCouponButton } from './components/BonusCouponButton'

export const ComponentOfAuthorized = () => {
  const isBalanceHidden = useAppSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  const {
    onboardingAttrFavourite,
    onboardingAttrPersonalAccount,
    onboardingAttrBalance,
    onboardingAttrBonus,
    onboardingAttrMessages,
    onboardingAttrSettings
  } = useOnboardingAttrs()

  return (
    <StyledComponentOfAuthorized>
      <StyledOnboardingBlock>
        <HistoryBetsButton />
      </StyledOnboardingBlock>

      <StyledOnboardingBlock>
        <BonusCouponButton />
      </StyledOnboardingBlock>

      {/* Check if we should show the bonus container, because it returns null if isBalanceHidden and it will break the onboarding */}
      {!isBalanceHidden && (
        <StyledOnboardingBlock {...onboardingAttrBonus}>
          <UserAccountBonus />
        </StyledOnboardingBlock>
      )}

      <StyledOnboardingBlock {...onboardingAttrBalance}>
        <UserAccountBalance />
      </StyledOnboardingBlock>

      <StyledOnboardingBlock {...onboardingAttrFavourite}>
        <FavouritesButton />
      </StyledOnboardingBlock>

      <StyledOnboardingBlock {...onboardingAttrSettings}>
        <SettingsButton />
      </StyledOnboardingBlock>

      <StyledOnboardingBlock {...onboardingAttrMessages}>
        <NotificationsButton />
      </StyledOnboardingBlock>

      <StyledOnboardingBlock {...onboardingAttrPersonalAccount}>
        <PersonalAccountButton />
      </StyledOnboardingBlock>
    </StyledComponentOfAuthorized>
  )
}

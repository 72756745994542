import { isBrowser } from 'astra-core'

import { type StorageKeys } from '../constants'

export const getLocalStorageItem = <T>(
  key: StorageKeys,
  defaultValue: T
): T => {
  try {
    if (isBrowser) {
      return JSON.parse(
        window.localStorage.getItem(key) ?? JSON.stringify(defaultValue)
      )
    }

    return defaultValue
  } catch (err) {
    console.error('getStorageValueByKey', err)
    return defaultValue
  }
}

export const setLocalStorageItem = <T>(
  key: StorageKeys,
  valueToStore: T
): void => {
  try {
    if (isBrowser) {
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    }
  } catch (error) {
    console.log(error)
  }
}

export const removeLocalStorageItem = (key: StorageKeys): void => {
  try {
    if (isBrowser) {
      window.localStorage.removeItem(key)
    }
  } catch (error) {
    console.log(error)
  }
}

export const clearLocalStorage = (): void => {
  try {
    if (isBrowser) {
      window.localStorage.clear()
    }
  } catch (error) {
    console.log(error)
  }
}

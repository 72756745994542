import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { ReactElement, RefObject } from 'react'

export enum CardEventStatus {
  ENDED = 'ended',
  CANCELLED = 'cancelled',
  PENDING = 'pending'
}

export interface CardProps {
  outcomeName: string
  teamsName: string
  tournamentName: string
  sportCode: ESportsCodes | undefined
  eventStatus: CardEventStatus | undefined
  isTooltipCommonTeamsName: boolean
  isTooltipCommonTournamentName: boolean
  isTooltipCommonOutcomeName: boolean
  isCardHover: boolean
  outcomeNameRef: RefObject<HTMLDivElement>
  teamsNameRef: RefObject<HTMLSpanElement>
  tournamentNameRef: RefObject<HTMLDivElement>
  Input: ReactElement | undefined
  CoefficientCoupon: ReactElement | false
  onDeleteOutcome: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

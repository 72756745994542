import { PayloadAction } from '@reduxjs/toolkit'
import { requestInitialState, toIdsArray } from 'astra-core'
import { EventList } from 'betweb-openapi-axios'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import { ContainerState } from './types'

const REDUCER_KEY = 'searchTournamentContainer'

export const emptyTournament = {
  lineEvents: [],
  liveEvents: []
}

// The initial state of the SportCountersProvider container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  tournaments: {},
  fetchItems: requestInitialState,
  layoutIsLoading: true
})

const searchTournamentContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchSearchTournament(
      state,
      _action: PayloadAction<{ tournamentId: number }>
    ) {
      state.fetchItems.loading = true
    },
    fetchSearchTournamentSuccess(
      state,
      {
        payload
      }: PayloadAction<{
        resData: EventList
        reqData: { tournamentId: number }
      }>
    ) {
      const { items } = payload.resData
      const { tournamentId } = payload.reqData
      state.fetchItems.loading = false
      state.layoutIsLoading = false

      if (!state.tournaments[tournamentId]) {
        state.tournaments[tournamentId] = {
          lineEvents: [],
          liveEvents: []
        }
      }
      state.tournaments[tournamentId].liveEvents = toIdsArray(
        items.filter((item) => item.live)
      )
      state.tournaments[tournamentId].lineEvents = toIdsArray(
        items.filter((item) => !item.live)
      )
    },
    fetchSearchTournamentError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItems.loading = false
      state.layoutIsLoading = false
      state.fetchItems.error = action.payload
    }
  }
})

export const {
  actions: searchTournamentContainerActions,
  reducer,
  name: sliceKey
} = searchTournamentContainerSlice

import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconTimeFilled40: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M5.40589 11.5941C4.88703 12.113 4.6276 12.3724 4.44208 12.6751C4.27759 12.9436 4.15638 13.2362 4.08289 13.5423C4 13.8876 4 14.2545 4 14.9882V25.0118C4 25.7455 4 26.1124 4.08289 26.4577C4.15638 26.7638 4.27759 27.0564 4.44208 27.3249C4.6276 27.6276 4.88703 27.887 5.40589 28.4059L11.5941 34.5941C12.113 35.113 12.3724 35.3724 12.6751 35.5579C12.9436 35.7224 13.2362 35.8436 13.5423 35.9171C13.8876 36 14.2545 36 14.9882 36H25.0118C25.7455 36 26.1124 36 26.4577 35.9171C26.7638 35.8436 27.0564 35.7224 27.3249 35.5579C27.6276 35.3724 27.887 35.113 28.4059 34.5941L34.5941 28.4059C35.113 27.887 35.3724 27.6276 35.5579 27.3249C35.7224 27.0564 35.8436 26.7638 35.9171 26.4577C36 26.1124 36 25.7455 36 25.0118V14.9882C36 14.2545 36 13.8876 35.9171 13.5423C35.8436 13.2362 35.7224 12.9436 35.5579 12.6751C35.3724 12.3724 35.113 12.113 34.5941 11.5941L28.4059 5.40589C27.887 4.88703 27.6276 4.6276 27.3249 4.44208C27.0564 4.27759 26.7638 4.15638 26.4577 4.08289C26.1124 4 25.7455 4 25.0118 4H14.9882C14.2545 4 13.8876 4 13.5423 4.08289C13.2362 4.15638 12.9436 4.27759 12.6751 4.44208C12.3724 4.6276 12.113 4.88703 11.5941 5.40589L5.40589 11.5941ZM20 11.75C20.6904 11.75 21.25 12.3096 21.25 13V19.1716C21.25 19.3705 21.329 19.5613 21.4697 19.7019L26.8839 25.1161C27.372 25.6043 27.372 26.3957 26.8839 26.8839C26.3957 27.372 25.6043 27.372 25.1161 26.8839L19.7019 21.4697C19.0924 20.8602 18.75 20.0335 18.75 19.1716V13C18.75 12.3096 19.3096 11.75 20 11.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

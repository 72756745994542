import { TFunction } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { OptionType } from 'shared/ui/SelectDeprecated'
import { INITIAL_FILTER_PERIOD_ID } from 'containers/CalendarEventsContainer/slice'
import i18n from 'app/lib/i18n'

import { ESelectOrder } from './Filters.types'

export const OPTIONS_SELECT_ORDER = (
  t?: TFunction<'translation'>
): OptionType[] => [
  {
    label: t?.('by popularity') || '',
    value: ESelectOrder.Popularity,
    id: '1'
  },
  { label: t?.('by alphabet') || '', value: ESelectOrder.Alphabet, id: '2' }
]

export const OPTIONS_SELECT_PERIOD = () => [
  {
    id: INITIAL_FILTER_PERIOD_ID,
    label: i18n.t('all time'),
    value: ''
  },
  {
    id: '2',
    label: i18n.t('2 hours'),
    value: dayjs().add(2, 'hour').format()
  },
  {
    id: '3',
    label: i18n.t('6 hours'),
    value: dayjs().add(8, 'hour').format()
  },
  {
    id: '4',
    label: i18n.t('12 hours'),
    value: dayjs().add(12, 'hour').format()
  },
  {
    id: '5',
    label: i18n.t('today'),
    scheduledTo: dayjs().endOf('day').format(),
    value: dayjs().endOf('day').format()
  },
  {
    id: '6',
    label: i18n.t('tomorrow'),
    scheduledFrom: dayjs().add(1, 'day').startOf('day').format(),
    scheduledTo: dayjs().add(1, 'day').endOf('day').format(),
    value: dayjs().add(1, 'day').endOf('day').format()
  }
]

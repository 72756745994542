import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'

import { BasketError, basketModel } from 'entities/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'

const { selectBasketErrorByMessage } = basketModel

export const ErrorClientList = () => {
  const errorClientBetsBlocked = useAppSelector((state) =>
    selectBasketErrorByMessage(
      state,
      ALL_BASKET_ERROR_MESSAGES.CLIENT_BETS_BLOCK
    )
  )
  const errorBetChangedCoefficient = useAppSelector((state) =>
    selectBasketErrorByMessage(
      state,
      ALL_BASKET_ERROR_MESSAGES.BET_CHANGED_COEFFICIENT
    )
  )

  const errorClientBlock = errorClientBetsBlocked || errorBetChangedCoefficient

  return errorClientBlock ? (
    <BasketError
      key={errorClientBlock.code}
      message={errorClientBlock.message}
    />
  ) : null
}

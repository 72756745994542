import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const supportedLngs = ['ru', 'en', 'kz', 'uz', 'cn', 'tr']

const options: InitOptions = {
  fallbackLng: 'ru',
  supportedLngs,
  load: 'languageOnly',
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase()
      return value
    }
  },
  detection: {
    order: ['querystring', 'cookie'],
    caches: ['cookie'],
    lookupQuerystring: 'locale'
  }
}

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next).use(LanguageDetector)
}

// initialize if not already initialized

if (!i18n.isInitialized) {
  i18n.init(options as any)
}

// eslint-disable-next-line import/no-default-export
export default i18n

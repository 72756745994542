import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconSimActiv: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 56 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9649 4.75886V5.35439C27.3937 4.88109 26.658 4.60552 25.832 4.60552C23.8494 4.60552 22.2573 6.28469 22.2573 8.46752C22.2573 9.47566 22.6174 10.3911 23.1886 11.0622C23.8346 11.8259 24.8555 12.314 25.9372 12.314C26.6736 12.314 27.3641 12.0851 27.9649 11.5666V12.1622H29.8423V4.75886H27.9649ZM27.8893 9.56752C27.5137 10.1163 26.8677 10.483 26.132 10.483C25.0355 10.483 24.2095 9.62822 24.2095 8.46829C24.2095 7.40022 24.9451 6.43802 26.0868 6.43802C26.8381 6.43802 27.4833 6.80392 27.8893 7.35429V9.56752ZM37.9875 10.6512C37.1312 11.8111 36.0502 12.3148 34.6832 12.3148C32.4303 12.3148 30.7782 10.606 30.7782 8.46829C30.7782 6.31582 32.4755 4.60552 34.6982 4.60552C35.9598 4.60552 37.0858 5.20106 37.7622 6.08696L36.3805 7.32239C35.9902 6.80392 35.4042 6.43802 34.6988 6.43802C33.5728 6.43802 32.7319 7.33796 32.7319 8.46909C32.7319 9.62822 33.558 10.483 34.7442 10.483C35.5405 10.483 36.1708 9.99489 36.4865 9.50679L37.9875 10.6512ZM43.7955 12.163C43.5402 12.254 42.9538 12.3148 42.5792 12.3148C41.4072 12.3148 40.5515 11.9177 40.0558 11.2467C39.6808 10.743 39.5158 10.086 39.5158 9.03269V6.43802H38.2968V4.75886H39.5158V2.69824H41.4678V4.75886H43.7965V6.43802H41.4678V9.26235C41.4678 9.72009 41.5435 9.99489 41.7085 10.1934C41.9035 10.4223 42.2192 10.5593 42.6998 10.5593C42.9852 10.5593 43.3608 10.5134 43.4965 10.483L43.7955 12.163ZM45.1478 4.75886H47.0998V12.163H45.1478V4.75886ZM56.0002 4.75886L52.6508 12.163H51.2995L47.9492 4.75886H49.9772L51.9752 9.27712L53.9725 4.75886H56.0002Z"
        fill="currentColor"
      />
      <path
        d="M17.8732 6.59039C17.8732 6.65736 17.8194 6.71106 17.7524 6.71106C17.6854 6.71106 17.6316 6.65736 17.6316 6.59039C17.6316 6.52423 17.6854 6.46973 17.7524 6.46973C17.8194 6.47053 17.8732 6.52423 17.8732 6.59039Z"
        fill="#F4764A"
      />
      <path
        d="M17.4655 3.72998C17.3993 3.72998 17.3447 3.78368 17.3447 3.85061C17.3447 3.91681 17.3985 3.97128 17.4655 3.97128C17.5317 3.97128 17.5863 3.91758 17.5863 3.85061C17.5863 3.78368 17.5317 3.72998 17.4655 3.72998Z"
        fill="#F68943"
      />
      <path
        d="M6.9081 1.97467C6.9081 1.9085 6.85434 1.854 6.7873 1.854C6.72027 1.854 6.6665 1.90772 6.6665 1.97467C6.6665 2.04084 6.72027 2.09533 6.7873 2.09533C6.85354 2.09533 6.9081 2.04162 6.9081 1.97467Z"
        fill="#D22587"
      />
      <path
        d="M12.9589 2.82165C13.0252 2.82165 13.0797 2.76793 13.0797 2.70099C13.0797 2.63482 13.026 2.58032 12.9589 2.58032C12.8927 2.58032 12.8381 2.63482 12.8381 2.70099C12.8381 2.76793 12.8919 2.82165 12.9589 2.82165Z"
        fill="#F68635"
      />
      <path
        d="M8.16103 2.78961C8.16103 2.72344 8.10647 2.66895 8.04023 2.66895C7.974 2.66895 7.91943 2.72266 7.91943 2.78961C7.91943 2.85578 7.9732 2.91027 8.04023 2.91027C8.10647 2.91027 8.16103 2.85578 8.16103 2.78961Z"
        fill="#DA2680"
      />
      <path
        d="M17.2993 4.78748C17.3663 4.78748 17.4201 4.73374 17.4201 4.66681C17.4201 4.60064 17.3663 4.54614 17.2993 4.54614C17.2323 4.54614 17.1777 4.59984 17.1777 4.66681C17.1777 4.73374 17.2323 4.78748 17.2993 4.78748Z"
        fill="#F58445"
      />
      <path
        d="M14.9883 5.69525C14.9883 6.34138 15.5127 6.86605 16.1595 6.86605C16.8071 6.86605 17.3316 6.34215 17.3316 5.69525C17.3316 5.04831 16.8063 4.52441 16.1595 4.52441C15.5119 4.52518 14.9883 5.04911 14.9883 5.69525Z"
        fill="#F58046"
      />
      <path
        d="M15.583 3.95679C15.583 4.22302 15.7989 4.43865 16.0654 4.43865C16.3327 4.43865 16.5486 4.22225 16.5486 3.95679C16.5486 3.69055 16.3327 3.47412 16.0654 3.47412C15.7989 3.47412 15.583 3.69055 15.583 3.95679Z"
        fill="#F68D43"
      />
      <path
        d="M15.4638 4.46686C15.4638 4.39989 15.41 4.34619 15.343 4.34619C15.2767 4.34619 15.2222 4.39989 15.2222 4.46686C15.2222 4.53302 15.2759 4.58752 15.343 4.58752C15.41 4.58752 15.4638 4.53302 15.4638 4.46686Z"
        fill="#F68D43"
      />
      <path
        d="M15.3678 3.03613C15.3725 3.16925 15.4847 3.27279 15.618 3.26889C15.7513 3.26422 15.8557 3.1529 15.851 3.01978C15.8463 2.88666 15.7349 2.78235 15.6008 2.78702C15.4676 2.79169 15.3632 2.90301 15.3678 3.03613Z"
        fill="#F79442"
      />
      <path
        d="M15.3674 2.37707C15.4344 2.37707 15.4889 2.32335 15.4889 2.25641C15.4889 2.19024 15.4351 2.13574 15.3674 2.13574C15.3003 2.13574 15.2466 2.18946 15.2466 2.25641C15.2466 2.32335 15.3003 2.37707 15.3674 2.37707Z"
        fill="#F89B41"
      />
      <path
        d="M14.9578 3.91998C14.9578 4.05311 15.0661 4.16131 15.1986 4.16131C15.3319 4.16131 15.4402 4.05311 15.4402 3.91998C15.4402 3.78688 15.3311 3.67944 15.1986 3.67944C15.0653 3.67944 14.9578 3.78688 14.9578 3.91998Z"
        fill="#F79243"
      />
      <path
        d="M13.1062 3.22527C13.1062 3.75775 13.5387 4.18979 14.0718 4.18979C14.6048 4.18979 15.0373 3.75775 15.0373 3.22527C15.0373 2.69279 14.6048 2.26074 14.0718 2.26074C13.5387 2.26074 13.1062 2.69279 13.1062 3.22527Z"
        fill="#F8963E"
      />
      <path
        d="M13.8098 1.83972C13.876 1.83972 13.9305 1.786 13.9305 1.71905C13.9305 1.65288 13.8768 1.59839 13.8098 1.59839C13.7435 1.59839 13.689 1.6521 13.689 1.71905C13.689 1.78522 13.7427 1.83972 13.8098 1.83972Z"
        fill="#F68936"
      />
      <path
        d="M13.2576 2.40757C13.3909 2.4029 13.4945 2.29158 13.4898 2.15846C13.4852 2.02534 13.3737 1.92102 13.2405 1.92647C13.1072 1.93114 13.0028 2.04247 13.0075 2.17559C13.0122 2.30871 13.1236 2.41224 13.2576 2.40757Z"
        fill="#F68635"
      />
      <path
        d="M11.8374 1.78925C11.8374 2.05549 12.0533 2.27113 12.3198 2.27113C12.5871 2.27113 12.803 2.05471 12.803 1.78925C12.803 1.52301 12.5871 1.30737 12.3198 1.30737C12.0533 1.30737 11.8374 1.52301 11.8374 1.78925Z"
        fill="#F47430"
      />
      <path
        d="M11.9089 0.5841C11.9751 0.5841 12.0297 0.530387 12.0297 0.463437C12.0297 0.397267 11.9759 0.342773 11.9089 0.342773C11.8426 0.342773 11.7881 0.39649 11.7881 0.463437C11.7881 0.529607 11.8419 0.5841 11.9089 0.5841Z"
        fill="#F26634"
      />
      <path
        d="M11.8735 2.6497C11.9538 2.6497 12.0185 2.58508 12.0185 2.5049C12.0185 2.42472 11.9538 2.36011 11.8735 2.36011C11.7925 2.36011 11.7278 2.42472 11.7278 2.5049C11.7278 2.58431 11.7925 2.6497 11.8735 2.6497Z"
        fill="#F37230"
      />
      <path
        d="M15.705 9.45801C15.6388 9.45801 15.5842 9.51171 15.5842 9.57867C15.5842 9.64484 15.638 9.69934 15.705 9.69934C15.7713 9.69934 15.8258 9.64561 15.8258 9.57867C15.8258 9.51171 15.7721 9.45801 15.705 9.45801Z"
        fill="#F15C50"
      />
      <path
        d="M9.20874 2.60205C9.20874 3.26765 9.74877 3.80791 10.4159 3.80791C11.0822 3.80791 11.623 3.26843 11.623 2.60205C11.623 1.93646 11.0829 1.39697 10.4159 1.39697C9.74877 1.3962 9.20874 1.93646 9.20874 2.60205Z"
        fill="#F15943"
      />
      <path
        d="M11.1509 1.36435C11.285 1.35968 11.3886 1.24836 11.3839 1.11524C11.3793 0.982118 11.2678 0.877805 11.1345 0.882475C11.0005 0.887145 10.8968 0.998468 10.9015 1.13159C10.9062 1.26549 11.0184 1.36902 11.1509 1.36435Z"
        fill="#F1603C"
      />
      <path
        d="M10.6464 0.5841C10.7135 0.5841 10.768 0.530387 10.768 0.463437C10.768 0.397267 10.7142 0.342773 10.6464 0.342773C10.5802 0.342773 10.5256 0.39649 10.5256 0.463437C10.5256 0.529607 10.5802 0.5841 10.6464 0.5841Z"
        fill="#F15645"
      />
      <path
        d="M9.4582 1.6737C9.52444 1.6737 9.57897 1.61998 9.57897 1.55304C9.57897 1.48687 9.5252 1.43237 9.4582 1.43237C9.39197 1.43237 9.3374 1.48609 9.3374 1.55304C9.3374 1.61921 9.39117 1.6737 9.4582 1.6737Z"
        fill="#EE445A"
      />
      <path
        d="M9.1696 3.44618C9.23583 3.44618 9.29036 3.39244 9.29036 3.3255C9.29036 3.25933 9.2366 3.20483 9.1696 3.20483C9.1026 3.20483 9.04883 3.25855 9.04883 3.3255C9.04883 3.39168 9.1026 3.44618 9.1696 3.44618Z"
        fill="#EA3E60"
      />
      <path
        d="M8.77485 2.24939C9.04138 2.24939 9.25725 2.03376 9.25725 1.76752C9.25725 1.50128 9.04138 1.28564 8.77485 1.28564C8.50835 1.28564 8.29248 1.50128 8.29248 1.76752C8.29168 2.03376 8.50835 2.24939 8.77485 2.24939Z"
        fill="#E4366A"
      />
      <path
        d="M8.06587 1.83557C8.13287 1.83557 8.18667 1.78185 8.18667 1.7149C8.18667 1.64795 8.13287 1.59424 8.06587 1.59424C7.9996 1.59424 7.94507 1.64795 7.94507 1.7149C7.94507 1.78185 7.9996 1.83557 8.06587 1.83557Z"
        fill="#DD2878"
      />
      <path
        d="M8.00621 2.40131C8.13945 2.40131 8.24778 2.29388 8.24778 2.16076C8.24778 2.02764 8.13945 1.91943 8.00621 1.91943C7.87295 1.91943 7.76465 2.02764 7.76465 2.16076C7.76465 2.2931 7.87295 2.40131 8.00621 2.40131Z"
        fill="#DB267F"
      />
      <path
        d="M9.35005 4.04868C9.49031 4.04868 9.60411 3.93502 9.60411 3.79412C9.60411 3.65402 9.49031 3.53955 9.35005 3.53955C9.20978 3.53955 9.09521 3.65322 9.09521 3.79412C9.09521 3.93425 9.20978 4.04868 9.35005 4.04868Z"
        fill="#EF4558"
      />
      <path
        d="M9.0595 2.63977C9.0595 2.49964 8.9457 2.38599 8.80467 2.38599C8.6636 2.38599 8.5498 2.49964 8.5498 2.63977C8.5498 2.7799 8.6636 2.89433 8.80467 2.89433C8.94494 2.89433 9.0595 2.7799 9.0595 2.63977Z"
        fill="#E53669"
      />
      <path
        d="M9.63403 4.10518C9.63403 4.21104 9.72053 4.29744 9.8273 4.29744C9.9333 4.29744 10.0198 4.21104 10.0198 4.10518C10.0198 3.99851 9.9333 3.91211 9.8273 3.91211C9.72053 3.91211 9.63403 3.99851 9.63403 4.10518Z"
        fill="#F0504B"
      />
      <path
        d="M7.25761 1.70228C7.52411 1.70228 7.73998 1.48586 7.73998 1.2204C7.73998 0.954165 7.52335 0.738525 7.25761 0.738525C6.99108 0.738525 6.77441 0.954165 6.77441 1.2204C6.77441 1.48586 6.99108 1.70228 7.25761 1.70228Z"
        fill="#D72683"
      />
      <path
        d="M6.59064 0.761813C6.7239 0.757143 6.82834 0.645043 6.82367 0.511923C6.819 0.378803 6.70677 0.275266 6.5735 0.279937C6.44024 0.284608 6.3358 0.39593 6.34127 0.52905C6.34594 0.66217 6.45737 0.766483 6.59064 0.761813Z"
        fill="#D32586"
      />
      <path
        d="M6.19938 0.241327C6.26638 0.241327 6.32015 0.187612 6.32015 0.120664C6.32015 0.0544934 6.26638 0 6.19938 0C6.13235 0 6.07861 0.0537147 6.07861 0.120664C6.07861 0.187612 6.13235 0.241327 6.19938 0.241327Z"
        fill="#D22587"
      />
      <path
        d="M5.85458 2.59729C6.25512 2.59729 6.57932 2.27344 6.57932 1.87409C6.57932 1.47473 6.25512 1.15088 5.85458 1.15088C5.45482 1.15088 5.13062 1.47473 5.13062 1.87409C5.13062 2.27344 5.45482 2.59729 5.85458 2.59729Z"
        fill="#CA2A90"
      />
      <path
        d="M5.32661 1.00227C5.39364 1.00227 5.44741 0.948552 5.44741 0.882382C5.44741 0.816212 5.39364 0.761719 5.32661 0.761719C5.26038 0.761719 5.20581 0.815435 5.20581 0.882382C5.20581 0.948552 5.25958 1.00227 5.32661 1.00227Z"
        fill="#CA2A90"
      />
      <path
        d="M4.90938 1.9486C4.97565 1.9486 5.03018 1.89489 5.03018 1.82794C5.03018 1.76177 4.97642 1.70728 4.90938 1.70728C4.84238 1.70728 4.78858 1.76099 4.78858 1.82794C4.78782 1.89489 4.84238 1.9486 4.90938 1.9486Z"
        fill="#C32F92"
      />
      <path
        d="M3.05713 3.04897C3.05713 3.69977 3.5855 4.2268 4.23623 4.2268C4.88773 4.2268 5.4161 3.69977 5.4161 3.04897C5.4161 2.39817 4.88773 1.87036 4.23623 1.87036C3.5855 1.87036 3.05713 2.39817 3.05713 3.04897Z"
        fill="#B43894"
      />
      <path
        d="M3.20325 2.01439C3.33651 2.01439 3.44405 1.90618 3.44405 1.77306C3.44405 1.63994 3.33573 1.53174 3.20325 1.53174C3.06999 1.53174 2.96167 1.63994 2.96167 1.77306C2.96089 1.90696 3.06921 2.01439 3.20325 2.01439Z"
        fill="#B43894"
      />
      <path
        d="M2.84286 3.96286C2.97611 3.96286 3.08443 3.85462 3.08443 3.72152C3.08443 3.58839 2.97611 3.48096 2.84286 3.48096C2.7096 3.48096 2.60205 3.58919 2.60205 3.72152C2.60127 3.85462 2.7096 3.96286 2.84286 3.96286Z"
        fill="#9E3D96"
      />
      <path
        d="M1.58569 4.04046C1.58569 4.30669 1.80156 4.52233 2.06809 4.52233C2.33461 4.52233 2.55048 4.30669 2.55048 4.04046C2.55048 3.77423 2.33461 3.55859 2.06809 3.55859C1.80156 3.55859 1.58569 3.77423 1.58569 4.04046Z"
        fill="#943C96"
      />
      <path
        d="M9.58419 14.989C9.37142 14.989 9.19922 15.161 9.19922 15.3736C9.19922 15.5861 9.37142 15.7581 9.58419 15.7581C9.79615 15.7581 9.96915 15.5861 9.96915 15.3736C9.96915 15.161 9.79615 14.989 9.58419 14.989Z"
        fill="#8C377E"
      />
      <path
        d="M1.73036 3.22016C1.86362 3.22016 1.97195 3.11195 1.97195 2.97883C1.97195 2.84571 1.86362 2.73828 1.73036 2.73828C1.5971 2.73828 1.48877 2.84649 1.48877 2.97883C1.48877 3.11195 1.5971 3.22016 1.73036 3.22016Z"
        fill="#9C3D97"
      />
      <path
        d="M1.80244 2.39074C1.86946 2.39074 1.92324 2.33703 1.92324 2.27008C1.92324 2.20313 1.86946 2.14941 1.80244 2.14941C1.73619 2.14941 1.68164 2.20313 1.68164 2.27008C1.68164 2.33703 1.73541 2.39074 1.80244 2.39074Z"
        fill="#A53B96"
      />
      <path
        d="M1.32084 4.95051C1.39721 4.95051 1.45954 4.88824 1.45954 4.81117C1.45954 4.73487 1.39721 4.67261 1.32084 4.67261C1.24447 4.67261 1.18214 4.73487 1.18214 4.81117C1.18136 4.88901 1.2437 4.95051 1.32084 4.95051Z"
        fill="#873E97"
      />
      <path
        d="M1.25662 6.88245C1.25662 6.74931 1.1483 6.64111 1.01503 6.64111C0.881764 6.64111 0.773438 6.74931 0.773438 6.88245C0.773438 7.01555 0.881764 7.12298 1.01503 7.12298C1.14907 7.12378 1.25662 7.01555 1.25662 6.88245Z"
        fill="#6D4099"
      />
      <path
        d="M1.12731 10.1643C0.99406 10.1643 0.885742 10.2725 0.885742 10.4056C0.885742 10.5387 0.99406 10.6469 1.12731 10.6469C1.26057 10.6469 1.36888 10.5387 1.36888 10.4056C1.36811 10.2717 1.26057 10.1643 1.12731 10.1643Z"
        fill="#404B9F"
      />
      <path
        d="M2.17355 10.4756C1.90703 10.4756 1.69116 10.6912 1.69116 10.9575C1.69116 11.2237 1.90781 11.4401 2.17355 11.4401C2.44008 11.4401 2.65672 11.2245 2.65672 10.9575C2.65672 10.6912 2.44008 10.4756 2.17355 10.4756Z"
        fill="#354DA1"
      />
      <path
        d="M2.05268 12.199C1.98644 12.199 1.93188 12.2527 1.93188 12.3196C1.93188 12.3858 1.98566 12.4403 2.05268 12.4403C2.11892 12.4403 2.17348 12.3866 2.17348 12.3196C2.17348 12.2527 2.1197 12.199 2.05268 12.199Z"
        fill="#2551A3"
      />
      <path
        d="M3.19674 11.0864C2.93022 11.0864 2.71436 11.3021 2.71436 11.5683C2.71436 11.8345 2.93022 12.051 3.19674 12.051C3.46326 12.051 3.67912 11.8345 3.67912 11.5683C3.6799 11.3021 3.46326 11.0864 3.19674 11.0864Z"
        fill="#2750A3"
      />
      <path
        d="M3.34724 12.2629C3.21399 12.2629 3.10645 12.3711 3.10645 12.5035C3.10645 12.6366 3.21477 12.744 3.34724 12.744C3.4805 12.744 3.58882 12.6358 3.58882 12.5035C3.58882 12.3704 3.4805 12.2629 3.34724 12.2629Z"
        fill="#1A53A5"
      />
      <path
        d="M3.37207 10.5063C3.37207 10.8659 3.66352 11.1571 4.02356 11.1571C4.3836 11.1571 4.67503 10.8659 4.67503 10.5063C4.67503 10.1466 4.3836 9.85547 4.02356 9.85547C3.66352 9.85547 3.37207 10.1466 3.37207 10.5063Z"
        fill="#324EA1"
      />
      <path
        d="M4.25475 4.48322C4.25475 4.41628 4.20098 4.36255 4.13395 4.36255C4.06695 4.36255 4.01318 4.41628 4.01318 4.48322C4.01318 4.54938 4.06695 4.60388 4.13395 4.60388C4.20022 4.60388 4.25475 4.55018 4.25475 4.48322Z"
        fill="#A33C96"
      />
      <path
        d="M7.47339 3.60309C7.47339 3.66926 7.52716 3.72376 7.59419 3.72376C7.66122 3.72376 7.71499 3.66926 7.71499 3.60309C7.71499 3.53612 7.66122 3.48242 7.59419 3.48242C7.52716 3.48242 7.47339 3.53692 7.47339 3.60309Z"
        fill="#D42486"
      />
      <path
        d="M8.41247 2.54474C8.41247 2.47779 8.3587 2.42407 8.29167 2.42407C8.22543 2.42407 8.1709 2.47779 8.1709 2.54474C8.1709 2.61169 8.22467 2.6654 8.29167 2.6654C8.3579 2.66618 8.41247 2.61169 8.41247 2.54474Z"
        fill="#DD2A77"
      />
      <path
        d="M8.54004 4.25323C8.54004 4.3194 8.59384 4.37313 8.66084 4.37313C8.72711 4.37313 8.78164 4.3194 8.78164 4.25323C8.78164 4.18707 8.72787 4.13257 8.66084 4.13257C8.59384 4.13334 8.54004 4.18707 8.54004 4.25323Z"
        fill="#DF2D72"
      />
      <path
        d="M3.98319 9.72764C4.0728 9.72764 4.14526 9.65524 4.14526 9.56571C4.14526 9.47621 4.07203 9.40381 3.98319 9.40381C3.89357 9.40381 3.82031 9.47621 3.82031 9.56571C3.82109 9.65524 3.89357 9.72764 3.98319 9.72764Z"
        fill="#44499E"
      />
      <path
        d="M3.90085 4.73544C3.90085 4.33531 3.57665 4.01147 3.17686 4.01147C2.77708 4.01147 2.45288 4.33531 2.45288 4.73544C2.45288 5.13481 2.77708 5.45867 3.17686 5.45867C3.57587 5.45867 3.90085 5.13481 3.90085 4.73544Z"
        fill="#943C96"
      />
      <path
        d="M1.05518 7.6806C1.05518 7.94687 1.27105 8.1625 1.53757 8.1625C1.80409 8.1625 2.01996 7.94687 2.01996 7.6806C2.01996 7.41437 1.80409 7.19873 1.53757 7.19873C1.27105 7.19797 1.05518 7.41437 1.05518 7.6806Z"
        fill="#63429A"
      />
      <path
        d="M2.25049 7.62973C2.25049 7.76363 2.35881 7.87106 2.49208 7.87106C2.62534 7.87106 2.73367 7.76363 2.73367 7.62973C2.73367 7.49659 2.62534 7.38916 2.49208 7.38916C2.35881 7.38916 2.25049 7.49739 2.25049 7.62973Z"
        fill="#664199"
      />
      <path
        d="M3.48915 5.69928C3.48915 5.63311 3.43537 5.57861 3.36835 5.57861C3.30211 5.57861 3.24756 5.63235 3.24756 5.69928C3.24756 5.76625 3.30133 5.81995 3.36835 5.81995C3.43537 5.81995 3.48915 5.76625 3.48915 5.69928Z"
        fill="#873E97"
      />
      <path
        d="M3.04577 7.73358C3.17903 7.73358 3.28734 7.62538 3.28734 7.49304C3.28734 7.35991 3.17903 7.25171 3.04577 7.25171C2.91252 7.25171 2.8042 7.35991 2.8042 7.49304C2.8042 7.62538 2.91252 7.73358 3.04577 7.73358Z"
        fill="#6A4099"
      />
      <path
        d="M3.2602 8.01941C3.32722 8.01941 3.38099 7.96568 3.38099 7.89874C3.38099 7.83254 3.32722 7.77808 3.2602 7.77808C3.19318 7.77808 3.1394 7.83254 3.1394 7.89874C3.1394 7.96568 3.19318 8.01941 3.2602 8.01941Z"
        fill="#64429A"
      />
      <path
        d="M2.74951 10.6395C2.74951 10.7726 2.85783 10.8801 2.99031 10.8801C3.12357 10.8801 3.23189 10.7719 3.23189 10.6395C3.23189 10.5064 3.12357 10.3982 2.99031 10.3982C2.85783 10.3982 2.74951 10.5064 2.74951 10.6395Z"
        fill="#364CA0"
      />
      <path
        d="M2.57474 10.3162C3.23169 10.3162 3.76394 9.78372 3.76394 9.12745C3.76394 8.47119 3.23169 7.93872 2.57474 7.93872C1.91702 7.93872 1.38477 8.47119 1.38477 9.12745C1.38477 9.78372 1.9178 10.3162 2.57474 10.3162Z"
        fill="#50469C"
      />
      <path
        d="M2.35811 4.98056C2.35811 4.84746 2.24979 4.73926 2.11653 4.73926C1.98328 4.73926 1.87573 4.84746 1.87573 4.98056C1.87573 5.11369 1.98405 5.22112 2.11653 5.22112C2.25057 5.22112 2.35811 5.11369 2.35811 4.98056Z"
        fill="#893E97"
      />
      <path
        d="M3.29993 6.3554C3.29993 5.8229 2.86743 5.39087 2.33439 5.39087C1.80136 5.39087 1.36963 5.8237 1.36963 6.3554C1.36963 6.88787 1.80214 7.3199 2.33517 7.3199C2.86821 7.3199 3.29993 6.88787 3.29993 6.3554Z"
        fill="#783F98"
      />
      <path
        d="M6.07819 12.3192C6.07819 11.6754 5.54983 11.1531 4.89833 11.1531C4.24683 11.1531 3.71924 11.6754 3.71924 12.3192C3.71924 12.9638 4.24759 13.4862 4.89833 13.4862C5.54983 13.4862 6.07819 12.9638 6.07819 12.3192Z"
        fill="#2351A3"
      />
      <path
        d="M5.06715 13.9559C5.06715 13.822 4.95882 13.7146 4.82555 13.7146C4.69228 13.7146 4.58472 13.8228 4.58472 13.9559C4.58472 14.089 4.69305 14.1965 4.82555 14.1965C4.95882 14.1965 5.06715 14.089 5.06715 13.9559Z"
        fill="#344CA0"
      />
      <path
        d="M5.20747 14.6682C5.1412 14.6682 5.08667 14.7219 5.08667 14.7889C5.08667 14.855 5.14044 14.9103 5.20747 14.9103C5.2737 14.9103 5.32824 14.8558 5.32824 14.7889C5.32824 14.7227 5.27447 14.6682 5.20747 14.6682Z"
        fill="#40499E"
      />
      <path
        d="M5.64028 13.4397C5.50702 13.4397 5.39868 13.5479 5.39868 13.6802C5.39868 13.8134 5.50702 13.9208 5.64028 13.9208C5.77355 13.9208 5.88188 13.8126 5.88188 13.6802C5.88108 13.5479 5.77355 13.4397 5.64028 13.4397Z"
        fill="#3C4A9F"
      />
      <path
        d="M7.41476 13.314C7.41476 12.9147 7.0906 12.5908 6.69003 12.5908C6.29026 12.5908 5.96606 12.9147 5.96606 13.314C5.96606 13.7142 6.29026 14.038 6.69003 14.038C7.0906 14.0372 7.41476 13.7134 7.41476 13.314Z"
        fill="#4B469D"
      />
      <path
        d="M6.75019 15.2041C6.68316 15.2041 6.62939 15.2578 6.62939 15.3247C6.62939 15.3909 6.68316 15.4454 6.75019 15.4454C6.81719 15.4454 6.87096 15.3917 6.87096 15.3247C6.87096 15.2578 6.81719 15.2041 6.75019 15.2041Z"
        fill="#5E4197"
      />
      <path
        d="M7.08507 14.0615C6.9518 14.0615 6.84424 14.1697 6.84424 14.3021C6.84424 14.436 6.95257 14.5434 7.08507 14.5434C7.21834 14.5434 7.32667 14.4352 7.32667 14.3021C7.32667 14.169 7.21834 14.0615 7.08507 14.0615Z"
        fill="#5C429A"
      />
      <path
        d="M7.63338 14.9143C7.50015 14.9143 7.39258 15.0217 7.39258 15.1556C7.39258 15.2888 7.50091 15.397 7.63338 15.397C7.76665 15.397 7.87494 15.2888 7.87494 15.1556C7.87494 15.0217 7.76741 14.9143 7.63338 14.9143Z"
        fill="#6C3E8F"
      />
      <path
        d="M7.09897 2.12329C6.6992 2.12329 6.375 2.44714 6.375 2.84649C6.375 3.24585 6.6992 3.5697 7.09897 3.5697C7.49877 3.5697 7.82373 3.24585 7.82373 2.84649C7.82297 2.44714 7.49877 2.12329 7.09897 2.12329Z"
        fill="#D22587"
      />
      <path
        d="M8.17493 13.4968C7.77516 13.4968 7.4502 13.8207 7.4502 14.22C7.4502 14.6186 7.77436 14.9432 8.17493 14.9432C8.57473 14.9432 8.8989 14.6194 8.8989 14.22C8.8989 13.8207 8.57473 13.4968 8.17493 13.4968Z"
        fill="#723C8D"
      />
      <path
        d="M8.73042 15.1604C8.66339 15.1604 8.60962 15.2141 8.60962 15.2811C8.60962 15.3472 8.66339 15.4017 8.73042 15.4017C8.79665 15.4017 8.85122 15.3472 8.85122 15.2811C8.85122 15.2141 8.79665 15.1604 8.73042 15.1604Z"
        fill="#7E3985"
      />
      <path
        d="M9.06977 14.9185C9.00351 14.9185 8.94897 14.9722 8.94897 15.0391C8.94897 15.1069 9.00274 15.1606 9.06977 15.1606C9.13601 15.1606 9.19054 15.1069 9.19054 15.0391C9.19054 14.9722 9.13677 14.9185 9.06977 14.9185Z"
        fill="#843983"
      />
      <path
        d="M9.21914 14.343C9.08587 14.343 8.97754 14.4512 8.97754 14.5843C8.97754 14.7175 9.08587 14.8257 9.21914 14.8257C9.35241 14.8257 9.45997 14.7175 9.45997 14.5843C9.45997 14.4512 9.35241 14.343 9.21914 14.343Z"
        fill="#843882"
      />
      <path
        d="M9.27916 13.7593C9.14593 13.7593 9.0376 13.8675 9.0376 14.0006C9.0376 14.1337 9.14593 14.2411 9.27916 14.2411C9.41243 14.2411 9.52 14.1329 9.52 14.0006C9.52 13.8683 9.41243 13.7593 9.27916 13.7593Z"
        fill="#873881"
      />
      <path
        d="M9.59823 4.53771C9.59823 4.32521 9.42523 4.15161 9.21249 4.15161C8.99896 4.15161 8.82593 4.32441 8.82593 4.53771C8.82593 4.75101 8.99896 4.92384 9.21249 4.92384C9.42599 4.92308 9.59823 4.75101 9.59823 4.53771Z"
        fill="#EE435A"
      />
      <path
        d="M8.97056 3.50385C8.97056 3.19011 8.71573 2.93555 8.40166 2.93555C8.0876 2.93555 7.83276 3.19011 7.83276 3.50385C7.83276 3.81755 8.0876 4.07212 8.40166 4.07212C8.71573 4.07212 8.97056 3.81755 8.97056 3.50385Z"
        fill="#DD2977"
      />
      <path
        d="M5.89463 2.8335C5.68343 2.8335 5.51196 3.00476 5.51196 3.21573C5.51196 3.42671 5.68263 3.59797 5.89463 3.59797C6.1058 3.59797 6.2765 3.42671 6.2765 3.21573C6.27726 3.00398 6.1058 2.8335 5.89463 2.8335Z"
        fill="#C32F92"
      />
      <path
        d="M10.813 13.1568C10.813 13.237 10.8785 13.3016 10.9579 13.3016C11.0382 13.3016 11.1029 13.237 11.1029 13.1568C11.1029 13.0758 11.0382 13.0112 10.9579 13.0112C10.8785 13.0112 10.813 13.0758 10.813 13.1568Z"
        fill="#B0326E"
      />
      <path
        d="M10.5913 13.3635C10.0583 13.3635 9.62573 13.7956 9.62573 14.3281C9.62573 14.8606 10.0583 15.2926 10.5913 15.2926C11.1243 15.2926 11.5569 14.8606 11.5569 14.3281C11.5569 13.7956 11.1243 13.3635 10.5913 13.3635Z"
        fill="#A03474"
      />
      <path
        d="M11.9379 14.0125C11.8038 14.0125 11.6963 14.1207 11.6963 14.2538C11.6963 14.3869 11.8046 14.4951 11.9379 14.4951C12.0711 14.4951 12.1794 14.3869 12.1794 14.2538C12.1794 14.1199 12.0711 14.0125 11.9379 14.0125Z"
        fill="#BE3069"
      />
      <path
        d="M12.7742 14.0955C12.5069 14.0955 12.291 14.3111 12.291 14.5774C12.291 14.8444 12.5069 15.06 12.7742 15.06C13.0407 15.06 13.2566 14.8444 13.2566 14.5774C13.2566 14.3111 13.0399 14.0955 12.7742 14.0955Z"
        fill="#C93166"
      />
      <path
        d="M12.4846 15.4231C12.4183 15.4231 12.3638 15.4768 12.3638 15.5438C12.3638 15.6099 12.4175 15.6644 12.4846 15.6644C12.5508 15.6644 12.6061 15.6099 12.6061 15.5438C12.6061 15.4768 12.5508 15.4231 12.4846 15.4231Z"
        fill="#BC316A"
      />
      <path
        d="M10.1542 15.7578C10.0872 15.7578 10.0334 15.8115 10.0334 15.8793C10.0334 15.9462 10.0872 15.9999 10.1542 15.9999C10.2205 15.9999 10.275 15.9462 10.275 15.8793C10.2742 15.8115 10.2205 15.7578 10.1542 15.7578Z"
        fill="#94357B"
      />
      <path
        d="M13.0804 13.0383C12.9472 13.0383 12.8396 13.1473 12.8396 13.2797C12.8396 13.4128 12.9479 13.5202 13.0804 13.5202C13.2137 13.5202 13.3213 13.412 13.3213 13.2797C13.322 13.1473 13.2137 13.0383 13.0804 13.0383Z"
        fill="#DF315F"
      />
      <path
        d="M12.7207 13.8798C12.7869 13.8798 12.8415 13.8253 12.8415 13.7591C12.8415 13.6929 12.7877 13.6384 12.7207 13.6384C12.6537 13.6384 12.5991 13.6929 12.5991 13.7591C12.5999 13.826 12.6537 13.8798 12.7207 13.8798Z"
        fill="#D13063"
      />
      <path
        d="M11.4856 3.41804C11.4856 3.48498 11.5394 3.53868 11.6064 3.53868C11.6734 3.53868 11.728 3.48421 11.728 3.41804C11.728 3.35184 11.6742 3.29736 11.6064 3.29736C11.5394 3.29736 11.4856 3.35108 11.4856 3.41804Z"
        fill="#F4752F"
      />
      <path
        d="M12.2576 12.1969C12.2576 12.1299 12.2039 12.0754 12.1369 12.0754C12.0706 12.0754 12.0161 12.1292 12.0161 12.1969C12.0161 12.263 12.0699 12.3175 12.1369 12.3175C12.2039 12.3175 12.2576 12.263 12.2576 12.1969Z"
        fill="#DD3160"
      />
      <path
        d="M11.954 12.4128C11.5542 12.4128 11.23 12.7367 11.23 13.136C11.23 13.5346 11.5542 13.8585 11.954 13.8585C12.353 13.8585 12.678 13.5346 12.678 13.136C12.678 12.7367 12.3538 12.4128 11.954 12.4128Z"
        fill="#C83166"
      />
      <path
        d="M12.9372 3.72107C13.0034 3.72107 13.058 3.66737 13.058 3.6004C13.058 3.53424 13.0042 3.47974 12.9372 3.47974C12.871 3.47974 12.8164 3.53347 12.8164 3.6004C12.8164 3.66737 12.8702 3.72107 12.9372 3.72107Z"
        fill="#F7913B"
      />
      <path
        d="M13.1501 11.108C13.1501 11.2419 13.2585 11.3493 13.391 11.3493C13.525 11.3493 13.6325 11.2411 13.6325 11.108C13.6325 10.9749 13.5242 10.8674 13.391 10.8674C13.2577 10.8674 13.1501 10.9749 13.1501 11.108Z"
        fill="#EF4157"
      />
      <path
        d="M13.1014 11.3899C12.7009 11.3899 12.3774 11.7137 12.3774 12.1139C12.3774 12.5125 12.7016 12.8363 13.1014 12.8363C13.5012 12.8363 13.8254 12.5125 13.8254 12.1139C13.8254 11.7137 13.5012 11.3899 13.1014 11.3899Z"
        fill="#EE355B"
      />
      <path
        d="M14.2403 12.0195C14.1071 12.0195 13.9995 12.127 13.9995 12.2601C13.9995 12.3932 14.1078 12.5006 14.2403 12.5006C14.3743 12.5006 14.4819 12.3924 14.4819 12.2601C14.4819 12.127 14.3736 12.0195 14.2403 12.0195Z"
        fill="#EF3D59"
      />
      <path
        d="M14.8276 13.3959C14.6943 13.4006 14.5907 13.5119 14.5954 13.6458C14.5993 13.7789 14.7115 13.8824 14.8447 13.8778C14.9788 13.8739 15.0824 13.7618 15.0778 13.6287C15.0731 13.4948 14.9616 13.3912 14.8276 13.3959Z"
        fill="#EE375A"
      />
      <path
        d="M15.0776 12.7922C15.0106 12.7922 14.9568 12.846 14.9568 12.9129C14.9568 12.9791 15.0106 13.0336 15.0776 13.0336C15.1438 13.0336 15.1984 12.9799 15.1984 12.9129C15.1984 12.8467 15.1446 12.7922 15.0776 12.7922Z"
        fill="#EF3C58"
      />
      <path
        d="M6.40494 12.4778C6.48521 12.4778 6.54991 12.4132 6.54991 12.333C6.54991 12.2528 6.48521 12.1882 6.40494 12.1882C6.32468 12.1882 6.26001 12.2528 6.26001 12.333C6.26001 12.4132 6.32468 12.4778 6.40494 12.4778Z"
        fill="#384CA0"
      />
      <path
        d="M14.8927 9.56152C14.2263 9.56152 13.6863 10.101 13.6863 10.7674C13.6863 11.433 14.2263 11.9725 14.8927 11.9725C15.559 11.9725 16.0998 11.433 16.0998 10.7674C16.0998 10.101 15.559 9.56152 14.8927 9.56152Z"
        fill="#F04C53"
      />
      <path
        d="M16.1863 9.61304C16.0531 9.61304 15.9456 9.72127 15.9456 9.8536C15.9456 9.9867 16.0539 10.0949 16.1863 10.0949C16.3204 10.0949 16.4279 9.9867 16.4279 9.8536C16.4279 9.72127 16.3204 9.61304 16.1863 9.61304Z"
        fill="#F15A50"
      />
      <path
        d="M15.4578 6.90139C15.4578 6.82119 15.3931 6.75659 15.3136 6.75659C15.2341 6.75659 15.1687 6.82119 15.1687 6.90139C15.1687 6.98079 15.2334 7.04619 15.3136 7.04619C15.3931 7.04539 15.4578 6.98079 15.4578 6.90139Z"
        fill="#F47848"
      />
      <path
        d="M15.0968 9.23863C15.3134 9.23863 15.488 9.06346 15.488 8.84783C15.488 8.6322 15.3134 8.45703 15.0968 8.45703C14.8809 8.45703 14.7063 8.6322 14.7063 8.84783C14.7055 9.06346 14.8809 9.23863 15.0968 9.23863Z"
        fill="#F1634D"
      />
      <path
        d="M14.8285 5.08472C14.7623 5.08472 14.7078 5.13845 14.7078 5.20538C14.7078 5.27232 14.7615 5.32605 14.8285 5.32605C14.8948 5.32605 14.9493 5.27232 14.9493 5.20538C14.9493 5.13922 14.8948 5.08472 14.8285 5.08472Z"
        fill="#F68A42"
      />
      <path
        d="M14.4221 9.32037C14.4221 9.38731 14.4759 9.44104 14.5429 9.44104C14.6092 9.44104 14.6637 9.38731 14.6637 9.32037C14.6637 9.25341 14.6099 9.19971 14.5429 9.19971C14.4759 9.20047 14.4221 9.25421 14.4221 9.32037Z"
        fill="#F15D4F"
      />
      <path
        d="M16.5453 7.73541C16.5453 7.33604 16.2211 7.01221 15.8213 7.01221C15.4216 7.01221 15.0974 7.33604 15.0974 7.73541C15.0974 8.13474 15.4216 8.45861 15.8213 8.45861C16.2211 8.45861 16.5453 8.13474 16.5453 7.73541Z"
        fill="#F36E4B"
      />
      <path
        d="M16.3574 8.51392C16.0901 8.51392 15.8743 8.73035 15.8743 8.99578C15.8743 9.26205 16.0901 9.47845 16.3574 9.47845C16.6239 9.47845 16.8398 9.26205 16.8398 8.99578C16.8398 8.73035 16.6239 8.51392 16.3574 8.51392Z"
        fill="#F1614E"
      />
      <path
        d="M16.9756 9.48839C16.8423 9.49305 16.7379 9.60439 16.7426 9.73829C16.7472 9.87139 16.8587 9.97572 16.9927 9.97025C17.126 9.96559 17.2296 9.85425 17.2249 9.72115C17.221 9.58882 17.1088 9.48449 16.9756 9.48839Z"
        fill="#F15C4F"
      />
      <path
        d="M17.1838 10.1887C17.1168 10.1887 17.063 10.2424 17.063 10.3094C17.063 10.3756 17.1168 10.4301 17.1838 10.4301C17.2508 10.4301 17.3046 10.3756 17.3046 10.3094C17.3046 10.2432 17.2508 10.1887 17.1838 10.1887Z"
        fill="#F15851"
      />
      <path
        d="M17.363 6.8378C17.2297 6.84246 17.1261 6.9538 17.13 7.0869C17.1347 7.22003 17.2469 7.32436 17.3802 7.31966C17.5134 7.315 17.6179 7.2037 17.6132 7.0698C17.6077 6.93743 17.4955 6.83313 17.363 6.8378Z"
        fill="#F3724A"
      />
      <path
        d="M15.026 4.64415C15.026 4.45652 14.881 4.30396 14.7034 4.30396C14.5257 4.30396 14.3823 4.45652 14.3823 4.64415C14.3823 4.83255 14.5265 4.98435 14.7034 4.98435C14.881 4.98512 15.026 4.83255 15.026 4.64415Z"
        fill="#F78F43"
      />
      <path
        d="M14.084 4.39191C14.084 4.45887 14.1378 4.51257 14.2048 4.51257C14.271 4.51257 14.3256 4.45887 14.3256 4.39191C14.3256 4.32497 14.2718 4.27124 14.2048 4.27124C14.1378 4.27124 14.084 4.32574 14.084 4.39191Z"
        fill="#F79442"
      />
      <path
        d="M12.8069 3.08002C12.8069 2.81378 12.591 2.59814 12.3237 2.59814C12.0572 2.59814 11.8413 2.81378 11.8413 3.08002C11.8413 3.34627 12.0572 3.5619 12.3237 3.5619C12.591 3.5619 12.8069 3.34547 12.8069 3.08002Z"
        fill="#F57F33"
      />
      <path
        d="M4.92703 10.6934C4.83739 10.6934 4.76416 10.7658 4.76416 10.8553C4.76416 10.9448 4.83739 11.0172 4.92703 11.0172C5.01666 11.0172 5.08913 10.9448 5.08913 10.8553C5.08913 10.7658 5.01586 10.6934 4.92703 10.6934Z"
        fill="#2650A3"
      />
      <path
        d="M6.37366 14.8125C6.19833 14.8125 6.05493 14.6701 6.05493 14.4949C6.05493 14.3182 6.19756 14.1758 6.37366 14.1758C6.5498 14.1758 6.6924 14.3182 6.6924 14.4949C6.6924 14.6701 6.5498 14.8125 6.37366 14.8125Z"
        fill="#4D3F99"
      />
    </svg>
  </Icon>
)

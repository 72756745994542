import React, { FC } from 'react'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  useAppSelector,
  useInjectReducer,
  useInjectSaga
} from 'shared/lib/@reduxjs'
import { useLineTournamentsContainerData } from 'entities/line-tournaments/model/hooks'
import {
  reducerLine,
  sliceKeyLine
} from 'entities/line-tournaments/model/slice'
import { lineTournamentsContainerSaga } from 'entities/line-tournaments/model/saga'

import { selectLiveIsEmpty } from './selectors'
import { useLiveContainerData } from './hooks'
import { reducerLive, sliceKeyLive } from './slice'
import { liveContainerSaga } from './saga'
import { LiveProps } from './types'

export const LiveContainer: FC<LiveProps> = ({ children }) => {
  useInjectReducer({ key: sliceKeyLive, reducer: reducerLive })
  useInjectSaga({ key: sliceKeyLive, saga: liveContainerSaga })

  // TODO remove after tags will be added to GET /events
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })
  const isEmpty = useAppSelector(selectLiveIsEmpty)

  useLiveContainerData()
  useLineTournamentsContainerData()

  return isEmpty ? <LoaderSpinner /> : <>{children}</>
}

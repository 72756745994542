import {
  EPostMessageIdEvent,
  useGetPostAuthServiceMessage
} from 'astra-core/containers/AuthMessageProvider'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface Message {
  to: string
}

export const useIframeLocation = () => {
  const history = useHistory()
  const location = useLocation()

  const handleMessage = useCallback(
    (payload?: Message) => {
      if (payload?.to) {
        history.push(payload.to)
      }
    },
    [history]
  )

  useEffect(() => {
    let page = ''
    if (location.pathname.startsWith('/line')) {
      page = '/line'
    }
    if (location.pathname.startsWith('/live')) {
      page = '/live'
    }

    window.parent.postMessage(
      {
        event_id: 'navigate',
        event_payload: {
          page
        }
      },
      { targetOrigin: '*' }
    )
  }, [location.pathname])

  useGetPostAuthServiceMessage('navigate' as EPostMessageIdEvent, handleMessage)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectCashoutByBetId,
  selectCashoutError,
  selectCurrentCashout,
  selectIsMakeCashoutLoading
} from 'astra-core/containers/BetsHistoryProvider'

import { DISABLE_HOVER_ON_CHILD } from 'widgets/bets-history/components/constants'
import { RootState } from 'shared/types/store'
import { Tooltip } from 'shared/ui/Tooltip'
import { MakeCashoutLoading } from 'features/basket-history/ui/BasketHistoryCashout/components/CurrentCashoutModal/constants'
import { setItemLocalStorage } from 'shared/lib/@system'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { CurrentCashoutModal } from '../CurrentCashoutModal'
import { StyledMakeCashoutLoadingWrapper } from '../CurrentCashoutModal/CurrentCashoutModal.styled'
import { NEW_MAKE_CASHOUT_LS } from '../CurrentCashoutModal/constants'

import {
  StyledCellEventCashoutText,
  StyledCellEventCashoutWrapper,
  CurrentCashoutModalWholeBackground
} from './CellEventCashout.styled'

const CellEventCashoutText = () => {
  const [t] = useTranslation()
  const isMakeCashoutLoading = useAppSelector(selectIsMakeCashoutLoading)

  const text = `${t('sell bet')}`

  return (
    <Tooltip
      isCommon={!isMakeCashoutLoading}
      title={t('currently impossible to sell a bet')}
    >
      <StyledCellEventCashoutText
        className={DISABLE_HOVER_ON_CHILD}
        disabled={isMakeCashoutLoading}
        isLoading={isMakeCashoutLoading}
        view="secondary-large"
      >
        {text}
      </StyledCellEventCashoutText>
    </Tooltip>
  )
}

export const CellEventCashout = ({ id, number }) => {
  const dispatch = useAppDispatch()
  const cashout = useAppSelector((state: RootState) =>
    selectCashoutByBetId(state, id)
  )
  const currentCashout = useAppSelector(selectCurrentCashout)
  const isMakeCashoutLoading = useAppSelector(selectIsMakeCashoutLoading)
  const isShowCurrentCashout = !!(currentCashout && currentCashout.betId === id)
  const isCashoutExist = !!(cashout && cashout.amount)
  const cashoutError = useAppSelector(selectCashoutError)
  const isShowCurrentCashoutModal =
    isShowCurrentCashout && !isMakeCashoutLoading

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const setCurrentCashout = (event) => {
    event.stopPropagation()

    if (isCashoutExist && !isShowCurrentCashout && !isMakeCashoutLoading) {
      dispatch(
        betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
      )

      setItemLocalStorage(NEW_MAKE_CASHOUT_LS, {
        betId: number,
        amount: cashout.amount
      })
    }
  }

  return isCashoutExist || !!cashoutError ? (
    <StyledCellEventCashoutWrapper onClick={setCurrentCashout}>
      {isMakeCashoutLoading && isShowCurrentCashout ? (
        <StyledMakeCashoutLoadingWrapper>
          <MakeCashoutLoading />
        </StyledMakeCashoutLoadingWrapper>
      ) : isCashoutExist ? (
        <CellEventCashoutText />
      ) : null}

      {isShowCurrentCashoutModal && (
        <>
          <CurrentCashoutModal number={number} />
          <CurrentCashoutModalWholeBackground onClick={unsetCurrentCashout} />
        </>
      )}
    </StyledCellEventCashoutWrapper>
  ) : null
}

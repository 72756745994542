import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M6.01171 5.14633C6.90575 4.34208 7.95995 3.71513 9.12007 3.3202C9.13627 3.31758 9.15204 3.31539 9.16745 3.3136C9.44152 3.28188 10.5368 3.30504 10.9246 3.35075C12.2399 3.50587 13.4432 3.91092 14.4427 4.53499C14.7385 4.71976 15.1692 5.02371 15.2167 5.08133C15.2401 5.10971 15.2038 5.15608 15.0192 5.33375C14.3055 6.02074 13.7865 6.44092 12.8224 7.11258L12.3535 7.43922L12.1544 7.27878C11.5939 6.82704 10.7006 6.29344 10.037 6.014C9.66099 5.85568 8.8569 5.58735 8.45641 5.48651C7.83791 5.3308 6.95857 5.19972 6.37085 5.17561C6.19785 5.16851 6.05159 5.15629 6.01171 5.14633Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M3.5374 8.82582C3.88501 7.85978 4.39061 6.97016 5.02268 6.18872L5.83119 6.19968C6.58831 6.20993 6.74344 6.21883 7.11666 6.27362C8.74436 6.51255 10.1317 7.0461 11.2392 7.85916C11.4035 7.97977 11.4324 8.01057 11.4056 8.03669C11.3737 8.06785 10.3615 8.69248 10.1969 8.78261C10.1502 8.80816 10.0411 8.87411 9.9544 8.92914C9.79633 9.02945 8.98809 9.50069 8.69403 9.66398L8.53723 9.75106L8.2332 9.59824C7.66463 9.31242 7.17667 9.14745 6.49818 9.01158C5.70742 8.85326 5.55804 8.84067 4.47905 8.8416C3.80767 8.84216 3.59806 8.84219 3.5374 8.82582Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M7.47654 19.7719C4.79938 18.1991 3 15.2728 3 11.9228C3 11.2383 3.07511 10.5716 3.21746 9.93046C3.23504 9.92147 3.25822 9.91937 3.29178 9.91443C3.43423 9.89344 3.7853 9.86088 3.91186 9.85691L4.01778 9.85359L4.00226 10.0061C3.73522 12.6282 4.34664 15.1501 5.84979 17.6267C6.25379 18.2923 6.81125 19.0366 7.31775 19.5868C7.38822 19.6633 7.44757 19.7329 7.47654 19.7719Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M12.9041 20.9548C12.6067 20.9847 12.3051 21 12 21C11.8497 21 11.7002 20.9963 11.5517 20.9889C11.3836 20.9639 11.2147 20.9254 11.013 20.8698C10.2774 20.6671 9.5943 20.2675 8.68105 19.5054C7.7382 18.7186 6.86798 17.5481 6.15038 16.1013C5.58406 14.9596 5.20308 13.7651 5.04509 12.6358C4.9228 11.7618 4.91482 10.8096 5.02268 9.96615L5.03793 9.84685L5.2951 9.8635C5.43655 9.87265 5.67233 9.89674 5.81906 9.91704C6.50577 10.0121 7.20257 10.2127 7.71787 10.4637L7.99342 10.5979L8.00128 11.2569C8.01193 12.1517 8.08802 12.7982 8.26934 13.5349C8.53361 14.6086 9.03793 15.6391 9.7227 16.5045C9.97899 16.8284 10.6532 17.5044 10.9852 17.7704C11.3274 18.0446 11.8473 18.3929 12.2396 18.6108C12.5506 18.7835 13.3589 19.1787 13.4712 19.213C13.5046 19.2232 13.572 19.2518 13.6211 19.2768L13.7103 19.322L13.661 19.4994C13.5273 19.9806 13.331 20.4096 13.1055 20.7131C13.0121 20.8389 12.9632 20.9118 12.9041 20.9548Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M19.8197 16.4198C19.3779 17.1995 18.8248 17.9068 18.1822 18.5196C17.1027 19.5491 15.7706 20.3119 14.2895 20.7037C14.3019 20.6636 14.3309 20.5885 14.3665 20.5052C14.4683 20.2676 14.6354 19.7593 14.6845 19.5378L14.7153 19.3988L14.924 19.3092C15.5514 19.0398 16.7061 18.4788 17.0487 18.2769C17.189 18.1943 18.2243 17.5779 18.2243 17.5779L18.6235 17.3057C19.0238 17.0328 19.1793 16.9182 19.6075 16.5804C19.715 16.4956 19.7805 16.4453 19.8197 16.4198Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M20.982 11.344C20.9939 11.5354 21 11.7284 21 11.9228C21 12.6561 20.9138 13.3692 20.751 14.0522C20.7044 14.1239 20.6363 14.2042 20.5277 14.332C19.9688 14.9902 19.3112 15.5943 18.592 16.1102C17.4239 16.9482 16.2754 17.6052 14.9262 18.2075C14.6392 18.3356 14.376 18.4476 14.3412 18.4564C14.2751 18.4731 13.9318 18.3345 13.3147 18.042C12.8342 17.8142 12.016 17.329 12.016 17.2719C12.016 17.2609 12.0787 17.2283 12.1555 17.1994C12.4532 17.0874 13.2912 16.717 13.8373 16.4561C14.3585 16.207 14.7132 16.0224 15.4358 15.6242C16.6346 14.9637 18.0996 13.9286 19.155 12.9965C19.3479 12.8262 20.7256 11.5769 20.9046 11.41C20.9323 11.3842 20.96 11.3607 20.982 11.344Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M19.8118 7.4118C20.2817 8.23792 20.6266 9.14569 20.8203 10.1088L20.7694 10.1625C20.5777 10.3647 18.3694 12.3474 17.9514 12.6926C17.1745 13.3344 16.0023 14.1505 15.2079 14.6028C14.4472 15.0359 13.4021 15.5602 12.5738 15.9242C12.1565 16.1076 11.144 16.5162 11.1069 16.5162C11.0683 16.5162 10.7785 16.2111 10.5119 15.8899C10.2716 15.6003 9.93458 15.0729 9.76107 14.7148C9.69398 14.5764 9.63909 14.4595 9.63909 14.455C9.63909 14.4505 9.79451 14.3843 9.98447 14.3078C12.8637 13.1485 15.1449 11.8019 17.0603 10.1308C17.9378 9.36521 19.2483 8.08691 19.6536 7.6013L19.8118 7.4118Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M17.1073 4.44763C17.9182 5.01232 18.6328 5.70779 19.221 6.50383L19.1606 6.5907C18.9803 6.84978 18.6417 7.24 18.3232 7.55578C17.5546 8.31781 16.626 9.18481 16.0411 9.68644C15.441 10.2012 14.4476 10.9014 13.5804 11.4208C12.5613 12.0313 11.4427 12.5896 10.1175 13.1493C9.86104 13.2576 9.59119 13.3719 9.51782 13.4034C9.39854 13.4545 9.34178 13.4848 9.30704 13.4685C9.26895 13.4505 9.25735 13.3763 9.21882 13.2117C9.07281 12.5879 9.00909 11.9728 9.00873 11.1834L9.00848 10.6463L9.66941 10.2683C10.3065 9.90386 10.5169 9.77913 11.3854 9.25109C11.8141 8.99042 12.3776 8.63154 12.4768 8.55598C12.5102 8.53058 12.6848 8.41321 12.8649 8.29514C13.1466 8.11042 13.8099 7.6443 14.2444 7.32564C14.3635 7.23824 14.6161 7.03209 15.0791 6.64429C15.6007 6.20744 16.5649 5.17212 16.9699 4.61409C17.0252 4.53798 17.0797 4.47235 17.1073 4.44763Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M16.2296 3.90837C16.1911 3.97268 15.994 4.23777 15.9215 4.32044C15.9124 4.33094 15.7662 4.23539 15.5967 4.10809C15.4273 3.98082 15.2044 3.82489 15.1015 3.76159C14.9986 3.69828 14.8653 3.61455 14.8052 3.57547C14.7138 3.516 14.126 3.21994 13.7138 3.02574C13.6923 3.01562 13.6749 3.00703 13.6621 3C14.5737 3.17167 15.437 3.48197 16.2296 3.90837Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M2.67707 1.90784C3.47177 1.19296 4.40885 0.635672 5.44006 0.284624C5.45446 0.282293 5.46848 0.280343 5.48217 0.278759C5.72579 0.250557 6.69941 0.271145 7.04406 0.311777C8.21323 0.449661 9.28283 0.80971 10.1712 1.36444C10.4342 1.52868 10.817 1.79885 10.8593 1.85007C10.8801 1.8753 10.8479 1.91652 10.6838 2.07445C10.0493 2.6851 9.58803 3.0586 8.731 3.65563L8.3142 3.94597L8.13726 3.80336C7.63905 3.40181 6.845 2.9275 6.2551 2.67911C5.92088 2.53839 5.20613 2.29986 4.85014 2.21023C4.30036 2.07182 3.51873 1.95531 2.99631 1.93387C2.84253 1.92756 2.71252 1.9167 2.67707 1.90784Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M0.477692 5.1785C0.78668 4.31981 1.23609 3.52903 1.79794 2.83442L2.51661 2.84416C3.18961 2.85327 3.3275 2.86119 3.65925 2.90989C5.1061 3.12227 6.33926 3.59653 7.32377 4.31926C7.46979 4.42647 7.49546 4.45384 7.47168 4.47706C7.44327 4.50476 6.54356 5.05998 6.39728 5.1401C6.35578 5.16281 6.25876 5.22143 6.18169 5.27035C6.04118 5.35951 5.32274 5.77839 5.06136 5.92354L4.92198 6.00094L4.65173 5.8651C4.14634 5.61104 3.71259 5.4644 3.10949 5.34363C2.4066 5.2029 2.27381 5.19171 1.31471 5.19253C0.717931 5.19303 0.531607 5.19305 0.477692 5.1785Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M3.97915 14.9084C1.59945 13.5103 0 10.9092 0 7.93134C0 7.32296 0.0667614 6.7303 0.193297 6.16041C0.208925 6.15242 0.229529 6.15056 0.25936 6.14616C0.385978 6.1275 0.698048 6.09856 0.810544 6.09503L0.904693 6.09208L0.890895 6.22766C0.653528 8.55838 1.19702 10.8001 2.53315 13.0015C2.89226 13.5932 3.38778 14.2548 3.838 14.7438C3.90064 14.8118 3.9534 14.8737 3.97915 14.9084Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M8.80362 15.9598C8.53932 15.9864 8.27124 16 8 16C7.86638 16 7.73352 15.9967 7.60151 15.9902C7.45207 15.9679 7.30194 15.9337 7.12268 15.8843C6.46879 15.7041 5.8616 15.3488 5.04983 14.6715C4.21173 13.9721 3.43821 12.9316 2.80033 11.6456C2.29695 10.6308 1.95829 9.56895 1.81786 8.56515C1.70916 7.78828 1.70206 6.94186 1.79794 6.19213L1.8115 6.08609L2.04009 6.10089C2.16582 6.10902 2.3754 6.13043 2.50583 6.14848C3.11624 6.23298 3.73562 6.41128 4.19366 6.63442L4.4386 6.75373L4.44558 7.33946C4.45505 8.13483 4.52268 8.70954 4.68386 9.36436C4.91877 10.3188 5.36705 11.2347 5.97573 12.004C6.20355 12.2919 6.80281 12.8928 7.09795 13.1293C7.40213 13.373 7.86423 13.6826 8.21296 13.8762C8.48941 14.0298 9.20791 14.3811 9.30778 14.4116C9.33742 14.4206 9.39736 14.4461 9.44097 14.4682L9.52024 14.5085L9.47646 14.6661C9.3576 15.0939 9.1831 15.4752 8.98269 15.7449C8.89961 15.8568 8.85617 15.9216 8.80362 15.9598Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M14.9508 11.9287C14.5581 12.6218 14.0665 13.2505 13.4953 13.7952C12.5357 14.7103 11.3516 15.3884 10.0351 15.7366C10.0462 15.7009 10.0719 15.6342 10.1036 15.5601C10.194 15.349 10.3426 14.8971 10.3862 14.7002L10.4136 14.5767L10.5991 14.497C11.1568 14.2576 12.1832 13.7589 12.4878 13.5794C12.6124 13.506 13.5327 12.9581 13.5327 12.9581L13.8875 12.7162C14.2434 12.4736 14.3816 12.3717 14.7623 12.0714C14.8578 11.9961 14.916 11.9514 14.9508 11.9287Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M15.984 7.41692C15.9946 7.58702 16 7.75855 16 7.93134C16 8.58324 15.9233 9.21708 15.7786 9.8242C15.7372 9.88795 15.6767 9.95926 15.5802 10.0729C15.0834 10.658 14.4989 11.1949 13.8595 11.6535C12.8212 12.3984 11.8004 12.9824 10.6011 13.5178C10.346 13.6316 10.112 13.7312 10.0811 13.739C10.0223 13.7538 9.71712 13.6306 9.16866 13.3706C8.74154 13.1682 8.01422 12.7369 8.01422 12.6861C8.01422 12.6763 8.07 12.6474 8.13819 12.6217C8.40283 12.5221 9.14772 12.1929 9.63315 11.9609C10.0964 11.7396 10.4117 11.5755 11.0541 11.2215C12.1196 10.6344 13.4219 9.71428 14.36 8.88578C14.5315 8.73436 15.7561 7.62395 15.9152 7.47559C15.9399 7.45263 15.9645 7.43177 15.984 7.41692Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M14.9438 3.9216C15.3615 4.65593 15.6681 5.46283 15.8402 6.31894L15.795 6.36668C15.6246 6.54636 13.6617 8.30882 13.2902 8.61567C12.5995 9.1861 11.5576 9.91154 10.8515 10.3136C10.1753 10.6986 9.24633 11.1646 8.51009 11.4882C8.13912 11.6512 7.2391 12.0144 7.20612 12.0144C7.17184 12.0144 6.9142 11.7432 6.67727 11.4577C6.46362 11.2002 6.16407 10.7314 6.00984 10.4132C5.9502 10.2901 5.90142 10.1862 5.90142 10.1822C5.90142 10.1782 6.03957 10.1193 6.20842 10.0514C8.76772 9.02093 10.7955 7.82388 12.498 6.3385C13.278 5.65797 14.443 4.5217 14.8032 4.09004L14.9438 3.9216Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M12.5398 1.28678C13.2606 1.78873 13.8958 2.40693 14.4187 3.11451L14.365 3.19173C14.2047 3.42203 13.9038 3.76889 13.6207 4.04958C12.9374 4.72694 12.112 5.49761 11.5921 5.9435C11.0586 6.4011 10.1757 7.02345 9.40479 7.4852C8.49894 8.02782 7.50463 8.5241 6.32667 9.02158C6.0987 9.11784 5.85884 9.21947 5.79362 9.24743C5.68759 9.29287 5.63714 9.31985 5.60626 9.30529C5.5724 9.28933 5.56209 9.2234 5.52784 9.07707C5.39805 8.52256 5.34141 7.97582 5.34109 7.27416L5.34088 6.79669L5.92836 6.46067C6.49466 6.13677 6.68173 6.02589 7.45368 5.55653C7.83476 5.32482 8.33567 5.00581 8.42385 4.93865C8.45349 4.91607 8.60872 4.81174 8.7688 4.70679C9.01923 4.54259 9.60876 4.12827 9.995 3.84501C10.1009 3.76733 10.3254 3.58408 10.737 3.23937C11.2006 2.85106 12.0577 1.93077 12.4177 1.43475C12.4668 1.36709 12.5153 1.30875 12.5398 1.28678Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
      <path
        d="M11.7597 0.807436C11.7254 0.864609 11.5502 1.10024 11.4858 1.17373C11.4776 1.18306 11.3477 1.09813 11.1971 0.984972C11.0465 0.87184 10.8484 0.73324 10.7569 0.676967C10.6654 0.620693 10.5469 0.546263 10.4935 0.511531C10.4123 0.458664 9.88979 0.195499 9.52337 0.0228829C9.50427 0.0138857 9.48881 0.00624829 9.4774 0C10.2878 0.152597 11.0551 0.428415 11.7597 0.807436Z"
        fill={theme.tokens.colors['sport-icon']['sport-netball']}
      />
    </>
  )
}

export const IconSportNetball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  ECashoutStatusView,
  selectCashoutStatusView
} from 'astra-core/containers/BetsHistoryProvider'
import { formatAmountLocaleString, useGetCurrencyIcon } from 'astra-core'

import { useTimer } from 'hooks'
import { ENotificationContainerIds } from 'shared/lib/Toastify'
import { IconWarningTriangleFilled16 } from 'shared/ui/Icon/General/IconWarningTriangleFilled16'
import { getItemLocalStorage } from 'shared/lib/@system'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { NEW_MAKE_CASHOUT_LS } from '../CurrentCashoutModal/constants'

import {
  TOASTIFY_CASHOUT_ID,
  TToastifyCashoutNotificationProps
} from './WithdrawalFormMethods.types'
import {
  StyledDeleteHistoryHint,
  StyledIconCheckCircleFilled16,
  StyledToastifyCashoutNotificationText,
  StyledToastifyCashoutNotificationTextWrapper
} from './ToastifyCashoutNotification.styled'
import { TOASTIFY_CASHOUT_NOTIFICATION_TIMER } from './constants'

export const ToastifyCashoutNotification: FC<
  TToastifyCashoutNotificationProps
> = ({ toastProps }) => {
  // TODO try to use setTimetout
  const seconds = useTimer(TOASTIFY_CASHOUT_NOTIFICATION_TIMER)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!seconds) {
      toast.update(toastProps.toastId, {
        closeButton: false,
        containerId: ENotificationContainerIds.SYSTEM,
        autoClose: 10,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: false,
        toastId: TOASTIFY_CASHOUT_ID
      })
    }
  }, [dispatch, seconds, toastProps.toastId])

  return (
    <StyledDeleteHistoryHint>
      <ToastifyCashoutNotificationText />
    </StyledDeleteHistoryHint>
  )
}

const ToastifyCashoutNotificationText = () => {
  const [t] = useTranslation()
  const cashoutStatusView = useAppSelector(selectCashoutStatusView)
  // const soldBet = useAppSelector(selectCashoutSoldBet)
  const currencyIcon = useGetCurrencyIcon()
  const cashoutData = getItemLocalStorage(NEW_MAKE_CASHOUT_LS)

  const isCashoutData = cashoutData?.betId && cashoutData?.amount

  const cashoutText = useMemo(
    () =>
      isCashoutData
        ? t('betid sold for amount', {
            betId: cashoutData?.betId,
            betAmount: `${formatAmountLocaleString({
              value: cashoutData?.amount,
              currency: currencyIcon
            })}`
          })
        : t('bet sold'),
    [isCashoutData, t, cashoutData?.betId, cashoutData?.amount, currencyIcon]
  )

  const sellingBetIdText = useMemo(
    () => `${t('selling a bet')} #${cashoutData?.betId}`,
    [cashoutData?.betId, t]
  )

  const cashoutTextError = useMemo(
    () =>
      isCashoutData
        ? `${sellingBetIdText} ${t('bet unavailable')}`
        : `${t('failed to sell bet')}`,
    [isCashoutData, sellingBetIdText, t]
  )

  if (ECashoutStatusView.SUCCESS === cashoutStatusView && cashoutData) {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <StyledIconCheckCircleFilled16 colorToken="icon-positive" />

        <StyledToastifyCashoutNotificationText>
          {cashoutText}
        </StyledToastifyCashoutNotificationText>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
    // TODO NIT: no need for else in these cases
  } else {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <IconWarningTriangleFilled16 colorToken="icon-warning" size={16} />

        <StyledToastifyCashoutNotificationText>
          {cashoutTextError}
        </StyledToastifyCashoutNotificationText>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
  }
}

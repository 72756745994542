import { FC, PropsWithChildren, useEffect, useState } from 'react'

import { HydratedContext } from './hydrated-context'

export const HydratedProvider: FC<PropsWithChildren> = ({ children }) => {
  const [hydrated, setHydrated] = useState(false)

  useEffect(() => {
    setHydrated(true)
  }, [])

  return (
    <HydratedContext.Provider value={hydrated}>
      {children}
    </HydratedContext.Provider>
  )
}

import { FC, memo, useMemo } from 'react'
import { Event } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'

import { EEventStatisticsCodes } from 'shared/lib/events/types'

import * as S from './score-cell.styled'

export const LiveScoreCell: FC<{ eventStatistics: Event['statistics'] }> = memo(
  ({ eventStatistics }) => {
    const gameScoreStatistics = eventStatistics.find(
      (s) => s.code === EEventStatisticsCodes.GameScore
    )
    const scoreByPeriodsStatistics = eventStatistics.find(
      (s) => s.code === EEventStatisticsCodes.ScoresByPeriods
    )

    const scoreStatistics = eventStatistics.find(
      (s) => s.code === EEventStatisticsCodes.Score
    )

    const gameScore = gameScoreStatistics?.value
      .split(':')
      .map((item) => (item === '50' ? 'A' : item))
    const scoreByPeriods = useMemo(
      () =>
        scoreByPeriodsStatistics?.value
          .split(',')
          .map((item) => item.split(':'))
          .map(([home, away], period) => ({
            home,
            away,
            period
          })),
      [scoreByPeriodsStatistics?.value]
    )

    const score = scoreStatistics?.value.split(':')

    return (
      <S.LiveContentScoreCell>
        <S.ScoresStatistics>
          {(scoreByPeriods || gameScore) && (
            <S.EventScoreByPeriods>
              {gameScore && (
                <S.EventScoreByPeriodsColumn>
                  <span>({gameScore[0]})</span>
                  <span>({gameScore[1]})</span>
                </S.EventScoreByPeriodsColumn>
              )}

              {scoreByPeriods && (
                <>
                  {scoreByPeriods.map((item) => (
                    <S.EventScoreByPeriodsColumn key={item.period}>
                      <span>{item.home}</span>
                      <span>{item.away}</span>
                    </S.EventScoreByPeriodsColumn>
                  ))}
                </>
              )}
            </S.EventScoreByPeriods>
          )}

          <S.EventScore>
            {score?.length && <div>{score[0]}</div>}
            {score?.length && <div>{score[1]}</div>}
          </S.EventScore>
        </S.ScoresStatistics>
      </S.LiveContentScoreCell>
    )
  },
  isEqual
)

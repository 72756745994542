import { ErrorCode } from 'betweb-openapi-axios'

import { LegacyResponseError, GetCashoutResponse } from 'entities/legacy-api'

export enum ECurrentCashoutModalContent {
  ACTIVE_SELL = 'active sell',
  AMOUNT_HAS_CHANGED = 'amount has changed',
  SELL_NOT_POSSIBLE = 'sell not possible',
  SELL_BET_ERROR = 'sell bet error'
}

export const CASHOUT_BUTTOR_ERROR_TEXT = {
  [ErrorCode.IncorrectCashoutAmount]: 'update amount'
}

export interface ICurrentCashoutModalContent {
  cashoutAmount: string
  contentType: ECurrentCashoutModalContent
  makeCashout: () => void
  closeCashout: () => void
  cashoutError?: IGetCurrentCashoutModalContent['cashoutError']
}

export interface IGetCurrentCashoutModalContent
  extends Omit<ICurrentCashoutModalContent, 'contentType'> {
  cashoutError: LegacyResponseError | null
  cashout: GetCashoutResponse['data']
}

import { MainOutcomeTypes } from 'astra-core/containers/CommonDataProvider'
import { HotProbabilityMarketType } from 'betweb-openapi-axios'

import { HotProbsMarketsRowMap } from 'features/events-table/lib/hot-probabilities-groups/types'

export const HOT_PROBS_MARKETS_ROW_MAP: HotProbsMarketsRowMap = {
  [HotProbabilityMarketType.MatchWinnerX3]: {
    id: HotProbabilityMarketType.MatchWinnerX3,
    config: [
      {
        outcomeTypesName: ['W1'],
        mainOutcomeType: MainOutcomeTypes.W1
      },
      {
        outcomeTypesName: ['WX'],
        mainOutcomeType: MainOutcomeTypes.WX
      },
      {
        outcomeTypesName: ['W2'],
        mainOutcomeType: MainOutcomeTypes.W2
      }
    ]
  },
  [HotProbabilityMarketType.MatchWinnerX2]: {
    id: HotProbabilityMarketType.MatchWinnerX2,
    config: [
      {
        outcomeTypesName: ['W1_X2'],
        mainOutcomeType: MainOutcomeTypes.W1_X2
      },
      {
        outcomeTypesName: ['W2_X2'],
        mainOutcomeType: MainOutcomeTypes.W2_X2
      }
    ]
  },
  [HotProbabilityMarketType.DoubleChance]: {
    id: HotProbabilityMarketType.DoubleChance,
    config: [
      {
        outcomeTypesName: ['W1X'],
        mainOutcomeType: MainOutcomeTypes.W1X
      },
      {
        outcomeTypesName: ['W12'],
        mainOutcomeType: MainOutcomeTypes.W12
      },
      {
        outcomeTypesName: ['WX2'],
        mainOutcomeType: MainOutcomeTypes.WX2
      }
    ]
  },
  [HotProbabilityMarketType.Handicap]: {
    id: HotProbabilityMarketType.Handicap,
    config: [
      {
        outcomeTypesName: ['HC1'],
        mainOutcomeType: MainOutcomeTypes.HC1,
        withParam: true
      },
      {
        outcomeTypesName: ['HC2'],
        mainOutcomeType: MainOutcomeTypes.HC2,
        withParam: true
      }
    ]
  },
  [HotProbabilityMarketType.Total]: {
    id: HotProbabilityMarketType.Total,
    isMerged: true,
    config: [
      {
        outcomeTypesName: ['TO'],
        mainOutcomeType: MainOutcomeTypes.TO,
        withParam: true
      },
      {
        outcomeTypesName: ['TU'],
        mainOutcomeType: MainOutcomeTypes.TU
      }
    ]
  },
  [HotProbabilityMarketType.HandicapWithOt]: {
    id: HotProbabilityMarketType.HandicapWithOt,
    config: [
      {
        outcomeTypesName: ['HC1O'],
        mainOutcomeType: MainOutcomeTypes.HC1O,
        withParam: true
      },
      {
        outcomeTypesName: ['HC2O'],
        mainOutcomeType: MainOutcomeTypes.HC2O,
        withParam: true
      }
    ]
  },
  [HotProbabilityMarketType.TotalWithOt]: {
    id: HotProbabilityMarketType.TotalWithOt,
    isMerged: true,
    config: [
      {
        outcomeTypesName: ['TOO'],
        mainOutcomeType: MainOutcomeTypes.TOO,
        withParam: true,
        paramCellWidth: 64
      },
      {
        outcomeTypesName: ['TUO'],
        mainOutcomeType: MainOutcomeTypes.TUO
      }
    ]
  },
  [HotProbabilityMarketType.MatchWinnerX2WithOt]: {
    id: HotProbabilityMarketType.MatchWinnerX2WithOt,
    config: []
  },
  [HotProbabilityMarketType.Unknown]: {
    id: HotProbabilityMarketType.Unknown,
    config: []
  }
}

export const HOT_PROBS_MARKETS_SMALL_ROW_MAP: HotProbsMarketsRowMap = {
  [HotProbabilityMarketType.MatchWinnerX3]: {
    id: HotProbabilityMarketType.MatchWinnerX3,
    config: [
      {
        outcomeTypesName: ['W1'],
        mainOutcomeType: MainOutcomeTypes.W1
      },
      {
        outcomeTypesName: ['WX'],
        mainOutcomeType: MainOutcomeTypes.WX
      },
      {
        outcomeTypesName: ['W2'],
        mainOutcomeType: MainOutcomeTypes.W2
      }
    ]
  },
  [HotProbabilityMarketType.MatchWinnerX2]: {
    id: HotProbabilityMarketType.MatchWinnerX2,
    config: [
      {
        outcomeTypesName: ['W1_X2'],
        mainOutcomeType: MainOutcomeTypes.W1_X2
      },
      {
        outcomeTypesName: ['W2_X2'],
        mainOutcomeType: MainOutcomeTypes.W2_X2
      }
    ]
  },
  [HotProbabilityMarketType.DoubleChance]: {
    id: HotProbabilityMarketType.DoubleChance,
    config: [
      {
        outcomeTypesName: ['W1X'],
        mainOutcomeType: MainOutcomeTypes.W1X
      },
      {
        outcomeTypesName: ['W12'],
        mainOutcomeType: MainOutcomeTypes.W12
      },
      {
        outcomeTypesName: ['WX2'],
        mainOutcomeType: MainOutcomeTypes.WX2
      }
    ]
  },
  [HotProbabilityMarketType.Handicap]: {
    id: HotProbabilityMarketType.Handicap,
    config: [
      {
        outcomeTypesName: ['HC1'],
        mainOutcomeType: MainOutcomeTypes.HC1,
        withParam: true,
        isCompact: true
      },
      {
        outcomeTypesName: ['HC2'],
        mainOutcomeType: MainOutcomeTypes.HC2,
        withParam: true,
        isCompact: true
      }
    ]
  },
  [HotProbabilityMarketType.Total]: {
    id: HotProbabilityMarketType.Total,
    config: [
      {
        outcomeTypesName: ['TO'],
        mainOutcomeType: MainOutcomeTypes.TO,
        withParam: true,
        isCompact: true
      },
      {
        outcomeTypesName: ['TU'],
        mainOutcomeType: MainOutcomeTypes.TU,
        withParam: true,
        isCompact: true
      }
    ]
  },
  [HotProbabilityMarketType.HandicapWithOt]: {
    id: HotProbabilityMarketType.HandicapWithOt,
    config: [
      {
        outcomeTypesName: ['HC1O'],
        mainOutcomeType: MainOutcomeTypes.HC1O,
        withParam: true,
        isCompact: true
      },
      {
        outcomeTypesName: ['HC2O'],
        mainOutcomeType: MainOutcomeTypes.HC2O,
        withParam: true,
        isCompact: true
      }
    ]
  },
  [HotProbabilityMarketType.TotalWithOt]: {
    id: HotProbabilityMarketType.TotalWithOt,
    config: [
      {
        outcomeTypesName: ['TOO'],
        mainOutcomeType: MainOutcomeTypes.TOO,
        withParam: true,
        isCompact: true
      },
      {
        outcomeTypesName: ['TUO'],
        mainOutcomeType: MainOutcomeTypes.TUO,
        withParam: true,
        isCompact: true
      }
    ]
  },
  [HotProbabilityMarketType.MatchWinnerX2WithOt]: {
    id: HotProbabilityMarketType.MatchWinnerX2WithOt,
    config: []
  },
  [HotProbabilityMarketType.Unknown]: {
    id: HotProbabilityMarketType.Unknown,
    config: []
  }
}

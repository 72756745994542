import { FC, memo, useCallback, useMemo } from 'react'
import {
  basketProviderActions,
  selectOutcomeType
} from 'astra-core/containers/BasketProvider'
import { selectOutcomeCategoryById } from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import isEqual from 'react-fast-compare'

import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { RootState } from 'shared/types/store'
import { BasketCardDependent } from 'entities/basket'
import { CoefficientCoupon } from 'features/coefficient-coupon'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { CardDependentProps } from './card-dependent.types'

export const CardDependent: FC<CardDependentProps> = memo(({ outcome }) => {
  const { event, probability, id: outcomeId } = outcome

  const dispatch = useAppDispatch()
  const outcomeType = useAppSelector((state: RootState) =>
    selectOutcomeType(state, probability.outcomeTypeId)
  )
  const outcomeCategory = useAppSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcomeType?.categoryId)
  )
  const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLDivElement>()

  const basketOutcomeName = useMemo(() => {
    return outcomeType
      ? getBasketOutcomeName({
          probWithOutcome: {
            ...probability,
            outcomeTypeData: outcomeType
          },
          event,
          outcomeCategory
        })
      : ''
  }, [event, outcomeCategory, outcomeType, probability])

  const deleteBasketOutcome = useCallback(() => {
    dispatch(basketProviderActions.deleteBasketOutcome({ outcomeId }))
  }, [dispatch, outcomeId])

  return (
    <BasketCardDependent
      Coupon={
        <CoefficientCoupon
          eventId={event.id}
          eventProbability={outcome.probability}
        />
      }
      isCommonTooltipTitle={!isOutcomeOverflowing}
      title={basketOutcomeName}
      titleRef={outcomeRef}
      onClickDeleteOutcome={deleteBasketOutcome}
    />
  )
}, isEqual)

import { getCombinations } from '../get-combinations'

const MAX_EXPRESS_RATE = 2000

export const getSystemMaxWinSum = (systemSize, value: number, outcomes) => {
  if (!systemSize) return 0

  const combinations = getCombinations(outcomes, systemSize)

  const newCombination = combinations
    .map((stakes) =>
      stakes.reduce((acc: any, stake: any) => acc * stake.probability.odd, 1)
    )
    .map((coef: any) => Math.min(coef, MAX_EXPRESS_RATE))
    .map((coef, _, expresses) => coef * (value / expresses.length))
    .reduce((acc, sum) => acc + sum, 0)
    .toFixed(2)

  return +newCombination
}

import styled, { css } from 'styled-components'

import { AppLink } from 'shared/lib/app-link'

export const MainInfoWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    grid-template-columns: minmax(0, 1fr) auto;
    justify-content: space-between;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledCompetitorsCell = styled.td`
  padding-left: 16px;
  padding-right: 8px;
  width: 100%;
`

export const CompetitorsWrapper = styled(AppLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CompetitorName = styled.div`
  align-items: center;
  display: grid;
  gap: 4px;
  grid-template-columns: minmax(0, 1fr) auto;
`

export const NameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CurrentServerPoint = styled.div`
  background-color: #0d80f2;
  border-radius: 50%;
  height: 4px;
  width: 4px;
`

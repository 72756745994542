import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M12.9326 16.5109C17.4617 16.0541 21.0009 12.3408 21.0009 7.87012C21.0009 4.37482 18.8431 1.36367 15.7376 0L15.7379 0.269907C18.1778 1.5508 19.8327 4.03893 19.8327 6.89883C19.8327 10.1675 16.8223 13.8543 12.9326 14.3833C12.9326 13.6219 12.9326 11.8026 12.9326 11.2939C13.1169 10.6815 14.2071 10.6643 14.2071 10.6643V10.4728H9.77687V10.6643C9.77687 10.6643 10.8664 10.6815 11.0515 11.2939C11.0515 11.8026 11.0497 13.6226 11.0497 14.3833C7.23205 13.9313 4.12078 10.758 4.12078 6.89883C4.12078 4.01866 5.79166 1.54538 8.25054 0.269923V0C5.1525 1.36656 3.00085 4.38293 3.00085 7.87304C3.00085 12.5881 6.52274 16.0827 11.05 16.5109L11.05 19.2575L12.0395 24L12.9326 19.2575V16.5109Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M8.62201 11.0073C11.6414 10.7027 14.0009 8.22722 14.0009 5.24675C14.0009 2.91655 12.5624 0.909114 10.4921 0L10.4922 0.179938C12.1188 1.03387 13.2221 2.69262 13.2221 4.59922C13.2221 6.77835 11.2151 9.2362 8.62201 9.58885C8.62201 9.08129 8.62201 7.86839 8.62201 7.52925C8.74488 7.12099 9.4717 7.10956 9.4717 7.10956V6.98184H6.5182V7.10956C6.5182 7.10956 7.24454 7.12099 7.36792 7.52925C7.36792 7.86838 7.36677 9.08174 7.36677 9.58885C4.82165 9.28755 2.74747 7.17202 2.74747 4.59922C2.74747 2.6791 3.86139 1.03026 5.50064 0.179949V0C3.43528 0.91104 2.00085 2.92195 2.00085 5.2487C2.00085 8.39207 4.34878 10.7218 7.36695 11.0073L7.36698 12.8384L8.02664 16L8.62201 12.8384V11.0073Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportQuake: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M21.9386 16.2347L21.8633 16.0449C21.7235 15.7287 21.347 15.5706 21.0243 15.7076L14.624 18.3112L14.9037 17.6366C15.7212 15.5812 12.8384 14.4322 11.8165 14.0949L12.3974 12.8195C12.7093 13.083 13.7958 13.979 14.3336 14.4217C15.1726 15.1068 17.7543 17.0463 17.7543 17.0463L18.9232 16.5624C18.9232 16.5624 17.2908 15.0811 16 13.5C15.2255 12.5513 13.1181 10.353 13.1181 10.353L10.0202 6.8219C9.54686 5.57811 8.47119 2.62674 7.89032 0.29726C7.82578 0.0442848 7.05129 -0.440584 7.05129 0.898075C7.05129 4.28161 7.76124 8.68759 7.93335 9.69949C7.12659 11.2279 6.45967 13.2833 6.76086 15.6444C6.92221 16.8882 8.35286 17.605 9.64367 17.605H9.99864L9.22416 20.5247L4.1147 22.6012C3.792 22.7382 3.63065 23.1072 3.77048 23.4234L3.84578 23.6131C3.98562 23.9293 4.3621 24.0874 4.68481 23.9504L21.6052 17.0779C21.9279 16.9409 22.0892 16.572 21.9494 16.2558L21.9386 16.2347ZM12.9137 17.0358C13.1934 16.9831 13.2579 16.9936 13.2579 16.9936L12.6878 18.9331L11.5584 19.4706C11.5584 19.4706 12.236 17.1517 12.903 17.0252L12.9137 17.0358Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
      <path
        d="M8.70783 18.1742L2.98524 16.2663C2.598 16.1398 2.16773 16.3401 2.03865 16.7195C1.90956 17.099 2.11394 17.5206 2.50119 17.6471L8.35286 19.5971L8.69708 18.1636L8.70783 18.1742Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
      <path
        d="M20.1315 21.9793L16.0009 20.5985L13.8603 21.4312L19.6582 23.3601C20.0455 23.4866 20.4757 23.2864 20.6048 22.9069C20.7339 22.5274 20.5295 22.1058 20.1423 21.9793H20.1315Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
      <path
        d="M14.0862 9.36219H14.0755C15.689 9.10922 16.1085 6.56893 14.6778 5.74676C12.903 4.71378 11.2357 7.43326 12.7524 8.89841C13.2042 9.34111 13.5269 9.45706 14.0862 9.36219Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M15.7549 10.113L15.6844 9.95811C15.567 9.70793 15.2617 9.57689 14.9915 9.6841L10.6952 11.4711L10.9301 10.935C11.5995 9.30289 8.78096 8.09966 7.93541 7.83757L8.53434 6.56287C8.7927 6.76539 10.4368 7.78992 10.8831 8.1354C11.576 8.67149 13.7486 10.4823 13.7486 10.4823L14.876 10.1249C14.876 10.1249 11.3059 6.19356 10.0493 5.00225C9.29769 4.28746 8.95712 3.9658 8.17028 3.44163C6.96068 2.64345 4.71796 1.3346 0.958737 0.0190495C0.524218 -0.105924 0 0.409183 0.524218 0.829143C3.66953 2.85276 5.39876 3.97772 5.53968 4.78781C4.87029 6.00295 3.96602 7.46826 4.21264 9.35054C4.34182 10.3393 5.52794 10.9112 6.58488 10.9112H6.87847L6.24431 13.2342L2.04004 14.8782C1.76994 14.9854 1.64075 15.2833 1.75819 15.5335L1.82865 15.6883C1.94609 15.9385 2.25143 16.0695 2.52154 15.9623L15.4848 10.7801C15.7549 10.6729 15.8841 10.3751 15.7666 10.1249L15.7549 10.113ZM8.96886 10.2321C9.20373 10.1964 9.49733 10.2202 9.49733 10.2202L9.10979 11.9833L7.93541 12.4003C7.93541 12.4003 8.4169 10.3393 8.96886 10.2321Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
      <path
        d="M5.51619 11.2566L0.806948 9.74367C0.489866 9.63645 0.137554 9.80324 0.0318597 10.113C-0.0738341 10.4108 0.0905785 10.7444 0.419404 10.8516L5.23434 12.4003L5.51619 11.2686V11.2566Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
      <path
        d="M15.5806 14.5208L12.1867 13.4248L10.4251 14.092L15.1931 15.6288C15.5101 15.736 15.8624 15.5692 15.9681 15.2595C16.0738 14.9616 15.9094 14.6281 15.5806 14.5208Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
      <path
        d="M10.0845 3.73945C11.4116 3.53693 11.7521 1.52361 10.5778 0.86839C9.12153 0.0463837 7.74751 2.20266 8.99235 3.37015C9.36815 3.72754 9.62651 3.81093 10.0845 3.73945Z"
        fill={theme.tokens.colors['sport-icon']['sport-freestyle']}
      />
    </>
  )
}

export const IconSportFreestyle: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

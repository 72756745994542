import styled, { css } from 'styled-components'

import { Scrollable } from 'shared/ui/scrollable'

export const ContentInstanceContainer = styled.div<{
  broadcastsEventIdsLength: number
}>`
  display: flex;
  flex-direction: column;

  ${({ broadcastsEventIdsLength }) => css`
    ${broadcastsEventIdsLength < 2 &&
    css`
      overflow-y: auto;
    `}
  `}
`

export const StyledBetGroupsList = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledBetCardList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const StyledBasketBodyList = styled(Scrollable)`
  min-height: auto;

  .rcs-positioning .rcs-inner-handle {
    height: calc(100% - 20px);
  }
`

import React, { FC } from 'react'
import {
  selectCashoutByBetId,
  selectCurrentCashout,
  betsHistoryProviderActions,
  selectIsMakeCashoutLoading,
  selectCashoutError
} from 'astra-core/containers/BetsHistoryProvider'
import { useTranslation } from 'react-i18next'

import { RootState } from 'shared/types/store'
import { Tooltip } from 'shared/ui/Tooltip'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { setItemLocalStorage } from 'shared/lib/@system'
import { NEW_MAKE_CASHOUT_LS } from 'widgets/bets-history/components/Cashout/components/CurrentCashoutModal/constants'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledBasketHistoryCashoutButtonTextWrapper,
  StyledBasketHistoryCashoutModalWholeBackground,
  StyledBasketHistoryCashoutWrapper
} from './BasketHistoryCashout.styled'
import { TBasketHistoryCashoutProps } from './BasketHistoryCashout.types'
import { CurrentCashoutModal } from './components'
import { MakeCashoutLoading } from './components/CurrentCashoutModal/constants'
import { DISABLE_HOVER_ON_CHILD } from './constants'

export const BasketHistoryCashout: FC<TBasketHistoryCashoutProps> = ({
  id,
  isLastElement,
  number
}) => {
  const dispatch = useAppDispatch()
  const cashout = useAppSelector((state: RootState) =>
    selectCashoutByBetId(state, id)
  )
  const currentCashout = useAppSelector(selectCurrentCashout)
  const isMakeCashoutLoading = useAppSelector(selectIsMakeCashoutLoading)
  const isShowCurrentCashout = !!(currentCashout && currentCashout.betId === id)
  const isCashoutExist = !!(cashout && cashout.amount)
  const cashoutError = useAppSelector(selectCashoutError)
  const isShowCurrentCashoutModal =
    isShowCurrentCashout && !isMakeCashoutLoading

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const setCurrentCashout = (event) => {
    event.stopPropagation()

    if (isCashoutExist && !isShowCurrentCashout && !isMakeCashoutLoading) {
      dispatch(
        betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
      )

      setItemLocalStorage(NEW_MAKE_CASHOUT_LS, {
        betId: number,
        amount: cashout.amount
      })
    }
  }

  return (
    <>
      {isCashoutExist || !!cashoutError ? (
        <BasketTooltipProvider>
          <StyledBasketHistoryCashoutWrapper onClick={setCurrentCashout}>
            {isMakeCashoutLoading && isShowCurrentCashout ? (
              <MakeCashoutLoading />
            ) : isCashoutExist ? (
              <BasketHistoryCashoutText />
            ) : null}

            {isShowCurrentCashoutModal && (
              <>
                <CurrentCashoutModal
                  isLastElement={isLastElement}
                  number={number}
                />
                <StyledBasketHistoryCashoutModalWholeBackground
                  onClick={unsetCurrentCashout}
                />
              </>
            )}
          </StyledBasketHistoryCashoutWrapper>
        </BasketTooltipProvider>
      ) : null}
    </>
  )
}

const BasketHistoryCashoutText = () => {
  const [t] = useTranslation()
  const isMakeCashoutLoading = useAppSelector(selectIsMakeCashoutLoading)

  const text = `${t('sell bet')}`

  return (
    <Tooltip
      isCommon={!isMakeCashoutLoading}
      title={t('currently impossible to sell a bet')}
    >
      <StyledBasketHistoryCashoutButtonTextWrapper
        className={DISABLE_HOVER_ON_CHILD}
        disabled={isMakeCashoutLoading}
        isLoading={isMakeCashoutLoading}
        view="secondary-medium"
      >
        {text}
      </StyledBasketHistoryCashoutButtonTextWrapper>
    </Tooltip>
  )
}

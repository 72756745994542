import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCheckCircleFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.94412 1C10.563 1 11.1468 1.2452 11.5905 1.68891L14.3111 4.42118C14.7548 4.85321 15 5.44871 15 6.06756L15 9.93244C15 10.5513 14.7548 11.1351 14.3111 11.5788L11.5788 14.3111C11.1468 14.7548 10.5513 15 9.93244 15L6.06756 15C5.44871 15 4.86489 14.7548 4.42118 14.3111L1.68891 11.5788C1.2452 11.1468 1 10.5513 1 9.93244L1 6.06756C1 5.44871 1.2452 4.86489 1.68891 4.42118L4.43286 1.68891C4.86489 1.2452 5.46038 1 6.07923 1L9.94412 1ZM11.6588 7.74956C12.0743 7.38575 12.1162 6.75398 11.7524 6.33845C11.3886 5.92293 10.7568 5.88101 10.3413 6.24482L7.53282 8.70378L5.61411 7.21078C5.17824 6.87162 4.54995 6.95001 4.21078 7.38589C3.87162 7.82176 3.95001 8.45005 4.38589 8.78922L6.82591 10.6879C7.27985 11.0411 7.92058 11.0225 8.35333 10.6437L11.6588 7.74956Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import styled, { css } from 'styled-components'

import { combineMedias } from 'shared/lib/styled'
import { RIGHT_CONTAINER_WIDTH } from 'entities/basket'

const RIGHT_CONTAINER_TOP_POSITION = 48

export const StyledBasketAsideWrapper = styled.div<{
  isShowBasketOnSmallWindow: boolean
}>`
  display: flex;
  flex-direction: column;
  grid-area: right-container;
  height: calc(100vh - ${RIGHT_CONTAINER_TOP_POSITION}px);
  justify-content: center;
  overflow-y: auto;
  position: relative;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  width: 300px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
    box-shadow: inset 1px 0 ${theme.tokens.colors.border['border-hover']};
  `}

  ${combineMedias(['tabletOrMobile', 'small'])} {
    display: flex;
    position: absolute;
    right: 0;
    top: ${RIGHT_CONTAINER_TOP_POSITION}px;
    transform: ${({ isShowBasketOnSmallWindow }) =>
      !isShowBasketOnSmallWindow
        ? `translateX(${RIGHT_CONTAINER_WIDTH}px)`
        : 'translateX(0)'};
    z-index: 1000;
  }
`

export const StyledBasketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: unset;
  position: relative;
`

import styled, { css } from 'styled-components'
import { opacify } from 'polished'

export const StyledLineTournamentGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.tokens.alias.space['space-xxs-6']}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const StyledLineTournamentGroupSport = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    background-image: linear-gradient(
      to right,
      ${opacify(-0.7, theme.tokens.colors['sport-icon']['sport-cybersport'])} 0%,
      transparent 150px
    );
    background-repeat: no-repeat;

    box-shadow: inset 0 -1px ${theme.tokens.colors.divider['divider-primary']};
    color: ${theme.tokens.colors['sport-icon']['sport-cybersport']};

    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

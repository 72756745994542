import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M6 7C5.44772 7 5 7.44771 5 8V9H4C3.44772 9 3 9.44772 3 10V10.5C2.44772 10.5 2 10.9477 2 11.5V13.5C2 14.0523 2.44772 14.5 3 14.5V15C3 15.5523 3.44772 16 4 16H5V17C5 17.5523 5.44772 18 6 18H7C7.55228 18 8 17.5523 8 17V8C8 7.44772 7.55228 7 7 7H6ZM16 8C16 7.44771 16.4477 7 17 7H18C18.5523 7 19 7.44772 19 8V9H20C20.5523 9 21 9.44772 21 10V10.5C21.5523 10.5 22 10.9477 22 11.5V13.5C22 14.0523 21.5523 14.5 21 14.5V15C21 15.5523 20.5523 16 20 16H19V17C19 17.5523 18.5523 18 18 18H17C16.4477 18 16 17.5523 16 17V8ZM9.00122 11H14.9988V14H9.00122V11Z"
      fill={theme.tokens.colors['sport-icon']['sport-weightlifting']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M3.39998 3.60026C2.84769 3.60026 2.39998 4.04798 2.39998 4.60026V5.20036H1.79988C1.2476 5.20036 0.799881 5.64807 0.799881 6.20036V6.40055C0.358087 6.40059 -4.57764e-05 6.75875 -4.57764e-05 7.20056V8.80056C-4.57764e-05 9.24236 0.358087 9.60052 0.799881 9.60055V9.80036C0.799881 10.3526 1.2476 10.8004 1.79988 10.8004H2.39998V11.4003C2.39998 11.9525 2.84769 12.4003 3.39998 12.4003H3.79998C4.35226 12.4003 4.79998 11.9525 4.79998 11.4003V9.19997H11.2V11.4003C11.2 11.9525 11.6477 12.4003 12.2 12.4003H12.6C13.1523 12.4003 13.6 11.9525 13.6 11.4003V10.8004H14.2001C14.7523 10.8004 15.2001 10.3526 15.2001 9.80036V9.60055C15.6418 9.60052 16 9.24236 16 8.80055V7.20055C16 6.75875 15.6418 6.40059 15.2001 6.40055V6.20036C15.2001 5.64807 14.7523 5.20036 14.2001 5.20036H13.6V4.60026C13.6 4.04798 13.1523 3.60026 12.6 3.60026H12.2C11.6477 3.60026 11.2 4.04798 11.2 4.60026V6.79997H4.79998V4.60026C4.79998 4.04798 4.35226 3.60026 3.79998 3.60026H3.39998Z"
      fill={theme.tokens.colors['sport-icon']['sport-weightlifting']}
      fillRule="evenodd"
    />
  )
}

export const IconSportWeightlifting: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

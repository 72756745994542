import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  RenderRightWrapper,
  StyledDescriptionWrapper,
  StyledErrorText,
  StyledInternalInput,
  StyledInternalInputWrapper,
  StyledLabelText,
  StyledMaskPlaceholder,
  StyledStartAdornment,
  StyledTextInput,
  StyledTextInputWrapper
} from 'shared/ui/text-input/text-input.styled'

import { Tooltip } from '../Tooltip'

import { TextInputProps } from './text-input.types'

export const TextInput = forwardRef<unknown, TextInputProps>(
  (
    {
      size = 'm',
      font = 'Body / 14 Medium',
      background = 'gray',
      label,
      error,
      touched,
      description,
      renderRight,
      errorMessage,
      maskPlaceholder,
      endAdornment,
      startAdornment,
      callbackOnClick,
      isAuthForm,
      className,
      withoutTouched,
      tooltipTitle,
      ...rest
    },
    forwardedRef
  ) => {
    const { t } = useTranslation()
    const internalInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle<unknown, HTMLInputElement | null>(
      forwardedRef,
      () => internalInputRef.current
    )

    const handleInputClick = useCallback(() => {
      internalInputRef.current?.focus()
    }, [])

    const errorMessages = useMemo(
      () => (error?.trim() && touched ? error : errorMessage),
      [error, errorMessage, touched]
    )

    const isLabelActive =
      !!rest.value ||
      !!rest.defaultValue ||
      !!maskPlaceholder ||
      !!rest.placeholder

    return (
      <StyledTextInputWrapper className={className} onClick={callbackOnClick}>
        <StyledTextInput
          background={background}
          hasLabel={!!label}
          hasValue={isLabelActive}
          isAuthForm={isAuthForm}
          isDisabled={rest.disabled}
          isError={!!errorMessages}
          size={size}
          onClick={handleInputClick}
        >
          {!!label && (
            <StyledLabelText className="input-label" htmlFor={rest.name}>
              {t(label)}
            </StyledLabelText>
          )}

          {maskPlaceholder && (
            <StyledMaskPlaceholder className="mask-placeholder" font={font}>
              {maskPlaceholder}
            </StyledMaskPlaceholder>
          )}

          <StyledInternalInputWrapper className="internal-input">
            <StyledStartAdornment>{startAdornment}</StyledStartAdornment>

            {tooltipTitle ? (
              <Tooltip
                positionDelta={{ top: 16, left: 16 }}
                title={tooltipTitle}
              >
                <StyledInternalInput
                  font={font}
                  ref={internalInputRef}
                  {...rest}
                />
              </Tooltip>
            ) : (
              <StyledInternalInput
                font={font}
                ref={internalInputRef}
                {...rest}
              />
            )}
            {endAdornment}
          </StyledInternalInputWrapper>

          {renderRight && (
            <RenderRightWrapper>{renderRight}</RenderRightWrapper>
          )}
        </StyledTextInput>
        {touched && !withoutTouched && errorMessages && (
          <StyledErrorText>{t(errorMessages)}</StyledErrorText>
        )}
        {withoutTouched && errorMessages && (
          <StyledErrorText>{t(errorMessages)}</StyledErrorText>
        )}
        {description && (
          <StyledDescriptionWrapper> {description}</StyledDescriptionWrapper>
        )}
      </StyledTextInputWrapper>
    )
  }
)

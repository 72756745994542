import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M8.83857 14.4208L12 14.4058L15.1614 14.4208L15.3441 14.2814C15.6632 14.038 16.1624 13.9423 16.6317 14.0346C17.6644 14.2376 18.4618 16.2918 18.9362 19.9709C19.185 21.9007 18.7142 22.7305 17.3763 22.7203C16.5385 22.7135 16.2731 22.3775 15.6398 20.5226C15.3332 19.624 15 19.5313 14.8957 19.5313C14.6667 19.5313 12.9078 19.5309 12 19.5313C11.0926 19.5313 9.33333 19.5313 9.10431 19.5313C9 19.5313 8.66682 19.624 8.36023 20.5226C7.72688 22.3775 7.46148 22.7135 6.62374 22.7203C5.28578 22.7305 4.81496 21.9007 5.06378 19.9709C5.53819 16.2918 6.3356 14.2376 7.36834 14.0346C7.83762 13.9423 8.33681 14.038 8.65588 14.2814L8.83857 14.4208Z"
        fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      />
      <path
        clipRule="evenodd"
        d="M11.8814 2.5C17.2806 2.5 21 4.60527 21 6.44738C21 7.36843 19.9202 8.42107 18.2404 9.21054C16.6807 9.86844 14.521 10.3948 12.0014 10.3948C9.48181 10.3948 7.32214 9.86844 5.76239 9.21054C3.96267 8.42107 3.00282 7.36843 3.00282 6.44738C2.88284 4.60527 6.60226 2.5 11.8814 2.5ZM12 11.6415C15.0789 11.6415 17.6842 10.9905 19.5789 9.94885C20.0526 9.68844 20.6447 9.29784 21 8.90723V12.5529V12.8133C20.9533 13.8404 20.2987 14.7864 19.2251 15.5556C19.1714 15.3948 19.1161 15.2413 19.0593 15.0952C18.8368 14.5229 18.5795 14.0347 18.276 13.6634C17.9718 13.2913 17.5881 12.9978 17.1145 12.9048C16.7846 12.8401 16.4323 12.8387 16.0974 12.9048C15.7734 12.9689 15.4657 13.0962 15.2105 13.2906L15.1887 13.3071L12.003 13.292L8.8112 13.3071L8.78925 13.2904C8.53409 13.0961 8.22659 12.9688 7.90278 12.9048C7.56784 12.8386 7.21544 12.84 6.88544 12.9048C6.41183 12.9978 6.02809 13.2913 5.72392 13.6634C5.42042 14.0347 5.16312 14.5229 4.9406 15.0952C4.88735 15.2322 4.83545 15.3756 4.7849 15.5255C3.74073 14.7618 3.0922 13.827 3 12.8133V12.6831V8.90723C3.35526 9.29784 3.94737 9.68844 4.42105 9.94885C6.43421 10.9905 9.03947 11.6415 12 11.6415Z"
        fill={theme.tokens.colors['sport-icon']['sport-hockey']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M7.8946 0.000183105C12.6939 0.000183105 16 1.87153 16 3.50896C16 4.32768 15.0401 5.26335 13.5471 5.96511C12.1606 6.54991 10.2409 7.01774 8.00125 7.01774C5.7616 7.01774 3.84191 6.54991 2.45546 5.96511C0.855707 5.26335 0.00250741 4.32768 0.00250741 3.50896C-0.104143 1.87153 3.20201 0.000183105 7.8946 0.000183105ZM8 8.12595C10.7368 8.12595 13.0526 7.54727 14.7368 6.62138C15.1579 6.38991 15.6842 6.0427 16 5.6955V8.9361V9.16757C15.9435 10.4097 14.8868 11.5185 13.2527 12.2793C13.1093 11.5045 12.938 10.8546 12.7384 10.3412C12.589 9.95707 12.4163 9.6294 12.2126 9.38019C12.0084 9.13042 11.7509 8.93345 11.433 8.87105C11.2116 8.82758 10.9751 8.82663 10.7504 8.87105C10.5329 8.91402 10.3264 8.99946 10.155 9.12994L10.1404 9.14107L8.00222 9.1309L5.85988 9.14107L5.84514 9.12984C5.67388 8.99942 5.46749 8.91402 5.25015 8.87105C5.02533 8.82661 4.7888 8.82756 4.56731 8.87105C4.24943 8.93345 3.99186 9.13042 3.7877 9.38019C3.584 9.6294 3.41129 9.95707 3.26194 10.3412C3.06368 10.8512 2.89333 11.4957 2.75051 12.2637C1.15118 11.5033 0.112197 10.4012 0 9.16757V9.05183V5.6955C0.31579 6.0427 0.842106 6.38991 1.26316 6.62138C3.05263 7.54727 5.36842 8.12595 8 8.12595Z"
        fill={theme.tokens.colors['sport-icon']['sport-hockey']}
        fillRule="evenodd"
      />
      <path
        d="M5.93936 10.0495L8.00007 10.0397L10.0608 10.0495L10.1799 9.95861C10.3878 9.79999 10.7132 9.73761 11.0191 9.79776C11.6923 9.9301 12.212 11.2691 12.5213 13.6672C12.6835 14.9251 12.3766 15.466 11.5045 15.4593C10.9584 15.4549 10.7854 15.2359 10.3726 14.0268C10.1727 13.4411 9.95555 13.3807 9.88755 13.3807C9.73827 13.3807 8.59182 13.3804 8.00007 13.3807C7.40857 13.3807 6.26187 13.3807 6.11258 13.3807C6.04459 13.3807 5.82741 13.4411 5.62757 14.0268C5.21474 15.2359 5.04174 15.4549 4.49568 15.4593C3.62356 15.466 3.31667 14.9251 3.47886 13.6672C3.7881 11.2691 4.30787 9.9301 4.98103 9.79776C5.28692 9.73761 5.61231 9.79999 5.82028 9.95861L5.93936 10.0495Z"
        fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      />
    </>
  )
}

export const IconSportCyberhockey: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconChevronDown: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M3.29289 6.29289C3.68342 5.90237 4.31658 5.90237 4.70711 6.29289L8 9.58579L11.2929 6.29289C11.6834 5.90237 12.3166 5.90237 12.7071 6.29289C13.0976 6.68342 13.0976 7.31658 12.7071 7.70711L8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071L3.29289 7.70711C2.90237 7.31658 2.90237 6.68342 3.29289 6.29289Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M9.4394 2.29382C7.79895 9.43065 8.66426 17.2524 11.4544 17.9683C15.9842 19.1305 17.2507 12.671 12.5205 8.95139C12.1764 8.68085 11.8295 8.45614 11.5299 8.26207C11.2698 8.09358 11.0453 7.94818 10.8894 7.81596C10.8191 7.23783 10.7666 6.6367 10.732 5.10057C10.7061 3.95039 10.6009 3.12044 10.4811 2.36557C10.3416 1.48565 9.59709 1.60745 9.4394 2.29382ZM12.7145 11.5062C13.4614 12.9826 13.329 15.2076 12.5205 15.479C11.9674 15.6647 11.2435 13.9452 11.1559 11.7014C11.1304 11.0482 11.0723 9.45049 11.0723 9.45049C11.0723 9.45049 12.0281 10.1489 12.7145 11.5062ZM15.977 8.86151C16.4173 8.21227 16.0565 7.08096 15.1708 6.33457C14.2853 5.58819 13.2104 5.50926 12.7699 6.1581C12.3296 6.80734 12.6906 7.93865 13.5761 8.68503C14.4618 9.43182 15.5367 9.51075 15.977 8.86151ZM1.17729 22.2588C0.827505 22.3599 1.04104 22.1404 1.42125 21.7845C3.29462 20.0308 7.3194 18.1819 12.5109 19.3838C13.05 19.5086 13.5625 19.6349 14.056 19.7564C17.1052 20.5075 19.4315 21.0804 22.8478 20.0407C23.0188 19.9886 23.1146 20.0283 22.7527 20.2795C19.2303 22.7241 15.6261 22.915 12.0536 21.7845C8.48112 20.654 5.51999 21.0039 1.17729 22.2588Z"
      fill={theme.tokens.colors['sport-icon']['sport-diving']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M6.13772 0.870909C4.94467 6.06131 5.57398 11.7498 7.60319 12.2705C10.8975 13.1157 11.8186 8.41793 8.37847 5.71276C8.12826 5.51601 7.87596 5.35258 7.65807 5.21144L7.65807 5.21144C7.46889 5.08889 7.30565 4.98316 7.19224 4.88699C7.1411 4.46654 7.10293 4.02936 7.07779 2.91218C7.05896 2.07568 6.98242 1.47209 6.89534 0.923093C6.79383 0.283158 6.2524 0.371735 6.13772 0.870909ZM8.51957 7.57079C9.06277 8.64454 8.96652 10.2627 8.37847 10.4601C7.97625 10.5952 7.44976 9.34462 7.38608 7.71278C7.36754 7.23771 7.32526 6.07574 7.32526 6.07574C7.32526 6.07574 8.0204 6.58368 8.51957 7.57079ZM10.8923 5.64739C11.2125 5.17522 10.9501 4.35245 10.306 3.80963C9.66197 3.26681 8.88019 3.2094 8.55985 3.68128C8.23964 4.15346 8.50215 4.97623 9.14614 5.51905C9.79028 6.06216 10.5721 6.11957 10.8923 5.64739ZM0.128937 15.3908C-0.125451 15.4643 0.0298454 15.3047 0.306364 15.0459C1.66881 13.7705 4.59591 12.4258 8.37156 13.3C8.76362 13.3907 9.13632 13.4825 9.49525 13.5709C11.7128 14.1171 13.4046 14.5338 15.8892 13.7776C16.0136 13.7398 16.0833 13.7687 15.8201 13.9513C13.2584 15.7292 10.6371 15.868 8.03895 15.0459C5.44079 14.2237 3.28725 14.4782 0.128937 15.3908Z"
      fill={theme.tokens.colors['sport-icon']['sport-diving']}
      fillRule="evenodd"
    />
  )
}

export const IconSportDiving: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

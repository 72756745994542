import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState, toIdsArray } from 'shared/lib/@reduxjs'

import {
  ContainerState,
  FetchLivePayload,
  FetchLiveSuccessPayload,
  TResetLiveFilter
} from './types'

const REDUCER_KEY = 'liveContainer'

// The initial state of the LiveContainer container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  eventsIdsBySport: {},
  fetchItems: requestInitialState,
  filters: {
    isTop: false,
    isBroadcast: false
  },
  layouts: {
    isLoading: false
  }
})

const liveContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchLive(state, _action: PayloadAction<FetchLivePayload>) {
      state.fetchItems.loading = true
      state.fetchItems.error = null
    },
    fetchLiveSuccess(state, action: PayloadAction<FetchLiveSuccessPayload>) {
      const { sportId } = action.payload.reqData
      const { items } = action.payload.resData
      state.fetchItems.loading = false
      state.fetchItems.error = null

      if (sportId) {
        state.eventsIdsBySport[sportId] = toIdsArray(items)
      }

      state.layouts.isLoading = false
    },
    fetchLiveError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItems.loading = false
      state.fetchItems.error = action.payload
      state.layouts.isLoading = false
    },
    toggleFilterIsTop(state) {
      if (!state.layouts.isLoading || !state.fetchItems.loading) {
        state.layouts.isLoading = true
        state.filters.isTop = !state.filters.isTop
        state.eventsIdsBySport = {}
      }
    },
    toggleFilterIsBroadcast(state) {
      state.filters.isBroadcast = !state.filters.isBroadcast
    },
    resetLiveFilter(state, { payload }: PayloadAction<TResetLiveFilter>) {
      // add isResetFilter: true, if we want to reset filter for Live
      if (payload.isResetFilter) {
        state.filters = initialState.filters
      }
      state.layouts.isLoading = true
    },
    resetFilterIsBroadcast(state) {
      state.filters.isBroadcast = initialState.filters.isBroadcast
    }
  }
})

export const {
  actions: liveContainerActions,
  reducer: reducerLive,
  name: sliceKeyLive
} = liveContainerSlice

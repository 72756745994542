import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { StyledFooterTopHelpSupport } from 'entities/footer-of-page'
import { Button } from 'shared/ui/Button'
import { ERoutes } from 'shared/types/routes'

export const OpenSupport = () => {
  const [t] = useTranslation()

  return (
    <NavLink to={ERoutes.Support}>
      <Button view="secondary-large">
        <StyledFooterTopHelpSupport>
          {t('support service')}
        </StyledFooterTopHelpSupport>
      </Button>
    </NavLink>
  )
}

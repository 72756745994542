import { Event } from 'betweb-openapi-axios'

export function getLinkEventPage(event?: {
  isEventLive: Event['live']
  eventId: Event['id']
  tournamentSportId: Event['tournament']['sportId']
  tournamentId: Event['tournament']['id']
}): string {
  return !event
    ? ''
    : event.isEventLive
    ? `/live/${event.tournamentSportId}/${event.eventId}`
    : `/line/${event.tournamentSportId}/${event.tournamentId}/${event.eventId}`
}

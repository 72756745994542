import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { RefRectType } from './Tooltip.types'

export const StyledTooltip = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;

  & > * {
    pointer-events: none;
  }
`

export const StyledTooltipBody = styled.div<{
  rect: RefRectType
  topPlacement: boolean
  positionDelta: Partial<RefRectType> | null
}>`
  ${typography['Body / 12 Medium']};
  box-shadow: 0 2px 4px 0 #6c799314;
  display: inline-block;
  position: fixed;

  width: max-content;
  z-index: 1001;

  ${({ theme, rect, topPlacement, positionDelta }) => css`
    background-color: ${theme.tokens.colors.surface[
      'surface-darkGray-opacity'
    ]};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;

    color: ${theme.tokens.colors.text['text-invert']};

    left: calc(
      ${rect.left}px +
        ${positionDelta?.left ? `${positionDelta.left}px` : '0px'}
    );
    max-width: ${Math.min(rect.maxWidth, 400)}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    top: calc(
      ${rect.top}px + ${positionDelta?.top ? `${positionDelta.top}px` : '0px'}
    );
    transform: translateX(${rect.translateX}%);

    ${typography['Body / 12 Regular']}

    ${topPlacement &&
    css`
      transform: translate(${rect.translateX}%, -100%);
    `}
  `}
`

export const StyledSpan = styled.span``

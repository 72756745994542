import styled, { css } from 'styled-components'

import { OddModes } from 'entities/event-probability/model/odd-mode'
import { getProbabilityCellStyle } from 'features/probability-cell/lib/probability-cell-style'
import { typography } from 'shared/lib/theme/typography'
import { IconCoefficients } from 'shared/ui/Icon/General/IconCoefficients'

export const ProbabilityCellWrapper = styled.div<{
  isInBasket: boolean
  mode: OddModes
  isEmpty: boolean
}>`
  ${typography['Body / 12 SemiBold']};
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  ${({ theme, isInBasket, mode, isEmpty }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
    ${getProbabilityCellStyle({ isInBasket, mode, isEmpty })}
  `}
`

export const OutcomeCode = styled.div``
export const ProbabilityOdd = styled.div``

export const IncreaseIcon = styled(IconCoefficients)`
  position: absolute;
  right: 0;
  top: 0;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-positive']};
  `}
`

export const DecreaseIcon = styled(IconCoefficients)`
  bottom: 0;
  position: absolute;
  right: 0;
  transform: rotate(90deg);
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-negative']};
  `}
`

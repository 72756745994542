export type ProviderState = Record<
  BalanceOperationsTypes,
  {
    data: {
      paymentMethodsWithVariants: Record<
        PaymentMethods,
        {
          title: string
          variantList: Array<PaymentVariant>
        }
      >
    }
    layout: {
      activePaymentTabValue: PaymentMethods
      activePaymentMethodVariantKey: PaymentVariant['key']
    }
  }
>

export enum PaymentMethods {
  CARD = 'card',
  SIM = 'sim',
  CASH = 'cash'
}

export enum BalanceOperationsTypes {
  TOP_UP = 'top_up',
  WITHDRAWAL = 'withdrawal'
}

export type PaymentVariant = {
  title: string
  limits: string
  min: string
  max: string
  key: string
  iconUrl: string
}

export type BalanceOperationsTypesObject = {
  balanceOperationsType: BalanceOperationsTypes
}

export interface ProviderReducerPayloads {
  setActivePaymentMethodVariantKey: {
    key: string
  } & BalanceOperationsTypesObject
  onChangeActivePaymentTab: {
    paymentMethod: PaymentMethods
  } & BalanceOperationsTypesObject
}

import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconTicketNo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8188 8.69677C13.8188 9.28977 14.3006 9.77899 14.8936 9.77899C15.1753 9.77899 15.4125 10.0088 15.4125 10.2904C15.4125 13.1368 14.5527 13.9967 11.7063 13.9967H7.81475V12.8181C7.81475 12.529 7.59237 12.2918 7.3107 12.2696V10.157C7.59237 10.1348 7.81475 9.89759 7.81475 9.6085V6.3915C7.81475 6.10241 7.59237 5.86521 7.3107 5.84298V2.7594H11.7063C14.5527 2.7594 15.4125 3.61924 15.4125 6.46562V7.09569C15.4125 7.38477 15.1753 7.61456 14.8936 7.61456C14.3006 7.61456 13.8188 8.09636 13.8188 8.69677Z"
        fill="#7845E7"
        opacity="0.4"
      />
      <path
        d="M7.31057 5.84301C7.00666 5.84301 6.70275 6.08762 6.70275 6.39153V9.60852C6.70275 9.91243 6.95477 10.1645 7.25868 10.1645C7.2735 10.1645 7.29574 10.1645 7.31057 10.157V12.2696C7.29574 12.2622 7.2735 12.2622 7.25868 12.2622C6.95477 12.2622 6.70275 12.5142 6.70275 12.8181V13.9967H5.39815C4.00461 13.9967 3.2856 12.9515 2.63331 11.3727L2.5073 11.0614C2.40352 10.7945 2.52953 10.4906 2.79638 10.3868C3.07064 10.2831 3.2856 10.0681 3.39679 9.78642C3.51539 9.51216 3.51539 9.20825 3.4042 8.93399C3.167 8.36323 2.51471 8.08897 1.93654 8.31876C1.81053 8.37806 1.66228 8.37806 1.53627 8.31876C1.41026 8.26687 1.31389 8.1631 1.25459 8.02967L1.14341 7.73318C0.0389529 5.04988 0.520762 3.90095 3.20407 2.78908L5.02011 2.04043C5.28696 1.92924 5.59087 2.05525 5.69465 2.3221L7.31057 5.84301Z"
        fill="#7845E7"
      />
    </svg>
  </Icon>
)

import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { useAppSelector } from 'shared/lib/@reduxjs'

import { BasketHistoryLegacy } from '../../legacy/ui/BasketHistoryLegacy'

import { BasketHistory } from './basket-history'

export const BasketHistoryFacade = () => {
  const { IS_TRANSIT } = useAppSelector(selectConfig)

  if (IS_TRANSIT) {
    return <BasketHistoryLegacy />
  }

  return <BasketHistory />
}

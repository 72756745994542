import { useMemo } from 'react'
import { selectCurrencyMaxPayout } from 'astra-core/containers/CommonDataProvider'
import {
  EBetTypes,
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab,
  selectBonusOnExpress
} from 'astra-core/containers/BasketProvider'

import { useAppSelector } from 'shared/lib/@reduxjs'

const useMaxSum = (winSum: number, inputValue: number | string) => {
  const maxPayout = useAppSelector(selectCurrencyMaxPayout)
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)
  const bonus = useAppSelector(selectBonusOnExpress)
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)

  const maxSum = useMemo(() => {
    const bonusSum = winSum * (bonus - 1)
    const totalWinSum =
      betTypeTab === EBetTypes.Express ? winSum + bonusSum : winSum

    const sum = totalWinSum > maxPayout ? maxPayout : totalWinSum
    const newValue = appliedCoupon ? inputValue : sum.toFixed(2)
    return newValue
  }, [winSum, maxPayout, appliedCoupon, inputValue, bonus, betTypeTab])

  const showMaxTag = useMemo(() => +maxSum === maxPayout, [maxSum, maxPayout])

  return { maxSum, showMaxTag }
}

export default useMaxSum

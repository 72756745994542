import { FC } from 'react'

import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { Tooltip } from 'shared/ui/Tooltip'
import { LEGACY_SPORT_ID_CODE_MAP, LegacyBetEvent } from 'entities/legacy-api'

import {
  StyledBasketHistoryOutcome,
  StyledBasketHistoryOutcomeCoefficient,
  StyledBasketHistoryOutcomeCoefficientValue,
  StyledBasketHistoryOutcomeCompetitors,
  StyledBasketHistoryOutcomeName,
  StyledBasketHistoryOutcomeTitle,
  StyledBasketHistoryOutcomeTitleText,
  StyledSportIcon
} from './BasketHistoryOutcome.styled'

export const BasketHistoryOutcome: FC<{
  outcome: LegacyBetEvent
}> = ({ outcome }) => {
  const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()
  const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
  const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLSpanElement>()

  const showSportIcon = outcome.sport_id !== 99

  return (
    <StyledBasketHistoryOutcome>
      <div>
        <StyledBasketHistoryOutcomeTitle>
          {showSportIcon && (
            <StyledSportIcon
              size="s"
              sport={LEGACY_SPORT_ID_CODE_MAP[outcome.sport_id]}
            />
          )}
          <Tooltip isCommon={!isNamesOverflowing} title={outcome.champname}>
            <StyledBasketHistoryOutcomeTitleText ref={namesRef}>
              {outcome.champname}
            </StyledBasketHistoryOutcomeTitleText>
          </Tooltip>
        </StyledBasketHistoryOutcomeTitle>

        <Tooltip isCommon={!isTeamsOverflowing} title={outcome.matchname}>
          <StyledBasketHistoryOutcomeCompetitors ref={teamsRef}>
            {outcome.matchname}
          </StyledBasketHistoryOutcomeCompetitors>
        </Tooltip>
      </div>

      <Tooltip isCommon={!isOutcomeOverflowing} title={outcome.matchname}>
        <StyledBasketHistoryOutcomeCoefficient>
          <StyledBasketHistoryOutcomeName ref={outcomeRef}>
            {outcome.eventname}
          </StyledBasketHistoryOutcomeName>

          <StyledBasketHistoryOutcomeCoefficientValue>
            {outcome.value}
          </StyledBasketHistoryOutcomeCoefficientValue>
        </StyledBasketHistoryOutcomeCoefficient>
      </Tooltip>
    </StyledBasketHistoryOutcome>
  )
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledSettingItem = styled.div``

export const StyledSettingItemTitle = styled.h2`
  ${({ theme }) => css`
    ${typography['Hint / 10 BOLD']}
    color: ${theme.tokens.colors.text['text-secondary-2']};
    margin-bottom: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledSettingItemContentRadio = styled.div`
  display: flex;
  gap: 4px;
`

export const StyledSettingItemIcon = styled.div<{
  checked?: boolean
  $src: string
}>`
  ${({ theme, $src }) => css`
    background-image: url(${$src});
    background-size: cover;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    height: 62px;
    width: 112px;
  `}
`

export const StyledSettingItemLabel = styled.label<{ checked: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  ${({ theme, checked }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px;
    &:hover {
      background: ${theme.tokens.colors.surface['surface-default']};
    }

    ${checked &&
    css`
      background: ${theme.tokens.colors.surface['surface-default']};
    `}
  `}
`

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutTheme } from 'betweb-openapi-axios'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'

import { Typography } from 'shared/ui/typography'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledSettingItem,
  StyledSettingItemContentRadio,
  StyledSettingItemIcon,
  StyledSettingItemLabel,
  StyledSettingItemTitle
} from '../../Settings.styled'

export const SettingTheme = () => {
  const [t] = useTranslation()
  const { onSetUserSetting } = useSetUserSetting()
  const themeType = useAppSelector(selectUserSettingLayoutTheme)

  const radioValue = useMemo(
    () => ({
      light: themeType === LayoutTheme.Light,
      dark: themeType === LayoutTheme.Dark
    }),
    [themeType]
  )

  const handleClickTheme = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>, themeType: LayoutTheme) => {
      e.preventDefault()

      onSetUserSetting({
        layoutTheme: themeType
      })
    },
    [onSetUserSetting]
  )

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>{t('color theme')}</StyledSettingItemTitle>

      <StyledSettingItemContentRadio>
        <StyledSettingItemLabel
          checked={radioValue.light}
          onClick={(e) => handleClickTheme(e, LayoutTheme.Light)}
        >
          <StyledSettingItemIcon $src="/images/settings/allThemes/setting_site_theme_light.png" />
          <Typography
            color={radioValue.light ? 'text-primary' : 'text-secondary'}
            font="Body / 14 SemiBold"
          >
            {t('light theme by single word')}
          </Typography>
        </StyledSettingItemLabel>

        <StyledSettingItemLabel
          checked={radioValue.dark}
          onClick={(e) => handleClickTheme(e, LayoutTheme.Dark)}
        >
          <StyledSettingItemIcon $src="/images/settings/allThemes/setting_site_theme_dark.png" />
          <Typography
            color={radioValue.dark ? 'text-primary' : 'text-secondary'}
            font="Body / 14 SemiBold"
          >
            {t('dark theme by single word')}
          </Typography>
        </StyledSettingItemLabel>
      </StyledSettingItemContentRadio>
    </StyledSettingItem>
  )
}

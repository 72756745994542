import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { useTimer } from 'hooks'
import { ENotificationContainerIds } from 'shared/lib/Toastify'
import { IconCheckCircleFilled16 } from 'shared/ui/Icon/General/IconCheckCircleFilled16'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { ERoutes } from 'shared/types/routes'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import {
  StyledMyBonusesToastifyNotificationText,
  StyledMyBonusesToastifyNotificationTextWrapper
} from './my-bonuses-toastify-notification.styled'
import {
  MY_BONUSES_TOASTIFY_NOTIFICATION_TIMER,
  MY_BONUSES_TOASTIFY_NOTIFICATION_TIMER_AUTO_CLOSE,
  TOASTIFY_MY_BONUSES_ID
} from './constants'
import { TMyBonusesToastifyNotificationProps } from './my-bonuses-toastify-notification.types'

export const MyBonusesToastifyNotification: FC<
  TMyBonusesToastifyNotificationProps
> = ({ toastProps }) => {
  const seconds = useTimer(MY_BONUSES_TOASTIFY_NOTIFICATION_TIMER)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      layoutContainerActions.setIsShowMyBonusesToastifyNotification(false)
    )

    if (!seconds) {
      toast.update(toastProps.toastId, {
        closeButton: false,
        containerId: ENotificationContainerIds.SYSTEM,
        autoClose: MY_BONUSES_TOASTIFY_NOTIFICATION_TIMER_AUTO_CLOSE,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: false,
        toastId: TOASTIFY_MY_BONUSES_ID
      })
    }
  }, [dispatch, seconds, toastProps.toastId])

  return <MyBonusesToastifyNotificationText />
}

export const MyBonusesToastifyNotificationText = () => {
  const [t] = useTranslation()

  return (
    <StyledMyBonusesToastifyNotificationTextWrapper>
      <IconCheckCircleFilled16 colorToken="icon-positive" size={16} />

      <StyledMyBonusesToastifyNotificationText>
        {t('you have activated the promo code')}
        <br />
        <Link style={{ color: '#0b73da' }} to={ERoutes.MyBonuses}>
          {t('my bonuses')}
        </Link>
      </StyledMyBonusesToastifyNotificationText>
    </StyledMyBonusesToastifyNotificationTextWrapper>
  )
}

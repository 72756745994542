import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconCircleMinus12: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.66639 0C8.19683 0 8.69725 0.210175 9.07756 0.590492L11.4095 2.93244C11.7898 3.30275 12 3.81318 12 4.34362V7.65638C12 8.18682 11.7898 8.68724 11.4095 9.06756L9.06756 11.4095C8.69725 11.7898 8.18682 12 7.65638 12H4.34362C3.81318 12 3.31276 11.7898 2.93244 11.4095L0.590492 9.06756C0.210175 8.69725 0 8.18682 0 7.65638V4.34362C0 3.81318 0.210175 3.31276 0.590492 2.93244L2.94245 0.590492C3.31276 0.210175 3.82319 0 4.35363 0H7.66639ZM3.33278 6.00508C3.33278 5.63477 3.63303 5.33452 4.00334 5.33452H8.00667C8.37698 5.33452 8.67723 5.63477 8.67723 6.00508C8.67723 6.37539 8.37698 6.67564 8.00667 6.67564H4.00334C3.63303 6.67564 3.33278 6.37539 3.33278 6.00508Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

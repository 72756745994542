import React from 'react'

export const IconTelegram = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_326_37)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="url(#paint0_linear_326_37)"
        />
        <path
          clipRule="evenodd"
          d="M3.62124 7.91555C5.9534 6.89947 7.50854 6.2296 8.28666 5.90596C10.5083 4.98188 10.97 4.82136 11.2709 4.81606C11.3371 4.81489 11.485 4.83129 11.5809 4.90907C11.6618 4.97474 11.6841 5.06345 11.6947 5.12572C11.7054 5.18798 11.7187 5.32982 11.7081 5.44065C11.5877 6.70564 11.0668 9.77544 10.8018 11.1922C10.6896 11.7918 10.4688 11.9928 10.255 12.0124C9.79046 12.0552 9.43768 11.7054 8.98772 11.4105C8.28362 10.9489 7.88584 10.6616 7.20239 10.2112C6.41254 9.6907 6.92456 9.40463 7.3747 8.9371C7.4925 8.81474 9.53943 6.95291 9.57905 6.78401C9.584 6.76288 9.5886 6.68415 9.54182 6.64257C9.49505 6.60099 9.42601 6.61521 9.37619 6.62652C9.30557 6.64254 8.18075 7.38601 6.00174 8.8569C5.68246 9.07614 5.39328 9.18296 5.13417 9.17736C4.84853 9.17119 4.29907 9.01586 3.8906 8.88308C3.3896 8.72022 2.99142 8.63412 3.02609 8.35754C3.04415 8.21348 3.24253 8.06615 3.62124 7.91555Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_326_37"
          x1="800"
          x2="800"
          y1="0"
          y2="1588.13"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_326_37">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  )
}

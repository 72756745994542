import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconHalfWinCircleFilled12: FC<IconProps> = (props) => {
  const theme = useTheme()

  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="13"
        viewBox="0 0 13 13"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.07758 1.55972C8.69724 1.17941 8.19684 0.969238 7.66638 0.969238H4.35364C3.82318 0.969238 3.31277 1.17941 2.94244 1.55972L0.590485 3.90167C0.210175 4.28201 0 4.78241 0 5.31287V8.62561C0 9.15607 0.210175 9.66647 0.590485 10.0368L2.93243 12.3788C3.31277 12.7591 3.81317 12.9692 4.34363 12.9692H4.86063L3.65216 11.7608C3.20871 11.3173 2.97217 10.7235 2.97464 10.1039L2.98346 7.89276C2.97964 7.2641 3.23398 6.66956 3.66855 6.23499L5.24271 4.66086C5.68448 4.21909 6.27539 3.97824 6.89471 3.97577H6.89685L9.11179 3.97165C9.74042 3.96317 10.3373 4.21323 10.7733 4.6492L12 5.87595V5.31287C12 4.78241 11.7898 4.272 11.4095 3.90167L9.07758 1.55972Z"
          fill={theme.tokens.colors.icon['icon-positive']}
        />
        <path
          d="M9.12027 4.97151C9.47557 4.96535 9.81608 5.10612 10.0661 5.35618L10.454 5.74402L7.06603 9.13578L7.06605 7.75937C7.06605 7.46708 6.82691 7.22794 6.53461 7.22794C6.24232 7.22794 6.00317 7.46709 6.00319 7.75936L6.00317 10.4165C6.00317 10.4856 6.01913 10.5547 6.0457 10.6185C6.06696 10.6822 6.10946 10.7407 6.15731 10.7938C6.25827 10.8948 6.39644 10.948 6.5293 10.9479L9.19179 10.948C9.48404 10.948 9.7232 10.7088 9.72321 10.4165C9.72321 10.1242 9.48406 9.88509 9.19177 9.8851H7.81536L11.2052 6.49524L11.6278 6.91782C11.8778 7.16787 12.0139 7.50364 12.0124 7.86364L11.9989 10.0758C12.0022 10.431 11.8587 10.7726 11.6067 11.0247L10.0325 12.5988C9.78047 12.8509 9.4436 12.9896 9.08361 12.991L6.86672 12.9998C6.51144 13.006 6.17095 12.8652 5.9209 12.6152L4.35926 11.0535C4.10921 10.8035 3.97319 10.4677 3.97462 10.1077L3.98346 7.89089C3.98014 7.53564 4.12361 7.19402 4.37566 6.94197L5.9498 5.36783C6.20186 5.11577 6.53872 4.97707 6.89872 4.97563L9.12027 4.97151Z"
          fill={theme.tokens.colors.icon['icon-warning']}
        />
      </svg>
    </Icon>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M22.0009 10.6253H14.3853L18.1985 4.03185L15.8172 2.65564L13.3755 6.87552V2H10.6262V9.61714L4.03271 5.80393L2.65649 8.1837L6.87638 10.6253H2.00085V13.3747H9.61799L5.80478 19.9681L8.18456 21.3459L10.6262 17.1245V22H13.3755V14.3844L19.969 18.1976L21.3468 15.8163L17.1253 13.3747H22.0009V10.6253Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M16.0021 6.9001H9.90866L12.9597 1.62447L11.0543 0.523313L9.10073 3.89977V-0.00128174H6.9009V6.09342L1.62526 3.04236L0.524106 4.94648L3.90056 6.9001H-0.000488281V9.09994H6.09421L3.04315 14.3756L4.94728 15.478L6.9009 12.1003V16.0013H9.10073V9.90786L14.3764 12.9589L15.4788 11.0536L12.1011 9.09994H16.0021V6.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportArtifact: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import {
  getBasketConstraints,
  selectExpressRate
} from 'astra-core/containers/BasketProvider'
import { useTranslation } from 'react-i18next'

import { TagMax } from 'entities/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const TagMaxTotalOddsSize = () => {
  const constrains = useAppSelector(getBasketConstraints) // Always rerender.  Every time new link
  const expressRate = useAppSelector(selectExpressRate)

  const [t] = useTranslation()

  return constrains?.betExpressMaxCoef === expressRate ? (
    <TagMax tooltipText={t('maximum total odds size')} />
  ) : null
}

import { FC } from 'react'
import {
  basketProviderActions,
  selectBasketAppliedCoupon
} from 'astra-core/containers/BasketProvider'
import { formatAmount, useGetCurrencyIcon } from 'astra-core'

import { BasketBonusCouponCanUse } from 'entities/basket-bonus-coupon'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { BonusCouponCanUseProps } from './bonus-coupon-can-use.types'

export const BonusCouponCanUse: FC<BonusCouponCanUseProps> = ({
  id,
  possibleAmount,
  coupon: { amount }
}) => {
  const dispatch = useAppDispatch()
  const currencyIcon = useGetCurrencyIcon()
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)

  const setAppliedCoupon = () => {
    localStorage.setItem('appliedCoupon', 'true')

    if (appliedCoupon === id) {
      dispatch(basketProviderActions.setAppliedCoupon(null))
    } else {
      dispatch(basketProviderActions.setAppliedCoupon(id))
    }
  }

  const isRadioChecked = appliedCoupon === id

  const couponValue = formatAmount({
    value: possibleAmount ?? amount,
    currency: currencyIcon
  })

  return (
    <BasketBonusCouponCanUse
      isRadioChecked={isRadioChecked}
      value={couponValue}
      onChangeRadio={setAppliedCoupon}
    />
  )
}

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBonus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M11.4853 1.58591C11.1103 1.21084 10.6015 1.00012 10.0711 1.00012H5.92898C5.39855 1.00012 4.88984 1.21084 4.51477 1.58591L1.58583 4.51484C1.21076 4.88991 1.00005 5.39862 1.00005 5.92905V10.0712C1.00005 10.6016 1.21076 11.1103 1.58583 11.4854L4.51476 14.4143C4.88984 14.7894 5.39854 15.0001 5.92898 15.0001H10.0711C10.6015 15.0001 11.1103 14.7894 11.4853 14.4143L14.4143 11.4854C14.7893 11.1103 15 10.6016 15 10.0712V5.92905C15 5.39862 14.7893 4.88991 14.4143 4.51484L11.4853 1.58591ZM4.09281 5.94349C3.86286 5.94349 3.65907 6.09155 3.58802 6.31024L3.42763 6.80385C3.35658 7.02254 3.43442 7.26211 3.62045 7.39727L5.25057 8.58162C5.4366 8.71678 5.51444 8.95635 5.44338 9.17504L4.8207 11.0915C4.74964 11.3102 4.82748 11.5497 5.01351 11.6849L5.43334 11.9899C5.61937 12.1251 5.87127 12.1251 6.0573 11.9899L7.68752 10.8055C7.87355 10.6703 8.12545 10.6703 8.31147 10.8055L9.94236 11.9904C10.1284 12.1256 10.3803 12.1256 10.5663 11.9904L10.9857 11.6857C11.1718 11.5505 11.2496 11.3109 11.1785 11.0923L10.5556 9.17504C10.4845 8.95635 10.5624 8.71678 10.7484 8.58162L12.3796 7.39649C12.5656 7.26133 12.6435 7.02176 12.5724 6.80307L12.4123 6.31024C12.3412 6.09155 12.1375 5.94349 11.9075 5.94349H9.89124C9.66129 5.94349 9.4575 5.79542 9.38645 5.57673L8.76356 3.6597C8.69251 3.44101 8.48871 3.29294 8.25877 3.29294H7.74022C7.51028 3.29294 7.30648 3.44101 7.23543 3.6597L6.61255 5.57673C6.54149 5.79542 6.3377 5.94349 6.10775 5.94349H4.09281Z"
        fill="#FFC61A"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

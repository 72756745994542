import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon/types'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconPaymentCash: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M4.45589 1.34361C4.28289 1.29789 4.09855 1.32182 3.94344 1.41015L2.77374 2.07619C2.61863 2.16452 2.50545 2.30999 2.45909 2.48062L0.856021 8.38116C0.809664 8.55178 0.833932 8.73358 0.923486 8.88656L1.59881 10.0402C1.68836 10.1932 1.83587 10.3048 2.00887 10.3505L13.2101 13.3106C13.3831 13.3564 13.5675 13.3324 13.7226 13.2441L14.8923 12.5781C15.0474 12.4897 15.1606 12.3443 15.2069 12.1736L16.81 6.2731C16.8564 6.10247 16.8321 5.92067 16.7425 5.76769L16.0672 4.61406C15.9777 4.46108 15.8301 4.34945 15.6571 4.30374L4.45589 1.34361ZM8.2275 4.40895C7.86723 4.31374 7.49693 4.5246 7.4004 4.87991C7.30387 5.23522 7.51766 5.60044 7.87792 5.69565L11.1395 6.55757C11.4998 6.65278 11.8701 6.44192 11.9666 6.08661C12.0631 5.73129 11.8493 5.36608 11.4891 5.27087L8.2275 4.40895ZM7.70314 6.339C7.34287 6.24379 6.97257 6.45465 6.87604 6.80996C6.77951 7.16527 6.9933 7.53049 7.35356 7.6257L8.33203 7.88428L7.80767 9.81433C7.71114 10.1696 7.92494 10.5349 8.2852 10.6301C8.64546 10.7253 9.01577 10.5144 9.1123 10.1591L9.63666 8.22905L10.6151 8.48762C10.9754 8.58283 11.3457 8.37197 11.4422 8.01666C11.5388 7.66135 11.325 7.29613 10.9647 7.20092L7.70314 6.339Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M15.2486 0.666672H5.49967L16.1663 3.33334L16.5212 2.30632C16.5706 2.11174 16.5284 1.9056 16.4063 1.74515L15.7888 0.93309C15.6613 0.765376 15.4611 0.666672 15.2486 0.666672Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M12.0007 23C17.5237 23 22.0009 18.5442 22.0009 13.0477C22.0009 7.55105 17.5237 3.09523 12.001 3.09523C6.478 3.0955 2.00085 7.55105 2.00085 13.0475C2.00085 18.5444 6.478 23 12.0007 23Z"
        fill="#FF9C00"
      />
      <path
        d="M12.0007 21.9523C16.9423 21.9523 20.9481 17.9656 20.9481 13.0478C20.9481 8.12965 16.9423 4.14298 12.001 4.14298C7.05936 4.14298 3.05362 8.12965 3.05362 13.0475C3.05362 17.9656 7.05936 21.9523 12.0007 21.9523Z"
        fill="#01579B"
      />
      <path
        d="M17.264 16.1905C17.7467 17.7184 16.1236 18.8332 14.8517 18.8332C14.156 18.8332 13.295 18.5692 13.0535 17.7618C12.3223 18.1531 11.6189 18.5939 10.9482 19.081V19.857H17.7456C18.8069 18.9746 19.6483 17.8595 20.204 16.5991C19.7541 16.4314 18.8368 16.1905 17.264 16.1905ZM18.613 7.061C17.0544 7.52332 15.6107 8.30596 14.3751 9.35835C13.9109 10.7608 12.7993 12.305 10.9482 13.5714V17.2382C10.9482 17.2382 12.4199 14.5619 15.9045 13.7457C17.5614 13.3603 19.2485 13.1174 20.9473 13.0197C20.9422 10.8134 20.1097 8.68848 18.613 7.061Z"
        fill="#0277BD"
      />
      <path
        d="M17.7478 6.23435C17.2473 5.81453 16.7021 5.45047 16.122 5.14865C15.5362 5.41815 14.8028 5.7894 14.1062 6.2382C14.4373 6.65042 14.6097 7.2018 14.6246 7.83045C15.5858 7.15601 16.637 6.61877 17.7478 6.23435Z"
        fill="#0091EA"
      />
      <path
        d="M12.0007 20.9048C16.361 20.9048 19.8956 17.387 19.8956 13.0477C19.8956 8.70825 16.361 5.19045 12.0007 5.19045C7.64046 5.19045 4.10638 8.70825 4.10638 13.0477C4.10638 17.3872 7.64073 20.9048 12.0007 20.9048Z"
        fill="#0277BD"
      />
      <path
        d="M14.9255 5.7542C14.6469 5.90608 14.3736 6.06751 14.1062 6.2382C14.4373 6.65042 14.6097 7.2018 14.6246 7.83045C15.256 7.39097 15.926 7.00918 16.6265 6.68975C16.1006 6.30907 15.5292 5.99482 14.9255 5.7542Z"
        fill="#00B0FF"
      />
      <path
        d="M19.8956 13.0477C19.8956 10.853 18.9898 8.87077 17.532 7.44545C16.0189 8.06365 14.9252 8.8837 14.3751 9.35835C13.9109 10.7608 12.7993 12.305 10.9482 13.5714V17.2382C10.9482 17.2382 12.4199 14.5619 15.9045 13.7457C17.2178 13.4412 18.5503 13.2253 19.8928 13.0995C19.8928 13.0821 19.8956 13.0656 19.8956 13.0477Z"
        fill="#0091EA"
      />
      <path
        d="M20.1446 18.8096H16.7376V19.8573H19.2797C19.5916 19.528 19.8806 19.178 20.1446 18.8096ZM5.15887 20.2918C5.48983 20.6021 5.84156 20.8896 6.21163 21.1525V4.94268C5.84156 5.20558 5.48983 5.49315 5.15887 5.80342V20.2918Z"
        fill="#B3831D"
      />
      <path
        d="M5.15887 18.7796C5.48006 19.1581 5.83218 19.5094 6.21163 19.8301V6.2646C5.83219 6.58516 5.48007 6.93644 5.15887 7.31482V18.7796Z"
        fill="#01579B"
      />
      <path
        d="M4.63248 1H10.948L10.9482 19.857H21.4742L18.8428 23H4.63248L6.21135 20.9048V3.09523L4.63248 1Z"
        fill="#FFC107"
      />
      <path
        d="M12.0007 18.8095L12.001 4.14298C11.6445 4.14298 11.2939 4.16965 10.9482 4.2098V19.857L17.7602 19.8573C18.1405 19.5376 18.4936 19.1872 18.8158 18.8095H12.0007Z"
        fill="#01579B"
      />
      <path
        d="M12.0007 12.7307C11.6715 13.0367 11.3197 13.3178 10.9482 13.5714V17.2382C10.9482 17.2382 11.2829 16.6362 12.001 15.9177L12.0007 12.7307Z"
        fill="#0277BD"
      />
      <path
        d="M10.9482 19.857L10.948 1H9.89547V20.9048H20.5972L21.4742 19.857H10.9482Z"
        fill="#FF9C00"
      />
      <path
        d="M17.264 16.1905C17.7467 17.7184 16.1236 18.8332 14.8517 18.8332C14.156 18.8332 13.295 18.5692 13.0535 17.7618C12.3223 18.1531 11.6189 18.5939 10.9482 19.081V19.857H15.9283C17.3474 19.044 18.4783 17.8129 19.165 16.3335C18.6815 16.2518 18.0562 16.1905 17.264 16.1905Z"
        fill="#0091EA"
      />
      <path
        d="M18.5002 19.15C18.6036 19.0422 18.6931 18.922 18.7903 18.8096H15.164C15.059 18.8236 14.954 18.8332 14.8517 18.8332C14.7418 18.8332 14.6279 18.8236 14.513 18.8096L12.0007 18.8095L12.001 18.3717C11.6423 18.5965 11.2912 18.8331 10.9482 19.081V19.857H17.7456C18.0072 19.6382 18.2563 19.4051 18.4919 19.1588L18.5002 19.15Z"
        fill="#0277BD"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M7.99946 15.3333C11.6815 15.3333 14.6662 12.3627 14.6662 8.69846C14.6662 5.03399 11.6815 2.06344 7.99964 2.06344C4.31765 2.06363 1.33289 5.03399 1.33289 8.69828C1.33289 12.3629 4.31765 15.3333 7.99946 15.3333Z"
        fill="#FF9C00"
      />
      <path
        d="M7.99946 14.6348C11.2939 14.6348 13.9644 11.977 13.9644 8.69846C13.9644 5.41973 11.2939 2.76194 7.99964 2.76194C4.70523 2.76194 2.03473 5.41973 2.03473 8.69828C2.03473 11.977 4.70522 14.6348 7.99946 14.6348Z"
        fill="#01579B"
      />
      <path
        d="M11.5083 10.7936C11.8301 11.8122 10.7481 12.5554 9.90014 12.5554C9.4363 12.5554 8.8623 12.3794 8.7013 11.8412C8.21383 12.1021 7.7449 12.3959 7.2978 12.7206V13.238H11.8294C12.5369 12.6497 13.0979 11.9063 13.4683 11.066C13.1684 10.9542 12.5568 10.7936 11.5083 10.7936ZM12.4076 4.70729C11.3686 5.0155 10.4061 5.53727 9.58238 6.23886C9.27291 7.17386 8.53183 8.20328 7.2978 9.04753V11.4921C7.2978 11.4921 8.27891 9.70789 10.602 9.16376C11.7066 8.9068 12.8313 8.74487 13.9638 8.67976C13.9604 7.2089 13.4055 5.79228 12.4076 4.70729Z"
        fill="#0277BD"
      />
      <path
        d="M11.8309 4.15619C11.4972 3.87632 11.1337 3.63361 10.747 3.43239C10.3564 3.61206 9.86754 3.85956 9.40314 4.15876C9.62383 4.43358 9.73878 4.80116 9.74872 5.22026C10.3895 4.77064 11.0903 4.41247 11.8309 4.15619Z"
        fill="#0091EA"
      />
      <path
        d="M7.99946 13.9365C10.9063 13.9365 13.2627 11.5913 13.2627 8.69846C13.2627 5.80546 10.9063 3.46026 7.99946 3.46026C5.09262 3.46026 2.73657 5.80546 2.73657 8.69846C2.73657 11.5915 5.0928 13.9365 7.99946 13.9365Z"
        fill="#0277BD"
      />
      <path
        d="M9.94933 3.83609C9.76358 3.93734 9.58141 4.04496 9.40314 4.15876C9.62383 4.43358 9.73878 4.80116 9.74872 5.22026C10.1697 4.92727 10.6163 4.67275 11.0833 4.45979C10.7327 4.20601 10.3518 3.9965 9.94933 3.83609Z"
        fill="#00B0FF"
      />
      <path
        d="M13.2627 8.69846C13.2627 7.23528 12.6589 5.91381 11.687 4.96359C10.6782 5.37573 9.94915 5.92243 9.58238 6.23886C9.27291 7.17386 8.53183 8.20328 7.2978 9.04753V11.4921C7.2978 11.4921 8.27891 9.70789 10.602 9.16376C11.4775 8.96073 12.3658 8.81679 13.2609 8.73293C13.2609 8.72138 13.2627 8.71038 13.2627 8.69846Z"
        fill="#0091EA"
      />
      <path
        d="M13.4287 12.5397H11.1574V13.2382H12.8521C13.0601 13.0187 13.2527 12.7853 13.4287 12.5397ZM3.43823 13.5278C3.65887 13.7347 3.89336 13.9264 4.14007 14.1017V3.29508C3.89336 3.47034 3.65887 3.66206 3.43823 3.86891V13.5278Z"
        fill="#B3831D"
      />
      <path
        d="M3.43823 12.5197C3.65236 12.772 3.8871 13.0062 4.14007 13.22V4.17636C3.88711 4.39007 3.65236 4.62425 3.43823 4.87651V12.5197Z"
        fill="#01579B"
      />
      <path
        d="M3.08731 0.666626H7.29762L7.2978 13.238H14.3151L12.5609 15.3333H3.08731L4.13988 13.9365V2.06344L3.08731 0.666626Z"
        fill="#FFC107"
      />
      <path
        d="M7.99946 12.5397L7.99964 2.76194C7.76201 2.76194 7.52825 2.77973 7.2978 2.80649V13.238L11.8391 13.2382C12.0927 13.0251 12.328 12.7914 12.5428 12.5397H7.99946Z"
        fill="#01579B"
      />
      <path
        d="M7.99946 8.48708C7.78001 8.69112 7.54546 8.87846 7.2978 9.04753V11.4921C7.2978 11.4921 7.52088 11.0908 7.99965 10.6117L7.99946 8.48708Z"
        fill="#0277BD"
      />
      <path
        d="M7.2978 13.238L7.29762 0.666626H6.59596V13.9365H13.7304L14.3151 13.238H7.2978Z"
        fill="#FF9C00"
      />
      <path
        d="M11.5083 10.7936C11.8301 11.8122 10.7481 12.5554 9.90014 12.5554C9.4363 12.5554 8.8623 12.3794 8.7013 11.8412C8.21383 12.1021 7.7449 12.3959 7.2978 12.7206V13.238H10.6178C11.5639 12.6959 12.3178 11.8752 12.7757 10.8889C12.4533 10.8345 12.0364 10.7936 11.5083 10.7936Z"
        fill="#0091EA"
      />
      <path
        d="M12.3325 12.7666C12.4014 12.6948 12.461 12.6146 12.5259 12.5397H10.1083C10.0383 12.549 9.9683 12.5554 9.90014 12.5554C9.82683 12.5554 9.75093 12.549 9.6743 12.5397L7.99946 12.5397L7.99965 12.2478C7.76052 12.3976 7.52644 12.5553 7.2978 12.7206V13.238H11.8294C12.0038 13.0921 12.1699 12.9367 12.3269 12.7725L12.3325 12.7666Z"
        fill="#0277BD"
      />
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M10.9449 1H4.62664L6.2062 3.09523V4.93869C6.20578 4.93898 6.20536 4.93928 6.20494 4.93958V6.26684C6.20536 6.26649 6.20578 6.26613 6.2062 6.26578V7.73471C6.20578 7.73516 6.20536 7.73562 6.20494 7.73607V18.3559C6.20536 18.3563 6.20578 18.3568 6.2062 18.3572V19.8262C6.20578 19.8258 6.20536 19.8254 6.20494 19.8251V20.9048L6.08397 21.0652C6.0837 21.065 6.08424 21.0654 6.08397 21.0652L4.62664 23H18.8432L21.4758 19.857H10.9452L10.9449 1Z"
        fill="currentColor"
      />
      <path
        d="M4.12154 13.046C4.12154 14.4223 4.47814 15.7158 5.10446 16.84V9.25195C4.47814 10.3761 4.12154 11.6696 4.12154 13.046Z"
        fill="currentColor"
      />
      <path
        d="M3.05491 13.046C3.05491 10.892 3.82423 8.91667 5.10446 7.37716V5.84347C3.19294 7.65524 2.00171 10.2122 2.00171 13.046C2.00171 15.8797 3.19294 18.4367 5.10446 20.2485V18.7148C3.82423 17.1753 3.05491 15.2 3.05491 13.046Z"
        fill="currentColor"
      />
      <path
        d="M12.0442 4.14653L12.0441 3.09888C17.5475 3.12162 22.0017 7.56637 22.0017 13.046C22.0017 15.1712 21.3317 17.1407 20.1903 18.757H18.8636C20.165 17.2115 20.9485 15.2197 20.9485 13.046C20.9485 8.14497 16.9658 4.16927 12.0442 4.14653Z"
        fill="currentColor"
      />
      <path
        d="M19.8819 13.046C19.8819 15.2975 18.9275 17.3273 17.3993 18.757H12.0444L12.0442 5.20754C16.3767 5.23028 19.8819 8.73095 19.8819 13.046Z"
        fill="currentColor"
      />
    </>
  ),
  s: () => (
    <>
      <g clipPath="url(#clip0_4284_1328)">
        <path
          d="M7.29708 0.666748H3.08492L4.13796 2.06356V3.29254L4.13712 3.29313V4.17797L4.13796 4.17726V5.15655L4.13712 5.15746V12.2373L4.13796 12.2382V13.2175L4.13712 13.2168V13.9366L4.05647 14.0436L3.08492 15.3334H12.5626L14.3177 13.2381H7.29726L7.29708 0.666748Z"
          fill="currentColor"
        />
        <path
          d="M2.74818 8.69739C2.74818 9.61494 2.98592 10.4773 3.40346 11.2267V6.16804C2.98592 6.91751 2.74818 7.77983 2.74818 8.69739Z"
          fill="currentColor"
        />
        <path
          d="M2.03709 8.69739C2.03709 7.26138 2.54997 5.94452 3.40346 4.91819V3.89573C2.12912 5.10357 1.33496 6.80823 1.33496 8.69739C1.33496 10.5865 2.12912 12.2912 3.40346 13.499V12.4766C2.54997 11.4503 2.03709 10.1334 2.03709 8.69739Z"
          fill="currentColor"
        />
        <path
          d="M8.02992 2.76443L8.02991 2.066C11.6988 2.08116 14.6683 5.04433 14.6683 8.69739C14.6683 10.1142 14.2216 11.4272 13.4607 12.5048H12.5762C13.4438 11.4744 13.9662 10.1466 13.9662 8.69739C13.9662 5.43006 11.311 2.77959 8.02992 2.76443Z"
          fill="currentColor"
        />
        <path
          d="M13.2551 8.69739C13.2551 10.1984 12.6188 11.5516 11.6 12.5048H8.03007L8.02993 3.47177C10.9183 3.48693 13.2551 5.82071 13.2551 8.69739Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4284_1328">
          <rect
            fill="white"
            height="16"
            transform="translate(0.00170898 0.00012207)"
            width="16"
          />
        </clipPath>
      </defs>
    </>
  )
}

export const IconCybersportLeagueOfLegends: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import {
  selectActiveLoyaltyByAppliedCouponIdsByBetType,
  selectBasketBetTypeTab
} from 'astra-core/containers/BasketProvider'
import { ELoyaltyCoupons } from 'astra-core/containers/AccountProvider'
import isEqual from 'react-fast-compare'

import { RootState } from 'shared/types/store'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const useGetSuitableCouponsForBetTypeTab = () => {
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)

  const suitableCoupons = useAppSelector(
    (state: RootState) =>
      selectActiveLoyaltyByAppliedCouponIdsByBetType(
        state,
        ELoyaltyCoupons.SUITABLE
      ),
    isEqual // Necessary to avoid rerender because every time selector changed an object link
  )

  return suitableCoupons[betTypeTab]
}

import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import {
  selectFilterIsTop,
  selectLineEvents,
  selectEventsLayoutIsLoading
} from 'entities/line-tournaments/model/selectors'
import { RootState } from 'shared/types/store'
import { LineParams } from 'entities/line/model/types'
import { useIsEventListHasTopMemo } from 'entities/event'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const LineTopFilter = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isTop = useAppSelector(selectFilterIsTop)
  const { tournamentId } = useParams<LineParams>()

  const layoutIsLoading = useAppSelector(selectEventsLayoutIsLoading)
  const handleSelector = useCallback(
    (state: RootState) => selectLineEvents(state, tournamentId),
    [tournamentId]
  )

  const isEventListHasTop = useIsEventListHasTopMemo(handleSelector)

  const toggleFilterTop = useCallback(() => {
    dispatch(lineTournamentsContainerActions.toggleFilterIsTop())
  }, [dispatch])

  useEffect(() => {
    if (isTop && !isEventListHasTop && !layoutIsLoading) {
      toggleFilterTop()
    }
  }, [isTop, isEventListHasTop, toggleFilterTop, layoutIsLoading])

  return (
    <Button
      disabled={!isEventListHasTop || layoutIsLoading}
      icon={IconTop}
      isActive={isTop}
      view="filter"
      onClick={toggleFilterTop}
    >
      {t('only top')}
    </Button>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M3 11.0498C3 6.07924 7.02944 2.0498 12 2.0498C16.9706 2.0498 21 6.07924 21 11.0498C21 12.9474 20.4128 14.7077 19.4101 16.1591C19.3005 15.7697 19.1836 15.4146 19.0593 15.095C18.8368 14.5226 18.5795 14.0344 18.276 13.6632C17.9718 13.291 17.5881 12.9976 17.1145 12.9046C16.7846 12.8398 16.4323 12.8384 16.0974 12.9046C15.941 12.9355 15.7884 12.9812 15.6436 13.042L15.7988 12.9046L15.9346 12.7843L15.4868 9.93439L12.7668 9.02773L10.499 11.0288L10.8183 12.9046L10.8851 13.2971L8.8112 13.3069L8.78925 13.2902C8.53409 13.0958 8.22659 12.9686 7.90278 12.9046C7.56784 12.8384 7.21544 12.8398 6.88544 12.9046C6.41183 12.9976 6.02809 13.291 5.72392 13.6632C5.65481 13.7477 5.58809 13.8383 5.52363 13.9346L5.05764 13.4154L4.45789 13.488C4.61086 13.9616 4.80717 14.4156 5.04217 14.8454C5.00752 14.9267 4.97368 15.0099 4.9406 15.095C4.81634 15.4146 4.69943 15.7697 4.58984 16.1591C3.58722 14.7077 3 12.9473 3 11.0498ZM9.7263 3.45652L9.69533 4.04499L12.3427 4.66789L14.7016 4.01918L14.8207 3.64222C13.9444 3.30835 12.9935 3.1255 12 3.1255C11.2097 3.1255 10.4465 3.24118 9.7263 3.45652ZM6.69082 7.31461L9.32775 5.06357L11.7832 5.64133L12.0205 8.25167L9.75595 10.2498L7.09225 9.68305L6.69082 7.31461ZM5.63478 7.52115L4.93629 7.45462C4.38601 8.53365 4.0757 9.75549 4.0757 11.0498C4.0757 11.5227 4.11713 11.986 4.19656 12.4362L4.99457 12.3394L6.04356 9.93293L5.63478 7.52115ZM16.9924 12.5863L18.8057 13.2457L19.8163 12.3614C19.8873 11.9347 19.9243 11.4966 19.9243 11.0498C19.9243 10.0494 19.7389 9.09236 19.4007 8.21106L17.8509 7.76112L16.5426 9.72358L16.9924 12.5863ZM15.8051 4.09714L15.741 4.30006L17.8301 6.63498L18.7541 6.90324C18.0329 5.73096 17.015 4.76073 15.8051 4.09714Z"
        fill={theme.tokens.colors['sport-icon']['sport-football']}
        fillRule="evenodd"
      />
      <path
        d="M8.83857 14.4208L12 14.4058L15.1614 14.4208L15.3441 14.2814C15.6632 14.038 16.1624 13.9423 16.6317 14.0346C17.6644 14.2376 18.4618 16.2918 18.9362 19.9709C19.185 21.9007 18.7142 22.7305 17.3763 22.7203C16.5385 22.7135 16.2731 22.3775 15.6398 20.5226C15.3332 19.624 15 19.5313 14.8957 19.5313C14.6667 19.5313 12.9078 19.5309 12 19.5313C11.0926 19.5313 9.33333 19.5313 9.10431 19.5313C9 19.5313 8.66682 19.624 8.36023 20.5226C7.72688 22.3775 7.46148 22.7135 6.62374 22.7203C5.28578 22.7305 4.81496 21.9007 5.06378 19.9709C5.53819 16.2918 6.3356 14.2376 7.36834 14.0346C7.83762 13.9423 8.33681 14.038 8.65588 14.2814L8.83857 14.4208Z"
        fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M0 7.99982C0 3.58154 3.58172 -0.000183105 8 -0.000183105C12.4183 -0.000183105 16 3.58154 16 7.99982C16 10.2983 15.0307 12.3704 13.4785 13.8296C13.4703 13.7306 13.4593 13.6295 13.446 13.5264C13.2727 12.1842 13.0373 11.1096 12.7384 10.3408C12.589 9.95671 12.4163 9.62903 12.2126 9.37982C12.0084 9.13006 11.7509 8.93309 11.433 8.87068C11.419 8.86793 11.4049 8.86535 11.3908 8.86294L11.0994 7.00833L8.68162 6.20242L6.66574 7.98113L6.81716 8.87068L6.86231 9.13595L5.85988 9.14071L5.84514 9.12947C5.67388 8.99905 5.46749 8.91365 5.25015 8.87068C5.02533 8.82624 4.7888 8.8272 4.56731 8.87068C4.24943 8.93309 3.99186 9.13006 3.7877 9.37982C3.584 9.62903 3.41129 9.95671 3.26194 10.3408C3.14817 10.6335 3.04359 10.9704 2.94814 11.3496L1.82901 10.1025L1.2959 10.1671C1.59158 11.0824 2.06949 11.9158 2.68687 12.6245C2.63923 12.91 2.59504 13.2108 2.55428 13.5264C2.54095 13.6296 2.53001 13.7308 2.52181 13.8299C0.969458 12.3707 0 10.2985 0 7.99982ZM5.97893 1.25023L5.9514 1.77331L8.3046 2.32701L10.4015 1.75037L10.5073 1.4153C9.72832 1.11852 8.88314 0.955992 8 0.955992C7.29755 0.955992 6.61912 1.05882 5.97893 1.25023ZM3.28073 4.67965L5.62467 2.67872L7.80729 3.19228L8.01823 5.51258L6.00529 7.2887L3.63755 6.78493L3.28073 4.67965ZM2.34203 4.86323L1.72115 4.8041C1.23201 5.76323 0.956175 6.84931 0.956175 7.99982C0.956175 8.42021 0.993002 8.83199 1.06361 9.23213L1.77295 9.14615L2.70539 7.00704L2.34203 4.86323ZM12.4377 9.36558L14.0495 9.95168L14.9478 9.16565C15.011 8.78643 15.0438 8.39696 15.0438 7.99982C15.0438 7.1106 14.8791 6.25987 14.5784 5.47649L13.2008 5.07654L12.0378 6.82095L12.4377 9.36558ZM11.3823 1.81967L11.3253 2.00005L13.1823 4.07553L14.0037 4.31398C13.3626 3.27195 12.4578 2.40953 11.3823 1.81967Z"
        fill={theme.tokens.colors['sport-icon']['sport-football']}
        fillRule="evenodd"
      />
      <path
        d="M5.93933 10.0497L8.00004 10.0399L10.0607 10.0497L10.1798 9.9588C10.3878 9.80017 10.7132 9.73779 11.0191 9.79794C11.6922 9.93028 12.212 11.2693 12.5212 13.6674C12.6834 14.9253 12.3765 15.4662 11.5044 15.4595C10.9584 15.455 10.7854 15.236 10.3725 14.027C10.1727 13.4412 9.95551 13.3809 9.88752 13.3809C9.73824 13.3809 8.59179 13.3806 8.00004 13.3809C7.40854 13.3809 6.26183 13.3809 6.11255 13.3809C6.04456 13.3809 5.82738 13.4412 5.62754 14.027C5.21471 15.236 5.04171 15.455 4.49565 15.4595C3.62353 15.4662 3.31664 14.9253 3.47883 13.6674C3.78807 11.2693 4.30784 9.93028 4.981 9.79794C5.28689 9.73779 5.61228 9.80017 5.82025 9.9588L5.93933 10.0497Z"
        fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      />
    </>
  )
}

export const IconSportCyberfootball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

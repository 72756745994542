import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { EAdditionalSportsCodes } from 'shared/ui/Icon/sport-icon/sport-icon.types'

export const SHORTNAMED_SPORT_CODES: Record<
  ESportsCodes & EAdditionalSportsCodes,
  string
> = {
  [ESportsCodes.TABLE_TENNIS]: 'table tennis short',
  [ESportsCodes.AUSTRALIAN_FOOTBALL]: 'australian football short',
  [ESportsCodes.AMERICAN_FOOTBALL]: 'american football short',
  [ESportsCodes.ROWING_ACADEMIC]: 'rowing academic short',
  [ESportsCodes.GRECOROMAN_WRESTLING]: 'grecoroman wrestling short',
  [ESportsCodes.SKATING]: 'skating short',
  [ESportsCodes.BEACH_VOLLEYBALL]: 'beach volleyball short',
  [ESportsCodes.BEACH_HANDBALL]: 'beach handball short',
  [ESportsCodes.BEACH_SOCCER]: 'beach soccer short',
  [ESportsCodes.SKI_JUMPING]: 'ski jumping short',
  [ESportsCodes.SYNCHRONIZED_SWIMMING]: 'synchronized swimming short',
  [ESportsCodes.MODERN_PENTATHLON]: 'modern pentathlon short',
  [ESportsCodes.GYMNASTICS]: 'sport gymnastics short',
  [ESportsCodes.PISTOL_SHOOTING]: 'pistol shooting short'
}

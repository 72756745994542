import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M13.6485 3.15063C13.114 3.05171 12.5631 3 12 3C11.5436 3 11.0952 3.03397 10.6571 3.09951C10.8296 3.25098 11.0157 3.42315 11.2049 3.61146C11.7582 4.16216 12.3771 4.88952 12.7461 5.67707C13.1498 6.53871 13.439 7.58747 13.4331 8.8068C13.429 9.64667 13.2849 10.5593 12.9491 11.5387C13.1427 12.0594 13.5941 12.6743 14.2882 13.2135C15.5626 11.23 16.4635 7.10313 13.6485 3.15063Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M9.26346 3.42358C7.21726 4.07592 5.48886 5.44026 4.36951 7.22537C5.22087 6.69719 6.17683 6.26903 7.14408 5.95051C8.49531 5.50555 9.91737 5.25852 11.174 5.26882C10.9406 4.96424 10.6763 4.67258 10.4113 4.40881C9.90816 3.90801 9.4404 3.54468 9.26346 3.42358Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M3.35245 9.49764C3.12297 10.2921 3 11.1317 3 12C3 12.2518 3.01034 12.5011 3.03061 12.7477C4.54247 10.8114 7.61562 8.15926 12.2842 8.21899C12.2257 7.5523 12.0645 6.95568 11.8491 6.43133C10.6259 6.30092 9.04121 6.51021 7.49595 7.01907C5.83155 7.56715 4.29706 8.4351 3.35245 9.49764Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M3.30348 14.3261C3.60971 15.4739 4.13712 16.5314 4.83728 17.4501C5.07411 16.2889 5.49568 15.3116 6.03682 14.4963C6.65641 13.5629 7.42428 12.8545 8.22757 12.3276C9.51046 11.4862 10.8929 11.101 11.9438 10.9958C12.1312 10.409 12.2381 9.85825 12.2829 9.34424C7.2501 9.27677 4.26153 12.7137 3.30348 14.3261Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M5.80784 18.5313C7.42117 20.0613 9.60097 21 12 21C12.0211 21 12.0422 20.9999 12.0633 20.9998C10.6852 20.3886 9.51751 19.6056 8.58341 18.7786C7.61601 17.9221 6.87477 16.9958 6.41883 16.1408C6.1221 16.8207 5.9059 17.6118 5.80784 18.5313Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M14.4694 20.657C15.5882 20.3385 16.618 19.809 17.5132 19.1142C12.7968 18.7583 9.67298 15.9675 8.3363 13.636C7.89371 13.9884 7.4775 14.4115 7.11392 14.9164C7.35718 15.7068 8.08887 16.8382 9.32915 17.9363C10.5881 19.0509 12.3245 20.0877 14.4694 20.657Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M18.6848 18.0262C19.387 17.2477 19.9551 16.3461 20.3528 15.3575C19.2655 15.6336 18.239 15.689 17.2981 15.5818C16.0647 15.4414 14.9868 15.0228 14.1174 14.4679C13.1075 13.8233 12.3471 12.9724 11.9731 12.1241C11.2105 12.2114 10.229 12.4742 9.27352 13.0083C10.5078 15.2217 13.7027 18.0856 18.6848 18.0262Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M20.7615 14.0669C20.9175 13.4032 21 12.7113 21 12C21 10.4545 20.6104 8.99994 19.9241 7.72923C19.9532 8.60489 19.8506 9.49148 19.6802 10.3285C19.3542 11.9297 18.7634 13.4353 18.2535 14.5077C19.0096 14.5023 19.8281 14.3752 20.6963 14.0845C20.718 14.0773 20.7397 14.0714 20.7615 14.0669Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M18.3225 5.5948C17.4582 4.74162 16.4221 4.06209 15.2708 3.61277C17.5096 7.62913 16.6052 11.6771 15.2389 13.8142C15.771 14.0842 16.3782 14.2949 17.0482 14.4102C17.5612 13.3997 18.2324 11.8004 18.5778 10.104C18.9074 8.48505 18.9214 6.87439 18.3225 5.5948Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M9.4653 0.133893C8.99026 0.0459615 8.5005 0 8 0C7.59434 0 7.19573 0.0301931 6.8063 0.0884551C6.95966 0.223096 7.12511 0.376136 7.29328 0.543523C7.78507 1.03303 8.33519 1.67958 8.66318 2.37961C9.02204 3.14552 9.27914 4.07775 9.27386 5.1616C9.27023 5.90815 9.1421 6.71936 8.84362 7.58996C9.01572 8.05279 9.41698 8.59935 10.034 9.07867C11.1668 7.31553 11.9675 3.64722 9.4653 0.133893Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M5.56752 0.37652C3.74867 0.956376 2.21232 2.16912 1.21734 3.75588C1.9741 3.28639 2.82385 2.9058 3.68363 2.62268C4.88472 2.22716 6.14878 2.00758 7.26582 2.01673C7.05835 1.74599 6.82338 1.48674 6.58782 1.25227C6.14059 0.807123 5.7248 0.484164 5.56752 0.37652Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M0.313288 5.77568C0.109308 6.48183 0 7.22815 0 8C0 8.22379 0.00918927 8.44544 0.0272111 8.66459C1.37108 6.94347 4.10278 4.58601 8.25262 4.6391C8.20061 4.04649 8.05731 3.51616 7.86583 3.05007C6.77859 2.93415 5.36997 3.12019 3.9964 3.5725C2.51693 4.05969 1.15295 4.8312 0.313288 5.77568Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M0.269762 10.0676C0.54196 11.0879 1.01077 12.0279 1.63313 12.8446C1.84366 11.8124 2.21838 10.9436 2.6994 10.219C3.25014 9.38923 3.9327 8.75958 4.64673 8.29123C5.78708 7.54325 7.01593 7.20092 7.95006 7.1074C8.11659 6.58575 8.21163 6.09622 8.2515 5.63932C3.77787 5.57935 1.12136 8.63437 0.269762 10.0676Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M2.49585 13.8056C3.92993 15.1656 5.86753 16 8 16C8.01877 16 8.03752 15.9999 8.05627 15.9998C6.83127 15.4565 5.79334 14.7605 4.96303 14.0254C4.10312 13.2641 3.44424 12.4407 3.03896 11.6807C2.7752 12.2851 2.58302 12.9883 2.49585 13.8056Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M10.1951 15.6951C11.1895 15.412 12.1049 14.9413 12.9007 14.3237C8.70823 14.0074 5.93154 11.5266 4.74338 9.45419C4.34997 9.76746 3.98 10.1436 3.65682 10.5923C3.87305 11.295 4.52344 12.3006 5.62591 13.2767C6.74501 14.2675 8.28848 15.1891 10.1951 15.6951Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M13.9421 13.3566C14.5662 12.6647 15.0712 11.8632 15.4247 10.9845C14.4582 11.2298 13.5458 11.2791 12.7095 11.1839C11.6131 11.0591 10.6549 10.687 9.88212 10.1937C8.98441 9.62069 8.30855 8.86433 7.97608 8.11029C7.29825 8.18791 6.42578 8.42156 5.57646 8.89631C6.67362 10.8637 9.51351 13.4094 13.9421 13.3566Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M15.788 9.83724C15.9267 9.24732 16 8.63223 16 8C16 6.6262 15.6537 5.33328 15.0437 4.20376C15.0695 4.98213 14.9783 5.77021 14.8268 6.51421C14.5371 7.9375 14.0119 9.27581 13.5587 10.2291C14.2308 10.2243 14.9583 10.1113 15.7301 9.8529C15.7493 9.84646 15.7686 9.84126 15.788 9.83724Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
      <path
        d="M13.62 2.30649C12.8517 1.54811 11.9307 0.944077 10.9073 0.544686C12.8975 4.11479 12.0935 7.713 10.879 9.61258C11.352 9.85259 11.8917 10.0399 12.4873 10.1424C12.9433 9.24419 13.5399 7.82262 13.8469 6.31471C14.1399 4.8756 14.1523 3.4439 13.62 2.30649Z"
        fill={theme.tokens.colors['sport-icon']['sport-gaelicFootball']}
      />
    </>
  )
}

export const IconSportGaelicFootball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import dayjs from 'dayjs'
import isYesterday from 'dayjs/plugin/isYesterday'
import isToday from 'dayjs/plugin/isToday'
import { TFunction } from 'react-i18next'
import { dayjsCore } from 'astra-core'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

export function formatBetsHistoryDate(date: Date): string {
  return `${dayjsCore(date).format('D MMMM')}, ${dayjsCore(date)
    .format('dd')
    .toUpperCase()}`
}

export function isTodayOrYesterday(date: Date, t: TFunction): string | null {
  if (dayjs(date).isToday()) {
    return t('today')
  } else if (dayjs(date).isYesterday()) {
    return t('yesterday')
  } else {
    return null
  }
}

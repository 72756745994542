import { EntityTag, EventStatus } from 'betweb-openapi-axios'

import { EntityWithTags } from './interface'

export const entityTagsColorsSchema = {
  TOP: '#0D80F2',
  SUPERTOP: '#FF6B00'
}

export enum ETagsTop {
  TOP = 'TOP',
  SUPERTOP = 'SUPERTOP',
  EVENT_DAY = 'EVENT DAY'
}

export const ENTITY_TAGS_LIST = [ETagsTop.TOP, ETagsTop.SUPERTOP]

export const getEntityTag = (
  entity: EntityWithTags,
  entityTags: Dictionary<EntityTag>
) =>
  entity.tags?.find((tag) =>
    ENTITY_TAGS_LIST.includes(entityTags[tag]?.name.toUpperCase() as ETagsTop)
  )

export const getTopTagIds = (entityTags: Dictionary<EntityTag>) =>
  Object.entries(entityTags)
    .filter(([_key, value]) =>
      ENTITY_TAGS_LIST.includes(value.name.toUpperCase() as ETagsTop)
    )
    .map(([key]) => +key)

export const getEntityTagName = (
  entity: EntityWithTags,
  entityTags: Dictionary<EntityTag>
) => {
  const tagId = getEntityTag(entity, entityTags)

  if (!tagId) {
    return null
  }
  return entityTags[tagId].name.toUpperCase() as ETagsTop
}

export const HIDDEN_FAVORITES_STATUSES = [
  EventStatus.Ended,
  EventStatus.Cancelled,
  EventStatus.Retired,
  EventStatus.Closed
]

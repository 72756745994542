import { useEffect } from 'react'

import {
  usePopUpNotification,
  ENotificationContainerIds
} from 'shared/lib/Toastify'
import { TOASTIFY_MY_BONUSES_ID } from 'widgets/my-bonuses-toastify-notification/constants'
import { MyBonusesToastifyNotification } from 'widgets/my-bonuses-toastify-notification'
import { selectIsShowMyBonusesToastifyNotification } from 'containers/LayoutContainer/selectors'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const useToastifyMyBonusesModal = () => {
  const isShownMyBonusesToastifyNotification = useAppSelector(
    selectIsShowMyBonusesToastifyNotification
  )

  const setToastifyNotify = usePopUpNotification({
    content: {
      children: (props) => <MyBonusesToastifyNotification {...props} />
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: false,
      toastId: TOASTIFY_MY_BONUSES_ID
    }
  })

  useEffect(() => {
    if (isShownMyBonusesToastifyNotification) {
      setToastifyNotify()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownMyBonusesToastifyNotification])

  return null
}

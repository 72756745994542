import { Tournament } from 'betweb-openapi-axios'
import { createSelector } from '@reduxjs/toolkit'
import { getEvents } from 'astra-core/containers/EventsProvider'

import { RootState } from 'shared/types/store'

import { emptyTournament, initialState } from './slice'

const getSearchTournamentContainer = (state: RootState) =>
  state.searchTournamentContainer ?? initialState

const getSearchTournamentLiveEvents = (
  state: RootState,
  tournamentId: Tournament['id']
) =>
  (
    getSearchTournamentContainer(state).tournaments[tournamentId] ??
    emptyTournament
  ).liveEvents

const getSearchTournamentLineEvents = (
  state: RootState,
  tournamentId: Tournament['id']
) =>
  (
    getSearchTournamentContainer(state).tournaments[tournamentId] ??
    emptyTournament
  ).lineEvents

export const selectSearchTournamentLiveEvents = createSelector(
  [getSearchTournamentLiveEvents, getEvents],
  (ids, entries) => ids.map((id) => entries[id])
)

export const selectSearchTournamentLineEvents = createSelector(
  [getSearchTournamentLineEvents, getEvents],
  (ids, entries) => ids.map((id) => entries[id])
)

export const selectSearchTournamentLayoutIsLoading = (state: RootState) =>
  getSearchTournamentContainer(state).layoutIsLoading

export const selectSearchTournamentEvents = createSelector(
  [getSearchTournamentLiveEvents, getSearchTournamentLineEvents, getEvents],
  (liveIds, lineIds, entries) =>
    [...liveIds, ...lineIds].map((id) => entries[id])
)

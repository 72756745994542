import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledPeriodSelectsContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
    text-align: center;
  `}
`

export const StyledPeriodSelectButton = styled.div`
  ${typography['Hint / 10 BOLD']}
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;

    text-align: center;
  `}
`

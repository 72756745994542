import React from 'react'

export const IconVK = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_326_38)">
        <path
          d="M0.5 7.7C0.5 4.30589 0.5 2.60883 1.55442 1.55442C2.60883 0.5 4.30589 0.5 7.7 0.5H8.3C11.6941 0.5 13.3912 0.5 14.4456 1.55442C15.5 2.60883 15.5 4.30589 15.5 7.7V8.3C15.5 11.6941 15.5 13.3912 14.4456 14.4456C13.3912 15.5 11.6941 15.5 8.3 15.5H7.7C4.30589 15.5 2.60883 15.5 1.55442 14.4456C0.5 13.3912 0.5 11.6941 0.5 8.3V7.7Z"
          fill="#0077FF"
        />
        <path
          d="M8.46054 11.2812C4.93716 11.2812 2.92749 8.99381 2.84375 5.1875H4.60867C4.66664 7.98123 5.96775 9.16459 6.99834 9.40859V5.1875H8.66026V7.59692C9.67797 7.49323 10.7471 6.39527 11.1078 5.1875H12.7697C12.4927 6.67587 11.3333 7.77383 10.5088 8.22521C11.3333 8.5912 12.6538 9.54888 13.1562 11.2812H11.3269C10.9339 10.1223 9.95495 9.2256 8.66026 9.10359V11.2812H8.46054Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_326_38">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  )
}

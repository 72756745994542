import { PayloadAction } from '@reduxjs/toolkit'
import { Tournament } from 'betweb-openapi-axios'
import i18n from 'i18next'
import { INITIAL_FILTER_PERIOD_ID } from 'astra-core/containers/LineProvider/slice'
import dayjs from 'dayjs'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState, toIdsArray } from 'shared/lib/@reduxjs'
import { sortAlphabeticallyByField } from 'shared/lib/arrays'
import { getItemLocalStorage } from 'shared/lib/@system'

import {
  ContainerState,
  FetchLinePayload,
  FetchLineSuccessPayload,
  FetchLineTournamentsPayload,
  FetchLineTournamentsSuccessPayload,
  Filters,
  Payloads,
  SetFilterPeriodPayload,
  SetFilterSportIdPayload
} from './types'

const REDUCER_KEY = 'lineTournamentsContainer'
export const LINE_TOURNAMENT_FILTER_LS = 'lineTournamentsFilter'

// The initial state of the LineTournamentsContainer container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  tournamentsIdsBySport: {},
  eventsIdsByTournament: {},
  fetchItems: requestInitialState,
  fetchEventsItems: requestInitialState,
  filters: {
    isTop: false,
    period: {
      id: INITIAL_FILTER_PERIOD_ID,
      applicationTime: '',
      scheduledFrom: '',
      scheduledTo: ''
    },
    search: '',
    eventsSearch: ''
  },
  layouts: {
    isLoading: false,
    isEventsLoading: false
  }
})

const lineTournamentsContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchLineTournaments(
      state,
      _action: PayloadAction<FetchLineTournamentsPayload>
    ) {
      state.fetchItems.loading = true
      state.fetchItems.error = null
    },
    fetchLineTournamentsSuccess(
      state,
      action: PayloadAction<FetchLineTournamentsSuccessPayload>
    ) {
      const { sportId } = action.payload.reqData
      const { items } = action.payload.resData
      state.fetchItems.loading = false
      state.fetchItems.error = null

      state.layouts.isLoading = false
      state.layouts.isEventsLoading = false

      if (sportId) {
        const filteredByAlphabetItems = sortAlphabeticallyByField<Tournament>(
          items,
          'name',
          i18n.language
        )

        const savedLSFilter = getItemLocalStorage(LINE_TOURNAMENT_FILTER_LS)
        if (savedLSFilter) {
          state.filters = savedLSFilter
        }

        state.tournamentsIdsBySport[sportId] = toIdsArray(
          filteredByAlphabetItems
        )
      }
    },
    fetchLineTournamentsError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItems.loading = false
      state.fetchItems.error = action.payload

      state.layouts.isLoading = false
      state.layouts.isEventsLoading = false
    },
    toggleFilterIsTop(state) {
      if (!state.layouts.isLoading || !state.layouts.isEventsLoading) {
        state.filters.isTop = !state.filters.isTop
        state.layouts.isLoading = true
        state.layouts.isEventsLoading = true
        state.tournamentsIdsBySport = initialState.tournamentsIdsBySport
        state.eventsIdsByTournament = initialState.eventsIdsByTournament
      }
    },
    setFilterPeriod(state, { payload }: PayloadAction<SetFilterPeriodPayload>) {
      state.filters.period = {
        ...state.filters.period,
        ...payload,
        applicationTime:
          payload.id !== INITIAL_FILTER_PERIOD_ID ? dayjs().format() : ''
      }
      state.layouts.isLoading = true
      state.layouts.isEventsLoading = true
    },
    setFilterSportId(
      state,
      { payload }: PayloadAction<SetFilterSportIdPayload>
    ) {
      state.filters.sportId = payload.sportId
    },
    resetFilters(
      state,
      { payload }: PayloadAction<Payloads['resetLineFilter']>
    ) {
      state.layouts.isLoading = true
      state.fetchEventsItems.loading = true

      const { excludedFilters } = payload || {}

      if (excludedFilters?.length) {
        const filteredFiltersList = Object.entries(initialState.filters).map(
          ([key, value]) =>
            excludedFilters.includes(key as Filters)
              ? [key, state.filters[key as Filters]] // set existing filter item value
              : [key, value] // set initial filter item value
        )

        state.filters = Object.fromEntries(filteredFiltersList)
      } else {
        state.filters = initialState.filters
      }
    },
    fetchLine(state, _action: PayloadAction<FetchLinePayload>) {
      state.fetchEventsItems.loading = true
      state.fetchEventsItems.error = null
    },
    fetchLineSuccess(state, action: PayloadAction<FetchLineSuccessPayload>) {
      const { tournamentId } = action.payload.reqData
      const { items } = action.payload.resData

      state.fetchEventsItems.loading = false
      state.fetchEventsItems.error = null
      state.layouts.isEventsLoading = false

      if (tournamentId) {
        state.eventsIdsByTournament[tournamentId] = toIdsArray(items)

        const savedLSFilter = getItemLocalStorage(LINE_TOURNAMENT_FILTER_LS)
        if (savedLSFilter) {
          state.filters = savedLSFilter
        }
      }
    },
    fetchLineError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchEventsItems.loading = false
      state.fetchEventsItems.error = action.payload
      state.layouts.isEventsLoading = false
    }
  }
})

export const {
  actions: lineTournamentsContainerActions,
  reducer: reducerLine,
  name: sliceKeyLine
} = lineTournamentsContainerSlice

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledEmptyHistoryWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledEmptyContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  text-align: center;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledEmptyText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledEmptyDescription = styled.span`
  ${typography['Body / 14 Medium']};
  text-align: center;
  white-space: break-spaces;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M9.27846 1.55288C9.02785 1.64246 9.06531 1.85142 9.49019 2.73407C11.2062 6.29905 11.9182 9.38259 11.8904 13.1283C11.8774 14.8733 11.9325 15.049 12.5586 15.2569C13.3179 15.5091 17.3334 15.2402 18.9693 14.8275C19.8263 14.6113 20.3085 14.0162 20.1972 13.3121C19.4902 8.83949 16.2396 4.63716 11.8007 2.45721C10.5904 1.86283 9.50258 1.4728 9.27846 1.55288ZM10.0826 7.74159C10.0209 7.79015 9.85612 8.06713 9.68177 8.41548C8.77162 10.234 7.46276 12.1994 5.68473 14.4175C4.93373 15.3543 4.87571 15.6034 5.37513 15.7466C5.6123 15.8146 8.67364 15.7955 8.94796 15.7243C10.0822 15.4299 10.5354 14.6559 10.7629 12.6248C10.9307 11.1266 10.6207 7.87289 10.2933 7.69772C10.1989 7.64717 10.204 7.64612 10.0826 7.74159ZM20.4122 15.6499C14.3461 16.9329 8.31807 17.2623 3.37954 16.5807C1.99188 16.3892 1.65057 16.7397 2.35797 17.6299C3.48685 19.0504 6.25649 20.0267 10.1379 20.3724C16.2645 20.9179 20.2817 19.7164 21.7136 16.9103C22.0955 16.1618 22.0955 15.8036 21.7134 15.5798C21.4945 15.4516 21.3008 15.462 20.4122 15.6499Z"
      fill={theme.tokens.colors['sport-icon']['sport-sailing']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M5.82277 0.425314C5.62228 0.496976 5.65225 0.664146 5.99215 1.37027C7.36492 4.22225 7.93456 6.68908 7.91231 9.68563C7.90194 11.0817 7.94603 11.2222 8.44685 11.3885C9.05434 11.5903 12.2667 11.3751 13.5755 11.045C14.261 10.8721 14.6468 10.396 14.5577 9.83268C13.9922 6.2546 11.3917 2.89273 7.84059 1.14878C6.87232 0.673272 6.00206 0.361246 5.82277 0.425314ZM6.4661 5.37628C6.41669 5.41513 6.2849 5.63671 6.14542 5.91539C5.41729 7.37023 4.37021 8.94251 2.94778 10.717C2.34698 11.4664 2.30057 11.6657 2.7001 11.7803C2.88984 11.8347 5.33891 11.8194 5.55837 11.7625C6.46572 11.5269 6.82832 10.9077 7.01033 9.28288C7.14459 8.08425 6.89654 5.48132 6.63468 5.34118C6.55914 5.30074 6.56317 5.2999 6.4661 5.37628ZM14.7297 11.703C9.87691 12.7293 5.05445 12.9928 1.10363 12.4475C-0.00649255 12.2943 -0.279546 12.5747 0.286375 13.2869C1.18948 14.4234 3.40519 15.2044 6.51032 15.4809C11.4116 15.9173 14.6254 14.9561 15.7709 12.7113C16.0764 12.1124 16.0764 11.8259 15.7707 11.6468C15.5956 11.5443 15.4406 11.5526 14.7297 11.703Z"
      fill={theme.tokens.colors['sport-icon']['sport-sailing']}
      fillRule="evenodd"
    />
  )
}

export const IconSportSailing: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

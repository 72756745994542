import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledHistoryEmptyText,
  StyledHistoryEmptyTitle,
  StyledHistoryEmptyWrapper
} from './EmptyHistoryScreen.styled'

export const EmptyHistoryScreen: FC<{ withBetStatus: boolean }> = ({
  withBetStatus
}) => {
  const [t] = useTranslation()

  return (
    <StyledHistoryEmptyWrapper>
      <StyledHistoryEmptyTitle>
        {withBetStatus
          ? t('bet types history is empty')
          : t('bet history is empty')}
      </StyledHistoryEmptyTitle>
      <StyledHistoryEmptyText>
        {withBetStatus ? t('try to change bet type') : t('start make bets')}
      </StyledHistoryEmptyText>
    </StyledHistoryEmptyWrapper>
  )
}

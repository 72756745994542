import { useTheme } from 'styled-components'
import { useEffect } from 'react'

export const MetaProvider = () => {
  const theme = useTheme()

  useEffect(() => {
    let meta = document.querySelector('meta[name="theme-color"]')

    if (!meta) {
      meta = document.createElement('meta')
      meta.setAttribute('name', 'theme-color')
      document.head.append(meta)
    }
    meta.setAttribute('content', theme.metaThemeColor)
    document.documentElement.style.setProperty(
      '--page-background',
      theme.metaThemeColor
    )
  }, [theme.typeLayoutTheme, theme.metaThemeColor])

  return null
}

import React, { FC } from 'react'
import {
  selectAccountUser,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'
import { Redirect, Route } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { useAuth, useCompleted } from 'hooks'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { AppRoutesProps, CustomRouteProps } from './AppRoute.types'

export const AppRoutes: FC<AppRoutesProps> = ({ routes }) => {
  useAuth()

  const user = useAppSelector(selectAccountUser)
  const { loading } = useAppSelector(selectFetchUserRequest)
  const authRequestCompleted = useCompleted(!!loading)

  return (
    <>
      {routes.map((route) => {
        return (
          <CustomRoute
            authRequestCompleted={authRequestCompleted}
            isAuthorized={!!user}
            key={route.name}
            route={route}
          />
        )
      })}
    </>
  )
}

const CustomRoute: FC<CustomRouteProps> = ({
  route,
  isAuthorized,
  authRequestCompleted
}) => {
  return route.private && !isAuthorized ? (
    <Route
      render={({ location }) =>
        authRequestCompleted ? (
          <Redirect
            to={{
              pathname: ERoutes.Root,
              state: { from: location, showAuthModal: true }
            }}
          />
        ) : (
          <LoaderPoints />
        )
      }
      exact={route.exact}
      path={route.path}
    />
  ) : (
    <Route {...route} />
  )
}

import React, { FC, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { IconAge } from 'shared/ui/Icon/General/IconAge'
import { Typography } from 'shared/ui/typography'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { IFooterOfPageProps } from './footer-of-page.types'
import {
  StyledFooter,
  StyledFooterContacts,
  StyledFooterDivider,
  StyledFooterLicence,
  StyledFooterLicenceText,
  StyledFooterNav,
  StyledFooterRow,
  StyledFooterSocials,
  StyledLicenceLink
} from './footer-of-page.styled'

export const FooterOfPage: FC<IFooterOfPageProps> = memo(
  ({ menuItems, logoMain, socialNetworksItems, helpButtons, phone, mail }) => {
    const [t] = useTranslation()
    const { IS_IFRAME } = useAppSelector(selectConfig)

    return (
      <StyledFooter>
        <StyledFooterRow>
          {logoMain}
          <StyledFooterContacts>
            {mail}
            {phone}
            {!IS_IFRAME ? helpButtons : null}
          </StyledFooterContacts>
        </StyledFooterRow>

        <StyledFooterRow>
          <StyledFooterNav>{menuItems}</StyledFooterNav>

          <StyledFooterSocials>{socialNetworksItems}</StyledFooterSocials>
        </StyledFooterRow>

        <StyledFooterDivider />

        <StyledFooterRow>
          <StyledFooterLicence>
            <IconAge />

            <StyledFooterLicenceText>
              <Trans
                components={[
                  <StyledLicenceLink
                    href="https://olimpbet.kz/public/lic/23019645.pdf"
                    rel="noreferrer"
                    target="_blank"
                  />
                ]}
                i18nKey="requisites"
                t={t}
              />
            </StyledFooterLicenceText>
          </StyledFooterLicence>
          <Typography color="text-description" font="Body / 12 Medium">
            {t('copyright')}
          </Typography>
        </StyledFooterRow>
      </StyledFooter>
    )
  }
)

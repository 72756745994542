import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconFavoritesFilled12: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.243211 4.11504C0.335925 3.81679 0.601833 3.61486 0.901863 3.61486H3.53096C3.83099 3.61486 4.0969 3.41293 4.18961 3.11467L5.00235 0.500184C5.09506 0.201932 5.36097 0 5.661 0H6.3376C6.63763 0 6.90354 0.201932 6.99625 0.500184L7.80899 3.11467C7.9017 3.41293 8.16761 3.61486 8.46764 3.61486H11.0985C11.3985 3.61486 11.6644 3.81679 11.7571 4.11504L11.9661 4.78717C12.0588 5.08542 11.9572 5.41216 11.7145 5.59649L9.58609 7.2128C9.34336 7.39713 9.24179 7.72386 9.33451 8.02211L10.1473 10.6368C10.24 10.9351 10.1385 11.2618 9.89574 11.4462L9.34848 11.8618C9.10575 12.0461 8.77707 12.0461 8.53434 11.8618L6.40637 10.2458C6.16364 10.0614 5.83496 10.0614 5.59223 10.2458L3.46512 11.8611C3.2224 12.0454 2.89372 12.0454 2.65099 11.8611L2.10319 11.4451C1.86046 11.2608 1.7589 10.934 1.85161 10.6358L2.66409 8.02211C2.75681 7.72386 2.65524 7.39713 2.41251 7.2128L0.285525 5.59755C0.0427961 5.41322 -0.0587713 5.08649 0.033943 4.78823L0.243211 4.11504Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

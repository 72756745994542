import React from 'react'
import { useTheme } from 'styled-components'

export const IconTikTok = () => {
  const theme = useTheme()

  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_483_182)">
        <path
          d="M6.56191 6.51263V5.96621C6.36485 5.94058 6.16484 5.92477 5.96077 5.92477C3.50122 5.92477 1.5 7.82916 1.5 10.17C1.5 11.6063 2.25392 12.8772 3.40454 13.6455C2.66206 12.887 2.20668 11.8711 2.20668 10.7551C2.20668 8.44791 4.15107 6.56636 6.56228 6.51263H6.56191Z"
          fill="#25F4EE"
        />
        <path
          d="M6.66709 12.6939C7.76457 12.6939 8.65982 11.863 8.70041 10.8281L8.70447 1.5854H10.4787C10.4418 1.39541 10.4215 1.20017 10.4215 1H7.99816L7.9941 10.2427C7.95351 11.2776 7.05825 12.1085 5.96077 12.1085C5.61979 12.1085 5.29837 12.0274 5.0157 11.8858C5.38583 12.3743 5.98734 12.6939 6.66709 12.6939Z"
          fill="#25F4EE"
        />
        <path
          d="M13.7933 4.72237V4.20862C13.1165 4.20862 12.4859 4.01723 11.957 3.68889C12.4279 4.20335 13.0674 4.57453 13.7933 4.72237Z"
          fill="#25F4EE"
        />
        <path
          d="M11.9574 3.68889C11.4415 3.12527 11.1282 2.38957 11.1282 1.58504H10.4791C10.65 2.46367 11.198 3.21762 11.9574 3.68889Z"
          fill="#FE2C55"
        />
        <path
          d="M5.96077 8.23124C4.83746 8.23124 3.92339 9.10109 3.92339 10.17C3.92339 10.9145 4.36732 11.5614 5.0157 11.8858C4.77399 11.567 4.63007 11.1772 4.63007 10.7551C4.63007 9.68613 5.54414 8.81629 6.66745 8.81629C6.87706 8.81629 7.07818 8.8493 7.26859 8.90584V6.55126C7.07154 6.52562 6.87152 6.50982 6.66745 6.50982C6.63203 6.50982 6.59734 6.51158 6.56228 6.51228V8.32044C6.37186 8.2639 6.17075 8.23089 5.96114 8.23089L5.96077 8.23124Z"
          fill="#FE2C55"
        />
        <path
          d="M13.7933 4.72237V6.51473C12.5368 6.51473 11.3722 6.13231 10.4215 5.483V10.17C10.4215 12.5109 8.42033 14.415 5.96078 14.415C5.01017 14.415 4.12894 14.1298 3.40417 13.6455C4.21898 14.4782 5.37993 15 6.66709 15C9.12701 15 11.1279 13.0956 11.1279 10.7551V6.0684C12.0785 6.71771 13.2431 7.10013 14.4996 7.10013V4.79366C14.2572 4.79366 14.021 4.76873 13.7933 4.72237Z"
          fill="#FE2C55"
        />
        <path
          d="M10.4215 10.17V5.48301C11.3721 6.13231 12.5368 6.51474 13.7933 6.51474V4.72238C13.0674 4.57453 12.4279 4.2037 11.9571 3.68889C11.1976 3.21762 10.6496 2.46402 10.4787 1.58504H8.70447L8.70041 10.8278C8.65982 11.8627 7.76456 12.6935 6.66708 12.6935C5.98734 12.6935 5.38583 12.374 5.0157 11.8855C4.36732 11.5607 3.92339 10.9138 3.92339 10.1697C3.92339 9.10073 4.83746 8.23089 5.96077 8.23089C6.17038 8.23089 6.3715 8.2639 6.56191 8.32044V6.51228C4.15071 6.56601 2.20631 8.44756 2.20631 10.7547C2.20631 11.8707 2.66169 12.8867 3.40417 13.6452C4.12856 14.1291 5.01016 14.4146 5.96077 14.4146C8.42032 14.4146 10.4215 12.5102 10.4215 10.1697V10.17Z"
          fill={theme.tokens.colors.icon['icon-primary']}
        />
      </g>
      <defs>
        <clipPath id="clip0_483_182">
          <rect
            fill="white"
            height="14"
            transform="translate(1.5 1)"
            width="13"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

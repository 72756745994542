import React, { FC } from 'react'

import { InputBetSumSystem } from '../input-bet-sum'
import { CardList } from '../card-list'
import { FooterSystem } from '../footer'

import { SystemProps } from './system.types'

export const System: FC<SystemProps> = ({ outcomes }) => (
  <>
    <CardList outcomeList={outcomes} />

    <FooterSystem InputBetSum={<InputBetSumSystem outcomes={outcomes} />} />
  </>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M14.92 5.66497C14.9422 5.70931 16.6066 9.02313 18.6628 12.2156L17.159 13.7187L8.88025 6.79261C8.98944 6.65279 9.1001 6.51638 9.21222 6.38338C9.39419 5.72628 9.6642 4.19992 9.75926 3.6271C9.78391 3.47871 9.85261 3.34115 9.95645 3.23231C10.0603 3.12347 10.1944 3.04836 10.3415 3.01675C10.4886 2.98514 10.6418 2.99848 10.7812 3.05504C10.9205 3.11161 11.0397 3.20878 11.1231 3.33394L12.4005 5.24993H14.2492C14.3885 5.24998 14.5251 5.28884 14.6435 5.36214C14.762 5.43545 14.8577 5.54031 14.92 5.66497ZM3.02275 20.0681C3.09822 19.766 4.57255 13.9529 7.21549 9.31011L15.0307 15.8484L14.1629 16.7162L12.7505 15.7072V20.2499C12.7505 20.4489 12.6714 20.6396 12.5308 20.7803C12.3901 20.9209 12.1994 20.9999 12.0004 20.9999H3.75035C3.63638 20.9999 3.52391 20.974 3.42148 20.924C3.31905 20.874 3.22936 20.8013 3.15922 20.7115C3.08908 20.6217 3.04033 20.517 3.01669 20.4055C2.99304 20.294 2.99511 20.1786 3.02275 20.0681ZM20.8353 15.281C20.6901 15.0998 20.545 14.913 20.3998 14.7207L16.6368 18.4836L16.8136 18.6099C16.8745 18.6533 16.9415 18.6874 17.0124 18.7111C17.7598 18.9334 18.5648 18.8516 19.2522 18.4833C20.0883 18.024 20.6687 17.1658 20.9772 15.9314C21.0052 15.8194 21.007 15.7024 20.9824 15.5896C20.9578 15.4768 20.9074 15.3712 20.8353 15.281ZM19.4362 12.5027C19.5924 12.3465 19.8457 12.3465 20.0019 12.5027L20.4969 12.9978C20.6531 13.154 20.6531 13.4072 20.4969 13.5634L15.8125 18.2478C15.6563 18.404 15.403 18.404 15.2468 18.2478L14.7518 17.7528C14.5956 17.5966 14.5956 17.3433 14.7518 17.1871L16.1394 15.7996L7.16983 8.29546C7.00039 8.15371 6.97795 7.90144 7.11971 7.732L7.56891 7.19507C7.71066 7.02564 7.96293 7.0032 8.13237 7.14495L17.2042 14.7347L19.4362 12.5027Z"
      fill={theme.tokens.colors['sport-icon']['sport-horseRacing']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M10.5956 2.36896C10.6154 2.40838 12.095 5.35412 13.9227 8.19202L12.586 9.52817L5.22678 3.37135C5.32384 3.24706 5.42221 3.1258 5.52188 3.00758C5.68364 2.42346 5.92365 1.06664 6.00815 0.557448C6.03007 0.425536 6.09114 0.303261 6.18344 0.206506C6.27574 0.109751 6.39501 0.0429878 6.52574 0.0148874C6.65647 -0.013213 6.79264 -0.00135159 6.91654 0.0489309C7.04045 0.0992134 7.14637 0.185593 7.22055 0.296851L8.35603 2.00002H9.99936C10.1232 2.00007 10.2446 2.03461 10.3499 2.09977C10.4552 2.16494 10.5403 2.25815 10.5956 2.36896ZM0.0199001 15.172C0.086987 14.9035 1.39756 9.73602 3.74693 5.60896L10.694 11.421L9.92267 12.1924L8.6671 11.2955V15.3337C8.6671 15.5105 8.59686 15.6801 8.47183 15.8051C8.3468 15.9301 8.17722 16.0004 8.0004 16.0004H0.666684C0.565371 16.0004 0.46539 15.9773 0.374338 15.9328C0.283285 15.8884 0.203556 15.8238 0.141206 15.744C0.078856 15.6641 0.0355256 15.5711 0.0145062 15.472C-0.00651307 15.3729 -0.0046684 15.2703 0.0199001 15.172ZM15.8539 10.9172C15.7249 10.756 15.5958 10.59 15.4668 10.4191L12.1218 13.7641L12.2789 13.8763C12.333 13.9149 12.3926 13.9452 12.4556 13.9662C13.12 14.1639 13.8356 14.0911 14.4466 13.7638C15.1898 13.3555 15.7058 12.5926 15.9801 11.4954C16.005 11.3958 16.0065 11.2918 15.9846 11.1915C15.9628 11.0912 15.918 10.9973 15.8539 10.9172ZM14.6102 8.44726C14.7491 8.3084 14.9742 8.3084 15.1131 8.44726L15.5531 8.88729C15.692 9.02615 15.692 9.25128 15.5531 9.39014L11.389 13.5542C11.2502 13.6931 11.025 13.6931 10.8862 13.5542L10.4462 13.1142C10.3073 12.9753 10.3073 12.7502 10.4462 12.6113L11.6796 11.3779L3.70634 4.7073C3.55573 4.58129 3.53578 4.35704 3.66179 4.20642L4.0611 3.72913C4.18711 3.57852 4.41136 3.55857 4.56197 3.68458L12.6262 10.4313L14.6102 8.44726Z"
      fill={theme.tokens.colors['sport-icon']['sport-horseRacing']}
      fillRule="evenodd"
    />
  )
}

export const IconSportHorseRacing: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

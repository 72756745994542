import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'

import {
  MAP_IDENTIFY_CONSTRAINTS_TO_STORE_PROPS,
  MAP_STATUS_TO_CONSTRAINTS
} from '../store-lib'

import { ProviderReducerPayloads, ProviderState } from './types'

const REDUCER_KEY = 'identificationProvider'

export const initialState: ProviderState = {
  data: {
    constraints: {
      isWithdrawalBlocked: false,
      isBetsBlocked: false,
      isTopUpBlocked: false
    }
  }
}

const stateSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    onChangeConstraints(
      state,
      { payload }: PayloadAction<ProviderReducerPayloads>
    ) {
      const { onChangeConstraints, accountConstraints } = payload

      const identificationStatus = onChangeConstraints

      const constraintBlockedList =
        !!identificationStatus || identificationStatus === 0
          ? MAP_STATUS_TO_CONSTRAINTS[identificationStatus]
          : MAP_STATUS_TO_CONSTRAINTS.empty

      const newConstraints: typeof state.data.constraints = {
        ...initialState.data.constraints
      }

      if (constraintBlockedList.length) {
        constraintBlockedList.forEach((constraint) => {
          newConstraints[MAP_IDENTIFY_CONSTRAINTS_TO_STORE_PROPS[constraint]] =
            true
        })
      }

      if (accountConstraints.isBetsBlocked) {
        newConstraints.isBetsBlocked = true
      }

      if (accountConstraints.isWithdrawalBlocked) {
        newConstraints.isWithdrawalBlocked = true
      }

      state.data.constraints = newConstraints
    }
  }
})

export const {
  actions: constraintProviderActions,
  reducer,
  name: sliceKey
} = stateSlice

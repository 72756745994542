import styled, { css } from 'styled-components'

export const Layout = styled.div`
  height: 100%;
  position: relative;

  ${({ theme }) => css`
    .rcs-positioning {
      z-index: 11;
    }
    .rcs-custom-scroll .rcs-inner-handle {
      background-color: ${theme.tokens.colors.surface['surface-active-4']};
      border-radius: ${theme.tokens.alias.space['space-m-16']}px;
      width: ${theme.tokens.alias.space['space-xxs-6']}px;
    }
  `}
`

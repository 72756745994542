import { useTranslation } from 'react-i18next'

import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { LineSearch } from 'features/line-search'
import { LineTopFilter } from 'features/events-page-header'
import { SelectFilterPeriod } from 'entities/line-tournaments'
import { BasketButtonOpenBasketForSmallWindow } from 'features/basket'

import {
  StyledButtonsWrapper,
  StyledSearchDivider,
  StyledSearchWrapper
} from './line-content.styled'

export const LineContent = () => {
  const { t } = useTranslation()

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  return (
    <StyledButtonsWrapper>
      <StyledSearchWrapper>
        <LineSearch placeholder={t('search in line')} />
      </StyledSearchWrapper>

      <StyledSearchDivider />

      <SelectFilterPeriod />

      <LineTopFilter />

      {(isTabletOrMobile || isSmall) && (
        <BasketButtonOpenBasketForSmallWindow isSmall />
      )}
    </StyledButtonsWrapper>
  )
}

import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'
import {
  GetTopUpFetch,
  GetWithdrawalListFetch,
  topUpApi,
  TopUpMethodCategories
} from 'entities/legacy-api'
import { RootState } from 'shared/types/store'

import {
  BalanceOperationsTypes,
  BalanceOperationsTypesObject,
  PaymentMethods,
  PaymentVariant,
  ProviderReducerPayloads,
  ProviderState
} from './types'

const REDUCER_KEY = 'paymentProvider'

export const initialState: ProviderState = getPreloadedState(REDUCER_KEY, {
  [BalanceOperationsTypes.TOP_UP]: {
    data: {
      paymentMethodsWithVariants: {
        [PaymentMethods.CARD]: {
          title: 'payment.top_up.pay_from_bank_card',
          variantList: []
        },
        [PaymentMethods.SIM]: {
          title: 'payment.top_up.pay_from_sim',
          variantList: []
        },
        [PaymentMethods.CASH]: {
          title: 'payment.top_up.pay_from_cash',
          variantList: []
        }
      }
    },
    layout: {
      activePaymentTabValue: PaymentMethods.CARD,
      activePaymentMethodVariantKey: ''
    }
  },
  [BalanceOperationsTypes.WITHDRAWAL]: {
    data: {
      paymentMethodsWithVariants: {
        [PaymentMethods.CARD]: {
          title: 'payment.withdrawal.pay_to_bank_card',
          variantList: []
        },
        [PaymentMethods.SIM]: {
          title: 'payment.withdrawal.pay_to_sim',
          variantList: []
        },
        [PaymentMethods.CASH]: {
          title: 'payment.withdrawal.pay_to_cash',
          variantList: []
        }
      }
    },
    layout: {
      activePaymentTabValue: PaymentMethods.CARD,
      activePaymentMethodVariantKey: ''
    }
  }
})

const stateSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    reset: (
      state,
      { payload }: PayloadAction<BalanceOperationsTypesObject>
    ) => {
      state[payload.balanceOperationsType].layout =
        initialState[payload.balanceOperationsType].layout
    },
    setActivePaymentMethodVariantKey: (
      state,
      {
        payload
      }: PayloadAction<
        ProviderReducerPayloads['setActivePaymentMethodVariantKey']
      >
    ) => {
      state[
        payload.balanceOperationsType
      ].layout.activePaymentMethodVariantKey = payload.key
    },
    onChangeActivePaymentTab: (
      state,
      {
        payload
      }: PayloadAction<ProviderReducerPayloads['onChangeActivePaymentTab']>
    ) => {
      state[payload.balanceOperationsType].layout.activePaymentTabValue =
        payload.paymentMethod
      state[
        payload.balanceOperationsType
      ].layout.activePaymentMethodVariantKey =
        initialState[
          payload.balanceOperationsType
        ].layout.activePaymentMethodVariantKey
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        topUpApi.endpoints.getTopUpList.matchFulfilled,
        (state, { payload }) => {
          if (payload.data) {
            return setFormattedResponsePaymentsMethodsVariants(
              state as unknown as RootState,
              payload.data as any,
              BalanceOperationsTypes.TOP_UP
            )
          }
        }
      )
      .addMatcher(
        topUpApi.endpoints.getWithdrawalList.matchFulfilled,
        (state, { payload }) => {
          if (payload.data) {
            return setFormattedResponsePaymentsMethodsVariants(
              state as unknown as RootState,
              payload.data as any,
              BalanceOperationsTypes.WITHDRAWAL
            )
          }
        }
      )
  }
})

const setFormattedResponsePaymentsMethodsVariants = (
  state: RootState,
  data: GetTopUpFetch['response']['data'] | GetWithdrawalListFetch['response'],
  balanceOperationType: BalanceOperationsTypes
) => {
  const list: Record<TopUpMethodCategories, Array<PaymentVariant>> = {
    [TopUpMethodCategories.BANK_CARD]: [],
    [TopUpMethodCategories.SIM]: [],
    [TopUpMethodCategories.CASH]: [],
    [TopUpMethodCategories.CASH_2]: []
  }

  Object.entries(data)?.forEach(([key, item]) => {
    list[item.category]?.push({
      min: item.min,
      max: item.max,
      title: item.name,
      limits: item.limits,
      key,
      iconUrl: item.img
    })
  })

  state[balanceOperationType].data.paymentMethodsWithVariants[
    PaymentMethods.CARD
  ].variantList = list[TopUpMethodCategories.BANK_CARD]
  state[balanceOperationType].data.paymentMethodsWithVariants[
    PaymentMethods.SIM
  ].variantList = list[TopUpMethodCategories.SIM]
  state[balanceOperationType].data.paymentMethodsWithVariants[
    PaymentMethods.CASH
  ].variantList = [
    ...list[TopUpMethodCategories.CASH],
    ...list[TopUpMethodCategories.CASH_2]
  ]
}

export const {
  actions: paymentProviderActions,
  reducer,
  name: sliceKey
} = stateSlice

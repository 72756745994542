import styled, { css, CSSProp } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { LayoutTheme } from 'betweb-openapi-axios'
import 'react-toastify/dist/ReactToastify.css'

import { ButtonDeprecated } from 'shared/ui/ButtonDeprecated'
import { typography } from 'shared/lib/theme/typography'

export const StyledButton = styled(ButtonDeprecated)`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[0]};
    line-height: initial;
  `}
`

export const StyledToastContainerDefault = styled(ToastContainer)<{
  containerPositionStyles: CSSProp
}>`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[33]};

    &&&.Toastify__toast-container {
      ${props.containerPositionStyles}
      max-width: 360px;
      width: fit-content;
      z-index: 49;
    }

    .Toastify__toast {
      background-color: ${props.theme.tokens.colors.surface[
        'surface-darkGray-opacity'
      ]};
      margin-bottom: 6px;
      min-height: 44px;
      padding: ${props.theme.tokens.alias.space['space-s-12']}px;
      width: 360px;
      z-index: 49;
      border-radius: ${props.theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;

      & > button {
        ${typography['Body / 14 SemiBold']}
        height: auto;
      }

      ${props.theme.typeLayoutTheme === LayoutTheme.Dark &&
      css`
        box-shadow: 0px 2px 4px 0px #0000001f;
      `}

      ${props.theme.typeLayoutTheme === LayoutTheme.Light &&
      css`
        box-shadow: 0px 2px 4px 0px #6c799314;
      `}
    }

    .Toastify__toast-body {
      ${typography['Body / 14 SemiBold']}
      color: ${props.theme.tokens.colors.text['text-invert']};
      display: flex;
      flex-grow: 1;
      padding: 0;
    }
  `}
`

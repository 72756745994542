import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { EColorsNames } from 'shared/lib/theme'
import { IconProps } from 'shared/ui/Icon'

export const EVENT_STATISTICS_POLLING_INTERVAL = 2000
export const BANNER_TABS_HEIGHT = 28
export const LIVE_COMPACT_CONTENT = 40
export const LINE_COMPACT_CONTENT = 36

export const ICON_ACTION_PROPS: IconProps = {
  size: 16,
  colorProps: { name: EColorsNames.Primary, value: 70 }
}

export const SIZE_LONG_NAME = 16

export interface SportStatistics {
  field: string
  text: string
}

interface SportStatisticsMap {
  [key: string]: SportStatistics[]
}

export const sportStatisticsMap: SportStatisticsMap = {
  [ESportsCodes.FOOTBALL]: [
    {
      field: 'corners',
      text: 'corners short'
    },
    {
      field: 'yellowCards',
      text: 'yellow card short'
    },
    {
      field: 'shotsOnTarget',
      text: 'woodwork short'
    },
    {
      field: 'fouls',
      text: 'free-kicks short'
    },
    {
      field: 'offsides',
      text: 'offside short'
    }
  ],
  [ESportsCodes.TENNIS]: [
    {
      field: 'aces',
      text: 'aces'
    },
    {
      field: 'doubleFaults',
      text: 'double faults short'
    }
  ],
  [ESportsCodes.BASKETBALL]: [
    {
      field: 'fouls',
      text: 'free-kicks short'
    }
  ]
}

import { useLocation } from 'react-router-dom'
import { Event } from 'betweb-openapi-axios'

import { ERoutes } from 'shared/types/routes'

import { getLinkEventPage } from '../get-link-event-page'

export const useOpenEventPage = ({ event }: { event: Event }) => {
  const location = useLocation()

  const link = getLinkEventPage({
    eventId: event.id,
    isEventLive: event.live,
    tournamentSportId: event.tournament.sportId,
    tournamentId: event.tournament.id
  })

  const pathReturnMap = {
    [ERoutes.Calendar]: {
      pathname: link,
      state: { prevPath: location.pathname }
    },
    default: link
  }

  return pathReturnMap[location.pathname] || pathReturnMap.default
}

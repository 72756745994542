import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledSetsGrid = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto auto;
`

export const StyledSetsContainer = styled.div`
  display: flex;
  gap: 4px;
`

export const StyledSetsWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: min-content auto min-content;
`

export const StyledSetsSide = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: 4px;
  grid-template-columns: minmax(32px, auto);
  grid-template-rows: max-content max-content;
`

export const StyledServes = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: 4px;
  grid-template-columns: 8px;
  grid-template-rows: 24px 32px 32px;
`

export const StyledSetsCenter = styled.div<{ columnsCount: number }>`
  align-items: center;
  display: grid;
  grid-column-gap: 1px;
  grid-row-gap: 4px;
  grid-template-rows: auto auto;

  ${({ columnsCount }) => css`
    grid-template-columns: repeat(${columnsCount}, minmax(32px, auto));

    & > div:nth-child(${columnsCount + 1}) {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    & > div:nth-child(${columnsCount * 2}) {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    & > div:nth-child(${columnsCount * 2 + 1}) {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    & > div:nth-child(${columnsCount * 3}) {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  `}
`

export const StyledSetsMainList = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 1px;
  overflow: hidden;
`

export const StyledSetsTopCell = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  font-weight: 800;
  height: 24px;
  justify-content: center;
  line-height: 14px;
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite-opacity50']};
  `}
`

export const StyledSetsCell = styled.div<{
  extraBold?: boolean
  extra?: boolean
}>`
  ${typography['Body / 12 Medium']}
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  min-width: 32px;
  text-transform: uppercase;

  ${({ theme, extraBold, extra }) => css`
    background: ${theme.tokens.colors.surface['surface-alwaysWhite-opacity']};
    color: ${theme.tokens.colors.text['text-alwaysWhite-opacity50']};
    padding: 0 ${theme.tokens.alias.space['space-xxxs-4']}px;
    position: relative;

    ${extra &&
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      color: ${theme.tokens.colors.text['text-alwaysWhite']};
      outline: 1px solid rgba(255, 255, 255, 0.2);
      outline-offset: -1px;
    `}

    ${extraBold &&
    css`
      ${typography['Body / 12 Bold']}
      background: ${theme.tokens.colors.surface['surface-alwaysWhite']};
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      color: ${theme.tokens.colors.text['text-alwaysDark']};
    `}
  `}
`

export const StyledServe = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    border-radius: 50%;
    content: '';
    height: 4px;
    margin-left: 2px;
    width: 4px;

    ${isActive &&
    css`
      background: ${theme.tokens.colors.text['text-alwaysWhite']};
    `}
  `}
`

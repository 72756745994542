import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M18.7199 21.9993H5.28014C3.72201 21.9993 2.3333 21.1633 1.56535 19.7634C0.781704 18.3355 0.814411 16.6507 1.653 15.2568L8.37286 4.08381C9.15716 2.77867 10.5132 2 12 2C13.4868 2 14.8428 2.77867 15.6271 4.08381L22.347 15.2568C23.1856 16.6507 23.2183 18.3355 22.4347 19.7641C21.6667 21.1639 20.278 22 18.7199 22V21.9993ZM9.26509 4.64229L2.54523 15.8153C1.90746 16.8749 1.88326 18.1567 2.47917 19.2423C3.05938 20.3005 4.10729 20.9317 5.2808 20.9317H18.7199C19.8934 20.9317 20.9406 20.2999 21.5215 19.2423C22.1174 18.156 22.0925 16.8749 21.4554 15.8153L14.7356 4.64229C14.1429 3.6561 13.1199 3.06759 12 3.06759C10.8801 3.06759 9.85773 3.6561 9.26444 4.64229H9.26509Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
      <path
        d="M11.9712 3.8516C10.4962 3.8516 9.30041 5.07133 9.30041 6.57597C9.30041 8.0806 10.4962 9.30033 11.9712 9.30033C13.4463 9.30033 14.642 8.0806 14.642 6.57597C14.642 5.07133 13.4463 3.8516 11.9712 3.8516ZM13.5078 7.02435C13.2651 7.02435 13.0682 6.82351 13.0682 6.57597C13.0682 5.95876 12.5763 5.457 11.9712 5.457C11.7285 5.457 11.5316 5.25616 11.5316 5.00861C11.5316 4.76106 11.7285 4.56022 11.9712 4.56022C13.061 4.56022 13.948 5.46434 13.948 6.57663C13.948 6.82418 13.7511 7.02502 13.5084 7.02502L13.5078 7.02435Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
      <path
        d="M11.9712 14.6516C10.4962 14.6516 9.30041 15.8714 9.30041 17.376C9.30041 18.8806 10.4962 20.1004 11.9712 20.1004C13.4463 20.1004 14.642 18.8806 14.642 17.376C14.642 15.8714 13.4463 14.6516 11.9712 14.6516ZM13.5078 17.8244C13.2651 17.8244 13.0682 17.6235 13.0682 17.376C13.0682 16.7588 12.5763 16.257 11.9712 16.257C11.7285 16.257 11.5316 16.0562 11.5316 15.8086C11.5316 15.5611 11.7285 15.3602 11.9712 15.3602C13.061 15.3602 13.948 16.2644 13.948 17.3767C13.948 17.6242 13.7511 17.825 13.5084 17.825L13.5078 17.8244Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
      <path
        d="M8.66395 9.25162C7.18889 9.25162 5.99314 10.4713 5.99314 11.976C5.99314 13.4806 7.18889 14.7003 8.66395 14.7003C10.139 14.7003 11.3348 13.4806 11.3348 11.976C11.3348 10.4713 10.139 9.25162 8.66395 9.25162ZM10.2005 12.4244C9.95781 12.4244 9.76092 12.2235 9.76092 11.976C9.76092 11.3588 9.26902 10.857 8.66395 10.857C8.42127 10.857 8.22437 10.6562 8.22437 10.4086C8.22437 10.1611 8.42127 9.96023 8.66395 9.96023C9.75373 9.96023 10.6407 10.8643 10.6407 11.9766C10.6407 12.2242 10.4438 12.425 10.2011 12.425L10.2005 12.4244Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
      <path
        d="M15.2785 9.25162C13.8034 9.25162 12.6077 10.4713 12.6077 11.976C12.6077 13.4806 13.8034 14.7003 15.2785 14.7003C16.7535 14.7003 17.9493 13.4806 17.9493 11.976C17.9493 10.4713 16.7535 9.25162 15.2785 9.25162ZM16.815 12.4244C16.5724 12.4244 16.3755 12.2235 16.3755 11.976C16.3755 11.3588 15.8836 10.857 15.2785 10.857C15.0358 10.857 14.8389 10.6562 14.8389 10.4086C14.8389 10.1611 15.0358 9.96023 15.2785 9.96023C16.3683 9.96023 17.2553 10.8643 17.2553 11.9766C17.2553 12.2242 17.0584 12.425 16.8157 12.425L16.815 12.4244Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
      <path
        d="M18.434 14.6997C16.9589 14.6997 15.7632 15.9194 15.7632 17.424C15.7632 18.9287 16.9589 20.1484 18.434 20.1484C19.9091 20.1484 21.1048 18.9287 21.1048 17.424C21.1048 15.9194 19.9091 14.6997 18.434 14.6997ZM19.9706 17.8724C19.7279 17.8724 19.531 17.6716 19.531 17.424C19.531 16.8068 19.0391 16.3051 18.434 16.3051C18.1913 16.3051 17.9944 16.1042 17.9944 15.8567C17.9944 15.6091 18.1913 15.4083 18.434 15.4083C19.5238 15.4083 20.4108 16.3124 20.4108 17.4247C20.4108 17.6722 20.2139 17.8731 19.9712 17.8731L19.9706 17.8724Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
      <path
        d="M5.50843 14.6997C4.03337 14.6997 2.83763 15.9194 2.83763 17.424C2.83763 18.9287 4.03337 20.1484 5.50843 20.1484C6.98349 20.1484 8.17924 18.9287 8.17924 17.424C8.17924 15.9194 6.98349 14.6997 5.50843 14.6997ZM7.04498 17.8724C6.8023 17.8724 6.60541 17.6716 6.60541 17.424C6.60541 16.8068 6.1135 16.3051 5.50843 16.3051C5.26575 16.3051 5.06886 16.1042 5.06886 15.8567C5.06886 15.6091 5.26575 15.4083 5.50843 15.4083C6.59821 15.4083 7.48521 16.3124 7.48521 17.4247C7.48521 17.6722 7.28832 17.8731 7.04563 17.8731L7.04498 17.8724Z"
        fill={theme.tokens.colors['sport-icon']['sport-billiards']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8269 15H3.17314C2.00899 15 0.974793 14.3967 0.407555 13.3866C-0.162057 12.3723 -0.132983 11.1775 0.48469 10.1914L5.31155 2.48408C5.89362 1.55506 6.89875 1 8 1C9.10125 1 10.1064 1.55506 10.6884 2.48408L15.5153 10.1914C16.133 11.1775 16.1621 12.3723 15.5924 13.3866C15.0252 14.3967 13.9916 15 12.8269 15ZM6.11791 2.98034L1.29105 10.6871C0.857904 11.3792 0.83773 12.2171 1.23705 12.9291C1.63459 13.637 2.35788 14.0592 3.17314 14.0592H12.8269C13.6415 14.0592 14.3654 13.6365 14.7629 12.9291C15.1623 12.2171 15.1421 11.3792 14.709 10.6871L9.88209 2.98034C9.47446 2.32944 8.77076 1.94078 8 1.94078C7.22925 1.94078 6.52554 2.32944 6.11791 2.98034Z"
          fill={theme.tokens.colors['sport-icon']['sport-billiards']}
        />
        <path
          d="M8 3.51541C6.54097 3.51541 5.35843 4.68727 5.35843 6.13314C5.35843 7.579 6.54097 8.75086 8 8.75086C9.45904 8.75086 10.6416 7.579 10.6416 6.13314C10.6416 4.68727 9.45904 3.51541 8 3.51541ZM9.4353 6.50239C9.22941 6.50239 9.06268 6.33717 9.06268 6.13314C9.06268 5.55279 8.58623 5.08064 8.00059 5.08064C7.7947 5.08064 7.62797 4.91541 7.62797 4.71138C7.62797 4.50735 7.7947 4.34213 8.00059 4.34213C8.99741 4.34213 9.80852 5.14591 9.80852 6.13373C9.80852 6.33776 9.64179 6.50298 9.4359 6.50298L9.4353 6.50239Z"
          fill={theme.tokens.colors['sport-icon']['sport-billiards']}
        />
        <path
          d="M5.09498 8.48156C3.63595 8.48156 2.45341 9.65342 2.45341 11.0993C2.45341 12.5451 3.63595 13.717 5.09498 13.717C6.55402 13.717 7.73656 12.5451 7.73656 11.0993C7.73656 9.65342 6.55402 8.48156 5.09498 8.48156ZM6.53028 11.4685C6.32439 11.4685 6.15766 11.3033 6.15766 11.0993C6.15766 10.5189 5.68121 10.0468 5.09558 10.0468C4.88968 10.0468 4.72295 9.88156 4.72295 9.67753C4.72295 9.4735 4.88968 9.30827 5.09558 9.30827C6.0924 9.30827 6.9035 10.1121 6.9035 11.0999C6.9035 11.3039 6.73677 11.4691 6.53088 11.4691L6.53028 11.4685Z"
          fill={theme.tokens.colors['sport-icon']['sport-billiards']}
        />
        <path
          d="M10.905 8.48156C9.44598 8.48156 8.26345 9.65342 8.26345 11.0993C8.26345 12.5451 9.44598 13.717 10.905 13.717C12.3641 13.717 13.5466 12.5451 13.5466 11.0993C13.5466 9.65342 12.3641 8.48156 10.905 8.48156ZM12.3403 11.4685C12.1344 11.4685 11.9677 11.3033 11.9677 11.0993C11.9677 10.5189 11.4912 10.0468 10.9056 10.0468C10.6997 10.0468 10.533 9.88156 10.533 9.67753C10.533 9.4735 10.6997 9.30827 10.9056 9.30827C11.9024 9.30827 12.7135 10.1121 12.7135 11.0999C12.7135 11.3039 12.5468 11.4691 12.3409 11.4691L12.3403 11.4685Z"
          fill={theme.tokens.colors['sport-icon']['sport-billiards']}
        />
      </svg>
    </>
  )
}

export const IconSportBilliards: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

export const StyledSuccessSignUpComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
  `}
`

export const StyledSuccessSignUp = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const SuccessSignUpText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

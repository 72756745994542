import styled, { css } from 'styled-components'

import { OddModes } from 'entities/event-probability/model/odd-mode'
import { typography } from 'shared/lib/theme/typography'
import { IconCoefficients } from 'shared/ui/Icon/General/IconCoefficients'

export const CoefficientCouponWrapper = styled.div<{
  mode: OddModes
}>`
  ${typography['Body / 14 Bold']};
  align-items: center;
  display: flex;
  justify-content: center;
  ${({ theme, mode }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;

    ${mode === OddModes.Increase &&
    css`
      && {
        color: ${theme.tokens.colors.text['text-green']};
      }
    `}
    ${mode === OddModes.Decrease &&
    css`
      && {
        color: ${theme.tokens.colors.text['text-error']};
      }
    `}
    ${mode === OddModes.Default &&
    css`
      && {
        color: ${theme.tokens.colors.text['text-primary']};
      }
    `}
  `}
`

export const OutcomeCode = styled.div``
export const ProbabilityOdd = styled.div``

export const IncreaseIcon = styled(IconCoefficients)`
  transform: rotate(-45deg);
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-positive']};
  `}
`

export const DecreaseIcon = styled(IconCoefficients)`
  transform: rotate(135deg);
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-negative']};
  `}
`

import styled, { css } from 'styled-components'

import { AlertProps, EAlertViev } from './alert'

export const AlertIconWrapper = styled.div`
  display: flex;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const AlertWrapper = styled.div<Omit<AlertProps, 'text'>>`
  display: grid;
  grid-template-columns: auto 1fr;
  ${({ theme, view, size }) => css`
    ${view === EAlertViev.WARNING &&
    css`
      background: ${theme.tokens.colors.surface['surface-warning']};
      box-shadow: inset 0 0 0 1px
        ${theme.tokens.colors.border['border-warning-opacity']};
    `}

    ${view === EAlertViev.INFO &&
    css`
      background: #e7f2fe;
      box-shadow: inset 0 0 0 1px #9eccfa;

      & svg {
        color: ${theme.tokens.colors.text['text-linkAccent-default']};
      }
    `}

    ${size === 'm' &&
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      gap: ${theme.tokens.alias.space['space-xs-8']}px;
      padding: ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-s-12']}px;
    `}

    ${size === 's' &&
    css`
      border-radius: 6px;
      gap: 4px;
      padding: 4px;
    `}
  `}
`

import { EntityTag, Event } from 'betweb-openapi-axios'

import { isEventTop } from '../is-event-top'
import { isEventSuperTop } from '../is-event-super-top'

export const isEventTopOrSuperTop = ({
  eventTags,
  allEntityTags
}: {
  eventTags: Event['tags']
  allEntityTags: Dictionary<EntityTag>
}) =>
  isEventTop({ eventTags, allEntityTags }) ||
  isEventSuperTop({ eventTags, allEntityTags })

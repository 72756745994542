import {
  selectBetsHistoryDataBetsHasMore,
  selectBetsHistoryPagination,
  selectBetsHistoryDataBetsFetchItems,
  betsHistoryProviderActions,
  SetDatePickerRangeValuesPayload
} from 'astra-core/containers/BetsHistoryProvider'

import { FooterOfPage } from 'widgets/footer-of-page'
import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS = {
  setPaginationAction: betsHistoryProviderActions.setPagination,
  selectorHasMore: selectBetsHistoryDataBetsHasMore,
  selectorPagination: selectBetsHistoryPagination,
  selectorRequestStatus: selectBetsHistoryDataBetsFetchItems,
  bottomComponent: <FooterOfPage />
} as DataListWithReduxPaginationProps['pagination']

export const getIsResetPagination = (
  dateList: SetDatePickerRangeValuesPayload
) => {
  // need to reset filter when both values are null
  // need to reset filter when both values are not null
  const bothNull = !dateList[0] && !dateList[1]
  const bothNotNull = dateList[0] && dateList[1]

  return bothNull || bothNotNull
}

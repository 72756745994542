import { FC, useCallback, useRef } from 'react'
import { formatAmount, useGetCurrencyIcon } from 'astra-core'

import { ModalRef } from 'shared/ui/modal'
import { BasketBonusCouponCanNotUse } from 'entities/basket-bonus-coupon'

import { BonusCouponAvailableProps } from './bonus-coupon-can-not-use.types'

export const BonusCouponCanNotUse: FC<BonusCouponAvailableProps> = ({
  possibleAmount,
  coupon: { amount, description }
}) => {
  const currencyIcon = useGetCurrencyIcon()
  const modalRef = useRef<ModalRef>(null)

  const couponValue = formatAmount({
    value: possibleAmount ?? amount,
    currency: currencyIcon
  })

  const handleOpenModal = useCallback(() => {
    modalRef.current?.open()
  }, [])

  return (
    <BasketBonusCouponCanNotUse
      description={description || ''}
      modalRef={modalRef}
      value={couponValue}
      onOpenModal={handleOpenModal}
    />
  )
}

import { useEffect, useState } from 'react'

import { usePrevious } from 'shared/lib/use-previous/usePrevious'

export const useCompleted = (loading: boolean) => {
  const [completed, setCompleted] = useState(false)
  const prevLoading = usePrevious(loading)

  useEffect(() => {
    if (prevLoading !== loading) {
      setCompleted(!!prevLoading && !loading)
    }
  }, [loading, prevLoading])

  return completed
}

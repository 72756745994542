import styled, { css } from 'styled-components'

import { IconCoefficients } from 'shared/ui/Icon/General/IconCoefficients'

export const IncreaseIcon = styled(IconCoefficients)`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-positive']};
    position: absolute;
    right: 0;
    top: 0;
  `}
`

export const DecreaseIcon = styled(IconCoefficients)`
  ${({ theme }) => css`
    bottom: 0;
    color: ${theme.tokens.colors.icon['icon-negative']};
    position: absolute;
    right: 0;
    transform: rotate(90deg);
  `}
`

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledNavSpace = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
export const StyledNavWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledBetsWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    outline: 1px solid ${theme.tokens.colors.border['border-primary']};
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const StyledBetTypeItemWrapper = styled.button<{
  $isActive: boolean
  $isDisabled: boolean
}>`
  ${({ theme, $isActive, $isDisabled }) => css`
    align-items: center;
    background: ${$isActive
      ? `${theme.tokens.colors.surface['surface-active2']}`
      : 'transparent'};
    border: none;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    transition: all 0.3s;
    width: 80px;

    ${$isDisabled &&
    css`
      cursor: default;

      &:hover {
        background: ${theme.tokens.colors.surface['surface-hover']};
      }

      & ${StyledBetTypeItemText} {
        color: ${theme.tokens.colors.text['text-disabled']};
      }
    `}
  `}
`

export const StyledBetTypeItemText = styled.div<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    ${typography['Hint / 11 BOLD']};
    color: ${$isActive
      ? theme.tokens.colors.text['text-invert']
      : theme.tokens.colors.text['text-primary']};
    line-height: 16px;
    text-align: center;
  `}
`

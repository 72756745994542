import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1ZM4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.9999 17.8488C15.2301 17.8488 17.8487 15.2302 17.8487 12C17.8487 8.7698 15.2301 6.1512 11.9999 6.1512C8.76972 6.1512 6.15112 8.7698 6.15112 12C6.15112 15.2302 8.76972 17.8488 11.9999 17.8488ZM11.9999 18.8488C15.7824 18.8488 18.8487 15.7825 18.8487 12C18.8487 8.21751 15.7824 5.1512 11.9999 5.1512C8.21744 5.1512 5.15112 8.21751 5.15112 12C5.15112 15.7825 8.21744 18.8488 11.9999 18.8488Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M24 12.8076H0.015625V11.8076H24V12.8076Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.8755 4.37113e-08L12.8755 24H11.8755L11.8755 0L12.8755 4.37113e-08Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M13.3333 0.666667H2.66667C1.5621 0.666667 0.666667 1.5621 0.666667 2.66667V13.3333C0.666667 14.4379 1.5621 15.3333 2.66667 15.3333H13.3333C14.4379 15.3333 15.3333 14.4379 15.3333 13.3333V2.66667C15.3333 1.5621 14.4379 0.666667 13.3333 0.666667ZM2.66667 0C1.19391 0 0 1.19391 0 2.66667V13.3333C0 14.8061 1.19391 16 2.66667 16H13.3333C14.8061 16 16 14.8061 16 13.3333V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 15.3333C12.0501 15.3333 15.3333 12.0501 15.3333 8C15.3333 3.94991 12.0501 0.666667 8 0.666667C3.94991 0.666667 0.666667 3.94991 0.666667 8C0.666667 12.0501 3.94991 15.3333 8 15.3333ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.99995 11.8992C10.1534 11.8992 11.8992 10.1535 11.8992 8C11.8992 5.84653 10.1534 4.1008 7.99995 4.1008C5.84648 4.1008 4.10075 5.84653 4.10075 8C4.10075 10.1535 5.84648 11.8992 7.99995 11.8992ZM7.99995 12.5659C10.5216 12.5659 12.5658 10.5217 12.5658 8C12.5658 5.47834 10.5216 3.43413 7.99995 3.43413C5.47829 3.43413 3.43408 5.47834 3.43408 8C3.43408 10.5217 5.47829 12.5659 7.99995 12.5659Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 8.53839H0.0104167V7.87172H16V8.53839Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.58366 2.91409e-08L8.58366 16H7.91699L7.91699 0L8.58366 2.91409e-08Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
    </>
  )
}

export const IconSportTemplate: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { Typography } from 'shared/ui/typography'
import { Switch } from 'shared/ui/Switch'
import { ESwitchView } from 'shared/ui/Switch/types'

import { BonusInfoProps } from './bonus.types'
import * as S from './bonus.styled'

export const Bonus: FC<BonusInfoProps> = ({
  onChange,
  bonusAmountValue,
  isActiveSwitchChange
}) => {
  const { t } = useTranslation()

  return (
    <S.StyledBasketBonusWrapper>
      <S.StyledGapRow>
        <IconBonus />

        <Typography color="text-primary-2" font="Body / 12 Medium">
          {t('use bonuses')}
        </Typography>

        <Typography color="text-primary-2" font="Body / 12 Bold">
          {bonusAmountValue}
        </Typography>
      </S.StyledGapRow>

      <Switch
        active={isActiveSwitchChange}
        view={ESwitchView.BONUS}
        onClick={onChange}
      />
    </S.StyledBasketBonusWrapper>
  )
}

import { useTranslation } from 'react-i18next'
import {
  EBetTypes,
  selectExpressRate
} from 'astra-core/containers/BasketProvider'
import { FC, memo } from 'react'

import { separateNumber } from 'shared/lib/format/separate-number'
import { TagMaxTotalOddsSize } from 'features/basket/ui/express/tag-max-total-odds-size'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { BasketFooter } from '../../footer'
import { InputBetSumExpress } from '../input-bet-sum'

import * as S from './footer.styled'

export const FooterExpress: FC = memo(() => {
  const [t] = useTranslation()
  const expressRate = useAppSelector(selectExpressRate)

  return (
    <BasketFooter betType={EBetTypes.Express}>
      <S.TotalAmount>
        <S.TotalAmountText>{t('overall coefficient')}</S.TotalAmountText>

        <S.TotalAmountValueWrapper>
          <S.TotalAmountValue>
            {separateNumber(expressRate.toFixed(2))}
          </S.TotalAmountValue>

          <TagMaxTotalOddsSize />
        </S.TotalAmountValueWrapper>
      </S.TotalAmount>

      <InputBetSumExpress />
    </BasketFooter>
  )
})

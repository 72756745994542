import { FC, useMemo } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { getBetOutcomeId } from 'astra-core'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'shared/ui/Tooltip'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { OutcomeStatusIcon } from 'features/bets-history-outcomes-table/outcome-status-icon'
import { getBetEventStatusFromLegacy } from 'entities/legacy-api/lib/utils'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { LEGACY_SPORT_ID_CODE_MAP } from 'entities/legacy-api'

import { BasicStatisticsLegacy } from './basic-statistics-legacy'
import { OUTCOME_EVENT_DATE_FORMAT } from './outcomes-table-legacy.constants'
import {
  OverflowableText,
  EventNameWrapper,
  StyledBetContentItem,
  StyledCellOutcome,
  TableHeadCell,
  HeaderTr,
  TableTBody
} from './outcomes-table-legacy.styled'
import { IOutcomeItem, IOutcomes } from './outcomes-table-legacy.types'

const DATE_COL_WIDTH = 116
const COEFF_COL_WIDTH = 54

const COEFF_ALIGN = 'end'

export const BetsHistoryHeader = () => {
  const { t } = useTranslation()

  return (
    <thead>
      <HeaderTr onClick={(e) => e.stopPropagation()}>
        <TableHeadCell colWidth={`${DATE_COL_WIDTH}px`}>
          {t('event beginning')}
        </TableHeadCell>
        <TableHeadCell>{t('event')}</TableHeadCell>
        <TableHeadCell>{t('outcome')}</TableHeadCell>
        <TableHeadCell
          colWidth={`${COEFF_COL_WIDTH}px`}
          textAlign={COEFF_ALIGN}
        >
          {t('coeff')}
        </TableHeadCell>
        <TableHeadCell colWidth="30%" largeColWidth="32%">
          {t('event result')}
        </TableHeadCell>
      </HeaderTr>
    </thead>
  )
}

export const BetsHistoryOutcomesTable: FC<IOutcomes> = ({ outcomes }) => {
  return (
    <TableTBody onClick={(e) => e.stopPropagation()}>
      {outcomes.map((outcome, index) => {
        const { matchid, eventid, matchname } = outcome

        const eventProbability = {
          eventId: matchid,
          outcomeTypeId: eventid
        }

        return (
          <OutcomeItem
            isLastItem={index === outcomes.length - 1}
            key={getBetOutcomeId(eventProbability) + matchname}
            outcome={outcome}
          />
        )
      })}
    </TableTBody>
  )
}

export const OutcomeItem: FC<IOutcomeItem> = ({ outcome, isLastItem }) => {
  const [eventNameRef, isEventNameOverflowing] =
    useTextOverflow<HTMLSpanElement>()
  const [outcomeNameRef, isOutcomeNameOverflowing] =
    useTextOverflow<HTMLSpanElement>()

  const date = useMemo(
    () => dayjs.unix(outcome.mdttm).format(OUTCOME_EVENT_DATE_FORMAT),
    [outcome.mdttm]
  )

  const showSportIcon = outcome.sport_id !== 99

  return (
    <StyledBetContentItem isLastItem={isLastItem}>
      <StyledCellOutcome fitContent>{date}</StyledCellOutcome>

      <StyledCellOutcome>
        {outcome.matchname && (
          <Tooltip isCommon={!isEventNameOverflowing} title={outcome.matchname}>
            <EventNameWrapper>
              {showSportIcon && (
                <SportIcon
                  numericSize={12}
                  size="s"
                  sport={LEGACY_SPORT_ID_CODE_MAP[outcome.sport_id]}
                />
              )}
              <OverflowableText ref={eventNameRef}>
                {outcome.matchname}
              </OverflowableText>
            </EventNameWrapper>
          </Tooltip>
        )}
      </StyledCellOutcome>

      <StyledCellOutcome>
        <Tooltip isCommon={!isOutcomeNameOverflowing} title={outcome.eventname}>
          <EventNameWrapper>
            <OutcomeStatusIcon status={getBetEventStatusFromLegacy(outcome)} />
            <OverflowableText ref={outcomeNameRef}>
              {outcome.eventname}
            </OverflowableText>
          </EventNameWrapper>
        </Tooltip>
      </StyledCellOutcome>

      <StyledCellOutcome textAlign={COEFF_ALIGN} fitContent>
        {outcome.value}
      </StyledCellOutcome>

      <StyledCellOutcome>
        <BasicStatisticsLegacy outcome={outcome} />
      </StyledCellOutcome>
    </StyledBetContentItem>
  )
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import {
  SegmentedControlItemSize,
  SegmentedControlView
} from 'shared/ui/segmented-control/segmented-control.types'

export const StyledSegmentedControl = styled.div<{
  fullWidth?: boolean
  equalItems?: boolean
  view: SegmentedControlView
}>`
  ${({ theme: { tokens }, fullWidth, equalItems, view }) => css`
    background: ${tokens.colors.surface['surface-default']};
    border-radius: ${tokens.alias['border-radius']['border-radius-m-8']}px;
    display: grid;
    grid-auto-flow: column;
    padding: ${tokens.alias.space['space-xxxxs-2']}px;
    ${fullWidth &&
    css`
      width: 100%;
    `}
    ${equalItems &&
    css`
      grid-auto-columns: 1fr;
    `}
    ${view === 'primary' &&
    css`
      box-shadow: inset 0 0 0 1px ${tokens.colors.border['border-primary']};
    `}
  `}
`

export const StyledSegmentedControlItem = styled.div<{
  isActive: boolean
  itemSize: SegmentedControlItemSize
  view: SegmentedControlView
  disabled?: boolean
}>`
  ${({ theme: { tokens }, isActive, itemSize, view, disabled }) => css`
    align-items: center;
    background: transparent;
    border-radius: ${tokens.alias['border-radius']['border-radius-s-6']}px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

    ${itemSize === 28 &&
    css`
      ${typography['Hint / 11 BOLD']};
      line-height: 16px;
      padding: ${tokens.alias.space['space-xxs-6']}px
        ${tokens.alias.space['space-s-12']}px;
    `}

    ${itemSize === 24 &&
    css`
      ${typography['Hint / 10 BOLD']};
      padding: ${tokens.alias.space['space-xxxs-4']}px
        ${tokens.alias.space['space-s-12']}px;
    `}
    ${itemSize === 68 &&
    css`
      ${typography['Hint / 10 EXTRABOLD']};
      flex-direction: column;
      gap: ${tokens.alias.space['space-xxxs-4']}px;
      padding: ${tokens.alias.space['space-s-12']}px;
    `}
    
    ${view === 'primary' &&
    css`
      color: ${tokens.colors.text['text-primary']};
      &:hover {
        background: ${tokens.colors.surface['surface-hover']};
      }
      ${isActive &&
      css`
        background: ${tokens.colors.surface['surface-active2']};
        color: ${tokens.colors.text['text-invert']};

        &:hover {
          background: ${tokens.colors.surface['surface-active2']};
        }
      `}
    `}

    ${view === 'secondary' &&
    css`
      ${typography['Hint / 10 BOLD']};
      color: ${tokens.colors.text['text-secondary-3']};
      &:hover {
        background: ${tokens.colors.surface['surface-hover']};
        color: ${tokens.colors.text['text-secondary']};
      }
      ${isActive &&
      css`
        background: ${tokens.colors.surface['surface-base']};
        color: ${tokens.colors.text['text-primary']};

        &:hover {
          background: ${tokens.colors.surface['surface-base']};
          color: ${tokens.colors.text['text-primary']};
        }
      `}
    `}
    
    ${disabled &&
    css`
      background-color: transparent;
      color: ${tokens.colors.text['text-disabled']};
      &:hover {
        background-color: transparent;
        color: ${tokens.colors.text['text-disabled']};
      }
    `}
  `}
`

import styled, { css } from 'styled-components'

import { Alert } from 'shared/ui/alert'
import { typography } from 'shared/lib/theme/typography'

export const AlertPassIdentification = styled(Alert)``

export const Link = styled.span`
  ${({ theme }) => css`
    ${typography['Body / 14 Medium']};
    color: ${theme.tokens.colors.text['text-linkAccent-default']};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `}
`

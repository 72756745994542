import { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconPolygonBgLeftPart: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="20"
        viewBox="0 0 4 20"
        width="4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 3.82843C0 3.29799 0.210714 2.78929 0.585786 2.41421L2.41421 0.585787C2.78929 0.210714 3.29799 0 3.82843 0H4V20H3.82843C3.29799 20 2.78929 19.7893 2.41421 19.4142L0.585787 17.5858C0.210714 17.2107 0 16.702 0 16.1716V3.82843Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}

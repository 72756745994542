import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { IconWithAdditionalCssProps } from '../types'
import { Icon } from '../Icon'

const StyledIconStar = styled(Icon)<IconWithAdditionalCssProps>`
  ${(props) => css`
    ${props.withHover &&
    css`
      &:hover {
        color: ${props.theme.colors.default.yellow[30]} !important;
      }
    `}

    ${props.withActive &&
    css`
      &:active {
        color: ${props.theme.colors.default.yellow[50]} !important;
      }
    `}

    ${props.$isActiveState &&
    css`
      color: ${props.theme.colors.default.yellow[50]} !important;
    `}
  `}
`

export const IconStar: FC<IconWithAdditionalCssProps> = (props) => (
  <StyledIconStar {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.84455 9.32964C3.97589 8.90712 4.3526 8.62105 4.77764 8.62105H8.50219C8.92723 8.62105 9.30394 8.33498 9.43528 7.91245L10.5867 4.20859C10.718 3.78607 11.0947 3.5 11.5197 3.5H12.4783C12.9033 3.5 13.28 3.78607 13.4114 4.20859L14.5627 7.91245C14.6941 8.33498 15.0708 8.62105 15.4958 8.62105H19.2228C19.6479 8.62105 20.0246 8.90712 20.1559 9.32964L20.4519 10.2818C20.5833 10.7044 20.4394 11.1672 20.0955 11.4284L17.0803 13.7181C16.7364 13.9793 16.5925 14.4421 16.7239 14.8647L17.8754 18.5689C18.0067 18.9914 17.8628 19.4543 17.519 19.7154L16.7437 20.3041C16.3998 20.5653 15.9342 20.5653 15.5903 20.3041L12.5757 18.0148C12.2318 17.7537 11.7662 17.7537 11.4223 18.0148L8.40893 20.3032C8.06506 20.5644 7.59943 20.5644 7.25556 20.3032L6.47952 19.7139C6.13566 19.4528 5.99177 18.9899 6.12311 18.5674L7.27413 14.8647C7.40548 14.4421 7.26159 13.9793 6.91772 13.7181L3.90449 11.4299C3.56063 11.1687 3.41674 10.7059 3.54809 10.2833L3.84455 9.32964Z"
        fill="currentColor"
      />
    </svg>
  </StyledIconStar>
)

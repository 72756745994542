import React, { FC } from 'react'

import { TextInput } from 'shared/ui/text-input'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { IconSearch } from 'shared/ui/Icon/General/icon-search'

import { InputSearchMarketProps } from './input-search-markets.types'
import * as S from './input-search-markets.styled'

export const InputSearchMarkets: FC<InputSearchMarketProps> = ({
  isOpen,
  value,
  onBlur,
  onChange,
  onOpen,
  onClose,
  inputRef
}) =>
  isOpen ? (
    <S.InputSearchMarkets>
      <TextInput
        endAdornment={
          <IconCloseOutline16
            colorToken="icon-secondary-2"
            size={16}
            onClick={onClose}
          />
        }
        background="white"
        ref={inputRef}
        size="s"
        value={value}
        autoFocus
        onBlur={onBlur}
        onChange={onChange}
      />
    </S.InputSearchMarkets>
  ) : (
    <IconButton icon={IconSearch} view="outline-l" onClick={onOpen} />
  )

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const Form = styled.section`
  ${(props) => css`
    background-color: ${props.theme.tokens.colors.surface['surface-base']};
    border-radius: ${props.theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: 71vh; /* TODO: replace to calculate */
    min-height: 71vh; /* TODO: replace to calculate */
  `}
`

export const Sidebar = styled.div`
  ${(props) => css`
    border-right: 1px solid
      ${props.theme.tokens.colors.divider['divider-secondary']};
    display: flex;
    flex-direction: column;
    max-width: 19.5rem;
    min-width: 19.5rem;
  `}
`

export const SidebarHeader = styled.div`
  ${(props) => css`
    padding: ${props.theme.tokens.alias.space['space-m-16']}px
      ${props.theme.tokens.alias.space['space-m-16']}px 0
      ${props.theme.tokens.alias.space['space-m-16']}px;

    & > *:first-child {
      flex-shrink: 0;
    }
  `}
`

export const TextSelectPayment = styled.div`
  ${(props) => css`
    ${typography['Body / 12 Medium']}
    color: ${props.theme.tokens.colors.text['text-description']};
    margin-top: ${props.theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const ContainerCards = styled.div`
  ${(props) => css`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: ${props.theme.tokens.alias.space['space-xs-8']}px
      ${props.theme.tokens.alias.space['space-m-16']}px
      ${props.theme.tokens.alias.space['space-m-16']}px
      ${props.theme.tokens.alias.space['space-m-16']}px;
    row-gap: ${props.theme.tokens.alias.space['space-xxs-6']}px;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`

export const ContainerContent = styled.div`
  ${(props) => css`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
    padding: ${props.theme.tokens.alias.space['space-xl-24']}px
      ${props.theme.tokens.alias.space['space-m-16']}px
      ${props.theme.tokens.alias.space['space-m-16']}px
      ${props.theme.tokens.alias.space['space-m-16']}px;
    width: 100%;
  `}
`

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Title = styled.h3`
  ${(props) => css`
    ${typography['Title / 20 Bold']}
    color: ${props.theme.tokens.colors.text['text-primary']};
    margin-bottom: ${props.theme.tokens.alias.space['space-xl-24']}px;
  `}
`

export const Description = styled.div`
  ${(props) => css`
    ${typography['Body / 14 Medium']}
    color: ${props.theme.tokens.colors.text['text-secondary']};
    margin-bottom: ${props.theme.tokens.alias.space['space-m-16']}px;
  `}
`

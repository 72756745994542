import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { StyledTabItemProps } from './tabs-fill.types'

export const StyledTabsFill = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledTabFillItem = styled.div<StyledTabItemProps>`
  ${typography['Hint / 10 EXTRABOLD']}
  cursor: pointer;

  transition: background-color 0.25s ease, color 0.25s ease;

  ${({ theme, type, isActive }) => css`
    border-radius: ${theme.tokens.alias.space['space-xxxs-4']}px;
    color: ${theme.tokens.colors.text['text-secondary-3']};

    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;

    ${type === 'white' &&
    css`
      background-color: ${theme.tokens.colors.surface[
        'surface-default-opacity'
      ]};
    `}

    ${type === 'gray' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};
    `}

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-hover']};
      color: ${theme.tokens.colors.text['text-secondary']};
      svg {
        fill: ${theme.tokens.colors.icon['icon-primary-2']};
      }
    }

    ${isActive &&
    css`
      && {
        background: ${theme.tokens.colors.surface['surface-active2']};
        color: ${theme.tokens.colors.text['text-invert']};
      }
    `}
  `}
`

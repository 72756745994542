import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCalendar: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4 2C4 1.44772 4.44772 1 5 1C5.55228 1 6 1.44772 6 2V3H10V2C10 1.44772 10.4477 1 11 1C11.5523 1 12 1.44772 12 2V3H12.6C12.9444 3 13.2778 3.13376 13.5222 3.36013L14.6222 4.38907C14.8667 4.61543 15 4.92412 15 5.25338V12.7466C15 13.0656 14.8667 13.3846 14.6222 13.6109L13.5222 14.6399C13.2778 14.8662 12.9444 15 12.6 15H3.4C3.05556 15 2.72222 14.8662 2.47778 14.6399L1.37778 13.6109C1.13333 13.3846 1 13.0759 1 12.7466V5.25338C1 4.93441 1.13333 4.61543 1.37778 4.38907L2.47778 3.36013C2.72222 3.13376 3.05556 3 3.4 3H4V2ZM7.4 6C7.17909 6 7 6.17909 7 6.4V7.6C7 7.82091 7.17909 8 7.4 8H8.6C8.82091 8 9 7.82091 9 7.6V6.4C9 6.17909 8.82091 6 8.6 6H7.4ZM3.4 10C3.17909 10 3 10.1791 3 10.4V11.6C3 11.8209 3.17909 12 3.4 12H4.6C4.82091 12 5 11.8209 5 11.6V10.4C5 10.1791 4.82091 10 4.6 10H3.4ZM3 6.4C3 6.17909 3.17909 6 3.4 6H4.6C4.82091 6 5 6.17909 5 6.4V7.6C5 7.82091 4.82091 8 4.6 8H3.4C3.17909 8 3 7.82091 3 7.6V6.4ZM7.4 10C7.17909 10 7 10.1791 7 10.4V11.6C7 11.8209 7.17909 12 7.4 12H8.6C8.82091 12 9 11.8209 9 11.6V10.4C9 10.1791 8.82091 10 8.6 10H7.4ZM11 6.4C11 6.17909 11.1791 6 11.4 6H12.6C12.8209 6 13 6.17909 13 6.4V7.6C13 7.82091 12.8209 8 12.6 8H11.4C11.1791 8 11 7.82091 11 7.6V6.4Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { useEffect, useState } from 'react'
import { Event, EventStatus } from 'betweb-openapi-axios'

export const useToggleDisplayTableOutcomes = ({
  eventStatus,
  outcomesCount
}: {
  eventStatus: Event['status']
  outcomesCount: Event['outcomesCount']
}) => {
  const [isDisplay, setIsDisplay] = useState(false)
  const isLoadingOutcomes = eventStatus === EventStatus.Suspended

  useEffect(() => {
    if (!isLoadingOutcomes) {
      setIsDisplay(false)
    }
  }, [eventStatus, isLoadingOutcomes])

  const isDisabledToggleDisplay = isLoadingOutcomes || !outcomesCount

  return {
    isDisplay,
    setIsDisplay,
    isDisabledToggleDisplay
  }
}

import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useRedirectToSport } from 'hooks/useRedirectToSport'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { LayoutEventsTable } from 'layouts/events-table'

export const PageLive = () => {
  useRedirectToSport({
    currentTabMenu: ESportsMenuTabTypes.LIVE
  })

  return (
    <LayoutEventsTable>
      <LoaderSpinner />
    </LayoutEventsTable>
  )
}

export const LiveScreenPrefetch = ({ store }) => {
  store.dispatch(sportCountersProviderActions.fetchLiveSportCounters({}))
}

export default PageLive

import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  createRef,
  RefObject,
  memo,
  PropsWithChildren
} from 'react'
import isEqual from 'react-fast-compare'
import * as Templates from 'astrabet-templates-kit'
import {
  MarketCategoriesInfosByEventItem,
  selectEventById,
  selectEventCompetitors,
  selectEventProbabilityByMarket,
  selectMarketCategoriesInfosByEventFiltered
} from 'astra-core/containers/EventsProvider'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import {
  selectInputEventsSearch,
  useSearchReplace
} from 'astra-core/containers/SearchProvider'
import { EventStatus } from 'betweb-openapi-axios'

import { OddsTable } from 'widgets/outcomes-grid/odds-table'
import { RootState } from 'shared/types/store'
import { ProbabilityWithOutcome } from 'shared/lib/outcomes/types'
import {
  OutcomesCategoriesProps,
  OutcomesCategoryProps,
  OutcomesTableProps,
  PanelProps,
  OutcomeOddProps,
  OutcomeCategoriesHandle,
  MemoizedMasonryProps,
  TemplatedOutcomeProps,
  PanelHandle
} from 'widgets/outcomes-grid/outcomes-grid.types'
import {
  StyledPanel,
  StyledPanelHead,
  StyledTableRadius,
  MessageNothingFoundContainer
} from 'widgets/outcomes-grid/outcomes-grid.styled'
import {
  useEventMode,
  useTitleMergedColumns
} from 'entities/event-probability/lib/odd-mode'
import {
  PROBABILITY_LABEL_TEXT,
  PROBABILITY_ODD_TEXT
} from 'features/probability-cell/lib/probability-cell-style'
import { OddModes } from 'entities/event-probability/model/odd-mode'
import { getSelectedOutcomesGroup } from 'containers/OutcomesContainer/selectors'
import { IconChevronDownKf } from 'shared/ui/Icon/General/IconChevronDownKf'
import { useHandleAddCoefficientToBasket } from 'entities/event-probability'
import { selectEventStatus } from 'entities/event/model'
import {
  MessageNothingFound,
  useGetHandlerSearchMarketsReset
} from 'entities/outcome-table'
import { useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './outcomes-grid.styled'

const MERGED_TEMPLATES = ['G2Template']

export const OutcomesCategories = memo(
  forwardRef<OutcomeCategoriesHandle, OutcomesCategoriesProps>(
    ({ eventId, columnsCount = 1 }, forwardedRef) => {
      const valueSearch =
        useAppSelector((state: RootState) =>
          selectInputEventsSearch(state, eventId)
        ) || ''

      const selectedMarketGroupId = useAppSelector(getSelectedOutcomesGroup)

      const eventMarketCategoriesInfos = useAppSelector((state: RootState) =>
        selectMarketCategoriesInfosByEventFiltered(
          state,
          eventId,
          selectedMarketGroupId?.id,
          valueSearch
        )
      )

      return (
        <MemoizedMasonry
          columnsCount={columnsCount}
          eventId={eventId}
          eventMarketCategoriesInfos={eventMarketCategoriesInfos}
          ref={forwardedRef}
        />
      )
    }
  )
)

/**
 * This was necessary to prevent rerendering of child components
 * Closed panels used to open on each probability update
 * Memoizing array of filtered marketCategories, without reacting to probabilities update,
 * isolates changes inside each table, not the whole Masonry layout.
 * Filtering marketCategories was impossible without looking up into
 * event probabilities
 */
const MemoizedMasonry = React.memo(
  forwardRef<OutcomeCategoriesHandle, MemoizedMasonryProps>(
    ({ eventMarketCategoriesInfos, eventId, columnsCount }, forwardedRef) => {
      const [panelRefs, setPanelRefs] = useState<
        Record<string, RefObject<React.ElementRef<typeof OutcomesCategory>>>
      >({})
      const valueSearch = useAppSelector((state: RootState) =>
        selectInputEventsSearch(state, eventId)
      )

      useEffect(() => {
        setPanelRefs((elRefs) =>
          mapValues(
            keyBy(eventMarketCategoriesInfos, 'marketCategoryId'),
            (value) => elRefs[value.marketCategoryId] ?? createRef()
          )
        )
      }, [eventMarketCategoriesInfos])

      useImperativeHandle(
        forwardedRef,
        () => ({
          openAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.open()
            })
          },
          closeAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.close()
            })
          }
        }),
        [panelRefs]
      )

      const { onResetSearch } = useGetHandlerSearchMarketsReset({
        eventId
      })

      const isNothingFound = valueSearch && !eventMarketCategoriesInfos.length

      const columns = useMemo(
        () =>
          eventMarketCategoriesInfos.reduce<
            MarketCategoriesInfosByEventItem[][]
          >((acc, item, i) => {
            const colNumber = i % columnsCount
            const col = acc[colNumber] || []
            acc[colNumber] = [...col, item]

            return acc
          }, []),
        [columnsCount, eventMarketCategoriesInfos]
      )

      return isNothingFound ? (
        <MessageNothingFoundContainer>
          <MessageNothingFound onClickResetButton={onResetSearch} />
        </MessageNothingFoundContainer>
      ) : (
        <S.MasonryGrid>
          {columns.map((col, i) => (
            <S.MasonryColumn
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              {col.map(
                ({
                  marketCategoryId,
                  marketCategoryName,
                  categoryMarketInfos
                }) => (
                  <OutcomesCategory
                    categoryMarketInfos={categoryMarketInfos}
                    eventId={eventId}
                    key={marketCategoryId}
                    marketCategoryId={marketCategoryId}
                    marketCategoryName={marketCategoryName}
                    ref={panelRefs[marketCategoryId]}
                  />
                )
              )}
            </S.MasonryColumn>
          ))}
        </S.MasonryGrid>
      )
    }
  ),
  isEqual
)

export const OutcomesCategory = memo(
  forwardRef<PanelHandle, OutcomesCategoryProps>(
    ({ eventId, marketCategoryName, categoryMarketInfos }, forwardedRef) => {
      const OutcomesTables = useMemo(
        () =>
          categoryMarketInfos.map((categoryMarketInfo, index, array) => (
            <OutcomesTable
              isMerged={
                index > 0 &&
                MERGED_TEMPLATES.includes(categoryMarketInfo.templateId) &&
                MERGED_TEMPLATES.includes(array[index - 1].templateId)
              }
              nextIsMerged={
                index < array.length - 1 &&
                MERGED_TEMPLATES.includes(categoryMarketInfo.templateId) &&
                MERGED_TEMPLATES.includes(array[index + 1].templateId)
              }
              categoryMarketInfo={categoryMarketInfo}
              eventId={eventId}
              isFirst={index === 0}
              isLast={index === array.length - 1}
              key={categoryMarketInfo.id}
            />
          )),
        [categoryMarketInfos, eventId]
      )

      return (
        <Panel ref={forwardedRef} title={marketCategoryName}>
          <StyledTableRadius>{OutcomesTables}</StyledTableRadius>
        </Panel>
      )
    }
  ),
  isEqual
)

export const OutcomesTable: FC<OutcomesTableProps> = memo(
  ({ eventId, categoryMarketInfo, isFirst, isLast, isMerged }) => {
    // TODO resolve why competitors is needed on the templates-side
    const { homeCompetitors, awayCompetitors } = useAppSelector(
      (state: RootState) => selectEventCompetitors(state, eventId)
    )

    const outcomes = useAppSelector((state: RootState) =>
      selectEventProbabilityByMarket(
        state,
        eventId,
        categoryMarketInfo.marketId,
        categoryMarketInfo.id
      )
    )

    return (
      <TemplatedOutcome
        awayCompetitors={awayCompetitors}
        categoryMarketInfo={categoryMarketInfo}
        eventId={eventId}
        homeCompetitors={homeCompetitors}
        isFirst={isFirst}
        isLast={isLast}
        isMerged={isMerged}
        outcomes={outcomes}
      />
    )
  },
  isEqual
)

const TemplatedOutcome: FC<TemplatedOutcomeProps> = memo((props) => {
  const Component = useMemo(
    () =>
      Templates[props.categoryMarketInfo.templateId ?? 'K1Template'] ??
      Templates.K1Template,
    [props.categoryMarketInfo.templateId]
  )

  const renderOdd = useCallback(
    (outcome: ProbabilityWithOutcome) => (
      <OutcomeOdd eventProbability={{ ...outcome, eventId: props.eventId }} />
    ),
    [props.eventId]
  )

  return (
    <Component
      competitors={{
        homeCompetitors: props.homeCompetitors,
        awayCompetitors: props.awayCompetitors
      }}
      categoryMarket={props.categoryMarketInfo}
      isLast={props.isLast}
      OddsTableComponent={OddsTable}
      probabilities={props.outcomes}
      renderOdd={renderOdd}
      withoutDivider={props.isFirst || props.isMerged}
      withoutTitle={props.isMerged}
    />
  )
}, isEqual)

const Panel = memo(
  forwardRef<PanelHandle, PropsWithChildren<PanelProps>>(
    ({ title = '', children }, forwardedRef) => {
      const [opened, setOpened] = useState<boolean>(true)

      const toggleOpened = useCallback(() => setOpened((opened) => !opened), [])

      const open = useCallback(() => setOpened(true), [])
      const close = useCallback(() => setOpened(false), [])
      useImperativeHandle(forwardedRef, () => ({ open, close }), [close, open])

      return (
        <StyledPanel opened={opened}>
          <StyledPanelHead onClick={toggleOpened}>
            <S.StyledPanelTitle
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <IconChevronDownKf
              colorToken="icon-secondary-2"
              twist={opened ? 180 : 0}
            />
          </StyledPanelHead>
          {opened && children}
        </StyledPanel>
      )
    }
  ),
  isEqual
)

const OutcomeOdd: FC<OutcomeOddProps> = memo(({ eventProbability }) => {
  const { labelInButton, eventId } = eventProbability
  const eventStatus = useAppSelector((state) =>
    selectEventStatus(state, { eventId })
  )

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket({
    eventProbability,
    eventStatus,
    eventId
  })

  const { status } = useAppSelector((state) => selectEventById(state, eventId))

  const { mode, isInBasket } = useEventMode({
    eventProbability,
    eventId
  })
  const title = useTitleMergedColumns(eventProbability)

  const { searchRegExpReplace } = useSearchReplace({
    eventId: eventProbability?.eventId
  })

  return (
    <S.OddWrapper
      isCentered={!labelInButton}
      isEmpty={!eventProbability.odd || status === EventStatus.Suspended}
      isInBasket={isInBasket}
      mode={mode}
      onClick={handleAddOutcomeToBasket}
    >
      {labelInButton && (
        <S.OddLabel
          className={PROBABILITY_LABEL_TEXT}
          dangerouslySetInnerHTML={{ __html: searchRegExpReplace(title) }}
        />
      )}
      <S.OddProbability className={PROBABILITY_ODD_TEXT}>
        {eventProbability.odd.toFixed(2)}
      </S.OddProbability>
      {mode === OddModes.Increase && <S.TableIncreaseIcon size={8} />}
      {mode === OddModes.Decrease && <S.TableDecreaseIcon size={8} />}
    </S.OddWrapper>
  )
}, isEqual)

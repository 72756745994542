import { all, put, select, takeLatest } from 'redux-saga/effects'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { selectCurrentLangCode } from 'astra-core/containers/LanguagesProvider'
import { PayloadAction } from '@reduxjs/toolkit'
import { TagFilterOperation } from 'betweb-openapi-axios'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { searchTournamentContainerActions } from './slice'

function* handleFetchSearchTournament({
  payload: { tournamentId }
}: PayloadAction<{ tournamentId: number }>) {
  const currentLangCode = yield select(selectCurrentLangCode)

  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        locale: currentLangCode,
        tournamentId,
        tagFilterOperation: TagFilterOperation.Loose
      },
      sideEffects: eventsProviderSideEffects.searchTournament
    })
  )
}

export function* searchTournamentContainerSaga() {
  yield all([
    takeLatest(
      searchTournamentContainerActions.fetchSearchTournament,
      handleFetchSearchTournament
    )
  ])
}

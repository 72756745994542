import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M20.149 7.47492L20.1755 7.45052C21.2079 6.4329 21.2066 4.77834 20.1728 3.76219C19.1423 2.74928 17.4615 2.74545 16.4261 3.75365L6.41273 14.9874L6.41582 14.9905L5.67185 15.8199L4.72962 16.746L6.96352 18.9415L7.92793 17.9937L8.74069 17.2755L8.75029 17.285L20.149 7.47492ZM3.34662 18.1018C2.95274 18.4889 2.95274 19.1166 3.34662 19.5037L4.15382 20.2971C4.54771 20.6842 5.18632 20.6842 5.58021 20.2971L6.20398 19.684L3.97039 17.4887L3.34662 18.1018ZM17.4478 16.9375C18.6192 16.9375 19.583 16.0494 19.6804 14.9224C20.4545 15.5526 20.9484 16.5041 20.9485 17.5668C20.9485 18.6298 20.4543 19.5814 19.68 20.2117C19.5796 19.0876 18.6171 18.2029 17.4478 18.2029C16.2826 18.2029 15.3227 19.0813 15.2165 20.1996C14.4506 19.5694 13.9625 18.6231 13.9625 17.5668C13.9625 16.5108 14.4504 15.5647 15.216 14.9344C15.3192 16.0558 16.2805 16.9375 17.4478 16.9375ZM17.4489 15.8831C18.0932 15.8831 18.6173 15.368 18.6173 14.7348L18.6178 14.7121L18.6343 14.3351C18.2662 14.2051 17.8697 14.1341 17.4567 14.1341C17.0442 14.1341 16.6483 14.2049 16.2806 14.3345V14.7348C16.2806 15.368 16.8047 15.8831 17.4489 15.8831ZM16.2807 20.4061C16.2807 19.7729 16.8048 19.2578 17.449 19.2578C18.0932 19.2578 18.6173 19.7729 18.6174 20.4061V20.805C18.254 20.9312 17.8634 21.0001 17.4567 21.0001C17.0443 21.0001 16.6483 20.9293 16.2807 20.7997V20.4061Z"
      fill={theme.tokens.colors['sport-icon']['sport-pesapallo']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M15.2203 3.97773L15.2439 3.95604C16.1616 3.05148 16.1605 1.58075 15.2415 0.67751C14.3255 -0.222859 12.8314 -0.226266 11.9111 0.669916L3.01025 10.6556L3.01293 10.6582L2.35169 11.3954L1.51415 12.2185L3.49985 14.1702L4.35711 13.3277L5.07948 12.6894L5.0881 12.6978L15.2203 3.97773ZM0.364048 13.346C-0.0298378 13.7331 -0.0298372 14.3608 0.364049 14.7479L0.923083 15.2974C1.31697 15.6845 1.95559 15.6845 2.34947 15.2974L2.8247 14.8303L0.839276 12.8789L0.364048 13.346ZM12.8193 12.3889C13.8606 12.3889 14.7172 11.5995 14.8038 10.5977C15.4919 11.1579 15.931 12.0037 15.931 12.9484C15.931 13.8933 15.4918 14.7392 14.8035 15.2994C14.7142 14.3002 13.8586 13.5137 12.8193 13.5137C11.7835 13.5137 10.9303 14.2945 10.8359 15.2887C10.1551 14.7284 9.72123 13.8873 9.72123 12.9484C9.72123 12.0096 10.1549 11.1687 10.8355 10.6084C10.9272 11.6052 11.7817 12.3889 12.8193 12.3889ZM12.8203 11.4519C13.3929 11.4519 13.8588 10.994 13.8588 10.4312L13.8593 10.411L13.874 10.0759C13.5467 9.96033 13.1943 9.8972 12.8272 9.8972C12.4605 9.8972 12.1086 9.96017 11.7818 10.0754V10.4311C11.7818 10.994 12.2476 11.4519 12.8203 11.4519ZM11.7818 15.472C11.7818 14.9091 12.2477 14.4513 12.8203 14.4513C13.3929 14.4513 13.8588 14.9091 13.8588 15.472V15.8266C13.5359 15.9387 13.1887 16 12.8272 16C12.4605 16 12.1086 15.937 11.7818 15.8218V15.472Z"
      fill={theme.tokens.colors['sport-icon']['sport-pesapallo']}
      fillRule="evenodd"
    />
  )
}

export const IconSportPesapallo: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC, useCallback, useMemo } from 'react'

import {
  isDisabledProbability,
  isHandicapOutcome,
  useEventMode,
  useHandleAddCoefficientToBasket,
  getEventProbabilityParameterValue,
  CellWithProbability as CellWithProbabilityEntity,
  TrendModeIcon
} from 'entities/event-probability'

import { CellWithProbabilityProps } from './cell-with-probability.types'

export const CellWithProbability: FC<CellWithProbabilityProps> = ({
  event,
  cellConfig,
  eventProbability,
  isMerged,
  isLast,
  isFirst,
  isEventSuspended
}) => {
  const { mode, isInBasket } = useEventMode({
    eventProbability,
    eventId: event.id
  })

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket({
    eventStatus: event.status,
    eventProbability,
    eventId: event.id
  })

  const isDisabled = isDisabledProbability({
    odd: eventProbability.odd,
    suspended: eventProbability.suspended,
    isEventSuspended
  })

  const value = getEventProbabilityParameterValue(eventProbability.parameters)

  const OddModeIcon = useMemo(() => <TrendModeIcon trendMode={mode} />, [mode])

  const onClick = useCallback(() => {
    if (!isDisabled) {
      handleAddOutcomeToBasket()
    }
  }, [handleAddOutcomeToBasket, isDisabled])

  return (
    <CellWithProbabilityEntity
      prefixValue={
        isHandicapOutcome(cellConfig.mainOutcomeType) &&
        value &&
        +value > 0 &&
        '+'
      }
      isCompact={!!cellConfig.isCompact}
      isDisabled={isDisabled}
      isEventSuspended={isEventSuspended}
      isFirst={isFirst && !cellConfig.withParam}
      isInBasket={isInBasket}
      isLast={isLast}
      isMerged={isMerged}
      oddCellWidth={cellConfig.oddCellWidth}
      oddMode={mode}
      OddModeStatus={OddModeIcon}
      paramCellWidth={cellConfig.paramCellWidth}
      probabilityOdd={eventProbability.odd.toFixed(2)}
      value={value.length ? +value : ''}
      withParam={!!cellConfig.withParam}
      onClick={onClick}
    />
  )

  // return (
  //   <CellStyles.CellWrapper
  //     isActive={isInBasket}
  //     isCompact={cellConfig.isCompact}
  //     isDisabled={isDisabled}
  //     isMerged={isMerged}
  //     mode={mode}
  //   >
  //     {cellConfig.withParam && (
  //       <CellStyles.BlockParamCell
  //         cellWidth={cellConfig.paramCellWidth}
  //         isCompact={cellConfig.isCompact}
  //         isFirst
  //       >
  //         {isHandicapOutcome(cellConfig.mainOutcomeType) &&
  //           value &&
  //           +value > 0 &&
  //           '+'}
  //
  //         {value.length ? +value : null}
  //       </CellStyles.BlockParamCell>
  //     )}
  //
  //     <CellStyles.BlockOddCell
  //       cellWidth={cellConfig.oddCellWidth}
  //       isCompact={cellConfig.isCompact}
  //       isFirst={isFirst && !cellConfig.withParam}
  //       isLast={isLast}
  //       onClick={isDisabled ? undefined : handleAddOutcomeToBasket}
  //     >
  //       {eventProbability.odd.toFixed(2)}
  //
  //       {mode === OddModes.Increase && <S.IncreaseIcon size={8} />}
  //       {mode === OddModes.Decrease && <S.DecreaseIcon size={8} />}
  //     </CellStyles.BlockOddCell>
  //   </CellStyles.CellWrapper>
  // )
}

function formatValue(number: number): string {
  const formattedNumber = number.toFixed(2)

  const parts = formattedNumber.split('.')

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  return parts.join(',')
}

export function formatBalance(
  value: number | undefined,
  currencyIcon?: string
): string {
  const isValidValue = value || value === 0

  if (!isValidValue) {
    return currencyIcon ? `... ${currencyIcon}` : '...'
  }

  const formattedValue = formatValue(value as number)
  return currencyIcon ? `${formattedValue} ${currencyIcon}` : formattedValue
}

import { FC, memo } from 'react'

import { IconButton } from 'shared/ui/buttons/IconButton'
import { IconTrashFilled } from 'shared/ui/Icon/General/IconTrashFilled'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { Tooltip } from 'shared/ui/Tooltip'

import {
  BetTypeNavigationItemProps,
  BetTypeNavigationProps
} from './bet-type-navigation.types'
import {
  StyledBetsWrapper,
  StyledBetTypeItemText,
  StyledBetTypeItemWrapper,
  StyledNavSpace,
  StyledNavWrapper
} from './bet-type-navigation.styled'

export const BetTypeNavigation: FC<BetTypeNavigationProps> = memo(
  ({ children, onCloseBasket }) => (
    <StyledNavSpace>
      <BasketTooltipProvider>
        <StyledNavWrapper>
          <StyledBetsWrapper>{children}</StyledBetsWrapper>

          <IconButton
            icon={IconTrashFilled}
            view="outline-l"
            onClick={onCloseBasket}
          />
        </StyledNavWrapper>
      </BasketTooltipProvider>
    </StyledNavSpace>
  )
)

export const BetTypeNavigationItem: FC<BetTypeNavigationItemProps> = memo(
  ({ isActive, tooltipText, isTooltipCommon, title, onClick, isDisabled }) => (
    <Tooltip isCommon={isTooltipCommon} title={tooltipText}>
      <StyledBetTypeItemWrapper
        $isActive={isActive}
        $isDisabled={isDisabled}
        onClick={onClick}
      >
        <StyledBetTypeItemText $isActive={isActive}>
          {title}
        </StyledBetTypeItemText>
      </StyledBetTypeItemWrapper>
    </Tooltip>
  )
)

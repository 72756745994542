import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M8.23077 5.74259C8.23077 3.67562 9.91831 2 12 2C14.0817 2 15.7692 3.67562 15.7692 5.74259C15.7692 6.90698 15.2337 7.94718 14.3939 8.6336H14.6923C15.4358 8.6336 16.0385 9.23203 16.0385 9.97024C16.0385 10.7084 15.4358 11.3069 14.6923 11.3069C14.6923 12.8202 15.6509 14.8094 17.1159 15.4786C17.2728 15.5503 17.3846 15.7008 17.3846 15.8732V16.5969C17.3846 16.7073 17.2951 16.7969 17.1846 16.7969H6.81546C6.705 16.7969 6.61545 16.7073 6.61546 16.5969L6.6155 15.8731C6.6155 15.7006 6.72738 15.5501 6.88426 15.4784C8.34912 14.8093 9.30769 12.8201 9.30769 11.3069C8.56423 11.3069 7.96154 10.7084 7.96154 9.97024C7.96154 9.23203 8.56423 8.6336 9.30769 8.6336H9.60613C8.7663 7.94718 8.23077 6.90698 8.23077 5.74259ZM6.61538 17.9969V18H6.29231C5.57859 18 5 18.5745 5 19.2832V22H19V19.2832C19 18.5745 18.4214 18 17.7077 18H17.3846V17.9969H6.61538Z"
      fill={theme.tokens.colors['sport-icon']['sport-chess']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      d="M7.99999 0C6.33464 0 4.9846 1.34049 4.9846 2.99407C4.9846 3.92559 5.41302 4.75774 6.08489 5.30688H5.84614C5.25137 5.30688 4.76922 5.78563 4.76922 6.37619C4.76922 6.96676 5.25137 7.4455 5.84614 7.4455C5.84614 8.65612 5.07928 10.2475 3.90739 10.7827C3.78189 10.8401 3.69239 10.9605 3.69238 11.0984V12.5388C3.69238 12.682 3.57705 12.7985 3.43387 12.7999C2.86289 12.7999 2.40002 13.2595 2.40002 13.8264V15.9999H13.6V13.8264C13.6 13.2595 13.1372 12.7999 12.5662 12.7999C12.4234 12.7999 12.3077 12.6841 12.3077 12.5414L12.3077 11.0986C12.3077 10.9606 12.2182 10.8402 12.0927 10.7829C10.9207 10.2475 10.1538 8.65613 10.1538 7.4455C10.7486 7.4455 11.2308 6.96676 11.2308 6.37619C11.2308 5.78563 10.7486 5.30688 10.1538 5.30688H9.91508C10.5869 4.75774 11.0154 3.92559 11.0154 2.99407C11.0154 1.34049 9.66534 0 7.99999 0Z"
      fill={theme.tokens.colors['sport-icon']['sport-chess']}
    />
  )
}

export const IconSportChess: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

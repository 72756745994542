import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M5.50167 18.7757C5.50167 18.682 5.57763 18.6061 5.67133 18.6061H6.28765C6.91049 18.6061 7.22191 18.3012 7.22191 17.6916C7.22191 17.4543 7.14777 17.2615 6.99947 17.1132C6.85117 16.9616 6.64191 16.8858 6.37168 16.8858C6.15088 16.8858 5.9581 16.9501 5.79332 17.0786C5.68153 17.1676 5.60843 17.2716 5.57404 17.3906C5.54802 17.4806 5.47515 17.5581 5.38145 17.5581H4.0549C3.9612 17.5581 3.88437 17.482 3.89251 17.3886C3.91963 17.0777 4.02266 16.7964 4.20161 16.5447C4.41252 16.2481 4.70417 16.0175 5.07656 15.8527C5.45224 15.6846 5.86418 15.6006 6.31236 15.6006C7.11316 15.6006 7.7426 15.7835 8.20067 16.1493C8.65874 16.5151 8.88778 17.0176 8.88778 17.657C8.88778 17.9667 8.79221 18.26 8.60107 18.5369C8.41323 18.8104 8.13806 19.0328 7.77555 19.2042C8.15783 19.3426 8.45772 19.5535 8.67522 19.8369C8.89272 20.117 9.00147 20.4647 9.00147 20.8799C9.00147 21.5226 8.75431 22.0367 8.25999 22.4222C7.76567 22.8078 7.11646 23.0006 6.31236 23.0006C5.84111 23.0006 5.40281 22.9116 4.99747 22.7337C4.59542 22.5524 4.29059 22.3036 4.08297 21.9872C3.90474 21.7128 3.80301 21.4068 3.77779 21.0692C3.7708 20.9758 3.8475 20.8997 3.9412 20.8997H5.27765C5.37134 20.8997 5.44495 20.9767 5.46582 21.068C5.50034 21.2191 5.58315 21.3542 5.71423 21.4731C5.89219 21.6346 6.11134 21.7153 6.37168 21.7153C6.66498 21.7153 6.89896 21.6346 7.07362 21.4731C7.24828 21.3084 7.33561 21.0991 7.33561 20.8453C7.33561 20.4828 7.24498 20.2258 7.06373 20.0742C6.88248 19.9226 6.63202 19.8468 6.31236 19.8468H5.67133C5.57763 19.8468 5.50167 19.7709 5.50167 19.6772V18.7757Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M9.78904 21.137C9.72025 21.0718 9.71826 20.9629 9.78462 20.8952L11.1419 19.5107L9.78422 18.1212C9.71805 18.0535 9.72012 17.9447 9.78884 17.8795L10.4968 17.2083C10.564 17.1445 10.6699 17.1465 10.7347 17.2126L12.0663 18.5715L13.3978 17.2126C13.4626 17.1465 13.5685 17.1445 13.6357 17.2083L14.3435 17.8794C14.4123 17.9446 14.4143 18.0535 14.3479 18.1212L12.9857 19.5107L14.3475 20.8952C14.4141 20.9628 14.4122 21.0719 14.3433 21.1372L13.6355 21.8083C13.5684 21.872 13.4627 21.8701 13.3978 21.8042L12.0663 20.4499L10.7347 21.8042C10.6699 21.8701 10.5641 21.872 10.497 21.8083L9.78904 21.137Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M16.7228 18.7757C16.7228 18.682 16.7987 18.6061 16.8924 18.6061H17.5088C18.1316 18.6061 18.443 18.3012 18.443 17.6916C18.443 17.4543 18.3689 17.2615 18.2206 17.1132C18.0723 16.9616 17.863 16.8858 17.5928 16.8858C17.372 16.8858 17.1792 16.9501 17.0144 17.0786C16.9026 17.1676 16.8295 17.2716 16.7951 17.3906C16.7691 17.4806 16.6963 17.5581 16.6026 17.5581H15.276C15.1823 17.5581 15.1055 17.482 15.1136 17.3886C15.1407 17.0777 15.2438 16.7964 15.4227 16.5447C15.6336 16.2481 15.9253 16.0175 16.2977 15.8527C16.6733 15.6846 17.0853 15.6006 17.5335 15.6006C18.3343 15.6006 18.9637 15.7835 19.4218 16.1493C19.8798 16.5151 20.1089 17.0176 20.1089 17.657C20.1089 17.9667 20.0133 18.26 19.8222 18.5369C19.6343 18.8104 19.3592 19.0328 18.9967 19.2042C19.3789 19.3426 19.6788 19.5535 19.8963 19.8369C20.1138 20.117 20.2226 20.4647 20.2226 20.8799C20.2226 21.5226 19.9754 22.0367 19.4811 22.4222C18.9868 22.8078 18.3376 23.0006 17.5335 23.0006C17.0622 23.0006 16.6239 22.9116 16.2186 22.7337C15.8165 22.5524 15.5117 22.3036 15.3041 21.9872C15.1258 21.7128 15.0241 21.4068 14.9989 21.0692C14.9919 20.9758 15.0686 20.8997 15.1623 20.8997H16.4988C16.5924 20.8997 16.6661 20.9767 16.6869 21.068C16.7214 21.2191 16.8043 21.3542 16.9353 21.4731C17.1133 21.6346 17.3324 21.7153 17.5928 21.7153C17.8861 21.7153 18.1201 21.6346 18.2947 21.4731C18.4694 21.3084 18.5567 21.0991 18.5567 20.8453C18.5567 20.4828 18.4661 20.2258 18.2848 20.0742C18.1036 19.9226 17.8531 19.8468 17.5335 19.8468H16.8924C16.7987 19.8468 16.7228 19.7709 16.7228 19.6772V18.7757Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        clipRule="evenodd"
        d="M12.3 7.425C12.3 7.49741 12.3078 7.56982 12.3158 7.64473C12.3327 7.80258 12.3509 7.97154 12.3 8.17499C13.125 8.39999 13.875 8.69999 14.55 9.07499C15 7.35 15.225 5.55 15.3 3.675C14.325 3.225 13.2 3 12 3C10.8 3 9.59999 3.225 8.54999 3.675C10.425 4.35 12.075 5.7 12.3 7.425ZM16.425 4.2C18.3 5.25 19.725 6.975 20.475 9.075C19.125 9.525 18.15 10.125 17.325 11.1C16.8 10.575 16.2 10.125 15.6 9.67501L15.6 9.675C16.05 7.95 16.35 6.15 16.425 4.2ZM11.25 7.57499V7.87499C9 7.49999 6.45 7.57499 3.825 8.24999C4.575 6.67499 5.7 5.39999 7.125 4.42499C9.075 4.79999 10.95 5.99999 11.25 7.57499ZM12.075 9.30001C12.825 9.52501 13.575 9.82501 14.25 10.2C13.5355 12.5115 12.4208 14.5403 10.9716 16.2206C9.51334 15.8955 8.5 15 8.5 15C8.5 15 7.5 14.2007 6.31253 14.2007C6.13138 14.2007 5.95178 14.2106 5.77418 14.2308C9.86896 12.5165 11.4961 10.7151 12.075 9.30001ZM20.4261 15.165C20.8087 14.1802 21 13.1379 21 12C21 11.4 20.925 10.725 20.775 10.2C19.65 10.575 18.75 11.025 18.075 12C18.7039 12.7861 19.223 13.6822 19.6322 14.6369C19.8649 14.7504 20.087 14.8889 20.2956 15.0554C20.3403 15.0912 20.3839 15.1277 20.4261 15.165ZM18.2257 14.2398C18.0218 13.8536 17.7959 13.4826 17.55 13.125C17.4228 13.4521 17.3088 13.8189 17.209 14.2317C17.3193 14.2119 17.4282 14.2007 17.5336 14.2007C17.7665 14.2007 17.9978 14.213 18.2257 14.2398ZM16.0081 14.6921C16.1922 13.6312 16.4484 12.7782 16.8 12.075C16.6728 11.966 16.55 11.8569 16.4283 11.7489L16.4283 11.7489L16.4283 11.7489C16.0481 11.4113 15.6796 11.0841 15.225 10.8C14.5331 12.8758 13.5456 14.7348 12.3031 16.3266C14.1463 16.2036 15.5 15 15.5 15C15.5 15 15.7046 14.8571 16.0081 14.6921ZM10.95 8.99999C8.7 8.54999 6.075 8.77499 3.3 9.52499L3.3 9.52499C3.15 10.35 3 11.175 3 12C3 12.675 3.075 13.35 3.225 13.95C8.475 12.15 10.35 10.35 10.95 8.99999Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M3.00128 12.6202C3.00128 12.5453 3.06204 12.4845 3.137 12.4845H3.63004C4.1283 12.4845 4.37743 12.2407 4.37743 11.7529C4.37743 11.5631 4.31812 11.4089 4.19948 11.2903C4.08085 11.169 3.91344 11.1084 3.69727 11.1084C3.52063 11.1084 3.36641 11.1598 3.23459 11.2626C3.14516 11.3338 3.08669 11.417 3.05917 11.5121C3.03835 11.5841 2.98006 11.6462 2.90511 11.6462H1.84389C1.76894 11.6462 1.70747 11.5853 1.71398 11.5106C1.73568 11.2618 1.8181 11.0368 1.96126 10.8355C2.12998 10.5982 2.36329 10.4137 2.6612 10.2819C2.96174 10.1474 3.29127 10.0802 3.64981 10.0802C4.29043 10.0802 4.79397 10.2265 5.16042 10.5191C5.52686 10.8118 5.71008 11.2138 5.71008 11.7253C5.71008 11.9731 5.63363 12.2077 5.48073 12.4291C5.33046 12.648 5.11033 12.8259 4.82033 12.963C5.12614 13.0737 5.36605 13.2425 5.54004 13.4692C5.71404 13.6933 5.80104 13.9714 5.80104 14.3036C5.80104 14.8176 5.60331 15.2289 5.20787 15.5374C4.81242 15.8458 4.29307 16 3.64981 16C3.27282 16 2.92219 15.9288 2.59793 15.7865C2.2763 15.6415 2.03244 15.4424 1.86635 15.1894C1.72377 14.9698 1.64239 14.725 1.62221 14.455C1.61662 14.3803 1.67798 14.3194 1.75294 14.3194H2.82206C2.89702 14.3194 2.9559 14.3809 2.9726 14.454C3.00022 14.5749 3.06646 14.6829 3.17132 14.7781C3.31368 14.9073 3.489 14.9719 3.69727 14.9719C3.9319 14.9719 4.11907 14.9073 4.2588 14.7781C4.39852 14.6463 4.46838 14.4789 4.46838 14.2759C4.46838 13.9859 4.39589 13.7803 4.25089 13.659C4.10589 13.5377 3.90553 13.4771 3.64981 13.4771H3.137C3.06204 13.4771 3.00128 13.4163 3.00128 13.3414V12.6202Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M6.43107 14.5092C6.37604 14.4571 6.37445 14.3699 6.42754 14.3157L7.51332 13.2082L6.42722 12.0967C6.37428 12.0425 6.37594 11.9554 6.43091 11.9033L6.99727 11.3663C7.05103 11.3153 7.13573 11.3169 7.18758 11.3698L8.2528 12.4568L9.31802 11.3698C9.36987 11.3169 9.45458 11.3153 9.50834 11.3663L10.0745 11.9032C10.1296 11.9553 10.1312 12.0425 10.0781 12.0967L8.98833 13.2082L10.0777 14.3157C10.131 14.3699 10.1295 14.4572 10.0744 14.5094L9.50817 15.0463C9.45448 15.0972 9.36989 15.0957 9.31802 15.0429L8.2528 13.9595L7.18759 15.0429C7.13571 15.0957 7.05113 15.0972 6.99743 15.0463L6.43107 14.5092Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M11.9779 12.6202C11.9779 12.5453 12.0387 12.4845 12.1136 12.4845H12.6067C13.1049 12.4845 13.3541 12.2407 13.3541 11.7529C13.3541 11.5631 13.2947 11.4089 13.1761 11.2903C13.0575 11.169 12.8901 11.1084 12.6739 11.1084C12.4973 11.1084 12.343 11.1598 12.2112 11.2626C12.1218 11.3338 12.0633 11.417 12.0358 11.5121C12.015 11.5841 11.9567 11.6462 11.8817 11.6462H10.8205C10.7456 11.6462 10.6841 11.5853 10.6906 11.5106C10.7123 11.2618 10.7947 11.0368 10.9379 10.8355C11.1066 10.5982 11.3399 10.4137 11.6378 10.2819C11.9384 10.1474 12.2679 10.0802 12.6264 10.0802C13.2671 10.0802 13.7706 10.2265 14.137 10.5191C14.5035 10.8118 14.6867 11.2138 14.6867 11.7253C14.6867 11.9731 14.6103 12.2077 14.4573 12.4291C14.3071 12.648 14.0869 12.8259 13.797 12.963C14.1028 13.0737 14.3427 13.2425 14.5167 13.4692C14.6907 13.6933 14.7777 13.9714 14.7777 14.3036C14.7777 14.8176 14.5799 15.2289 14.1845 15.5374C13.789 15.8458 13.2697 16 12.6264 16C12.2494 16 11.8988 15.9288 11.5745 15.7865C11.2529 15.6415 11.0091 15.4424 10.843 15.1894C10.7004 14.9698 10.619 14.725 10.5988 14.455C10.5932 14.3803 10.6546 14.3194 10.7296 14.3194H11.7987C11.8736 14.3194 11.9325 14.3809 11.9492 14.454C11.9768 14.5749 12.0431 14.6829 12.1479 14.7781C12.2903 14.9073 12.4656 14.9719 12.6739 14.9719C12.9085 14.9719 13.0957 14.9073 13.2354 14.7781C13.3751 14.6463 13.445 14.4789 13.445 14.2759C13.445 13.9859 13.3725 13.7803 13.2275 13.659C13.0825 13.5377 12.8822 13.4771 12.6264 13.4771H12.1136C12.0387 13.4771 11.9779 13.4163 11.9779 13.3414V12.6202Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M8.43978 3.53989C8.43978 3.59782 8.44599 3.65575 8.45242 3.71567C8.46597 3.84196 8.48047 3.97712 8.43978 4.13987C9.09976 4.31987 9.69974 4.55986 10.2397 4.85985C10.5997 3.4799 10.7797 2.03994 10.8397 0.539984C10.0597 0.179995 9.15976 0 8.19978 0C7.23981 0 6.27984 0.179995 5.43987 0.539984C6.93982 1.07997 8.25978 2.15993 8.43978 3.53989Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M11.7397 0.959975C13.2396 1.79995 14.3796 3.17991 14.9796 4.85986C13.8996 5.21985 13.1196 5.69983 12.4597 6.47981C12.0397 6.05982 11.5597 5.69984 11.0797 5.33985C11.4397 3.95989 11.6797 2.51993 11.7397 0.959975Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M7.59981 3.65989V3.89988C5.79986 3.59989 3.75992 3.65989 1.65998 4.19987C2.25997 2.93991 3.15994 1.91994 4.29991 1.13996C5.85986 1.43995 7.35982 2.39992 7.59981 3.65989Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M8.25978 5.03986C8.85977 5.21986 9.45975 5.45985 9.99973 5.75984C9.42819 7.60895 8.53638 9.23199 7.37709 10.5762C6.21052 10.3161 5.39987 9.59972 5.39987 9.59972C5.39987 9.59972 4.59989 8.96028 3.64995 8.96028C3.50503 8.96028 3.36135 8.9682 3.21928 8.98437C6.49501 7.61294 7.79666 6.17194 8.25978 5.03986Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M14.9405 9.73173C15.2465 8.9439 15.3996 8.11009 15.3996 7.19977C15.3996 6.71978 15.3396 6.1798 15.2196 5.75981C14.3196 6.0598 13.5996 6.41979 13.0596 7.19977C13.5628 7.82869 13.978 8.5455 14.3054 9.30927C14.4915 9.40007 14.6692 9.51082 14.836 9.64404C14.8719 9.67265 14.9067 9.70189 14.9405 9.73173Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M13.1802 8.9916C13.0171 8.6826 12.8363 8.38585 12.6397 8.09975C12.5379 8.36146 12.4467 8.65487 12.3669 8.98511C12.4551 8.96927 12.5423 8.96028 12.6266 8.96028C12.8128 8.96028 12.9979 8.97017 13.1802 8.9916Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M11.4061 9.35342C11.5534 8.50472 11.7584 7.82232 12.0397 7.2598C11.9379 7.17256 11.8396 7.08532 11.7424 6.99894C11.4382 6.72887 11.1434 6.46711 10.7797 6.23983C10.2262 7.90038 9.43626 9.38757 8.44222 10.661C9.91675 10.5626 10.9997 9.59972 10.9997 9.59972C10.9997 9.59972 11.1634 9.48539 11.4061 9.35342Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M7.35981 4.79985C5.55987 4.43986 3.45993 4.61985 1.23999 5.21984C1.12 5.87982 1 6.5398 1 7.19978C1 7.73976 1.06 8.27974 1.17999 8.75973C5.37987 7.31977 6.87983 5.87982 7.35981 4.79985Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
    </>
  )
}

export const IconSportBasketball3vs3: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

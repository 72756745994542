import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { StyledLoaderPointsMini } from 'shared/ui/LoaderPointsMini/loader-poinst-mini.styled'
import { LoaderPointsMiniProps } from 'shared/ui/LoaderPointsMini/loader-points-mini.types'

export const LoaderPointsMini: FC<LoaderPointsMiniProps> = ({
  gap,
  size = 3,
  colors
}) => {
  const theme = useTheme()
  const loaderColors = colors ?? {
    end: '#6C799366', // Replace with token
    start: theme.tokens.colors.icon['icon-primary-2']
  }
  return (
    <StyledLoaderPointsMini>
      <LoaderPoints
        settingsAnimation={{
          timeStep: 0.5,
          timeAfterEnd: 1.5,
          scaleActivePoint: 1
        }}
        colors={loaderColors}
        count={3}
        margin={gap}
        size={size}
      />
    </StyledLoaderPointsMini>
  )
}

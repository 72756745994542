import { useParams } from 'react-router-dom'
import { useCallback } from 'react'

import {
  searchTournamentContainerActions,
  selectSearchTournamentLayoutIsLoading
} from 'entities/search-tournament'
import { useEventsPoll } from 'entities/event/api'
import { LineParams } from 'entities/line/model/types'
import {
  LINE_POLLING_INTERVAL,
  getFetchLineReqData
} from 'entities/line/model/utils'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const useSearchTournamentData = () => {
  const dispatch = useAppDispatch()
  const { tournamentId } = useParams<LineParams>()
  const isLayoutLoading = useAppSelector(selectSearchTournamentLayoutIsLoading)

  const fetch = useCallback(
    ({ tournamentId }) => {
      if (tournamentId) {
        dispatch(
          searchTournamentContainerActions.fetchSearchTournament(
            getFetchLineReqData({
              tournamentId
            })
          )
        )
      }
    },
    [dispatch]
  )

  useEventsPoll({
    isLoading: isLayoutLoading,
    tournamentId: +tournamentId,
    fetch,
    pollingInterval: LINE_POLLING_INTERVAL
  })
}

import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { SegmentedControl } from 'shared/ui/segmented-control'
import { selectLiveSportCountersTotal } from 'entities/sport-counters'
import { Typography } from 'shared/ui/typography'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { ISportsMenuTabProps } from './sports-menu-tabs.types'
import { LiveTab, StyledTabsWrapper } from './sports-menu-tabs.styled'

export const SportsMenuTabs: FC<ISportsMenuTabProps> = memo(
  ({ currentTabMenu, setCurrentTabMenu }) => {
    const [hasDataLoadedOnce, setHasDataLoadedOnce] = useState(false)
    const [t] = useTranslation()

    const liveCountersTotal = useAppSelector(
      selectLiveSportCountersTotal,
      (left, right) => left === right
    )

    const handleTabChange = useCallback(
      (type: ESportsMenuTabTypes) => {
        setCurrentTabMenu(type)
      },
      [setCurrentTabMenu]
    )

    useEffect(() => {
      if (liveCountersTotal > 0) {
        setHasDataLoadedOnce(true)
      }
    }, [liveCountersTotal])

    const items = useMemo(
      () => [
        {
          value: ESportsMenuTabTypes.LINE,
          label: t('line')
        },
        {
          value: ESportsMenuTabTypes.LIVE,
          label: (
            <LiveTab>
              {t('live')}
              {hasDataLoadedOnce && (
                <Typography
                  color={
                    currentTabMenu === ESportsMenuTabTypes.LIVE
                      ? 'text-description'
                      : 'text-secondary-2'
                  }
                  font="Hint / 11 Medium"
                >
                  {liveCountersTotal}
                </Typography>
              )}
            </LiveTab>
          )
        }
      ],
      [currentTabMenu, hasDataLoadedOnce, liveCountersTotal, t]
    )

    return (
      <StyledTabsWrapper>
        <SegmentedControl
          items={items}
          itemSize={28}
          value={currentTabMenu}
          equalItems
          fullWidth
          onChange={handleTabChange}
        />
      </StyledTabsWrapper>
    )
  }
)

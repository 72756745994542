import styled, { css } from 'styled-components'

export const SearchResultsWrapper = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.tokens.alias.space['space-xxl-32']}px;
  `}
`

export const SearchResultSectionTitle = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const SectionTitleWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxs-6']}px 0;
  `}
`

export const EmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  white-space: break-spaces;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    padding: ${theme.tokens.alias.space['space-xxl-32']}px;

    > div {
      display: flex;
      flex-direction: column;
      gap: ${theme.tokens.alias.space['space-xxxs-4']}px;

      > * {
        text-align: center;
      }
    }
  `}
`

import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'

import {
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText
} from '../sports-menu-list/sports-menu-list.styled'

export const StyledFavoriteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 6px;
  width: 24px;
`

export const StyledFavoriteButton = styled.div<{ $isActive: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  text-decoration: none;
  transition: background-color 0.25s ease-in-out;

  ${({ theme: { tokens }, $isActive }) => css`
    padding: ${tokens.alias.space['space-xs-8']}px
      ${tokens.alias.space['space-s-12']}px;

    ${StyledIcon} {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background-color: ${tokens.colors.surface['surface-menu-active']};
    }

    ${$isActive &&
    css`
      background-color: ${tokens.colors.surface['surface-menu-active']};

      &:hover {
        background-color: ${tokens.colors.surface['surface-menu-active']};
      }

      &:before {
        background-color: ${tokens.colors.icon['icon-warning']};
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;
      }

      ${StyledSportsMenuItemCount} {
        color: ${tokens.colors.text['text-secondary-3']};
      }

      ${StyledSportsMenuItemText} {
        color: ${tokens.colors.text['text-primary-2']};
      }
    `}
  `}
`

import React, { FC, memo } from 'react'
import isEqual from 'react-fast-compare'

import { Card } from '../../card'
import { OrdinarInputBetSum } from '../input-bet-sum'

import { BetCardElementProps } from './card.types'
import { StyledBetCardElementWrapper } from './card.styled'

export const OrdinarCard: FC<BetCardElementProps> = memo(
  ({ outcome }) => (
    <StyledBetCardElementWrapper>
      <Card outcome={outcome}>
        <OrdinarInputBetSum
          eventStatus={outcome.event.status}
          maxBetAmount={outcome.maxBet}
          odd={outcome.probability.odd}
          outcomeId={outcome.id}
        />
      </Card>
    </StyledBetCardElementWrapper>
  ),
  isEqual
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M4.03967 5.93332C3.83572 5.93393 3.63961 6.01194 3.49097 6.15159C3.36373 6.27105 3.2338 6.47196 3.2022 6.59791C3.19209 6.63819 2.87255 7.30886 2.68314 7.70474C2.61956 7.83762 2.71628 7.99109 2.86359 7.99109C4.27417 7.99109 9.92442 7.99109 10.4664 7.99109C15.2925 7.99109 22.9836 7.99109 22.9836 7.99109V7.01253C22.9836 6.38263 22.9836 6.02587 22.3541 5.95197C22.3541 5.95197 10.0388 5.91547 4.03967 5.93332ZM18.9693 9.40955C17.4671 9.40955 2.50631 9.40955 2.50631 9.40955C2.35071 9.40955 2.22457 9.53569 2.22457 9.69128C2.22457 9.84633 2.13422 10.0953 2.02375 10.2447C1.89742 10.4156 1.85952 10.6362 1.92158 10.8394L2.00058 11.0982C2.33866 12.2055 2.25367 13.158 1.63943 15.1475C0.974676 17.3003 0.976066 17.7148 1.65027 18.4338C2.16749 18.9854 2.4444 19.0434 4.57072 19.0456C7.13631 19.0483 6.93271 19.2062 7.27468 16.9512C7.40525 16.0909 7.53148 15.2975 7.5552 15.1881C7.59607 15.0001 7.66131 14.9872 8.7343 14.9545C10.9758 14.8864 12.0352 13.9132 12.1219 11.8423L12.1618 10.8923H17.3308C19.8359 10.8923 21.8305 10.8923 22.6188 10.8923C22.8218 10.8923 22.9856 10.7291 22.988 10.5261L22.9836 9.40955C22.9836 9.40955 20.4715 9.40955 18.9693 9.40955ZM10.7366 11.7107C10.6824 13.082 10.1182 13.5741 8.59593 13.5779C7.62248 13.5803 7.47402 13.4362 8.27037 13.2618C8.64097 13.1807 8.77989 12.893 8.9 11.9581C8.95894 11.4994 9.02279 11.0719 9.04188 11.0082C9.06644 10.9265 9.32621 10.8923 9.92284 10.8923H10.7691L10.7366 11.7107Z"
      fill={theme.tokens.colors['sport-icon']['sport-pistolShooting']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M1.71991 3.35878C1.82877 3.2565 1.97241 3.19936 2.12179 3.19891C6.51571 3.18584 15.5357 3.21257 15.5357 3.21257C15.9968 3.2667 15.9968 3.52799 15.9968 3.98935V4.17779L16 4.17773V6.22112L15.9987 6.22109L16 6.56276C15.9982 6.71143 15.8782 6.83102 15.7296 6.83102H8.0706L8.04142 7.52676C7.97788 9.04353 7.20198 9.75638 5.56024 9.80626C4.77436 9.83016 4.72658 9.83966 4.69665 9.97732C4.67927 10.0575 4.58682 10.6386 4.49118 11.2687C4.37043 12.0649 4.34256 12.4504 4.1355 12.6359C3.91308 12.8352 3.4839 12.8037 2.51074 12.8027C0.953372 12.8011 0.750556 12.7586 0.371735 12.3546C-0.122069 11.828 -0.123087 11.5244 0.363794 9.94759C0.813682 8.4904 0.875925 7.79283 0.62831 6.98177L0.570446 6.79227C0.524994 6.64342 0.552754 6.48184 0.645279 6.3567C0.726188 6.24728 0.735948 6.11 0.792368 5.95133C1.10325 5.07703 1.33093 4.39296 1.5084 3.68567C1.53155 3.59343 1.62671 3.44627 1.71991 3.35878ZM5.4589 8.79795C6.57385 8.79517 6.98708 8.43475 7.02679 7.43037L7.05055 6.83102H6.43076C5.99377 6.83102 5.80351 6.85607 5.78553 6.91586C5.77154 6.96256 5.72478 7.27561 5.68161 7.6116C5.59364 8.29634 5.49189 8.50703 5.22045 8.56649C4.63719 8.69417 4.74592 8.79972 5.4589 8.79795Z"
      fill={theme.tokens.colors['sport-icon']['sport-pistolShooting']}
      fillRule="evenodd"
    />
  )
}

export const IconSportPistolShooting: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { Alert, EAlertViev } from 'shared/ui/alert'

import * as S from './bonus-coupon-can-use.styled'
import { BonusCouponCanUseListProps } from './bonus-coupon-can-use.types'

export const BonusCouponCanUseList: FC<BonusCouponCanUseListProps> = ({
  CouponList,
  isDisplayAlertInfo
}) => {
  const [t] = useTranslation()

  return (
    <>
      <S.BonusCouponCanUseListContainer>
        {CouponList}
      </S.BonusCouponCanUseListContainer>

      {isDisplayAlertInfo && (
        <S.AlertContainer>
          <Alert size="s" view={EAlertViev.INFO}>
            {t('you can apply the bonus coupon available to you')}
          </Alert>
        </S.AlertContainer>
      )}
    </>
  )
}

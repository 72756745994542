import styled, { css } from 'styled-components'

export const GlobalSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchHeader = styled.div`
  padding: 24px 16px 12px 16px;
`

export const TitleWrapper = styled.div`
  padding: 4px 0;
`

export const SearchWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const GlobalSearchFilters = styled.div`
  display: grid;
  grid-template-columns: auto auto minmax(0, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const GlobalSearchFiltersDivider = styled.div`
  ${({ theme }) =>
    css`
      background: ${theme.tokens.colors.border['border-primary']};
      height: 100%;
      width: 1px;
    `}
`

export const SportTabsWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xs-8']}px 0;
  `}
`
export const SegmentedControlWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const SearchTitleLoaderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;

    > * {
      padding: ${theme.tokens.alias.space['space-xxs-6']}px 0;
    }
  `}
`

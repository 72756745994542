import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M16.1194 20.3131C16.0845 20.2996 16.0497 20.2855 16.0151 20.2711C15.0868 19.8837 14.1937 19.1648 13.4337 18.4191C12.6665 17.6664 11.9991 16.8504 11.5256 16.2266C10.9285 15.4401 10.4325 14.5956 9.89403 13.7694L9.46568 13.1074C9.37599 13.2182 9.27707 13.3426 9.1715 13.4787C8.71097 14.0724 8.13092 14.8807 7.63906 15.7517C7.1423 16.6313 6.76046 17.5318 6.65486 18.3176C6.55368 19.0705 6.70987 19.6432 7.18612 20.0475C7.62413 20.2433 8.10769 20.351 8.61056 20.3518L12.1071 20.3576L15.6037 20.3518C15.7783 20.3516 15.9505 20.3384 16.1194 20.3131Z"
        fill={theme.tokens.colors['sport-icon']['sport-horseRacing']}
      />
      <path
        d="M5.53138 18.1755C5.51951 18.2638 5.5102 18.3522 5.50369 18.4406C5.43635 18.3037 5.37778 18.1612 5.32879 18.0136L4.24273 14.743L3.1678 11.4688C2.94842 10.8006 2.94905 10.1023 3.14103 9.46066C3.41066 8.90789 3.9204 8.61425 4.66568 8.51816C5.45197 8.41679 6.42061 8.55256 7.39886 8.80472C8.36747 9.05439 9.29834 9.40549 9.99107 9.69665C10.2143 9.79049 10.4118 9.87766 10.5766 9.95254L10.0628 10.5849C9.66749 11.0747 9.28017 11.5722 8.87386 12.0529C8.72509 12.2289 8.51675 12.4818 8.27604 12.7921C7.7961 13.4109 7.18061 14.2669 6.65215 15.2027C6.1286 16.1298 5.66535 17.1786 5.53138 18.1755Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
      />
      <path
        d="M7.68279 7.70362C6.76501 7.46704 5.76645 7.30158 4.85449 7.35881L7.24781 5.64167L10.08 3.62388C10.5711 3.274 11.1323 3.06604 11.7067 3C12.2976 3.06556 12.7422 3.43295 13.0921 4.07753C13.4704 4.77427 13.6933 5.72663 13.8112 6.72997C13.9278 7.7234 13.9363 8.71825 13.9148 9.46937C13.9087 9.68194 13.9002 9.87409 13.8912 10.0401L13.1524 9.75512C12.2379 9.39926 11.3353 9.02818 10.4315 8.64828C9.70955 8.34485 8.72347 7.97187 7.68279 7.70362Z"
        fill={theme.tokens.colors['sport-icon']['sport-pistolShooting']}
      />
      <path
        d="M14.9348 6.59129C15.0602 7.65865 15.0682 8.71288 15.0457 9.49565C15.0176 10.4745 14.9404 11.4529 14.8906 12.4309L14.8476 13.2265C14.9987 13.192 15.1698 13.1512 15.3565 13.1039C16.085 12.9195 17.0397 12.6397 17.9635 12.2561C18.8965 11.8688 19.7518 11.3942 20.3187 10.84C20.8643 10.3068 21.0934 9.75698 20.9635 9.14213C20.7271 8.56417 20.329 8.04762 19.7922 7.66248L16.9669 5.63538L14.1347 3.61759L14.1313 3.61523C14.5815 4.48528 14.8146 5.56765 14.9348 6.59129Z"
        fill={theme.tokens.colors['sport-icon']['sport-fencing']}
      />
      <path
        d="M14.7695 14.4065L12.8769 14.917L12.1143 15.12C12.2053 15.2459 12.31 15.388 12.4269 15.5419C12.8812 16.1404 13.5119 16.9099 14.2259 17.6104C14.947 18.3179 15.7179 18.9198 16.4496 19.2252C17.1169 19.5037 17.6867 19.5147 18.1831 19.2088C18.4918 18.8668 18.7333 18.4602 18.8841 18.0061L19.9702 14.7354L20.926 11.8242C20.224 12.4445 19.2993 12.9308 18.3976 13.3051C17.405 13.7172 16.3931 14.0128 15.6339 14.205C15.2867 14.2929 14.9901 14.3597 14.7695 14.4065Z"
        fill={theme.tokens.colors['sport-icon']['sport-swimming']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M11.6623 15.6759C11.6313 15.6639 11.6004 15.6514 11.5696 15.6386C10.7444 15.2942 9.95045 14.6551 9.27484 13.9922C8.59291 13.3232 7.99956 12.5978 7.57866 12.0433C7.04791 11.3441 6.607 10.5934 6.12832 9.85901L5.74754 9.27051C5.66781 9.36897 5.57987 9.47953 5.48603 9.60052C5.07665 10.1283 4.56101 10.8469 4.12378 11.6211C3.68219 12.4031 3.34276 13.2035 3.24889 13.902C3.15894 14.5714 3.29779 15.0804 3.72115 15.4398C4.11051 15.6139 4.54037 15.7096 4.98739 15.7104L8.09562 15.7155L11.2038 15.7104C11.359 15.7101 11.5122 15.6984 11.6623 15.6759Z"
        fill={theme.tokens.colors['sport-icon']['sport-horseRacing']}
      />
      <path
        d="M2.25024 13.775C2.23969 13.8534 2.23142 13.9321 2.22563 14.0106C2.16577 13.8889 2.11371 13.7622 2.07015 13.6311L1.10471 10.7237L0.149161 7.81317C-0.0458501 7.21918 -0.0452916 6.59842 0.125372 6.02801C0.365051 5.53664 0.818182 5.2756 1.48069 5.19019C2.17965 5.10008 3.04072 5.22077 3.91032 5.44492C4.77135 5.66687 5.59884 5.97897 6.21463 6.23779C6.41311 6.32121 6.58865 6.3987 6.73513 6.46526L6.27837 7.02739C5.927 7.46279 5.58269 7.90506 5.22151 8.33235C5.08926 8.4888 4.90406 8.71361 4.69008 8.9895C4.26345 9.53956 3.71631 10.3004 3.24654 11.1323C2.78113 11.9565 2.36933 12.8888 2.25024 13.775Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
      />
      <path
        d="M4.16272 4.46639C3.34686 4.25609 2.45921 4.109 1.64853 4.15987L3.77604 2.63344L6.29371 0.839747C6.73026 0.52873 7.22911 0.343866 7.73973 0.285156C8.26504 0.343436 8.66019 0.670022 8.97129 1.24302C9.30756 1.86237 9.50572 2.70897 9.61047 3.60087C9.71419 4.48397 9.72172 5.36833 9.70255 6.03603C9.69713 6.22499 9.68959 6.3958 9.68159 6.5434L9.02489 6.29005C8.21192 5.97371 7.4096 5.64384 6.60612 5.30613C5.96438 5.0364 5.08782 4.70485 4.16272 4.46639Z"
        fill={theme.tokens.colors['sport-icon']['sport-pistolShooting']}
      />
      <path
        d="M10.6093 3.47756C10.7208 4.42638 10.7279 5.36353 10.7079 6.05937C10.6829 6.92955 10.6143 7.79924 10.5701 8.66862L10.5318 9.3759C10.6661 9.3452 10.8182 9.30892 10.9842 9.26691C11.6318 9.103 12.4804 8.85422 13.3016 8.51327C14.131 8.16891 14.8913 7.74707 15.3953 7.25443C15.8802 6.78039 16.0839 6.29167 15.9684 5.7451C15.7583 5.23134 15.4044 4.77215 14.9272 4.42979L12.4157 2.62782L9.89804 0.834123L9.8951 0.832031C10.2952 1.60545 10.5025 2.56761 10.6093 3.47756Z"
        fill={theme.tokens.colors['sport-icon']['sport-fencing']}
      />
      <path
        d="M10.4624 10.4244L8.78003 10.8782L8.10205 11.0587C8.18298 11.1706 8.2761 11.2969 8.37998 11.4337C8.78385 11.9657 9.34444 12.6498 9.97913 13.2725C10.6202 13.9014 11.3055 14.4365 11.9559 14.7079C12.5491 14.9555 13.0556 14.9653 13.4969 14.6934C13.7713 14.3893 13.986 14.0279 14.1201 13.6242L15.0855 10.7168L15.9351 8.12891C15.3111 8.68031 14.4891 9.11255 13.6875 9.44535C12.8052 9.81168 11.9056 10.0745 11.2308 10.2453C10.9222 10.3234 10.6585 10.3828 10.4624 10.4244Z"
        fill={theme.tokens.colors['sport-icon']['sport-swimming']}
      />
    </>
  )
}

export const IconSportModernPentathlon: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { StyledButtonProps } from './Button.types'

export const StyledButton = styled.button<StyledButtonProps>`
  ${({
    internalView,
    internalSize,
    withIcon,
    withContent,
    isActive,
    theme
  }) => css`
    align-items: center;
    border: none;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    cursor: pointer;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    transition: background-color 0.25s ease, color 0.25s ease;
    white-space: nowrap;
    &:disabled {
      cursor: default;
    }

    ${internalSize === 'm' &&
    css`
      ${typography['Hint / 10 EXTRABOLD']}
      height: 24px;
      padding: 0 ${theme.tokens.alias.space['space-xxs-6']}px;
    `}
    ${internalSize === 'l' &&
    css`
      ${typography['Hint / 11 BOLD']}
      height: 32px;
      padding: 0 ${theme.tokens.alias.space['space-s-12']}px;
      ${withIcon &&
      css`
        padding-left: ${theme.tokens.alias.space['space-xs-8']}px;
      `}
      ${!withContent &&
      css`
        padding-right: ${theme.tokens.alias.space['space-xs-8']}px;
      `}
    `}
    ${internalSize === 'xl' &&
    css`
      ${typography['Hint / 11 BOLD']}
      height: 40px;
      padding: 0 ${theme.tokens.alias.space['space-s-12']}px;
      ${withIcon &&
      css`
        padding-left: ${theme.tokens.alias.space['space-xs-8']}px;
      `}
      ${!withContent &&
      css`
        padding-right: ${theme.tokens.alias.space['space-xs-8']}px;
      `}
    `}

    ${internalView === 'primary' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-brand-default']};
      color: ${theme.tokens.colors.text['text-brand']};

      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-brand-hover']};
      }
      &:active {
        background-color: ${theme.tokens.colors.surface[
          'surface-brand-active'
        ]};
      }
      &:disabled {
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
        color: ${theme.tokens.colors.text['text-disabled']};
      }
    `}
    ${internalView === 'secondary' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};
      color: ${theme.tokens.colors.text['text-secondary-3']};
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-active-4']};
      }
      &:disabled {
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
        color: ${theme.tokens.colors.text['text-disabled']};
      }
    `}
    ${internalView === 'outline' &&
    css`
      ${typography['Body / 12 SemiBold']}
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      box-shadow: inset 0 0 0 1px
        ${theme.tokens.colors.border['border-default-gray']};
      color: ${theme.tokens.colors.text['text-secondary-3']};

      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-hover']};
        color: ${theme.tokens.colors.text['text-secondary']};
        svg {
          color: ${theme.tokens.colors.icon['icon-primary-2']};
        }
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-active-4']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-primary']};
        color: ${theme.tokens.colors.text['text-secondary']};
        svg {
          color: ${theme.tokens.colors.icon['icon-primary-2']};
        }
      }
      &:disabled,
      &:disabled:hover,
      &:disabled:active {
        background-color: ${theme.tokens.colors.surface['surface-modal']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-disabled']};
        color: ${theme.tokens.colors.text['text-disabled']};
        svg {
          color: ${theme.tokens.colors.icon['icon-opacity']};
        }
      }
    `}
    ${internalView === 'filter' &&
    css`
      ${typography['Body / 12 SemiBold']}
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      box-shadow: inset 0 0 0 1px
        ${theme.tokens.colors.border['border-default-gray']};
      color: ${theme.tokens.colors.text['text-secondary-3']};

      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-hover']};
        color: ${theme.tokens.colors.text['text-secondary']};
        svg {
          color: ${theme.tokens.colors.icon['icon-primary-2']};
        }
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-active2']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-primary']};
        color: ${theme.tokens.colors.text['text-invert']};
        svg {
          color: ${theme.tokens.colors.icon['icon-invert']};
        }
      }
      &:disabled,
      &:disabled:hover,
      &:disabled:active {
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-disabled']};
        color: ${theme.tokens.colors.text['text-disabled']};
        svg {
          color: ${theme.tokens.colors.icon['icon-opacity']};
        }
      }
      ${isActive &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-active2']};
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-primary']};
        color: ${theme.tokens.colors.text['text-invert']};
        svg {
          color: ${theme.tokens.colors.icon['icon-invert']};
        }

        &:hover {
          background-color: ${theme.tokens.colors.surface[
            'surface-active2-hover'
          ]};
          box-shadow: inset 0 0 0 1px
            ${theme.tokens.colors.border['border-primary']};
          color: ${theme.tokens.colors.text['text-invert']};
          svg {
            color: ${theme.tokens.colors.icon['icon-invert']};
          }
        }
      `}
    `}
    ${internalView === 'ghost' &&
    css`
      ${typography['Body / 12 EXTRABOLD']}
      background-color: transparent;
      color: ${theme.tokens.colors.text['text-secondary-3']};
      padding: ${theme.tokens.alias.space['space-xxxs-4']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xxxs-4']}px
        ${theme.tokens.alias.space['space-xxxs-4']}px;
      svg {
        color: ${theme.tokens.colors.icon['icon-secondary-2']};
      }
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
        color: ${theme.tokens.colors.text['text-secondary']};
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-active-4']};
        color: ${theme.tokens.colors.text['text-secondary']};
      }
      &:disabled {
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
        color: ${theme.tokens.colors.text['text-disabled']};
        svg {
          color: ${theme.tokens.colors.icon['icon-opacity']};
        }
      }
    `}
  `}
`

export const StyledLoadingOverlay = styled.div<StyledButtonProps>`
  ${({ theme, view }) => css`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;

    right: 0;
    top: 0;
    z-index: 2;

    ${(view === 'primary-medium' ||
      view === 'primary-large' ||
      view === 'primary-extra-large') &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-brand-loading']};
    `}
  `}
`

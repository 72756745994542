import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M18.4158 10.6297L20.5607 11.1098C20.8601 11.1773 21.0502 11.471 20.9883 11.7702L20.4257 14.5536C20.3863 14.7516 20.2434 14.9124 20.0521 14.9766L10.6533 18.1165L8.92331 19.8462L8.75966 20.0111C8.1228 20.6479 7.27439 21 6.3731 21C5.4718 21 4.6234 20.649 3.98653 20.0111C2.67116 18.6948 2.67116 16.555 3.98653 15.2387L5.88125 13.343L9.02171 3.94563C9.0881 3.74988 9.25463 3.607 9.4583 3.571L12.6404 3.00848C12.7934 2.98148 12.9509 3.01973 13.0747 3.11198C13.1985 3.20424 13.2806 3.34487 13.2964 3.499L13.5051 5.3512C13.5373 5.63636 13.4592 5.92302 13.2869 6.15252L7.74229 13.5384C7.57445 13.762 7.59652 14.0749 7.79409 14.2728C8.03579 14.5148 8.43583 14.4866 8.64119 14.2131L13.9077 7.19901C14.1473 6.87994 14.538 6.71161 14.9345 6.75661L16.564 6.94159C16.8239 6.96972 17.0298 7.1756 17.0591 7.43661L17.2282 8.92798C17.2727 9.31991 17.1086 9.70648 16.7958 9.94681L9.76736 15.3466C9.49804 15.5535 9.47218 15.9501 9.71235 16.1902C9.91205 16.3899 10.2289 16.4105 10.4528 16.2385L17.4843 10.8355C17.7489 10.6322 18.0901 10.5568 18.4158 10.6297Z"
      fill={theme.tokens.colors['sport-icon']['sport-badminton']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      d="M13.7029 6.78197L15.6095 7.20874C15.8756 7.26874 16.0446 7.52975 15.9896 7.79576L15.4895 10.2698C15.4545 10.4458 15.3275 10.5888 15.1575 10.6458L6.80291 13.4369L5.26517 14.9744L5.1197 15.121C4.5536 15.687 3.79946 16 2.99831 16C2.19716 16 1.44302 15.688 0.876913 15.121C-0.292304 13.9509 -0.292304 12.0489 0.876913 10.8789L2.56111 9.1938L5.35263 0.840563C5.41165 0.666558 5.55967 0.539554 5.74071 0.507554L8.56923 0.00753951C8.70526 -0.0164612 8.84529 0.0175398 8.95531 0.0995421C9.06533 0.181544 9.13834 0.306548 9.15234 0.443552L9.33791 2.08996C9.36648 2.34343 9.29709 2.59824 9.14394 2.80224L4.21537 9.36747C4.06617 9.56622 4.0858 9.8444 4.26142 10.0202C4.47626 10.2353 4.83185 10.2103 5.01439 9.96717L9.69575 3.73245C9.90871 3.44883 10.256 3.2992 10.6084 3.33921L12.0569 3.50364C12.2879 3.52864 12.471 3.71164 12.497 3.94365L12.6473 5.26932C12.6868 5.6177 12.541 5.96132 12.2629 6.17494L6.01543 10.9748C5.77603 11.1587 5.75305 11.5112 5.96653 11.7246C6.14405 11.9021 6.42568 11.9205 6.62473 11.7675L12.875 6.96492C13.1101 6.7842 13.4134 6.71718 13.7029 6.78197Z"
      fill={theme.tokens.colors['sport-icon']['sport-badminton']}
    />
  )
}

export const IconSportBadminton: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M8.18391 1.50249L4.6046 2.52421L5.81948 3.31417C5.93807 3.39142 6.05181 3.47615 6.15588 3.57334C6.57939 3.96209 6.88674 4.42061 7.10697 4.96387C7.13843 5.04361 7.15537 5.13083 7.15779 5.21805V19.238C7.81606 20.2572 9.74971 21.9019 11.1485 22.5V2.45942L8.18391 1.5V1.50249Z"
        fill="url(#paint0_linear_2220_7284)"
      />
      <path
        d="M16.1095 1.50249L19.6888 2.52421L18.4739 3.31417C18.3553 3.39142 18.2416 3.47615 18.1375 3.57334C17.714 3.96209 17.4066 4.42061 17.1864 4.96387C17.1549 5.04361 17.138 5.13083 17.1356 5.21805V19.238C16.4773 20.2572 14.5437 21.9019 13.1449 22.5V2.45942L16.1095 1.5V1.50249Z"
        fill="url(#paint1_linear_2220_7284)"
      />
      <path
        d="M3.17202 14.092C3.40435 14.7998 3.95855 15.1536 4.83704 15.1536H8.09932C8.80357 15.1536 9.15448 14.9842 9.15448 14.6477C9.15448 14.1219 8.15982 13.1102 6.16809 11.61C5.36462 11.0319 4.15942 10.1622 2.55732 9.00339H5.11051C7.15064 10.222 8.87375 11.448 10.2774 12.6816C11.7754 14.0023 12.5232 15.0539 12.5232 15.8364C12.5232 16.3448 12.182 16.7036 11.4995 16.908H0.00170898L3.17202 14.0895V14.092ZM13.6292 6.37434L11.9231 9.00339L10.425 8.12871H2.55732C2.55732 7.58545 2.70979 7.15683 3.01472 6.84284C3.31965 6.52885 3.7359 6.37185 4.26348 6.37185H13.6316L13.6292 6.37434ZM13.7793 8.1312L16.3325 6.37434H24.0017L22.2955 9.00339L20.5894 8.12871H13.7768L13.7793 8.1312ZM13.7793 10.7602V9.00588H16.3325V10.7602C16.3325 12.1508 16.5793 13.2672 17.073 14.1045C17.678 15.1362 18.6122 15.652 19.8755 15.652C20.5918 15.652 21.3977 15.4826 22.2955 15.1461C20.8024 16.3224 19.4326 16.9105 18.1862 16.9105C17.2593 16.9105 16.4438 16.5915 15.7395 15.9511C15.1127 15.3854 14.6287 14.6253 14.2875 13.6659C13.9462 12.7065 13.7768 11.7371 13.7768 10.7553L13.7793 10.7602Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2220_7284"
          x1="11.9928"
          x2="11.9928"
          y1="1.50249"
          y2="22.5"
        >
          <stop stopColor="#86B3E0" />
          <stop offset="1" stopColor="#7A9EC0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2220_7284"
          x1="12.0106"
          x2="12.0106"
          y1="1.50249"
          y2="22.5"
        >
          <stop stopColor="#86B3E0" />
          <stop offset="1" stopColor="#7A9EC0" />
        </linearGradient>
      </defs>
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M5.45651 1.00166L3.0703 1.68281L3.88023 2.20945C3.95928 2.26095 4.03511 2.31743 4.10449 2.38222C4.38683 2.64139 4.59173 2.94708 4.73855 3.30924C4.75952 3.36241 4.77082 3.42055 4.77243 3.4787V12.8253C5.21127 13.5048 6.50037 14.6013 7.43291 15V1.63961L5.45651 1V1.00166Z"
        fill="url(#paint0_linear_2220_7283)"
      />
      <path
        d="M10.7402 1.00166L13.1264 1.68281L12.3165 2.20945C12.2374 2.26095 12.1616 2.31743 12.0922 2.38222C11.8099 2.64139 11.605 2.94708 11.4582 3.30924C11.4372 3.36241 11.4259 3.42055 11.4243 3.4787V12.8253C10.9854 13.5048 9.69635 14.6013 8.76381 15V1.63961L10.7402 1V1.00166Z"
        fill="url(#paint1_linear_2220_7283)"
      />
      <path
        d="M2.11525 9.39469C2.27014 9.86651 2.6396 10.1024 3.22526 10.1024H5.40012C5.86961 10.1024 6.10356 9.98944 6.10356 9.76517C6.10356 9.41463 5.44045 8.74013 4.11263 7.74001C3.57698 7.35458 2.77352 6.77478 1.70545 6.00226H3.40758C4.76767 6.81465 5.9164 7.63202 6.85217 8.45438C7.85086 9.33488 8.3494 10.036 8.3494 10.5576C8.3494 10.8965 8.12191 11.1358 7.66693 11.272H0.00170898L2.11525 9.39303V9.39469ZM9.08671 4.24956L7.94927 6.00226L6.95059 5.41914H1.70545C1.70545 5.05697 1.80709 4.77122 2.01038 4.56189C2.21367 4.35257 2.49117 4.2479 2.84289 4.2479H9.08833L9.08671 4.24956ZM9.18674 5.4208L10.8889 4.24956H16.0017L14.8643 6.00226L13.7268 5.41914H9.18513L9.18674 5.4208ZM9.18674 7.1735V6.00392H10.8889V7.1735C10.8889 8.10052 11.0534 8.84479 11.3826 9.403C11.7859 10.0908 12.4087 10.4347 13.2509 10.4347C13.7284 10.4347 14.2657 10.3217 14.8643 10.0974C13.8688 10.8816 12.9556 11.2737 12.1247 11.2737C11.5068 11.2737 10.9631 11.061 10.4936 10.634C10.0757 10.2569 9.75305 9.75021 9.52556 9.1106C9.29807 8.47099 9.18513 7.82474 9.18513 7.17017L9.18674 7.1735Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2220_7283"
          x1="7.99579"
          x2="7.99579"
          y1="1.00166"
          y2="15"
        >
          <stop stopColor="#86B3E0" />
          <stop offset="1" stopColor="#7A9EC0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2220_7283"
          x1="8.00763"
          x2="8.00763"
          y1="1.00166"
          y2="15"
        >
          <stop stopColor="#86B3E0" />
          <stop offset="1" stopColor="#7A9EC0" />
        </linearGradient>
      </defs>
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M4.6046 2.52421L8.18391 1.50249V1.5L11.1485 2.45942V4.87189H7.0685C6.85136 4.36862 6.55527 3.93995 6.15588 3.57334C6.05181 3.47615 5.93807 3.39142 5.81948 3.31417L4.6046 2.52421Z"
        fill="currentColor"
      />
      <path
        d="M11.1485 8.55199L10.4236 8.12874H2.55732C2.55732 7.58549 2.70979 7.15686 3.01472 6.84287C3.31965 6.52888 3.7359 6.37189 4.26348 6.37189H13.6302L13.6292 6.37438L11.9231 9.00343L11.1485 8.55199Z"
        fill="currentColor"
      />
      <path
        d="M7.15779 12.3828C8.48843 13.4634 9.15309 14.218 9.15309 14.6478C9.15309 14.9842 8.80217 15.1537 8.09793 15.1537H4.83704C3.95855 15.1537 3.40435 14.7998 3.17202 14.0921V14.0896L0.00170898 16.908H11.4995C12.182 16.7037 12.5232 16.3448 12.5232 15.8365C12.5232 15.054 11.7754 14.0024 10.2774 12.6816C8.87375 11.4481 7.15064 10.222 5.11051 9.00343H2.55732C4.15942 10.1622 5.36462 11.0319 6.16809 11.61C6.53111 11.8835 6.861 12.1418 7.15779 12.3828Z"
        fill="currentColor"
      />
      <path
        d="M7.15779 18.408V19.238C7.81606 20.2572 9.7497 21.9019 11.1485 22.5V18.408H7.15779Z"
        fill="currentColor"
      />
      <path
        d="M13.1449 22.5V17.7156C13.2161 17.6555 13.285 17.5911 13.3511 17.5218C13.6954 17.1609 13.9082 16.7258 13.987 16.2573C14.2113 16.5477 14.4589 16.8169 14.7312 17.0629C15.436 17.7031 16.2454 18.123 17.1356 18.3058V19.238C16.4773 20.2572 14.5437 21.9019 13.1449 22.5Z"
        fill="currentColor"
      />
      <path
        d="M17.1356 16.7601C16.6292 16.6073 16.1634 16.3378 15.7381 15.9511C15.1113 15.3854 14.6273 14.6254 14.2861 13.6659C13.9853 12.8203 13.818 11.9668 13.7826 11.1039C13.7779 10.9899 13.7755 10.8746 13.7755 10.7603L13.7755 10.7553L13.7768 10.7582L13.7779 9.00592H16.3311L16.3325 10.7603C16.3325 12.1508 16.5793 13.2672 17.073 14.1045C17.678 15.1362 18.6122 15.6521 19.8755 15.6521C20.5918 15.6521 21.3977 15.4826 22.2955 15.1462C20.8024 16.3224 19.4326 16.9105 18.1862 16.9105C17.8185 16.9105 17.4683 16.8607 17.1356 16.7601Z"
        fill="currentColor"
      />
      <path
        d="M16.3311 6.37438L13.7815 8.12874H13.7768L13.7779 8.13123L13.7815 8.12874H20.5894L22.2955 9.00343L24.0017 6.37438H16.3311Z"
        fill="currentColor"
      />
      <path
        d="M17.2238 4.87438C17.441 4.37003 17.7374 3.94055 18.1375 3.57334C18.2416 3.47615 18.3553 3.39142 18.4739 3.31417L19.6888 2.52421L16.1095 1.50249V1.5L13.1449 2.45942V4.87189H17.1797L17.1773 4.87438H17.2238Z"
        fill="currentColor"
      />
      <path
        d="M7.72837 15.0137C7.72837 15.0137 7.72429 15.0075 7.71885 14.9958C7.72611 15.008 7.72837 15.0137 7.72837 15.0137Z"
        fill="currentColor"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M3.0703 1.68311L5.45651 1.00197V1.00031L7.43291 1.63992V3.24823H4.7129C4.56814 2.91272 4.37075 2.62694 4.10449 2.38253C4.03511 2.31774 3.95928 2.26125 3.88023 2.20975L3.0703 1.68311Z"
        fill="currentColor"
      />
      <path
        d="M7.43291 5.70163L6.94966 5.41947H1.70545C1.70545 5.0573 1.80709 4.77155 2.01038 4.56222C2.21367 4.35289 2.49117 4.24823 2.84289 4.24823H9.0874L9.08671 4.24989L7.94927 6.00259L7.43291 5.70163Z"
        fill="currentColor"
      />
      <path
        d="M4.77243 8.2555C5.65952 8.97592 6.10263 9.47894 6.10263 9.76549C6.10263 9.98977 5.86869 10.1027 5.39919 10.1027H3.22526C2.6396 10.1027 2.27014 9.86684 2.11525 9.39502V9.39336L0.00170898 11.2723H7.66693C8.12191 11.1361 8.3494 10.8969 8.3494 10.5579C8.3494 10.0363 7.85086 9.33521 6.85217 8.45471C5.9164 7.63235 4.76767 6.81498 3.40758 6.00259H1.70545C2.77352 6.77511 3.57698 7.35491 4.11263 7.74034C4.35464 7.92262 4.57457 8.09484 4.77243 8.2555Z"
        fill="currentColor"
      />
      <path
        d="M4.77243 12.2723V12.8256C5.21127 13.5051 6.50037 14.6016 7.43291 15.0003V12.2723H4.77243Z"
        fill="currentColor"
      />
      <path
        d="M8.76381 15.0003V11.8107C8.81128 11.7707 8.85725 11.7277 8.90132 11.6815C9.13087 11.4409 9.27268 11.1508 9.32526 10.8385C9.47474 11.0321 9.63982 11.2116 9.82136 11.3756C10.2912 11.8024 10.8308 12.0823 11.4243 12.2042V12.8256C10.9854 13.5051 9.69635 14.6016 8.76381 15.0003Z"
        fill="currentColor"
      />
      <path
        d="M11.4243 11.1737C11.0867 11.0718 10.7761 10.8922 10.4927 10.6344C10.0748 10.2572 9.75212 9.75054 9.52463 9.11093C9.32411 8.54714 9.21259 7.97819 9.18897 7.40293C9.18585 7.32693 9.18426 7.25005 9.18421 7.17383L9.18421 7.1705L9.18513 7.17241L9.18582 6.00425H10.8879L10.8889 7.17383C10.8889 8.10085 11.0534 8.84512 11.3826 9.40333C11.7859 10.0911 12.4087 10.435 13.2509 10.435C13.7284 10.435 14.2657 10.322 14.8643 10.0978C13.8688 10.8819 12.9556 11.274 12.1247 11.274C11.8796 11.274 11.6461 11.2408 11.4243 11.1737Z"
        fill="currentColor"
      />
      <path
        d="M10.8879 4.24989L9.18823 5.41947H9.18513L9.18582 5.42113L9.18823 5.41947H13.7268L14.8643 6.00259L16.0017 4.24989H10.8879Z"
        fill="currentColor"
      />
      <path
        d="M11.4831 3.24989C11.6279 2.91366 11.8255 2.62734 12.0922 2.38253C12.1616 2.31774 12.2374 2.26125 12.3165 2.20975L13.1264 1.68311L10.7402 1.00197V1.00031L8.76381 1.63992V3.24823H11.4537L11.4521 3.24989H11.4831Z"
        fill="currentColor"
      />
      <path
        d="M5.15282 10.0094C5.15282 10.0094 5.15009 10.0053 5.14647 9.99751C5.15131 10.0056 5.15282 10.0094 5.15282 10.0094Z"
        fill="currentColor"
      />
    </>
  )
}

export const IconCybersportStarCraft2: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'
import { selectBasketHeaderTab } from 'astra-core/containers/BasketProvider'

import { basketModel } from 'entities/basket'
import { Basket as BasketCoupon } from 'features/basket'
import { BasketHistoryFacade as BasketHistory } from 'features/basket-history'
import { BasketBroadcastEvent } from 'features/basket-broadcast-event'
import { useAppSelector, useInjectReducer } from 'shared/lib/@reduxjs'

import { EBasketHeaderTypes } from '../../model/basket-header-type'
import { Header } from '../header'

import * as S from './basket.styled'

const { selectIsShowBasketOnSmallWindow, basketReducer, basketSliceKey } =
  basketModel

const CONTENT_MAP = {
  [EBasketHeaderTypes.Coupon]: <BasketCoupon />,
  [EBasketHeaderTypes.History]: <BasketHistory />
}

export const Basket: FC = () => {
  useInjectReducer({ key: basketSliceKey, reducer: basketReducer })

  const basketType = useAppSelector(selectBasketHeaderTab)
  const isShowBasketOnSmallWindow = useAppSelector(
    selectIsShowBasketOnSmallWindow
  )

  const basketContent = CONTENT_MAP[basketType]

  return (
    <S.StyledBasketAsideWrapper
      isShowBasketOnSmallWindow={isShowBasketOnSmallWindow}
    >
      <S.StyledBasketWrapper>
        <BasketBroadcastEvent />

        <Header />

        {basketContent}
      </S.StyledBasketWrapper>
    </S.StyledBasketAsideWrapper>
  )
}

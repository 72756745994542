import React from 'react'

import { IconPerson } from 'shared/ui/Icon/General/IconPerson'
import { ERoutes } from 'shared/types/routes'
import { useHandleRedirectList } from 'widgets/header/components/PersonalData/components/ComponentOfAuthorized/hooks'
import { IconButton } from 'shared/ui/buttons/IconButton'

export const PersonalAccountButton = () => {
  const handleRedirectList = useHandleRedirectList()

  return (
    <IconButton
      icon={IconPerson}
      view="ghost-l"
      onClick={handleRedirectList(ERoutes.Profile)}
    />
  )
}

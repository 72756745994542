import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconWarningTriangleFilled16: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.52596 4H10.474C10.769 4 11.0324 4.15333 11.1798 4.41618L16.8894 14.711C17.0369 14.9739 17.0369 15.2915 16.8894 15.5543L16.3311 16.5729C16.1836 16.8357 15.9202 17 15.6253 17H4.37472C4.07976 17 3.8164 16.8357 3.66892 16.5729L3.11061 15.5543C2.96313 15.2915 2.96313 14.9739 3.11061 14.711L8.82017 4.41618C8.96765 4.16428 9.24154 4 9.52596 4ZM10 7C9.44772 7 9 7.44772 9 8V11C9 11.5523 9.44772 12 10 12C10.5523 12 11 11.5523 11 11V8C11 7.44772 10.5523 7 10 7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { CardPaymentProps } from './card-payment.types'

export const Card = styled.div<{ $isActive: CardPaymentProps['isActive'] }>`
  ${({ theme, $isActive }) => css`
    background-color: ${theme.tokens.colors.surface['surface-cards']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    cursor: pointer;
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    padding: ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-m-16']}px;
    width: 100%;

    &:hover {
      outline-color: ${theme.tokens.colors.border['border-settings-active']};
    }

    &:active {
      outline: 2px solid ${theme.tokens.colors.border['border-settings-active']};
    }

    ${$isActive &&
    css`
      outline: 2px solid ${theme.tokens.colors.border['border-settings-active']};
    `}
  `}
`

export const IconsContainer = styled.div`
  ${(props) => css`
    align-items: center;
    column-gap: ${props.theme.tokens.alias.space['space-xxs-6']}px;
    display: flex;
  `}
`

export const Title = styled.div`
  ${(props) => css`
    ${typography['Body / 14 Bold']}
    color: ${props.theme.tokens.colors.text['text-primary']};
    column-gap: ${props.theme.tokens.alias.space['space-xxs-6']}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`

export const Amount = styled.div`
  ${(props) => css`
    color: ${props.theme.tokens.colors.text['text-secondary']};
    ${typography['Body / 12 Medium']}
    margin-bottom: ${props.theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const Limits = styled.div`
  ${(props) => css`
    color: ${props.theme.tokens.colors.text['text-description']};
    ${typography['Body / 12 Medium']}
  `}
`

export const PaymentIconsContainer = styled.div`
  ${(props) => css`
    align-items: center;
    column-gap: ${props.theme.tokens.alias.space['space-xs-8']}px;
    display: flex;
    max-height: 1.25rem;
  `}
`

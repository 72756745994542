import { createSelector } from '@reduxjs/toolkit'
import {
  getSportsIdsOrder,
  selectSports,
  selectSportsList
} from 'astra-core/containers/CommonDataProvider'
import {
  getSportCountersByTab,
  SportListType
} from 'astra-core/containers/SportCountersProvider'
import { Sport } from 'betweb-openapi-axios'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { getValues } from 'astra-core'

//  TODO replace after CORE implementation
export const selectSportsCountersListCurrent = createSelector(
  [selectSportsList, getSportCountersByTab],
  (sportsList, sportCountersByTab) =>
    sportsList
      .filter((sport) => !!sport)
      .map((sport: Sport) => ({
        ...sport,
        count: sportCountersByTab[sport.id]?.count
      }))
      .filter(({ count }) => count) as SportListType
)

export const selectSportsCountersListCurrentNew = createSelector(
  [selectSports, getSportCountersByTab, getSportsIdsOrder],
  (sports, sportCountersByTab, sportsIds) => {
    const sortedSportsDictionary = {}

    Object.keys(sportCountersByTab).forEach((key) => {
      sortedSportsDictionary[key] = sports[key]
    })

    const orderedList = getValues(sportsIds, sortedSportsDictionary)

    return orderedList
      .filter((sport) => !!sport)
      .map((sport: Sport) => ({
        ...sport,
        count: sportCountersByTab[sport.id]?.count
      }))
      .filter(({ count }) => count) as SportListType
  }
)

export const selectLiveSportCountersTotal = createSelector(
  [(state) => getSportCountersByTab(state, ESportsCounterType.LIVE)],
  (sportsList) =>
    Object.values(sportsList).reduce((res, cur) => res + cur.count, 0)
)

import React, { FC, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectMaxBetExpressSystem,
  selectSystemSize
} from 'astra-core/containers/BasketProvider'
import { BasketOutcome } from 'betweb-openapi-axios'

import {
  getSystemMaxWinSum,
  onValidateBasketInputValue,
  useBetErrors
} from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { InputBetSum } from '../../input-bet-sum'

import * as S from './input-bet-sum.styled'

export const InputBetSumSystem: FC<{ outcomes: BasketOutcome[] }> = ({
  outcomes
}) => {
  const dispatch = useAppDispatch()
  const systemSize = useAppSelector(selectSystemSize)
  const maxSystemBet = useAppSelector(selectMaxBetExpressSystem)
  const isBonusWallet = useAppSelector(selectBasketBonusWallet)

  const [inputValue, setInputValue] = useState('')

  const { minAmountError, maxAmountError } = useBetErrors()

  const winSum = useMemo(() => {
    const amount = (inputValue !== '' && inputValue) || 0

    return (
      getSystemMaxWinSum(systemSize, +amount, outcomes) -
      +amount * +isBonusWallet
    )
  }, [inputValue, systemSize, outcomes, isBonusWallet])

  const setOutcomeAmount = useCallback(
    (value: string) => {
      setInputValue(value)
      dispatch(
        basketProviderActions.setSystemAmount({
          amount: onValidateBasketInputValue(value)
        })
      )
    },
    [dispatch]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(basketProviderActions.makeBet())
      }
    },
    [dispatch]
  )

  return (
    <S.StyledInputSum>
      <InputBetSum
        errorMessage={maxAmountError?.message || minAmountError?.message}
        maxValue={maxSystemBet}
        winSum={winSum}
        onChange={setOutcomeAmount}
        onKeyDown={handleKeyDown}
      />
    </S.StyledInputSum>
  )
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const Step = styled.div`
  min-width: 20rem;
`

export const StepText = styled.div`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
    ${typography['Hint / 10 BOLD']};
  `}
`

export const TitleText = styled.div`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    ${typography['Body / 16 Bold']};
  `}
`

export const DescriptionText = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    margin: ${theme.tokens.alias.space['space-xl-24']}px 0;
  `}
`

export const ControlButtonsContainer = styled.div`
  ${({ theme }) => css`
    column-gap: ${theme.tokens.alias.space['space-xs-8']}px;
    display: flex;
    flex-direction: row;
    margin-top: ${theme.tokens.alias.space['space-xl-24']}px;

    & > * {
      flex-basis: 100%;
    }
  `}
`

export const InfoText = styled.div`
  ${({ theme }) => css`
    ${typography['Body / 12 Medium']};
    color: ${theme.tokens.colors.text['text-description']};
    margin-top: 0.5rem;
    max-width: 20rem;
  `}
`

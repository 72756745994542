import {
  favouritesProviderActions,
  selectFavouriteEvents,
  selectFavouriteEventsCount
} from 'astra-core/containers/FavouritesProvider'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import { ERoutes } from 'shared/types/routes'
import { IconStar } from 'shared/ui/Icon/General/IconStar'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { HIDDEN_FAVORITES_STATUSES } from 'entities/event'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { useHandleRedirectList } from '../../hooks'

export const FavouritesButton = () => {
  const handleRedirectList = useHandleRedirectList()
  const favouriteEventsCount = useAppSelector(selectFavouriteEventsCount)

  const location = useLocation()

  const events = useAppSelector(selectFavouriteEvents)
  const dispatch = useAppDispatch()

  const hiddenEvents = events.filter((event) =>
    HIDDEN_FAVORITES_STATUSES.includes(event.status)
  )

  useEffect(() => {
    hiddenEvents.forEach((event) => {
      dispatch(
        favouritesProviderActions.deleteFavouriteEvent({ eventId: event.id })
      )
    })
  }, [dispatch, hiddenEvents])

  return (
    <IconButton
      counter={favouriteEventsCount || null}
      icon={IconStar}
      iconProps={{ size: 24 }}
      isActive={location.pathname === ERoutes.FavouriteEvents}
      view="ghost-l"
      onClick={handleRedirectList(ERoutes.FavouriteEvents)}
    />
  )
}

import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export const GlobalSearchContext = createContext<{
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isFocused: boolean
  setIsFocused: Dispatch<SetStateAction<boolean>>
}>({
  isOpen: false,
  setIsOpen: () => {},
  isFocused: false,
  setIsFocused: () => {}
})

export const useGlobalSearchContext = () => useContext(GlobalSearchContext)

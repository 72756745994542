import { useCallback } from 'react'
import { useConfigProvider } from 'astra-core/containers/ConfigProvider'
import { useBasketProvider } from 'astra-core/containers/BasketProvider'
import { useBetsHistoryProvider } from 'astra-core/containers/BetsHistoryProvider'
import { useEventsProvider } from 'astra-core/containers/EventsProvider'
import { useFavouritesProvider } from 'astra-core/containers/FavouritesProvider'
import { useResultsProvider } from 'astra-core/containers/ResultsProvider'
import { useSearchProvider } from 'astra-core/containers/SearchProvider'
import { useSettingsProvider } from 'astra-core/containers/SettingsProvider'
import { usePlayersProvider } from 'astra-core/containers/PlayersProvider'
import { useGameVendorSettingsProvider } from 'astra-core/containers/GameVendorSettingsProvider'
import { useLanguagesProvider } from 'astra-core/containers/LanguagesProvider'
import { useCommonDataProvider } from 'astra-core/containers/CommonDataProvider'
import { useSportCountersProvider } from 'astra-core/containers/SportCountersProvider'
import { useBroadcastProvider } from 'astra-core/containers/BroadcastProvider/useBroadcastProvider'
import { useVirtualGamesProvider } from 'astra-core/containers/VirtualGamesProvider/useVirtualGamesProvider'
import { useMessagesProvider } from 'astra-core/containers/MessagesProvider'

import { matchBroadcastModel } from 'entities/match-broadcast'
import { useLayoutContainer } from 'containers/LayoutContainer'
import { useOutcomesContainer } from 'containers/OutcomesContainer'
import { matchTrackerModel } from 'entities/match-tracker'
import { useLogosProvider } from 'entities/logos'
import { useLocalStorageProvider } from 'containers/StorageProvider'
import { useSearchTournamentProvider } from 'entities/search-tournament'
import { paymentModel } from 'entities/payment'
import { transactionHistoryModel } from 'entities/transaction-history'
import { identificationModel } from 'entities/constration'

import { useBalanceProvider } from './use-balance-provider'
import { useAccountProvider } from './use-account-provider'

export const useProviders = () => {
  /**
   * It is required to memoize the function passed to the provider
   * to avoid unnecessary re-creations of the EventSource
   */
  const getEventSource = useCallback(
    (url, options: EventSourceInit) => new EventSource(url, options),
    []
  ) as Parameters<typeof useAccountProvider>[0]['getEventSource']

  useLocalStorageProvider()
  useConfigProvider()
  useLanguagesProvider()
  useCommonDataProvider()
  useSportCountersProvider()
  useEventsProvider()
  useAccountProvider({ getEventSource })
  useBalanceProvider()
  useBasketProvider()
  useBetsHistoryProvider()
  useFavouritesProvider()
  useResultsProvider()
  matchTrackerModel.useLiveMatchTracker()
  matchBroadcastModel.useLiveMatchBroadcast()
  useLayoutContainer()
  useOutcomesContainer()
  useSearchProvider()
  useSettingsProvider()
  usePlayersProvider()
  useGameVendorSettingsProvider()
  useLogosProvider()
  useBroadcastProvider()
  useVirtualGamesProvider()
  useSearchTournamentProvider()
  paymentModel.useProvider()
  transactionHistoryModel.useProvider()
  useMessagesProvider()
  identificationModel.useProvider()
}

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconPhoneFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7017 5.25C12.2762 6.01 12.5709 6.87 12.6109 7.815C12.6109 7.885 12.6708 7.94 12.7358 7.94H13.8748C13.9448 7.94 14.0047 7.88 13.9997 7.81C13.9747 7.09 13.8299 6.395 13.5551 5.73C13.1954 4.855 12.6559 4.1 11.9415 3.48C11.4219 3.025 10.8374 2.67 10.193 2.415C9.55852 2.16 8.89409 2.025 8.21467 2C8.14473 2 8.08478 2.055 8.08478 2.13V3.265C8.08478 3.335 8.13973 3.39 8.20468 3.39C8.25963 3.39 8.31458 3.395 8.36454 3.4C9.74336 3.53 10.8624 4.145 11.6967 5.25H11.7017Z"
        fill="currentColor"
      />
      <path
        d="M11.2221 7.94C11.317 7.94 11.3969 7.855 11.387 7.76C11.302 6.96 10.9723 6.26 10.3978 5.665C9.79332 5.08 9.08393 4.745 8.26463 4.67C8.16971 4.66 8.08478 4.74 8.08478 4.835V5.785C8.08478 5.86 8.13474 5.925 8.20468 5.94C8.47445 6.005 9.06394 6.17 9.3437 6.4C9.79332 6.77 10.0481 7.24 10.123 7.795C10.133 7.875 10.198 7.935 10.2829 7.935H11.2221V7.94Z"
        fill="currentColor"
      />
      <path
        d="M4.98244 8.165C4.86255 8.045 4.86255 7.85 4.98244 7.73L6.4262 6.285C6.62603 6.085 6.62603 5.765 6.4262 5.565L4.17813 3.31C3.9783 3.11 3.65858 3.11 3.45875 3.31L2.40965 4.36C2.14488 4.625 2 4.98 2 5.35V7.57C2 7.94 2.14987 8.3 2.40965 8.56L7.43534 13.59C7.70011 13.855 8.05481 14 8.42449 14H10.6426C11.0123 14 11.372 13.85 11.6317 13.59L12.6708 12.55C12.8707 12.35 12.8707 12.02 12.6708 11.82L10.4328 9.58C10.2329 9.38 9.90322 9.38 9.70339 9.58L8.26463 11.02C8.14473 11.14 7.9499 11.14 7.83 11.02L4.98244 8.17V8.165Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M17.6763 1.62481C16.7046 2.16878 14.3889 5.305 12.2514 8.97196C9.17034 14.2576 6.90188 16.8551 4.75118 17.5598C3.90387 17.7809 3.71189 18.3403 3.75584 19.393C3.78949 20.1991 4.0023 21.0505 4.66261 21.696C5.61874 22.6308 7.12445 22.6748 8.04733 22.2142C8.80597 21.8356 9.34553 21.0523 9.73722 19.7973C11.4129 14.427 12.1544 12.6683 14.1917 9.23139C16.2677 5.72956 17.7562 3.90536 18.5375 3.90536C19.2591 3.90536 19.7187 3.48368 19.7187 2.82156C19.7187 2.45619 19.3204 1.98696 18.7786 1.71428C18.268 1.45727 18.0127 1.43654 17.6763 1.62481Z"
        fill={theme.tokens.colors['sport-icon']['sport-hurling']}
      />
      <path
        d="M20.5 18.25C20.5 19.4926 19.4926 20.5 18.25 20.5C17.0074 20.5 16 19.4926 16 18.25C16 17.0074 17.0074 16 18.25 16C19.4926 16 20.5 17.0074 20.5 18.25Z"
        fill={theme.tokens.colors['sport-icon']['sport-hurling']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M12.2296 0.0950929C11.4893 0.509545 9.72495 2.89905 8.09637 5.69292C5.74892 9.72005 4.02057 11.6991 2.38194 12.2361C1.73637 12.4045 1.5901 12.8307 1.62359 13.6328C1.64923 14.2469 1.81137 14.8956 2.31446 15.3874C3.04294 16.0996 4.19015 16.1332 4.89329 15.7822C5.47131 15.4938 5.8824 14.897 6.18083 13.9408C7.45753 9.84911 8.02247 8.50916 9.57475 5.89058C11.1565 3.22252 12.2905 1.83265 12.8858 1.83265C13.4356 1.83265 13.7858 1.51137 13.7858 1.00691C13.7858 0.728528 13.4823 0.371014 13.0695 0.163262C12.6805 -0.0325592 12.486 -0.0483512 12.2296 0.0950929Z"
        fill={theme.tokens.colors['sport-icon']['sport-hurling']}
      />
      <path
        d="M14.381 12.7617C14.381 13.7085 13.6135 14.476 12.6667 14.476C11.72 14.476 10.9525 13.7085 10.9525 12.7617C10.9525 11.8149 11.72 11.0474 12.6667 11.0474C13.6135 11.0474 14.381 11.8149 14.381 12.7617Z"
        fill={theme.tokens.colors['sport-icon']['sport-hurling']}
      />
    </>
  )
}

export const IconSportHurling: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

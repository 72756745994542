import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCheckCircleFilled12: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.66639 0C8.19683 0 8.69725 0.210175 9.07756 0.590492L11.4095 2.93244C11.7898 3.30275 12 3.81318 12 4.34362V7.65638C12 8.18682 11.7898 8.68724 11.4095 9.06756L9.06756 11.4095C8.69725 11.7898 8.18682 12 7.65638 12H4.34362C3.81318 12 3.31276 11.7898 2.93244 11.4095L0.590492 9.06756C0.210175 8.69725 0 8.18682 0 7.65638V4.34362C0 3.81318 0.210175 3.31276 0.590492 2.93244L2.94245 0.590492C3.31276 0.210175 3.82319 0 4.35363 0H7.66639ZM8.82 4.22004C9.07 4.49004 9.05 4.91004 8.78 5.16004L5.86 7.83004C5.6 8.06004 5.21 8.06004 4.96 7.83004L3.21 6.23004C2.94 5.98004 2.92 5.56004 3.17 5.29004C3.42 5.02004 3.84 5.00004 4.11 5.25004L5.41 6.44004L7.88 4.18004C8.15 3.93004 8.57 3.95004 8.82 4.22004Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M7.95052 14.016C6.80192 10.5926 6.54318 7.33687 7.19679 4.37475C6.02569 5.11838 5.00084 6.14325 4.24598 7.41562C2.71489 10.029 2.63614 13.0923 3.78474 15.6608L3.88599 15.6383C5.49583 15.1882 6.84692 14.6134 7.96177 14.0385C7.95052 14.0284 7.95052 14.0283 7.95052 14.016ZM11.4976 3C10.4615 3.05625 9.46024 3.28125 8.51414 3.68737L8.42414 4.0485C7.67041 6.92063 7.86165 10.0864 8.96413 13.476C10.1015 12.7887 10.8777 12.1586 11.3176 11.8088C11.3513 11.7863 11.3626 11.775 11.3963 11.7525C11.3851 11.7075 11.3738 11.6625 11.3626 11.6063C11.1151 10.389 10.4615 7.19063 11.4976 3ZM12.2524 12.6874C12.1962 12.6649 12.1512 12.6536 12.0949 12.6311C12.0724 12.6536 12.0612 12.6649 12.0274 12.6874C11.0701 13.4535 8.57039 15.4808 4.32585 16.6868C4.82084 17.5092 5.4632 18.264 6.22819 18.9053L6.63318 18.7928C9.56036 17.9817 12.3863 16.2592 14.8522 13.8146C13.7047 13.2172 12.7812 12.8787 12.2524 12.6874ZM16.5757 4.2285C15.3371 3.49612 13.9972 3.10125 12.6574 3L12.6237 3.12375C12.2299 4.656 12.0837 6.04088 12.0612 7.24688C16.4744 8.29425 19.3116 10.209 21.0002 11.7638C20.9327 8.75663 19.3454 5.86088 16.5757 4.2285ZM19.3004 17.0805C18.1068 15.9093 16.9357 15.042 15.8669 14.3884C13.356 16.968 10.4401 18.804 7.40041 19.7277C7.41166 19.7389 7.42291 19.7388 7.43416 19.7501C8.86288 20.595 10.439 21 11.9937 21C14.8534 21 17.6456 19.6377 19.3791 17.1593L19.3004 17.0805ZM20.6065 12.9349C19.1204 11.4589 16.4294 9.5115 12.0724 8.418C12.1399 9.78038 12.3537 10.8176 12.4662 11.3801C12.4887 11.4589 12.4999 11.5151 12.5112 11.5826C12.5449 11.5939 12.5899 11.6051 12.6349 11.6276C13.7947 12.045 16.8457 13.1374 19.9753 16.167C20.4703 15.2322 20.7865 14.241 20.9215 13.2499L20.6065 12.9349Z"
        fill={theme.tokens.colors['sport-icon']['sport-volleyball']}
      />
      <path
        d="M2 20.9001C2 20.6792 2.18606 20.5003 2.40695 20.4973C11.8681 20.3654 12.1319 15.6347 21.593 15.5028C21.8139 15.4997 22 15.6791 22 15.9V22.1C22 22.3209 21.8209 22.5 21.6 22.5H2.4C2.17909 22.5 2 22.3209 2 22.1V20.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sand']}
      />
      <path
        d="M22 20.9001C22 20.6792 21.8139 20.5003 21.593 20.4973C12.1319 20.3654 11.8681 15.6347 2.40695 15.5028C2.18606 15.4997 2 15.6791 2 15.9V22.1C2 22.3209 2.17908 22.5 2.4 22.5H21.6C21.8209 22.5 22 22.3209 22 22.1V20.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sandDarker']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M4.85034 9.568C3.95698 6.90537 3.75574 4.37312 4.2641 2.06925C3.35325 2.64762 2.55614 3.44475 1.96903 4.43437C0.778177 6.467 0.716929 8.84959 1.61028 10.8472L1.68903 10.8297C2.94113 10.4797 3.99198 10.0327 4.85909 9.5855C4.85034 9.57766 4.85034 9.57759 4.85034 9.568ZM7.60915 1C6.8033 1.04375 6.02456 1.21875 5.2887 1.53462L5.21871 1.8155C4.63247 4.04938 4.78122 6.51163 5.6387 9.148C6.5233 8.61341 7.12704 8.12334 7.46916 7.85125C7.49541 7.83375 7.50416 7.825 7.53041 7.8075C7.52166 7.7725 7.51291 7.7375 7.50416 7.69375C7.31166 6.747 6.8033 4.25937 7.60915 1ZM8.19627 8.53466C8.15252 8.51716 8.11752 8.50834 8.07377 8.49084C8.05627 8.50834 8.04753 8.51716 8.02128 8.53466C7.27666 9.1305 5.33245 10.7072 2.03115 11.6453C2.41614 12.2849 2.91576 12.872 3.51074 13.3708L3.82574 13.2832C6.10244 12.6524 8.30036 11.3127 10.2183 9.41134C9.32584 8.94675 8.60751 8.68341 8.19627 8.53466ZM11.5588 1.9555C10.5955 1.38587 9.55333 1.07875 8.51126 1L8.48502 1.09625C8.17877 2.288 8.06503 3.36513 8.04753 4.30313C11.48 5.11775 13.6867 6.607 15.0001 7.81625C14.9476 5.47738 13.713 3.22513 11.5588 1.9555ZM13.678 11.9515C12.7497 11.0406 11.8388 10.366 11.0075 9.85766C9.05459 11.864 6.78667 13.292 4.42247 14.0104C4.43122 14.0192 4.43997 14.0191 4.44872 14.0278C5.55995 14.685 6.7858 15 7.99502 15C10.2192 15 12.3909 13.9404 13.7392 12.0128L13.678 11.9515ZM14.6939 8.72716C13.538 7.57913 11.445 6.0645 8.05628 5.214C8.10877 6.27362 8.27502 7.08037 8.36252 7.51787C8.38002 7.57912 8.38877 7.62288 8.39752 7.67537C8.42377 7.68412 8.45877 7.69287 8.49377 7.71037C9.39584 8.035 11.7688 8.88466 14.203 11.241C14.588 10.5139 14.8339 9.743 14.9389 8.97216L14.6939 8.72716Z"
        fill={theme.tokens.colors['sport-icon']['sport-volleyball']}
      />
      <path
        d="M0.00012207 14.8001C0.00012207 14.5792 0.184805 14.4004 0.405685 14.3966C7.86961 14.2661 8.13064 10.534 15.5946 10.4035C15.8154 10.3997 16.0001 10.5791 16.0001 10.8V15.6C16.0001 15.8209 15.821 16 15.6001 16H0.400122C0.179208 16 0.00012207 15.8209 0.00012207 15.6V14.8001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sand']}
      />
      <path
        d="M16.0001 14.8001C16.0001 14.5792 15.8154 14.4004 15.5946 14.3966C8.13064 14.2661 7.86961 10.534 0.405684 10.4035C0.184804 10.3997 0.00012207 10.5791 0.00012207 10.8V15.6C0.00012207 15.8209 0.179208 16 0.400122 16H15.6001C15.821 16 16.0001 15.8209 16.0001 15.6V14.8001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sandDarker']}
      />
    </>
  )
}

export const IconSportBeachVolleyball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

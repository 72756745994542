import { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconWarningTriangle: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M7.52596 2H8.47404C8.769 2 9.03236 2.15333 9.17984 2.41618L14.8894 12.711C15.0369 12.9739 15.0369 13.2915 14.8894 13.5543L14.3311 14.5729C14.1836 14.8357 13.9202 15 13.6253 15H2.37472C2.07976 15 1.8164 14.8357 1.66892 14.5729L1.11061 13.5543C0.96313 13.2915 0.96313 12.9739 1.11061 12.711L6.82017 2.41618C6.96765 2.16428 7.24154 2 7.52596 2ZM8 5C7.44772 5 7 5.44772 7 6V9C7 9.55228 7.44772 10 8 10C8.55228 10 9 9.55228 9 9V6C9 5.44772 8.55228 5 8 5ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { typography } from 'shared/lib/theme/typography'

export const StyledDatePicker = styled.div<{ isTodayActive: boolean }>`
  .react-datepicker {
    border: none;
    padding: 16px;
    border-radius: 8px;

    ${({ theme }) => css`
      background: ${theme.tokens.colors.surface['surface-modal']};

      ${theme.typeLayoutTheme === LayoutTheme.Light &&
      css`
        box-shadow: 0px 4px 8px 2px #6c79931f, 0px 2px 4px 0px #6c799314;
      `}

      ${theme.typeLayoutTheme === LayoutTheme.Dark &&
      css`
        box-shadow: 0px 4px 8px 2px #00000029, 0px 2px 4px 0px #0000001f;
      `}
    `};
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__header {
    background: transparent;
    border-bottom: none;
    padding-bottom: 0px;
  }

  .react-datepicker__navigation {
    width: unset;
    ${({ theme }) => css`
      & svg {
        color: ${theme.tokens.colors.icon['icon-secondary-3']};
      }
    `};

    &--previous {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  .react-datepicker-popper {
    z-index: 99;
    width: max-content;
  }

  .react-datepicker__close-icon {
    display: none;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    ${typography['Body / 12 Medium']}
    margin: 0px 2px;
    padding: 6px 0;
    width: 32px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  .react-datepicker__day {
    transition: all 0.3s;
    outline: none !important;
    background: unset;

    ${({ theme }) => css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px !important;
      color: ${theme.tokens.colors.text['text-primary']};
    `};

    &:hover,
    &--keyboard-selected,
    &--selected {
      ${({ theme }) => css`
        background: ${theme.tokens.colors.surface['surface-hover-light']};
      `};
    }

    &-names {
      margin-top: 6px;
      margin-bottom: 0px;
    }

    &-name {
      text-transform: uppercase;
      ${({ theme }) => css`
        color: ${theme.tokens.colors.text['text-disabled']};
      `};
    }

    &--today {
      background: unset;
      ${({ theme, isTodayActive }) =>
        isTodayActive &&
        css`
          background: ${theme.tokens.colors.surface[
            'surface-hover-light'
          ]} !important;
        `};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 4px;
  }

  .react-datepicker__week {
    margin-top: 4px;
    &:first-child {
      margin-top: 0px;
    }
  }

  .react-datepicker__month {
    ${typography['Body / 14 Medium']}
    margin: 0;
    ${({ theme }) => css`
      color: ${theme.tokens.colors.text['text-primary']};
    `};
  }

  .react-datepicker__current-month {
    padding: 6px 0px;
    ${({ theme }) => css`
      color: ${theme.tokens.colors.text['text-primary']};
    `};
  }

  .react-datepicker__day--in-range {
    background: unset;
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      z-index: -1;
      transition: all 0.3s;
    }

    &:hover::before {
      ${({ theme }) => css`
        background: ${theme.tokens.colors.surface['surface-counter']};
      `};
    }

    &::after {
      z-index: -2;
      content: '';
      position: absolute;
      top: 0;
      left: -2px;
      right: -2px;
      bottom: 0;
      border-radius: 0;
      ${({ theme }) => css`
        background: ${theme.tokens.colors.surface['surface-disabled']};
      `};
    }
  }

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--selecting-range-start
    ) {
    background: none !important;
    position: relative;
    z-index: 2;

    &::after {
      z-index: -2;
      content: '';
      position: absolute;
      top: 0;
      left: -2px;
      right: -2px;
      bottom: 0;
      border-radius: 0;
      ${({ theme }) => css`
        background: ${theme.tokens.colors.surface['surface-disabled']};
      `};
    }
  }

  .react-datepicker__day--selecting-range-start {
    position: relative;
    z-index: 2;
    ${({ theme }) => css`
      background: ${theme.tokens.colors.surface['surface-counter']} !important;
    `};

    &::after {
      z-index: -2;
      content: '';
      position: absolute;
      top: 0;
      left: 0px;
      right: -2px;
      bottom: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      ${({ theme }) => css`
        background: ${theme.tokens.colors.surface['surface-disabled']};
      `};
    }

    &.react-datepicker__day--selecting-range-end::after,
    &:hover::after {
      right: 0px;
      border-radius: 8px;
    }
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-start:hover {
    ${({ theme }) => css`
      &::before {
        background: ${theme.tokens.colors.surface[
          'surface-counter'
        ]} !important;
      }
    `};

    &::after {
      left: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .react-datepicker__day--selecting-range-end::after {
    right: 0 !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--range-end:hover {
    ${({ theme }) => css`
      &::before {
        background: ${theme.tokens.colors.surface[
          'surface-counter'
        ]} !important;
      }
    `};
    &::after {
      right: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .react-datepicker__day.react-datepicker__day--disabled[aria-disabled='true'] {
    ${({ theme }) => css`
      color: ${theme.tokens.colors.text['text-disabled']};
    `};

    &:hover {
      background: none;
    }
  }
`

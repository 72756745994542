import styled, { css } from 'styled-components'

export const Content = styled.div<{
  hideHeader: boolean
  isOverflowHidden: boolean
}>`
  display: grid;
  grid-area: content;
  grid-template-rows: 1fr auto;
  min-height: 100%;

  ${({ isOverflowHidden }) =>
    isOverflowHidden &&
    css`
      overflow-y: hidden;
    `}

  ${(p) =>
    !p.hideHeader &&
    css`
      padding-top: 48px;
    `}
`

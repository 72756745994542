import { broadcastProviderActions } from 'astra-core/containers/BroadcastProvider'
import { useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useInterval } from 'astra-core'
import isEqual from 'react-fast-compare'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { LiveEventsParams } from 'entities/live/model/types'
import {
  BROADCAST_CHUNK_SIZE,
  BROADCAST_POLLING_INTERVAL
} from 'entities/live/model/utils'
import { getMainPageLiveEventsIds } from 'pages/page-main/MainPageContainer/selectors'
import { ERoutes } from 'shared/types/routes'
import { getLiveEventsIdsBySport } from 'entities/live/model/selectors'
import { selectCalendarEventsIds } from 'containers/CalendarEventsContainer'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const TIME_DATA_FORMAT = 'HH:mm'

const getEventIdsSelector = (pathname: string, sportId: string) => {
  if (pathname === ERoutes.Root) {
    return getMainPageLiveEventsIds
  } else if (pathname === ERoutes.Calendar) {
    return selectCalendarEventsIds
  } else {
    return (state) => getLiveEventsIdsBySport(state, { sportId })
  }
}

export function useFetchBroadcastsAvailability() {
  const { pathname } = useLocation()
  const { sportId } = useParams<LiveEventsParams>()
  const dispatch = useAppDispatch()
  const isBroadcastFeatureFlagEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )

  const eventIds = useAppSelector(
    getEventIdsSelector(pathname, sportId),
    isEqual
  )

  const fetchBroadcastsAvailability = useCallback(() => {
    if (isBroadcastFeatureFlagEnabled && eventIds.length > 0) {
      for (let i = 0; i < eventIds.length; i += BROADCAST_CHUNK_SIZE) {
        dispatch(
          broadcastProviderActions.fetchBroadcastsAvailability({
            reqData: {
              eventIds: eventIds.slice(i, i + BROADCAST_CHUNK_SIZE),
              platform: 'Desktop' // Desktop, Mobile, Android, IOS
            }
          })
        )
      }
    }
  }, [dispatch, eventIds, isBroadcastFeatureFlagEnabled])

  useEffect(() => {
    fetchBroadcastsAvailability()
  }, [fetchBroadcastsAvailability])

  useInterval(fetchBroadcastsAvailability, BROADCAST_POLLING_INTERVAL, false)

  return null
}

import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import { getEvents } from 'astra-core/containers/EventsProvider'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import isEqual from 'lodash/isEqual'
import { selectBroadcastsAvailabilityMapping } from 'astra-core/containers/BroadcastProvider'
import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'
import { getIsBroadcastFilterButtonEnabled } from 'shared/lib/events'
import { getEventsBySearch } from 'entities/event/lib/get-events-by-search'

import { initialState } from './slice'
import { getFilteredEventsBroadcast } from './utils'

type SelectLiveEventsParams = {
  sportId: string
}

const EMPTY_ARRAY = []

export const getLiveEventsIdsBySport = (
  state: RootState,
  params: SelectLiveEventsParams
) =>
  (state.liveContainer.eventsIdsBySport || initialState.eventsIdsBySport)[
    +params.sportId // TODO either add sportIdPrev here or add sceleton preloader to UI
  ] || EMPTY_ARRAY

export const getLiveFetchItems = (state: RootState) =>
  state.liveContainer.fetchItems || initialState.fetchItems

export const getLiveFilters = (state: RootState) =>
  state.liveContainer.filters ?? initialState.filters

export const getFilterIsTop = (state: RootState) =>
  state.liveContainer?.filters?.isTop ?? initialState.filters.isTop

export const getFilterIsBroadcast = (state: RootState) =>
  state.liveContainer?.filters?.isBroadcast ?? initialState.filters.isTop

export const getLayoutIsLoading = (state: RootState) =>
  state.liveContainer.layouts.isLoading ?? initialState.layouts.isLoading

export const selectLiveEvents = createSelector(
  [
    getLiveEventsIdsBySport,
    getEvents,
    getFilterIsBroadcast,
    selectBroadcastsAvailabilityMapping
  ],
  (eventsIdsBySport, events, isBroadcast, broadcasts) => {
    const filteredEvents: Dictionary<Event> = isBroadcast
      ? getFilteredEventsBroadcast(events, broadcasts)
      : events

    return getValues(eventsIdsBySport, filteredEvents)
  }
)

export const selectLiveEventsBySearch = createSelector(
  [selectLiveEvents, selectInputSearchLine],
  (liveEvents, searchText) =>
    getEventsBySearch({
      events: liveEvents,
      searchText
    })
)

export const selectIsExistBroadcastForCurrentEvents = createSelector(
  [selectLiveEventsBySearch, selectBroadcastsAvailabilityMapping],
  (events, broadcastsMap) =>
    getIsBroadcastFilterButtonEnabled({
      eventsIdsBySport: events.map((e) => e.id),
      broadcastsMap
    })
)

export const selectLiveIsEmpty = createSelector([getEvents], (events) =>
  isEmpty(events)
)

export const selectLiveFetchItems = createSelector(
  [getLiveFetchItems],
  (liveFetchItems) => liveFetchItems
)

export const selectFilterIsTop = createSelector(
  [getFilterIsTop],
  (isTop) => isTop
)

export const selectLayoutIsLoading = createSelector(
  [getLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectIsLiveFilterUsed = createSelector(
  [getLiveFilters],
  (filters) => !isEqual(filters, initialState.filters)
)

import React, { FC, useCallback } from 'react'

import {
  SegmentedControlItem,
  SegmentedControlProps
} from './segmented-control.types'
import * as S from './segmented-control.styled'

export const SegmentedControl: FC<SegmentedControlProps> = ({
  items,
  onChange,
  value,
  fullWidth,
  equalItems,
  view = 'primary',
  itemSize = 28
}) => {
  const handleItemClick = useCallback(
    (item: SegmentedControlItem) => () => {
      if (item.disabled) {
        return
      }
      onChange(item.value)
    },
    [onChange]
  )

  return (
    <S.StyledSegmentedControl
      equalItems={equalItems}
      fullWidth={fullWidth}
      view={view}
    >
      {items.map((item) => (
        <S.StyledSegmentedControlItem
          disabled={item.disabled}
          isActive={item.value === value}
          itemSize={itemSize}
          key={item.value}
          view={view}
          onClick={handleItemClick(item)}
        >
          {item.label}
        </S.StyledSegmentedControlItem>
      ))}
    </S.StyledSegmentedControl>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M12.1773 4.55423C11.1806 5.29897 10.7692 5.38041 9.52469 5.05294C9.19067 4.96505 8.80628 4.90289 8.48446 5.05292C8.23768 5.16795 7.96211 5.36857 7.65884 5.72351C7.19607 6.26513 6.99189 6.42992 6.60272 6.55247C6.15274 6.69416 5.91072 6.70648 5.38551 6.55247C5.11855 6.47419 4.37776 6.52811 3.94079 7.09607C3.55085 7.60289 3.18054 9.19122 3.06802 10.115C2.91645 11.3577 3.02349 13.4932 3.33625 14.7107C3.47939 15.2679 3.6911 15.9369 3.99552 16.2316C4.36572 16.5899 4.58253 16.6224 4.97903 16.6816L4.99219 16.6836C5.30241 16.73 5.60549 16.7049 5.73828 16.6133C5.87107 16.5216 6 16.378 6 16.1758V10.5C6 10.2239 6.22386 9.99999 6.5 9.99999C6.77614 9.99999 7 10.2239 7 10.5V16.0039C7 16.3126 7.12909 16.5273 7.26128 16.7085C7.54223 17.0937 8.28642 17.5842 8.93316 17.6985C9.56249 17.8098 10.417 17.7459 10.8754 17.2425C10.9518 17.1586 11 17.0352 11 16.8106V9.49999C11 9.22385 11.2239 8.99999 11.5 8.99999C11.7761 8.99999 12 9.22385 12 9.49999V16.9492C12 17.2003 12.1042 17.4568 12.2871 17.6253C12.4903 17.8126 12.7458 18.0035 13.6198 18.0033C14.2803 18.0032 14.7353 17.8604 15.0625 17.6562C15.2383 17.5466 15.5237 17.3072 15.6953 17.0547C15.873 16.7933 16 16.5528 16 16.3005V9.49999C16 9.22385 16.2239 8.99999 16.5 8.99999C16.7761 8.99999 17 9.22385 17 9.49999V16.3865C17 16.5859 17.0991 16.7491 17.2461 16.9297C17.4345 17.1611 17.8615 17.3449 18.2417 17.3573C19.114 17.3859 19.6047 17.2404 20.0401 16.8349C20.9897 15.9505 21.0312 12.8108 20.9897 10.8411C20.9595 9.40335 20.4476 6.46873 20.1898 6.00334C19.8929 5.46736 19.579 5.2194 19.0373 5.0894C18.4329 4.94434 18.0895 5.02365 17.4483 5.17171L17.409 5.18077C16.7135 5.34127 16.3809 5.36091 16.0556 5.28694C15.6191 5.18774 15.2636 4.94466 14.9082 4.70162C14.4655 4.39892 14.0229 4.09629 13.4242 4.07173C12.925 4.05122 12.4946 4.31715 12.1773 4.55423Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
      <path
        d="M17.6764 18.1987C17.4233 18.1287 17.1695 18.0586 16.9097 18.0856C15.6905 18.2126 14.6001 18.8763 14.2671 19.4659C13.9067 20.1039 14.0056 20.7785 14.7178 20.8919C14.7693 20.9 14.8822 20.9082 14.9344 20.9082C16.612 21.0179 18.1294 21.0401 19.7164 20.9082C20.649 20.8307 21.4605 20.3031 21.8079 19.3654C22.054 18.7017 21.9985 17.5069 21.9789 17.0847C21.9758 17.0183 21.9636 16.7773 21.9375 16.6914C21.9348 16.6827 21.9316 16.67 21.9276 16.6542C21.8988 16.5413 21.8295 16.27 21.6431 16.1451C21.5663 16.0936 21.4767 16.1556 21.4461 16.2428C21.2948 16.6733 21.0779 17.1073 20.75 17.4376C20.4318 17.758 20.0638 18.0006 19.6059 18.1451C19.1685 18.2831 18.7011 18.3147 18.2067 18.2972C18.0797 18.2927 17.96 18.2737 17.8485 18.2451C17.7911 18.2304 17.7337 18.2145 17.6764 18.1987Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M8.29592 2.35639C7.56143 2.90519 7.25822 2.96521 6.34113 2.72389C6.09499 2.65912 5.71251 2.61333 5.47535 2.72389C5.29349 2.80866 4.99279 2.95896 4.76931 3.22052C4.42828 3.61966 4.12912 3.73862 3.84233 3.82892C3.51074 3.93334 3.18744 3.94241 2.80041 3.82892C2.60368 3.77124 2.05778 3.81097 1.73576 4.22951C1.44841 4.603 1.17553 5.77347 1.09261 6.45422C0.980911 7.37002 1.05979 8.94368 1.29027 9.84086C1.39575 10.2515 1.55177 10.7445 1.7761 10.9616C2.05191 11.2286 2.44341 11.2935 2.51056 11.2947C2.51787 11.2949 2.52646 11.2952 2.53607 11.2955C2.61472 11.2981 2.76242 11.3031 2.84962 11.2429C2.94747 11.1754 3.03125 11.0625 3.03125 10.9135V6.73093C3.03125 6.44915 3.26563 6.2304 3.53125 6.2304C3.8125 6.2304 4.03125 6.44915 4.03125 6.73093V10.7869C4.03125 11.0144 4.21579 11.2298 4.42019 11.4495C4.71658 11.7681 5.33874 11.9272 5.81534 12.0115C6.27911 12.0935 6.60336 12.0366 6.94118 11.6656C6.99746 11.6038 7.03297 11.5129 7.03297 11.3474V5.96005C7.03297 5.69052 7.18922 5.46786 7.53297 5.46786C7.83375 5.46786 8.03297 5.65536 8.03297 5.96005V11.4495C8.03297 11.6346 8.14902 11.8478 8.28381 11.972C8.43359 12.11 8.71481 12.2674 9.3589 12.2673C9.84563 12.2672 10.1809 12.1619 10.422 12.0115C10.5516 11.9307 10.682 11.7543 10.8084 11.5682C10.9394 11.3756 11.033 11.0963 11.033 10.9104L11.033 6.00101C11.033 5.67653 11.2328 5.44672 11.5324 5.44672C11.832 5.44672 12.033 5.68885 12.033 6.00101L12.033 10.9969C12.033 11.1439 12.106 11.343 12.2144 11.4761C12.3532 11.6466 12.4847 11.782 12.7649 11.7912C13.4077 11.8123 13.7693 11.7051 14.0901 11.4062C14.7899 10.7545 14.8205 8.44081 14.7899 6.98927C14.7676 5.92979 14.3904 3.76722 14.2004 3.42426C13.9816 3.02929 13.7503 2.84656 13.3511 2.75076C12.9058 2.64386 12.6527 2.7023 12.1802 2.81141L12.1513 2.81809C11.6387 2.93637 11.3936 2.95084 11.1538 2.89633C10.8323 2.82322 10.5703 2.6441 10.3083 2.46499C9.98211 2.24193 9.65595 2.01892 9.21476 2.00082C8.84692 1.98571 8.52973 2.18167 8.29592 2.35639Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
      <path
        d="M11.8762 12.4666C11.9821 12.4598 12.0834 12.4943 12.1824 12.5325C12.3535 12.5986 12.5373 12.6348 12.7318 12.6412C13.5215 12.667 14.1855 12.5341 14.7713 11.9885C14.9771 11.7968 15.1297 11.5655 15.2449 11.3304C15.2953 11.2277 15.4271 11.2147 15.4609 11.324C15.4706 11.3552 15.4776 11.3827 15.4828 11.4031C15.4858 11.4147 15.4881 11.4241 15.4901 11.4305C15.5093 11.4938 15.5183 11.6714 15.5206 11.7203C15.5351 12.0315 15.576 12.9119 15.3946 13.4009C15.1386 14.092 14.5406 14.4807 13.8534 14.5378C12.684 14.635 11.5658 14.6187 10.3295 14.5378C10.291 14.5378 10.2079 14.5318 10.1699 14.5258C9.64508 14.4423 9.57223 13.9451 9.83779 13.475C10.0832 13.0406 10.7836 12.6357 11.536 12.5076C11.6505 12.4881 11.7758 12.473 11.8762 12.4666Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
    </>
  )
}

export const IconSportFistFight: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import loadable from '@loadable/component'

import { fallback } from './fallback'

export const GamesCyberbetPage = loadable(
  () => import('../page-games-cyberbet/ui'),
  {
    resolveComponent: (components) => components.PageGamesCyberbet,
    fallback
  }
)

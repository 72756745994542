import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { basketProviderActions } from 'astra-core/containers/BasketProvider/slice'
import { accountProviderActions } from 'astra-core/containers/AccountProvider/slice'

import { RootState } from 'shared/types/store'
import { useAppSelector } from 'shared/lib/@reduxjs'

const API_NAME = 'kzapi'

export const baseLegacyApi = createApi({
  baseQuery: (args, api, extraOptions) => {
    const state = api.getState() as RootState
    const { API_URL, isDevelopment } = state.configProvider.config

    const baseUrl = isDevelopment ? `/${API_NAME}` : `${API_URL}/${API_NAME}`

    return fetchBaseQuery({
      baseUrl,
      credentials: 'include',
      responseHandler: (response) => {
        if (response.status === 401) {
          api.dispatch(basketProviderActions.resetState())
          api.dispatch(accountProviderActions.resetUser())
          api.dispatch(accountProviderActions.getUser({}))
        }

        return response.json()
      }
    })(args, api, extraOptions)
  },
  reducerPath: 'legacyApi',
  endpoints: () => ({}),
  tagTypes: [
    'BetsHistory',
    'Notifications',
    'NotificationsCount',
    'TransactionHistory'
  ]
})

export const getLegacyApiUrl = (apiUrl: string) => {
  return `${apiUrl}/${API_NAME}`
}

export const useGetLegacyApiUrl = () => {
  const config = useAppSelector(selectConfig)

  return getLegacyApiUrl(config.API_URL)
}

export const basePromoAPi = createApi({
  reducerPath: 'promoApi',
  baseQuery: fetchBaseQuery({
    baseUrl:
      'https://olimplanding2.xyz/storage/json/export.new.olimpbet.kz.desktop.json'
  }),
  endpoints: (builder) => ({
    getPromotions: builder.query({
      query: () => ''
    })
  })
})

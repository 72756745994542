import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  BalanceOperationsTypes,
  selectActivePaymentTabValue,
  selectPaymentMethodTitle
} from '../../../model'

export const useGetPaymentMethodTitle = (
  balanceOperationType: BalanceOperationsTypes
) => {
  const activePaymentTabValue = useAppSelector((state) =>
    selectActivePaymentTabValue(state, balanceOperationType)
  )

  return useAppSelector((state) =>
    selectPaymentMethodTitle(state, activePaymentTabValue, balanceOperationType)
  )
}

import { useEffect } from 'react'
import { storageProviderActions } from 'astra-core/containers/StorageProvider'

import { useAppDispatch } from 'shared/lib/@reduxjs'

// TODO: used in mobile too. Transfer to Astra-Core
export const useSetStorage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(storageProviderActions.setStorage())
  }, [dispatch])
}

import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconReceiptFilled40 } from 'shared/ui/Icon/General/IconReceiptFilled40'
import { Typography } from 'shared/ui/typography'

import {
  StyledEmptyContent,
  StyledEmptyHistoryWrapper,
  StyledEmptyText,
  StyledEmptyDescription
} from './empty-items.styled'

export const EmptyItems = memo(() => {
  const [t] = useTranslation()

  return (
    <StyledEmptyHistoryWrapper>
      <StyledEmptyContent>
        <IconReceiptFilled40 colorToken="icon-secondary-3" size={40} />
        <StyledEmptyText>
          <Typography color="text-primary" font="Body / 16 SemiBold">
            {t('no operations')}
          </Typography>
          <StyledEmptyDescription>
            {t('finance will be displayed here')}
          </StyledEmptyDescription>
        </StyledEmptyText>
      </StyledEmptyContent>
    </StyledEmptyHistoryWrapper>
  )
})

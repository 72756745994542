import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { MAIN_HEADER_HEIGHT } from 'shared/lib/layout'

export const StyledCouponSettingsWrapper = styled.div<{
  isShowBasketOnSmallWindow: boolean
}>`
  box-shadow: 0px 4px 20px 3px #0000001f, 0px 11px 15px 1px #00000024,
    0px 6px 7px -4px #00000033;
  display: flex;
  flex-direction: column;
  height: 240px;
  outline-offset: -1px;
  position: fixed;
  width: 300px;
  z-index: 9999;

  ${({ theme, isShowBasketOnSmallWindow }) => css`
    background-color: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    outline: 1px solid ${theme.tokens.colors.border['border-primary']};
    padding: ${theme.tokens.alias.space['space-l-20']}px;

    right: ${isShowBasketOnSmallWindow ? '47px' : '16px'};
    top: ${MAIN_HEADER_HEIGHT + 36}px;
  `}
`

export const StyledCouponSettingsHeader = styled.h2`
  ${typography['Body / 16 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledSettingsDescription = styled.p`
  ${typography['Hint / 10 BOLD']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const StyledSettingsRadioWrapper = styled.div``

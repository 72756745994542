import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { typography } from 'shared/lib/theme/typography'

export const NavTabsWrapper = styled.div`
  display: flex;
`

const baseNavTab = css`
  ${typography['Body / 14 SemiBold']};
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    padding: 14px ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

const activeNavTab = css`
  ${({ theme }) => css`
    box-shadow: inset 0 -2px ${theme.tokens.colors.border['border-active']};
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const NavTab = styled(NavLink)`
  ${baseNavTab}
  &.active {
    ${activeNavTab}
  }
`

export const NavTabControlled = styled.div<{ isActive: boolean }>`
  ${baseNavTab}
  ${({ isActive }) =>
    isActive &&
    css`
      ${activeNavTab}
    `}
`

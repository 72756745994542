import React from 'react'

import { IconYoutube } from 'shared/ui/Icon/General/IconYoutube'
import { IconButton } from 'shared/ui/buttons/IconButton'

import { LINK_YOUTUBE } from '../lib'

export const OpenYoutube = () => (
  <IconButton
    as="a"
    href={LINK_YOUTUBE}
    icon={IconYoutube}
    rel="noreferrer"
    target="_blank"
    view="fill-l"
  />
)

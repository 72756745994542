import { FC, useCallback, useEffect, useState } from 'react'
import {
  searchProviderActions,
  selectInputSearchLine
} from 'astra-core/containers/SearchProvider'
import { useParams } from 'react-router-dom'
import { LiveParams } from 'astra-core/containers/LiveProvider'

import { IconClearSearch } from 'shared/ui/Icon/General/icon-clear-search'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { StyledIconSearch, StyledLineSearch } from './line-search.styled'
import { LineSearchProps } from './line-search.types'

export const LineSearch: FC<LineSearchProps> = ({
  isTournaments = false,
  placeholder
}) => {
  const dispatch = useAppDispatch()
  const { sportId } = useParams<LiveParams>()
  const searchText = useAppSelector(selectInputSearchLine)
  const [isFocused, setIsFocused] = useState(false)

  const handleInputSearch = useCallback(
    ({ target }) =>
      dispatch(
        searchProviderActions.setSearchLine({
          valueSearch: target.value,
          isTournaments,
          sportId: +sportId
        })
      ),
    [dispatch, isTournaments, sportId]
  )

  const clearInputSearch = useCallback(
    () => dispatch(searchProviderActions.setSearchLine({ valueSearch: '' })),
    [dispatch]
  )

  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    return () => {
      clearInputSearch()
    }
  }, [clearInputSearch])

  return (
    <StyledLineSearch
      endAdornment={
        isFocused || !!searchText ? (
          <IconClearSearch
            colorToken={isFocused ? 'icon-secondary-2' : 'icon-secondary-3'}
            size={16}
            onClick={clearInputSearch}
          />
        ) : undefined
      }
      background="white"
      font="Body / 12 Medium"
      placeholder={placeholder}
      size="s"
      startAdornment={<StyledIconSearch colorToken="icon-secondary-2" />}
      value={searchText}
      onBlur={handleBlur}
      onChange={handleInputSearch}
      onFocus={handleFocus}
    />
  )
}

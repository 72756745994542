import React, { FC } from 'react'

import { usePaginationInfiniteScroll } from 'hooks/usePagination/usePagination'

import { DataListDefault } from '../DataListDefault'

import { DataListWithPaginationProps } from './types'

export const DataListWithPagination: FC<DataListWithPaginationProps> = ({
  children,
  pagination: paginationData,
  ...props
}) => {
  const { containerRef, containerBottomRef } =
    usePaginationInfiniteScroll(paginationData)

  return (
    <DataListDefault
      {...props}
      containerRef={containerRef}
      isLoading={props.isLoading && !paginationData.pagination.offset}
      isPaginationLoading={paginationData.hasMore}
      loadingRef={containerBottomRef}
    >
      {children}
    </DataListDefault>
  )
}

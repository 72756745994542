import React from 'react'

import { IconTelegram } from 'shared/ui/Icon/General/IconTelegram'
import { IconButton } from 'shared/ui/buttons/IconButton'

import { LINK_TELEGRAM } from '../lib'

export const OpenTelegram = () => (
  <IconButton
    as="a"
    href={LINK_TELEGRAM}
    icon={IconTelegram}
    rel="noreferrer"
    target="_blank"
    view="fill-l"
  />
)

import { BetStatus } from 'betweb-openapi-axios'

export const SECTION_SELECT_OPTIONS = [
  {
    titleKey: 'all',
    value: BetStatus.Unknown
  },
  {
    titleKey: 'current',
    value: BetStatus.Current
  },
  {
    titleKey: 'history computed',
    value: BetStatus.Computed
  },
  {
    titleKey: 'history won',
    value: BetStatus.Won
  },
  {
    titleKey: 'history returned',
    value: BetStatus.Returned
  },
  {
    titleKey: 'history lost',
    value: BetStatus.Lost
  }
]

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M11.2098 20.9966C11.5097 21.023 11.7997 20.8951 12.0012 20.6721C12.1015 20.5611 12.2049 20.4475 12.3016 20.3424C12.5945 20.024 12.8342 19.7445 12.8342 19.7214C12.8342 19.6983 12.5845 19.3443 12.2792 18.9348C11.974 18.5253 11.5982 17.9953 11.444 17.757C11.2899 17.5187 11.1422 17.2969 11.1158 17.2641C11.0894 17.2314 10.8523 17.1883 10.589 17.1683C10.0447 17.1269 9.22241 16.9902 8.29213 16.7863C7.94166 16.7094 7.62458 16.6466 7.58742 16.6466C7.55025 16.6466 7.17985 17.0299 6.76432 17.4984L6.17453 18.1632C6.07196 18.2789 6.03926 18.4404 6.0888 18.5866C6.10245 18.6269 6.11657 18.6671 6.13112 18.707C6.18669 18.8596 6.28529 18.9926 6.41301 19.0933C7.75575 20.1514 9.40679 20.8378 11.2098 20.9966Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M4.76042 17.3981C4.83648 17.4999 4.94261 17.5913 5.06977 17.5854C5.34193 17.5728 5.60064 17.2647 6.36814 16.3506C6.43337 16.273 6.50226 16.1909 6.57516 16.1043C6.68277 15.9764 6.7038 15.797 6.62865 15.6479L6.61388 15.6186C6.23708 14.8708 5.9054 13.992 5.61461 12.971C5.60789 12.9475 5.60157 12.925 5.59554 12.9035C5.54614 12.7277 5.51631 12.6216 5.44944 12.5517C5.34759 12.4451 5.15981 12.4223 4.68605 12.3648C4.6481 12.3602 4.60831 12.3554 4.56658 12.3502C4.18148 12.303 3.73136 12.2487 3.56628 12.2297L3.54197 12.2268C3.37106 12.207 3.20236 12.2787 3.09825 12.4153C3.0279 12.5076 2.99249 12.622 3.00167 12.7375C3.1398 14.4766 3.77286 16.0768 4.76042 17.3981Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M3.08945 10.566C3.08279 10.6071 3.08696 10.6493 3.10157 10.6883L3.12264 10.7446C3.2151 10.9916 3.44145 11.1638 3.70481 11.1876C3.9418 11.2091 4.46226 11.2675 4.86136 11.3176L5.36554 11.3808C5.50275 11.398 5.63882 11.3415 5.72314 11.2322C6.19959 10.6146 7.10499 9.49482 7.51115 9.02081C7.91376 8.55086 7.97996 8.44757 7.9338 8.3609C7.80706 8.12273 7.60412 7.08621 7.54498 6.3749L7.53729 6.28236C7.50434 5.88583 7.20742 5.56127 6.81441 5.4922C6.67797 5.46823 6.51829 5.44388 6.3568 5.42186C6.07186 5.383 5.78432 5.47625 5.58222 5.68025C4.29143 6.98315 3.39587 8.67618 3.08945 10.566Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M7.49486 4.20591C7.26945 4.3359 7.34448 4.66208 7.60234 4.69824L7.80079 4.72603C8.00012 4.75394 8.20326 4.72152 8.38389 4.63296L8.59876 4.52761C9.48838 4.09147 10.648 3.71014 11.3611 3.61925C11.6375 3.58398 11.64 3.58193 12.0233 3.06702C12.0286 3.05999 12.0338 3.05298 12.0389 3.046C12.0531 3.0269 12.0395 3 12.0157 3C10.3688 3 8.82479 3.43895 7.49486 4.20591Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M17.4123 19.1652C17.438 19.1914 17.4348 19.2342 17.4053 19.2561C16.3399 20.0458 15.0942 20.6073 13.7411 20.8679C13.4636 20.9214 13.3062 20.6075 13.5076 20.41L13.7839 20.139C13.8936 20.0314 14.0266 19.9504 14.1727 19.9021L14.9753 19.6372C15.5388 19.4512 16.2268 19.2026 16.5042 19.0848C16.5445 19.0676 16.582 19.0516 16.6169 19.0366C16.8828 18.9227 16.9993 18.8727 17.0965 18.8982C17.1723 18.9182 17.2363 18.9841 17.3502 19.1016C17.3694 19.1213 17.39 19.1425 17.4123 19.1652Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M20.6171 12.6587C20.8581 12.7581 21.0085 13.0026 20.9728 13.2603C20.717 15.1074 19.9001 16.7758 18.6991 18.089C18.5889 18.2095 18.4104 18.2178 18.2952 18.102C18.1054 17.9111 18.0135 17.6443 18.0456 17.3774L18.084 17.0581C18.1331 16.6502 18.1931 15.9372 18.2174 15.4737L18.2419 15.0045C18.2545 14.7636 18.354 14.5353 18.5219 14.3615L18.6094 14.271C19.0158 13.8506 19.4326 13.3524 19.8061 12.8406C19.8237 12.8165 19.8401 12.7939 19.8555 12.7726C19.9691 12.6156 20.0288 12.533 20.1064 12.5088C20.1924 12.482 20.3004 12.5269 20.5281 12.6217C20.5559 12.6333 20.5855 12.6456 20.6171 12.6587Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M20.9984 10.9733C21.0216 11.174 20.8237 11.3239 20.6323 11.2577C20.23 11.1186 20.2155 11.1075 20.1832 10.9147C20.0642 10.2045 19.7322 9.20551 19.3178 8.31063C19.3049 8.2829 19.2927 8.25654 19.281 8.23144C19.1331 7.91291 19.0802 7.79901 19.1038 7.70452C19.1169 7.65193 19.1536 7.60536 19.2109 7.53286C19.219 7.52256 19.2275 7.51174 19.2365 7.50031C19.3214 7.39176 19.4431 7.19936 19.5069 7.07272C19.5208 7.04499 19.5594 7.04276 19.5765 7.06869C20.3324 8.21484 20.8332 9.54315 20.9984 10.9733Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.9386 3.52708C13.1213 3.25099 13.4462 3.10602 13.7717 3.1699C15.5497 3.51879 17.1407 4.3873 18.379 5.61023C18.6377 5.86577 18.7112 6.25227 18.5309 6.56779C18.4703 6.67399 18.41 6.76752 18.362 6.82469C18.2771 6.92589 18.1344 6.95722 18.0147 6.90096L17.7084 6.75704C17.1548 6.49688 16.617 6.32707 15.7514 6.13927L15.4854 6.08154C15.2957 6.04035 15.1219 5.9452 14.9853 5.80761L14.4049 5.22332C13.8794 4.69417 13.4663 4.33126 13.1701 4.13848L12.869 3.9425C12.7897 3.89086 12.7677 3.78467 12.82 3.70591C12.8623 3.64232 12.9017 3.58278 12.9386 3.52708Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.4894 4.55058C13.059 4.98082 14.3428 6.22876 14.3412 6.35068C14.3389 6.53523 12.4579 8.94931 12.3134 8.95326C12.2787 8.95424 11.9636 8.87234 11.6132 8.77126C11.2627 8.67024 10.564 8.5221 10.0604 8.44209C9.55678 8.36209 9.09197 8.28631 9.02744 8.27369C8.62392 8.19472 8.2317 5.6436 8.58223 5.37775C9.05572 5.01858 10.3136 4.48645 11.1376 4.2967C11.8819 4.12532 11.9411 4.13648 12.4894 4.55058Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M16.9231 7.43758C17.207 7.52604 17.6332 7.67916 17.8702 7.77791L17.9525 7.8122C18.1772 7.90578 18.3602 8.07753 18.4674 8.29551L18.5797 8.52389C18.8784 9.13114 19.2114 10.0827 19.4073 10.8882L19.448 11.0553C19.4993 11.2666 19.4624 11.4896 19.3456 11.6732C19.1114 12.0413 18.5394 12.7572 18.0177 13.335L17.8649 13.5043C17.7038 13.6826 17.4673 13.7741 17.2278 13.7507C15.6629 13.5976 14.4838 13.2391 14.3911 12.8881C14.2316 12.2843 13.7549 10.9634 13.4662 10.3255L13.3756 10.1253C13.2335 9.8115 13.2874 9.44398 13.5136 9.18379C13.7176 8.94909 13.9507 8.6736 14.0317 8.57166C14.6215 7.82866 14.763 7.64109 14.9743 7.32188C15.1168 7.10665 15.3812 7.00611 15.6312 7.07207L15.8099 7.11919C16.1382 7.20586 16.6392 7.34913 16.9231 7.43758Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M9.8988 9.30745C10.4677 9.40782 11.9034 9.75839 12.0403 9.83037C12.2635 9.94775 12.9565 11.5843 13.3211 12.855L13.4489 13.3006C13.5008 13.4813 13.4684 13.6757 13.3606 13.83C12.7962 14.6383 11.4951 16.1185 11.2178 16.2677C11.0216 16.3733 9.13048 16.0788 8.14832 15.7896L8.06015 15.7637C7.79027 15.6842 7.56618 15.4956 7.44238 15.2436L7.35856 15.073C7.02856 14.4014 6.47692 12.9354 6.34404 12.377C6.28388 12.1242 6.33289 11.8579 6.47913 11.6428L6.49555 11.6187C6.90403 11.018 7.48562 10.3134 8.06112 9.72188L8.32409 9.45159C8.50283 9.26788 8.75411 9.17272 9.0101 9.19181C9.23227 9.20838 9.63219 9.26044 9.8988 9.30745Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M14.6307 14.3967C15.2013 14.5702 16.3026 14.79 16.8415 14.8378C17.0799 14.8589 17.2599 15.0626 17.2509 15.3011L17.2331 15.7746C17.1985 16.6895 17.0304 18.0274 16.9383 18.12C16.8571 18.2016 15.576 18.6823 14.6385 18.9828L14.3679 19.0696C14.0072 19.1853 13.6119 19.0825 13.3537 18.806C12.8948 18.3144 12.4707 17.7332 12.1865 17.2062C12.0788 17.0066 12.1112 16.7608 12.2668 16.5956L12.457 16.3937C12.9379 15.8834 13.5883 15.0818 13.9338 14.5735C14.0498 14.4029 14.1554 14.2633 14.1684 14.2633C14.1814 14.2633 14.3895 14.3234 14.6307 14.3967Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M2 20.9001C2 20.6792 2.18606 20.5003 2.40695 20.4973C11.8681 20.3654 12.1319 15.6347 21.593 15.5028C21.8139 15.4997 22 15.6791 22 15.9V22.1C22 22.3209 21.8209 22.5 21.6 22.5H2.4C2.17909 22.5 2 22.3209 2 22.1V20.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sand']}
      />
      <path
        d="M22 20.9001C22 20.6792 21.8139 20.5003 21.593 20.4973C12.1319 20.3654 11.8681 15.6347 2.40695 15.5028C2.18606 15.4997 2 15.6791 2 15.9V22.1C2 22.3209 2.17908 22.5 2.4 22.5H21.6C21.8209 22.5 22 22.3209 22 22.1V20.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sandDarker']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M7.38535 14.9973C7.61859 15.0179 7.84414 14.9184 8.00086 14.745C8.07887 14.6586 8.15934 14.5703 8.23454 14.4885C8.46234 14.2409 8.64876 14.0235 8.64876 14.0055C8.64876 13.9875 8.45451 13.7123 8.21711 13.3938C7.97971 13.0753 7.68739 12.663 7.5675 12.4777C7.44764 12.2923 7.33273 12.1198 7.31221 12.0943C7.29168 12.0689 7.10727 12.0353 6.90246 12.0198C6.47915 11.9876 5.83959 11.8813 5.11603 11.7227C4.84344 11.6629 4.59683 11.614 4.56792 11.614C4.53902 11.614 4.25093 11.9121 3.92774 12.2765L3.46901 12.7936C3.38923 12.8836 3.3638 13.0092 3.40233 13.1229C3.41295 13.1543 3.42393 13.1855 3.43525 13.2166C3.47847 13.3352 3.55516 13.4387 3.65449 13.517C4.69885 14.34 5.98299 14.8739 7.38535 14.9973Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M2.36915 12.1985C2.42831 12.2777 2.51085 12.3488 2.60975 12.3442C2.82143 12.3344 3.02265 12.0947 3.6196 11.3838C3.67033 11.3234 3.72392 11.2596 3.78061 11.1922C3.86431 11.0928 3.88066 10.9532 3.82222 10.8372L3.81073 10.8144C3.51766 10.2329 3.25969 9.5493 3.03351 8.75525C3.02829 8.73691 3.02338 8.71942 3.01869 8.70274C2.98026 8.56603 2.95706 8.4835 2.90505 8.42907C2.82584 8.34618 2.67979 8.32845 2.31131 8.28373C2.28179 8.28014 2.25084 8.27639 2.21839 8.27241C1.91886 8.23567 1.56877 8.19346 1.44037 8.17863L1.42147 8.17644C1.28854 8.16103 1.15732 8.21675 1.07635 8.32298C1.02163 8.39477 0.994094 8.48376 1.00123 8.57364C1.10866 9.92628 1.60104 11.1709 2.36915 12.1985Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M1.06951 6.88465C1.06432 6.91663 1.06757 6.94945 1.07893 6.9798L1.09532 7.02357C1.16724 7.21569 1.34329 7.34966 1.54812 7.36815C1.73244 7.38483 2.13724 7.43029 2.44766 7.46926L2.8398 7.51843C2.94651 7.53181 3.05235 7.48782 3.11793 7.40282C3.4885 6.9225 4.1927 6.05152 4.50861 5.68285C4.82175 5.31734 4.87323 5.237 4.83733 5.16959C4.73876 4.98435 4.58091 4.17816 4.53491 3.62492L4.52893 3.55295C4.5033 3.24453 4.27237 2.9921 3.96669 2.93838C3.86057 2.91973 3.73638 2.9008 3.61077 2.88367C3.38916 2.85344 3.16551 2.92597 3.00832 3.08464C2.00438 4.098 1.30783 5.4148 1.06951 6.88465Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M4.49593 1.93793C4.32061 2.03903 4.37897 2.29273 4.57953 2.32085L4.73388 2.34247C4.88891 2.36418 5.04692 2.33896 5.1874 2.27008L5.35453 2.18814C6.04645 1.84892 6.94838 1.55233 7.50302 1.48164C7.71801 1.45421 7.71994 1.45261 8.01808 1.05213C8.02216 1.04666 8.0262 1.04121 8.03023 1.03577C8.04122 1.02092 8.03063 1 8.01212 1C6.73123 1 5.53032 1.34141 4.49593 1.93793Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M12.2095 13.5729C12.2295 13.5933 12.227 13.6266 12.204 13.6436C11.3754 14.2579 10.4066 14.6945 9.35409 14.8973C9.13831 14.9389 9.01586 14.6947 9.17253 14.5411L9.38739 14.3303C9.47272 14.2466 9.57619 14.1836 9.68982 14.1461L10.314 13.9401C10.7524 13.7953 11.2875 13.602 11.5032 13.5104C11.5346 13.4971 11.5637 13.4846 11.5909 13.4729C11.7977 13.3843 11.8883 13.3454 11.9639 13.3653C12.0228 13.3808 12.0726 13.4321 12.1612 13.5234C12.1761 13.5388 12.1921 13.5553 12.2095 13.5729Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M14.7021 8.51229C14.8896 8.58967 15.0065 8.77979 14.9788 8.9802C14.7799 10.4168 14.1445 11.7145 13.2104 12.7359C13.1247 12.8296 12.9858 12.8361 12.8962 12.746C12.7486 12.5975 12.6771 12.39 12.7021 12.1824L12.7319 11.9341C12.7701 11.6168 12.8168 11.0623 12.8357 10.7018L12.8548 10.3368C12.8646 10.1494 12.9419 9.97187 13.0725 9.83675L13.1406 9.76635C13.4567 9.43935 13.7808 9.05188 14.0714 8.6538C14.085 8.63509 14.0978 8.61747 14.1097 8.60092C14.1981 8.47879 14.2446 8.41457 14.3049 8.39576C14.3718 8.37489 14.4558 8.40985 14.6329 8.48356C14.6545 8.49257 14.6776 8.50216 14.7021 8.51229Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M14.9987 7.20147C15.0167 7.35753 14.8628 7.47411 14.7139 7.42262C14.4011 7.31443 14.3898 7.3058 14.3646 7.15586C14.2721 6.6035 14.0138 5.82651 13.6915 5.13049C13.6815 5.10893 13.672 5.08842 13.6629 5.0689C13.5479 4.82115 13.5068 4.73256 13.5251 4.65907C13.5353 4.61817 13.5639 4.58194 13.6084 4.52556C13.6147 4.51755 13.6213 4.50913 13.6283 4.50024C13.6944 4.41581 13.789 4.26617 13.8386 4.16767C13.8495 4.1461 13.8795 4.14437 13.8928 4.16454C14.4807 5.05599 14.8702 6.08912 14.9987 7.20147Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M8.72992 1.40995C8.87203 1.19521 9.12476 1.08246 9.37793 1.13214C10.7608 1.4035 11.9983 2.07901 12.9614 3.03018C13.1626 3.22893 13.2197 3.52954 13.0796 3.77495C13.0324 3.85755 12.9855 3.93029 12.9482 3.97476C12.8821 4.05347 12.7711 4.07784 12.678 4.03408L12.4398 3.92214C12.0092 3.71979 11.5909 3.58772 10.9177 3.44166L10.7108 3.39675C10.5632 3.36472 10.4281 3.29071 10.3218 3.1837L9.87041 2.72925C9.46167 2.31769 9.1404 2.03543 8.91004 1.88549L8.67586 1.73306C8.61414 1.69289 8.59704 1.6103 8.63774 1.54904C8.67061 1.49958 8.70126 1.45328 8.72992 1.40995Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M8.38057 2.20601C8.82358 2.54064 9.82211 3.51126 9.82089 3.60609C9.81905 3.74962 8.35611 5.62724 8.24367 5.63032C8.21669 5.63107 7.97163 5.56737 7.69908 5.48876C7.42649 5.41019 6.88303 5.29497 6.49134 5.23274C6.09965 5.17052 5.73813 5.11158 5.68794 5.10176C5.37409 5.04034 5.06903 3.05613 5.34166 2.84936C5.70993 2.57 6.68827 2.15613 7.32917 2.00855C7.90808 1.87525 7.95416 1.88393 8.38057 2.20601Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M11.829 4.45145C12.0498 4.52025 12.3813 4.63935 12.5656 4.71615L12.6297 4.74282C12.8044 4.81561 12.9467 4.94919 13.0301 5.11873L13.1175 5.29636C13.3498 5.76867 13.6088 6.50875 13.7612 7.13526L13.7928 7.26523C13.8327 7.42956 13.804 7.60304 13.7132 7.74583C13.531 8.03214 13.0861 8.58897 12.6804 9.03832L12.5615 9.16999C12.4362 9.30871 12.2523 9.37984 12.066 9.36162C10.8488 9.2426 9.93174 8.96375 9.85964 8.69071C9.73564 8.2211 9.36482 7.19377 9.14028 6.69762L9.06982 6.54192C8.95936 6.29783 9.00125 6.01199 9.17714 5.80962C9.33583 5.62707 9.51718 5.4128 9.58014 5.33351C10.0389 4.75563 10.1489 4.60973 10.3133 4.36147C10.4241 4.19406 10.6297 4.11586 10.8242 4.16717L10.9632 4.20382C11.2186 4.27122 11.6082 4.38266 11.829 4.45145Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M6.36566 5.9058C6.80817 5.98386 7.92479 6.25652 8.03128 6.31251C8.20488 6.40381 8.7439 7.67666 9.02742 8.66497L9.12689 9.0116C9.16723 9.15215 9.142 9.30333 9.05821 9.4233C8.61919 10.052 7.60725 11.2033 7.39159 11.3193C7.23894 11.4015 5.76808 11.1724 5.00418 10.9475L4.9356 10.9273C4.7257 10.8655 4.5514 10.7188 4.45512 10.5228L4.38993 10.3901C4.13326 9.86772 3.7042 8.72753 3.60085 8.29322C3.55406 8.09661 3.59218 7.88944 3.70592 7.7222L3.7187 7.70341C4.0364 7.23624 4.48875 6.68818 4.93636 6.22813L5.14089 6.01791C5.27991 5.87502 5.47535 5.80101 5.67445 5.81585C5.84726 5.82874 6.1583 5.86923 6.36566 5.9058Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M10.0461 9.86409C10.4898 9.99908 11.3464 10.17 11.7655 10.2072C11.951 10.2236 12.091 10.3821 12.084 10.5676L12.0701 10.9358C12.0432 11.6474 11.9125 12.688 11.8408 12.76C11.7777 12.8235 10.7813 13.1973 10.0521 13.4311L9.84163 13.4986C9.56106 13.5885 9.25362 13.5086 9.05281 13.2935C8.69585 12.9112 8.36599 12.4591 8.14497 12.0493C8.06124 11.894 8.0864 11.7028 8.20746 11.5743L8.35539 11.4174C8.72945 11.0204 9.23525 10.397 9.50402 10.0016C9.59426 9.86894 9.67637 9.76033 9.6865 9.76033C9.6966 9.76033 9.85843 9.80705 10.0461 9.86409Z"
        fill={theme.tokens.colors['sport-icon']['sport-handball']}
      />
      <path
        d="M0.00012207 14.8C0.00012207 14.579 0.184805 14.4003 0.405685 14.3965C7.86961 14.2659 8.13064 10.5339 15.5946 10.4034C15.8154 10.3995 16.0001 10.579 16.0001 10.7999V15.5999C16.0001 15.8208 15.821 15.9999 15.6001 15.9999H0.400122C0.179208 15.9999 0.00012207 15.8208 0.00012207 15.5999V14.8Z"
        fill={theme.tokens.colors['sport-icon']['sport-sand']}
      />
      <path
        d="M16.0001 14.8C16.0001 14.579 15.8154 14.4003 15.5946 14.3965C8.13064 14.2659 7.86961 10.5339 0.405684 10.4034C0.184804 10.3995 0.00012207 10.579 0.00012207 10.7999V15.5999C0.00012207 15.8208 0.179208 15.9999 0.400122 15.9999H15.6001C15.821 15.9999 16.0001 15.8208 16.0001 15.5999V14.8Z"
        fill={theme.tokens.colors['sport-icon']['sport-sandDarker']}
      />
    </>
  )
}

export const IconSportBeachHandball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

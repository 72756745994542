import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'

import { RootState } from 'shared/types/store'
import { ERoutes } from 'shared/types/routes'
import { isActiveCurrentPath } from 'shared/lib/routes'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { LINE_PATH_FOR_CHECK } from './constants'

export const useCurrentTabMenu = () => {
  const { pathname } = useLocation()
  const isDisplayLiveDefault = useAppSelector((state: RootState) =>
    selectSettingsDataProp(state, 'liveInitiallyDisplayed')
  )

  const state = useState<ESportsMenuTabTypes>(
    setTabMenu({ pathname, isDisplayLiveDefault })
  )
  const [, setCurrentTabMenu] = state

  useEffect(() => {
    // Necessary for "hot visible change" of liveInitiallyDisplayed setting
    setCurrentTabMenu(setTabMenu({ pathname, isDisplayLiveDefault }))
  }, [isDisplayLiveDefault, pathname, setCurrentTabMenu])

  return state
}

const setTabMenu = ({ pathname, isDisplayLiveDefault }) => {
  if (isActiveCurrentPath(pathname, LINE_PATH_FOR_CHECK)) {
    return ESportsMenuTabTypes.LINE
  }

  if (isActiveCurrentPath(pathname, ERoutes.LiveRedirect)) {
    return ESportsMenuTabTypes.LIVE
  }

  if (isDisplayLiveDefault) {
    return ESportsMenuTabTypes.LIVE
  }

  return ESportsMenuTabTypes.LINE
}

import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

export const useTextOverflow = <T extends HTMLElement>() => {
  const [overflows, setOverflows] = useState(false)
  const targetRef = useRef<T>(null)

  const handleHover = useCallback(() => {
    const { offsetWidth, scrollWidth, offsetHeight, scrollHeight } =
      targetRef.current as HTMLElement
    setOverflows(
      offsetWidth < scrollWidth || Math.floor(scrollHeight / offsetHeight) > 1
    )
  }, [])

  useEffect(() => {
    if (!targetRef.current) {
      return
    }

    const target = targetRef.current

    target.addEventListener('mouseenter', handleHover)

    return () => {
      target.removeEventListener('mouseenter', handleHover)
    }
  }, [handleHover])

  return useMemo(() => [targetRef, overflows], [overflows]) as [
    RefObject<T>,
    boolean
  ]
}

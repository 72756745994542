import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M12.0009 2L22.0009 19.5758L19.0598 22L13.4494 18.0614H16.7043L12.0009 9.5758L7.31232 18.0614H10.5324L4.94213 22L2.00085 19.5758L12.0009 2Z"
        fill="#ED1B24"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M8.00085 0L16.0009 14.0606L13.648 16L9.15966 12.8491H11.7636L8.00085 6.06064L4.25002 12.8491H6.82605L2.35388 16L0.000854492 14.0606L8.00085 0Z"
        fill="#ED1B24"
      />
    </>
  )
}

export const IconCybersportApexLegends: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'

import { ContainerState, ToggleBasketOnSmallWindowPayload } from './types'

const REDUCER_KEY = 'rightBasketContainerState'

export const initialState: ContainerState = {
  layout: {
    isShowBasketOnSmallWindow: false,
    isBasketLoaded: false
  }
}

const rightBasketContainerStateSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    toggleBasketOnSmallWindow(
      state,
      { payload }: PayloadAction<ToggleBasketOnSmallWindowPayload>
    ) {
      state.layout.isShowBasketOnSmallWindow = payload.isShow
    },
    setBasketLoaded(state, { payload }: PayloadAction<boolean>) {
      state.layout.isBasketLoaded = payload
    }
  }
})

export const {
  actions: rightBasketContainerActions,
  reducer: basketReducer,
  name: basketSliceKey
} = rightBasketContainerStateSlice

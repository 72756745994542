import styled, { css } from 'styled-components'
import get from 'lodash/get'

import { typography } from 'shared/lib/theme/typography'
import { SPORT_MAIN_COLOR_MAP } from 'shared/ui/Icon/sport-icon/constants'
import { ControlButton } from 'shared/ui/Carousel/Carousel.styled'

import { StyledSportsListItemProps } from './sport-tabs.types'

export const StyledSportsListItem = styled.div<StyledSportsListItemProps>`
  align-items: center;
  cursor: pointer;
  display: flex;

  ${({ theme, isActive, sport }) => css`
    border-radius: ${theme.tokens.alias.space['space-xs-8']}px;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;
    transition: background-color 0.25s ease, box-shadow 0.25s ease,
      color 0.25s ease;

    svg {
      path {
        transition: fill 0.25s ease;
      }
    }

    ${isActive
      ? css`
          background-color: ${theme.tokens.colors.surface['surface-base']};
          box-shadow: inset 0 0 0 1px
            ${get(theme.tokens.colors, SPORT_MAIN_COLOR_MAP[sport!])};
          color: ${theme.tokens.colors.text['text-primary']};
        `
      : css`
          &:not(&:hover) {
            svg {
              path {
                fill: ${theme.tokens.colors.icon['icon-secondary-2']};
              }
            }
          }

          background-color: ${theme.tokens.colors.surface[
            'surface-sidebar-list'
          ]};
          box-shadow: inset 0 0 0 1px
            ${theme.tokens.colors.border['border-default-gray']};
          color: ${theme.tokens.colors.text['text-secondary-2']};
          &:hover {
            background-color: ${theme.tokens.colors.surface['surface-base']};
            box-shadow: inset 0 0 0 1px
              ${theme.tokens.colors.border['border-hover']};
            color: ${theme.tokens.colors.text['text-primary']};
          }
        `}
  `}
`

export const StyledSportsListItemSkeleton = styled.div`
  align-items: center;
  background-color: ${(p) => p.theme.colors.default.primary[10]};
  border-radius: 0.25rem;
  column-gap: 0.25rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  height: 32px;
  margin-right: 0.25rem;
  padding: 0.25rem 0.5rem;
  width: 90px;
`

export const StyledSportsListItemLabel = styled.div`
  ${typography['Body / 12 SemiBold']}
  white-space: nowrap;
`

export const ControlButtonArrow = styled.div``

export const StyledSportsContainer = styled.div`
  position: relative;

  &:hover {
    ${ControlButton} {
      display: flex;
    }
  }
`

export const StyledSportsWrapper = styled.div<{
  leftOffset: number
  rightOffset: number
}>`
  -ms-overflow-style: none;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ theme, leftOffset, rightOffset }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    scroll-padding: ${leftOffset}px;

    ${leftOffset > 0 &&
    css`
      &::before {
        content: '';
        scroll-snap-type: x mandatory;
        width: ${leftOffset - theme.tokens.alias.space['space-xxxs-4']}px;
      }
    `}

    ${rightOffset > 0 &&
    css`
      &:after {
        content: '';
        scroll-snap-type: x mandatory;
        width: ${rightOffset - theme.tokens.alias.space['space-xxxs-4']}px;
      }
    `}
  `}
`

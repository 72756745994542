import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { MAP_IDENTIFY_CONSTRAINTS_TO_STORE_PROPS } from '../store-lib'

import { IdentificationConstraints } from './types'
import { initialState } from './slice'

export const getConstraint = (
  state: RootState,
  constraintType: IdentificationConstraints
) => {
  return !!(
    state.identificationProvider.data.constraints[
      MAP_IDENTIFY_CONSTRAINTS_TO_STORE_PROPS[constraintType]
    ] ??
    initialState.data.constraints[
      MAP_IDENTIFY_CONSTRAINTS_TO_STORE_PROPS[constraintType]
    ]
  )
}

const getIsAllIdentifyDone = (state: RootState) => {
  const { constraints } = state.identificationProvider.data

  return !(
    constraints.isTopUpBlocked &&
    constraints.isWithdrawalBlocked &&
    constraints.isBetsBlocked
  )
}

export const selectIsAllIdentifyDone = createSelector(
  [getIsAllIdentifyDone],
  (data) => data
)

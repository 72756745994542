import React, { FC, memo } from 'react'

import { Logo } from 'features/logo/ui'
import { ERoutes } from 'shared/types/routes'

import * as S from './Logo.styled'

export const HeaderLogo: FC = memo(() => (
  <S.LogoWrapper>
    <S.StyledLogo key="Redirect to Live" to={ERoutes.Root} exact>
      <Logo />
    </S.StyledLogo>
  </S.LogoWrapper>
))

import React, { FC } from 'react'

import { usePaginationWithReduxInfiniteScroll } from 'hooks/usePagination/usePagination'

import { DataListDefault } from '../DataListDefault'

import { DataListWithReduxPaginationProps } from './types'

export const DataListWithReduxPagination: FC<
  DataListWithReduxPaginationProps
> = ({ children, pagination, isHidePreloader, ...props }) => {
  const {
    containerRef,
    containerBottomRef,
    isPaginationLoading,
    isInitLoading
  } = usePaginationWithReduxInfiniteScroll(pagination, isHidePreloader)

  return (
    <DataListDefault
      {...props}
      bottomComponent={pagination.bottomComponent}
      containerRef={containerRef}
      isLoading={props.isLoading || isInitLoading}
      isPaginationLoading={isPaginationLoading}
      loadingRef={containerBottomRef}
    >
      {children}
    </DataListDefault>
  )
}

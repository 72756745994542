import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconSimTele2: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 42 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M21.3837 16H17.4717L17.4651 13.1462L21.3837 13.025V16ZM30.4261 16H25.8975V12.8871L30.4261 12.7474V16ZM30.4261 4.55782L25.8975 5.06262V1.95641L30.4261 1.31026V4.55782ZM9.94605 8.79651L12.1904 8.59626V11.334L9.94605 11.4568V8.79651ZM41.9997 12.3907V16H31.4273V12.7154L41.9997 12.3907ZM25.8975 7.4032L28.8663 7.14578V10.4034L25.8975 10.5717V7.4032ZM16.9744 16H14.2791V3.62058L16.9744 3.23356V16ZM37.0633 7.16261C37.9484 6.10084 38.323 4.95662 37.9601 4.19773C37.7346 3.71312 37.2124 3.4725 36.5743 3.50783V0.0634017C38.149 -0.163758 39.5878 0.223256 40.5691 1.16387C41.4677 2.0259 41.9261 3.25933 41.8124 4.50902C41.6085 6.90178 40.4647 8.81838 37.2788 11.9768L32.0655 12.145C32.0655 12.145 35.6974 8.79815 37.0633 7.16261ZM35.9709 3.65255C35.3294 3.92514 34.6912 4.42657 34.8404 6.54005L31.5666 6.83617C31.3909 3.0939 32.8993 0.810507 35.9709 0.137438V3.65255ZM13.3657 16H9.94605V13.3784L13.3657 13.2724V16ZM9.49847 16H7.12805V4.63523L9.49847 4.2987V16ZM13.3657 6.46932L9.94605 6.84966V4.23475L13.3657 3.74678V6.46932ZM4.37143 16H2.12202V8.08306L4.37143 7.84744V16ZM6.29928 7.25514L0.166016 7.93997V5.63138L6.29928 4.75301V7.25514ZM25.3173 16H22.2125V2.47467L25.3173 2.03382V16Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { IdentificationProps } from './identification.types'
import * as S from './identification.styled'

export const Identification: FC<IdentificationProps> = ({ iframeSrc }) => (
  <S.Identification
    sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms"
    src={iframeSrc}
    title="identification"
  />
)

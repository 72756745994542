import { baseLegacyApi } from '../api'

import { GetTopUpFetch, GetWithdrawalListFetch } from './top-up.types'

export const topUpApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    // Top up
    getTopUpList: builder.query<
      GetTopUpFetch['response'],
      GetTopUpFetch['request']
    >({
      query: () => ({
        method: 'POST',
        url: 'pay/in/list',
        body: { session: '__session__', platforma: 'NEWOLIMPBET' }
      }),
      merge(currentCacheData, responseData) {
        currentCacheData.data = {
          ...currentCacheData.data,
          ...responseData.data
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      keepUnusedDataFor: 600
    }),
    getWithdrawalList: builder.query<
      GetWithdrawalListFetch['response'],
      GetWithdrawalListFetch['request']
    >({
      query: () => ({
        method: 'POST',
        url: 'pay/out/list',
        body: { session: '__session__', platforma: 'NEWOLIMPBET' }
      }),
      merge(currentCacheData, responseData) {
        currentCacheData.data = {
          ...currentCacheData.data,
          ...responseData.data
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      keepUnusedDataFor: 600
    })
  })
})

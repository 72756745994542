import { FC } from 'react'

import { CardPaymentProps } from './card-payment.types'
import * as S from './card-payment.styled'

export const CardPayment: FC<CardPaymentProps> = ({
  limits,
  RightIcons,
  amount,
  TopIcons,
  title,
  onClick,
  isActive
}) => {
  return (
    <S.Card $isActive={isActive} onClick={onClick}>
      {TopIcons && <S.IconsContainer>{TopIcons}</S.IconsContainer>}

      <S.Title>
        {title}

        <S.PaymentIconsContainer>{RightIcons}</S.PaymentIconsContainer>
      </S.Title>

      <S.Amount>{amount}</S.Amount>

      <S.Limits>{limits}</S.Limits>
    </S.Card>
  )
}

import { forwardRef, PropsWithChildren } from 'react'

import { StyledTypography } from './typography.styled'
import { TypographyProps } from './typography.types'

export const Typography = forwardRef<
  HTMLSpanElement,
  PropsWithChildren<TypographyProps>
>(
  (
    {
      color = 'text-primary',
      font = 'Body / 12 Medium',
      lineClamp,
      children,
      ...rest
    },
    ref
  ) => (
    <StyledTypography
      colorToken={color}
      fontName={font}
      lineClamp={lineClamp}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledTypography>
  )
)

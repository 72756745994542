import styled, { css } from 'styled-components'

export const StyledBasketBottomWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
    bottom: 0px;
    box-shadow: inset 1px 0px
      ${({ theme }) => theme.tokens.colors.border['border-hover']};

    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;

    position: sticky;
  `}
`

import styled, { css } from 'styled-components'

export const EntityLogoWrapper = styled.div<{
  backgroundImg: string
  size: number
}>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  ${({ size, backgroundImg }) => css`
    background-image: url('${backgroundImg}');
    height: ${size}px;
    width: ${size}px;
  `}
`

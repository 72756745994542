import { CashoutUpdate, ErrorCode } from 'betweb-openapi-axios'

export enum ECurrentCashoutModalContent {
  ACTIVE_SELL = 'active sell',
  AMOUNT_HAS_CHANGED = 'amount has changed',
  SELL_NOT_POSSIBLE = 'sell not possible',
  SELL_BET_ERROR = 'sell bet error'
}

export const CASHOUT_BUTTOR_ERROR_TEXT = {
  [ErrorCode.IncorrectCashoutAmount]: 'update amount'
}

export interface ICurrentCashoutModalContent {
  setCurrentCashout: () => void
  cashoutAmount: string
  contentType: ECurrentCashoutModalContent
  number: number
}

export interface IGetCurrentCashoutModalContent
  extends Omit<ICurrentCashoutModalContent, 'contentType'> {
  cashoutError: {
    code: ErrorCode
    title: string
  } | null
  cashout: CashoutUpdate
  isCashoutAmountHasChanged: boolean
}

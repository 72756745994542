import { FC } from 'react'

import * as S from './events-page-title.styled'
import { EventsPageTitleProps } from './events-page-title.types'

export const EventsPageTitle: FC<EventsPageTitleProps> = ({
  title,
  renderRight
}) => {
  return (
    <S.EventsPageTitleWrapper>
      <S.TitleWrapper>{title}</S.TitleWrapper>
      {renderRight}
    </S.EventsPageTitleWrapper>
  )
}

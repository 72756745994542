import React, { FC } from 'react'

import { Button } from 'shared/ui/Button'

import { TableRowProps } from './table-row.types'
import * as S from './table-row.styled'

export const TableRow: FC<TableRowProps> = ({
  date,
  id,
  amount,
  operationType,
  method,
  // status,
  isDisabledCancelTransaction,
  onClickCancelTransaction,
  t
}) => {
  return (
    <S.TableRow $isDisabledCancelTransaction={isDisabledCancelTransaction}>
      <S.ItemAccent>{date}</S.ItemAccent>
      <S.ItemAccent>{id}</S.ItemAccent>
      <S.ItemOperationType>{operationType}</S.ItemOperationType>
      <S.Item>{method}</S.Item>
      <S.ItemAmount>{amount}</S.ItemAmount>
      {/* <S.ItemStatus $type={status.type}>/!* {status.text} *!/</S.ItemStatus> */}
      {!isDisabledCancelTransaction && (
        <S.ItemButton>
          <Button view="secondary-medium" onClick={onClickCancelTransaction}>
            {t('cancel operation')}
          </Button>
        </S.ItemButton>
      )}
    </S.TableRow>
  )
}

import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { Id, ToastOptions } from 'react-toastify/dist/types'

import { ToastifyContent } from './components/ToastifyContent'
import { ToastContent } from './components/ToastifyContent/ToastifyContent.types'
import { TCustomToastContentProps } from './Toastify.types'

export type TUsePopUpNotificationDefault = {
  content: Omit<ToastContent, 'children'> & {
    children?:
      | ((
          toastContentProps: TCustomToastContentProps
        ) => ToastContent['children'])
      | ToastContent['children']
  }
  options: ToastOptions
}

export const usePopUpNotification = ({
  content,
  options
}: TUsePopUpNotificationDefault) => {
  const toastId = React.useRef<Id | null>(null)

  return useCallback(
    (toastText?: string) => {
      // Forbid display copies the concrete toast
      // if (!toast.isActive(toastId.current || '')) {
      toastId.current = toast(
        (toastProps) => (
          <ToastifyContent {...content}>
            {toastText ??
              (typeof content.children === 'function'
                ? content.children(toastProps)
                : content.children)}
          </ToastifyContent>
        ),
        {
          autoClose: 5000,
          hideProgressBar: true,
          closeButton: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: false,
          closeOnClick: false,
          ...options
        }
      )
      // }
    },
    [content, options]
  )
}

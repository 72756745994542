import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const ModalConfirmWrapper = styled.div`
  max-height: 640px;
  width: 375px;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xl-16'
    ]}px;
    box-shadow: inset 0 0 0 1px ${theme.tokens.colors.border['border-primary']};
    padding: ${theme.tokens.alias.space['space-xxl-32']}px 0;
  `}
`

export const ModalBody = styled.div`
  display: grid;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: 0 ${theme.tokens.alias.space['space-xxl-32']}px;
  `}
`

export const ModalTitle = styled.div`
  ${typography['Title / 18 Bold']};
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px 0;
  `}
`

export const ModalText = styled.div`
  ${typography['Body / 14 SemiBold']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
  `}
`

export const ModalConfirmBackdrop = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  z-index: 1001;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-overlay']};
  `}
`

import styled, { css } from 'styled-components'

export const StyledHotKeyWrapper = styled.div`
  display: flex;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    box-shadow: inset 0 0 0 1px ${theme.tokens.colors.border['border-primary']};
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

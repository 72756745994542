import { FC } from 'react'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { StyledContentLoader } from './content-loader.styled'
import { ContentLoaderProps } from './content-loader.types'

export const ContentLoader: FC<ContentLoaderProps> = ({
  width = '100%',
  height = '100%',
  borderRadius = 8,
  disableAnimation = false
}) => {
  const theme = useTheme()

  const baseColor = theme.tokens.colors.surface['surface-default']

  const highlightColor =
    theme.typeLayoutTheme === LayoutTheme.Light ? '#C4C9D4' : '#333C4D'

  return (
    <StyledContentLoader
      animationDuration="3s"
      baseColor={baseColor}
      disableAnimation={disableAnimation}
      highlightColor={highlightColor}
      style={{ width, height, borderRadius }}
    />
  )
}

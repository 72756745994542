export interface SwitchProps {
  active?: boolean
  label?: string
  onClick?: () => void
  disabled?: boolean
  view?: ESwitchView
  size?: 16 | 24
}

export enum ESwitchView {
  PRIMARY = 'primary',
  BONUS = 'bonus'
}

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'

import { ButtonOpenBasketForSmallWindowProps } from './button-open-basket-for-small-window.types'
import * as S from './button-open-basket-for-small-window.styled'

export const ButtonOpenBasketForSmallWindow: FC<
  ButtonOpenBasketForSmallWindowProps
> = ({ isSmall, className, onClick, outcomesCount, expressRate }) => {
  const [t] = useTranslation()

  return (
    <S.StyledBasketButtonSmallWindowWrapper
      className={className}
      isSmall={isSmall}
      onClick={onClick}
    >
      <S.StyledBasketButtonSmallWindowText>
        {t('just coupon')}
      </S.StyledBasketButtonSmallWindowText>

      {!!outcomesCount && (
        <S.StyledBasketButtonSmallEventCounter>
          <IconPolygonBgLeftPart />

          <S.StyledBasketButtonSmallEventRect>
            <div>{outcomesCount}</div>

            <S.StyledDivideLine />

            <div>{expressRate}</div>
          </S.StyledBasketButtonSmallEventRect>

          <IconPolygonBgLeftPart twist={180} />
        </S.StyledBasketButtonSmallEventCounter>
      )}
    </S.StyledBasketButtonSmallWindowWrapper>
  )
}

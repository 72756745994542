import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'

import {
  ECurrentCashoutModalContent,
  ICurrentCashoutModalContent,
  IGetCurrentCashoutModalContent
} from './CurrentCashoutModal.types'
import {
  StyledCurrentCashoutButton,
  StyledCurrentCashoutButtonWrapper,
  StyledCurrentCashoutModalContentWrapper,
  StyledCurrentCashoutText
} from './CurrentCashoutModal.styled'

export const getCurrentCashoutModalContent = ({
  cashoutError,
  cashoutAmount,
  makeCashout,
  closeCashout
}: IGetCurrentCashoutModalContent) => {
  if (cashoutError?.err_code !== 0) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        cashoutError={cashoutError}
        closeCashout={closeCashout}
        contentType={ECurrentCashoutModalContent.SELL_BET_ERROR}
        makeCashout={makeCashout}
      />
    )
  } else {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        closeCashout={closeCashout}
        contentType={ECurrentCashoutModalContent.ACTIVE_SELL}
        makeCashout={makeCashout}
      />
    )
  }
}

export const CurrentCashoutModalContent = ({
  cashoutAmount,
  contentType,
  makeCashout: onMakeCashoutClick,
  closeCashout,
  cashoutError
}: ICurrentCashoutModalContent) => {
  const [t] = useTranslation()
  const match = useRouteMatch([ERoutes.BetsHistory])

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    closeCashout()
  }

  const makeCashout = (event) => {
    event.stopPropagation()
    onMakeCashoutClick()
  }

  if (contentType === ECurrentCashoutModalContent.ACTIVE_SELL) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText isBasketHistory={!match}>
          {cashoutAmount}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButtonWrapper>
          <StyledCurrentCashoutButton onClick={makeCashout}>
            {t('yes')}
          </StyledCurrentCashoutButton>
          <StyledCurrentCashoutButton
            view="secondary-large"
            onClick={unsetCurrentCashout}
          >
            {t('no')}
          </StyledCurrentCashoutButton>
        </StyledCurrentCashoutButtonWrapper>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText isBasketHistory={!match}>
          {t('bet amount has changed')}. {cashoutAmount}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButtonWrapper>
          <StyledCurrentCashoutButton onClick={makeCashout}>
            {t('yes')}
          </StyledCurrentCashoutButton>
          <StyledCurrentCashoutButton
            view="secondary-large"
            onClick={unsetCurrentCashout}
          >
            {t('no')}
          </StyledCurrentCashoutButton>
        </StyledCurrentCashoutButtonWrapper>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_NOT_POSSIBLE) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText isBasketHistory={!match}>
          {t('sell bet is not possible')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton onClick={unsetCurrentCashout}>
          {t('close')}
        </StyledCurrentCashoutButton>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_BET_ERROR) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText isBasketHistory={!match}>
          {t(cashoutError?.err_desc ?? 'error')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton onClick={unsetCurrentCashout}>
          {t('close')}
        </StyledCurrentCashoutButton>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else {
    return null
  }
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      d="M14.6248 8.48877L16.3934 6.7249C16.6291 6.48986 17.4354 5.68815 17.9516 5.17494C18.1997 4.92836 18.6005 4.93077 18.846 5.17987C19.0887 5.42607 19.0884 5.82138 18.8452 6.06702C18.4453 6.47098 17.8946 7.02723 17.7448 7.17866L16.4322 8.50495L17.5081 8.47586C18.9792 8.43619 19.6646 8.03022 20.0482 6.97122C20.3672 6.09074 20.7624 5.9779 21.5353 6.5468C23.82 8.22875 23.3507 11.7193 20.6926 12.8136C20.2263 13.0056 20.1572 13.0088 16.0449 13.0329L11.8706 13.0571H11.8618L10.1735 14.7518L10.264 15.1739C10.4633 16.1031 10.2571 16.4904 8.79356 17.9359C8.04036 18.6799 7.71004 19.047 7.38301 19.0441C7.0632 19.0413 6.74653 18.6845 6.04065 17.9802C5.33392 17.275 4.97595 16.9584 4.97271 16.6382C4.96941 16.3107 5.3372 15.9794 6.08248 15.2249C7.53428 13.755 7.85816 13.5818 8.8267 13.7578L9.26961 13.8384L10.0278 13.0946L6.904 13.0502C3.91709 13.0076 3.75941 12.9972 3.30677 12.8123C0.650092 11.7263 0.179719 8.2285 2.46415 6.5468C3.23696 5.9779 3.6322 6.09074 3.95117 6.97122C4.13846 7.48822 4.49942 7.90835 5.01841 8.21346L5.41545 8.44687L14.6177 8.49574L14.6248 8.48877Z"
      fill={theme.tokens.colors['sport-icon']['sport-canoeing']}
    />
  ),
  s: ({ theme }) => (
    <path
      d="M9.90898 5.43339L11.1953 4.15058C11.3667 3.97964 11.9531 3.39658 12.3285 3.02334C12.5089 2.84401 12.8004 2.84576 12.979 3.02693C13.1554 3.20598 13.1553 3.49348 12.9784 3.67213C12.6875 3.96591 12.287 4.37046 12.1781 4.48059L11.2235 5.44516L12.0059 5.42401C13.0758 5.39516 13.5743 5.09991 13.8533 4.32972C14.0853 3.68937 14.3727 3.60731 14.9348 4.02105C16.5964 5.24429 16.2551 7.78286 14.322 8.57876C13.9828 8.71834 13.9326 8.72073 10.9418 8.73821L7.90593 8.75584H7.89951L6.67167 9.98834L6.73752 10.2953C6.88242 10.9711 6.73244 11.2528 5.66808 12.3041C5.12029 12.8451 4.88006 13.1121 4.64222 13.11C4.40963 13.1079 4.17933 12.8485 3.66596 12.3363C3.15197 11.8234 2.89163 11.5931 2.88928 11.3602C2.88688 11.1221 3.15436 10.8811 3.69638 10.3324C4.75224 9.26336 4.98778 9.13744 5.69218 9.26544L6.01429 9.32403L6.56569 8.78308L4.29385 8.75077C2.12155 8.71984 2.00687 8.71229 1.67768 8.57778C-0.254448 7.78794 -0.596538 5.24411 1.06487 4.02105C1.62691 3.60731 1.91435 3.68937 2.14634 4.32972C2.28255 4.70572 2.54506 5.01127 2.92251 5.23317L3.21127 5.40292L9.90384 5.43847L9.90898 5.43339Z"
      fill={theme.tokens.colors['sport-icon']['sport-canoeing']}
    />
  )
}

export const IconSportCanoeing: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

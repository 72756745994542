import React, { FunctionComponent } from 'react'

import {
  PickerPeriod,
  PickerPeriodProps
} from 'features/basket-history/ui/PickerPeriod'

import {
  StyledFilter,
  StyledFilterList,
  StyledFilterWrap
} from './Filter.styled'
import { ButtonToggleCollapse, ButtonClearHistory } from './components'

export type FilterProps = PickerPeriodProps

export const Filter: FunctionComponent<FilterProps> = (props) => {
  return (
    <StyledFilter>
      <StyledFilterList>
        <PickerPeriod {...props} isWithPeriodSelectsOption />
      </StyledFilterList>

      <StyledFilterWrap>
        <ButtonClearHistory />
        <ButtonToggleCollapse />
      </StyledFilterWrap>
    </StyledFilter>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import { initialState } from 'astra-core/containers/CommonDataProvider/slice'
import { getEventById } from 'astra-core/containers/EventsProvider'

import { RootState } from 'shared/types/store'

export const selectEventStatus = createSelector(
  [getEventById],
  (event) => event.status
)

const getEntityTags = (state: RootState) =>
  state.commonDataProvider.entries.entityTags || initialState.entries.entityTags

export const selectEntityTags = createSelector(
  [getEntityTags],
  (entityTags) => entityTags
)

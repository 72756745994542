export const GET_VIDEOJS_OPTIONS = ({
  isPlayingBroadcast,
  isBroadcastMuted
}) => ({
  autoplay: isPlayingBroadcast,
  muted: isBroadcastMuted,
  controls: true,
  responsive: true,
  fluid: true,
  preload: 'auto'
})

export const getRedirectUrl = (isDevelopment: boolean) => {
  if (isDevelopment) return 'https://olimpbet.olimpbetkz-stage.betdev.cloud'

  return window?.location?.origin || ''
}

import { FC, useMemo, useState } from 'react'
import { BasicScore } from 'betweb-openapi-axios'
import range from 'lodash/range'

import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { IconCloseOutline12 } from 'shared/ui/Icon/General/IconCloseOutline12'
import { IconLiveSmall } from 'shared/ui/Icon/General/IconLiveSmall'

import { BasicStatisticsProps } from './basic-statistics.types'
import {
  BasicStatisticsWrapper,
  EventScores,
  Score,
  ScoresByPeriodsModal,
  ScoresByPeriodsWrapper,
  ScoresByPeriodText,
  ShowMoreButton,
  ShowMoreDot
} from './basic-statistics.styled'

const renderScore = (score: BasicScore) =>
  `${score.homeScore}:${score.awayScore}`

const ScoresByPeriods: FC<{ scoreByPeriods: BasicScore[] }> = ({
  scoreByPeriods
}) => {
  const [ref, isOverflowing] = useTextOverflow<HTMLDivElement>()
  const [isOpen, setIsOpen] = useState(false)

  const buttonContent = useMemo(
    () =>
      isOpen ? (
        <IconCloseOutline12 colorToken="icon-secondary" size={12} />
      ) : (
        <>
          {range(0, 3).map((index) => (
            <ShowMoreDot key={index} />
          ))}
        </>
      ),
    [isOpen]
  )

  const renderedScore = `(${scoreByPeriods.map(renderScore).join(', ')})`

  return (
    <ScoresByPeriodsWrapper>
      <ScoresByPeriodText ref={ref}>{renderedScore}</ScoresByPeriodText>
      <ShowMoreButton
        isVisible={isOverflowing}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {buttonContent}
      </ShowMoreButton>
      {isOpen && (
        <ScoresByPeriodsModal>
          {renderedScore}
          <ShowMoreButton isVisible onClick={() => setIsOpen((prev) => !prev)}>
            {buttonContent}
          </ShowMoreButton>
        </ScoresByPeriodsModal>
      )}
    </ScoresByPeriodsWrapper>
  )
}

export const BasicStatistics: FC<BasicStatisticsProps> = ({ event }) => {
  const { basicStatistics: statistics } = event

  return (
    <BasicStatisticsWrapper>
      {event.live && <IconLiveSmall colorToken="icon-negative" size={12} />}
      <EventScores>
        {statistics?.currentScore && (
          <Score>{renderScore(statistics.currentScore)}</Score>
        )}
        {statistics?.scoresByPeriod && (
          <ScoresByPeriods scoreByPeriods={statistics?.scoresByPeriod} />
        )}
      </EventScores>
    </BasicStatisticsWrapper>
  )
}

import React from 'react'
import { Redirect } from 'react-router-dom'

import { LiveScreenPrefetch, PageLiveLazy } from 'pages/page-live'
import { PageRefundPolicyLazy } from 'pages/page-refund-policy'
import { PageBalanceLazy } from 'pages/page-balance'
import { PageGamesBetLazy } from 'pages/page-games-bet'
import { BetsHistoryLazy } from 'pages/page-bets-history'
import { PageGamesLiveLazy } from 'pages/page-games-live'
import { PageSportGamesLazy } from 'pages/page-sport-games'
import { PageGamesTurboGameLazy } from 'pages/page-games-turbo-game'
import { PageGamesTurboLazy } from 'pages/page-games-turbo'
import { PageGamesTwainLazy } from 'pages/page-games-twain'
import { PageCasinoLazy } from 'pages/page-casino'
import { PageResultsLazy } from 'pages/page-results'
import { PageStatisticsLazy } from 'pages/page-statistics'
import { PageMainLazy } from 'pages/page-main'
import {
  pageLiveEventsPrefetch,
  PageLiveEventsLazy
} from 'pages/page-live-events'
import { PageLiveEventLazy, pageLiveEventPrefetch } from 'pages/page-live-event'
import { PageLineLazy, pageLinePrefetch } from 'pages/page-line-events'
import {
  PageLineTournamentLazy,
  pageLineTournamentsPrefetch
} from 'pages/page-line-tournaments'
import { PageLineEventLazy, pageLineEventPrefetch } from 'pages/page-line-event'
import { PageCalendarLazy } from 'pages/page-calendar'
import { PageFavouriteEventsLazy } from 'pages/page-favourite-events'
import { PageProfileLazy } from 'pages/page-profile'
import { PageNotificationLazy } from 'pages/page-notification'
import { PageResponsibleGamblingLazy } from 'pages/page-responsible-gambling'
import { PagePolicyPrivacyLazy } from 'pages/page-policy-privacy'
import { PagePolicyAmlLazy } from 'pages/page-policy-aml'
import { PagePageHeadToHeadLazy } from 'pages/page-head-to-head'
import { PageSearchLazy } from 'pages/page-search'
import { PageNotFoundLazy } from 'pages/page-not-found'
import { ERoutes, IRoute } from 'shared/types/routes'
import { PageUnderConstructionLazy } from 'pages/page-under-construction'
import { PageDocumentsLazy } from 'pages/page-documents'
import { PageAuthSuccessLazy } from 'pages/page-auth-success'
import { PageAuthSuccessSignUpLazy } from 'pages/page-auth-success-sign-up'
import { PageLiveResultsLazy } from 'pages/page-live-results'
import { PagePromotionsLazy } from 'pages/page-promotions'
import { PageTestCoinLazy } from 'pages/page-test-coin'
import { PageMyBonusesLazy } from 'pages/page-my-bonuses'
import { PageBonusClubLazy } from 'pages/bonus-club'
import { PageSupportLazy } from 'pages/page-support'
import { PageFAQLazy } from 'pages/page-faq'
import { PageFAQDetailsLazy } from 'pages/page-faq-details'

import { PageAboutLazy } from '../page-about'
import { PageSearchTournamentsLazy } from '../page-search-tournament'

import { GamesCyberbetPage } from './imports'

// This is a static route configuration. It should include all of your top level
// routes, regardless of whether they are going to server render. In fact, you
// can totally point multiple routes to the same component! This is great for
// when you only need to server render a handful of routes and not your entire
// application!

export const routes: Array<IRoute> = [
  {
    path: ERoutes.Root,
    component: PageMainLazy,
    name: 'Root',
    exact: true
  },
  {
    path: ERoutes.LiveRedirect,
    component: PageLiveLazy,
    name: 'Live',
    exact: true,
    prefetch: LiveScreenPrefetch
  },
  {
    path: ERoutes.LiveEvents,
    component: PageLiveEventsLazy,
    name: 'LiveEvents',
    exact: true,
    prefetch: pageLiveEventsPrefetch
  },
  {
    path: ERoutes.LiveEvent,
    component: PageLiveEventLazy,
    name: 'LiveEvent',
    exact: true,
    prefetch: pageLiveEventPrefetch
  },
  {
    path: ERoutes.LineEvents,
    component: PageLineLazy,
    name: 'Line',
    exact: true,
    prefetch: pageLinePrefetch
  },
  {
    path: ERoutes.LineTournaments,
    component: PageLineTournamentLazy,
    name: 'Line Tournament',
    exact: true,
    prefetch: pageLineTournamentsPrefetch
  },
  {
    path: ERoutes.LineEvent,
    component: PageLineEventLazy,
    name: 'LineEvent',
    exact: true,
    prefetch: pageLineEventPrefetch
  },
  {
    path: ERoutes.LineRedirect,
    render: () => <Redirect to="/line/100" />,
    name: 'Redirect to Line',
    exact: true
  },
  {
    path: ERoutes.Calendar,
    component: PageCalendarLazy,
    name: 'Calendar',
    exact: true
    // TODO check problem prefetch and infinity pagination loading in the Calendar page
    // prefetch: pageCalendarPrefetch
  },
  {
    path: ERoutes.FavouriteEvents,
    component: PageFavouriteEventsLazy,
    name: 'Favourite events',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Statistics,
    component: PageStatisticsLazy,
    name: 'Statistics',
    exact: true
  },
  {
    path: ERoutes.LiveResults,
    component: PageLiveResultsLazy,
    name: 'Live Results',
    exact: true
  },
  {
    path: ERoutes.Results,
    component: PageResultsLazy,
    name: 'Results',
    exact: true
  },
  {
    path: ERoutes.Casino,
    component: PageCasinoLazy,
    name: 'Casino',
    exact: true
  },
  {
    path: ERoutes.BetGames,
    component: PageGamesBetLazy,
    name: 'BetGames TV',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGames,
    component: PageGamesTurboLazy,
    name: 'TurboGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TwainGames,
    component: PageGamesTwainLazy,
    name: 'TwainGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGamesGame,
    component: PageGamesTurboGameLazy,
    name: 'TurboGamesGame',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.SportGamesLobby,
    component: PageSportGamesLazy,
    name: 'SportGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.LiveGames,
    component: PageGamesLiveLazy,
    name: 'Live Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VirtualGames,
    name: 'Virtual Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.ProfileBalance,
    component: PageBalanceLazy,
    name: 'Balance',
    private: true
  },
  {
    path: ERoutes.BetsHistory,
    component: BetsHistoryLazy,
    name: 'BetsHistory',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Profile,
    component: PageProfileLazy,
    name: 'Profile',
    exact: true,
    private: true
  },
  {
    path: ERoutes.BonusClub,
    component: PageBonusClubLazy,
    name: 'Bonus club',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Identification,
    name: 'Identification',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Won,
    name: 'Won',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Notifications,
    component: PageNotificationLazy,
    name: 'Notifications',
    private: true
  },
  {
    path: ERoutes.TestCoin,
    component: PageTestCoinLazy,
    name: 'TestCoin'
  },
  {
    path: ERoutes.Promotions,
    component: PagePromotionsLazy,
    name: 'Promotions'
  },
  // {    TODO integrate
  //   path: ERoutes.FAQ,
  //   component: FAQScreen,
  //   name: 'FAQ',
  //   exact: true,
  //   private: true
  // },
  // {    TODO integrate
  //   path: ERoutes.SupportPage,
  //   component: SupportPageScreen,
  //   name: 'Support',
  //   exact: true,
  //   private: true
  // },
  {
    path: ERoutes.CategoryFAQ,
    component: PageFAQLazy,
    name: 'Category FAQ',
    exact: true
  },
  {
    path: ERoutes.DetailsFAQ,
    component: PageFAQDetailsLazy,
    name: 'Page FAQ',
    exact: true
  },
  {
    path: ERoutes.About,
    component: PageAboutLazy,
    name: 'About',
    exact: true
  },
  {
    path: ERoutes.Documents,
    component: PageDocumentsLazy,
    name: 'Documents',
    exact: true
  },
  {
    path: ERoutes.RefundPolicy,
    component: PageRefundPolicyLazy,
    name: 'Refund Policy',
    exact: true
  },
  {
    path: ERoutes.ResponsibleGambling,
    component: PageResponsibleGamblingLazy,
    name: 'Responsible Gambling',
    exact: true
  },
  {
    path: ERoutes.PrivacyPolicy,
    component: PagePolicyPrivacyLazy,
    name: 'Privacy Policy',
    exact: true
  },
  {
    path: ERoutes.AMLPolicy,
    component: PagePolicyAmlLazy,
    name: 'AML Policy',
    exact: true
  },
  {
    path: ERoutes.Support,
    component: PageSupportLazy,
    name: 'Support',
    exact: true
  },
  {
    path: ERoutes.Training,
    name: 'Training',
    exact: true
  },
  {
    path: ERoutes.SuccessLoginPage,
    component: PageAuthSuccessLazy,
    name: 'Success Page',
    exact: true
  },
  {
    path: ERoutes.SuccessFastSignUp,
    component: PageAuthSuccessSignUpLazy,
    name: 'Success Fast Sign Up',
    exact: true
  },
  {
    path: ERoutes.SuccessFullSignUp,
    component: PageAuthSuccessSignUpLazy,
    name: 'Success Full Sign Up',
    exact: true
  },
  {
    path: ERoutes.HeadToHead,
    component: PagePageHeadToHeadLazy,
    name: 'Head 2 Head statistics Page',
    exact: true
  },
  {
    path: ERoutes.Search,
    component: PageSearchLazy,
    name: 'Search Page',
    exact: true
  },
  {
    path: ERoutes.SearchTournaments,
    component: PageSearchTournamentsLazy,
    name: 'Search Tournaments Page',
    exact: true
  },
  {
    path: ERoutes.NotFound,
    component: PageNotFoundLazy,
    name: 'Not Found Page',
    exact: true
  },
  {
    path: ERoutes.BetsGames,
    component: PageSportGamesLazy,
    name: 'BetsGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.UnderConstruction,
    component: PageUnderConstructionLazy,
    name: 'Under Construction Page',
    exact: true
  },
  {
    path: ERoutes.CyberSport,
    component: GamesCyberbetPage,
    name: 'CyberSport',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.BetsGamesGame,
    component: PageUnderConstructionLazy,
    name: 'BetsGamesGame',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.MyBonuses,
    component: PageMyBonusesLazy,
    name: 'MyBonuses',
    exact: true,
    private: true
  }
]

export const virtualGamesRoutes = routes.reduce<Set<IRoute['path']>>(
  (acc, route) => {
    if (route.virtualGame) {
      acc.add(route.path)
    }
    return acc
  },
  new Set()
)

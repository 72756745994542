import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getLiveMatchBroadcastContainer = (state: RootState) =>
  state.liveMatchBroadcastContainer ?? initialState

export const selectBroadcastsEventIds = (state: RootState) =>
  getLiveMatchBroadcastContainer(state).broadcastsEventIds

export const selectBroadcastsEventIdsLength = (state: RootState) =>
  getLiveMatchBroadcastContainer(state).broadcastsEventIds.length

export const selectIsShownBroadcastToastifyNotification = (state: RootState) =>
  getLiveMatchBroadcastContainer(state).isShownToastifyNotification

export const selectIsActiveBroadcastsEventId = (
  state: RootState,
  eventId: Event['id']
) => getLiveMatchBroadcastContainer(state).broadcastsEventIds.includes(eventId)

export const selectIsBroadcastMuted = (
  state: RootState,
  eventId: Event['id']
) =>
  getLiveMatchBroadcastContainer(state).options.isMutedBroadcastList.includes(
    eventId
  )

export const selectIsPlayingBroadcast = (
  state: RootState,
  eventId: Event['id']
) =>
  getLiveMatchBroadcastContainer(state).options.isPlayingBroadcastList.includes(
    eventId
  )

import styled, { css } from 'styled-components'

export const StyledLanguagesList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledLanguagesListItem = styled.li<{ checked: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 100%;

  ${({ theme, checked }) => css`
    background-color: ${checked
      ? theme.tokens.colors.surface['surface-hover']
      : theme.tokens.colors.surface['surface-modal']};

    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    padding: 0 ${theme.tokens.alias.space['space-s-12']}px;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-default']};
    }
  `}
`

export const StyledLanguagesListItemContent = styled.div`
  ${({ theme }) => css`
    align-content: center;
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    position: relative;
    width: 100%;
  `}
`

import { FunctionComponent, useCallback } from 'react'
import {
  betsHistoryProviderActions,
  selectBetsHistoryFiltersDates,
  selectBetsHistorySelect,
  SetDatePickerRangeValuesPayload
} from 'astra-core/containers/BetsHistoryProvider'

import { DatePickerRange } from 'shared/ui/DatePicker'
import { PeriodSelects } from 'features/basket/basket-history/ui/PickerPeriod/components/period-selects/period-selects'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { CURRENT_DATE } from './constants'
import { StyledPickerPeriodContainer } from './PickerPeriod.styled'

export type PickerPeriodProps = {
  onChangeDatePicker?: (dateRangeArray: SetDatePickerRangeValuesPayload) => void
  onChangeSelect?: (dateRangeArray: SetDatePickerRangeValuesPayload) => void
  isWithPeriodSelectsOption?: boolean
}

export const PickerPeriod: FunctionComponent<PickerPeriodProps> = ({
  onChangeDatePicker: onChangeDatePickerProp,
  onChangeSelect: onChangeSelectProp,
  isWithPeriodSelectsOption = false
}) => {
  const dispatch = useAppDispatch()
  const dates = useAppSelector(selectBetsHistoryFiltersDates)
  const selectValue = useAppSelector(selectBetsHistorySelect)

  const onChangeDatePicker = (dateRangeArray) => {
    dispatch(
      betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
    )

    onChangeDatePickerProp?.(dateRangeArray)
  }

  const onChangeInputDatePicker = useCallback(
    (data) =>
      dispatch(betsHistoryProviderActions.setDatePickerRangeInputValue(data)),
    [dispatch]
  )

  const onChangeSelect = (dateRangeArray, value) => {
    dispatch(
      betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
    )

    dispatch(betsHistoryProviderActions.setDatePickerSelectDatePeriod(value))

    onChangeSelectProp?.(dateRangeArray)
  }

  const onCalendarClose = useCallback(
    (value) => dispatch(betsHistoryProviderActions.handleCalendarClose(value)),
    [dispatch]
  )

  return (
    <StyledPickerPeriodContainer>
      <DatePickerRange
        maxDate={CURRENT_DATE}
        popperPlacement="bottom-end"
        rangeCalendarDates={dates}
        selectValue={selectValue}
        // @ts-ignore
        onCalendarClose={onCalendarClose}
        onChange={onChangeDatePicker}
        onChangeInputRange={onChangeInputDatePicker}
        onChangeSelect={onChangeSelect}
      />
      {isWithPeriodSelectsOption && (
        <PeriodSelects onChangeSelect={onChangeSelect} />
      )}
    </StyledPickerPeriodContainer>
  )
}

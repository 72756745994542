import { ContentLoader } from 'shared/ui/content-loader/content-loader'

import * as S from './skeleton-loader.styled'

export const SkeletonLoader = () => (
  <S.BasketBodyLoaderWrapper>
    <S.BasketBodyLoaderTitle>
      <ContentLoader
        borderRadius={8}
        height={32}
        width="100%"
        disableAnimation
      />

      <ContentLoader borderRadius={8} height={32} width={32} disableAnimation />
    </S.BasketBodyLoaderTitle>

    <S.BasketBodyLoaderItems>
      <S.BasketBodyLoaderItem>
        <ContentLoader
          borderRadius={4}
          height={14}
          width={176}
          disableAnimation
        />

        <ContentLoader
          borderRadius={4}
          height={14}
          width={134}
          disableAnimation
        />

        <div>
          <ContentLoader
            borderRadius={4}
            height={14}
            width={14}
            disableAnimation
          />

          <ContentLoader
            borderRadius={4}
            height={14}
            width={32}
            disableAnimation
          />
        </div>
      </S.BasketBodyLoaderItem>

      <S.BasketBodyLoaderItem>
        <ContentLoader
          borderRadius={4}
          height={14}
          width={176}
          disableAnimation
        />

        <ContentLoader
          borderRadius={4}
          height={14}
          width={134}
          disableAnimation
        />

        <div>
          <ContentLoader
            borderRadius={4}
            height={14}
            width={14}
            disableAnimation
          />

          <ContentLoader
            borderRadius={4}
            height={14}
            width={32}
            disableAnimation
          />
        </div>
      </S.BasketBodyLoaderItem>

      <S.BasketBodyLoaderItem>
        <ContentLoader
          borderRadius={4}
          height={14}
          width={176}
          disableAnimation
        />

        <ContentLoader
          borderRadius={4}
          height={14}
          width={134}
          disableAnimation
        />

        <div>
          <ContentLoader
            borderRadius={4}
            height={14}
            width={14}
            disableAnimation
          />

          <ContentLoader
            borderRadius={4}
            height={14}
            width={32}
            disableAnimation
          />
        </div>
      </S.BasketBodyLoaderItem>
    </S.BasketBodyLoaderItems>
  </S.BasketBodyLoaderWrapper>
)

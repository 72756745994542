import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon/types'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconLogoTeam: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.42211 4.13064L6.18214 3.79757C12.5489 3.02923 18.985 3.02923 25.3517 3.79757L28.1118 4.13064C28.645 4.19499 29.0322 4.66908 28.9887 5.20441L28.3589 12.9568C28.3167 13.4759 27.883 13.8758 27.3622 13.8758H26.9816C26.4563 13.8758 26.0205 14.2823 25.984 14.8063L25.2009 26.0532C25.1729 26.4544 24.9074 26.7998 24.5268 26.9299L16.0903 29.8146C15.8806 29.8863 15.653 29.8863 15.4433 29.8146L7.0068 26.9299C6.62625 26.7998 6.36068 26.4544 6.33274 26.0532L5.54958 14.8063C5.51309 14.2823 5.07732 13.8758 4.552 13.8758H4.17172C3.65083 13.8758 3.21718 13.4759 3.175 12.9567L2.54521 5.20441C2.50171 4.66907 2.88888 4.19499 3.42211 4.13064ZM17.809 11.7203L15.9747 8.00357L14.1404 11.7203L10.0388 12.3163L13.0068 15.2094L12.3061 19.2944L15.9747 17.3657L19.6434 19.2944L18.9427 15.2094L21.9107 12.3163L17.809 11.7203Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

// Remove when icons will be determined properly on the legacy backend
export const LEGACY_SPORT_ID_CODE_MAP: Record<number, ESportsCodes> = {
  1: ESportsCodes.FOOTBALL,
  2: ESportsCodes.ICE_HOCKEY,
  3: ESportsCodes.TENNIS,
  4: ESportsCodes.BILLIARDS,
  5: ESportsCodes.BASKETBALL,
  6: ESportsCodes.AMERICAN_FOOTBALL,
  7: ESportsCodes.BIATHLON,
  8: ESportsCodes.HOCKEY_WITH_BALL,
  9: ESportsCodes.HANDBALL,
  10: ESportsCodes.VOLLEYBALL,
  11: ESportsCodes.FUTSAL,
  12: ESportsCodes.BOX,
  29: ESportsCodes.BASEBALL,
  30: ESportsCodes.CHESS,
  32: ESportsCodes.RUGBY,
  33: ESportsCodes.FORMULA_1,
  35: ESportsCodes.BEACH_SOCCER,
  37: ESportsCodes.CURLING,
  38: ESportsCodes.SKIING,
  39: ESportsCodes.SKI_JUMPING,
  40: ESportsCodes.TABLE_TENNIS,
  41: ESportsCodes.WATER_POLO,
  44: ESportsCodes.CYCLING,
  45: ESportsCodes.BEACH_HANDBALL,
  46: ESportsCodes.RUGBY_UNION,
  47: ESportsCodes.DARTS,
  48: ESportsCodes.MOTORCYCLE_RACING,
  49: ESportsCodes.HORSE_RACING,
  51: ESportsCodes.BADMINTON,
  53: ESportsCodes.ATHLETICS,
  54: ESportsCodes.SAILING,
  55: ESportsCodes.BULLET_SHOOTING,
  56: ESportsCodes.ARCHERY,
  57: ESportsCodes.PISTOL_SHOOTING,
  58: ESportsCodes.ROWING_ACADEMIC,
  59: ESportsCodes.SWIMMING,
  60: ESportsCodes.BEACH_VOLLEYBALL,
  61: ESportsCodes.DIVING,
  62: ESportsCodes.SOFTBALL,
  63: ESportsCodes.GYMNASTICS,
  65: ESportsCodes.WEIGHTLIFTING,
  66: ESportsCodes.GRECOROMAN_WRESTLING,
  67: ESportsCodes.FIELD_HOCKEY,
  68: ESportsCodes.ROWING_ON_KAYAKS_AND_CANOES,
  69: ESportsCodes.FREESTYLE_WRESTLING,
  70: ESportsCodes.SYNCHRONIZED_SWIMMING,
  72: ESportsCodes.LACROSSE,
  73: ESportsCodes.CRICKET,
  74: ESportsCodes.BOBSLED,
  75: ESportsCodes.ALPINE_SKIING,
  76: ESportsCodes.SKATING,
  77: ESportsCodes.DUATHLON,
  78: ESportsCodes.SKI_JUMPING,
  79: ESportsCodes.SLED,
  80: ESportsCodes.SKELETON,
  81: ESportsCodes.SNOWBOARDING,
  82: ESportsCodes.FIGURE_SKATING,
  83: ESportsCodes.FREESTYLE,
  84: ESportsCodes.SHORT_TRACK,
  85: ESportsCodes.RUGBY_LEAGUE,
  89: ESportsCodes.MINI_FOOTBALL,
  90: ESportsCodes.GOLF,
  91: ESportsCodes.AUSTRALIAN_FOOTBALL,
  93: ESportsCodes.BALLS,
  96: ESportsCodes.MMA,
  101: ESportsCodes.RUGBY_7,
  110: ESportsCodes.HORSE_RACING,
  111: ESportsCodes.FISHING,
  112: ESportsCodes.CYBER_SPORT,
  113: ESportsCodes.FLOORBALL,
  114: ESportsCodes.NORDIC_COMBINED,
  115: ESportsCodes.PESAPALLO,
  116: ESportsCodes.SPEEDWAY,
  118: ESportsCodes.DTM,
  119: ESportsCodes.RALLY,
  120: ESportsCodes.NASCAR,
  122: ESportsCodes.BOX,
  123: ESportsCodes.SQUASH,
  126: ESportsCodes.BILLIARDS,
  127: ESportsCodes.NETBALL,
  128: ESportsCodes.BOWLING,
  129: ESportsCodes.KABBADI,
  130: ESportsCodes.HORSEBACK_RIDING,
  131: ESportsCodes.SEPAK_TAKRAW,
  132: ESportsCodes.SKATEBOARDING,
  133: ESportsCodes.MODERN_PENTATHLON,
  134: ESportsCodes.TRIATHLON,
  135: ESportsCodes.SHORT_HOCKEY,
  136: ESportsCodes.BASKETBALL_3X3,
  137: ESportsCodes.GAELIC_FOOTBALL,
  138: ESportsCodes.CYBER_FOOTBALL,
  139: ESportsCodes.CYBER_TENNIS,
  140: ESportsCodes.CYBER_BASKETBALL,
  141: ESportsCodes.CYBER_HOCKEY,
  144: ESportsCodes.TEQBOL
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M8.71611 5.92041C10.2176 5.92268 11.6679 6.45324 12.8009 7.41476L14.5625 5.69555C12.9614 4.28434 10.8807 3.50311 8.72331 3.50311C6.5659 3.50311 4.48521 4.28434 2.88411 5.69555L4.62651 7.41476C5.7608 6.45219 7.21299 5.92155 8.71611 5.92041Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M15.0905 6.20147L13.3289 7.92068C14.1974 8.86334 14.7428 10.047 14.8891 11.3066C15.0353 12.5662 14.7752 13.839 14.1449 14.9474L10.6553 11.6683L9.08091 7.96753V13.5374L12.5369 16.7837C11.4446 17.6153 10.099 18.0669 8.71371 18.0669C7.32841 18.0669 5.9828 17.6153 4.89051 16.7837L8.34651 13.5374V7.96753L6.77211 11.6776L3.28251 14.9568C2.65222 13.8483 2.39208 12.5756 2.53836 11.316C2.68463 10.0564 3.23004 8.87271 4.09851 7.93005L2.33691 6.20147C1.18098 7.41337 0.412771 8.92828 0.126574 10.5602C-0.159624 12.1922 0.048647 13.8702 0.725822 15.3883C1.403 16.9064 2.51961 18.1985 3.93859 19.106C5.35758 20.0135 7.01719 20.4969 8.71371 20.4969C10.4102 20.4969 12.0699 20.0135 13.4888 19.106C14.9078 18.1985 16.0244 16.9064 16.7016 15.3883C17.3788 13.8702 17.5871 12.1922 17.3009 10.5602C17.0147 8.92828 16.2464 7.41337 15.0905 6.20147Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M23.2697 8.28607H18.7241C18.2212 6.99703 17.4627 5.81745 16.4921 4.81487L15.7001 5.60654C17.3656 7.33612 18.2929 9.62034 18.2929 11.9938C18.2929 14.3673 17.3656 16.6516 15.7001 18.3811L16.5113 19.1728C17.4761 18.1693 18.2281 16.9897 18.7241 15.7016H23.2505C23.3486 15.7041 23.4461 15.6874 23.5375 15.6525C23.6288 15.6176 23.712 15.5652 23.7823 15.4984C23.8525 15.4316 23.9083 15.3518 23.9464 15.2636C23.9845 15.1754 24.0041 15.0806 24.0041 14.9849V9.0028C24.0041 8.81271 23.9267 8.63041 23.789 8.496C23.6513 8.36158 23.4645 8.28607 23.2697 8.28607Z"
        fill="#F06414"
      />
      <path
        d="M20.5865 13.4156L22.3913 11.7526C22.5021 11.6578 22.6048 11.5544 22.6985 11.4434C22.8239 11.2839 22.8974 11.0913 22.9097 10.8906V10.4925C22.9171 10.4333 22.9171 10.3736 22.9097 10.3145C22.8953 10.2435 22.8728 10.1744 22.8425 10.1083L19.7897 12.919C19.6937 13.008 19.5929 13.097 19.5017 13.2001C19.3521 13.3656 19.2701 13.579 19.2713 13.7997V14.4274H22.3865C22.5067 14.4278 22.6241 14.3918 22.7225 14.3244C22.8328 14.2489 22.9206 14.1463 22.9771 14.027C23.0335 13.9077 23.0567 13.776 23.0441 13.6451V13.4156H20.5865ZM22.5161 9.72421C22.3937 9.62901 22.2406 9.57922 22.0841 9.58367H20.2697C20.2107 9.57684 20.1511 9.57684 20.0921 9.58367C19.9788 9.61963 19.8738 9.67703 19.7832 9.75258C19.6926 9.82814 19.6182 9.92037 19.5641 10.024C19.4444 10.2224 19.3845 10.4497 19.3913 10.6798V11.1483H20.4233V10.5721H21.6041L22.5161 9.72421Z"
        fill="white"
      />
      <path
        d="M15.1433 19.0744H15.0809V19.1634H15.0521V18.9245H15.1529C15.163 18.9237 15.1731 18.9251 15.1826 18.9287C15.192 18.9323 15.2005 18.9378 15.2075 18.945C15.2144 18.9523 15.2195 18.9609 15.2226 18.9703C15.2256 18.9797 15.2264 18.9897 15.2249 18.9995C15.2266 19.0161 15.2223 19.0327 15.2126 19.0464C15.2029 19.0602 15.1886 19.0701 15.1721 19.0744L15.2297 19.1634H15.1913L15.1433 19.0744ZM15.0809 19.0463H15.1481C15.1608 19.0463 15.1731 19.0414 15.1821 19.0326C15.1911 19.0238 15.1961 19.0119 15.1961 18.9995C15.1961 18.9871 15.1911 18.9752 15.1821 18.9664C15.1731 18.9576 15.1608 18.9526 15.1481 18.9526H15.0809V19.0463Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M15.1337 19.2712C15.102 19.2731 15.0703 19.2687 15.0404 19.2583C15.0105 19.2479 14.9831 19.2318 14.9597 19.2108C14.9364 19.1897 14.9177 19.1643 14.9047 19.1361C14.8917 19.1078 14.8847 19.0773 14.8841 19.0463C14.8841 18.9842 14.9094 18.9246 14.9544 18.8807C14.9994 18.8368 15.0605 18.8121 15.1241 18.8121C15.1878 18.8121 15.2488 18.8368 15.2938 18.8807C15.3388 18.9246 15.3641 18.9842 15.3641 19.0463C15.3629 19.1056 15.3382 19.1621 15.2953 19.204C15.2523 19.2459 15.1944 19.27 15.1337 19.2712ZM15.1337 18.8496C15.1075 18.8496 15.0815 18.8547 15.0573 18.8646C15.0331 18.8746 15.0112 18.8892 14.9928 18.9075C14.9745 18.9258 14.9601 18.9476 14.9505 18.9714C14.9409 18.9952 14.9363 19.0207 14.9369 19.0463C14.9369 19.0973 14.9576 19.1461 14.9946 19.1821C15.0315 19.2182 15.0815 19.2384 15.1337 19.2384C15.16 19.239 15.1861 19.2345 15.2105 19.2251C15.2349 19.2158 15.2572 19.2017 15.276 19.1838C15.2948 19.1659 15.3097 19.1445 15.3199 19.1209C15.3301 19.0973 15.3353 19.072 15.3353 19.0463C15.3353 18.9942 15.3141 18.9441 15.2763 18.9072C15.2385 18.8703 15.1872 18.8496 15.1337 18.8496Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M5.81073 3.94657C6.81161 3.94809 7.77838 4.30176 8.53366 4.94271L9.70794 3.79668C8.64065 2.85597 7.25366 2.33521 5.81553 2.33521C4.3774 2.33521 2.99041 2.85597 1.92312 3.79668L3.0846 4.94271C3.84072 4.30106 4.80875 3.94734 5.81073 3.94657Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M10.0599 4.13393L8.88562 5.27996C9.46455 5.90833 9.82812 6.69736 9.92562 7.53701C10.0231 8.37667 9.84972 9.22509 9.42957 9.96397L7.1034 7.7781L6.05391 5.31118V9.02405L8.35768 11.1881C7.62956 11.7424 6.73257 12.0434 5.80913 12.0434C4.88569 12.0434 3.9887 11.7424 3.26059 11.1881L5.56435 9.02405V5.31118L4.51486 7.78434L2.18869 9.97022C1.76854 9.23134 1.59513 8.38292 1.69264 7.54326C1.79014 6.70361 2.15371 5.91458 2.73264 5.2862L1.55836 4.13393C0.787813 4.94178 0.275723 5.95162 0.0849438 7.03949C-0.105835 8.12736 0.0329979 9.24592 0.484402 10.2579C0.935807 11.2698 1.68014 12.1311 2.62603 12.7361C3.57193 13.341 4.67823 13.6633 5.80913 13.6633C6.94003 13.6633 8.04633 13.341 8.99223 12.7361C9.93812 12.1311 10.6825 11.2698 11.1339 10.2579C11.5853 9.24592 11.7241 8.12736 11.5333 7.03949C11.3425 5.95162 10.8304 4.94178 10.0599 4.13393Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M15.5122 5.52352H12.4821C12.1468 4.66425 11.6412 3.87794 10.9942 3.20962L10.4663 3.73735C11.5765 4.89029 12.1946 6.41295 12.1946 7.99512C12.1946 9.5773 11.5765 11.1 10.4663 12.2529L11.007 12.7806C11.6501 12.1117 12.1514 11.3253 12.4821 10.4667H15.4994C15.5647 10.4684 15.6298 10.4573 15.6906 10.434C15.7515 10.4107 15.807 10.3758 15.8538 10.3313C15.9007 10.2867 15.9379 10.2335 15.9633 10.1747C15.9887 10.1159 16.0017 10.0528 16.0017 9.98895V6.00129C16.0017 5.87458 15.9501 5.75306 15.8583 5.66346C15.7665 5.57386 15.642 5.52352 15.5122 5.52352Z"
        fill="#F06414"
      />
      <path
        d="M13.7235 8.94286L14.9266 7.83431C15.0004 7.77109 15.0689 7.70217 15.1314 7.62821C15.215 7.52185 15.264 7.39352 15.2722 7.25973V6.99431C15.2771 6.9549 15.2771 6.91505 15.2722 6.87564C15.2626 6.82835 15.2475 6.78226 15.2274 6.73825L13.1924 8.61185C13.1284 8.67118 13.0612 8.73051 13.0004 8.79921C12.9007 8.90955 12.846 9.05181 12.8468 9.19892V9.61735H14.9234C15.0036 9.61763 15.0818 9.59363 15.1474 9.54866C15.2209 9.49835 15.2794 9.42996 15.3171 9.35044C15.3547 9.27092 15.3701 9.18309 15.3618 9.09587V8.94286H13.7235ZM15.0098 6.48219C14.9282 6.41873 14.8262 6.38554 14.7218 6.38851H13.5124C13.473 6.38395 13.4333 6.38395 13.394 6.38851C13.3184 6.41247 13.2485 6.45073 13.1881 6.5011C13.1277 6.55146 13.078 6.61295 13.042 6.68204C12.9622 6.81427 12.9223 6.96581 12.9268 7.11921V7.43148H13.6147V7.04739H14.4019L15.0098 6.48219Z"
        fill="white"
      />
      <path
        d="M10.0951 12.7151H10.0535V12.7744H10.0343V12.6151H10.1015C10.1082 12.6146 10.115 12.6155 10.1213 12.6179C10.1276 12.6203 10.1332 12.624 10.1379 12.6288C10.1425 12.6336 10.1459 12.6394 10.1479 12.6456C10.1499 12.6519 10.1505 12.6586 10.1495 12.6651C10.1507 12.6761 10.1477 12.6872 10.1413 12.6964C10.1349 12.7055 10.1253 12.7121 10.1143 12.7151L10.1527 12.7744H10.1271L10.0951 12.7151ZM10.0535 12.6963H10.0983C10.1068 12.6963 10.1149 12.693 10.1209 12.6872C10.1269 12.6813 10.1303 12.6734 10.1303 12.6651C10.1303 12.6568 10.1269 12.6489 10.1209 12.643C10.1149 12.6372 10.1068 12.6339 10.0983 12.6339H10.0535V12.6963Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M10.0887 12.8462C10.0676 12.8475 10.0464 12.8446 10.0265 12.8376C10.0066 12.8307 9.98828 12.8199 9.97273 12.8059C9.95719 12.7919 9.94471 12.775 9.93604 12.7561C9.92738 12.7373 9.92271 12.717 9.92232 12.6963C9.92232 12.6549 9.93917 12.6152 9.96918 12.5859C9.99918 12.5566 10.0399 12.5402 10.0823 12.5402C10.1247 12.5402 10.1654 12.5566 10.1954 12.5859C10.2254 12.6152 10.2423 12.6549 10.2423 12.6963C10.2415 12.7358 10.225 12.7735 10.1964 12.8014C10.1678 12.8294 10.1292 12.8454 10.0887 12.8462ZM10.0887 12.5652C10.0712 12.5652 10.0539 12.5686 10.0377 12.5752C10.0216 12.5818 10.007 12.5915 9.99479 12.6038C9.98256 12.616 9.97297 12.6305 9.96657 12.6464C9.96017 12.6623 9.95709 12.6792 9.95751 12.6963C9.95751 12.7303 9.97134 12.7628 9.99594 12.7868C10.0205 12.8109 10.0539 12.8243 10.0887 12.8243C10.1062 12.8248 10.1236 12.8218 10.1399 12.8155C10.1562 12.8093 10.171 12.7999 10.1835 12.788C10.1961 12.776 10.206 12.7618 10.2128 12.746C10.2196 12.7303 10.2231 12.7134 10.2231 12.6963C10.2231 12.6615 10.2089 12.6282 10.1837 12.6036C10.1585 12.579 10.1243 12.5652 10.0887 12.5652Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M12.8009 7.41476C11.6679 6.45324 10.2176 5.92268 8.71611 5.92041C7.21299 5.92155 5.7608 6.45219 4.62651 7.41476L2.88411 5.69555C4.48521 4.28434 6.5659 3.50311 8.72331 3.50311C10.8807 3.50311 12.9614 4.28434 14.5625 5.69555L12.8009 7.41476Z"
        fill="currentColor"
      />
      <path
        d="M13.3289 7.92068L15.0905 6.20147C16.2464 7.41337 17.0147 8.92828 17.3009 10.5602C17.5871 12.1922 17.3788 13.8702 16.7016 15.3883C16.0244 16.9064 14.9078 18.1985 13.4888 19.106C12.0699 20.0135 10.4102 20.4969 8.71371 20.4969C7.01719 20.4969 5.35758 20.0135 3.93859 19.106C2.51961 18.1985 1.403 16.9064 0.725822 15.3883C0.048647 13.8702 -0.159624 12.1922 0.126574 10.5602C0.412771 8.92828 1.18098 7.41337 2.33691 6.20147L4.09851 7.93005C3.23004 8.87271 2.68463 10.0564 2.53836 11.316C2.39208 12.5756 2.65222 13.8483 3.28251 14.9568L6.77211 11.6776L8.34651 7.96753V13.5374L4.89051 16.7837C5.9828 17.6153 7.32841 18.0669 8.71371 18.0669C10.099 18.0669 11.4446 17.6153 12.5369 16.7837L9.08091 13.5374V7.96753L10.6553 11.6683L14.1449 14.9474C14.7752 13.839 15.0353 12.5662 14.8891 11.3066C14.7428 10.047 14.1974 8.86334 13.3289 7.92068Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M23.2697 8.28607H18.7241C18.2212 6.99703 17.4627 5.81745 16.4921 4.81487L15.7001 5.60654C17.3656 7.33612 18.2929 9.62034 18.2929 11.9938C18.2929 14.3673 17.3656 16.6516 15.7001 18.3811L16.5113 19.1728C17.4761 18.1693 18.2281 16.9897 18.7241 15.7016H23.2505C23.3486 15.7041 23.4461 15.6874 23.5375 15.6525C23.6288 15.6176 23.712 15.5652 23.7823 15.4984C23.8525 15.4316 23.9083 15.3518 23.9464 15.2636C23.9845 15.1754 24.0041 15.0806 24.0041 14.9849V9.0028C24.0041 8.81271 23.9267 8.63041 23.789 8.49599C23.6513 8.36158 23.4645 8.28607 23.2697 8.28607ZM22.3913 11.7526L20.5865 13.4156H23.0441V13.6451C23.0566 13.776 23.0335 13.9077 22.977 14.027C22.9205 14.1463 22.8327 14.2489 22.7225 14.3244C22.6241 14.3918 22.5067 14.4279 22.3865 14.4274H19.2713V13.7997C19.2701 13.579 19.3521 13.3656 19.5017 13.2001C19.5781 13.1137 19.6613 13.0372 19.7428 12.9623C19.7585 12.9478 19.7741 12.9334 19.7897 12.919L22.8425 10.1083C22.8727 10.1744 22.8952 10.2435 22.9097 10.3145C22.917 10.3736 22.917 10.4333 22.9097 10.4925V10.8906C22.8974 11.0913 22.8238 11.2839 22.6985 11.4434C22.6047 11.5544 22.502 11.6578 22.3913 11.7526ZM22.0841 9.58368C22.2405 9.57923 22.3936 9.62901 22.5161 9.72421L21.6041 10.5721H20.4233V11.1483H19.3913V10.6798C19.3844 10.4497 19.4443 10.2224 19.5641 10.024C19.6181 9.92037 19.6926 9.82814 19.7832 9.75258C19.8738 9.67703 19.9787 9.61963 20.0921 9.58368C20.151 9.57684 20.2107 9.57684 20.2697 9.58368H22.0841Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.1433 19.0744H15.0809V19.1634H15.0521V18.9245H15.1529C15.163 18.9237 15.1731 18.9251 15.1826 18.9287C15.192 18.9323 15.2005 18.9378 15.2075 18.945C15.2144 18.9523 15.2195 18.9609 15.2226 18.9703C15.2256 18.9797 15.2264 18.9897 15.2249 18.9995C15.2266 19.0161 15.2223 19.0327 15.2126 19.0464C15.2029 19.0602 15.1886 19.0701 15.1721 19.0744L15.2297 19.1634H15.1913L15.1433 19.0744ZM15.0809 19.0463H15.1481C15.1608 19.0463 15.1731 19.0414 15.1821 19.0326C15.1911 19.0238 15.1961 19.0119 15.1961 18.9995C15.1961 18.9871 15.1911 18.9752 15.1821 18.9664C15.1731 18.9576 15.1608 18.9526 15.1481 18.9526H15.0809V19.0463Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.0404 19.2583C15.0703 19.2687 15.102 19.2731 15.1337 19.2712C15.1944 19.27 15.2523 19.2459 15.2953 19.204C15.3382 19.1621 15.3629 19.1056 15.3641 19.0463C15.3641 18.9842 15.3388 18.9246 15.2938 18.8807C15.2488 18.8368 15.1878 18.8121 15.1241 18.8121C15.0605 18.8121 14.9994 18.8368 14.9544 18.8807C14.9094 18.9246 14.8841 18.9842 14.8841 19.0463C14.8847 19.0773 14.8917 19.1078 14.9047 19.1361C14.9177 19.1643 14.9364 19.1897 14.9597 19.2108C14.9831 19.2318 15.0105 19.2479 15.0404 19.2583ZM15.0573 18.8646C15.0815 18.8547 15.1075 18.8496 15.1337 18.8496C15.1872 18.8496 15.2385 18.8703 15.2763 18.9072C15.3141 18.9441 15.3353 18.9942 15.3353 19.0463C15.3353 19.072 15.3301 19.0973 15.3199 19.1209C15.3097 19.1445 15.2948 19.1659 15.276 19.1838C15.2572 19.2017 15.2349 19.2158 15.2105 19.2251C15.1861 19.2345 15.16 19.239 15.1337 19.2384C15.0815 19.2384 15.0315 19.2182 14.9946 19.1821C14.9576 19.1461 14.9369 19.0973 14.9369 19.0463C14.9363 19.0207 14.9409 18.9952 14.9505 18.9714C14.9601 18.9476 14.9745 18.9258 14.9928 18.9075C15.0112 18.8892 15.0331 18.8746 15.0573 18.8646Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M8.53366 4.94307C7.77838 4.30213 6.81161 3.94846 5.81073 3.94694C4.80875 3.9477 3.84072 4.30142 3.0846 4.94307L1.92312 3.79705C2.99041 2.85634 4.3774 2.33557 5.81553 2.33557C7.25366 2.33557 8.64065 2.85634 9.70794 3.79705L8.53366 4.94307Z"
        fill="currentColor"
      />
      <path
        d="M8.88562 5.28032L10.0599 4.1343C10.8304 4.94215 11.3425 5.95199 11.5333 7.03986C11.7241 8.12773 11.5853 9.24629 11.1339 10.2582C10.6825 11.2702 9.93812 12.1315 8.99223 12.7364C8.04633 13.3414 6.94003 13.6636 5.80913 13.6636C4.67823 13.6636 3.57193 13.3414 2.62603 12.7364C1.68014 12.1315 0.935807 11.2702 0.484402 10.2582C0.0329979 9.24629 -0.105835 8.12773 0.0849438 7.03986C0.275723 5.95199 0.787813 4.94215 1.55836 4.1343L2.73264 5.28657C2.15371 5.91494 1.79014 6.70397 1.69264 7.54363C1.59513 8.38328 1.76854 9.2317 2.18869 9.97058L4.51486 7.78471L5.56435 5.31155V9.02441L3.26059 11.1884C3.9887 11.7427 4.88569 12.0438 5.80913 12.0438C6.73257 12.0438 7.62956 11.7427 8.35768 11.1884L6.05391 9.02441V5.31155L7.1034 7.77846L9.42957 9.96434C9.84972 9.22546 10.0231 8.37704 9.92562 7.53738C9.82812 6.69773 9.46455 5.9087 8.88562 5.28032Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M15.5122 5.52389H12.4821C12.1468 4.66462 11.6412 3.87831 10.9942 3.20999L10.4663 3.73772C11.5765 4.89065 12.1946 6.41331 12.1946 7.99549C12.1946 9.57767 11.5765 11.1003 10.4663 12.2533L11.007 12.781C11.6501 12.1121 12.1514 11.3257 12.4821 10.4671H15.4994C15.5647 10.4688 15.6298 10.4576 15.6906 10.4344C15.7515 10.4111 15.807 10.3762 15.8538 10.3316C15.9007 10.2871 15.9379 10.2339 15.9633 10.1751C15.9887 10.1163 16.0017 10.0531 16.0017 9.98932V6.00166C16.0017 5.87495 15.9501 5.75343 15.8583 5.66383C15.7665 5.57423 15.642 5.52389 15.5122 5.52389ZM14.9266 7.83467L13.7235 8.94322H15.3617V9.09624C15.3701 9.18346 15.3547 9.27129 15.317 9.35081C15.2794 9.43033 15.2208 9.49872 15.1474 9.54902C15.0818 9.594 15.0035 9.618 14.9234 9.61772H12.8468V9.19928C12.846 9.05217 12.9007 8.90991 13.0004 8.79958C13.0513 8.74199 13.1068 8.69099 13.1611 8.64105C13.1716 8.63141 13.182 8.62182 13.1924 8.61222L15.2273 6.73861C15.2475 6.78263 15.2625 6.82872 15.2721 6.87601C15.277 6.91542 15.277 6.95526 15.2721 6.99467V7.2601C15.264 7.39389 15.2149 7.52222 15.1314 7.62858C15.0689 7.70254 15.0004 7.77146 14.9266 7.83467ZM14.7218 6.38887C14.8261 6.38591 14.9281 6.4191 15.0098 6.48255L14.4018 7.04776H13.6147V7.43185H12.9268V7.11958C12.9222 6.96618 12.9622 6.81464 13.042 6.68241C13.078 6.61332 13.1276 6.55183 13.188 6.50147C13.2484 6.4511 13.3184 6.41284 13.3939 6.38887C13.4333 6.38432 13.473 6.38432 13.5123 6.38887H14.7218Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.0951 12.7154H10.0535V12.7747H10.0343V12.6155H10.1015C10.1082 12.6149 10.115 12.6159 10.1213 12.6183C10.1276 12.6206 10.1332 12.6244 10.1379 12.6292C10.1425 12.634 10.1459 12.6397 10.1479 12.646C10.1499 12.6523 10.1505 12.6589 10.1495 12.6655C10.1507 12.6765 10.1477 12.6876 10.1413 12.6967C10.1349 12.7059 10.1253 12.7125 10.1143 12.7154L10.1527 12.7747H10.1271L10.0951 12.7154ZM10.0535 12.6967H10.0983C10.1068 12.6967 10.1149 12.6934 10.1209 12.6875C10.1269 12.6817 10.1303 12.6737 10.1303 12.6655C10.1303 12.6572 10.1269 12.6492 10.1209 12.6434C10.1149 12.6375 10.1068 12.6342 10.0983 12.6342H10.0535V12.6967Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.0265 12.838C10.0464 12.8449 10.0676 12.8478 10.0887 12.8466C10.1292 12.8458 10.1678 12.8297 10.1964 12.8018C10.225 12.7738 10.2415 12.7362 10.2423 12.6967C10.2423 12.6553 10.2254 12.6156 10.1954 12.5863C10.1654 12.557 10.1247 12.5405 10.0823 12.5405C10.0399 12.5405 9.99918 12.557 9.96918 12.5863C9.93917 12.6156 9.92232 12.6553 9.92232 12.6967C9.92271 12.7173 9.92738 12.7377 9.93604 12.7565C9.94471 12.7753 9.95719 12.7923 9.97273 12.8063C9.98828 12.8203 10.0066 12.8311 10.0265 12.838ZM10.0377 12.5756C10.0539 12.5689 10.0712 12.5655 10.0887 12.5655C10.1243 12.5655 10.1585 12.5793 10.1837 12.6039C10.2089 12.6285 10.2231 12.6619 10.2231 12.6967C10.2231 12.7138 10.2196 12.7307 10.2128 12.7464C10.206 12.7621 10.1961 12.7764 10.1835 12.7883C10.171 12.8003 10.1562 12.8096 10.1399 12.8159C10.1236 12.8221 10.1062 12.8251 10.0887 12.8247C10.0539 12.8247 10.0205 12.8112 9.99594 12.7872C9.97134 12.7632 9.95751 12.7306 9.95751 12.6967C9.95709 12.6796 9.96017 12.6626 9.96657 12.6467C9.97297 12.6308 9.98256 12.6163 9.99479 12.6041C10.007 12.5919 10.0216 12.5822 10.0377 12.5756Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  )
}

export const IconCybersportOverwatch2: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconBankSimply: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 43 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2254 2.81223C11.002 2.81223 11.6315 2.18269 11.6315 1.40611C11.6315 0.629538 11.002 0 10.2254 0C9.44881 0 8.81927 0.629538 8.81927 1.40611C8.81927 2.18269 9.44881 2.81223 10.2254 2.81223Z"
        fill="currentColor"
      />
      <path
        d="M1.0216 4.28194C0.792344 2.65213 1.95373 1.36963 3.91315 1.08887C4.50697 1.00378 5.2983 1.09397 5.54852 1.14368C7.06969 1.44584 7.99602 2.35425 8.10566 3.6513L8.12516 3.88208H6.28823L6.24999 3.7216C6.07536 2.98896 5.53287 2.64409 4.55507 2.64409C3.60057 2.64409 3.035 3.01634 2.98747 3.67584C2.9381 4.36102 3.37302 4.62992 5.15114 5.01364C7.5213 5.52509 8.27941 6.18465 8.27748 7.73361C8.27537 9.42645 7.06908 10.5015 4.98302 10.6697C2.97491 10.8316 1.3893 10.0585 0.936074 8.69649C0.840366 8.40882 0.76338 7.91387 0.806083 7.87116C1.43189 7.87116 2.0577 7.87116 2.68351 7.87116L2.72624 8.03828C3.09577 9.48251 6.18889 9.47208 6.30495 8.02623C6.36251 7.30915 5.94985 7.05137 4.07443 6.63305C1.93194 6.15513 1.20651 5.59644 1.0216 4.28194Z"
        fill="currentColor"
      />
      <path
        d="M14.7418 3.94945C15.131 3.65628 15.365 3.54232 15.7276 3.46933C16.6161 3.29041 17.5238 3.58206 18.1316 4.24177L18.3195 4.44569L18.5302 4.24688C20.1742 2.69602 22.7332 3.42696 23.1384 5.56321C23.179 5.77722 23.1861 6.16185 23.1861 8.14963V10.4847H21.2603L21.2598 8.41374C21.2592 6.16631 21.2561 6.11737 21.0888 5.74466C20.692 4.86049 19.3284 5.02501 18.9719 6.00009C18.9719 6.00009 18.8891 6.28166 18.8913 6.52619C18.8936 6.77071 18.8744 10.4847 18.8744 10.4847H16.9197L16.9186 8.39846C16.9175 6.12988 16.9159 6.10557 16.745 5.74047C16.3752 4.95027 15.1576 4.98197 14.7291 5.79296C14.7239 5.80284 14.7188 5.81243 14.7139 5.82177C14.6808 5.88412 14.6535 5.93559 14.6309 5.98906C14.5239 6.24225 14.5224 6.54002 14.5137 8.248L14.5021 10.4847H12.5791V3.54584H14.5049V4.12788L14.7418 3.94945Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M26.5305 3.92876C26.8715 3.68541 27.2146 3.5481 27.6795 3.4689C30.2328 3.03389 32.3581 5.71568 31.4654 8.24608C30.715 10.373 28.1467 11.2962 26.4797 10.0382L26.3345 9.92861V13.2358H24.4088C24.4043 10.0058 24.3991 6.77583 24.3991 3.54584H26.3345V4.06858L26.5305 3.92876ZM29.2444 8.38317C30.2814 7.397 29.9424 5.71859 28.6165 5.27349C27.448 4.88125 26.2123 5.77648 26.2123 7.01527C26.2123 8.59253 28.1195 9.45298 29.2444 8.38317Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M35.4186 3.51527L37.4459 3.51528C38.0434 5.04472 38.6412 6.57579 39.2509 8.10046C39.2509 8.10046 40.3729 5.02963 40.9546 3.51528L43 3.51738C41.7638 6.77237 40.4402 9.99467 39.168 13.2358H37.1513C37.511 12.2995 38.2603 10.4384 38.2603 10.4384L35.4186 3.51527Z"
        fill="currentColor"
      />
      <path
        d="M9.24721 10.4847V3.54584H11.173V10.4847H9.24721Z"
        fill="currentColor"
      />
      <path
        d="M34.6796 1.28394H32.7232V10.4848H34.6796V1.28394Z"
        fill="#FFDD04"
      />
      <path
        d="M35.1075 12.594C35.1075 13.3706 34.478 14.0001 33.7014 14.0001C32.9248 14.0001 32.2953 13.3706 32.2953 12.594C32.2953 11.8174 32.9248 11.1879 33.7014 11.1879C34.478 11.1879 35.1075 11.8174 35.1075 12.594Z"
        fill="#FFDD04"
      />
    </svg>
  </Icon>
)

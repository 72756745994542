import styled, { css } from 'styled-components'

import { Button as ButtonShared } from 'shared/ui/Button'

export const Button = styled(ButtonShared)<{
  isBonus: boolean
  isCoupon: boolean
}>`
  justify-content: center;
  width: 100%;

  ${({ isBonus, theme, isCoupon }) => css`
    ${isBonus &&
    css`
      background: ${theme.tokens.colors.icon['icon-warning']} !important;
      color: #664d00 !important;
    `}

    ${isCoupon &&
    css`
      background: #7845e7 !important;
      color: ${theme.tokens.colors.text['text-invert']} !important;

      &:disabled {
        background: #c9b4f6 !important;
      }
    `}
  `}
`

import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding-bottom: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const HotProbsBlock = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const ProbabilitiesTable = styled.div`
  display: grid;
  gap: 1px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  overflow: hidden;
  position: relative;
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    &:before {
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      bottom: 0;
      box-shadow: inset 0 0 0 1px
        ${theme.tokens.colors.border['border-primary']};
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  `}
`

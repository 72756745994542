import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M15.0985 6.1974L15.7078 5.23444H11.3952L11.7775 4.63076H16.016L16.5345 3.80957H12.2959L12.683 3.19611H16.9239L17.5332 2.23316H11.6676L9.15884 6.1974H15.0985Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.33585 3.19611L4.76263 6.94772L5.65382 6.95994L9.43839 5.32487H7.67751L9.00115 3.19611H10.6067L11.216 2.23316H2.22763L4.10558 3.19611H7.33585Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.40733 10.1054L9.73943 9.5824H13.4165L13.8657 8.87118H10.1886L10.5255 8.33838H14.2025L14.7306 7.50252H9.64147L7.64406 10.6578L6.05043 9.2329L6.0576 9.17669L9.58174 7.50252H7.71574L5.10907 8.74165C5.02783 8.77342 5.04456 8.71965 5.07562 8.66344L5.81151 7.50252H4.39946L2.22286 10.9388H3.6349L4.46158 9.63372H4.9466L6.43271 10.9388H12.6185L13.1465 10.103H9.40494L9.40733 10.1054Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.1437 6.1974L18.097 4.69187H18.6561L20.3692 6.1974H22.1301L19.9296 4.2275L19.9391 4.16395L24.0009 2.23316H21.8481L18.8425 3.66048C18.7493 3.69714 18.7684 3.63359 18.8042 3.57005L19.6524 2.23071H18.0253L15.5166 6.19495H17.1437V6.1974Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.4148 9.70949L16.9574 7.50252H15.1176L12.9745 10.9388H15.2944L16.8044 10.125H14.8691L15.918 8.47036H15.9491L18.1568 11.7991L20.7801 8.33838H22.068L23.1861 7.50252H19.9702L18.4148 9.70949Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        d="M22.0656 12.9828C21.3846 13.5107 20.7371 14.0167 20.0873 14.5201C19.9917 14.5959 19.8842 14.6594 19.8006 14.7474C19.134 15.4342 18.2332 15.6444 17.4018 16.0037C14.6446 17.189 12.3963 19.0245 10.7119 21.5639C10.6258 21.6934 10.547 21.7692 10.3797 21.7692C8.37039 21.7618 6.36103 21.7643 4.35168 21.7643C4.323 21.7643 4.29194 21.757 4.25133 21.7496C4.24177 21.5956 4.36601 21.5174 4.44008 21.4221C5.5248 20.0535 6.84127 18.9536 8.25571 17.9711C10.2077 16.6147 12.2935 15.5173 14.463 14.5764C14.5227 14.5519 14.6039 14.5544 14.6302 14.4444H0.000854492C0.309068 14.2 0.547992 14.0093 0.789306 13.8236C1.12858 13.5621 1.47024 13.3079 1.80713 13.0439C1.9027 12.9706 1.99827 12.9437 2.11534 12.9437C8.73117 12.9437 15.3446 12.9437 21.9604 12.9437C21.9772 12.9437 21.9939 12.9559 22.068 12.9877L22.0656 12.9828Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M10.066 4.13164L10.4721 3.48967H7.59707L7.85192 3.08722H10.6776L11.0233 2.53975H8.19757L8.45561 2.13078H11.2829L11.6891 1.48881H7.77866L6.10618 4.13164H10.066Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.89085 2.13078L3.17537 4.63185L3.7695 4.64L6.29254 3.54996H5.11863L6.00105 2.13078H7.07144L7.47761 1.48881H1.48537L2.73734 2.13078H4.89085Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.27184 6.73699L6.49324 6.38831H8.94461L9.24406 5.91416H6.79269L7.01728 5.55896H9.46865L9.82067 5.00172H6.42793L5.09632 7.10523L4.03391 6.15531L4.03868 6.11783L6.38811 5.00172H5.14411L3.40633 5.82781C3.35217 5.84899 3.36332 5.81314 3.38403 5.77567L3.87462 5.00172H2.93326L1.48219 7.2926H2.42355L2.97467 6.42252H3.29802L4.28876 7.2926H8.4126L8.76462 6.73536H6.27024L6.27184 6.73699Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.4294 4.13164L12.065 3.12795H12.4377L13.5798 4.13164H14.7537L13.2867 2.81837L13.293 2.77601L16.0009 1.48881H14.5657L12.5619 2.44036C12.4998 2.4648 12.5126 2.42244 12.5364 2.38007L13.1019 1.48718H12.0172L10.3447 4.13001H11.4294V4.13164Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.2768 6.47303L11.3052 5.00172H10.0787L8.64993 7.2926H10.1966L11.2032 6.75003H9.91305L10.6123 5.64695H10.633L12.1048 7.86614L13.8537 5.55896H14.7123L15.4577 5.00172H13.3137L12.2768 6.47303Z"
        fill="#DD1323"
        fillRule="evenodd"
      />
      <path
        d="M14.7107 8.65526C14.2567 9.00721 13.825 9.34448 13.3918 9.68013C13.3281 9.73064 13.2564 9.77301 13.2007 9.83166C12.7563 10.2895 12.1558 10.4296 11.6015 10.6692C9.76332 11.4594 8.26447 12.6831 7.14152 14.376C7.08418 14.4623 7.03162 14.5128 6.92012 14.5128C5.58055 14.5079 4.24097 14.5096 2.9014 14.5096C2.88229 14.5096 2.86158 14.5047 2.8345 14.4998C2.82813 14.3971 2.91096 14.345 2.96034 14.2815C3.68348 13.369 4.56113 12.6358 5.50409 11.9808C6.80543 11.0765 8.19598 10.3449 9.64227 9.71761C9.68209 9.70131 9.73625 9.70294 9.75377 9.62962H0.000854492C0.20633 9.46669 0.365613 9.3396 0.526489 9.21576C0.752671 9.04142 0.980446 8.87197 1.20504 8.696C1.26875 8.64712 1.33246 8.62919 1.41051 8.62919C5.82106 8.62919 10.23 8.62919 14.6406 8.62919C14.6517 8.62919 14.6629 8.63734 14.7123 8.65852L14.7107 8.65526Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportTekken7: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M6.24276 10.4855C8.58597 10.4855 10.4855 8.58597 10.4855 6.24276C10.4855 3.89955 8.58597 2 6.24276 2C3.89955 2 2 3.89955 2 6.24276C2 8.58597 3.89955 10.4855 6.24276 10.4855Z"
        fill={theme.tokens.colors['sport-icon']['sport-fieldHockey']}
      />
      <path
        d="M8.99281 21.9986C8.8928 21.8996 8.75762 21.9243 8.6376 21.8953C6.41394 21.3543 4.83034 20.0656 4.17994 17.7641C3.773 16.3241 4.09096 14.9584 4.78551 13.6711C4.90208 13.4554 5.02278 13.2425 5.14969 13.0339C5.68353 12.1568 6.73259 11.8357 7.62509 12.2728C8.50863 12.705 8.93349 13.7602 8.60381 14.7278C8.48173 15.0864 8.33964 15.4386 8.38861 15.829C8.45828 16.3885 8.83486 16.7902 9.39974 16.9211C10.0088 17.0625 10.504 16.8793 10.813 16.3757C11.5862 15.1175 12.3524 13.855 13.1208 12.5939C14.9941 9.51871 16.8667 6.44347 18.7393 3.36823C19.0076 2.92689 19.0745 2.90992 19.5159 3.1709C20.2511 3.60588 20.9871 4.04085 21.7209 4.47866C22.0354 4.66609 22.0706 4.79764 21.894 5.12016C19.331 9.80727 16.7756 14.4986 14.1988 19.178C13.3912 20.6449 12.1973 21.6174 10.544 21.9229C10.4598 21.9385 10.3736 21.9434 10.2881 21.9533C10.1881 22.0071 10.0708 21.9335 9.9729 22H9.35353C9.31905 21.9668 9.28456 21.9668 9.25008 22H8.99212L8.99281 21.9986Z"
        fill={theme.tokens.colors['sport-icon']['sport-fieldHockey']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M3.39423 6.78846C5.26881 6.78846 6.78846 5.26881 6.78846 3.39423C6.78846 1.51965 5.26881 0 3.39423 0C1.51965 0 0 1.51965 0 3.39423C0 5.26881 1.51965 6.78846 3.39423 6.78846Z"
        fill={theme.tokens.colors['sport-icon']['sport-fieldHockey']}
      />
      <path
        d="M5.8833 15.9989C5.80551 15.9207 5.70037 15.9403 5.60703 15.9174C3.87751 15.4902 2.64582 14.4728 2.13995 12.6559C1.82345 11.519 2.07075 10.4408 2.61095 9.42457C2.70161 9.25426 2.79549 9.08619 2.8942 8.92147C3.30941 8.22909 4.12535 7.97558 4.81952 8.32066C5.50671 8.66183 5.83716 9.49492 5.58074 10.2588C5.48579 10.5419 5.37528 10.8199 5.41337 11.1282C5.46755 11.5698 5.76045 11.887 6.1998 11.9903C6.67349 12.102 7.05866 11.9574 7.29899 11.5598C7.90035 10.5664 8.49634 9.56975 9.09395 8.57416C10.5509 6.14635 12.0074 3.71853 13.4639 1.29071C13.6725 0.942285 13.7246 0.928884 14.0679 1.13492C14.6398 1.47833 15.2122 1.82173 15.7829 2.16736C16.0276 2.31533 16.0549 2.41919 15.9176 2.67381C13.9241 6.37416 11.9366 10.0779 9.93242 13.7721C9.30424 14.9302 8.37564 15.6979 7.08977 15.9391C7.02432 15.9514 6.95727 15.9553 6.89075 15.9631C6.81296 16.0056 6.72177 15.9475 6.64559 16H6.16386C6.13704 15.9738 6.11021 15.9738 6.08339 16H5.88276L5.8833 15.9989Z"
        fill={theme.tokens.colors['sport-icon']['sport-fieldHockey']}
      />
    </>
  )
}

export const IconSportFieldHockey: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

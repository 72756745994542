import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'
import {
  BalanceOperationsTypes,
  PaymentMethods
} from 'entities/payment/model/store/types'

import { initialState } from './slice'

// activePaymentTabValue
const getActivePaymentTabValue = (
  state: RootState,
  balanceOperationType: BalanceOperationsTypes
) =>
  state.paymentProvider[balanceOperationType].layout.activePaymentTabValue ||
  initialState[balanceOperationType].layout.activePaymentTabValue

export const selectActivePaymentTabValue = createSelector(
  [getActivePaymentTabValue],
  (value) => value
)

// activePaymentMethod
const getActivePaymentMethodKey = (
  state: RootState,
  balanceOperationType: BalanceOperationsTypes
) =>
  state.paymentProvider[balanceOperationType].layout
    .activePaymentMethodVariantKey ||
  initialState[balanceOperationType].layout.activePaymentMethodVariantKey

export const selectActivePaymentMethodKey = createSelector(
  [getActivePaymentMethodKey],
  (value) => value
)

// paymentMethodVariants
const getPaymentMethodVariants = (
  state: RootState,
  paymentMethod: PaymentMethods,
  balanceOperationType: BalanceOperationsTypes
) =>
  state.paymentProvider[balanceOperationType].data.paymentMethodsWithVariants[
    paymentMethod
  ].variantList ||
  initialState[balanceOperationType].layout.activePaymentMethodVariantKey[
    paymentMethod
  ].variantList

export const selectPaymentMethodVariants = createSelector(
  [getPaymentMethodVariants],
  (data) => data
)

// paymentMethodTitle
const getPaymentMethodTitle = (
  state: RootState,
  paymentMethod: PaymentMethods,
  balanceOperationType: BalanceOperationsTypes
) =>
  state.paymentProvider[balanceOperationType].data.paymentMethodsWithVariants[
    paymentMethod
  ].title ||
  initialState[balanceOperationType].layout.activePaymentMethodVariantKey[
    paymentMethod
  ].title

export const selectPaymentMethodTitle = createSelector(
  [getPaymentMethodTitle],
  (data) => data
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M6.77177 12.9312C6.32779 11.9927 6.09515 10.9762 6.09515 9.97564C6.09515 6.92407 8.61595 3.07439 12.9964 3.07439C14.6745 3.07439 16.1382 3.36768 17.3047 3.72876C15.8172 2.64167 13.9835 2 12 2C7.02944 2 3 6.02944 3 11C3 12.9038 3.59112 14.6695 4.59984 16.1237C4.70653 15.7482 4.82011 15.4049 4.9406 15.095C5.16312 14.5226 5.42042 14.0344 5.72392 13.6632C6.00338 13.3213 6.35 13.0458 6.77177 12.9312ZM8.22442 12.9914C8.2065 12.9626 8.18885 12.9337 8.17146 12.9046C7.63046 12.0002 7.34515 10.9763 7.34515 9.97564C7.34515 7.45866 9.4524 4.32439 12.9964 4.32439C14.9901 4.32439 16.6551 4.77799 17.8209 5.23166C18.4033 5.45828 18.8589 5.68405 19.1658 5.85114C19.3192 5.93464 19.4352 6.00333 19.511 6.04992L19.5221 6.05677C20.4564 7.47551 21 9.17427 21 11C21 12.9038 20.4089 14.6696 19.4001 16.1238C19.2934 15.7482 19.1798 15.4049 19.0593 15.095C18.8368 14.5226 18.5795 14.0344 18.276 13.6632C17.9718 13.291 17.5881 12.9976 17.1145 12.9046C16.7846 12.8398 16.4323 12.8384 16.0974 12.9046C15.7734 12.9686 15.4657 13.0959 15.2105 13.2903L15.1887 13.3069L12.003 13.2917L8.8112 13.3069L8.78925 13.2902C8.62041 13.1616 8.42865 13.0624 8.22442 12.9914Z"
        fill={theme.tokens.colors['sport-icon']['sport-tennis']}
        fillRule="evenodd"
      />
      <path
        d="M8.83857 14.4208L12 14.4058L15.1614 14.4208L15.3441 14.2814C15.6632 14.038 16.1624 13.9423 16.6317 14.0346C17.6644 14.2376 18.4618 16.2918 18.9362 19.9709C19.185 21.9007 18.7142 22.7305 17.3763 22.7203C16.5385 22.7135 16.2731 22.3775 15.6398 20.5226C15.3332 19.624 15 19.5313 14.8957 19.5313C14.6667 19.5313 12.9078 19.5309 12 19.5313C11.0926 19.5313 9.33333 19.5313 9.10431 19.5313C9 19.5313 8.66682 19.624 8.36023 20.5226C7.72688 22.3775 7.46148 22.7135 6.62374 22.7203C5.28578 22.7305 4.81496 21.9007 5.06378 19.9709C5.53819 16.2918 6.3356 14.2376 7.36834 14.0346C7.83762 13.9423 8.33681 14.038 8.65588 14.2814L8.83857 14.4208Z"
        fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M3.41229 9.99127C2.93377 9.08131 2.6818 8.07689 2.6818 7.08946C2.6818 4.34725 4.94549 0.885566 8.8857 0.885566C10.292 0.885566 11.5298 1.11606 12.5381 1.41084C11.2487 0.521052 9.68519 0 8 0C3.58172 0 0 3.58172 0 7.99999C0 10.2986 0.969458 12.3708 2.52181 13.8301C2.53001 13.731 2.54096 13.6298 2.55428 13.5266C2.72761 12.1844 2.96306 11.1098 3.26194 10.341C3.3096 10.2185 3.35963 10.1016 3.41229 9.99127ZM4.30611 8.95697C4.05971 8.35447 3.9318 7.71671 3.9318 7.08946C3.9318 4.88184 5.78193 2.13557 8.8857 2.13557C10.6474 2.13557 12.1187 2.53641 13.149 2.93731C13.6635 3.13754 14.0658 3.33693 14.3364 3.48423C14.4716 3.55783 14.5737 3.61828 14.6401 3.6591C14.6733 3.6795 14.6976 3.69499 14.7127 3.70474L14.7283 3.71496L14.7301 3.71618C14.7301 3.71614 14.73 3.71611 14.7442 3.69515C15.5392 4.938 16 6.41518 16 7.99999C16 10.2985 15.0307 12.3706 13.4785 13.8298C13.4703 13.7308 13.4593 13.6297 13.446 13.5266C13.2727 12.1844 13.0373 11.1098 12.7384 10.341C12.589 9.95689 12.4163 9.62921 12.2126 9.38001C12.0084 9.13024 11.7509 8.93327 11.433 8.87087C11.2116 8.8274 10.9751 8.82645 10.7504 8.87087C10.5329 8.91384 10.3264 8.99928 10.155 9.12976L10.1404 9.14089L8.00222 9.13072L5.85988 9.14089L5.84514 9.12966C5.67388 8.99923 5.46749 8.91383 5.25015 8.87087C5.02533 8.82642 4.7888 8.82738 4.56731 8.87087C4.47524 8.88894 4.38823 8.91831 4.30611 8.95697Z"
        fill={theme.tokens.colors['sport-icon']['sport-tennis']}
        fillRule="evenodd"
      />
      <path
        d="M5.93933 10.0497L8.00004 10.0399L10.0607 10.0497L10.1798 9.9588C10.3878 9.80017 10.7132 9.73779 11.0191 9.79794C11.6922 9.93028 12.212 11.2693 12.5212 13.6674C12.6834 14.9253 12.3765 15.4662 11.5044 15.4595C10.9584 15.455 10.7854 15.236 10.3725 14.027C10.1727 13.4412 9.95551 13.3809 9.88752 13.3809C9.73824 13.3809 8.59179 13.3806 8.00004 13.3809C7.40854 13.3809 6.26183 13.3809 6.11255 13.3809C6.04456 13.3809 5.82738 13.4412 5.62754 14.027C5.21471 15.236 5.04171 15.455 4.49565 15.4595C3.62353 15.4662 3.31664 14.9253 3.47883 13.6674C3.78807 11.2693 4.30784 9.93028 4.981 9.79794C5.28689 9.73779 5.61228 9.80017 5.82025 9.9588L5.93933 10.0497Z"
        fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      />
    </>
  )
}

export const IconSportCybertennis: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

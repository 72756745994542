import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledEventCouponError = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-error']};
  `}
`

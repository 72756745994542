import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLogo = styled(NavLink)`
  align-items: center;
  display: grid;
`

export const LogoWrapper = styled.div`
  height: ${({ theme }) => theme.tokens.adaptive.Desktop.logo.height}px;
  width: ${({ theme }) => theme.tokens.adaptive.Desktop.logo.width}px;
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IconSizeMap, IIconsSportNameProps } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM9.69533 4.99518L9.7263 4.40672C10.4465 4.19137 11.2097 4.0757 12 4.0757C12.9935 4.0757 13.9444 4.25854 14.8207 4.59241L14.7016 4.96938L12.3427 5.61809L9.69533 4.99518ZM9.32775 6.01377L6.69082 8.26481L7.09225 10.6333L9.75595 11.2L12.0205 9.20186L11.7832 6.59152L9.32775 6.01377ZM12.7668 9.97793L10.499 11.979L10.9642 14.7123L14.0276 15.4235L15.9346 13.7345L15.4868 10.8846L12.7668 9.97793ZM4.93629 8.40482L5.63478 8.47134L6.04356 10.8831L4.99457 13.2896L4.19656 13.3863C4.11713 12.9362 4.0757 12.4729 4.0757 12C4.0757 10.7057 4.38601 9.48384 4.93629 8.40482ZM5.05764 14.3655L4.45789 14.4382C4.91828 15.8634 5.77139 17.112 6.88965 18.0565L7.57767 17.9533L7.35818 16.929L5.05764 14.3655ZM12.0598 19.0099L8.67129 17.923L8.45834 16.9292L10.577 15.7267L13.5112 16.4079L13.8592 18.032L12.0598 19.0099ZM14.7722 18.7601L12.6835 19.8952C13.8305 19.7972 14.9078 19.4549 15.8631 18.9205L14.7722 18.7601ZM18.8057 14.1958L16.9924 13.5365L16.5426 10.6738L17.8509 8.71131L19.4007 9.16126C19.7389 10.0426 19.9243 10.9996 19.9243 12C19.9243 12.4468 19.8873 12.8849 19.8163 13.3116L18.8057 14.1958ZM15.741 5.25026L15.8051 5.04733C17.015 5.71092 18.0329 6.68115 18.7541 7.85344L17.8301 7.58518L15.741 5.25026Z"
      fill={theme.tokens.colors['sport-icon']['sport-football']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM5.9514 1.7735L5.97893 1.25042C6.61912 1.059 7.29755 0.956175 8 0.956175C8.88314 0.956175 9.72832 1.1187 10.5073 1.41548L10.4015 1.75056L8.3046 2.32719L5.9514 1.7735ZM5.62467 2.6789L3.28073 4.67983L3.63755 6.78511L6.00529 7.28889L8.01823 5.51276L7.80729 3.19246L5.62467 2.6789ZM8.68162 6.2026L6.66574 7.98131L7.07931 10.411L9.80231 11.0431L11.4974 9.54174L11.0994 7.00852L8.68162 6.2026ZM1.72115 4.80428L2.34203 4.86341L2.70539 7.00722L1.77295 9.14633L1.06361 9.23231C0.993002 8.83218 0.956175 8.42039 0.956175 8C0.956175 6.8495 1.23201 5.76342 1.72115 4.80428ZM1.82901 10.1027L1.2959 10.1673C1.70514 11.4341 2.46345 12.544 3.45747 13.3836L4.06904 13.2918L3.87394 12.3813L1.82901 10.1027ZM8.05313 14.2311L5.04114 13.2649L4.85186 12.3815L6.73507 11.3127L9.3433 11.9182L9.65264 13.3618L8.05313 14.2311ZM10.4642 14.009L8.60757 15.018C9.62715 14.9309 10.5847 14.6266 11.4339 14.1516L10.4642 14.009ZM14.0495 9.95186L12.4377 9.36576L12.0378 6.82113L13.2008 5.07672L14.5784 5.47667C14.8791 6.26005 15.0438 7.11078 15.0438 8C15.0438 8.39715 15.011 8.78662 14.9478 9.16584L14.0495 9.95186ZM11.3253 2.00023L11.3823 1.81985C12.4578 2.40971 13.3626 3.27214 14.0037 4.31417L13.1823 4.07571L11.3253 2.00023Z"
      fill={theme.tokens.colors['sport-icon']['sport-football']}
      fillRule="evenodd"
    />
  )
}

export const IconSportFootball: FC<IIconsSportNameProps> = ({ ...props }) => (
  <SportIconWrapper {...props}>
    {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
  </SportIconWrapper>
)

import { selectBonusOnExpress } from 'astra-core/containers/BasketProvider'
import { bonusOnExpressToPercent, useGetCurrencyIcon } from 'astra-core'
import { FC, useMemo } from 'react'
import { selectCurrencyMaxPayout } from 'astra-core/containers/CommonDataProvider'

import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { BonusPanelInfo } from 'entities/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { ExpressBonusProps } from './express-bonus.types'

export const ExpressBonus: FC<ExpressBonusProps> = ({ winSumValue }) => {
  const bonus = useAppSelector(selectBonusOnExpress)
  const bonusAsPercent = bonusOnExpressToPercent(bonus)
  const currencyIcon = useGetCurrencyIcon()
  const maxPayout = useAppSelector(selectCurrencyMaxPayout)

  const winSumValueModified = useMemo(() => {
    const sum =
      winSumValue * (bonus - 1) >= maxPayout
        ? maxPayout
        : winSumValue * (bonus - 1)

    return +sum.toFixed(2)
  }, [winSumValue, maxPayout, bonus])

  const expressBonusRestriction = useMemo(() => {
    const totalSum = +winSumValueModified.toFixed(2) + winSumValue

    if (totalSum >= maxPayout) {
      return +(winSumValueModified - (totalSum - maxPayout)).toFixed(2)
    }

    return 0
  }, [winSumValueModified, winSumValue, maxPayout])

  return (
    <BonusPanelInfo
      bonusPercent={bonusAsPercent}
      bonusRestriction={formatMonetaryAmount(expressBonusRestriction)}
      bonusSum={formatMonetaryAmount(+winSumValueModified.toFixed(2))}
      currencyIcon={currencyIcon}
      isDisabled={winSumValue >= maxPayout}
    />
  )
}

import { useTranslation } from 'react-i18next'

import { NavTabsProps } from './nav-tabs.types'
import { NavTab, NavTabControlled, NavTabsWrapper } from './nav-tabs.styled'

export const NavTabs = <
  IsControlled extends boolean = false,
  ValueType = string
>(
  props: NavTabsProps<IsControlled, ValueType>
) => {
  const { t } = useTranslation()
  if (!props.isControlled)
    return (
      <NavTabsWrapper>
        {props.items.map((item) => (
          <NavTab activeClassName="active" key={item.to} to={item.to}>
            {t(item.titleKey)}
          </NavTab>
        ))}
      </NavTabsWrapper>
    )
  else {
    return (
      <NavTabsWrapper>
        {props.items.map((item) => (
          <NavTabControlled
            isActive={
              item.isActive
                ? item.isActive(props.value)
                : item.value === props.value
            }
            key={item.titleKey}
            onClick={() => props.onChange(item.value)}
          >
            {t(item.titleKey)}
          </NavTabControlled>
        ))}
      </NavTabsWrapper>
    )
  }
}

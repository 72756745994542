import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledMyBonusesToastifyNotificationTextWrapper = styled.div`
  align-items: flex-start;
  display: flex;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledMyBonusesToastifyNotificationText = styled.div`
  ${typography['Body / 14 Medium']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-invert']};
  `}
`

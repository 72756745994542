import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconHotkey: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 2.5V5.5C9.5 6.05228 9.05228 6.5 8.5 6.5H2.5M2.5 6.5L4.5 4.5M2.5 6.5L4.5 8.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  </Icon>
)

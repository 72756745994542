import React from 'react'
import { useTheme } from 'styled-components'

export const IconAge = () => {
  const theme = useTheme()

  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.85355 3.85355L7.06066 0.646446C7.15443 0.552678 7.28161 0.5 7.41421 0.5H24.5858C24.7184 0.5 24.8456 0.552678 24.9393 0.646446L28.1464 3.85355L31.3536 7.06066C31.4473 7.15443 31.5 7.2816 31.5 7.41421V24.5858C31.5 24.7184 31.4473 24.8456 31.3536 24.9393L28.1464 28.1464L24.9393 31.3536C24.8456 31.4473 24.7184 31.5 24.5858 31.5H7.41421C7.2816 31.5 7.15443 31.4473 7.06066 31.3536L3.85355 28.1464L0.646446 24.9393C0.552678 24.8456 0.5 24.7184 0.5 24.5858V7.41421C0.5 7.28161 0.552678 7.15443 0.646447 7.06066L3.85355 3.85355Z"
        stroke={theme.tokens.colors.border['border-default-gray']}
      />
      <path
        d="M6.738 20.488V19.228L10.434 15.934C10.722 15.678 10.922 15.436 11.034 15.208C11.146 14.98 11.202 14.76 11.202 14.548C11.202 14.26 11.14 14.004 11.016 13.78C10.892 13.552 10.72 13.372 10.5 13.24C10.284 13.108 10.034 13.042 9.75 13.042C9.45 13.042 9.184 13.112 8.952 13.252C8.724 13.388 8.546 13.57 8.418 13.798C8.29 14.026 8.23 14.272 8.238 14.536H6.75C6.75 13.96 6.878 13.458 7.134 13.03C7.394 12.602 7.75 12.27 8.202 12.034C8.658 11.798 9.184 11.68 9.78 11.68C10.332 11.68 10.828 11.804 11.268 12.052C11.708 12.296 12.054 12.636 12.306 13.072C12.558 13.504 12.684 14.002 12.684 14.566C12.684 14.978 12.628 15.324 12.516 15.604C12.404 15.884 12.236 16.142 12.012 16.378C11.792 16.614 11.518 16.876 11.19 17.164L8.568 19.486L8.436 19.126H12.684V20.488H6.738ZM16.9034 20.5V13.438L15.2834 14.428V12.832L16.9034 11.86H18.3794V20.5H16.9034ZM23.7508 18.892V16.744H21.6028V15.61H23.7508V13.468H24.8848V15.61H27.0208V16.744H24.8848V18.892H23.7508Z"
        fill={theme.tokens.colors.text['text-description']}
      />
    </svg>
  )
}

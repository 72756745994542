import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M14.25 10.2C13.575 9.82501 12.825 9.52501 12.075 9.30001C11.4 10.95 9.3 13.125 3.525 15.075C4.2 16.875 5.4 18.45 6.975 19.5C10.425 17.55 12.975 14.325 14.25 10.2Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M11.25 7.87499C11.25 7.79999 11.25 7.64999 11.25 7.57499C10.95 5.99999 9.075 4.79999 7.125 4.42499C5.7 5.39999 4.575 6.67499 3.825 8.24999C6.45 7.57499 9 7.49999 11.25 7.87499Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M10.95 8.99999C8.7 8.54999 6.075 8.77499 3.3 9.52499C3.15 10.35 3 11.175 3 12C3 12.675 3.075 13.35 3.225 13.95C8.475 12.15 10.35 10.35 10.95 8.99999Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M20.475 9.075C19.725 6.975 18.3 5.25 16.425 4.2C16.35 6.15 16.05 7.95 15.6 9.675C16.2 10.125 16.8 10.575 17.325 11.1C18.15 10.125 19.125 9.525 20.475 9.075Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M12.3 7.425C12.3 7.65 12.375 7.875 12.3 8.17499C13.125 8.39999 13.875 8.69999 14.55 9.07499C15 7.35 15.225 5.55 15.3 3.675C14.325 3.225 13.2 3 12 3C10.8 3 9.59999 3.225 8.54999 3.675C10.425 4.35 12.075 5.7 12.3 7.425Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M16.725 19.65C17.7 19.05 18.6 18.225 19.275 17.25C18.975 15.675 18.375 14.325 17.55 13.125C17.025 14.475 16.725 16.5 16.725 19.65Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M20.775 10.2C19.65 10.575 18.75 11.025 18.075 12C18.975 13.125 19.65 14.475 20.1 15.9C20.7 14.7 21 13.425 21 12C21 11.4 20.925 10.725 20.775 10.2Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M15.225 10.8C13.875 14.85 11.4 18.075 8.1 20.1C9.3 20.7 10.65 21 12 21C13.275 21 14.475 20.7 15.6 20.25C15.675 16.2 15.975 13.725 16.8 12.075C16.275 11.625 15.825 11.175 15.225 10.8Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M10 6.40001C9.4 6.06668 8.73334 5.80001 8.06667 5.60001C7.46667 7.06668 5.6 9.00001 0.466665 10.7333C1.06667 12.3333 2.13333 13.7333 3.53333 14.6667C6.6 12.9333 8.86667 10.0667 10 6.40001Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M7.33334 4.33332C7.33334 4.26666 7.33334 4.13332 7.33334 4.06666C7.06667 2.66666 5.40001 1.59999 3.66667 1.26666C2.40001 2.13333 1.4 3.26666 0.733338 4.66666C3.06667 4.06666 5.33334 3.99999 7.33334 4.33332Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M7.06667 5.33332C5.06667 4.93332 2.73334 5.13332 0.266667 5.79999C0.133333 6.53332 0 7.26665 0 7.99999C0 8.59999 0.0666667 9.19998 0.2 9.73332C4.86667 8.13332 6.53334 6.53332 7.06667 5.33332Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M15.5333 5.4C14.8667 3.53333 13.6 2 11.9333 1.06667C11.8667 2.8 11.6 4.4 11.2 5.93333C11.7333 6.33333 12.2667 6.73333 12.7333 7.2C13.4667 6.33333 14.3333 5.8 15.5333 5.4Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M8.26667 3.93333C8.26667 4.13333 8.33333 4.33333 8.26667 4.6C9 4.8 9.66667 5.06666 10.2667 5.39999C10.6667 3.86666 10.8667 2.26666 10.9333 0.599999C10.0667 0.2 9.06667 0 8 0C6.93333 0 5.86667 0.2 4.93333 0.599999C6.6 1.2 8.06667 2.4 8.26667 3.93333Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M12.2 14.8C13.0667 14.2667 13.8667 13.5333 14.4667 12.6667C14.2 11.2667 13.6667 10.0667 12.9333 8.99999C12.4667 10.2 12.2 12 12.2 14.8Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M15.8 6.39998C14.8 6.73331 14 7.13331 13.4 7.99998C14.2 8.99997 14.8 10.2 15.2 11.4666C15.7333 10.4 16 9.26664 16 7.99998C16 7.46664 15.9333 6.86664 15.8 6.39998Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
      <path
        d="M10.8667 6.93334C9.66667 10.5333 7.46667 13.4 4.53334 15.2C5.60001 15.7333 6.80001 16 8.00001 16C9.13334 16 10.2 15.7333 11.2 15.3333C11.2667 11.7333 11.5333 9.53334 12.2667 8.06668C11.8 7.66668 11.4 7.26668 10.8667 6.93334Z"
        fill={theme.tokens.colors['sport-icon']['sport-basketball']}
      />
    </>
  )
}

export const IconSportBasketball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { EntityTag, Event } from 'betweb-openapi-axios'

import { ETagsTop } from '../../../model'

export const isEventTop = ({
  eventTags,
  allEntityTags
}: {
  eventTags: Event['tags']
  allEntityTags: Dictionary<EntityTag>
}) =>
  eventTags?.some(
    (tag) => ETagsTop.TOP === allEntityTags[tag]?.name.toUpperCase()
  )

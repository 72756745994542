import styled, { css } from 'styled-components'
import { ReactNode } from 'react'

import { typography } from 'shared/lib/theme/typography'

import { ESelectView } from './select.types'

export const SelectWrapper = styled.div<{
  isActive: boolean
  isDisabled: boolean
  leftIcon?: ReactNode
  withLabel?: boolean
  view?: ESelectView
}>`
  column-gap: 4px;
  cursor: pointer;
  display: grid;
  height: 32px;
  ${typography['Body / 12 Medium']};
  position: relative;
  transition: box-shadow 0.25s ease;
  width: 140px;

  ${({ theme, isActive, isDisabled, withLabel, view }) => css`
    background-color: ${theme.tokens.colors.surface['surface-active']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    box-shadow: inset 0 0 0 1px
      ${theme.tokens.colors.border['border-default-gray']};
    color: ${theme.tokens.colors.text['text-primary-2']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px;

    &:hover {
      box-shadow: inset 0 0 0 1px
        ${theme.tokens.colors.border['border-settings-active']};
    }

    ${isActive &&
    css`
      box-shadow: inset 0 0 0 1px ${theme.tokens.colors.border['border-active']};
      &:hover {
        box-shadow: inset 0 0 0 1px
          ${theme.tokens.colors.border['border-active']};
      }
    `}

    ${isDisabled &&
    css`
      box-shadow: none;
      pointer-events: none;

      &:hover {
        box-shadow: none;
      }
    `}

    ${withLabel &&
    css`
      align-items: center;
      height: 40px;
      padding: 2px 12px;
    `}

    ${view === ESelectView.SECONDARY &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};

      ${isActive &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-active']};
      `}
    `}
  `}

  ${({ leftIcon }) => css`
    ${leftIcon
      ? css`
          font-weight: 500;
          grid-template-columns: auto 1fr auto;
          min-width: 124px;
        `
      : css`
          grid-template-columns: 1fr auto;
        `}
  `}
`

export const DropdownContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  left: 0;
  min-width: 124px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 5;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-active']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    box-shadow: inset 0 0 0 1px
      ${theme.tokens.colors.border['border-default-gray']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px 0;
    transform: translateY(${theme.tokens.alias.space['space-xxxs-4']}px);
  `}
`

export const DropdownItem = styled.div<{ active: boolean }>`
  ${typography['Body / 12 Medium']};
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: space-between;
  transition: background-color 0.25s ease;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-default']};
    }
  `}
`

export const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M9.28956 6.07793L9.12523 6.36177L9.12313 6.36387L7.91162 8.4673L7.37207 9.40056L7.31356 9.50294L6.57846 10.7649H7.25828C7.50071 10.7649 7.73962 10.7787 7.97609 10.8125L7.97925 10.8129C8.51646 10.8846 8.92612 10.9552 9.43109 11.2274C9.53472 11.2833 9.6343 11.3381 9.73102 11.3913C10.4261 11.7738 10.973 12.0748 11.8117 12.1064C11.8809 12.1145 11.9505 12.1144 11.993 12.1144H12.0068C12.0494 12.1144 12.119 12.1145 12.1881 12.1064C12.6578 12.0889 13.1139 11.994 13.5332 11.8279L13.5987 11.8019L13.6072 11.7935C13.7754 11.7239 13.9445 11.6441 14.1105 11.5437C14.2554 11.4641 14.3891 11.3745 14.5152 11.2755H14.5409L14.6344 11.1821C15.0872 10.8194 15.4627 10.3639 15.7301 9.84563C16.1482 9.05921 15.9163 8.16921 15.5303 7.49983L14.7105 6.07817L14.7034 6.06781C14.1176 5.20533 13.1242 4.63477 11.9999 4.63477C10.8756 4.63477 9.88227 5.20533 9.29644 6.06781L9.28956 6.07793Z"
        fill={theme.tokens.colors['sport-icon']['sport-swimming']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.25837 19.3655H12.4889L12.1559 18.8423C12.0166 18.6233 11.9015 18.3875 11.7976 18.1312L11.7966 18.1288C11.5917 17.6341 11.4471 17.2095 11.421 16.6102C11.4156 16.486 11.4118 16.3654 11.408 16.2474C11.3853 15.5251 11.3657 14.9031 10.9982 14.1811C10.9398 14.0646 10.8745 13.9585 10.8043 13.8564C10.5518 13.4482 10.2208 13.0773 9.82489 12.7827C9.67599 12.6596 9.51586 12.5563 9.36669 12.4668L9.3604 12.4632C9.19291 12.3675 9.01319 12.2816 8.83857 12.2056L8.82738 12.2012C8.34547 12.0116 7.81455 11.9004 7.25837 11.9004L7.25015 11.9004C7.20213 11.9004 7.13127 11.9003 7.06158 11.9086C6.18273 11.9492 5.52903 12.5827 5.14501 13.2517L4.54254 14.3013L4.37828 14.5776L4.37369 14.5864C4.13882 15.04 4 15.5529 4 16.1071C4 17.9073 5.45819 19.3655 7.25837 19.3655Z"
        fill={theme.tokens.colors['sport-icon']['sport-cycling']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.1404 13.9366V13.9395C13.0912 14.0158 13.045 14.0951 13.0024 14.18C12.7893 14.5911 12.6371 15.0459 12.5809 15.5359L12.5787 15.5552V15.5607C12.5494 15.7394 12.5349 15.9238 12.5349 16.1072C12.5349 16.2907 12.5494 16.475 12.5787 16.6537V16.662L12.5816 16.6841C12.6611 17.2888 12.8758 17.8615 13.1942 18.3553C13.679 19.1162 14.5736 19.3656 15.3618 19.3656H16.7416C18.5418 19.3656 20 17.9074 20 16.1072C20 15.553 19.8612 15.0401 19.6263 14.5865L19.6217 14.5777L19.4573 14.3011L18.344 12.3687V12.3605L17.758 11.3546L17.6916 11.244L16.9514 9.96106L16.6613 10.4955C16.5301 10.7372 16.3878 10.9598 16.2212 11.1664L16.2161 11.173C16.0476 11.3928 15.6049 11.9252 15.1118 12.228C15.0456 12.2687 14.9807 12.3082 14.9171 12.347C14.215 12.7745 13.6655 13.1091 13.1934 13.8531L13.1404 13.9366Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M5.28959 2.07793L5.12526 2.36177L5.12317 2.36387L3.91165 4.46731L3.3721 5.40057L3.3136 5.50295L2.57849 6.76488H3.25831C3.50074 6.76488 3.73965 6.77876 3.97613 6.81254L3.97928 6.81296C4.5165 6.88459 4.92616 6.95524 5.43113 7.22746C5.53476 7.28332 5.63434 7.33812 5.73105 7.39134C6.42612 7.7738 6.97307 8.07477 7.81173 8.10636C7.88091 8.11451 7.95054 8.11446 7.99309 8.11443H8.00688C8.04942 8.11446 8.119 8.11451 8.18816 8.10637C8.65786 8.0889 9.11393 7.99403 9.53328 7.82788L9.59879 7.80192L9.60723 7.79348C9.77542 7.72387 9.94452 7.64416 10.1105 7.54376C10.2555 7.46415 10.3891 7.37447 10.5152 7.27552H10.541L10.6344 7.18206C11.0873 6.81938 11.4628 6.36392 11.7301 5.84564C12.1482 5.05922 11.9164 4.16922 11.5304 3.49983L10.7105 2.07817L10.7035 2.06781C10.1176 1.20533 9.12428 0.634766 7.99997 0.634766C6.87567 0.634766 5.8823 1.20533 5.29647 2.06781L5.28959 2.07793Z"
        fill={theme.tokens.colors['sport-icon']['sport-swimming']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.25837 15.3655H8.48887L8.15593 14.8423C8.01661 14.6234 7.90152 14.3875 7.79759 14.1312L7.7966 14.1288C7.59168 13.6341 7.44711 13.2095 7.42098 12.6102C7.41557 12.486 7.41177 12.3654 7.40805 12.2475C7.38529 11.5251 7.36569 10.9031 6.99817 10.1811C6.93984 10.0646 6.87454 9.95847 6.8043 9.85645C6.55179 9.44821 6.22081 9.07729 5.8249 8.78268C5.676 8.65961 5.51587 8.55629 5.3667 8.46678L5.36041 8.46319C5.19291 8.36748 5.0132 8.28156 4.83858 8.20564L4.82739 8.20124C4.34548 8.01164 3.81455 7.9004 3.25837 7.9004L3.25016 7.90039C3.20214 7.90036 3.13128 7.90032 3.06159 7.90861C2.18274 7.94916 1.52903 8.5827 1.14501 9.25172L0.542537 10.3013L0.378282 10.5776L0.373693 10.5864C0.138823 11.04 0 11.5529 0 12.1071C0 13.9073 1.45819 15.3655 3.25837 15.3655Z"
        fill={theme.tokens.colors['sport-icon']['sport-cycling']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.1404 9.93645V9.93936C9.09124 10.0156 9.04499 10.095 9.00244 10.1799C8.78929 10.591 8.63715 11.0458 8.58092 11.5358L8.5787 11.5551V11.5606C8.54939 11.7393 8.53493 11.9236 8.53493 12.1071C8.53493 12.2905 8.54939 12.4749 8.5787 12.6536V12.6619L8.58161 12.684C8.66117 13.2887 8.87577 13.8614 9.1942 14.3552C9.67898 15.1161 10.5736 15.3655 11.3619 15.3655H12.7416C14.5418 15.3655 16 13.9073 16 12.1071C16 11.5529 15.8612 11.04 15.6263 10.5864L15.6217 10.5775L15.4573 10.301L14.3441 8.36862V8.36043L13.758 7.35452L13.6916 7.24384L12.9514 5.96094L12.6613 6.49542C12.5301 6.73712 12.3878 6.95967 12.2212 7.16626L12.2161 7.1729C12.0476 7.39272 11.6049 7.92512 11.1118 8.22793C11.0456 8.26859 10.9807 8.3081 10.9171 8.34686C10.2151 8.77436 9.6655 9.10902 9.19339 9.85295L9.1404 9.93645Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
    </>
  )
}

export const IconSportTriathlon: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

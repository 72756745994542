import React, { ComponentProps, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectInputSearch } from 'astra-core/containers/SearchProvider'

import { Typography } from 'shared/ui/typography'
import {
  GlobalSearchInput,
  GlobalSearchSegmentedControl,
  GlobalSearchSports,
  GlobalSearchTabs,
  PopularQueries,
  RecentSearch,
  ValueWarning,
  SearchResults,
  GlobalSearchValueProvider,
  GlobalSearchContext
} from 'features/global-search'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  GlobalSearchFilters,
  GlobalSearchFiltersDivider,
  GlobalSearchWrapper,
  SearchHeader,
  SearchWrapper,
  TitleWrapper
} from './page.styled'

export const GlobalSearch = () => {
  const { t } = useTranslation()
  const [activeSport, setActiveSport] = useState(0)
  const [selectedTab, setSelectedTab] = useState(GlobalSearchTabs.All)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <GlobalSearchContext.Provider
      value={{
        isFocused,
        setIsFocused,
        isOpen: false,
        setIsOpen: () => {}
      }}
    >
      <GlobalSearchValueProvider>
        <GlobalSearchWrapper>
          <SearchHeader>
            <TitleWrapper>
              <Typography font="Title / 24 Bold">
                {t('search events and tournaments')}
              </Typography>
            </TitleWrapper>
          </SearchHeader>
          <SearchWrapper>
            <GlobalSearchInput scrollTopOnFocus={false} />
          </SearchWrapper>
          <GlobalSearchFilters>
            <GlobalSearchSegmentedControl
              value={selectedTab}
              onChange={setSelectedTab}
            />
            <GlobalSearchFiltersDivider />
            <GlobalSearchSports
              activeSport={activeSport}
              selectedTab={selectedTab}
              onChange={setActiveSport}
            />
          </GlobalSearchFilters>
          <GlobalSearchPageBody
            selectedTab={selectedTab}
            sportId={activeSport}
          />
        </GlobalSearchWrapper>
      </GlobalSearchValueProvider>
    </GlobalSearchContext.Provider>
  )
}

const GlobalSearchPageBody: FC<ComponentProps<typeof SearchResults>> = (
  props
) => {
  const valueSearch = useAppSelector(selectInputSearch) || ''
  const showLengthWarning =
    valueSearch && valueSearch.length > 0 && valueSearch.length < 3

  if (!valueSearch || showLengthWarning) {
    return (
      <div>
        {showLengthWarning && <ValueWarning />}
        <RecentSearch />
        <PopularQueries />
      </div>
    )
  }

  return <SearchResults {...props} />
}

import { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { selectEventStatistics } from 'astra-core/containers/EventsProvider'

import { EventContext, IEventContext } from 'widgets/Event/Event.context'
import { RootState } from 'shared/types/store'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledServe,
  StyledServes,
  StyledSetsCell,
  StyledSetsCenter,
  StyledSetsContainer,
  StyledSetsSide,
  StyledSetsTopCell,
  StyledSetsWrapper
} from './Sets.styled'
import { ESportColumn, sportColumnName } from './lib'

export const Sets: FC = () => {
  const [t] = useTranslation()

  const { event } = useContext(EventContext) as IEventContext
  const eventStatistics = useAppSelector((state: RootState) =>
    selectEventStatistics(state, event.id)
  )

  const { sportId } = event.tournament

  const sport = useAppSelector((state) => selectSport(state, sportId))

  const currentServe = useMemo(
    () => eventStatistics?.commonStatistics?.currentServer,
    [eventStatistics]
  )

  const hasStatistics =
    !!eventStatistics &&
    !!eventStatistics.homeStatistics &&
    !!eventStatistics.awayStatistics

  const gameScoreData = useMemo(
    () =>
      hasStatistics
        ? {
            id: t('game_score'),
            value: [
              eventStatistics.homeStatistics!.gameScore,
              eventStatistics.awayStatistics!.gameScore
            ]
          }
        : undefined,
    [eventStatistics, hasStatistics, t]
  )

  const scoreData = useMemo(
    () =>
      hasStatistics
        ? {
            id: t('score'),
            value: [
              eventStatistics.homeStatistics!.score,
              eventStatistics.awayStatistics!.score
            ]
          }
        : undefined,
    [eventStatistics, hasStatistics, t]
  )

  const sets = useMemo(
    () =>
      hasStatistics
        ? eventStatistics.homeStatistics?.periodScores.map((item, index) => ({
            id: `${index + 1} ${t(
              sportColumnName[sport.code] ?? ESportColumn.T
            )}`,
            value: [
              eventStatistics.homeStatistics!.periodScores[index].score,
              eventStatistics.awayStatistics!.periodScores[index].score
            ]
          }))
        : undefined,
    [eventStatistics, hasStatistics, sport.code, t]
  )

  return (
    <StyledSetsContainer>
      {currentServe && (
        <StyledServes>
          <div />
          <StyledServe isActive={currentServe === 1} />
          <StyledServe isActive={currentServe === 2} />
        </StyledServes>
      )}

      <StyledSetsWrapper>
        {ESportsCodes.TENNIS === sport.code && !!gameScoreData && (
          <StyledSetsSide>
            <StyledSetsTopCell>{gameScoreData.id}</StyledSetsTopCell>
            <StyledSetsCell extra>
              {gameScoreData?.value[0] === 50 ? 'A' : gameScoreData?.value[0]}
            </StyledSetsCell>
            <StyledSetsCell extra>
              {gameScoreData?.value[1] === 50 ? 'A' : gameScoreData?.value[1]}
            </StyledSetsCell>
          </StyledSetsSide>
        )}

        {!!sets && (
          <StyledSetsCenter columnsCount={sets.length}>
            <>
              {sets.map((item) => (
                <StyledSetsTopCell key={item.id}>{item.id}</StyledSetsTopCell>
              ))}

              {sets.map((item) => (
                <StyledSetsCell key={`1_${item.id}_${item.value[0]}`}>
                  {item.value[0]}
                </StyledSetsCell>
              ))}

              {sets.map((item) => (
                <StyledSetsCell key={`2_${item.id}_${item.value[1]}`}>
                  {item.value[1]}
                </StyledSetsCell>
              ))}
            </>
          </StyledSetsCenter>
        )}

        {!!scoreData && (
          <StyledSetsSide>
            <StyledSetsTopCell>{scoreData.id}</StyledSetsTopCell>
            <StyledSetsCell extraBold>{scoreData.value[0]}</StyledSetsCell>
            <StyledSetsCell extraBold>{scoreData.value[1]}</StyledSetsCell>
          </StyledSetsSide>
        )}
      </StyledSetsWrapper>
    </StyledSetsContainer>
  )
}

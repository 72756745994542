import styled, { css } from 'styled-components'

export const MaxTagContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & > span {
    width: 27px;
  }

  ${({ theme }) => css`
    & svg {
      color: ${theme.tokens.colors.surface['surface-brand-default']};
    }
  `}
`

import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useTranslation } from 'react-i18next'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { SHORTNAMED_SPORT_CODES } from 'shared/lib/locales/sports'
import { ControlButton } from 'shared/ui/Carousel/Carousel.styled'
import { IconArrowRight } from 'shared/ui/Icon/General/IconArrowRight'
import { IconArrowLeft } from 'shared/ui/Icon/General/IconArrowLeft'

import {
  StyledSportsContainer,
  StyledSportsListItem,
  StyledSportsListItemLabel,
  StyledSportsWrapper
} from './sport-tabs.styled'
import {
  SportsItemProps,
  SportsListProps,
  TSportsListItemLabelProps
} from './sport-tabs.types'

export const SportTabs: FC<SportsListProps> = memo(
  ({
    activeSport,
    items,
    onChange,
    leftOffset = 16,
    rightOffset = 16,
    withCount = false,
    countsMap
  }) => {
    const carouselRef = useRef<HTMLDivElement>(null)
    const [scrollPos, setScrollPos] = useState(0)

    const scrollLeftTo = (direction: -1 | 1) => {
      if (carouselRef.current) {
        const scrollTo = Math.round(
          carouselRef.current.scrollLeft +
            direction * carouselRef.current?.clientWidth * 0.25
        )

        setScrollPos(scrollTo)

        carouselRef.current.scrollTo({
          left: scrollTo,
          behavior: 'smooth'
        })
      }
    }

    const scrollWidth = useMemo(
      () =>
        (carouselRef?.current?.scrollWidth ?? 0) -
        (carouselRef?.current?.clientWidth ?? 0),
      /* eslint-disable-next-line */
      [carouselRef?.current]
    )

    return (
      <StyledSportsContainer>
        <StyledSportsWrapper
          leftOffset={leftOffset}
          ref={carouselRef}
          rightOffset={rightOffset}
        >
          {items.map((sport) => (
            <SportItem
              count={countsMap ? countsMap[sport.id] : undefined}
              isActive={sport.id === activeSport}
              key={sport.id}
              sportCode={sport.code}
              sportId={sport.id}
              sportName={sport.name}
              withCount={withCount}
              onChange={onChange}
            />
          ))}
        </StyledSportsWrapper>

        {scrollPos > 0 && (
          <ControlButton
            data-dir="prev"
            icon={IconArrowLeft}
            offset={leftOffset}
            view="outline-m"
            onClick={() => scrollLeftTo(-1)}
          />
        )}

        {scrollPos < scrollWidth && (
          <ControlButton
            data-dir="next"
            icon={IconArrowRight}
            offset={rightOffset}
            view="outline-m"
            onClick={() => scrollLeftTo(1)}
          />
        )}
      </StyledSportsContainer>
    )
  }
)

const SportsListItemLabel: FC<TSportsListItemLabelProps> = memo(
  ({ name, withCount, count }) => (
    <StyledSportsListItemLabel>
      {name} {withCount && `(${count})`}
    </StyledSportsListItemLabel>
  )
)

const SportItem: FC<SportsItemProps> = memo(
  ({ isActive, onChange, withCount, count, sportCode, sportId, sportName }) => {
    const [isHovered, setIsHovered] = useState(false)
    const { t, i18n } = useTranslation()
    const handleSportItemClick = useCallback(() => {
      onChange(sportId)
    }, [onChange, sportId])

    const name =
      SHORTNAMED_SPORT_CODES[sportCode] &&
      i18n.exists(SHORTNAMED_SPORT_CODES[sportCode])
        ? t(SHORTNAMED_SPORT_CODES[sportCode])
        : sportName

    return (
      <StyledSportsListItem
        isActive={isActive}
        sport={sportCode as ESportsCodes}
        onClick={handleSportItemClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <SportIcon
          isMonochrome={!isActive && !isHovered}
          sport={sportCode as ESportsCodes}
        />
        <SportsListItemLabel count={count} name={name} withCount={withCount} />
      </StyledSportsListItem>
    )
  }
)

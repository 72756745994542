import { FC, memo } from 'react'
import { BasketOutcome } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'

import { Card } from '../../card'

import * as S from './card.styled'

export const CardSystem: FC<{
  outcome: BasketOutcome
}> = memo(
  ({ outcome }) => (
    <S.CardContainer>
      <Card outcome={outcome} />
    </S.CardContainer>
  ),
  isEqual
  // Outcome every time with new object link
)

import React, { FC } from 'react'

import { selectIsDisplayModalSettings } from 'containers/LayoutContainer/selectors'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { SettingsList } from './components'

export const Settings: FC = () => {
  const isDisplayModal = useAppSelector(selectIsDisplayModalSettings)

  return isDisplayModal ? <SettingsList /> : null
}

import styled, { css } from 'styled-components'

export const RecentSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-m-16']}px 0;
  `}
`

export const RecentSearchTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ClearButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const RecentSearchList = styled.div`
  display: flex;
  flex-direction: column;
`

export const RecentSearchItem = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    &:hover {
      background: ${theme.tokens.colors.surface['surface-default']};
    }
  `}
`

import styled, { css } from 'styled-components'

import { TableRow } from '../table-row/table-row.styled'

export const TableStyled = styled.table`
  ${({ theme }) => css`
    border-collapse: collapse;
    border-spacing: 0;
    height: fit-content;
    margin: 0 auto;
    max-width: 102.5rem;
    width: 100%;

    ${TableRow}:not(:last-of-type) {
      border-bottom: 1px solid
        ${theme.tokens.colors.border['border-default-gray']};
    }
  `}
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M19.7227 4.6922C27.762 15.6456 15.562 27.8548 4.61676 19.8095C4.46065 19.6953 4.32857 19.5631 4.21449 19.4069C-3.82481 8.45349 8.37524 -3.75568 19.3204 4.28964C19.4705 4.39779 19.6086 4.53598 19.7227 4.6922Z"
        fill="#011D30"
      />
      <path
        d="M23.0009 11.9985C23.0009 9.77534 22.3284 7.60629 21.0736 5.77371V3.08193C21.0736 2.9918 21.0015 2.9197 20.9115 2.9197H18.2217C14.4812 0.3601 9.54599 0.3601 5.80552 2.9197H3.12175C3.07973 2.9197 3.0377 2.93772 3.00768 2.96777C2.97766 2.99781 2.95965 3.03987 2.95965 3.08193V5.73165C0.347924 9.49895 0.347924 14.492 2.95965 18.2593V20.921C2.95965 21.0111 3.03169 21.0832 3.12175 21.0832H5.79952C9.564 23.6549 14.5173 23.6368 18.2637 21.0412H20.9115C21.0015 21.0412 21.0736 20.9691 21.0736 20.879V18.2172C22.3224 16.3906 23.0009 14.2216 23.0009 11.9985ZM2.49734 11.9985C2.49734 6.74708 6.75415 2.48709 12.0016 2.48709C17.2491 2.48709 21.5059 6.74708 21.5059 11.9985C21.5059 17.2498 17.2491 21.5098 12.0016 21.5098C6.75415 21.5038 2.50335 17.2498 2.49734 11.9985Z"
        fill="#FF9C00"
      />
      <path
        d="M6.11782 7.22777H9.48004C9.40199 7.6063 9.36596 7.99084 9.36596 8.37538C9.51006 9.8835 10.4947 13.9993 10.4947 13.9993L12.0858 7.54621L13.7188 13.8731C13.7188 13.8731 14.6915 8.3213 14.6915 7.94878C14.6494 7.67239 14.5114 7.41403 14.3012 7.23377H17.8556C17.3812 7.67239 17.039 8.23118 16.8529 8.85005C16.5347 10.0758 15.454 16.2344 15.454 16.625C15.472 17.0275 15.6161 17.4121 15.8682 17.7305H12.5601C12.6381 17.4361 12.6741 17.1357 12.6681 16.8293C12.6681 16.2645 12.1458 13.5787 12.1458 13.5787C12.1458 13.5787 11.4734 16.3606 11.4734 16.8653C11.4734 17.1657 11.5454 17.4662 11.6895 17.7305H8.24923C8.24923 17.7305 8.90366 17.2679 8.87964 16.8293C8.80759 15.5675 7.03643 9.01828 6.92235 8.51357C6.83229 7.99685 6.5441 7.5342 6.11782 7.22777Z"
        fill="#FF9C00"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M13.6176 2.68433C19.4653 10.6517 10.5911 19.5326 2.62969 13.6805C2.51614 13.5975 2.42006 13.5013 2.33708 13.3877C-3.51063 5.42026 5.36357 -3.46058 13.325 2.39151C13.4342 2.47018 13.5346 2.5707 13.6176 2.68433Z"
        fill="#011D30"
      />
      <path
        d="M16.0021 7.99884C16.0021 6.38176 15.513 4.80401 14.6002 3.47101V1.51303C14.6002 1.44748 14.5478 1.39503 14.4823 1.39503H12.5258C9.80503 -0.4668 6.21517 -0.4668 3.49438 1.39503H1.54223C1.51166 1.39503 1.48109 1.40814 1.45926 1.42999C1.43742 1.45185 1.42432 1.48244 1.42432 1.51303V3.44042C-0.475424 6.18072 -0.475424 9.8126 1.42432 12.5529V14.489C1.42432 14.5546 1.47673 14.607 1.54223 14.607H3.49002C6.22827 16.4776 9.83123 16.4645 12.5564 14.5764H14.4823C14.5478 14.5764 14.6002 14.524 14.6002 14.4584V12.5223C15.5086 11.1937 16.0021 9.61593 16.0021 7.99884ZM1.08804 7.99884C1.08804 4.17903 4.18441 1.08035 8.00136 1.08035C11.8183 1.08035 14.9147 4.17903 14.9147 7.99884C14.9147 11.8187 11.8183 14.9173 8.00136 14.9173C4.18441 14.913 1.09241 11.8187 1.08804 7.99884Z"
        fill="#FF9C00"
      />
      <path
        d="M3.72155 4.52868H6.16719C6.11042 4.80402 6.08422 5.08373 6.08422 5.36344C6.18903 6.46044 6.90526 9.45422 6.90526 9.45422L8.06257 4.76031L9.25045 9.36244C9.25045 9.36244 9.95795 5.32411 9.95795 5.05314C9.92738 4.8521 9.82693 4.66416 9.67408 4.53305H12.2595C11.9145 4.8521 11.6655 5.25855 11.5301 5.70871C11.2987 6.60029 10.5126 11.08 10.5126 11.3641C10.5257 11.657 10.6305 11.9367 10.8139 12.1683H8.40758C8.46436 11.9541 8.49056 11.7356 8.48619 11.5127C8.48619 11.1019 8.10624 9.14829 8.10624 9.14829C8.10624 9.14829 7.61711 11.1718 7.61711 11.5389C7.61711 11.7575 7.66952 11.976 7.77433 12.1683H5.27191C5.27191 12.1683 5.74794 11.8318 5.73047 11.5127C5.67807 10.5949 4.38973 5.83109 4.30676 5.46396C4.24125 5.0881 4.03162 4.75157 3.72155 4.52868Z"
        fill="#FF9C00"
      />
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M23.0018 11.9984C23.0018 9.77527 22.3294 7.60624 21.0745 5.77368V3.08191C21.0745 2.99179 21.0025 2.91968 20.9124 2.91968H18.2227C14.4822 0.360105 9.54702 0.360105 5.80658 2.91968H3.12284C3.08081 2.91968 3.03878 2.93771 3.00876 2.96775C2.97874 2.99779 2.96073 3.03985 2.96073 3.08191V5.73162C0.349027 9.49889 0.349027 14.4919 2.96073 18.2591V20.9209C2.96073 21.011 3.03278 21.0831 3.12284 21.0831H5.80058C9.56503 23.6547 14.5183 23.6367 18.2647 21.041H20.9124C21.0025 21.041 21.0745 20.9689 21.0745 20.8788V18.2171C22.3233 16.3905 23.0018 14.2215 23.0018 11.9984ZM2.49843 11.9984C2.49843 6.74704 6.7552 2.48708 12.0026 2.48708C17.25 2.48708 21.5068 6.74704 21.5068 11.9984C21.5068 17.2497 17.25 21.5097 12.0026 21.5097C6.7552 21.5037 2.50443 17.2497 2.49843 11.9984Z"
        fill="currentColor"
      />
      <path
        d="M6.11888 7.22772H9.48107C9.40302 7.60625 9.367 7.99079 9.367 8.37532C9.51109 9.88343 10.4957 13.9992 10.4957 13.9992L12.0868 7.54616L13.7198 13.873C13.7198 13.873 14.6925 8.32125 14.6925 7.94873C14.6504 7.67234 14.5124 7.41398 14.3022 7.23373H17.8565C17.3822 7.67234 17.04 8.23112 16.8539 8.84999C16.5357 10.0757 15.455 16.2343 15.455 16.6249C15.473 17.0274 15.6171 17.412 15.8692 17.7304H12.5611C12.6391 17.436 12.6752 17.1356 12.6692 16.8291C12.6692 16.2644 12.1468 13.5786 12.1468 13.5786C12.1468 13.5786 11.4744 16.3605 11.4744 16.8652C11.4744 17.1656 11.5464 17.466 11.6905 17.7304H8.25027C8.25027 17.7304 8.9047 17.2678 8.88068 16.8291C8.80863 15.5674 7.03748 9.01822 6.92341 8.51352C6.83335 7.99679 6.54516 7.53415 6.11888 7.22772Z"
        fill="currentColor"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M16.0017 7.99852C16.0017 6.38157 15.5126 4.80396 14.6 3.47108V1.51327C14.6 1.44772 14.5475 1.39527 14.482 1.39527H12.5257C9.80515 -0.466394 6.2156 -0.466394 3.49506 1.39527H1.54308C1.51251 1.39527 1.48194 1.40839 1.46011 1.43024C1.43827 1.45209 1.42517 1.48268 1.42517 1.51327V3.44049C-0.474407 6.18055 -0.474407 9.81211 1.42517 12.5522V14.4881C1.42517 14.5537 1.47757 14.6061 1.54308 14.6061H3.49069C6.2287 16.4765 9.83136 16.4634 12.5563 14.5755H14.482C14.5475 14.5755 14.6 14.5231 14.6 14.4575V12.5216C15.5083 11.1931 16.0017 9.61546 16.0017 7.99852ZM1.08893 7.99852C1.08893 4.17904 4.18502 1.08063 8.00165 1.08063C11.8183 1.08063 14.9144 4.17904 14.9144 7.99852C14.9144 11.818 11.8183 14.9164 8.00165 14.9164C4.18502 14.912 1.09329 11.818 1.08893 7.99852Z"
        fill="currentColor"
      />
      <path
        d="M3.7222 4.52865H6.16764C6.11087 4.80397 6.08467 5.08366 6.08467 5.36334C6.18947 6.46024 6.90563 9.45377 6.90563 9.45377L8.06285 4.76027L9.25063 9.362C9.25063 9.362 9.95806 5.32401 9.95806 5.05307C9.92749 4.85204 9.82706 4.66413 9.67422 4.53302H12.2594C11.9144 4.85204 11.6655 5.25846 11.5301 5.70858C11.2987 6.60009 10.5126 11.0795 10.5126 11.3635C10.5258 11.6563 10.6306 11.936 10.814 12.1676H8.40783C8.4646 11.9535 8.4908 11.735 8.48643 11.5121C8.48643 11.1013 8.10652 9.14786 8.10652 9.14786C8.10652 9.14786 7.61743 11.1712 7.61743 11.5383C7.61743 11.7568 7.66983 11.9753 7.77464 12.1676H5.27243C5.27243 12.1676 5.74842 11.8311 5.73095 11.5121C5.67855 10.5944 4.39033 5.83095 4.30736 5.46386C4.24186 5.08803 4.03225 4.75153 3.7222 4.52865Z"
        fill="currentColor"
      />
    </>
  )
}

export const IconCybersportWorldOfWarcraft: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconWarningCirle: FC<IconProps> = (props) => {
  const theme = useTheme()

  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M25.5475 0C27.3041 0 28.9717 0.688889 30.239 1.95556L38.0211 9.75556C39.2885 11 40 12.6889 40 14.4667V25.5111C40 27.2889 39.2885 28.9556 38.0211 30.2222L30.2168 38.0222C28.9717 39.2889 27.2818 40 25.5031 40H14.4747C12.6959 40 11.0283 39.2889 9.76098 38.0222L1.95664 30.2222C0.689272 28.9778 0 27.2889 0 25.5111V14.4889C0 12.7111 0.689272 11.0444 1.95664 9.77778L9.80545 1.95556C11.0283 0.688889 12.7404 0 14.4969 0H25.5475Z"
          fill={theme.tokens.colors.icon['icon-warning']}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M20.7449 10H19.255C18.8081 10 18.3777 10.2527 18.1459 10.6403L9.17381 26.4785C8.94206 26.8829 8.94206 27.3715 9.17381 27.7759L10.0512 29.3429C10.2829 29.7473 10.6968 30 11.1603 30H28.8396C29.3031 30 29.717 29.7473 29.9487 29.3429L30.8261 27.7759C31.0578 27.3715 31.0578 26.8829 30.8261 26.4785L21.854 10.6403C21.6222 10.2359 21.2084 10 20.7449 10ZM19.0005 17.9956C19.0005 17.4436 19.448 16.9961 20 16.9961C20.5519 16.9961 20.9994 17.4436 20.9994 17.9956L20.9995 22.3285C20.9995 22.8805 20.552 23.328 20 23.328C19.448 23.328 19.0005 22.8805 19.0005 22.3285L19.0005 17.9956ZM19.0005 25.0008C19.0005 24.4488 19.448 24.0013 20 24.0013C20.552 24.0013 20.9995 24.4488 20.9995 25.0008C20.9995 25.5528 20.552 26.0002 20 26.0002C19.448 26.0002 19.0005 25.5528 19.0005 25.0008Z"
          fill="white"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

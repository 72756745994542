import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledBonusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const StyledBonus = styled.div<{ disabled: boolean }>`
  ${({ theme, disabled }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-card-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    display: flex;
    justify-content: space-between;
    outline: 1px solid ${theme.tokens.colors.border['border-warning']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;

    ${disabled &&
    css`
      opacity: 30%;
    `}
  `}
`

export const StyledBonusContent = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;

    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-3']};
    }
  `}
`

export const StyledBonusPercent = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-warning']};
  `}
`

export const StyledBonusText = styled.div`
  ${typography['Body / 12 Regular']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledBonusSum = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-warning']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

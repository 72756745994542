import styled from 'styled-components'

import { ColumnLayoutContent } from 'shared/lib/layout'

export const Content = styled(ColumnLayoutContent)`
  grid-area: content;
  grid-template-rows: minmax(0, 1fr);
  overflow: hidden;
  scrollbar-gutter: auto;
`

import React, { FC, PropsWithChildren } from 'react'

import { ColumnLayout, MAIN_HEADER_HEIGHT } from 'shared/lib/layout'
import { SportsMenu } from 'widgets/sports-menu'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { useBannerScroll } from 'entities/event'
import { StyledHeadEventWrapper } from 'widgets/Event/Event.styled'
import { Basket } from 'widgets/basket'

import { LayoutContext } from '../../model/context'

import * as S from './event.styled'

export const Event: FC<PropsWithChildren> = ({ children }) => {
  const { setEventBannerRefData, isScrolled, containerScrolledRef } =
    useBannerScroll({
      mainHeaderHeight: MAIN_HEADER_HEIGHT,
      headEventClassName: StyledHeadEventWrapper.toString()
    })

  return (
    <LayoutContext.Provider
      value={{
        getCoordsEventBanner: setEventBannerRefData,
        isEventBannerScrolled: !!isScrolled
      }}
    >
      <ColumnLayout>
        <SportsMenu />

        <NotificationsPanel />

        <S.Content ref={containerScrolledRef}>{children}</S.Content>

        <Basket />
      </ColumnLayout>
    </LayoutContext.Provider>
  )
}

import styled, { css, keyframes } from 'styled-components'

import { TStyledLoaderPoints, TStyledLoaderPointsItem } from './types'

export const StyledLoaderPoints = styled.div<TStyledLoaderPoints>`
  ${(props) => css`
    height: ${props.size}px;
    position: relative;
    width: ${props.size * props.count + props.margin * (props.count - 1)}px;
  `}
`

export const StyledLoaderPointsItem = styled.div<TStyledLoaderPointsItem>`
  ${(props) => {
    const animationDuration = `${
      props.settingsAnimation.timeStep *
      props.settingsAnimation.timeAfterEnd *
      (props.count - 1)
    }s`

    const animationBounceFountainKeyframe = getKeyframeBounceFountainG(props)

    return css`
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -webkit-transform: scale(1);
      animation-delay: ${props.delayPoint}s;
      animation-direction: normal;
      animation-duration: ${animationDuration};
      animation-iteration-count: infinite;
      animation-name: ${animationBounceFountainKeyframe};
      background-color: ${props.colors.start};
      border-radius: 50%;
      height: ${props.size}px;
      left: ${props.orderNum === 0
        ? 0
        : (props.size + props.margin) * props.orderNum}px;
      position: absolute;
      top: 0;
      transform: scale(1);
      width: ${props.size}px;
    `
  }}
`

const getKeyframeBounceFountainG = (props: TStyledLoaderPointsItem) => {
  return keyframes`
    0%, 100% {
      transform: scale(${props.settingsAnimation.scaleActivePoint});
      background-color: ${props.colors.start};
    }
  
    33%, 66% {
      transform: scale(1);
      background-color: ${props.colors.end};
    }
  `
}

import styled, { css } from 'styled-components'

export const LiveContentScoreCell = styled.td`
  padding-right: 8px;
  white-space: nowrap;
`

export const LiveContentStatsCell = styled.td`
  min-width: 116px;
  padding-right: 8px;
  white-space: nowrap;
`

export const TimeStatsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const TimeWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${(p) => p.theme.tokens.alias.space['space-xxxxs-2']}px;
`

export const AdditionalBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: end;
`

export const IconVideoFilledWrapper = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    & svg {
      color: ${isActive
        ? `${theme.tokens.colors.icon['icon-negative']} !important`
        : theme.tokens.colors.icon['icon-secondary-3']};
      transition: all 0.3s;
    }

    &:hover {
      & svg {
        color: ${isActive
          ? `${theme.tokens.colors.icon['icon-negativeDarker']} !important`
          : theme.tokens.colors.icon['icon-secondary']};
      }
    }
  `}
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M15.8 6C15.9105 6 16 6.08954 16 6.2V6.81597C16 6.91999 15.9203 7.00664 15.8166 7.01528L10.5178 7.45685C10.1963 7.48365 9.91193 7.67615 9.76762 7.96476C9.64467 8.21067 9.82348 8.5 10.0984 8.5H16.9189C17.5645 8.5 18.1377 8.91314 18.3419 9.52564L18.5 9.99994H5.5L5.68162 9.45509C5.87176 8.88472 6.40553 8.5 7.00676 8.5C7.33796 8.5 7.64423 8.32409 7.81111 8.03801L8.71059 6.49611C8.8898 6.1889 9.2187 6 9.57436 6H15.8ZM3.5 12.9999C3.5 11.8953 4.39543 10.9999 5.5 10.9999H18.5C19.6046 10.9999 20.5 11.8953 20.5 12.9999V14.3041H3.5V12.9999ZM3.5 15.7041H20.5V16.9999C20.5 18.1045 19.6046 18.9999 18.5 18.9999H5.5C4.39543 18.9999 3.5 18.1045 3.5 16.9999V15.7041Z"
      fill={theme.tokens.colors['sport-icon']['sport-curling']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M12.1823 1.5C12.2928 1.5 12.3823 1.58954 12.3823 1.7V2.31597C12.3823 2.41999 12.3026 2.50664 12.1989 2.51528L6.90015 2.95685C6.57858 2.98365 6.29425 3.17615 6.14994 3.46476C6.02699 3.71067 6.20581 4 6.48074 4H13.3012C13.9468 4 14.52 4.41314 14.7242 5.02564L14.8823 5.49994H1.88232L2.06395 4.95509C2.25408 4.38472 2.78786 4 3.38908 4C3.72028 4 4.02655 3.82409 4.19344 3.53801L5.09291 1.99611C5.27213 1.6889 5.60102 1.5 5.95668 1.5H12.1823ZM2 6.49994C0.895431 6.49994 0 7.39537 0 8.49994V12.4999C0 13.6045 0.895431 14.4999 2 14.4999H14C15.1046 14.4999 16 13.6045 16 12.4999V8.49994C16 7.39537 15.1046 6.49994 14 6.49994H2Z"
      fill={theme.tokens.colors['sport-icon']['sport-curling']}
      fillRule="evenodd"
    />
  )
}

export const IconSportCurling: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

import { PORTAL_MODAL_ID } from './constants'
import { ModalProps, ViewComponentType } from './types'
import { StyledModal, StyledModalContainer } from './styled'

export const ModalDeprecated: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isOpen,
  children,
  onModalClose,
  styleContainer,
  view = ViewComponentType.CUSTOM
}) => {
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const hydrated = useHydrated()

  const backDropHandler = (e) => {
    if (modalWrapperRef?.current === e.target && onModalClose) {
      onModalClose()
    }
  }

  useEffect(() => {
    const documentBody = document.body as HTMLDivElement

    if (isOpen) {
      documentBody.classList.add('fixed')
    }
    return () => {
      documentBody.classList.remove('fixed')
    }
  }, [isOpen])

  const modalContent = isOpen ? (
    <StyledModal
      aria-hidden="true"
      ref={modalWrapperRef}
      onClick={backDropHandler}
    >
      <StyledModalContainer
        style={{
          ...styleContainer
        }}
        view={view}
      >
        {children}
      </StyledModalContainer>
    </StyledModal>
  ) : null

  return hydrated
    ? createPortal(
        modalContent,
        document.getElementById(PORTAL_MODAL_ID) as Element
      )
    : null
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { Button } from 'shared/ui/Button'
import { AppLink } from 'shared/lib/app-link'

export const VIDEO_PLAYER_STYLES = {
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  overflow: 'hidden'
}

export const VIDEO_PLAYER_WIDTH = '284px'

export const StyledBroadcastWrapper = styled.div`
  ${({ theme }) =>
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
    `}

  align-self: center;
  margin-bottom: 2px;
  margin-top: 2px;
  position: relative;

  user-select: none;
  width: ${VIDEO_PLAYER_WIDTH};
`

export const StyledBroadcastHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 44px;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      border-left: 1px solid ${theme.tokens.colors.divider['divider-primary']};
      border-right: 1px solid ${theme.tokens.colors.divider['divider-primary']};
      border-top: 1px solid ${theme.tokens.colors.divider['divider-primary']};

      border-top-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;

      border-top-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;

      padding: ${theme.tokens.alias.space['space-xxs-6']}px;
    `}
`

export const StyledBroadcastTournamentWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledBroadcastCompetitorsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const StyledBroadcastTournamentName = styled.div`
  ${typography['Hint / 11 Medium']};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const StyledCompetitors = styled(AppLink)`
  max-width: ${VIDEO_PLAYER_WIDTH};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${typography['Body / 12 Medium']};

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};

    &:hover {
      color: ${({ theme }) =>
        theme.tokens.colors.text['text-linkAccent-default']};
    }
  `}
`

export const StyledNotAuthorized = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 158px;
  justify-content: center;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      border: 1px solid ${theme.tokens.colors.divider['divider-primary']};

      border-bottom-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      border-bottom-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;

      padding: 0 ${theme.tokens.alias.space['space-m-16']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-m-16']}px;
    `}
`

export const StyledNotAuthorizedText = styled.span`
  ${typography['Body / 14 Medium']};

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}

  text-align: center;
`

export const StyledCloseIcon = styled(IconCloseOutline16)`
  cursor: pointer;
`

export const StyledButtonSignIn = styled(Button)`
  ${typography['Hint / 10 BOLD']}
`

export const StyledIconsWrapper = styled.div`
  display: flex;
  gap: 1px;
  justify-content: center;
`

import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { selectSport } from 'astra-core/containers/CommonDataProvider'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'
import { selectLineFetchItems } from 'entities/line-tournaments/model/selectors'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { EmptyPageFilter } from 'features/empty-page-filter'
import { ClearSearchResults } from 'features/clear-search-results'
import { EventsTable } from 'widgets/events-table'
import { getBackLinkBuilder } from 'shared/lib/events'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'
import {
  selectSearchTournamentEvents,
  selectSearchTournamentLayoutIsLoading
} from 'entities/search-tournament'
import { EventsPageTitle } from 'features/events-page-title/events-page-title'
import { LineParams } from 'entities/line/model/types'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { useSearchTournamentData } from './hook'
import { SearchTournamentWrapper } from './line.styled'

export const SearchTournamentEvents: FC = () => {
  const dispatch = useAppDispatch()
  const { tournamentId } = useParams<LineParams>()
  const searchText = useAppSelector(selectInputSearchLine)
  const events = useAppSelector((state: RootState) =>
    selectSearchTournamentEvents(state, +tournamentId)
  )
  const sport = useAppSelector((state) =>
    selectSport(state, events[0]?.tournament?.sportId)
  )

  const isLayoutLoading = useAppSelector(selectSearchTournamentLayoutIsLoading)
  const eventsLineFetchItems = useAppSelector(selectLineFetchItems)

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetFilters())
  }, [dispatch])

  const hasResults = !!events.length

  useSearchTournamentData()

  return (
    <SearchTournamentWrapper>
      <EventsPageTitle title={sport?.name} />

      {isLayoutLoading ? (
        <LoaderSpinner />
      ) : hasResults ? (
        <>
          {events.length && (
            <EventsTable
              headerProps={{
                withIcon: true,
                backLinkBuilder: (tournamentSportId, genericSportId) =>
                  getBackLinkBuilder(
                    ESportsMenuTabTypes.LINE,
                    tournamentSportId,
                    genericSportId
                  )
              }}
              events={events}
            />
          )}
        </>
      ) : searchText ? (
        <ClearSearchResults />
      ) : eventsLineFetchItems.loading && !hasResults ? (
        <LoaderSpinner />
      ) : (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </SearchTournamentWrapper>
  )
}

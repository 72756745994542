import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Widget } from '@typeform/embed-react'
import { getCookie, setCookie } from 'react-use-cookie'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  accountProviderActions,
  selectAccountUser,
  selectIsCodesTypeform,
  selectIsDisplayTypeformModal
} from 'astra-core/containers/AccountProvider'

import { EColorsNames, EColorsTypes } from 'shared/lib/theme'
import { ModalDeprecated } from 'shared/ui/modal-deprecated'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledTypeformModalHeaderWrapper,
  StyledTypeformModalHeaderExit
} from './styled'

export const TypeformComponents = () => {
  const dispatch = useAppDispatch()
  const [modalHeight, setModalHeight] = useState(100)
  const user = useAppSelector(selectAccountUser)
  const codes = useAppSelector(selectIsCodesTypeform)
  const isDisplayTypeformModal = useAppSelector(selectIsDisplayTypeformModal)
  const code = codes?.[0]
  const showTypeform = getCookie(code)

  useEffect(() => {
    if (codes?.length && !showTypeform) {
      dispatch(accountProviderActions.setIsDisplayTypeformModal(true))
    }
    return () => {
      dispatch(accountProviderActions.setIsDisplayTypeformModal(false))
    }
  }, [codes?.length, dispatch, showTypeform])

  const saveCookieTypeform = useCallback(() => {
    setCookie(code, dayjs().toString(), {
      days: 1
    })
  }, [code])

  const setTypeformShow = useCallback(() => {
    dispatch(accountProviderActions.setIsDisplayTypeformModal(false))
    saveCookieTypeform()
  }, [dispatch, saveCookieTypeform])

  const onHeightChanged = useCallback((data) => {
    setModalHeight(data.height)
  }, [])

  const isOpen = useMemo(
    () => user && codes?.length && !showTypeform && isDisplayTypeformModal,
    [codes?.length, isDisplayTypeformModal, showTypeform, user]
  )

  if (!code?.length) {
    return null
  }

  return (
    <ModalDeprecated
      styleContainer={{
        height: modalHeight,
        maxWidth: 1240,
        width: '100%',
        padding: 0,
        position: 'relative'
      }}
      isOpen={!!isOpen}
    >
      <StyledTypeformModalHeaderWrapper>
        <StyledTypeformModalHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 100,
            type: EColorsTypes.FIXED
          }}
          size={16}
          onClick={setTypeformShow}
        />
      </StyledTypeformModalHeaderWrapper>
      <Widget
        hidden={{
          client_id: user?.id || ''
        }}
        id={code}
        autoResize
        onClose={setTypeformShow}
        onEndingButtonClick={setTypeformShow}
        onHeightChanged={onHeightChanged}
      />
    </ModalDeprecated>
  )
}

import styled, { css } from 'styled-components'

export const StyledLiveMatchBroadcastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
`

export const StyledDraggedDestinationElement = styled.div<{
  destinationIndex: number
}>`
  position: absolute;

  ${({ theme: { tokens } }) => css`
    background-color: rgba(158, 204, 250, 0.15);
    border: solid 1px #258cf4;
    border-radius: ${tokens.alias['border-radius']['border-radius-m-8']}px;
  `}
`

import { HotProbabilityMarketType } from 'betweb-openapi-axios'

import { HotProbsMarketsHeaderMap } from './types'

export const HOT_PROBS_MARKETS_HEADER_MAP: HotProbsMarketsHeaderMap = {
  [HotProbabilityMarketType.MatchWinnerX3]: {
    id: HotProbabilityMarketType.MatchWinnerX3,
    config: [
      {
        localeKey: 'w1'
      },
      { label: 'X' },
      {
        localeKey: 'w2'
      }
    ]
  },

  [HotProbabilityMarketType.MatchWinnerX2]: {
    id: HotProbabilityMarketType.MatchWinnerX2,
    config: [
      {
        localeKey: 'w1'
      },
      {
        localeKey: 'w2'
      }
    ]
  },
  [HotProbabilityMarketType.DoubleChance]: {
    id: HotProbabilityMarketType.DoubleChance,
    config: [
      {
        label: '1X'
      },
      {
        label: '12'
      },
      {
        label: 'X2'
      }
    ]
  },
  [HotProbabilityMarketType.Handicap]: {
    id: HotProbabilityMarketType.Handicap,
    config: [
      {
        localeKey: 'handicap 1',
        customWidth: 77
      },
      {
        localeKey: 'handicap 2',
        customWidth: 77
      }
    ]
  },
  [HotProbabilityMarketType.Total]: {
    id: HotProbabilityMarketType.Total,
    isMerged: true,
    config: [
      {
        localeKey: 'total',
        customWidth: 40
      },
      {
        localeKey: 'total over'
      },
      {
        localeKey: 'total under'
      }
    ]
  },
  [HotProbabilityMarketType.HandicapWithOt]: {
    id: HotProbabilityMarketType.HandicapWithOt,
    config: [
      {
        localeKey: 'handicap 1 with ot',
        customWidth: 77
      },
      {
        localeKey: 'handicap 2 with ot',
        customWidth: 77
      }
    ]
  },
  [HotProbabilityMarketType.TotalWithOt]: {
    id: HotProbabilityMarketType.TotalWithOt,
    isMerged: true,
    config: [
      {
        localeKey: 'total with ot',
        customWidth: 64
      },
      {
        localeKey: 'total over'
      },
      {
        localeKey: 'total under'
      }
    ]
  },
  [HotProbabilityMarketType.MatchWinnerX2WithOt]: {
    id: HotProbabilityMarketType.MatchWinnerX2WithOt,
    config: []
  },
  [HotProbabilityMarketType.Unknown]: {
    id: HotProbabilityMarketType.Unknown,
    config: []
  }
}

export const HOT_PROBS_MARKETS_SMALL_HEADER_MAP: HotProbsMarketsHeaderMap = {
  [HotProbabilityMarketType.MatchWinnerX3]: {
    id: HotProbabilityMarketType.MatchWinnerX3,
    config: [
      {
        localeKey: 'w1'
      },
      { label: 'X' },
      {
        localeKey: 'w2'
      }
    ]
  },

  [HotProbabilityMarketType.MatchWinnerX2]: {
    id: HotProbabilityMarketType.MatchWinnerX2,
    config: [
      {
        localeKey: 'w1'
      },
      {
        localeKey: 'w2'
      }
    ]
  },
  [HotProbabilityMarketType.DoubleChance]: {
    id: HotProbabilityMarketType.DoubleChance,
    config: [
      {
        label: '1X'
      },
      {
        label: '12'
      },
      {
        label: 'X2'
      }
    ]
  },
  [HotProbabilityMarketType.Handicap]: {
    id: HotProbabilityMarketType.Handicap,
    config: [
      {
        localeKey: 'hc1'
      },
      {
        localeKey: 'hc2'
      }
    ]
  },
  [HotProbabilityMarketType.Total]: {
    id: HotProbabilityMarketType.Total,
    config: [
      {
        localeKey: 'total over'
      },
      {
        localeKey: 'total under'
      }
    ]
  },
  [HotProbabilityMarketType.HandicapWithOt]: {
    id: HotProbabilityMarketType.HandicapWithOt,
    config: [
      {
        localeKey: 'hc1 with ot'
      },
      {
        localeKey: 'hc2 with ot'
      }
    ]
  },
  [HotProbabilityMarketType.TotalWithOt]: {
    id: HotProbabilityMarketType.TotalWithOt,
    config: [
      {
        localeKey: 'total over'
      },
      {
        localeKey: 'total under'
      }
    ]
  },
  [HotProbabilityMarketType.MatchWinnerX2WithOt]: {
    id: HotProbabilityMarketType.MatchWinnerX2WithOt,
    config: []
  },
  [HotProbabilityMarketType.Unknown]: {
    id: HotProbabilityMarketType.Unknown,
    config: []
  }
}

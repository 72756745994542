import { FC } from 'react'
import { BetOutcomeStatus } from 'betweb-openapi-axios'

import { OutcomeStatusIconProps } from 'features/bets-history-outcomes-table/outcome-status-icon/outcome-status-icon.types'
import { IconCheckCircleFilled12 } from 'shared/ui/Icon/General/IconCheckCircleFilled12'
import { IconCircleMinus12 } from 'shared/ui/Icon/General/IconCircleMinus12'
import { IconLoadingSmall } from 'shared/ui/Icon/General/IconLoadingSmall'
import { IconHalfReturnCircleFilled12 } from 'shared/ui/Icon/General/IconHalfReturnCircleFilled12'
import { IconHalfWinCircleFilled12 } from 'shared/ui/Icon/General/IconHalfWinCircleFilled12'
import { IconArrowCircleFilled12 } from 'shared/ui/Icon/General/IconArrowCircleFilled12'

export const OutcomeStatusIcon: FC<OutcomeStatusIconProps> = ({ status }) => {
  if (status === BetOutcomeStatus.Win) {
    return <IconCheckCircleFilled12 colorToken="icon-positive" size={12} />
  }
  if (status === BetOutcomeStatus.Lost) {
    return <IconCircleMinus12 colorToken="icon-secondary" size={12} />
  }
  if (status === BetOutcomeStatus.Return) {
    return <IconArrowCircleFilled12 colorToken="icon-warning" size={12} />
  }

  if (status === BetOutcomeStatus.WinReturn) {
    return <IconHalfWinCircleFilled12 colorToken="icon-warning" size={12} />
  }

  if (status === BetOutcomeStatus.LostReturn) {
    return <IconHalfReturnCircleFilled12 colorToken="icon-warning" size={12} />
  }

  return <IconLoadingSmall colorToken="icon-secondary-3" size={12} />
}

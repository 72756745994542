import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon/types'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconPaymentCard: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M2.82843 0C2.29799 0 1.78929 0.210713 1.41421 0.585786L0.414214 1.58579C0.148997 1.851 0 2.21071 0 2.58579C0 3.36684 0.633165 4 1.41421 4H20.5858C21.3668 4 22 3.36684 22 2.58579C22 2.21071 21.851 1.851 21.5858 1.58579L20.5858 0.585787C20.2107 0.210714 19.702 0 19.1716 0H2.82843ZM0 8C0 6.89543 0.895431 6 2 6H20C21.1046 6 22 6.89543 22 8V13.1716C22 13.702 21.7893 14.2107 21.4142 14.5858L20.5858 15.4142C20.2107 15.7893 19.702 16 19.1716 16H2.82843C2.29799 16 1.78929 15.7893 1.41421 15.4142L0.585786 14.5858C0.210713 14.2107 0 13.702 0 13.1716V8ZM15 10C15 9.44772 15.4477 9 16 9H18C18.5523 9 19 9.44772 19 10V11C19 11.5523 18.5523 12 18 12H16C15.4477 12 15 11.5523 15 11V10Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

const listOfReplacementValuesForBasketInput = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09'
]

const dot = '.'
const comma = ','
const regExOnlyNumbers = /^[0-9]+$/
const regExForWholeValue = /^[0-9]*\d?(\.)(\d{1,2})?$/

export const onValidateBasketInputValue = (value: string) => {
  const inputValue = value.replaceAll(' ', '').replace(comma, dot)

  // if user writes the first character as "dot", we explicitly return 0.
  if (inputValue.length === 1 && inputValue.includes(dot)) {
    return `0${dot}`
    // if the user forgot to write a "dot" for certain cases, we add a "dot" between characters
  } else if (listOfReplacementValuesForBasketInput.includes(inputValue)) {
    return inputValue.split('').join(dot)
    // case when user adds only numbers
  } else if (regExOnlyNumbers.test(inputValue)) {
    return inputValue
  } else {
    // if regEx pass the test, we return new value else we return previous value
    return regExForWholeValue.test(inputValue)
      ? inputValue
      : inputValue.slice(0, -1)
  }
}

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { OddModes } from 'entities/event-probability/model/odd-mode'
import { getProbabilityCellStyle } from 'features/probability-cell/lib/probability-cell-style'
import { IconCoefficients } from 'shared/ui/Icon/General/IconCoefficients'

export const HotProbabilityCellWrapper = styled.div<{
  isInBasket: boolean
  mode: OddModes
  isEmpty: boolean
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  ${({ theme, isInBasket, mode, isEmpty }) => css`
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
    ${getProbabilityCellStyle({
      isInBasket,
      mode,
      isEmpty,
      oddTextColored: true
    })};
  `};
`

export const HotProbabilityCellLabel = styled.span`
  ${typography['Body / 12 SemiBold']};
`

export const HotProbabilityCellOdd = styled.span`
  ${typography['Body / 12 SemiBold']};
`

export const IncreaseIcon = styled(IconCoefficients)`
  position: absolute;
  right: 3px;
  top: 3px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-positive']};
  `}
`

export const DecreaseIcon = styled(IconCoefficients)`
  bottom: 3px;
  position: absolute;
  right: 3px;
  transform: rotate(90deg);
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-negative']};
  `}
`

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconCashRegisterPaydala: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 70 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.1903 5.02085C31.8374 5.01641 31.4882 5.09381 31.1698 5.24701C30.8514 5.40021 30.5724 5.62512 30.3543 5.90432V5.13549H29.1V13.272H30.3543V10.1046C30.5723 10.3838 30.8514 10.6088 31.1698 10.762C31.4882 10.9152 31.8374 10.9926 32.1903 10.9881C33.7525 10.9881 34.9387 9.64562 34.9387 8.00435C34.9387 6.36328 33.7525 5.02085 32.1903 5.02085ZM31.9622 9.84066C30.9472 9.84066 30.2516 9.03735 30.2516 8.00435C30.2516 6.97156 30.9472 6.16824 31.9622 6.16824C32.9658 6.16824 33.6614 6.97156 33.6614 8.00435C33.6614 9.03735 32.9658 9.84066 31.9622 9.84066ZM46.7974 5.13549L45.0411 9.19799L43.2965 5.13549H41.9165L44.3685 10.5638L43.1483 13.272H44.5279L48.1886 5.13549H46.7974ZM40.0788 5.90432C39.8631 5.62528 39.5861 5.40033 39.2696 5.24705C38.953 5.09377 38.6055 5.01633 38.2542 5.02085C36.692 5.02085 35.5059 6.36328 35.5059 8.00435C35.5059 9.64562 36.692 10.9881 38.2542 10.9881C38.6055 10.9926 38.9531 10.9152 39.2696 10.7619C39.5861 10.6087 39.8631 10.3837 40.0788 10.1046V10.8735H41.3333V5.13549H40.0788V5.90432ZM38.4823 9.84066C37.4787 9.84066 36.7831 9.03735 36.7831 8.00431C36.7831 6.97156 37.4787 6.16824 38.4823 6.16824C39.4858 6.16824 40.1814 6.97156 40.1814 8.00431C40.1814 9.03735 39.4858 9.84066 38.4823 9.84066Z"
        fill="currentColor"
      />
      <path
        d="M52.6757 5.90453C52.46 5.62545 52.1828 5.40045 51.8665 5.24717C51.5498 5.09389 51.2023 5.01649 50.8512 5.02105C49.2887 5.02105 48.1028 6.36349 48.1028 8.00452C48.1028 9.64579 49.2887 10.9883 50.8512 10.9883C51.2023 10.9928 51.5498 10.9155 51.8665 10.7622C52.1828 10.6089 52.46 10.3839 52.6757 10.1047V10.8736H53.9299V2.26678H52.6757V5.90453ZM51.0792 9.84083C50.0759 9.84083 49.3801 9.03756 49.3801 8.00452C49.3801 6.97172 50.0759 6.16841 51.0792 6.16841C52.0826 6.16841 52.7784 6.97172 52.7784 8.00452C52.7784 9.03756 52.0826 9.84083 51.0792 9.84083ZM68.7455 5.13565V5.90449C68.5298 5.62545 68.253 5.40045 67.9363 5.24717C67.6199 5.09389 67.2721 5.01649 66.921 5.02101C65.3588 5.02101 64.173 6.36345 64.173 8.00452C64.173 9.64579 65.3588 10.9882 66.921 10.9882C67.2721 10.9928 67.6199 10.9154 67.9363 10.7621C68.253 10.6089 68.5298 10.3839 68.7455 10.1047V10.8736H70.0001V5.13565H68.7455ZM67.149 9.84083C66.1457 9.84083 65.4499 9.03756 65.4499 8.00452C65.4499 6.97172 66.1457 6.16841 67.149 6.16841C68.1524 6.16841 68.8486 6.97172 68.8486 8.00452C68.8486 9.03756 68.1524 9.84083 67.149 9.84083ZM61.9878 10.8736H63.2423V2.26678H61.9878V10.8736ZM59.4363 5.90449C59.2206 5.62545 58.9438 5.40045 58.6271 5.24717C58.3107 5.09389 57.9632 5.01649 57.6117 5.02101C56.0496 5.02101 54.8633 6.36345 54.8633 8.00452C54.8633 9.64579 56.0496 10.9882 57.6117 10.9882C57.9632 10.9928 58.3107 10.9154 58.6271 10.7621C58.9438 10.6089 59.2206 10.3839 59.4363 10.1047V10.8736H60.6908V5.13565H59.4363V5.90449ZM57.8398 9.84083C56.8365 9.84083 56.1407 9.03751 56.1407 8.00448C56.1407 6.97172 56.8365 6.16841 57.8398 6.16841C58.8435 6.16841 59.5389 6.97172 59.5389 8.00448C59.5389 9.03751 58.8435 9.84083 57.8398 9.84083Z"
        fill="#FF6A13"
      />
      <path
        d="M10.506 3.99997H6.07746L4.67579 11.9998H9.01818C9.47204 11.9998 9.91144 11.8389 10.2591 11.5453C10.6069 11.2517 10.8407 10.8443 10.9196 10.3945L11.7735 5.52012C11.8062 5.33384 11.7979 5.14264 11.7492 4.95996C11.7006 4.77725 11.6128 4.60749 11.492 4.46261C11.3712 4.31773 11.2203 4.20121 11.0499 4.12129C10.8796 4.04137 10.6939 3.99996 10.506 3.99997ZM3.72271 3.99997C3.26878 3.99997 2.82938 4.16093 2.48166 4.45457C2.13393 4.74821 1.90011 5.15572 1.82127 5.60556L0 16H2.98116L5.08391 3.99997H3.72271Z"
        fill="currentColor"
      />
      <path
        d="M14.6535 3.99992C14.1996 3.9999 13.7602 4.16086 13.4125 4.4545C13.0648 4.74814 12.8311 5.15565 12.7523 5.60553L11.8981 10.4796C11.8655 10.6659 11.8738 10.8571 11.9224 11.0398C11.9711 11.2225 12.0589 11.3922 12.1797 11.5371C12.3005 11.682 12.4514 11.7985 12.6217 11.8784C12.7921 11.9583 12.9777 11.9998 13.1657 11.9997H17.5942L18.9959 3.99992H14.6535ZM18.5878 11.9997H19.949C20.4029 11.9998 20.8423 11.8389 21.19 11.5453C21.5377 11.2517 21.7716 10.8442 21.8504 10.3944L23.6717 0H20.6905L18.5878 11.9997Z"
        fill="#FF6A13"
      />
    </svg>
  </Icon>
)

import {
  selectBasketOutcomesLength,
  selectExpressRate
} from 'astra-core/containers/BasketProvider'
import React, { FC, useCallback } from 'react'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { separateNumber } from 'shared/lib/format/separate-number'
import { rightBasketContainerActions } from 'entities/basket/model/slice'
import { ButtonOpenBasketForSmallWindow as ButtonOpenBasketForSmallWindowEntity } from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const ButtonOpenBasketForSmallWindow: FC<{
  isSmall?: boolean
  className?: string
}> = ({ isSmall, className }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectAccountUser)
  const expressRate = useAppSelector(selectExpressRate)
  const outcomesLength = useAppSelector(selectBasketOutcomesLength)

  const toggleShowBasket = useCallback(() => {
    dispatch(
      rightBasketContainerActions.toggleBasketOnSmallWindow({ isShow: true })
    )
  }, [dispatch])

  return user ? (
    <ButtonOpenBasketForSmallWindowEntity
      className={className}
      expressRate={separateNumber(+expressRate.toFixed(2))}
      isSmall={isSmall}
      outcomesCount={outcomesLength}
      onClick={toggleShowBasket}
    />
  ) : null
}

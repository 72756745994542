import React, { FC } from 'react'

import { CardListOrdinar } from '../card-list'
import { OrdinarFooter } from '../footer'

import { OrdinarProps } from './ordinar.types'

export const Ordinar: FC<OrdinarProps> = ({ outcomes }) => (
  <>
    <CardListOrdinar outcomes={outcomes} />

    <OrdinarFooter />
  </>
)

import { useEffect } from 'react'
import {
  selectBetsHistoryDates,
  selectBetsHistoryBetsStatus,
  selectBetsHistoryGameVendor,
  selectBetsHistoryBetsTypes,
  betsHistoryProviderActions,
  selectBetsHistoryPagination
} from 'astra-core/containers/BetsHistoryProvider'

import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const useFetchBetsHistoryPage = () => {
  const dispatch = useAppDispatch()

  /*
  Refetch bets history page data on any filters change
   */

  const dates = useAppSelector(selectBetsHistoryDates)
  const betsStatus = useAppSelector(selectBetsHistoryBetsStatus)
  const gameVendor = useAppSelector(selectBetsHistoryGameVendor)
  const betsTypes = useAppSelector(selectBetsHistoryBetsTypes)
  const pagination = useAppSelector(selectBetsHistoryPagination)

  useEffect(() => {
    dispatch(betsHistoryProviderActions.fetchHistoryBetsPage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    betsStatus,
    betsTypes,
    dates,
    gameVendor,
    dispatch,
    pagination.offset,
    pagination.limit
  ])
}

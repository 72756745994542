import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconHalfReturnCircleFilled12: FC<IconProps> = (props) => {
  const theme = useTheme()

  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="12"
        viewBox="0 0 13 12"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.08928 0.590492C8.70897 0.210175 8.20855 0 7.67811 0H4.36535C3.8349 0 3.32448 0.210175 2.95417 0.590492L0.602211 2.93244C0.221894 3.31276 0.0117188 3.81318 0.0117188 4.34362V7.65638C0.0117188 8.18682 0.221894 8.69725 0.602211 9.06756L2.94416 11.4095C3.32448 11.7898 3.8249 12 4.35534 12H4.93654L3.69257 10.756C3.24444 10.3168 3.00195 9.71838 3.00195 9.10403V6.89536C3.00195 6.27081 3.25149 5.68445 3.68854 5.24739L3.69005 5.24589L5.25344 3.68915C5.69261 3.24157 6.29064 2.99939 6.90459 2.99939H9.11327C9.73781 2.99939 10.3242 3.24892 10.7612 3.68597L10.7627 3.68749L12.0117 4.94182V4.34362C12.0117 3.81318 11.8015 3.30275 11.4212 2.93244L9.08928 0.590492Z"
          fill={theme.tokens.colors.icon['icon-warning']}
        />
        <path
          clipRule="evenodd"
          d="M10.0541 4.39308C9.80056 4.13952 9.46692 3.99939 9.11327 3.99939H6.90459C6.55094 3.99939 6.21063 4.13952 5.96374 4.39308L4.39564 5.9545C4.14208 6.20807 4.00195 6.5417 4.00195 6.89536V9.10403C4.00195 9.45769 4.14208 9.798 4.39564 10.0449L5.95706 11.6063C6.21063 11.8599 6.54427 12 6.89792 12H9.1066C9.46025 12 9.80056 11.8599 10.0475 11.6063L11.6089 10.0449C11.8624 9.79132 12.0026 9.45769 12.0026 9.10403V6.89536C12.0026 6.5417 11.8624 6.20139 11.6089 5.9545L10.0541 4.39308ZM5.53711 8.06336C5.53711 7.724 5.81226 7.44885 6.15161 7.44885H9.8203C10.1597 7.44885 10.4348 7.724 10.4348 8.06336C10.4348 8.40271 10.1597 8.67786 9.8203 8.67786H6.15161C5.81226 8.67786 5.53711 8.40271 5.53711 8.06336Z"
          fill={theme.tokens.colors.icon['icon-secondary']}
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

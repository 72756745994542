import React from 'react'

export const LogoLight = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 169 24"
      width="169"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.37063 5.00803C2.23282 4.90535 2.03551 4.95958 1.96913 5.11839L0.164571 9.4358C-0.169185 10.2343 0.0142731 11.1561 0.628102 11.7649L3.84616 14.9564L0.722807 14.9496C0.533267 14.9492 0.40487 15.1431 0.478729 15.3181L2.41544 19.9095C2.65208 20.4705 3.11771 20.9024 3.69377 21.0953L12.8897 24.174C13.3243 24.3195 13.7943 24.3195 14.229 24.174L23.4249 21.0953C24.0009 20.9024 24.4666 20.4705 24.7032 19.9095L26.6399 15.3181C26.7138 15.1431 26.5854 14.9492 26.3958 14.9496L23.2725 14.9564L26.4905 11.7649C27.1044 11.1561 27.2878 10.2343 26.9541 9.4358L25.1495 5.11839C25.0831 4.95958 24.8858 4.90535 24.748 5.00803L22.5845 6.62006L22.5644 3.87256C22.5582 3.02459 22.0493 2.26175 21.2701 1.93267L16.7442 0.0210645C16.5699 -0.0525438 16.3772 0.0758153 16.3772 0.265492V2.06803C16.3772 2.72841 16.6837 3.35114 17.2064 3.75262L21.8738 7.33731V14.9564L13.5593 22.2077L5.24488 14.9564V7.33731L9.9122 3.75262C10.4349 3.35114 10.7415 2.72841 10.7415 2.06803V0.265492C10.7415 0.0758153 10.5488 -0.0525438 10.3745 0.0210645L5.84852 1.93267C5.06938 2.26175 4.56041 3.02459 4.55423 3.87256L4.53418 6.62006L2.37063 5.00803Z"
        fill="#E61919"
      />
      <path
        clipRule="evenodd"
        d="M45.3452 19.2142C52.093 19.2142 54.2321 16.0477 54.2321 12.1415C54.2321 8.23531 52.093 5.06873 45.3452 5.06873C38.5974 5.06873 36.3145 8.23531 36.3145 12.1415C36.3145 16.0477 38.5974 19.2142 45.3452 19.2142ZM45.311 15.8708C48.8549 15.8708 49.5916 14.2011 49.5916 12.1415C49.5916 10.0819 48.8549 8.41221 45.311 8.41221C41.7672 8.41221 40.955 10.0819 40.955 12.1415C40.955 14.2011 41.7672 15.8708 45.311 15.8708Z"
        fill="#E61919"
        fillRule="evenodd"
      />
      <path
        d="M70.8606 5.7117C70.8606 5.49864 71.0337 5.32592 71.2473 5.32592H75.1144C75.328 5.32592 75.5011 5.49864 75.5011 5.7117V18.6999C75.5011 18.9129 75.328 19.0856 75.1144 19.0856H71.2473C71.0337 19.0856 70.8606 18.9129 70.8606 18.6999V5.7117Z"
        fill="#E61919"
      />
      <path
        d="M78.7237 5.32592C78.5101 5.32592 78.337 5.49864 78.337 5.7117V18.6999C78.337 18.9129 78.5101 19.0856 78.7237 19.0856H82.5908C82.8043 19.0856 82.9775 18.9129 82.9775 18.6999V11.1865L86.7386 18.8692C86.8034 19.0016 86.9383 19.0856 87.0861 19.0856H89.3149C89.4602 19.0856 89.5931 19.0045 89.6592 18.8755L93.6764 11.0375V18.6999C93.6764 18.9129 93.8496 19.0856 94.0631 19.0856H97.9302C98.1438 19.0856 98.3169 18.9129 98.3169 18.6999V5.7117C98.3169 5.49864 98.1438 5.32592 97.9302 5.32592H92.8825C92.7369 5.32592 92.6037 5.40746 92.5377 5.53692L88.198 14.0558L84.2415 5.54932C84.1781 5.41308 84.0413 5.32592 83.8907 5.32592H78.7237Z"
        fill="#E61919"
      />
      <path
        d="M60.935 5.7117C60.935 5.49864 60.7619 5.32592 60.5483 5.32592H56.6812C56.4677 5.32592 56.2945 5.49864 56.2945 5.7117V18.6999C56.2945 18.9129 56.4677 19.0856 56.6812 19.0856H68.2086C68.3994 19.0856 68.5617 18.9467 68.5908 18.7585L68.9874 16.1866C69.0234 15.9529 68.8422 15.7422 68.6052 15.7422H60.935V5.7117Z"
        fill="#E61919"
      />
      <path
        clipRule="evenodd"
        d="M101.797 5.32592C101.584 5.32592 101.411 5.49864 101.411 5.7117V18.6999C101.411 18.9129 101.584 19.0856 101.797 19.0856H105.664C105.878 19.0856 106.051 18.9129 106.051 18.6999V14.842H111.078V14.8418C111.105 14.8419 111.132 14.842 111.158 14.842C115.698 14.842 117.137 12.7117 117.137 10.084C117.137 7.45616 115.698 5.32592 111.158 5.32592C111.132 5.32592 111.105 5.32599 111.078 5.32614V5.32592H101.797ZM106.051 8.6694V11.4985H111.091C112.254 11.4985 112.496 10.8931 112.496 10.084C112.496 9.27482 112.254 8.6694 111.091 8.6694H106.051Z"
        fill="#E61919"
        fillRule="evenodd"
      />
      <path
        d="M153.359 5.32592C153.145 5.32592 152.972 5.49864 152.972 5.7117V8.02642C152.972 8.23949 153.145 8.41221 153.359 8.41221H158.386V18.6999C158.386 18.9129 158.559 19.0856 158.773 19.0856H162.64C162.853 19.0856 163.026 18.9129 163.026 18.6999V8.41221H167.597C167.786 8.41221 167.947 8.27587 167.979 8.08985L168.365 5.77513C168.404 5.53998 168.223 5.32592 167.984 5.32592H153.359Z"
        fill="#E61919"
      />
      <path
        clipRule="evenodd"
        d="M119.586 5.32592C119.372 5.32592 119.199 5.49864 119.199 5.71171V18.6999C119.199 18.9129 119.372 19.0856 119.586 19.0856H130.801V19.0855C130.823 19.0856 130.845 19.0856 130.867 19.0856C134.634 19.0856 135.828 17.3584 135.828 15.2278C135.828 13.5204 135.061 12.0721 132.818 11.564C134.272 10.9855 134.797 9.90584 134.797 8.6694C134.797 6.82285 133.627 5.32592 129.938 5.32592C129.924 5.32592 129.911 5.32594 129.898 5.32597L119.586 5.32592ZM129.898 15.9993H123.84V13.6846H129.898V13.6884C129.943 13.6859 129.989 13.6846 130.037 13.6846C130.989 13.6846 131.187 14.18 131.187 14.842C131.187 15.504 130.989 15.9993 130.037 15.9993C129.989 15.9993 129.943 15.9981 129.898 15.9956V15.9993ZM129.125 10.5983H123.84V8.41221H129.125V8.41281C129.977 8.43164 130.156 8.89297 130.156 9.50527C130.156 10.1176 129.977 10.5789 129.125 10.5977V10.5983Z"
        fill="#E61919"
        fillRule="evenodd"
      />
      <path
        d="M138.277 5.32592C138.063 5.32592 137.89 5.49864 137.89 5.7117V18.6999C137.89 18.9129 138.063 19.0856 138.277 19.0856H151.038C151.252 19.0856 151.425 18.9129 151.425 18.6999V16.3851C151.425 16.1721 151.252 15.9993 151.038 15.9993H142.531V13.6846H148.133C148.322 13.6846 148.483 13.5483 148.514 13.3623L148.901 11.0475C148.94 10.8124 148.758 10.5983 148.519 10.5983H142.531V8.41221H151.038C151.252 8.41221 151.425 8.23949 151.425 8.02642V5.7117C151.425 5.49864 151.252 5.32592 151.038 5.32592H138.277Z"
        fill="#E61919"
      />
    </svg>
  )
}

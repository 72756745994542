import React from 'react'

import { IconButton } from 'shared/ui/buttons/IconButton'
import { ERoutes } from 'shared/types/routes'
import { IconStatefulNotification } from 'features/icon-stateful-notification'

import { useHandleRedirectList } from '../../hooks'

export const NotificationsButton = () => {
  const handleRedirectList = useHandleRedirectList()

  return (
    <IconButton
      icon={IconStatefulNotification}
      iconProps={{ size: 24 }}
      view="ghost-l"
      onClick={handleRedirectList(ERoutes.Notifications)}
    />
  )
}

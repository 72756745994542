import { IdentificationConstraints } from '../../store/types'

export const MAP_STATUS_TO_CONSTRAINTS: Record<
  number | 'empty',
  IdentificationConstraints[]
> = {
  empty: [
    IdentificationConstraints.TOP_UP,
    IdentificationConstraints.WITHDRAWAL,
    IdentificationConstraints.BETS
  ],
  0: [IdentificationConstraints.WITHDRAWAL],
  1: [IdentificationConstraints.WITHDRAWAL],
  3: [],
  4: [
    IdentificationConstraints.TOP_UP,
    IdentificationConstraints.WITHDRAWAL,
    IdentificationConstraints.BETS
  ],
  6: [IdentificationConstraints.WITHDRAWAL],
  7: [
    IdentificationConstraints.TOP_UP,
    IdentificationConstraints.WITHDRAWAL,
    IdentificationConstraints.BETS
  ],
  8: [IdentificationConstraints.WITHDRAWAL],
  9: [
    IdentificationConstraints.TOP_UP,
    IdentificationConstraints.WITHDRAWAL,
    IdentificationConstraints.BETS
  ],
  10: [IdentificationConstraints.WITHDRAWAL],
  11: [
    IdentificationConstraints.TOP_UP,
    IdentificationConstraints.WITHDRAWAL,
    IdentificationConstraints.BETS
  ],
  12: [IdentificationConstraints.WITHDRAWAL]
}

import { ChangeEvent, createContext, useContext } from 'react'

export const GlobalSearchValueContext = createContext<{
  value: string
  setValue: (value: string) => void
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
} | null>(null)

export const useGlobalSearchValueContext = () =>
  useContext(GlobalSearchValueContext)!

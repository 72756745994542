import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCheckCircleFilled24: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.4964 3C15.2868 3 16.0372 3.31 16.6076 3.88L20.1095 7.39C20.6798 7.95 21 8.71 21 9.51V14.48C21 15.28 20.6798 16.03 20.1095 16.6L16.5976 20.11C16.0372 20.68 15.2768 21 14.4764 21H9.51362C8.71317 21 7.96276 20.68 7.39244 20.11L3.88049 16.6C3.31017 16.04 3 15.28 3 14.48V9.52C3 8.72 3.31017 7.97 3.88049 7.4L7.41245 3.88C7.96276 3.31 8.73318 3 9.52362 3H14.4964ZM16.2374 9.33002C16.6176 9.73002 16.5875 10.37 16.1773 10.74L11.7949 14.74C11.4147 15.09 10.8243 15.09 10.4441 14.74L7.82268 12.34C7.42245 11.97 7.39244 11.34 7.76264 10.93C8.13285 10.53 8.7632 10.5 9.17343 10.87L11.1245 12.65L14.8266 9.27002C15.2268 8.89002 15.8671 8.92002 16.2374 9.33002Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { routes } from 'pages/model'
import { IRoute } from 'shared/types/routes'

import {
  ICustomRoute,
  IFilterRoutes,
  IRouteTranslate
} from './filterRoutes.types'

export const filterRoutes = <Route extends IRouteTranslate>({
  routeList,
  t,
  isIframe,
  forwardProps
}: IFilterRoutes<Route>): ICustomRoute<Route>[] =>
  routeList
    .filter((item) => (isIframe ? !item.hideInIframe : true))
    .map((item) => ({
      ...routes.find(({ path }) => path === item.path)!,
      translatedName: t(item.locale),
      icon: item.icon,
      ...forwardProps?.(item)
    })) as ICustomRoute<Route>[]

export const isActiveCurrentPath = (
  path: string,
  itemPath: IRoute['path'] | string
) => typeof itemPath === 'string' && `${path}/`.includes(`${itemPath}/`)

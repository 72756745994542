import { TBaseColorsTheme } from 'shared/lib/theme'

export const DARK_THEME_COLORS: TBaseColorsTheme = {
  custom: {
    // colors for few color cases
    primary: {
      0: '#FFFFFF',
      1: '#333333',
      2: '#191919', // mostly background
      3: '#0D0D0D',
      4: '#333333', // mostly border
      5: '#262626',
      6: '#B3B3B3',
      7: '#B3B3B3',
      8: '#333333',
      9: '#808080',
      10: '#262626',
      11: '#E6E6E6',
      12: '#FFFFFF',
      13: '#CCCCCC',
      14: '#4D4D4D',
      15: '#CCCCCC',
      16: '#B3B3B3',
      17: '#B3B3B3',
      18: '#262626',
      19: '#4D4D4D', // mostly hover border input, select, etc.
      20: '#333333',
      21: '#4D4D4D',
      22: '#0D0D0D',
      23: '#F2F2F2',
      24: '#333333',
      25: '#666666',
      26: '#666666',
      27: '#262626',
      28: '#666666',
      29: '#CCCCCC',
      30: '#FFFFFF',
      31: '#262626',
      32: '#CCCCCC',
      33: '#808080',
      34: '#4D4D4D',
      35: '#666666',
      36: '#000000',
      37: '#262626',
      38: '#FFD240',
      39: '#B3B3B3',
      40: '#FFFFFF',
      41: '#333333',
      42: '#333333',
      43: '#FFFFFF',
      44: '#4D4D4D',
      45: '#4D4D4D',
      46: '#FFFFFF',
      47: '#666666',
      48: '#4D4D4D',
      49: '#808080',
      50: '#1A1A1A',
      51: '#E6E6E6',
      52: '#1A1A1A',
      53: '#333333',
      54: '#B3B3B3',
      55: '#808080',
      56: '#B3B3B3',
      57: '#666666',
      58: '#FFFFFF',
      59: '#333333',
      60: '#CCCCCC',
      61: '#333333',
      62: '#2E2E2E',
      63: '#FFFFFF',
      64: '#333333',
      65: '#262626',
      66: '#FFFFFF',
      67: '#CCCCCC',
      68: '#333333',
      69: '#D9D9D9',
      70: '#666666',
      71: '#F7F1DC'
    },
    blue: {
      0: '#4E7FFF',
      1: '#4E7FFF',
      2: '#48607B',
      3: '#102151',
      4: '#1F397C',
      5: '#1F242E',
      6: '#333333',
      7: '#677899',
      8: '#4D4D4D',
      9: '#FFFFFF',
      10: '#FFFFFF',
      11: '#333333',
      12: '#303848',
      13: '#677899',
      14: '#A3ADC2',
      15: '#4E7FFF',
      16: '#392C53',
      17: '#DFD3F8',
      18: '#DFD3F8',
      19: '#503E74',
      20: '#5C4785',
      21: '#A07AEB',
      22: '#503E74',
      23: '#CCB4FE',
      24: '#9470FE',
      25: '#A07AEB',
      26: '#A07AEB'
    },
    yellow: {
      0: '#486C7B',
      1: '#262626',
      2: '#333333',
      3: '#89732B',
      4: '#4D3E00',
      5: '#262626',
      6: '#333333',
      7: '#333333',
      8: '#353227',
      9: '#484436',
      10: '#FFC400',
      11: '#333333'
    },
    red: {
      0: '#333333',
      1: '#481A1A'
    },
    brown: {
      0: '#8F5C36'
    },
    green: {
      0: '#333333',
      1: '#356434',
      2: '#1C3A1C',
      3: '#43B243',
      4: '#333333',
      5: '#43B343',
      6: '#40BF40',
      7: '#3AAC3A'
    }
  },
  default: {
    // colors for one color case

    primary: {
      100: '#000000',
      90: '#FFFFFF',
      80: '#F2F2F2',
      70: '#B3B3B3',
      60: '#D9D9D9',
      50: '#808080',
      40: '#999999',
      30: '#B3B3B3',
      20: '#333333',
      15: '#666666',
      10: '#4D4D4D',
      7: '#EFF2F5',
      5: '#262626',
      3: '#F7F7F7',
      1: '#F6F6F6',
      0: '#1A1A1A'
    },
    green: {
      50: '#43B243',
      11: '#204820',
      10: '#204820',
      5: '#1C3A1C'
    },
    red: {
      100: '#FF3333',
      60: '#C62C2C',
      50: '#E03A3A',
      40: '#EF4848',
      10: '#581F1F',
      5: '#481A1A'
    },
    yellow: {
      50: '#FFD240',
      31: '#FFD240',
      30: '#FFE48C'
    },
    blue: {
      71: '#1F242E',
      70: '#1F397C',
      51: '#0D59F2',
      50: '#4E7FFF',
      1: '#3D7AF5',
      0: '#4E7FFF'
    },
    shadow: {
      0: 'rgba(0, 0, 0, 0.6)',
      1: 'rgba(13, 13, 13, 0.6)'
    }
  }
}

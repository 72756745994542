import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'shared/ui/Icon'

import { ISportIconWrapperProps, SportIconSize } from '../sport-icon.types'

const sportIconSizeMap: Record<SportIconSize, number> = {
  s: 16,
  m: 24
}

export const SportIconWrapper: FC<ISportIconWrapperProps> = ({
  children,
  size,
  backgroundColor,
  withBackground,
  numericSize,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Icon size={numericSize ?? sportIconSizeMap[size]} {...props}>
      <svg
        viewBox={`0 0 ${sportIconSizeMap[size]} ${sportIconSizeMap[size]}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {withBackground && <SportIconBackground fillColor={backgroundColor} />}

        {children({ theme, size })}
      </svg>
    </Icon>
  )
}

const SportIconBackground = ({ fillColor }) => (
  <path
    d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
    fill={fillColor}
  />
)

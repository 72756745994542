import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M5 8C6.10457 8 7 7.10457 7 6C7 4.89543 6.10457 4 5 4C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8Z"
        fill={theme.tokens.colors['sport-icon']['sport-cricket']}
      />
      <path
        clipRule="evenodd"
        d="M19.4348 2.56308L21.4368 4.56516C22.1876 5.31594 22.1876 6.56724 21.4368 7.31802L11.8797 16.8752L7.12475 12.1202L16.6819 2.56308C17.4327 1.81231 18.684 1.81231 19.4348 2.56308ZM6.44825 13.4236C6.43109 13.6368 6.4499 13.8559 6.50467 14.075C6.67151 14.7424 6.50467 15.4097 6.00416 15.9103L2.25026 19.6642C1.91658 19.9978 1.91658 20.4984 2.25026 20.7486L3.2513 21.7497C3.58498 22.0833 4.0855 22.0833 4.33576 21.7497L8.08965 17.9958C8.59017 17.4952 9.25753 17.3284 9.92489 17.4952C10.144 17.55 10.3631 17.5688 10.5763 17.5517L6.44825 13.4236Z"
        fill={theme.tokens.colors['sport-icon']['sport-cricket']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M13.9479 0.450469L15.5495 2.05214C16.1502 2.65276 16.1502 3.65381 15.5495 4.25443L7.80813 11.9958C7.40772 12.3962 6.87383 12.5297 6.33994 12.3962C5.80605 12.2628 5.27216 12.3962 4.87174 12.7967L1.86861 15.7998C1.6684 16.0667 1.26799 16.0667 1.00104 15.7998L0.200209 14.999C-0.0667362 14.7987 -0.0667362 14.3983 0.200209 14.1314L3.20334 11.1283C3.60375 10.7278 3.73723 10.194 3.60375 9.66006C3.47028 9.12617 3.60375 8.59228 4.00417 8.19187L11.7456 0.450469C12.3462 -0.150156 13.3472 -0.150156 13.9479 0.450469ZM3.99997 3.19962C3.99997 4.08327 3.28362 4.79962 2.39996 4.79962C1.5163 4.79962 0.799954 4.08327 0.799954 3.19962C0.799954 2.31596 1.5163 1.59961 2.39996 1.59961C3.28362 1.59961 3.99997 2.31596 3.99997 3.19962Z"
      fill={theme.tokens.colors['sport-icon']['sport-cricket']}
      fillRule="evenodd"
    />
  )
}

export const IconSportCricket: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

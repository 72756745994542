import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconBerekeBank: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 95 16"
      width="95"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8752 0.0136719C14.6089 0.0136719 16.0098 1.41357 16.0098 3.14611V4.49519C16.0098 6.22774 14.6089 7.62765 12.8752 7.62765H0.0314941V3.14611C0.0314941 1.41357 1.43238 0.0136719 3.16614 0.0136719H12.8752Z"
        fill="#023DFF"
      />
      <path
        d="M12.8752 15.9862C14.6089 15.9862 16.0098 14.5864 16.0098 12.8538V11.5048C16.0098 9.77225 14.6089 8.37231 12.8752 8.37231H0.0314941V12.8538C0.0314941 14.5864 1.43238 15.9862 3.16614 15.9862H12.8752Z"
        fill="#00BB00"
      />
      <path
        d="M21.4781 11.3428H23.6788C24.2011 11.3428 24.6081 11.2134 24.8901 10.95C25.1768 10.6913 25.3201 10.3448 25.3201 9.91051C25.3201 9.47624 25.1768 9.13437 24.8901 8.86637C24.6035 8.59844 24.2011 8.46444 23.6788 8.46444H21.4781V11.3382V11.3428ZM21.4781 7.08297H23.2165C23.6556 7.08297 24.007 6.95824 24.2705 6.70417C24.5294 6.45005 24.6635 6.13126 24.6635 5.73855C24.6635 5.34585 24.5341 5.04553 24.2705 4.78681C24.007 4.52807 23.6556 4.3941 23.2165 4.3941H21.4781V7.08764V7.08297ZM19.8506 12.8443V2.88794H23.3273C24.2058 2.88794 24.9178 3.15129 25.4587 3.67335C25.9996 4.19543 26.2725 4.84687 26.2725 5.62305C26.2725 6.44543 25.9673 7.09224 25.3617 7.55884C26.4205 8.01624 26.9475 8.82477 26.9475 9.98444C26.9475 10.8438 26.6423 11.5368 26.0273 12.0589C25.4171 12.581 24.6265 12.8443 23.6649 12.8443H19.8506Z"
        fill="currentColor"
      />
      <path
        d="M31.03 7.03694C30.6185 7.03694 30.2301 7.16167 29.8649 7.40654C29.4996 7.656 29.2685 8.03027 29.1622 8.53387H32.8932C32.8193 8.04874 32.6111 7.67454 32.269 7.4204C31.9269 7.16167 31.5154 7.03694 31.0254 7.03694H31.03ZM31.1548 12.9507C30.1515 12.9507 29.3055 12.6042 28.6074 11.9111C27.9092 11.2181 27.5625 10.3588 27.5625 9.33314C27.5625 8.30747 27.9046 7.44347 28.5842 6.75514C29.2638 6.06208 30.1146 5.71558 31.1225 5.71558C32.1304 5.71558 33.0134 6.08056 33.6468 6.81054C34.2802 7.54054 34.5714 8.48767 34.516 9.65654H29.102C29.1575 10.2156 29.384 10.6683 29.7817 11.0102C30.1793 11.3521 30.637 11.5231 31.1502 11.5231C31.9269 11.5231 32.5419 11.2366 32.9995 10.6683L34.1091 11.6201C33.4064 12.5071 32.4216 12.9507 31.1502 12.9507H31.1548Z"
        fill="currentColor"
      />
      <path
        d="M35.5051 12.8392V5.83511H37.0494V6.57895C37.4978 6.02915 38.1034 5.75195 38.857 5.75195C39.1021 5.75195 39.2962 5.77505 39.4442 5.82125V7.41979C39.2316 7.32739 38.9588 7.28119 38.6304 7.28119C37.9001 7.28119 37.3729 7.57225 37.0447 8.14979V12.8439H35.5005L35.5051 12.8392Z"
        fill="currentColor"
      />
      <path
        d="M43.0506 7.03694C42.6391 7.03694 42.2507 7.16167 41.8855 7.40654C41.5202 7.656 41.2891 8.03027 41.1828 8.53387H44.9138C44.8397 8.04874 44.6317 7.67454 44.2896 7.4204C43.9475 7.16167 43.536 7.03694 43.0506 7.03694ZM43.1754 12.9507C42.1721 12.9507 41.3261 12.6042 40.6279 11.9111C39.9297 11.2181 39.583 10.3588 39.583 9.33314C39.583 8.30747 39.9252 7.44347 40.6048 6.75514C41.2845 6.06671 42.1352 5.71558 43.1431 5.71558C44.1509 5.71558 45.034 6.08056 45.6674 6.81054C46.3007 7.54054 46.5921 8.48767 46.5365 9.65654H41.1226C41.1781 10.2156 41.4047 10.6683 41.8023 11.0102C42.1999 11.3521 42.6575 11.5231 43.1707 11.5231C43.9475 11.5231 44.5623 11.2366 45.0201 10.6683L46.1297 11.6201C45.4269 12.5071 44.4421 12.9507 43.1707 12.9507H43.1754Z"
        fill="currentColor"
      />
      <path
        d="M47.5212 12.8397V2.88794H49.0655V9.02811L51.8718 5.83558H53.7628L50.8316 9.13897L54.1142 12.8397H52.1631L49.0655 9.33764V12.8397H47.5212Z"
        fill="currentColor"
      />
      <path
        d="M57.0872 7.03694C56.6756 7.03694 56.2872 7.16167 55.922 7.40654C55.5568 7.656 55.3256 8.03027 55.2193 8.53387H58.9503C58.8764 8.04874 58.6682 7.67447 58.3262 7.4204C57.984 7.16167 57.5726 7.03694 57.0825 7.03694H57.0872ZM57.2119 12.9507C56.2086 12.9507 55.3626 12.6042 54.6645 11.9111C53.9663 11.2181 53.6196 10.3588 53.6196 9.33314C53.6196 8.30747 53.9618 7.44347 54.6413 6.75507C55.321 6.06208 56.1717 5.71558 57.1796 5.71558C58.1875 5.71558 59.0706 6.08056 59.7039 6.81054C60.3373 7.54054 60.6286 8.48767 60.5732 9.65654H55.1591C55.2146 10.2156 55.4412 10.6683 55.8388 11.0102C56.2364 11.3521 56.6941 11.5231 57.2074 11.5231C57.984 11.5231 58.599 11.2366 59.0566 10.6683L60.1662 11.6201C59.4635 12.5071 58.4788 12.9507 57.2074 12.9507H57.2119Z"
        fill="currentColor"
      />
      <path
        d="M66.0815 11.3466H68.2822C68.8048 11.3466 69.2115 11.2173 69.4935 10.954C69.7802 10.6952 69.9235 10.3487 69.9235 9.91444C69.9235 9.48011 69.7802 9.13824 69.4935 8.87031C69.2068 8.60231 68.8048 8.47297 68.2822 8.47297H66.0815V11.3466ZM66.0815 7.08691H67.8202C68.2588 7.08691 68.6108 6.96217 68.8742 6.70804C69.1328 6.45397 69.2668 6.13518 69.2668 5.74246C69.2668 5.34975 69.1375 5.04945 68.8742 4.79073C68.6155 4.53199 68.2588 4.39801 67.8202 4.39801H66.0815V7.09151V7.08691ZM64.4541 12.8482V2.89185H67.9308C68.8095 2.89185 69.5215 3.15519 70.0622 3.67727C70.6028 4.19934 70.8762 4.85078 70.8762 5.62697C70.8762 6.44934 70.5708 7.09617 69.9655 7.56277C71.0195 8.02017 71.5508 8.82871 71.5508 9.98837C71.5508 10.8477 71.2455 11.5407 70.6308 12.0628C70.0208 12.5848 69.2302 12.8482 68.2682 12.8482H64.4541Z"
        fill="currentColor"
      />
      <path
        d="M75.8274 11.5275C76.6134 11.5275 77.2193 11.2179 77.6493 10.6035V8.09473C77.2287 7.4664 76.6227 7.15687 75.8274 7.15687C75.222 7.15687 74.718 7.36473 74.3247 7.7806C73.932 8.1964 73.738 8.71847 73.738 9.3422C73.738 9.96587 73.9367 10.488 74.3247 10.9038C74.718 11.3196 75.2174 11.5275 75.8274 11.5275ZM77.6493 12.8489V12.2621C77.1267 12.7287 76.4654 12.9643 75.67 12.9643C74.6627 12.9643 73.8213 12.6132 73.16 11.9109C72.494 11.2087 72.166 10.354 72.166 9.3468C72.166 8.3396 72.4987 7.48487 73.16 6.7826C73.8253 6.08038 74.6627 5.72925 75.67 5.72925C76.456 5.72925 77.1127 5.9695 77.6493 6.44537V5.84476H79.1933V12.8535H77.6493V12.8489Z"
        fill="currentColor"
      />
      <path
        d="M80.668 12.8435V5.83477H82.212V6.49544C82.67 5.98261 83.3313 5.72388 84.1906 5.72388C85.0506 5.72388 85.74 5.99646 86.2533 6.54164C86.766 7.08684 87.0253 7.81217 87.0253 8.7085V12.8435H85.4806V8.88864C85.4806 8.36657 85.342 7.94617 85.0693 7.62737C84.792 7.30857 84.3993 7.1515 83.886 7.1515C83.0813 7.1515 82.5266 7.53497 82.2166 8.3019V12.8435H80.6726H80.668Z"
        fill="currentColor"
      />
      <path
        d="M88.4075 12.8436V2.89185H89.9521V9.03197L92.7581 5.83487H94.6488L91.7181 9.14291L95.0001 12.8436H93.0495L89.9521 9.34157V12.8436H88.4075Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

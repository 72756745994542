import React, { ReactNode } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { DefaultTheme } from 'styled-components'

import { IconProps } from '../index'

export type SportIconSize = 'm' | 's'

export interface ISportIconProps extends Omit<IconProps, 'size'> {
  sport?: ESportsCodes | EAdditionalSportsCodes
  size?: SportIconSize
  numericSize?: number
}

export interface IIconsSportNameProps extends ISportIconProps {
  backgroundColor?: string
  size: SportIconSize
}

export interface ISportIconWrapperProps extends IIconsSportNameProps {
  children: (arg1: { theme: DefaultTheme; size: SportIconSize }) => ReactNode
  withBackground?: boolean
  numericSize?: number
}

export type IconSizeMap = Record<
  SportIconSize,
  React.FC<{ theme: DefaultTheme }>
>

export enum EAdditionalSportsCodes {
  CULTURE = 'Culture',
  POLITICS = 'Politics',
  ANIMALS = 'Animals',
  OLYMPICS = 'Olympics',
  WINDSURF = 'Windsurf',
  POPMMA = 'POPMMA',
  BINGO = 'Bingo',
  SNOOKER = 'Snooker',
  RUNNING = 'Running',
  ALL_SPORTS = 'Все виды спорта',
  HURLING = 'Hurling',
  FIST_FIGHT = 'Fist_fighting',
  SUMO = 'Sumo'
}

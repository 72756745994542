import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { RadioProps } from './types'

export const StyledRadioWrapper = styled.label<RadioProps>`
  align-items: center;
  cursor: pointer;
  display: flex;

  ${({ theme, checked }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-default']};
    }

    ${checked &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-hover']};
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
      }
    `}
  `}
`

export const StyledRadio = styled.div<RadioProps>`
  height: 16px;
  padding: 4px;
  width: 16px;

  ${({ theme, checked }) => css`
    align-items: center;
    background-color: ${checked
      ? theme.tokens.colors.surface['surface-active2']
      : theme.tokens.colors.surface['surface-default-opacity']};
    border: 1px solid ${theme.tokens.colors.border['border-primary']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-round'
    ]}px;
    display: flex;
    justify-content: center;
  `}
`

export const StyledRing = styled.div<RadioProps>`
  height: 6px;
  width: 6px;

  ${({ theme, checked }) => css`
    background-color: ${checked
      ? theme.tokens.colors.icon['icon-invert']
      : theme.tokens.colors.surface['surface-default-opacity']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-round'
    ]}px;
    display: ${checked ? 'block' : 'none'};
  `}
`

export const StyledRadioInput = styled.input`
  display: none;
`

export const StyledRadioLabel = styled.div<RadioProps>`
  ${typography['Body / 14 Medium']}
  ${({ theme, checked }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-none']}px;

    ${checked &&
    css`
      color: ${theme.tokens.colors.text['text-primary']};
    `}
  `}
`

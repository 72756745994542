import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M12.181 8.09695L13.8718 8.29546C13.448 7.18505 12.2093 6.6186 11.0911 7.03228C9.96675 7.44872 9.39288 8.69791 9.80851 9.8219C10.2249 10.9467 11.4741 11.5205 12.5985 11.1045C13.2607 10.8594 13.7236 10.3228 13.9148 9.69589L11.9999 9.48692L11.9995 9.48633C11.9848 9.46318 11.5376 8.76131 12.181 8.09695ZM3.68064 15.1526H20.8648C22.3451 15.1526 23.4873 13.9065 22.7902 12.6004C22.2718 11.6306 21.5623 10.8529 20.8772 10.2589C19.7579 9.2896 17.4464 8.78599 15.9665 8.78599H14.6168V9.93091C14.6168 10.9998 13.7472 11.8694 12.6783 11.8694H6.281C5.21207 11.8694 4.34246 10.9998 4.34246 9.93091V8.78599H3.68064C2.20034 8.78599 1 9.98637 1 11.4666V12.4719C1 13.9522 2.20034 15.1526 3.68064 15.1526ZM14.9082 15.9403H20.7238C21.0449 15.9403 21.3054 16.2004 21.3054 16.5219C21.3054 16.8433 21.0449 17.1034 20.7238 17.1034H14.9081C14.5871 17.1034 14.3266 16.8433 14.3266 16.5219C14.3266 16.2004 14.5871 15.9403 14.9082 15.9403ZM3.85711 17.1034C3.53608 17.1034 3.27553 16.8433 3.27553 16.5219C3.27553 16.2004 3.53608 15.9403 3.85711 15.9403H9.67282C9.99385 15.9403 10.2544 16.2004 10.2544 16.5219C10.2544 16.8433 9.99385 17.1034 9.67282 17.1034H3.85711ZM9.10221 8.29546L7.4114 8.09695C6.76647 8.7629 7.21731 9.46653 7.23009 9.48648C7.23027 9.48677 7.23036 9.48691 7.23036 9.48692L9.14526 9.69589C8.95409 10.3228 8.49119 10.8594 7.82897 11.1045C6.70457 11.5205 5.45538 10.9467 5.03898 9.8219C4.62335 8.69791 5.19719 7.44872 6.32154 7.03228C7.4397 6.6186 8.67843 7.18505 9.10221 8.29546Z"
      fill={theme.tokens.colors['sport-icon']['sport-bobsled']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M9.36129 5.30581C9.05528 4.49414 8.15219 4.08627 7.33896 4.38713C6.52127 4.69 6.10391 5.5985 6.40619 6.41594C6.70905 7.23395 7.61756 7.65126 8.43527 7.34872C8.91689 7.1705 9.23325 6.84816 9.39258 6.3243C9.50837 5.94358 9.50167 5.67816 9.36129 5.30581ZM1.94951 10.2925H14.4471C15.5237 10.2925 16.3544 9.38628 15.8474 8.43637C15.4704 7.73112 14.9544 7.1655 14.4561 6.73351C13.6421 6.02855 11.9609 5.66229 10.8847 5.66229H9.90311V6.49496C9.90311 7.27236 9.27066 7.9048 8.49326 7.9048H3.84068C3.06328 7.9048 2.43083 7.27236 2.43083 6.49496V5.66229H1.94951C0.872928 5.66229 -4.48227e-05 6.53529 -4.48227e-05 7.61185V8.34299C-4.48227e-05 9.41957 0.872928 10.2925 1.94951 10.2925ZM10.1149 10.8654H14.3445C14.578 10.8654 14.7675 11.0546 14.7675 11.2884C14.7675 11.5222 14.578 11.7113 14.3445 11.7113H10.1149C9.88144 11.7113 9.69195 11.5222 9.69195 11.2884C9.69195 11.0546 9.88147 10.8654 10.1149 10.8654ZM2.07786 11.7113C1.84437 11.7113 1.65489 11.5222 1.65489 11.2884C1.65489 11.0546 1.84437 10.8654 2.07786 10.8654H6.30746C6.54094 10.8654 6.73042 11.0546 6.73042 11.2884C6.73042 11.5222 6.54094 11.7113 6.30746 11.7113H2.07786ZM3.87021 4.38713C4.68341 4.08627 5.58648 4.49416 5.89251 5.30581C6.0329 5.67815 6.03961 5.94358 5.92383 6.3243C5.76451 6.84816 5.44814 7.1705 4.96652 7.34872C4.14878 7.65126 3.24028 7.23395 2.93744 6.41594C2.63517 5.5985 3.0525 4.69 3.87021 4.38713Z"
      fill={theme.tokens.colors['sport-icon']['sport-bobsled']}
      fillRule="evenodd"
    />
  )
}

export const IconSportBobsled: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconCheckOutlineBig16: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13.6507 5.48031L6.33325 12.0381L2.34912 8.62316L3.6507 7.10465L6.33325 9.40397L12.3491 3.96179L13.6507 5.48031Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { IframeHTMLAttributes } from 'react'

export const IFRAME_SETTINGS: {
  allow: IframeHTMLAttributes<HTMLIFrameElement>['allow']
  sandbox: IframeHTMLAttributes<HTMLIFrameElement>['sandbox']
} = {
  allow: '',
  sandbox:
    'allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation  allow-top-navigation-by-user-activation  allow-top-navigation-to-custom-protocols'
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M7.57389 8.8166C5.09554 8.72561 2.12719 8.30902 1.98866 7.53109C1.87354 6.8846 2.9696 6.88316 4.05132 6.88174C4.34356 6.88136 4.63475 6.88098 4.90073 6.86788C6.33198 6.7975 7.78143 6.46531 9.37094 5.84351C13.1885 4.34998 18.6769 3.98749 18.5553 5.23699C18.5213 5.58524 18.1696 5.75091 17.2149 5.86794C16.1267 6.00141 14.8167 6.39373 13.8954 6.86198L13.2484 7.19097C13.2484 7.19097 13.4587 8.00797 13.4942 8.19668C14.169 11.7907 14.5541 12.7077 16.4029 16.5532C16.5927 16.9479 16.7263 17.2636 16.8141 17.5171L17.8254 17.3297C21.1693 16.5836 22.9379 16.6999 22.5745 18.0151C22.2332 19.2502 20.9951 19.5184 13.5727 20.8629C7.48268 21.966 5.44314 22.3311 4.67732 21.5567C3.80923 20.679 4.69904 19.7852 7.94492 19.1607L9.6247 18.8494C9.2199 18.1314 8.9427 17.1672 9.04901 16.3553C9.11055 15.8853 9.24952 15.4143 9.39467 14.9225C9.80261 13.5401 10.2593 11.9926 9.18244 9.83658L8.74774 8.85967L7.57389 8.8166ZM11.0848 18.5788L15.197 17.8168C15.0596 17.6684 14.9157 17.5033 14.7638 17.3246C13.8305 16.2265 13.0917 15.1387 12.7058 14.5706C12.5716 14.373 12.4801 14.2383 12.438 14.1879C12.369 14.1053 12.2877 14.1675 12.091 14.453C12.091 14.453 11.6042 15.3476 11.05 16.8075C10.9077 17.1824 10.9861 17.7994 11.0604 18.3842C11.0687 18.4496 11.0769 18.5146 11.0848 18.5788ZM7.25782 4.56297C6.87365 4.10323 6.81024 3.79838 6.99048 3.27889C7.50347 1.80061 10.1659 1.61181 10.7944 3.00922C11.5769 4.74885 8.53129 6.08698 7.25782 4.56297Z"
      fill={theme.tokens.colors['sport-icon']['sport-snowboarding']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M4.33598 5.52169C2.41486 5.45115 0.113908 5.12823 0.00652458 4.5252C-0.0827138 4.02408 0.766908 4.02296 1.60542 4.02186C1.83195 4.02156 2.05767 4.02126 2.26385 4.01111C3.3733 3.95656 4.49685 3.69906 5.72898 3.21706C8.68819 2.05934 12.9426 1.77835 12.8483 2.74691C12.822 3.01686 12.5494 3.14528 11.8093 3.236C10.9658 3.33946 9.95029 3.64357 9.23618 4.00654L8.73462 4.26156C8.73462 4.26156 8.89768 4.89487 8.92515 5.04115C9.44826 7.82709 9.74679 8.53791 11.1799 11.5188C11.327 11.8248 11.4306 12.0696 11.4986 12.2661L12.2824 12.1208C14.8745 11.5425 16.2454 11.6327 15.9637 12.6522C15.6992 13.6095 14.7395 13.8174 8.98588 14.8596C4.26517 15.7147 2.6842 15.9977 2.09057 15.3975C1.41766 14.7171 2.10741 14.0243 4.62348 13.5401L5.92574 13.2988C5.61192 12.7423 5.39702 11.9948 5.47943 11.3654C5.52713 11.001 5.63486 10.636 5.74737 10.2547C6.06359 9.18314 6.41758 7.98356 5.58286 6.31233L5.24589 5.55507L4.33598 5.52169ZM7.05753 13.0891L10.2452 12.4984C10.1387 12.3834 10.0271 12.2553 9.90932 12.1168C9.18585 11.2655 8.61315 10.4223 8.31405 9.98197C8.21002 9.8288 8.13909 9.72437 8.10642 9.68528C8.05296 9.62129 7.98991 9.66947 7.83746 9.89082C7.83746 9.89082 7.46011 10.5842 7.03049 11.7159C6.92018 12.0065 6.98096 12.4848 7.03856 12.9381C7.04501 12.9889 7.05141 13.0393 7.05753 13.0891ZM4.09097 2.22444C3.79318 1.86807 3.74402 1.63176 3.88374 1.22907C4.28139 0.0831661 6.34518 -0.0631841 6.83241 1.02003C7.43894 2.36852 5.07812 3.40579 4.09097 2.22444Z"
      fill={theme.tokens.colors['sport-icon']['sport-snowboarding']}
      fillRule="evenodd"
    />
  )
}

export const IconSportSnowboarding: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { css } from 'styled-components'

import { combineMedias } from 'shared/lib/styled'
import { RIGHT_CONTAINER_WIDTH } from 'entities/basket/lib'

const WIDE_FILTER_MIN_HEIGHT = '68px'

export const threeColumnLayout = (
  leftGridArea: string,
  hideHeader: boolean,
  leftPartWidth = 240
) => css`
  display: grid;
  ${hideHeader
    ? css`
        grid-template-areas: '${leftGridArea} content right-container';
        grid-template-rows: minmax(0, 1fr);
      `
    : css`
        grid-template-areas:
          '. content .'
          '${leftGridArea} content right-container';
        grid-template-rows: 48px minmax(0, 1fr);
      `}

  grid-template-columns: ${leftPartWidth}px 1fr ${RIGHT_CONTAINER_WIDTH}px;
  height: 100%;

  ${combineMedias(['tabletOrMobile', 'small'])} {
    ${hideHeader
      ? css`
          grid-template-areas: '${leftGridArea} content';
          grid-template-rows: minmax(0, 1fr);
        `
      : css`
          grid-template-areas:
            '. content'
            '${leftGridArea} content';
          grid-template-rows: 48px minmax(0, 1fr);
        `}

    grid-template-columns: 240px 1fr;
  }
`

export const threeColumnWideFilterContainerLayout = (
  leftGridArea: string,
  hideHeader: boolean,
  leftPartWidth = 240
) => css`
  display: grid;

  ${hideHeader
    ? css`
        grid-template-areas:
          'filter-container filter-container right-container'
          '${leftGridArea} content right-container';
        grid-template-rows: minmax(${WIDE_FILTER_MIN_HEIGHT}, auto) minmax(
            0,
            1fr
          );
      `
    : css`
        grid-template-areas:
          '. . .'
          'filter-container filter-container right-container'
          '${leftGridArea} content right-container';
        grid-template-rows: 48px minmax(${WIDE_FILTER_MIN_HEIGHT}, auto) minmax(
            0,
            1fr
          );
      `}

  grid-template-columns: ${leftPartWidth}px 1fr ${RIGHT_CONTAINER_WIDTH}px;
  height: 100%;

  ${combineMedias(['tabletOrMobile', 'small'])} {
    ${hideHeader
      ? css`
          grid-template-areas:
            'filter-container filter-container'
            '${leftGridArea} content';
          grid-template-rows: minmax(${WIDE_FILTER_MIN_HEIGHT}, auto) minmax(
              0,
              1fr
            );
        `
      : css`
          grid-template-areas:
            '. . '
            'filter-container filter-container'
            '${leftGridArea} content';
          grid-template-rows: 48px minmax(${WIDE_FILTER_MIN_HEIGHT}, auto) minmax(
              0,
              1fr
            );
        `}

    grid-template-columns: 240px 1fr;
  }
`

export const twoColumnLayout = (
  leftGridArea: string,
  hideHeader: boolean,
  leftPartWidth = 280
) => css`
  display: grid;
  ${hideHeader
    ? css`
        grid-template-areas: '${leftGridArea} content';
        grid-template-rows: minmax(0, 1fr);
      `
    : css`
        grid-template-areas:
          '. content'
          '${leftGridArea} content';
        grid-template-rows: 48px minmax(0, 1fr);
      `}

  grid-template-columns: ${leftPartWidth}px 1fr;
  height: 100%;
`

export const oneColumnLayout = (hideHeader: boolean) => css`
  display: grid;
  ${hideHeader
    ? css`
        grid-template-areas: 'content';
        grid-template-rows: minmax(0, 1fr);
      `
    : css`
        grid-template-areas:
          'content'
          'content';
        grid-template-rows: 48px minmax(0, 1fr);
      `}

  grid-template-columns: 1fr;
  height: 100%;
`

export enum EventsPageHeaderTypes {
  Live,
  Line,
  LineTournaments,
  LiveTournaments
}

export interface EventsPageParams {
  sportId: string
}

export interface EventsPageHeaderProps {
  type: EventsPageHeaderTypes
}

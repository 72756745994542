import { FC } from 'react'

import { BasketCardDependentList } from 'entities/basket'

import { CardDependentGroup } from './card-dependent-group'
import { CardDependentListProps } from './card-dependent.types'

export const CardDependentList: FC<CardDependentListProps> = ({
  dependentEvents
}) =>
  dependentEvents?.length ? (
    <BasketCardDependentList>
      {dependentEvents?.map(({ eventId, outcomes }) => (
        <CardDependentGroup key={eventId} outcomes={outcomes} />
      ))}
    </BasketCardDependentList>
  ) : null

import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

export enum ESportColumn {
  G = 'G first letter',
  P = 'P first letter',
  S = 'S first letter',
  T = 'T first letter'
}

export const sportColumnName = {
  [ESportsCodes.TENNIS]: ESportColumn.S,
  [ESportsCodes.TABLE_TENNIS]: ESportColumn.S,
  [ESportsCodes.BADMINTON]: ESportColumn.G,
  [ESportsCodes.VOLLEYBALL]: ESportColumn.P,
  [ESportsCodes.SQUASH]: ESportColumn.P,
  [ESportsCodes.TEQBOL]: ESportColumn.P,
  [ESportsCodes.SEPAK_TAKRAW]: ESportColumn.P,
  [ESportsCodes.CYBER_TENNIS]: ESportColumn.P
}

import styled, { css } from 'styled-components'

export const GlobalSearchBodyWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  left: 0;
  max-height: 440px;
  position: absolute;
  top: calc(100% + 4px);
  width: 520px;
  z-index: 10;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    box-shadow: ${theme.tokens.shadows.s};
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;
  `}
`

export const OverlaySearchWrapper = styled.div`
  position: relative;
`

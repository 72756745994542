import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconCircleMinus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.4964 3C15.2868 3 16.0372 3.31 16.6076 3.88L20.1095 7.39C20.6798 7.95 21 8.71 21 9.51V14.48C21 15.28 20.6798 16.03 20.1095 16.6L16.5976 20.11C16.0372 20.68 15.2768 21 14.4764 21H9.51362C8.71317 21 7.96276 20.68 7.39244 20.11L3.88049 16.6C3.31017 16.04 3 15.28 3 14.48V9.52C3 8.72 3.31017 7.97 3.88049 7.4L7.41245 3.88C7.96276 3.31 8.73318 3 9.52362 3H14.4964ZM8 12C8 11.4477 8.44772 11 9 11L15 11C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13L9 13C8.44772 13 8 12.5523 8 12Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

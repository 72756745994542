import { createSelector } from '@reduxjs/toolkit'
import {
  selectDisabledButtonExpressSystem,
  selectDisabledButtonOrdinar,
  selectExpressOutcomeValue,
  selectMakeBetErrors,
  selectOrdinarTotalAmount,
  selectSystemAmount
} from 'astra-core/containers/BasketProvider'
import { EAllBasketErrors } from 'astra-core/containers/BasketProvider/constants'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getLayoutSubstate = (state: RootState) =>
  (state.rightBasketContainerState || initialState).layout

export const selectIsShowBasketOnSmallWindow = (state: RootState) =>
  getLayoutSubstate(state).isShowBasketOnSmallWindow

export const selectIsBasketLoaded = (state: RootState) =>
  getLayoutSubstate(state).isBasketLoaded

export const getBasketOrdinarOutcomeErrorMessage = (
  state: RootState,
  outcomeId: string
) => state.basketProvider.ordinar.outcomeErrors[outcomeId]?.message

export const getBasketOrdinarOutcomeAmountValue = (
  state: RootState,
  outcomeId: string
) => state.basketProvider.ordinar.outcomesAmounts[outcomeId]

export const selectBasketErrorByCode = createSelector(
  [
    selectMakeBetErrors,
    (_, basketErrorType: EAllBasketErrors) => basketErrorType
  ],
  (errors, basketErrorType) =>
    Object.values(errors)
      .flat()
      .find((err) => err?.code === basketErrorType)
)

export const selectBasketErrorByMessage = createSelector(
  [selectMakeBetErrors, (_, message: string) => message],
  (errors, message) =>
    Object.values(errors)
      .flat()
      .find((err) => err?.message === message)
)

// Rendered every time. Find out on astra-core
export const selectBasketOrdinarIsDisabledBetButton = createSelector(
  [selectDisabledButtonOrdinar, selectOrdinarTotalAmount],
  (disabledButton, value) => disabledButton || !value
)

// Rendered every time. Find out on astra-core
export const selectBasketExpressIsDisabledBetButton = createSelector(
  [selectDisabledButtonExpressSystem, selectExpressOutcomeValue],
  (disabledButton, value) => disabledButton || !+value
)

// Rendered every time. Find out on astra-core
export const selectBasketSystemIsDisabledBetButton = createSelector(
  [selectDisabledButtonExpressSystem, selectSystemAmount],
  (disabledButton, value) => disabledButton || !value
)

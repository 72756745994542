import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { ERoutes } from 'shared/types/routes'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { AnimationNotificationPanel } from './AnimationNotificationPanel'
import { NotificationsList } from './components/NotificationsList'
import {
  StyledLastTitle,
  StyledModalTitle,
  StyledNotifications,
  StyledNotificationsHeader
} from './NotificationsPanel.styled'

export const NotificationsPanel = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const routeRedirect = useRouteRedirect()

  useEffect(() => {
    return () => {
      dispatch(layoutContainerActions.toggleIsDisplayNotificationPanel(false))
    }
  }, [dispatch])

  const onClose = useCallback(() => {
    dispatch(layoutContainerActions.toggleIsDisplayNotificationPanel(false))
  }, [dispatch])

  const handleClickAllNotifications = useCallback(
    () => routeRedirect({ route: ERoutes.Notifications }),
    [routeRedirect]
  )

  return (
    <AnimationNotificationPanel>
      <StyledNotifications>
        <StyledNotificationsHeader>
          <StyledModalTitle>{t('notifications')}</StyledModalTitle>
          <StyledLastTitle onClick={handleClickAllNotifications}>
            {t('all notifications')}
          </StyledLastTitle>
          <IconCloseOutline16 size={10} onClick={onClose} />
        </StyledNotificationsHeader>

        <NotificationsList />
      </StyledNotifications>
    </AnimationNotificationPanel>
  )
}

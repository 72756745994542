import colorTokens from './color-tokens.json'
import buttonTokens from './button-tokens.json'
import aliasTokens from './alias-tokens.json'
import adaptiveTokens from './adaptive-tokens.json'
import shadowTokens from './shadows.json'

export type TThemeTokens = (typeof colorTokens)['Light'] &
  (typeof colorTokens)['Dark']
export type TButtonTokens = typeof buttonTokens
export type TAliasTokens = typeof aliasTokens
export type TAdaptiveTokens = typeof adaptiveTokens
export type TShadowTokens = (typeof shadowTokens)['Dark'] &
  (typeof shadowTokens)['Light']

export const LIGHT_THEME_TOKENS: TThemeTokens = colorTokens.Light
export const DARK_THEME_TOKENS: TThemeTokens = colorTokens.Dark
export const ALIAS_TOKENS: TAliasTokens = aliasTokens
export const BUTTON_TOKENS: TButtonTokens = buttonTokens
export const ADAPTIVE_TOKENS: TAdaptiveTokens = adaptiveTokens
export const LIGHT_THEME_SHADOW_TOKENS: TShadowTokens = shadowTokens.Light
export const DARK_THEME_SHADOW_TOKENS: TShadowTokens = shadowTokens.Dark

import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { OptionType } from 'astra-core/utils/line/types'
import { INITIAL_FILTER_PERIOD_ID } from 'astra-core/containers/LineProvider/slice'

import i18n from 'app/lib/i18n'

export const OPTIONS_SELECT_PERIOD = () => [
  {
    id: INITIAL_FILTER_PERIOD_ID,
    label: i18n.t('all time select'),
    value: ''
  },
  {
    id: '2',
    label: i18n.t('1 hour'),
    value: dayjs().add(1, 'hour').format()
  },
  {
    id: '3',
    label: i18n.t('2 hours'),
    value: dayjs().add(2, 'hour').format()
  },
  {
    id: '4',
    label: i18n.t('4 hours'),
    value: dayjs().add(4, 'hour').format()
  },
  {
    id: '5',
    label: i18n.t('6 hours'),
    value: dayjs().add(8, 'hour').format()
  },
  {
    id: '6',
    label: i18n.t('12 hours'),
    value: dayjs().add(12, 'hour').format()
  },
  {
    id: '7',
    label: i18n.t('1 day'),
    value: dayjs().add(1, 'day').format()
  },
  {
    id: '8',
    label: i18n.t('2 days'),
    value: dayjs().add(2, 'day').format()
  }
]

export const getScheduleTime = (
  option: OptionType
): Pick<OptionType, 'id' | 'scheduledFrom' | 'scheduledTo'> => {
  const scheduledFrom =
    option.scheduledFrom ?? (option.value ? dayjs().format() : '')
  const scheduledTo = option.scheduledTo ?? option.value

  return { scheduledFrom, scheduledTo, id: option.id }
}

import { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLogout: FC<IconProps> = (props) => {
  const theme = useTheme()

  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9434 25.074C20.9434 22.5481 20.9776 20.0573 20.9786 17.5314C20.9786 17.1832 20.9585 16.838 20.9118 16.4928C20.6188 14.3369 18.8714 12.6326 16.9026 12.5892C14.5549 12.5376 12.652 14.5084 12.6109 17.0571C12.5899 18.3841 12.5956 19.7121 12.6081 21.0392C12.6214 22.4561 12.5164 23.8782 12.6873 25.2879C13.341 30.658 15.9397 34.5119 20.4384 36.8683C20.5062 36.9034 20.5749 36.9871 20.6713 36.9086C20.2189 36.0632 19.9326 35.1527 19.8735 34.1781C19.8515 33.8174 19.7236 33.6179 19.464 33.4081C16.6268 31.1148 15.0741 28.0535 14.9118 24.2172C14.8422 22.5584 14.8927 20.8924 14.8918 19.2305C14.8918 18.4699 14.8651 17.7082 14.909 16.9506C14.9586 16.0856 15.4081 15.4965 16.1534 15.1906C16.8721 14.896 17.6241 15.1017 18.1375 15.7166C18.5317 16.1879 18.6948 16.7511 18.6948 17.3806C18.6929 19.9447 18.6948 22.5098 18.6929 25.074C18.6929 25.4863 18.7025 25.8977 18.7645 26.3059C18.9229 27.3497 19.2942 28.2882 19.9403 29.1832C20.0891 28.3553 20.3029 27.6422 20.6894 27.018C21.0503 26.4331 20.9434 25.729 20.9434 25.074Z"
          fill={theme.tokens.colors.icon['icon-secondary']}
        />
        <path
          d="M10.8154 6.3378C14.8993 3.94616 19.0901 4.02273 23.2669 6.1953C23.9953 6.57388 24.7572 6.40267 25.1235 5.76461C25.491 5.12443 25.251 4.36409 24.5367 3.977C20.9575 2.03519 17.1471 1.4333 13.2296 2.57754C6.96607 4.40875 3.39769 8.69753 2.19599 14.9568C1.90204 16.4913 2.0328 18.0503 2.00903 19.4072C2.00903 20.6876 2.01119 21.7755 2.00795 22.8623C2.00795 23.175 2.05982 23.4653 2.26299 23.7194C2.62285 24.1714 3.16427 24.3437 3.70028 24.1671C4.25034 23.9863 4.59076 23.5195 4.594 22.8857C4.60264 21.4405 4.594 19.9953 4.59724 18.5501C4.5994 17.4378 4.57022 16.3244 4.77123 15.2195C5.46826 11.388 7.40699 8.3349 10.8154 6.33886V6.3378Z"
          fill={theme.tokens.colors.icon['icon-secondary']}
        />
        <path
          d="M23.7791 21.0578C24.0894 20.4962 24.3942 19.943 24.7904 19.4417C25.1983 18.9239 25.6685 18.4653 26.2246 18.095C26.3427 18.016 26.3512 17.9151 26.3523 17.7945C26.3577 17.0426 26.3298 16.2949 26.2053 15.5483C25.326 10.2384 20.0014 5.9716 13.759 7.67396C11.642 8.25112 9.93191 9.42207 8.61686 11.1234C8.04576 11.8617 8.44832 12.8736 9.37476 13.0701C9.98559 13.1991 10.4139 12.9245 10.7864 12.4732C12.7306 10.1147 15.9511 9.19537 18.8195 10.1656C21.7362 11.1525 23.7576 14.004 23.778 17.1716C23.7866 18.4673 23.7791 19.762 23.7791 21.0578Z"
          fill={theme.tokens.colors.icon['icon-secondary']}
        />
        <path
          d="M14.0056 34.5646C13.5029 34.2479 13.0199 33.905 12.5883 33.4932C10.7044 31.6911 9.76815 29.4783 9.77433 26.8473C9.78154 23.654 9.77536 20.4607 9.77639 17.2673C9.77639 17.0186 9.76918 16.7729 9.67236 16.5388C9.44061 15.9827 8.88338 15.6785 8.29525 15.7862C7.73081 15.8886 7.31778 16.3841 7.31469 17.0144C7.30542 18.8259 7.3116 20.6373 7.3116 22.4498H7.31469C7.31469 24.0658 7.26216 25.6839 7.32499 27.2978C7.48258 31.388 9.31804 34.5144 12.7201 36.6969C13.403 37.1349 14.1549 36.9666 14.5288 36.3301C14.8924 35.7112 14.6854 34.9921 14.0056 34.5646Z"
          fill={theme.tokens.colors.icon['icon-secondary']}
        />
        <path
          d="M29.1726 16.587C29.1746 16.7608 29.2066 16.8334 29.3859 16.7971C30.1269 16.6486 30.8679 16.6849 31.6471 16.8235C31.6388 13.9823 31.005 11.3776 29.6879 8.97198C29.3488 8.35271 28.6047 8.18111 28.0337 8.5342C27.4483 8.89609 27.2309 9.66056 27.5782 10.3161C28.6088 12.2642 29.1499 14.3486 29.1726 16.5848V16.587Z"
          fill={theme.tokens.colors.icon['icon-secondary']}
        />
        <path
          d="M37.5386 27.933L36.3213 26.6009C36.1053 26.3915 35.8894 26.2583 35.6243 26.2012V23.8129C35.6145 21.1201 33.3664 18.9412 30.5882 18.9412C27.8101 18.9412 25.5522 21.1201 25.5522 23.8129V26.2012C25.2871 26.2678 25.0711 26.3915 24.8552 26.6009L23.6379 27.933C23.3335 28.228 23.1765 28.6752 23.1765 28.9701V35.1835C23.1765 35.6307 23.3335 35.9257 23.6379 36.2207L24.8552 37.5528C25.1595 37.8478 25.4638 38 25.9252 38H35.2513C35.7127 38 36.017 37.8478 36.3213 37.5528L37.5386 36.2207C37.8429 35.9257 38 35.4785 38 35.1835V28.9701C38 28.5229 37.8429 28.228 37.5386 27.933ZM30.5882 20.8442C32.2767 20.8442 33.6511 22.1763 33.6511 23.8129V26.1537H27.5155V23.8129C27.5155 22.1763 28.8899 20.8442 30.5784 20.8442H30.5882Z"
          fill={theme.tokens.colors.icon['icon-primary-2']}
        />
      </svg>
    </Icon>
  )
}

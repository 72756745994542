import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconMastercard: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 26 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.56 1.7124H9.56323V14.2844H16.56V1.7124Z" fill="#FF5F00" />
      <path
        d="M10.0074 7.99815C10.0074 5.55483 11.1402 3.22258 13.0504 1.71217C9.58538 -1.0199 4.54327 -0.420178 1.81121 3.0671C-0.898652 6.53216 -0.298929 11.5521 3.18835 14.2841C6.09811 16.572 10.1629 16.572 13.0727 14.2841C11.1402 12.7737 10.0074 10.4415 10.0074 7.99815Z"
        fill="#EB001B"
      />
      <path
        d="M26.0001 7.99815C26.0001 12.4183 22.424 15.9944 18.0038 15.9944C16.2046 15.9944 14.4721 15.3947 13.0728 14.2841C16.5378 11.5521 17.1375 6.53216 14.4055 3.04488C14.0057 2.55622 13.5614 2.08977 13.0728 1.71217C16.5378 -1.0199 21.5799 -0.420178 24.2898 3.0671C25.4004 4.46645 26.0001 6.19898 26.0001 7.99815Z"
        fill="#F79E1B"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconVisa: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 37 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7856 0.597986L9.07246 11.4306H5.99748L3.67811 2.78568C3.5373 2.25321 3.41486 2.05813 2.98662 1.8338C2.28743 1.4684 1.13281 1.12558 0.116943 0.91281L0.185943 0.597986H5.13573C5.76666 0.597986 6.33385 1.00258 6.4771 1.70249L7.70203 7.97079L10.7294 0.597806L13.7856 0.597986ZM25.8339 7.89385C25.8463 5.03478 21.73 4.87728 21.7583 3.6001C21.7671 3.2114 22.1513 2.79814 22.9922 2.69266C23.409 2.6401 24.5574 2.6 25.86 3.17745L26.3709 0.880297C25.671 0.635555 24.7704 0.400024 23.6499 0.400024C20.7746 0.400024 18.7512 1.87245 18.7341 3.98085C18.7155 5.54034 20.1784 6.41058 21.2805 6.92878C22.4143 7.45945 22.7948 7.80046 22.7904 8.27514C22.7824 9.00178 21.8861 9.32256 21.0486 9.33521C19.5865 9.35688 18.738 8.95409 18.0617 8.65119L17.5345 11.0244C18.2142 11.3248 19.4687 11.5868 20.7694 11.6C23.8254 11.6 25.8246 10.1458 25.8339 7.89385ZM33.4265 11.4306H36.1169L33.7685 0.597986H31.2853C30.7269 0.597986 30.2559 0.911185 30.0474 1.39272L25.6822 11.4306H28.7368L29.3432 9.81259H33.0753L33.4265 11.4306ZM30.1807 7.59239L31.7118 3.52514L32.5931 7.59239H30.1807ZM17.9419 0.597986L15.5365 11.4306H12.6276L15.034 0.597986H17.9419Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

import React, { useCallback } from 'react'
import {
  SetCoefficientStatusOnChangedPayload,
  basketProviderActions,
  selectCoefficientStatusOnChanged
} from 'astra-core/containers/BasketProvider'
import { useTranslation } from 'react-i18next'
import { BetSaveAny } from 'betweb-openapi-axios'

import { Radio } from 'shared/ui/Radio'
import { selectIsDisplayModalCouponSetting } from 'containers/LayoutContainer/selectors'
import { selectIsShowBasketOnSmallWindow } from 'entities/basket/model/selectors'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { useClickOutside } from 'shared/lib/click-outside/click-outside'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledCouponSettingsHeader,
  StyledCouponSettingsWrapper,
  StyledSettingsWrapper,
  StyledSettingsDescription,
  StyledSettingsRadioWrapper
} from './coupon-setting.styled'
import { IRadioCouponSettingOptions } from './coupon-setting.types'

const radioCouponSettingOptions: IRadioCouponSettingOptions[] = [
  { label: 'never', value: BetSaveAny.Never },
  { label: 'partly', value: BetSaveAny.Partly },
  { label: 'always', value: BetSaveAny.Always }
]

export const CouponSettings = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const selectedRadioSetting = useAppSelector(selectCoefficientStatusOnChanged)
  const isShowBasketOnSmallWindow = useAppSelector(
    selectIsShowBasketOnSmallWindow
  )
  const isShowModalCouponSetting = useAppSelector(
    selectIsDisplayModalCouponSetting
  )

  const handleRadioSetting = useCallback(
    (value: BetSaveAny) => {
      const payload: SetCoefficientStatusOnChangedPayload = { status: value }
      dispatch(basketProviderActions.setCoefficientStatusOnChanged(payload))
    },
    [dispatch]
  )

  const handleModalClose = useCallback(() => {
    if (isShowModalCouponSetting) {
      dispatch(layoutContainerActions.setIsDisplayModalCouponSetting(false))
    }
  }, [dispatch, isShowModalCouponSetting])

  const modalRef = useClickOutside<HTMLDivElement>(handleModalClose)

  return isShowModalCouponSetting ? (
    <StyledCouponSettingsWrapper
      isShowBasketOnSmallWindow={isShowBasketOnSmallWindow}
      ref={modalRef}
    >
      <StyledCouponSettingsHeader>
        {t('coupon settings')}
      </StyledCouponSettingsHeader>

      <StyledSettingsWrapper>
        <StyledSettingsDescription>
          {t('accept bets with modified odds')}
        </StyledSettingsDescription>

        <StyledSettingsRadioWrapper>
          {radioCouponSettingOptions.map((option) => (
            <Radio
              checked={selectedRadioSetting === option.value}
              key={option.value}
              label={t(option.label)}
              onChange={() => handleRadioSetting(option.value)}
            />
          ))}
        </StyledSettingsRadioWrapper>
      </StyledSettingsWrapper>
    </StyledCouponSettingsWrapper>
  ) : null
}

import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { typography } from 'shared/lib/theme/typography'

export const StyledCurrentCashoutWrapper = styled.div<{
  isMakeCashoutLoading: boolean
}>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  padding: 8px 8px 8px 12px;
  position: absolute;
  right: -133px;
  top: -8px;
  width: fit-content;
  z-index: 1001;

  ${({ theme, isMakeCashoutLoading }) =>
    css`
      ${isMakeCashoutLoading
        ? css`
            background-color: ${theme.tokens.colors.surface[
              'surface-disabled'
            ]};
            border-radius: 24px;
            gap: 8px;
            height: 24px;
            padding: 0 8px;
            top: 0px;
          `
        : css`
            background-color: ${theme.tokens.colors.surface['surface-modal']};
            box-shadow: 0px 2px 4px 0px ${theme.colors.default.shadow[0]};
            outline: 1px solid ${theme.tokens.colors.border['border-primary']};
          `};
    `}
`

export const StyledCurrentCashoutText = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: max-content;

  ${typography['Body / 12 Medium']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledCurrentCashoutButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const StyledCurrentCashoutButton = styled(Button)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  min-width: 40px;
  padding: 4px 6px;

  ${typography['Hint / 10 BOLD']}

  ${({ theme }) =>
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
    `}
`

export const StyledCurrentCashoutModalContentWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const StyledMakeCashoutLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 140px;
`

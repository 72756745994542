import React, { FC } from 'react'
import { LayoutTheme } from 'betweb-openapi-axios'

import { selectTheme } from 'shared/lib/theme/select-theme'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { LogoLight } from '../lib/logo-light'
import { LogoDark } from '../lib/logo-dark'

const logosMap: Record<LayoutTheme, JSX.Element> = {
  [LayoutTheme.Light]: <LogoLight />,
  [LayoutTheme.Dark]: <LogoDark />,
  [LayoutTheme.Unknown]: <LogoLight />
}

export const Logo: FC = () => {
  const layoutTheme = useAppSelector(selectTheme)

  return <>{logosMap[layoutTheme]}</>
}

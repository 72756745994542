import dayjs, { Dayjs } from 'dayjs'

export const COUPON_DATE_FORMAT = 'D MMMM'

export const EVENT_CARD_DATE_FORMAT = 'D MMMM'
export const EVENT_CARD_TIME_FORMAT = 'HH:mm'
export const EVENT_ROW_DATE_FORMAT = 'D MMMM YYYY, HH:mm'
export const EVENT_DATE_DAY_WITH_YEAR_FORMAT = 'D MMMM, '
export const EVENT_DATE_DAY_FORMAT = 'D MMMM YYYY, '
export const EVENT_DATE_TIME_FORMAT = 'HH:mm'

export const checkIfCurrentYear = (date: string | Date | Dayjs): boolean =>
  dayjs(date).year() === dayjs().year()

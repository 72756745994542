import styled, { css } from 'styled-components'

import { IconSearch } from 'shared/ui/Icon/General/icon-search'
import { TextInput } from 'shared/ui/text-input'

export const StyledIconSearch = styled(IconSearch)`
  ${({ theme }) => css`
    margin-right: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledLineSearch = styled(TextInput)`
  .mask-placeholder,
  .input-label,
  .internal-input {
    left: 8px;
    right: 8px;
  }
`

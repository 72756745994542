import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { ROWS_SIZE } from '../table-row/table-row.styled'

export const Item = styled.th`
  ${({ theme }) => css`
    ${typography['Hint / 10 Medium']};
    background-color: ${theme.tokens.colors.surface['surface-event-title']};
    color: ${theme.tokens.colors.text['text-secondary-3']};
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;

    &:first-of-type {
      padding-left: ${theme.tokens.alias.space['space-m-16']}px;
    }

    &:last-of-type {
      padding-right: ${theme.tokens.alias.space['space-m-16']}px;
    }
  `}
`

export const TableHeaderStyled = styled.tr`
  position: sticky;
  text-align: left;
  top: 0;
  z-index: 10;

  ${Item}:nth-child(1) {
    width: ${ROWS_SIZE[0].width};
  }

  ${Item}:nth-child(2) {
    width: ${ROWS_SIZE[1].width};
  }

  ${Item}:nth-child(3) {
    width: ${ROWS_SIZE[2].width};
  }

  ${Item}:nth-child(4) {
    width: ${ROWS_SIZE[3].width};
  }

  ${Item}:nth-child(5) {
    text-align: right;
    width: ${ROWS_SIZE[4].width};
  }

  ${Item}:nth-child(6) {
    width: ${ROWS_SIZE[5].width};
  }
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        clipRule="evenodd"
        d="M1.50085 3.42746H7.1778V1.5H17.0776L16.8079 3.42746H22.5019L21.9264 7.94135L22.5019 19.2974L13.1498 18.9253V21.4033L7.1778 22.5V19.5733L1.50085 20.3096L2.11074 7.94135L1.50085 3.42746Z"
        fill="#3E0079"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.88282 21.5449V2.39887H16.0626L15.6519 6.93775H12.3732V9.8564H15.222V13.7779H12.3732V20.6705L7.88282 21.5449Z"
        fill="white"
        fillRule="evenodd"
      />
    </>
  ),
  s: () => (
    <>
      <path
        clipRule="evenodd"
        d="M1.00085 2.28497H4.78548V1H11.3853L11.2056 2.28497H15.0016L14.6179 5.29423L15.0016 12.8649L8.76679 12.6169V14.2689L4.78548 15V13.0488L1.00085 13.5397L1.40745 5.29423L1.00085 2.28497Z"
        fill="#3E0079"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.2555 14.3633V1.59925H10.7087L10.4349 4.62516H8.24911V6.57093H10.1483V9.18528H8.24911V13.7803L5.2555 14.3633Z"
        fill="white"
        fillRule="evenodd"
      />
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        clipRule="evenodd"
        d="M7.17814 3.42746H1.50122L2.11111 7.94135L1.50122 20.3096L7.17814 19.5733V22.5L13.1501 21.4033V18.9253L22.5022 19.2974L21.9267 7.94135L22.5022 3.42746H16.8082L17.0779 1.5H7.17814V3.42746ZM7.88201 2.39886V21.5449L12.3724 20.6705V13.7779H15.2211V9.85639H12.3724V6.93774H15.6511L16.0617 2.39886H7.88201Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  s: () => (
    <>
      <path
        clipRule="evenodd"
        d="M4.78634 2.284H1.00171L1.4083 5.29328L1.00171 13.5388L4.78634 13.0479V14.9991L8.76765 14.268V12.616L15.0024 12.864L14.6187 5.29328L15.0024 2.284H11.2064L11.3862 0.999023H4.78634V2.284ZM5.25559 1.59827V14.3624L8.24922 13.7794V9.18435H10.1484V6.56998H8.24922V4.62421H10.435L10.7088 1.59827H5.25559Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  )
}

export const IconCybersportFortnite: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

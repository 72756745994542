import { EAllBasketErrors } from 'astra-core/containers/BasketProvider/constants'
import { useMemo } from 'react'

import { selectBasketErrorByCode } from 'entities/basket/model'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { UseIsEventCanceledProps } from './is-event-canceled.types'

export const useIsEventCanceled = ({
  eventDate,
  isLive
}: Pick<UseIsEventCanceledProps, 'eventDate' | 'isLive'>) => {
  const errorEventCanceled = useAppSelector((state) =>
    selectBasketErrorByCode(state, EAllBasketErrors.EVENT_CANCELED)
  )

  return useMemo(() => {
    const isEventOutDate = !isLive && new Date(eventDate) < new Date()

    return isEventOutDate || errorEventCanceled
  }, [errorEventCanceled, eventDate, isLive])
}

import React, { FC, PropsWithChildren } from 'react'
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider
} from 'styled-components'

import { useSelectDefaultTheme } from './use-select-default-theme'

export const ThemeProvider: FC<PropsWithChildren<{ theme?: DefaultTheme }>> = (
  props
) => {
  const [defaultTheme] = useSelectDefaultTheme()

  return <StyledThemeProvider {...props} theme={defaultTheme} />
}

import React from 'react'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import * as S from './loader.styled'

export const Loader = () => {
  return (
    <S.LoaderContainer>
      <LoaderSpinner />
    </S.LoaderContainer>
  )
}

import React, { FC } from 'react'

import { IconStateProps } from './icon-state.types'
import { StyledIconState } from './icon-state.styled'

export const IconState: FC<IconStateProps> = ({
  size,
  isActive,
  icon: Icon,
  iconColorToken = 'icon-secondary-2',
  ...props
}) => {
  const iconProps = {
    size: size === 'l' ? 24 : size === 'm' ? 16 : 12,
    twist: props.twist
  }

  return (
    <StyledIconState isActive={isActive} size={size} {...props}>
      <Icon {...iconProps} colorToken={iconColorToken} />
    </StyledIconState>
  )
}

import {
  EBetTypes,
  basketProviderActions,
  selectBasketBetTypeTab,
  selectBasketOutcomesLength
} from 'astra-core/containers/BasketProvider'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BetTypeNavigationItem as BetTypeNavigationItemEntity } from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { BetTypeItemProps } from './bet-type-navigation-item.types'

const MIN_OUTCOMES_LENGTH = {
  [EBetTypes.Ordinar]: 0,
  [EBetTypes.Express]: 2,
  [EBetTypes.System]: 3
}

export const BetTypeNavigationItem: FC<BetTypeItemProps> = ({
  locale,
  name
}) => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)
  const outcomesLength = useAppSelector(selectBasketOutcomesLength)

  const hoverText = useMemo(
    () =>
      EBetTypes.Express === name
        ? t('express hover text')
        : EBetTypes.System === name
        ? t('system hover text')
        : '',
    [t, name]
  )

  const isAllow = useMemo(() => {
    return ((outcomesLength as number) || 0) >= MIN_OUTCOMES_LENGTH[name]
  }, [name, outcomesLength])

  const handleBetTypeTabChange = useCallback(() => {
    if (isAllow) {
      dispatch(
        basketProviderActions.handleBetTypeTabChange({ betTypeTab: name })
      )
    }
  }, [dispatch, isAllow, name])

  const isCommon = useMemo(() => {
    return !hoverText || isAllow
  }, [hoverText, isAllow])

  return (
    <BetTypeNavigationItemEntity
      isActive={name === betTypeTab}
      isDisabled={!isAllow}
      isTooltipCommon={isCommon}
      title={t(locale)}
      tooltipText={hoverText}
      onClick={handleBetTypeTabChange}
    />
  )
}

import React, { FC } from 'react'

import { StyledRadio, StyledRadioInput, StyledRing } from './RadioBonus.styled'
import { RadioBonusProps } from './RadioBonus.types'

export const RadioBonus: FC<RadioBonusProps> = ({
  checked = false,
  ...props
}) => {
  return (
    <StyledRadio checked={checked}>
      {checked && <StyledRing />}
      <StyledRadioInput checked={checked} type="radio" {...props} />
    </StyledRadio>
  )
}

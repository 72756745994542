import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconTicketCouponNo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 32 24"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.85 12.9397C27.85 13.7397 28.5 14.3997 29.3 14.3997C29.68 14.3997 30 14.7097 30 15.0897C30 18.9297 28.84 20.0897 25 20.0897H24.4997H11.7503V18.4997C11.7503 18.1097 11.4503 17.7897 11.0703 17.7597V14.9097C11.4503 14.8797 11.7503 14.5597 11.7503 14.1697V9.82969C11.7503 9.43969 11.4503 9.11969 11.0703 9.08969V4.92969L24.4997 4.92969H25C28.84 4.92969 30 6.08969 30 9.92969V10.7797C30 11.1697 29.68 11.4797 29.3 11.4797C28.5 11.4797 27.85 12.1297 27.85 12.9397Z"
        fill="#7845E7"
        opacity="0.28"
      />
      <path
        d="M11.0701 9.08981C10.6601 9.08981 10.2501 9.41981 10.2501 9.82981V14.1698C10.2501 14.5798 10.5901 14.9198 11.0001 14.9198C11.0201 14.9198 11.0501 14.9198 11.0701 14.9098V17.7598C11.0501 17.7498 11.0201 17.7498 11.0001 17.7498C10.5901 17.7498 10.2501 18.0898 10.2501 18.4998V20.0898H8.49009C6.61009 20.0898 5.64009 18.6798 4.76009 16.5498L4.59009 16.1298C4.45009 15.7698 4.62009 15.3598 4.98009 15.2198C5.35009 15.0798 5.64009 14.7898 5.79009 14.4098C5.95009 14.0398 5.95009 13.6298 5.80009 13.2598C5.48009 12.4898 4.60009 12.1198 3.82009 12.4298C3.65009 12.5098 3.45009 12.5098 3.28009 12.4298C3.11009 12.3598 2.98009 12.2198 2.90009 12.0398L2.75009 11.6398C1.26009 8.01981 1.91009 6.46981 5.53009 4.96981L7.98009 3.95981C8.34009 3.80981 8.75009 3.97981 8.89009 4.33981L11.0701 9.08981Z"
        fill="#7845E7"
      />
    </svg>
  </Icon>
)

import { ClientConstraints } from 'betweb-openapi-axios'

import { GetUserInfoFetch } from 'entities/legacy-api'

export type ProviderState = {
  data: {
    constraints: {
      isBetsBlocked: ClientConstraints['betsBlocked']
      isWithdrawalBlocked: ClientConstraints['withdrawalBlocked']
      isTopUpBlocked: boolean
    }
  }
}

export enum IdentificationConstraints {
  WITHDRAWAL = 'withdrawal',
  BETS = 'bets',
  TOP_UP = 'topUp'
}

export interface ProviderReducerPayloads {
  onChangeConstraints: GetUserInfoFetch['modifiedResponse']['data']['identificationStatus']
  accountConstraints: {
    isBetsBlocked: boolean
    isWithdrawalBlocked: boolean
  }
}

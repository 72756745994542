import { css } from 'styled-components'

import { OddModes } from 'entities/event-probability/model/odd-mode'

export const PROBABILITY_LABEL_TEXT = 'probability-label-text'
export const PROBABILITY_ODD_TEXT = 'probability-odd-text'

const inBasketColorByMode = (mode: OddModes) => css`
  ${({ theme }) => css`
    ${mode === OddModes.Increase &&
    css`
      .${PROBABILITY_ODD_TEXT} {
        color: ${theme.tokens.colors.text['text-green']};
      }
    `}
    ${mode === OddModes.Decrease &&
    css`
      .${PROBABILITY_ODD_TEXT} {
        color: ${theme.tokens.colors.text['text-error']};
      }
    `}
    ${mode === OddModes.Default &&
    css`
      .${PROBABILITY_ODD_TEXT} {
        color: ${theme.tokens.colors.text['text-invert']};
      }
    `}
  `}
`

const notInBasketColorByMode = ({
  mode,
  oddTextColored
}: {
  mode: OddModes
  oddTextColored?: boolean
}) => css`
  ${({ theme }) => css`
    ${mode === OddModes.Increase &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-positiveLight']};
      &:hover {
        background-color: ${theme.tokens.colors.surface[
          'surface-positiveLight-hover'
        ]};
      }
      ${oddTextColored &&
      css`
        .${PROBABILITY_ODD_TEXT} {
          color: ${theme.tokens.colors.text['text-green']};
        }
      `}
    `}
    ${mode === OddModes.Decrease &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-negativeLight']};
      &:hover {
        background-color: ${theme.tokens.colors.surface[
          'surface-negativeLight-hover'
        ]};
      }
      ${oddTextColored &&
      css`
        .${PROBABILITY_ODD_TEXT} {
          color: ${theme.tokens.colors.text['text-error']};
        }
      `}
    `}
    ${mode === OddModes.Default &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
      }
    `}
  `}
`

export const getProbabilityCellStyle = ({
  isInBasket,
  isEmpty,
  mode,
  oddTextColored
}: {
  isInBasket: boolean
  mode: OddModes
  isEmpty?: boolean
  oddTextColored?: boolean
}) => css`
  ${({ theme }) => css`
    position: relative;
    transition: 0.5s ease background-color, color;
    ${isInBasket &&
    css`
      .${PROBABILITY_LABEL_TEXT} {
        color: ${theme.tokens.colors.text['text-description']};
      }
      background-color: ${theme.tokens.colors.surface['surface-active2']};
      &:hover {
        background-color: ${theme.tokens.colors.surface[
          'surface-active2-hover'
        ]};
      }

      ${inBasketColorByMode(mode)}
    `}
    ${!isInBasket &&
    css`
      .${PROBABILITY_LABEL_TEXT} {
        color: ${theme.tokens.colors.text['text-secondary-3']};
      }
      .${PROBABILITY_ODD_TEXT} {
        color: ${theme.tokens.colors.text['text-primary']};
      }

      ${notInBasketColorByMode({ mode, oddTextColored })}
    `}
    ${isEmpty &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-disabled']};
      /* box-shadow: none; */
      cursor: default;
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
      }
      .${PROBABILITY_LABEL_TEXT} {
        color: ${theme.tokens.colors.text['text-disabled']};
      }
      .${PROBABILITY_ODD_TEXT} {
        color: ${theme.tokens.colors.text['text-disabled']};
      }
    `}
  `}
`

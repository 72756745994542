import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useTimer } from 'hooks'
import { ENotificationContainerIds } from 'shared/lib/Toastify'
import { IconWarningTriangle } from 'shared/ui/Icon/General/IconWarningTriangle'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { TBroadcastToastifyNotificationProps } from './broadcast-toastify-notification.types'
import {
  BROADCAST_TOASTIFY_NOTIFICATION_TIMER,
  BROADCAST_TOASTIFY_NOTIFICATION_TIMER_AUTO_CLOSE,
  TOASTIFY_BROADCAST_ID
} from './constants'
import {
  StyledBroadcastToastifyNotificationTextWrapper,
  StyledBroadcastToastifyNotificationText
} from './broadcast-toastify-notification.styled'

export const BroadcastToastifyNotification: FC<
  TBroadcastToastifyNotificationProps
> = ({ toastProps }) => {
  const seconds = useTimer(BROADCAST_TOASTIFY_NOTIFICATION_TIMER)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.changeToastifyNotification({
        isShown: false
      })
    )

    if (!seconds) {
      toast.update(toastProps.toastId, {
        closeButton: false,
        containerId: ENotificationContainerIds.SYSTEM,
        autoClose: BROADCAST_TOASTIFY_NOTIFICATION_TIMER_AUTO_CLOSE,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: false,
        toastId: TOASTIFY_BROADCAST_ID
      })
    }
  }, [dispatch, seconds, toastProps.toastId])

  return <BroadcastToastifyNotificationText />
}

const BroadcastToastifyNotificationText = () => {
  const [t] = useTranslation()

  return (
    <StyledBroadcastToastifyNotificationTextWrapper>
      <IconWarningTriangle colorToken="icon-warning" size={16} />

      <StyledBroadcastToastifyNotificationText>
        {`${t('watch 4 broadcasts at the same time')}`}
      </StyledBroadcastToastifyNotificationText>
    </StyledBroadcastToastifyNotificationTextWrapper>
  )
}

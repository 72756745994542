import React from 'react'

import { StyledHotKeyWrapper } from 'features/global-search/ui/hotkey/hotkey.styled'
import { IconHotkey } from 'shared/ui/Icon/General/icon-hotkey'

export const Hotkey = () => (
  <StyledHotKeyWrapper>
    <IconHotkey colorToken="icon-secondary" size={12} />
  </StyledHotKeyWrapper>
)

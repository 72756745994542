import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledUserAccountBalance = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-l-12'
    ]}px;
    cursor: pointer;
    overflow: hidden;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledBalanceValue = styled.span`
  ${typography['Body / 12 ExtraBold']}
  white-space: nowrap;
`

export const StyledBalanceInner = styled.div`
  align-items: center;
  display: flex;
`

export const StyledBalanceContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
    overflow: hidden;
  `}
`

export const StyledCurrencyIcon = styled.div`
  ${typography['Body / 12 ExtraBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding-left: 2px;
  `}
`

export const StyledValueContainer = styled.div<{
  active: boolean
  totalWidth: number | undefined
}>`
  ${({ active, totalWidth, theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-primary']};
    display: flex;
    position: relative;

    ${active
      ? css`
          & ${StyledBalanceValue} {
            margin-right: ${`calc(-${totalWidth}px + 21px)`};
            opacity: 0;
            transition: margin-right 0.2s, opacity 0.05s;
          }

          & ${StyledHiddenMark} {
            opacity: 1;
          }
        `
      : css`
          & ${StyledBalanceValue} {
            margin-right: 0;
            transition: margin-right 0.2s cubic-bezier(0.47, 1.64, 0.8, 0.8),
              opacity 0.1s 0.1s;
          }
        `}
  `}
`

export const StyledHiddenMark = styled.div`
  ${typography['Body / 12 ExtraBold']}
  align-items: center;
  display: flex;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
`

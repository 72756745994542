import { FC, ReactNode } from 'react'

import { IconWarningTriangle } from 'shared/ui/Icon/General/IconWarningTriangle'
import { Typography } from 'shared/ui/typography'

import { IconInfoCircleFilled } from '../Icon/General/IconInfoCircleFilled'

import { AlertIconWrapper, AlertWrapper } from './alert.styled'

export enum EAlertViev {
  WARNING = 'warning',
  INFO = 'info'
}
export interface AlertProps {
  view?: EAlertViev
  size?: 'm' | 's'
  children: ReactNode
  className?: string // For Styled component
}

export const Alert: FC<AlertProps> = ({
  view = EAlertViev.WARNING,
  size = 'm',
  children,
  className
}) => {
  return (
    <AlertWrapper className={className} size={size} view={view}>
      <AlertIconWrapper>
        {view === EAlertViev.WARNING && (
          <IconWarningTriangle colorToken="icon-warning" size={16} />
        )}
        {view === EAlertViev.INFO && <IconInfoCircleFilled size={16} />}
      </AlertIconWrapper>
      <Typography
        color="text-primary-2"
        font={size === 'm' ? 'Body / 14 Medium' : 'Body / 12 Medium'}
      >
        {children}
      </Typography>
    </AlertWrapper>
  )
}

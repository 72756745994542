import React, { FC, memo } from 'react'
import { searchProviderActions } from 'astra-core/containers/SearchProvider'

import { useAppDispatch } from 'shared/lib/@reduxjs'

import { EmptyPageFilter } from '../empty-page-filter'

import { ClearResultSearchProps } from './clear-search-results.types'

export const ClearSearchResults: FC<ClearResultSearchProps> = memo(
  ({ isLive }) => {
    const dispatch = useAppDispatch()
    const handleResetClick = () => {
      dispatch(searchProviderActions.setSearchLine({ valueSearch: '' }))
    }

    return (
      <EmptyPageFilter
        isLive={isLive}
        isSearch
        onButtonClickResetFilter={handleResetClick}
      />
    )
  }
)

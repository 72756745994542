import { all, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import { IS_HIDDEN_WELCOME_SCREEN_COOKIE } from 'constants/cookies.constants'

import { SetDisplayWelcomeScreenPayload } from './types'
import { layoutContainerActions } from './slice'

export function* setHiddenWelcomeScreenSaga(
  action: PayloadAction<SetDisplayWelcomeScreenPayload>
) {
  yield Cookies.set(IS_HIDDEN_WELCOME_SCREEN_COOKIE, `${action.payload}`, {
    expires: 365,
    path: '/'
  })
}

export function* layoutContainerSaga() {
  yield all([
    takeLatest(
      layoutContainerActions.setHiddenWelcomeScreen.type,
      setHiddenWelcomeScreenSaga
    )
  ])
}

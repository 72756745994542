import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconSimIzi: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 29 10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4428 0.450195H12.5704C12.2235 0.450195 11.9424 0.731349 11.9424 1.07817V8.92213C11.9424 9.26897 12.2235 9.55013 12.5704 9.55013H14.4428C14.7896 9.55013 15.0707 9.26897 15.0707 8.92213V1.07817C15.0707 0.731349 14.7896 0.450195 14.4428 0.450195Z"
        fill="currentColor"
      />
      <path
        d="M28.474 0.450195H26.6016C26.2548 0.450195 25.9736 0.731349 25.9736 1.07817V8.92213C25.9736 9.26897 26.2548 9.55013 26.6016 9.55013H28.474C28.8208 9.55013 29.102 9.26897 29.102 8.92213V1.07817C29.102 0.731349 28.8208 0.450195 28.474 0.450195Z"
        fill="currentColor"
      />
      <path
        d="M24.0816 6.93032C24.4246 6.93032 24.7019 7.20773 24.7019 7.5506V8.92986C24.7019 9.27272 24.4246 9.55013 24.0816 9.55013H16.9928C16.6461 9.55013 16.3648 9.2689 16.3648 8.92213C16.3648 8.79887 16.4033 8.67558 16.4727 8.57155L20.1866 3.06999H16.9389C16.596 3.06999 16.3186 2.7926 16.3186 2.44971V1.07047C16.3186 0.727586 16.596 0.450195 16.9389 0.450195H24.0932C24.44 0.450195 24.7212 0.731436 24.7212 1.07817C24.7212 1.20531 24.6865 1.32474 24.6172 1.42877L20.9109 6.93032H24.0816Z"
        fill="currentColor"
      />
      <path
        d="M4.80339 9.55012C7.31625 9.55012 9.35334 7.51304 9.35334 5.00017C9.35334 2.48728 7.31625 0.450195 4.80339 0.450195C2.29051 0.450195 0.253418 2.48728 0.253418 5.00017C0.253418 7.51304 2.29051 9.55012 4.80339 9.55012Z"
        fill="url(#paint0_linear_3155_189219)"
      />
      <path
        d="M6.23264 8.9609C7.62844 8.9609 8.75997 7.82938 8.75997 6.43358C8.75997 5.03778 7.62844 3.90625 6.23264 3.90625C4.83684 3.90625 3.70532 5.03778 3.70532 6.43358C3.70532 7.82938 4.83684 8.9609 6.23264 8.9609Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3155_189219"
          x1="2.27633"
          x2="7.2971"
          y1="7.52894"
          y2="2.5082"
        >
          <stop stopColor="#00F1FF" />
          <stop offset="1" stopColor="#FF00D7" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
)

import React from 'react'

import { FooterOfPage as FooterOfPageEntity } from 'entities/footer-of-page'
import {
  OpenInstagram,
  OpenSupport,
  OpenTelegram,
  OpenTikTok,
  OpenVK,
  OpenYoutube
} from 'features/footer-of-page'
import { Logo } from 'features/logo/ui'
import { AppLink } from 'shared/lib/app-link'

import {
  FooterOfPageMailItems,
  FooterOfPageMenuItems,
  FooterOfPagePhoneItems
} from './ui'

export const FooterOfPage = () => {
  return (
    <FooterOfPageEntity
      logoMain={
        <AppLink to="/">
          <Logo />
        </AppLink>
      }
      socialNetworksItems={
        <>
          <OpenTelegram />
          <OpenVK />
          <OpenInstagram />
          <OpenTikTok />
          <OpenYoutube />
        </>
      }
      helpButtons={<OpenSupport />}
      mail={<FooterOfPageMailItems />}
      menuItems={<FooterOfPageMenuItems />}
      phone={<FooterOfPagePhoneItems />}
    />
  )
}

import { FC } from 'react'

import { notificationsApi } from 'entities/legacy-api/lib/notifications'
import { IconProps } from 'shared/ui/Icon'
import { IconNotification } from 'shared/ui/Icon/General/icon-notification'
import { IconNotificationUnread } from 'shared/ui/Icon/General/icon-notification-unread'

const { useGetNotificationsCountQuery } = notificationsApi

export const IconStatefulNotification: FC<IconProps> = (props) => {
  const { data } = useGetNotificationsCountQuery()

  const count = data?.data
  const hasUnread = count ? count.notread > 0 || count.waiting > 0 : false

  const Icon = hasUnread ? IconNotificationUnread : IconNotification

  return <Icon {...props} />
}

import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconCircleArrowToCenter: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0672 6.34534L12.4126 13H15.0026C15.5526 13 16.0026 13.45 16.0026 14C16.0026 14.55 15.5526 15 15.0026 15H9.99256C9.74256 15 9.48256 14.9 9.29256 14.71C9.20256 14.61 9.12256 14.5 9.08256 14.38C9.03256 14.26 9.00256 14.13 9.00256 14V9.00004C9.00256 8.45004 9.45256 8.00004 10.0026 8.00004C10.5526 8.00004 11.0026 8.45004 11.0026 9.00004V11.59L17.6551 4.92997L16.6076 3.88C16.0372 3.31 15.2868 3 14.4964 3H9.52362C8.73318 3 7.96276 3.31 7.41245 3.88L3.88049 7.4C3.31017 7.97 3 8.72 3 9.52V14.48C3 15.28 3.31017 16.04 3.88049 16.6L7.39244 20.11C7.96276 20.68 8.71317 21 9.51362 21H14.4764C15.2768 21 16.0372 20.68 16.5976 20.11L20.1095 16.6C20.6798 16.03 21 15.28 21 14.48V9.51C21 8.71 20.6798 7.95 20.1095 7.39L19.0672 6.34534Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}

import { Props as SelectProps } from 'react-select'

export interface OptionType {
  id: string
  value: string
  label: string
  scheduledFrom?: string
  scheduledTo?: string
  additionalLabel?: string
  styles?: {
    isBottomBorder?: boolean
  }
}

export interface CustomReactSelectProps extends SelectProps<OptionType, false> {
  view?: ESelectView
}

export enum ESelectView {
  Default = 'default',
  Mini = 'mini',
  Basket = 'basket',
  Results = 'results'
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M12.9261 7.03328C14.1806 7.03328 15.1969 5.9046 15.1969 4.51664C15.1969 3.12486 14.1806 2 12.9261 2C11.6715 2 10.6553 3.12486 10.6553 4.51664C10.6553 5.9046 11.6715 7.03328 12.9261 7.03328Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
      <path
        d="M10.8078 7.52135C10.9896 7.68913 11.1912 7.83403 11.4078 7.95604C11.859 8.20389 12.376 8.34498 12.9261 8.34498C13.2237 8.34498 13.511 8.30304 13.7832 8.22677C14.4745 8.03231 15.0707 7.60143 15.4832 7.02946H16.1651L17.8577 7.03709C19.0237 7.03709 20.1285 7.57092 20.8603 8.48988L22.712 10.8159C23.0464 11.2353 22.9814 11.8492 22.567 12.1886C22.1516 12.5279 21.545 12.4593 21.2107 12.0399L19.359 9.71769C18.9926 9.25631 18.7129 9.22199 17.9716 9.283C17.8275 9.29444 17.6457 9.3135 17.448 9.3402L16.8009 9.4279L17.4253 11.7882C17.2549 12.2343 17.0213 12.65 16.735 13.0236C15.8534 14.1714 14.4745 14.9111 12.9261 14.9111C11.7667 14.9111 10.7033 14.4993 9.87163 13.8091C9.48076 13.485 9.14169 13.0999 8.86761 12.6652C8.69242 12.3907 8.54361 12.0971 8.42588 11.7882L9.05127 9.4279L8.47015 9.35163C8.24598 9.32113 8.04066 9.29444 7.88054 9.283C7.5151 9.25249 7.2608 9.24487 7.05359 9.30206C6.84167 9.35926 6.67873 9.48509 6.49318 9.71769L4.64149 12.0399C4.49456 12.2229 4.29394 12.3411 4.08108 12.383L3.94263 12.4021H3.85221C3.65253 12.3983 3.45286 12.3258 3.28521 12.1886C3.11285 12.0475 3.00077 11.8606 2.95273 11.6586C2.88492 11.3726 2.94425 11.0599 3.14016 10.8159L4.99186 8.48988C5.72368 7.57092 6.82848 7.03709 7.99451 7.03709L9.68609 7.02946H10.3699C10.4989 7.20868 10.6458 7.37264 10.8078 7.52135Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
      <path
        d="M15.0369 18.4157L14.8627 16.1732C16.3433 15.6318 17.7682 14.5374 18.4783 13.1342H20.072C21.1363 13.1342 21.8361 14.2591 21.3774 15.2314L18.4416 21.4429C18.2118 21.931 17.6354 22.1369 17.155 21.9043C16.9083 21.7861 16.735 21.5726 16.6549 21.3285C16.5796 21.0959 16.589 20.8367 16.7011 20.6002L17.6542 17.5612L16.2047 18.3429L16.2556 18.9263C14.774 21.3667 11.13 21.3667 9.64841 18.9263L9.69927 18.3429L8.24975 17.1685L9.20291 20.6002C9.31688 20.8405 9.32441 21.1074 9.24435 21.34C9.18219 21.5268 9.0654 21.6908 8.90434 21.8128C8.85631 21.8471 8.80451 21.8776 8.74894 21.9043C8.26859 22.1369 7.69217 21.931 7.46236 21.4429L4.52658 15.2314C4.06789 14.2591 4.76769 13.1342 5.832 13.1342H7.42562C8.13579 14.5374 9.54507 15.6318 11.0257 16.1732L10.8514 18.4157C12.0137 19.7961 13.8653 19.8075 15.0369 18.4157Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M8.1604 4.02662C9.16404 4.02662 9.97706 3.12368 9.97706 2.01331C9.97706 0.899889 9.16404 0 8.1604 0C7.15675 0 6.34374 0.899889 6.34374 2.01331C6.34374 3.12368 7.15675 4.02662 8.1604 4.02662Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
      <path
        d="M6.4658 4.41708C6.61123 4.5513 6.77247 4.66722 6.94578 4.76484C7.3067 4.96312 7.72036 5.07598 8.1604 5.07598C8.3985 5.07598 8.62831 5.04243 8.84607 4.98142C9.39913 4.82584 9.87609 4.48114 10.2061 4.02357H10.7516L12.1057 4.02967C13.0385 4.02967 13.9223 4.45674 14.5078 5.1919L15.9891 7.05269C16.2566 7.38824 16.2046 7.87937 15.8731 8.15086C15.5408 8.42235 15.0556 8.36744 14.7881 8.03189L13.3067 6.17415C13.0136 5.80505 12.7898 5.77759 12.1968 5.8264C12.0815 5.83555 11.9361 5.8508 11.7779 5.87216L11.2602 5.94232L11.7598 7.83056C11.6234 8.18746 11.4366 8.51997 11.2075 8.81891C10.5022 9.7371 9.39913 10.3289 8.1604 10.3289C7.23285 10.3289 6.38217 9.99944 5.71684 9.44731C5.40414 9.18802 5.13288 8.87992 4.91362 8.53217C4.77347 8.31253 4.65442 8.07765 4.56023 7.83056L5.06055 5.94232L4.59565 5.88131C4.41632 5.8569 4.25206 5.83555 4.12396 5.8264C3.83161 5.802 3.62817 5.79589 3.4624 5.84165C3.29287 5.88741 3.16251 5.98807 3.01408 6.17415L1.53272 8.03189C1.41518 8.17831 1.25468 8.27288 1.08439 8.30643L0.973632 8.32168H0.901297C0.741557 8.31863 0.581818 8.26067 0.447697 8.15086C0.309809 8.03799 0.220144 7.88852 0.181716 7.72684C0.127465 7.49806 0.174935 7.24792 0.33166 7.05269L1.81302 5.1919C2.39848 4.45674 3.28232 4.02967 4.21514 4.02967L5.5684 4.02357H6.11543C6.21866 4.16694 6.3362 4.29811 6.4658 4.41708Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
      <path
        d="M9.84908 13.1326L9.70969 11.3386C10.8942 10.9054 12.0341 10.0299 12.6022 8.90737H13.8771C14.7286 8.90737 15.2884 9.80726 14.9214 10.5851L12.5728 15.5543C12.389 15.9448 11.9278 16.1095 11.5436 15.9235C11.3461 15.8289 11.2075 15.6581 11.1435 15.4628C11.0832 15.2768 11.0907 15.0693 11.1804 14.8802L11.9429 12.449L10.7833 13.0743L10.824 13.541C9.63874 15.4933 6.7235 15.4933 5.53826 13.541L5.57895 13.0743L4.41933 12.1348L5.18186 14.8802C5.27303 15.0724 5.27906 15.2859 5.21501 15.472C5.16528 15.6215 5.07185 15.7526 4.94301 15.8502C4.90458 15.8777 4.86314 15.9021 4.81868 15.9235C4.4344 16.1095 3.97327 15.9448 3.78942 15.5543L1.44079 10.5851C1.07385 9.80726 1.63369 8.90737 2.48513 8.90737H3.76003C4.32816 10.0299 5.45559 10.9054 6.64007 11.3386L6.50067 13.1326C7.43048 14.2368 8.91174 14.246 9.84908 13.1326Z"
        fill={theme.tokens.colors['sport-icon']['sport-popMma']}
      />
    </>
  )
}

export const IconSportSumo: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

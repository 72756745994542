import { FC } from 'react'
import { Trans } from 'react-i18next'

import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { Typography } from 'shared/ui/typography'
import { RadioBonus } from 'shared/ui/RadioBonus'

import { BonusCouponCanUseProps } from './bonus-coupon-can-use.types'
import * as S from './bonus-coupon-can-use.styled'

export const BonusCouponCanUse: FC<BonusCouponCanUseProps> = ({
  value,
  isRadioChecked,
  onChangeRadio
}) => (
  <S.StyledBasketBonusCouponWrapper>
    <IconTicketNo />

    <S.StyledBonusText>
      <Trans
        components={{
          bold: <Typography color="text-primary-2" font="Body / 12 Bold" />
        }}
        i18nKey="apply coupon to"
        values={{ coupon: value }}
      />
    </S.StyledBonusText>

    <RadioBonus checked={isRadioChecked} onChange={onChangeRadio} />
  </S.StyledBasketBonusCouponWrapper>
)

export interface IDragDropContextEvent {
  clientY: number
  clientX: number
  clientHeight: number
  clientWidth: number
  destinationIndex: number
}

interface IGetClientNodeValues {
  draggedDOM: Element
  parentNode: Element
  childrenArray: Element[]
  index: number
}

export const DROPPABLE_PLACEHOLDER_DEFAULT_VALUE: IDragDropContextEvent = {
  clientY: 0,
  clientX: 0,
  clientHeight: 0,
  clientWidth: 0,
  destinationIndex: 0
}

const QUERY_ATTR = 'data-rbd-draggable-id'

export const getDraggedDestinationElementPosition = ({
  clientY,
  clientX,
  clientHeight,
  clientWidth,
  destinationIndex
}: IDragDropContextEvent) => ({
  top: clientY + (destinationIndex + 1) * 2,
  left: clientX,
  height: clientHeight,
  width: clientWidth
})

export const getDraggedDom = (draggableId) => {
  const domQuery = `[${QUERY_ATTR}='${draggableId}']`
  const draggedDOM = document.querySelector(domQuery)

  return draggedDOM
}

export const getClientNodeValues = ({
  draggedDOM,
  parentNode,
  childrenArray,
  index
}: IGetClientNodeValues) => {
  const { clientHeight, clientWidth } = draggedDOM
  const clientX = parseFloat(window.getComputedStyle(parentNode).paddingLeft)

  const clientY =
    parseFloat(window.getComputedStyle(parentNode).paddingTop) +
    childrenArray.slice(0, index).reduce((total, curr) => {
      const style = window.getComputedStyle(curr as HTMLElement)
      const marginBottom = parseFloat(style.marginBottom)
      return total + curr.clientHeight + marginBottom
    }, 0)

  return { clientX, clientY, clientHeight, clientWidth }
}

import omit from 'lodash/omit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCurrentLng } from 'astra-core'

import { RootState } from 'shared/types/store'
import { i18nLocalToLegacyLang } from 'shared/lib/locale'

import { baseLegacyApi } from '../api'
import { LegacyBet } from '../types'

import {
  GetBetsHistoryRequest,
  GetBetsHistoryResponse,
  GetEventStatisticsRequest,
  GetEventStatisticsResponse
} from './bets-history.types'

export const betsHistoryApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getBetsHistory: builder.query<
      GetBetsHistoryResponse,
      GetBetsHistoryRequest
    >({
      providesTags: () => ['BetsHistory'],
      serializeQueryArgs: ({ queryArgs }) =>
        omit(queryArgs, ['offset', 'limit']),
      query: (args) => ({
        method: 'POST',
        url: '/user/history',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      }),
      merge(currentCacheData, responseData) {
        const existingBetIds = new Set(
          currentCacheData.data.bet_list.map((bet) => bet.bet_id)
        )
        const newBets = responseData.data.bet_list.filter(
          (bet) => !existingBetIds.has(bet.bet_id)
        )
        currentCacheData.data.bet_list.push(...newBets)
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),
    getEventStatistics: builder.query<
      GetEventStatisticsResponse,
      GetEventStatisticsRequest
    >({
      query: (args) => ({
        method: 'POST',
        url: '/user/history/get_score',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    })
  })
})

export const resetBetsHistory = () =>
  betsHistoryApi.util.invalidateTags(['BetsHistory'])

export const markBetSold = createAsyncThunk(
  'markBetSold',
  ({ betId }: { betId: LegacyBet['bet_id'] }, thunkAPI) => {
    betsHistoryApi.util
      .selectInvalidatedBy(thunkAPI.getState() as RootState, ['BetsHistory'])
      .filter(({ endpointName }) => endpointName === 'getBetsHistory')
      .forEach(({ originalArgs }) => {
        thunkAPI.dispatch(
          betsHistoryApi.util.updateQueryData(
            'getBetsHistory',
            originalArgs,
            (draft) => {
              if (!draft?.data) {
                return
              }

              const index = draft.data.bet_list.findIndex(
                (v) => v.bet_id === betId
              )

              if (index >= 0) {
                draft.data.bet_list[index].cashout_allowed = false
              }
            }
          )
        )
      })
  }
)

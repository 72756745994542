import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M8.50744 18.1248C12.1014 18.1248 15.015 15.2113 15.015 11.6173C15.015 8.0233 12.1014 5.10978 8.50744 5.10978C4.91343 5.10978 1.99991 8.0233 1.99991 11.6173C1.99991 12.2353 2.08604 12.8331 2.24697 13.3995C2.08423 12.2934 2.36765 11.3332 3.08317 10.9201C4.19478 10.2783 5.92835 11.1999 6.95521 12.9784C7.98207 14.757 7.91337 16.7191 6.80176 17.3609C6.51708 17.5252 6.1916 17.5871 5.84765 17.5582C6.65967 17.9223 7.5599 18.1248 8.50744 18.1248Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.57951 13.5761C6.2041 14.658 6.16232 15.8514 5.48618 16.2418C4.81004 16.6322 3.75559 16.0716 3.131 14.9898C2.50641 13.908 2.5482 12.7145 3.22433 12.3242C3.90047 11.9338 4.95492 12.4943 5.57951 13.5761Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.9737 7.52397C15.8794 8.64203 16.422 10.0663 16.422 11.6173C16.422 12.2451 16.3331 12.852 16.1673 13.4264C16.4024 13.4595 16.6426 13.4766 16.8869 13.4766C19.7107 13.4766 21.9999 11.1874 21.9999 8.36355C21.9999 5.53968 19.7107 3.25049 16.8869 3.25049C16.4016 3.25049 15.9322 3.31808 15.4875 3.44435C16.3559 3.31698 17.1096 3.53974 17.434 4.1016C17.9383 4.97501 17.2142 6.3371 15.8167 7.14392C15.5383 7.30466 15.2542 7.43123 14.9737 7.52397Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.7411 6.3532C14.1729 6.48494 14.7797 6.39071 15.3473 6.063C16.1973 5.57225 16.6377 4.74376 16.331 4.21251C16.0242 3.68125 15.0865 3.64842 14.2365 4.13917C13.3865 4.62992 12.9461 5.45842 13.2528 5.98967C13.2663 6.01297 13.2809 6.03531 13.2968 6.05669C13.4491 6.14955 13.5973 6.2485 13.7411 6.3532Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.2491 18.1248C21.2491 19.5745 20.0739 20.7497 18.6242 20.7497C17.1745 20.7497 15.9993 19.5745 15.9993 18.1248C15.9993 16.6752 17.1745 15.5 18.6242 15.5C20.0739 15.5 21.2491 16.6752 21.2491 18.1248Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M5.20602 12.8995C8.08123 12.8995 10.412 10.5687 10.412 7.69346C10.412 4.81825 8.08123 2.48743 5.20602 2.48743C2.33082 2.48743 0 4.81825 0 7.69346C0 8.18783 0.0689088 8.6661 0.197645 9.1192C0.0674568 8.2343 0.294191 7.46618 0.866613 7.13569C1.7559 6.62226 3.14276 7.3595 3.96424 8.78236C4.78573 10.2052 4.73077 11.7749 3.84148 12.2883C3.61374 12.4198 3.35335 12.4693 3.0782 12.4462C3.72781 12.7375 4.448 12.8995 5.20602 12.8995Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M2.86368 9.26053C3.36336 10.126 3.32993 11.0807 2.78902 11.393C2.2481 11.7053 1.40454 11.2569 0.904872 10.3914C0.405199 9.52599 0.43863 8.57123 0.979541 8.25894C1.52045 7.94664 2.36401 8.39507 2.86368 9.26053Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.379 4.41878C11.1036 5.31324 11.5377 6.45265 11.5377 7.69346C11.5377 8.19565 11.4666 8.68124 11.3339 9.1407C11.522 9.16719 11.7142 9.18089 11.9096 9.18089C14.1686 9.18089 16 7.34954 16 5.09045C16 2.83136 14.1686 1 11.9096 1C11.5214 1 11.1458 1.05407 10.79 1.15509C11.4848 1.05319 12.0878 1.2314 12.3473 1.68089C12.7507 2.37962 12.1714 3.46929 11.0535 4.11474C10.8307 4.24333 10.6034 4.34459 10.379 4.41878Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.39297 3.48217C9.73842 3.58756 10.2238 3.51218 10.6779 3.25001C11.3579 2.85741 11.7102 2.19462 11.4648 1.76961C11.2195 1.34461 10.4693 1.31834 9.7893 1.71094C9.10929 2.10354 8.75696 2.76634 9.00233 3.19134C9.01309 3.20998 9.02483 3.22786 9.03748 3.24496C9.15936 3.31925 9.27795 3.39841 9.39297 3.48217Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.3993 12.8995C15.3993 14.0592 14.4592 14.9994 13.2994 14.9994C12.1397 14.9994 11.1995 14.0592 11.1995 12.8995C11.1995 11.7397 12.1397 10.7996 13.2994 10.7996C14.4592 10.7996 15.3993 11.7397 15.3993 12.8995Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
        fillRule="evenodd"
      />
    </>
  )
}

export const IconSportBowls: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css, DefaultTheme } from 'styled-components'
import { get } from 'lodash'
import { LayoutTheme } from 'betweb-openapi-axios'

import { SPORT_MAIN_COLOR_MAP } from 'shared/ui/Icon/sport-icon/constants'
import { ISportIconProps } from 'shared/ui/Icon/sport-icon/sport-icon.types'
import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'

import {
  StyledBannerCompact,
  StyledBannerContentLine,
  StyledBannerContentLive
} from './components/BannerContent/BannerContent.styled'
import {
  BANNER_TABS_HEIGHT,
  LINE_COMPACT_CONTENT,
  LIVE_COMPACT_CONTENT
} from './libs'

const getEventBannerGradient = (
  sport: ISportIconProps['sport'],
  theme: DefaultTheme
) => {
  const colorToken = SPORT_MAIN_COLOR_MAP[sport!] ?? 'sport-icon.sport-global'
  if (
    colorToken === 'sport-icon.sport-global' &&
    theme.typeLayoutTheme === LayoutTheme.Dark
  ) {
    return css`
      background-image: linear-gradient(
          180deg,
          rgba(31, 31, 38, 0.256) 0%,
          rgba(31, 31, 38, 0.448) 48.96%,
          rgba(31, 31, 38, 0.64) 100%
        ),
        linear-gradient(
          0deg,
          ${theme.tokens.colors['sport-icon']['sport-global']},
          ${theme.tokens.colors['sport-icon']['sport-global']}
        );
    `
  }
  return css`
    background-image: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.16),
        rgba(0, 0, 0, 0.32)
      ),
      radial-gradient(
        circle at 710px -16px,
        rgba(0, 0, 0, 0.12) 240px,
        rgba(0, 0, 0, 0)
      );
  `
}

export const StyledEventBannerContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`

export const StyledEventHeadWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledEventHead = styled(AppLink)`
  align-items: center;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const StyledEventArrow = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  `}
`

export const StyledEventTitle = styled.span`
  ${typography['Body / 14 Medium']};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
  `}
`

export const StyledEventBannerWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-none']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledEventBanner = styled.div<
  Pick<ISportIconProps, 'sport'> & {
    transition: number
    isLive: boolean
    maxHeight: number
  }
>`
  ${({ theme, sport, transition, isLive, maxHeight }) => css`
    ${getEventBannerGradient(sport, theme)};
    background-color: ${get(
      theme.tokens.colors,
      SPORT_MAIN_COLOR_MAP[sport!] ?? 'sport-icon.sport-global'
    )};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    flex-direction: column;
    height: calc(
      ${maxHeight}px -
        ${(maxHeight -
          BANNER_TABS_HEIGHT -
          (isLive ? LIVE_COMPACT_CONTENT : LINE_COMPACT_CONTENT)) *
        transition}px
    );
    max-height: calc(
      ${maxHeight}px -
        ${(maxHeight -
          BANNER_TABS_HEIGHT -
          (isLive ? LIVE_COMPACT_CONTENT : LINE_COMPACT_CONTENT)) *
        transition}px
    );
    overflow: hidden;

    position: relative;
    transition: all 0.3s;

    ${StyledBannerContentLine} {
      opacity: ${1 - transition};
      transition: all 0.3s;
    }

    ${StyledBannerContentLive} {
      opacity: ${1 - transition};
      transition: all 0.3s;
    }

    ${StyledBannerCompact} {
      opacity: ${transition};
      transition: all 0.3s;
    }
  `}
`

export const StyledBannerTabs = styled.div`
  ${() => css`
    bottom: 0;
    display: flex;
    justify-content: space-between;
    min-height: ${BANNER_TABS_HEIGHT}px;
    position: sticky;
    width: 100%;
  `}
`

export const StyledBannerTab = styled.div`
  ${typography['Body / 12 SemiBold']}
  align-items: center;
  cursor: pointer;
  display: flex;
  transition: all 0.3s;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-alwaysWhite-opacity70']};
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;

    & svg {
      color: ${theme.tokens.colors.text['text-alwaysWhite-opacity70']};
      transition: all 0.3s;
    }

    &:hover {
      color: ${theme.tokens.colors.text['text-alwaysWhite']};

      & svg {
        color: ${theme.tokens.colors.text['text-alwaysWhite']};
      }
    }
  `}
`

export const StyledBannerTabsGroup = styled.div`
  display: flex;
`

export const StyledBannerTabWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledLine = styled.div<{ entityTagColor: string }>`
  ${({ entityTagColor }) => css`
    background: ${entityTagColor};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: 3px;
    margin: 0 8px 1px;
    position: relative;

    ${StyledLineIndicator} {
      background: ${entityTagColor};

      &::before {
        box-shadow: 3px -3px ${entityTagColor};
      }

      &::after {
        box-shadow: -3px -3px ${entityTagColor};
      }
    }
  `}
`
export const StyledLineIndicatorWrapper = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const StyledLineIndicator = styled.div`
  ${typography['Hint / 10 BOLD']}
  ${({ theme }) => css`
    border: 1px solid ${theme.tokens.colors.surface['surface-page']};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: none;
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
    left: 50%;
    padding: 0 6px;
    position: absolute;
    top: 1px;
    transform: translate(-50%);
    z-index: 1;

    &::before {
      border-right: 1px solid ${theme.tokens.colors.surface['surface-page']};
      border-top: 1px solid ${theme.tokens.colors.surface['surface-page']};
      border-top-right-radius: 4px;
      content: '';
      height: 8px;
      left: -8px;
      position: absolute;
      top: 2px;
      width: 8px;
    }

    &::after {
      border-left: 1px solid ${theme.tokens.colors.surface['surface-page']};
      border-top: 1px solid ${theme.tokens.colors.surface['surface-page']};
      border-top-left-radius: 4px;
      content: '';
      height: 8px;
      position: absolute;
      right: -8px;
      top: 2px;
      width: 8px;
    }
  `}
`

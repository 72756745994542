import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertPassIdentificationProps } from './alert-pass-identification.types'
import * as S from './alert-pass-identification.styled'

export const AlertPassIdentification: FC<AlertPassIdentificationProps> = ({
  onClickToPassIdentificationLink
}) => {
  const [t] = useTranslation()

  return (
    <S.AlertPassIdentification>
      <p>{t('identification.basket.alert.text')}</p>

      <S.Link onClick={onClickToPassIdentificationLink}>
        {t('identification.basket.alert.link')}
      </S.Link>
    </S.AlertPassIdentification>
  )
}

import React, { FC } from 'react'

import {
  useAppSelector,
  useInjectReducer,
  useInjectSaga
} from 'shared/lib/@reduxjs'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  reducerLine,
  sliceKeyLine
} from 'entities/line-tournaments/model/slice'
import { lineTournamentsContainerSaga } from 'entities/line-tournaments/model/saga'
import { selectLineIsEmpty } from 'entities/line-tournaments/model/selectors'
import { useLineContainerData } from 'entities/line-tournaments/model/hooks'

import { LineProps } from './types'

export const LineContainer: FC<LineProps> = ({ children }) => {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  const isEmpty = useAppSelector(selectLineIsEmpty)

  useLineContainerData()

  return isEmpty ? <LoaderSpinner /> : <>{children}</>
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M2 9.56121C2 6.40511 3.85185 3.62031 6.81481 2.50639C9.77778 1.39247 13.0185 2.13509 15.1481 4.54858C16.2593 5.75532 17.0926 7.0549 17.3704 8.72577V8.8186C17.463 9.37556 17.3704 9.83969 16.9074 10.211C14.4074 12.5317 12 14.8523 9.59259 17.173C9.31481 17.3586 9.12963 17.5443 8.85185 17.4515C8.2963 17.2658 7.64815 17.173 7.09259 16.9873C4.40741 15.6878 2.46296 13.6456 2 10.4895C2 10.3967 2 10.3038 2 10.1182C2 9.93252 2 9.74687 2 9.56121ZM11.2708 9.18991C12.3819 9.18991 13.2153 8.35447 13.2153 7.24055C13.2153 6.12663 12.3819 5.19836 11.2708 5.19836C10.2523 5.19836 9.41898 6.12663 9.41898 7.14772C9.32639 8.35447 10.1597 9.18991 11.2708 9.18991Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M17.8333 11.4178C17.5556 12.2532 17.3704 12.9958 17.0926 13.8313C16.8148 14.6667 17 15.3165 17.6481 15.7806C18.8519 16.8017 20.1481 17.8228 21.3519 18.7511C21.537 18.9367 21.8148 19.1224 22 19.308C21.0741 20.2363 20.2407 21.0717 19.3148 22C19.2222 21.8143 19.037 21.6287 18.8519 21.3502C17.9259 20.1435 17 19.0295 16.0741 17.8228C15.8889 17.5443 15.7037 17.3587 15.4259 17.173C15.0556 16.8945 14.5926 16.8017 14.037 16.8945C13.3889 17.0802 12.7407 17.2658 12 17.4515C11.9074 17.4515 11.7222 17.5443 11.6296 17.5443C13.7593 15.595 15.7963 13.5528 17.8333 11.4178Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M3.92532 0.414851C1.50972 1.32297 0 3.59302 0 6.16611V6.92296C0.37743 9.49605 1.96266 11.161 4.15185 12.2204C4.19776 12.2358 4.24254 12.2493 4.28633 12.2612C5.51243 12.7392 6.65609 12.8388 8.15268 12.5991C8.81661 12.4928 9.28497 12.2961 9.81342 12.1448C10.2664 12.0691 10.6438 12.1448 10.9457 12.3717C11.1722 12.5235 11.3231 12.6747 11.4742 12.9016C12.0189 13.6117 12.5637 14.282 13.1084 14.9702C13.3185 15.2354 13.5286 15.5036 13.7387 15.7773C13.9157 16.0442 14.35 16.0728 14.5761 15.8461L15.8132 14.6058C16.0852 14.333 16.049 13.9312 15.7769 13.6585C14.7956 12.9016 13.7387 12.0691 12.7575 11.2367C12.229 10.8585 12.078 10.3285 12.3045 9.64732C12.5288 8.97308 12.7284 6.93341 12.5309 5.53068V5.4854C12.3045 4.12297 11.6251 3.06358 10.7193 2.07982C8.98305 0.111812 6.34092 -0.49327 3.92532 0.414851ZM9.1434 4.61509C9.1434 5.52321 8.46406 6.20393 7.55817 6.20393C6.65242 6.20393 5.97294 5.52321 6.04845 4.53896C6.04845 3.70648 6.7278 3.02277 7.55817 3.02277C8.46406 3.02277 9.1434 3.70648 9.1434 4.61509Z"
      fill={theme.tokens.colors['sport-icon']['sport-global']}
      fillRule="evenodd"
    />
  )
}

export const IconSportTableTennis: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      d="M16.0467 3.40321C16.0467 3.30622 15.9772 3.22304 15.8815 3.20748C15.3601 3.12277 14.7511 3.05891 14.0398 3.02532C13.9265 3.01997 13.8322 3.11078 13.8322 3.22421V5.42502C13.8322 5.74921 13.5542 6.01202 13.2114 6.01202C12.8685 6.01202 12.5905 5.74921 12.5905 5.42502V3.202C12.5905 3.09079 12.4997 3.00085 12.3885 3.00277C11.6931 3.01479 11.0833 3.05339 10.5484 3.11184C10.4479 3.12283 10.3724 3.20819 10.3724 3.30935V6.20772C10.3724 6.53191 10.0944 6.79472 9.75152 6.79472C9.40865 6.79472 9.13069 6.53191 9.13069 6.20772V3.59886C9.13069 3.46995 9.00976 3.37473 8.8852 3.40795C6.73431 3.98157 6.73431 4.95543 6.73431 4.95543V7.69474C6.73431 7.69474 4.49931 8.32087 4.49931 10.5123C4.49931 12.1559 6.73431 15.0518 6.73431 15.0518L8.47267 17.0689V19.9043C8.47267 20.5305 8.96934 21.0001 9.63156 21.0001H16.5849C17.2471 21.0001 17.8265 20.5305 17.8265 19.9043V17.6875C17.8265 17.5939 17.7618 17.5127 17.6703 17.4934C17.4079 17.438 17.0996 17.3819 16.7476 17.3322C15.4143 17.1439 13.4513 17.0473 10.9736 17.4312C10.6353 17.4836 10.316 17.2667 10.2606 16.9468C10.2051 16.6269 10.4345 16.325 10.7728 16.2726C13.3886 15.8674 15.4817 15.9664 16.931 16.1711C17.4122 16.239 17.8223 16.3186 18.1567 16.3951C18.2491 16.4162 18.3443 16.3712 18.3832 16.2849C18.5813 15.8458 18.8065 15.2479 18.9854 14.5039C20.1443 8.947 18.9854 4.95543 18.9854 4.95543C18.9854 4.95543 18.9854 4.21223 17.5514 3.6419C17.424 3.59124 17.2884 3.68745 17.2884 3.82455V6.20769C17.2884 6.53188 17.0104 6.79468 16.6676 6.79468C16.3247 6.79468 16.0467 6.53188 16.0467 6.20769V3.40321Z"
      fill={theme.tokens.colors['sport-icon']['sport-popMma']}
    />
  ),
  s: ({ theme }) => (
    <path
      d="M11.5972 0.377044C11.5972 0.280052 11.5277 0.196855 11.432 0.181465C10.9782 0.108549 10.4504 0.0533521 9.83633 0.0236113C9.72304 0.0181243 9.62876 0.108958 9.62876 0.222385V2.15558C9.62876 2.44375 9.38169 2.67736 9.07691 2.67736C8.77214 2.67736 8.52506 2.44375 8.52506 2.15558V0.202C8.52506 0.0907885 8.4343 0.000841021 8.32311 0.00287104C7.7227 0.0138326 7.19433 0.0471502 6.72937 0.0973007C6.6288 0.108148 6.55335 0.19352 6.55335 0.294673V2.85132C6.55335 3.13949 6.30628 3.37309 6.0015 3.37309C5.69672 3.37309 5.44965 3.13949 5.44965 2.85132V0.560372C5.44965 0.431452 5.32832 0.3363 5.20389 0.37003C3.31953 0.880844 3.31953 1.73817 3.31953 1.73817V4.17311C3.31953 4.17311 1.33287 4.72967 1.33287 6.67763C1.33287 8.1386 3.31953 10.7127 3.31953 10.7127L4.86475 12.5057V15.0261C4.86475 15.5826 5.30623 16.0001 5.89487 16.0001H12.0756C12.6642 16.0001 13.1793 15.5826 13.1793 15.0261V13.0733C13.1793 12.9798 13.1145 12.8986 13.023 12.8794C12.7936 12.8313 12.5254 12.7828 12.2203 12.7397C11.035 12.5724 9.29016 12.4865 7.08782 12.8277C6.78705 12.8743 6.50328 12.6815 6.45399 12.3972C6.40471 12.1128 6.60858 11.8445 6.90934 11.7979C9.23443 11.4377 11.095 11.5257 12.3833 11.7076C12.8026 11.7668 13.1611 11.836 13.4551 11.9027C13.5475 11.9237 13.6428 11.8787 13.6816 11.7923C13.8558 11.4035 14.0526 10.8777 14.2094 10.2257C15.2395 5.28623 14.2094 1.73817 14.2094 1.73817C14.2094 1.73817 14.2094 1.08735 12.963 0.58194C12.836 0.530419 12.7009 0.626902 12.7009 0.764004V2.85128C12.7009 3.13945 12.4539 3.37306 12.1491 3.37306C11.8443 3.37306 11.5972 3.13945 11.5972 2.85128V0.377044Z"
      fill={theme.tokens.colors['sport-icon']['sport-popMma']}
    />
  )
}

export const IconSportMMA: FC<IIconsSportNameProps> = ({ sport, ...props }) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'

import { useAppDispatch } from 'shared/lib/@reduxjs'

import {
  ECurrentCashoutModalContent,
  ICurrentCashoutModalContent,
  IGetCurrentCashoutModalContent
} from './CurrentCashoutModal.types'
import {
  StyledCurrentCashoutButton,
  StyledCurrentCashoutButtonWrapper,
  StyledCurrentCashoutModalContentWrapper,
  StyledCurrentCashoutText
} from './CurrentCashoutModal.styled'

export const NEW_MAKE_CASHOUT_LS = 'newMakeCashout'

export const getCurrentCashoutModalContent = ({
  cashoutError,
  cashout,
  isCashoutAmountHasChanged,
  setCurrentCashout,
  cashoutAmount,
  number
}: IGetCurrentCashoutModalContent) => {
  if (cashoutError) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.SELL_BET_ERROR}
        number={number}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else if (cashout?.outdated) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.SELL_NOT_POSSIBLE}
        number={number}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else if (isCashoutAmountHasChanged) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED}
        number={number}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.ACTIVE_SELL}
        number={number}
        setCurrentCashout={setCurrentCashout}
      />
    )
  }
}

export const CurrentCashoutModalContent = ({
  cashoutAmount,
  contentType,
  number
}: ICurrentCashoutModalContent) => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const makeCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.makeCashout())
  }

  const sellingBetIdText = `${t('selling a bet')} #${number}`
  const cashoutError = `${sellingBetIdText} ${t('bet unavailable')}`

  if (contentType === ECurrentCashoutModalContent.ACTIVE_SELL) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>{cashoutAmount}</StyledCurrentCashoutText>

        <StyledCurrentCashoutButtonWrapper>
          <StyledCurrentCashoutButton onClick={makeCashout}>
            {t('yes')}
          </StyledCurrentCashoutButton>
          <StyledCurrentCashoutButton
            view="secondary-large"
            onClick={unsetCurrentCashout}
          >
            {t('no')}
          </StyledCurrentCashoutButton>
        </StyledCurrentCashoutButtonWrapper>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>
          {t('bet amount has changed')}. {cashoutAmount}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButtonWrapper>
          <StyledCurrentCashoutButton onClick={makeCashout}>
            {t('yes')}
          </StyledCurrentCashoutButton>
          <StyledCurrentCashoutButton
            view="secondary-large"
            onClick={unsetCurrentCashout}
          >
            {t('no')}
          </StyledCurrentCashoutButton>
        </StyledCurrentCashoutButtonWrapper>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_NOT_POSSIBLE) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>
          {t('sell bet is not possible')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton onClick={unsetCurrentCashout}>
          {t('close')}
        </StyledCurrentCashoutButton>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_BET_ERROR) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>
          {t(cashoutError ?? 'error')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton onClick={unsetCurrentCashout}>
          {t('close')}
        </StyledCurrentCashoutButton>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else {
    return null
  }
}

import { LogoEntityType } from 'betweb-openapi-axios/dist/api'
import keyBy from 'lodash/keyBy'

import { createSlice, getPreloadedState } from 'shared/lib/@reduxjs'

import { getLogos } from './actions'
import { LogosContainerState } from './types'

const REDUCER_KEY = 'logosContainer'

export const initialState: LogosContainerState = getPreloadedState(
  REDUCER_KEY,
  {
    entries: {
      [LogoEntityType.Player]: {},
      [LogoEntityType.Competitor]: {},
      [LogoEntityType.Tournament]: {}
    },
    requested: {
      [LogoEntityType.Player]: {},
      [LogoEntityType.Competitor]: {},
      [LogoEntityType.Tournament]: {}
    }
  }
)

export const logosSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLogos.fulfilled, (state, action) => {
      state.entries[action.payload.entityType] = {
        ...state.entries[action.payload.entityType],
        ...keyBy(action.payload.items, (item) => item.entityId)
      }
      action.payload.items.forEach((id) => {
        state.requested[action.payload.entityType][id.entityId] = true
      })
    })
  }
})

export const { reducer: logosReducer, name: sliceKey } = logosSlice

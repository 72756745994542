import { getCurrentLng } from 'astra-core'

import { i18nLocalToLegacyLang } from 'shared/lib/locale'

import { baseLegacyApi } from '../api'

import {
  GetCashoutRequest,
  GetCashoutResponse,
  SaveCashoutRequest,
  SaveCashoutResponse
} from './cashout.types'

export const cashoutApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getCashout: builder.query<GetCashoutResponse, GetCashoutRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/cashout/get_cashout',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    }),
    saveCashout: builder.mutation<SaveCashoutResponse, SaveCashoutRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/cashout/save_cashout',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    })
  })
})

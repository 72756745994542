import { useMediaQuery } from 'react-responsive'

import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

export const queryMedia = {
  tabletOrMobile: 'only screen and (max-width: 1023px)',
  small: 'only screen and (min-width: 1024px) and (max-width: 1439px)',
  medium: 'only screen and (min-width: 1440px) and (max-width: 1919px)',
  large: 'only screen and (min-width: 1920px)'
}

export const customMedia = {
  tabletOrMobile: `@media ${queryMedia.tabletOrMobile}`,
  small: `@media ${queryMedia.small}`,
  medium: `@media ${queryMedia.medium}`,
  large: `@media ${queryMedia.large}`
}

export const combineMedias = (medias: (keyof typeof queryMedia)[]) =>
  `@media ${medias.map((media) => queryMedia[media])}`

export const useIsTabletOrMobile = () => {
  const hydrated = useHydrated()

  const queryResult = useMediaQuery({
    query: queryMedia.tabletOrMobile
  })

  return hydrated && queryResult
}

export const useIsSmall = () => {
  const hydrated = useHydrated()

  const queryResult = useMediaQuery({
    query: queryMedia.small
  })

  return hydrated && queryResult
}

export const useIsMedium = () => {
  const hydrated = useHydrated()

  const queryResult = useMediaQuery({
    query: queryMedia.medium
  })

  return hydrated && queryResult
}

export const useIsLarge = () => {
  const hydrated = useHydrated()

  const queryResult = useMediaQuery({
    query: queryMedia.large
  })

  return hydrated && queryResult
}

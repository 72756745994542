import {
  LineTournamentFilterPeriod,
  TFormatLineTournamentsFiltersParams
} from './types'

interface GetFetchLineTournamentsReqDataParams {
  sportId: string | number
  filterPeriod?: LineTournamentFilterPeriod
}

export const getFetchLineTournamentsReqData = ({
  sportId,
  filterPeriod
}: GetFetchLineTournamentsReqDataParams) => ({
  live: false,
  sportId: +sportId,
  ...filterPeriod
})

export const LINE_TOURNAMENTS_POLLING_INTERVAL = 5000

export const formatLineTournamentsFiltersData = ({
  period
}: TFormatLineTournamentsFiltersParams) => ({
  filterPeriod: period
})

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCloseOutline16: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.64649 3.64641C4.03701 3.25588 4.67017 3.25588 5.0607 3.64641L8.35359 6.9393L11.6465 3.64641C12.037 3.25588 12.6702 3.25588 13.0607 3.64641C13.4512 4.03693 13.4512 4.6701 13.0607 5.06062L9.76781 8.35352L13.0607 11.6464C13.4512 12.0369 13.4512 12.6701 13.0607 13.0606C12.6702 13.4511 12.037 13.4511 11.6465 13.0606L8.35359 9.76773L5.0607 13.0606C4.67017 13.4511 4.03701 13.4511 3.64649 13.0606C3.25596 12.6701 3.25596 12.0369 3.64649 11.6464L6.93938 8.35352L3.64649 5.06062C3.25596 4.6701 3.25596 4.03693 3.64649 3.64641Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M11.1696 20.527C11.5258 20.9038 11.8043 20.9977 11.9929 21H12.0071C12.1957 20.9977 12.4742 20.9038 12.8304 20.527C13.1932 20.1435 13.5659 19.5348 13.9 18.6997C14.1198 18.1501 14.3144 17.5238 14.4764 16.8355C13.6811 16.9432 12.8522 17 12.0016 17C11.1498 17 10.3198 16.9431 9.52349 16.835C9.68555 17.5236 9.88017 18.15 10.1 18.6997C10.4341 19.5348 10.8068 20.1435 11.1696 20.527Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M9.31192 15.7934C9.11293 14.6424 9 13.36 9 12C9 11.2288 9.03631 10.4826 9.10403 9.77115C10.0086 9.91965 10.9855 9.99999 12.0013 9.99999C13.0159 9.99999 13.9913 9.91341 14.8945 9.75537C14.9632 10.4716 15 11.2231 15 12C15 13.3602 14.887 14.6428 14.688 15.7939C13.8369 15.9278 12.9359 16 12.0016 16C11.0662 16 10.164 15.9276 9.31192 15.7934Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M15.5407 16.6571C15.171 18.4101 14.5991 19.8552 13.9026 20.7985C17.1623 20.0969 19.7658 17.63 20.6631 14.4477C20.2838 14.7543 19.8606 15.0358 19.4039 15.2895C18.3138 15.8952 16.9985 16.3645 15.5407 16.6571Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M20.9725 12.709C20.5155 13.3304 19.8248 13.9118 18.9183 14.4154C18.0225 14.9131 16.9422 15.3174 15.734 15.5931C15.9058 14.4779 16 13.2665 16 12C16 11.1541 15.958 10.3326 15.8789 9.54809C16.5744 9.3755 17.2127 9.15827 17.7752 8.90485C18.5252 8.56696 19.162 8.1551 19.6183 7.67932C19.678 7.6171 19.735 7.55331 19.789 7.48801C20.5591 8.81449 21 10.3557 21 12C21 12.2386 20.9907 12.475 20.9725 12.709Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M8.26585 15.5923C7.05901 15.3166 5.98 14.9126 5.08501 14.4154C4.17602 13.9104 3.48405 13.3273 3.02713 12.704C3.00915 12.4717 3 12.2369 3 12C3 10.3817 3.42711 8.86315 4.17476 7.55095C4.24154 7.63316 4.31328 7.71286 4.38932 7.78991C4.84775 8.25442 5.48631 8.64979 6.23592 8.97105C6.79486 9.2106 7.42837 9.41442 8.11833 9.57591C8.04103 10.352 8 11.164 8 12C8 13.2662 8.09413 14.4774 8.26585 15.5923Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M3.33589 14.4443C3.71635 14.7521 4.14095 15.0349 4.59937 15.2895C5.68862 15.8947 7.00275 16.3638 8.45919 16.6564C8.82886 18.4097 9.40078 19.855 10.0973 20.7985C6.83656 20.0967 4.23232 17.6282 3.33589 14.4443Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M10.1001 5.30032C9.71867 6.25378 9.41329 7.43807 9.22196 8.77706C10.0783 8.9203 11.0156 8.99999 12.0013 8.99999C12.9849 8.99999 13.9205 8.9139 14.7757 8.76085C14.5842 7.4285 14.2798 6.24993 13.9 5.30032C13.5659 4.46523 13.1932 3.85653 12.8304 3.47296C12.4697 3.0915 12.1887 3 12 3C11.8113 3 11.5303 3.0915 11.1696 3.47296C10.8068 3.85653 10.4341 4.46523 10.1001 5.30032Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M13.9026 3.20144C14.753 4.35316 15.4176 6.25295 15.7552 8.54783C16.3498 8.3941 16.8909 8.20644 17.3644 7.9931C18.0395 7.68899 18.556 7.34235 18.8966 6.98713C19.0244 6.85394 19.1239 6.72357 19.1983 6.59685C17.9204 4.89706 16.0545 3.66455 13.9026 3.20144Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M6.62984 8.05191C7.10363 8.25496 7.64521 8.43232 8.24048 8.57699C8.57678 6.26905 9.24343 4.35804 10.0974 3.20144C7.92517 3.66893 6.04428 4.92046 4.76554 6.64525C4.84201 6.78881 4.95212 6.93655 5.10107 7.08748C5.43964 7.43053 5.95434 7.76241 6.62984 8.05191Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M6.066 13.2096C6.73671 14.5511 7.47826 15 8 15C8.52174 15 9.26329 14.5511 9.934 13.2096C10.1464 12.7849 10.3351 12.2987 10.4925 11.7625C9.7023 11.9173 8.86493 12 8 12C7.14174 12 6.30913 11.9362 5.52217 11.8119C5.67657 12.3286 5.86016 12.798 6.066 13.2096Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M5.2608 10.7513C5.09422 9.91061 5 8.98268 5 8C5 7.5955 5.01596 7.20028 5.04637 6.81661C5.95612 6.93965 6.95721 7 8.00122 7C9.04345 7 10.0404 6.90516 10.9467 6.73172C10.9816 7.14191 11 7.5656 11 8C11 8.95599 10.9108 9.86017 10.7526 10.6826C9.90296 10.8867 8.97509 11 8 11C7.02836 11 6.10554 10.9134 5.2608 10.7513Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M11.6005 11.4893C11.2912 12.7637 10.8177 13.8491 10.2351 14.6356C12.4271 13.8975 14.1339 12.1037 14.7506 9.8586C14.4951 10.0739 14.2175 10.2747 13.9219 10.4595C13.2452 10.8824 12.4612 11.2312 11.6005 11.4893Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M14.9993 8.10235C14.6426 8.65462 14.1009 9.16838 13.3919 9.61154C12.9285 9.90114 12.4011 10.1549 11.8226 10.3635C11.9379 9.61652 12 8.82277 12 8C12 7.48837 11.976 6.98796 11.9301 6.50303C12.3682 6.38165 12.7763 6.24015 13.1479 6.08089C13.6514 5.86511 14.1008 5.61118 14.4688 5.32036C14.8111 6.14575 15 7.05081 15 8C15 8.03417 14.9998 8.06829 14.9993 8.10235Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M4.19848 10.4957C3.59964 10.3199 3.05566 10.1014 2.58026 9.84533C1.89606 9.47678 1.37265 9.04312 1.02198 8.55882C1.00742 8.37446 1 8.1881 1 8C1 7.16494 1.14622 6.36404 1.41445 5.6215C1.81526 5.92026 2.32411 6.15787 2.89483 6.34615C3.25071 6.46355 3.64002 6.56478 4.0567 6.65012C4.01942 7.08894 4 7.53994 4 8C4 8.87147 4.06967 9.71039 4.19848 10.4957Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M1.37792 10.2748C1.60563 10.437 1.84925 10.5874 2.10603 10.7257C2.78356 11.0907 3.56697 11.3811 4.4249 11.592C4.73445 12.822 5.19802 13.8703 5.76495 14.6356C3.71009 13.9437 2.08175 12.3241 1.37792 10.2748Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M6.066 2.79036C5.65968 3.60301 5.34004 4.64036 5.16052 5.82294C6.01664 5.93979 6.97798 6 8.00122 6C9.01665 6 9.97331 5.90454 10.8261 5.73646C10.6443 4.58977 10.3304 3.58314 9.934 2.79036C9.26329 1.44895 8.52174 1 8 1C7.47826 1 6.73671 1.44895 6.066 2.79036Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M10.2351 1.3644C10.9634 2.34758 11.5211 3.79774 11.8008 5.50012C12.1448 5.39956 12.4639 5.28607 12.754 5.16175C13.293 4.93077 13.7129 4.67045 14.006 4.40236C13.1573 2.98863 11.8236 1.89928 10.2351 1.3644Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
      <path
        d="M3.20812 5.39649C3.50191 5.49341 3.82554 5.57913 4.17485 5.65329C4.44577 3.88476 5.0149 2.37694 5.76495 1.3644C4.07566 1.93318 2.67463 3.12896 1.83854 4.67504C1.85248 4.68815 1.8669 4.70128 1.88182 4.71443C2.16595 4.96472 2.60849 5.19868 3.20812 5.39649Z"
        fill={theme.tokens.colors['sport-icon']['sport-allSports']}
      />
    </>
  )
}

export const IconSportAllSports: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'
import { typography } from 'shared/lib/theme/typography'
import { StyledIcon } from 'shared/ui/Icon'

export const StyledBasketButtonSmallWindowWrapper = styled.div<{
  isSmall?: boolean
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  ${({ theme, isSmall }) =>
    css`
      background-color: ${theme.tokens.colors.surface['surface-active2']};
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      color: ${theme.tokens.colors.text['text-invert']};
      gap: ${theme.tokens.alias.space['space-xs-8']}px;
      outline: invert 1px solid ${theme.tokens.colors.border['border-primary']};
      outline: 1px solid ${theme.tokens.colors.border['border-primary']};
      outline-offset: -1px;

      ${isSmall
        ? css`
            padding: ${theme.tokens.alias.space['space-xxs-6']}px
              ${theme.tokens.alias.space['space-xs-8']}px
              ${theme.tokens.alias.space['space-xxs-6']}px
              ${theme.tokens.alias.space['space-s-12']}px;
          `
        : css`
            padding: ${theme.tokens.alias.space['space-xs-8']}px
              ${theme.tokens.alias.space['space-xs-8']}px
              ${theme.tokens.alias.space['space-xs-8']}px
              ${theme.tokens.alias.space['space-s-12']}px;
          `}
    `}

  ${customMedia.small} {
    display: flex;
  }
`

export const StyledBasketButtonSmallWindowText = styled.div`
  ${typography['Body / 14 Bold']}
`

export const StyledBasketButtonSmallEventCounter = styled.div`
  color: #6c799333;
  display: flex;

  & ${StyledIcon} {
    height: 20px;
    width: 4px;
  }
`

export const StyledBasketButtonSmallEventRect = styled.div`
  ${typography['Body / 12 ExtraBold']}
  ${({ theme }) => css`
    align-items: center;
    background: #6c799333;
    color: ${theme.tokens.colors.text['text-invert']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    height: 20px;
    padding: 0px ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledDivideLine = styled.div`
  ${({ theme }) => css`
    background: #6c799333;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xxs-2'
    ]}px;
    height: 8px;
    width: 1px;
  `}
`

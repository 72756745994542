import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconChevronDoubleUp: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M12.7071 7.70711C12.3166 8.09763 11.6834 8.09763 11.2929 7.70711L8 4.41421L4.70711 7.70711C4.31658 8.09763 3.68342 8.09763 3.29289 7.70711C2.90237 7.31658 2.90237 6.68342 3.29289 6.29289L7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289L12.7071 6.29289C13.0976 6.68342 13.0976 7.31658 12.7071 7.70711Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M12.7071 13.7071C12.3166 14.0976 11.6834 14.0976 11.2929 13.7071L8 10.4142L4.70711 13.7071C4.31658 14.0976 3.68342 14.0976 3.29289 13.7071C2.90237 13.3166 2.90237 12.6834 3.29289 12.2929L7.29289 8.29289C7.68342 7.90237 8.31658 7.90237 8.70711 8.29289L12.7071 12.2929C13.0976 12.6834 13.0976 13.3166 12.7071 13.7071Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

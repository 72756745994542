import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBetsHistory: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.33 3C15.2251 3 16.0835 3.35558 16.7164 3.98851L20.0115 7.28356C20.6444 7.9165 21 8.77494 21 9.67005V14.33C21 15.2251 20.6444 16.0835 20.0115 16.7164L16.7164 20.0115C16.0835 20.6444 15.2251 21 14.33 21H9.67005C8.77495 21 7.9165 20.6444 7.28356 20.0115L3.98852 16.7164C3.35558 16.0835 3 15.2251 3 14.33V9.67005C3 8.77495 3.35558 7.9165 3.98851 7.28356L7.28356 3.98852C7.9165 3.35558 8.77494 3 9.67005 3H14.33ZM12 7.75C12.5523 7.75 13 8.19772 13 8.75V11.8358L15.7071 14.5429C16.0976 14.9334 16.0976 15.5666 15.7071 15.9571C15.3166 16.3476 14.6834 16.3476 14.2929 15.9571L11.2929 12.9571C11.1054 12.7696 11 12.5152 11 12.25V8.75C11 8.19772 11.4477 7.75 12 7.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { selectMakeBetErrorsBottom } from 'astra-core/containers/BasketProvider'
import isEqual from 'react-fast-compare'

import { BasketError } from 'entities/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const ErrorMakeBetList = () => {
  const errorsBottom = useAppSelector(selectMakeBetErrorsBottom, isEqual) // Rendered without isEqual

  return errorsBottom.length ? (
    <>
      {errorsBottom.map((error) => (
        <BasketError key={error.code} message={error.message} />
      ))}
    </>
  ) : null
}

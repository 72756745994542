import { baseLegacyApi } from '../api'

import {
  UserPasswordChangeRequest,
  UserPasswordChangeResponse
} from './change-password.types'

export const changePasswordApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation<
      UserPasswordChangeResponse,
      UserPasswordChangeRequest
    >({
      query: (args) => ({
        method: 'POST',
        url: '/user/passchange',
        body: { ...args, session: '__session__', platforma: 'NEWOLIMPBET' }
      })
    })
  })
})

import {
  ECashoutStatusView,
  selectCashoutStatusView,
  selectCurrentCashout
} from 'astra-core/containers/BetsHistoryProvider'
import { useEffect } from 'react'

import { useAppSelector } from 'shared/lib/@reduxjs'
import {
  usePopUpNotification,
  ENotificationContainerIds
} from 'shared/lib/Toastify'
import { ToastifyCashoutNotification } from 'widgets/bets-history/components/Cashout'
import { TOASTIFY_CASHOUT_ID } from 'widgets/bets-history/components/Cashout/components/ToastifyCashoutNotification/WithdrawalFormMethods.types'

export const useToastifyCashoutModal = () => {
  const cashoutStatusView = useAppSelector(selectCashoutStatusView)
  const currentCashout = useAppSelector(selectCurrentCashout)

  useEffect(() => {
    if (cashoutStatusView !== ECashoutStatusView.NONE && !currentCashout) {
      setToastifyNotifyCashout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashoutStatusView])

  const setToastifyNotifyCashout = usePopUpNotification({
    content: {
      children: (props) => <ToastifyCashoutNotification {...props} />
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: false,
      toastId: TOASTIFY_CASHOUT_ID
    }
  })

  return null
}

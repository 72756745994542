import React, { FC } from 'react'

import {
  IconButtonProps,
  IconButtonSize,
  IconButtonType
} from './IconButton.types'
import { StyledIconButton } from './IconButton.styled'

export const IconButton: FC<IconButtonProps> = ({
  view,
  icon: Icon,
  iconColorToken = 'icon-secondary-2',
  counter,
  isActive,
  ...props
}) => {
  const [viewType, size] = view.split('-') as [IconButtonType, IconButtonSize]

  const iconProps = {
    size: view === 'ghost-l' ? 24 : 16,
    twist: props.twist
  }

  return (
    <StyledIconButton
      isActive={isActive}
      isCounter={!!counter}
      size={size}
      viewType={viewType}
      {...props}
    >
      <Icon {...iconProps} colorToken={iconColorToken} />
      {counter}
    </StyledIconButton>
  )
}

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCloseOutline12: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.17574 3.17574C3.41005 2.94142 3.78995 2.94142 4.02426 3.17574L6 5.15147L7.97574 3.17574C8.21005 2.94142 8.58995 2.94142 8.82426 3.17574C9.05858 3.41005 9.05858 3.78995 8.82426 4.02426L6.84853 6L8.82426 7.97574C9.05858 8.21005 9.05858 8.58995 8.82426 8.82426C8.58995 9.05858 8.21005 9.05858 7.97574 8.82426L6 6.84853L4.02426 8.82426C3.78995 9.05858 3.41005 9.05858 3.17574 8.82426C2.94142 8.58995 2.94142 8.21005 3.17574 7.97574L5.15147 6L3.17574 4.02426C2.94142 3.78995 2.94142 3.41005 3.17574 3.17574Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

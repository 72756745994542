import React, { FC } from 'react'

import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'

import { OrdinarCard } from '../card'
import { OrdinarProps } from '../ordinar/ordinar.types'
import { Styles } from '../../basket'

export const CardListOrdinar: FC<OrdinarProps> = ({ outcomes }) => (
  <Styles.StyledBasketBodyList>
    <Styles.StyledBetGroupsList>
      <BasketTooltipProvider>
        <Styles.StyledBetCardList>
          {outcomes.map((outcome) => (
            <OrdinarCard key={outcome.id} outcome={outcome} />
          ))}
        </Styles.StyledBetCardList>
      </BasketTooltipProvider>
    </Styles.StyledBetGroupsList>
  </Styles.StyledBasketBodyList>
)

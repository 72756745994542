import { selectBasketAppliedCoupon } from 'astra-core/containers/BasketProvider'

import {
  BasketBonusCouponCanUseList,
  useGetSuitableCouponsForBetTypeTab
} from 'entities/basket-bonus-coupon'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { BonusCouponCanUse } from './bonus-coupon-can-use'

// List of bonus coupons that have passed the terms of use and can be used
export const BonusCouponCanUseList = () => {
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)

  const isAppliedCoupon = localStorage.getItem('appliedCoupon')

  const suitableCoupons = useGetSuitableCouponsForBetTypeTab()

  return suitableCoupons.length ? (
    <BasketBonusCouponCanUseList
      CouponList={suitableCoupons.map((coupon) => (
        <div>
          <BonusCouponCanUse key={coupon.id} {...coupon} />
        </div>
      ))}
      isDisplayAlertInfo={!appliedCoupon && !isAppliedCoupon}
    />
  ) : null
}

import { FC, useCallback, useMemo } from 'react'
import {
  basketProviderActions,
  selectCountSystemCombinations,
  selectSystemSize
} from 'astra-core/containers/BasketProvider'

import { TabFill, TabsFill } from 'shared/ui/tabs-fill'
import { onFormatSystemCombinationsCount } from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const TabsCombinations: FC = () => {
  const dispatch = useAppDispatch()
  const combinations = useAppSelector(selectCountSystemCombinations)
  const systemSize = useAppSelector(selectSystemSize)

  const formattedCountSystemCombinations = useMemo(
    () =>
      onFormatSystemCombinationsCount({
        combinations
      }),
    [combinations]
  )

  const handleSelectSystemSize = useCallback(
    (value: number) => {
      dispatch(basketProviderActions.setSystemSize(value))
    },
    [dispatch]
  )

  const selectedSystemSize = useMemo(
    () =>
      formattedCountSystemCombinations.find(
        (combination) => combination.value === systemSize
      ),
    [formattedCountSystemCombinations, systemSize]
  )

  return (
    <TabsFill>
      {formattedCountSystemCombinations.map((item) => (
        <TabFill
          isActive={selectedSystemSize?.value === item.value}
          tabIndex={item.value}
          type="white"
          onClick={() => handleSelectSystemSize(item.value)}
        >
          {item.label}
        </TabFill>
      ))}
    </TabsFill>
  )
}

import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'

export const MessageNothingFound = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      text-align: center;
    }

    span:first-child {
      margin-bottom: ${theme.tokens.alias.space['space-xxxs-4']}px;
    }
  `}
`

export const ButtonReset = styled(Button)`
  ${({ theme }) => css`
    margin-top: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

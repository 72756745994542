import React, { FC, memo } from 'react'

import {
  EInputIconPositionSides,
  EInputSize,
  EInputView,
  InputProps
} from './types'
import { StyledInput, StyledInputWrapper } from './styled'
import { ICON_PROPS } from './constants'

export const Input: FC<InputProps> = memo(
  ({
    customSize = EInputSize.L,
    view = EInputView.PRIMARY,
    icon: Icon = null,
    iconColor,
    inputRef,
    iconProps,
    testId,
    ...props
  }) => {
    if (iconProps?.hideIcon) {
      Icon = null
    }
    const iconRight = iconProps?.positionSide === EInputIconPositionSides.Right

    return (
      <StyledInputWrapper
        customSize={customSize}
        iconColor={iconColor}
        iconRight={iconRight}
      >
        {!!Icon && !iconRight && <Icon {...ICON_PROPS} {...iconProps} />}

        <StyledInput
          customSize={customSize}
          icon={Icon ?? undefined}
          iconRight={iconRight}
          id={testId}
          ref={inputRef}
          view={view}
          {...props}
        />
        {!!Icon && iconRight && <Icon {...ICON_PROPS} {...iconProps} />}
      </StyledInputWrapper>
    )
  }
)

import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import {
  selectFilterIsTop,
  selectLayoutIsLoading,
  selectLiveEventsBySearch
} from 'entities/live/model/selectors'
import { liveContainerActions } from 'entities/live/model/slice'
import { LiveEventsParams } from 'entities/live/model/types'
import { RootState } from 'shared/types/store'
import { useIsEventListHasTopMemo } from 'entities/event'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const LiveTopFilter = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isTop = useAppSelector(selectFilterIsTop)
  const { sportId } = useParams<LiveEventsParams>()
  const isLayoutIsLoading = useAppSelector(selectLayoutIsLoading)

  const handleSelector = useCallback(
    (state: RootState) => selectLiveEventsBySearch(state, { sportId }),
    [sportId]
  )

  const isEventListHasTop = useIsEventListHasTopMemo(handleSelector)

  const toggleFilterTop = useCallback(
    () => dispatch(liveContainerActions.toggleFilterIsTop()),
    [dispatch]
  )

  useEffect(() => {
    if (isTop && !isEventListHasTop && !isLayoutIsLoading) {
      toggleFilterTop()
    }
  }, [isTop, isEventListHasTop, toggleFilterTop, dispatch, isLayoutIsLoading])

  return (
    <Button
      disabled={!isEventListHasTop || isLayoutIsLoading}
      icon={IconTop}
      isActive={isTop}
      view="filter"
      onClick={toggleFilterTop}
    >
      {t('only top')}
    </Button>
  )
}

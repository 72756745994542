import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { StyledTypographyProps } from './typography.types'

export const StyledTypography = styled.span<StyledTypographyProps>`
  ${({ lineClamp = 0, theme, fontName, colorToken }) => css`
    color: ${theme.tokens.colors.text[colorToken]};
    ${typography[fontName]};
    ${lineClamp === 1 &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
    ${lineClamp > 1 &&
    css`
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${lineClamp};
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  `}
`

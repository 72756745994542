import styled, { css } from 'styled-components'

import { MAIN_HEADER_HEIGHT } from 'shared/lib/layout'

export const StyledNotifications = styled.main`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-left: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100% - ${MAIN_HEADER_HEIGHT}px);
  padding: 10px 8px;
  position: absolute;
  right: 0;
  top: ${MAIN_HEADER_HEIGHT}px;
  width: 340px;
  z-index: 100;
`
export const StyledNotificationsHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  padding: 19px 8px;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const StyledLastTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.fixed.primary[50]};
    cursor: pointer;
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    justify-self: end;
    margin-right: 14px;
  `};
`

export const StyledModalTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.bold};
    margin-right: auto;
  `};
`

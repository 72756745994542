import styled, { css } from 'styled-components'

export const StyledToastifyContent = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  ${({ theme }) =>
    css`
      color: ${theme.tokens.colors.text['text-invert']};
      gap: ${theme.tokens.alias.space['space-xs-8']}px;
    `};
`

export const StyledToastifyContentRight = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`

export const StyledToastifyContentLeft = styled.div`
  align-items: center;
  display: flex;
`

export const StyledToastifyContentMain = styled.div`
  flex-grow: 1;
  width: 0;
`

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const InputWrapper = styled.div`
  margin-bottom: 4px;
  padding: 4px 16px;
  position: relative;
`
export const TotalAmount = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-xxs-6']}px;
    position: relative;

    &:after {
      background: ${theme.tokens.colors.border['border-hover']};
      bottom: 0px;
      content: '';
      height: 1px;
      left: -8px;
      position: absolute;
      width: calc(100% + 16px);
    }
  `}
`

export const TotalAmountText = styled.div`
  ${typography['Body / 12 Regular']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const TotalAmountValue = styled.div`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const TotalAmountValueWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`

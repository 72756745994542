import styled from 'styled-components'

import { Button } from 'shared/ui/Button'
import { typography } from 'shared/lib/theme/typography'

export const StyledBasketHistoryCashoutWrapper = styled.div`
  margin-top: 4px;
  position: relative;
`

export const StyledBasketHistoryCashoutButtonTextWrapper = styled(Button)`
  display: flex;
  gap: 2px;
  justify-content: center;
  width: 100%;

  ${typography['Hint / 10 BOLD']}
`

export const StyledBasketHistoryCashoutModalWholeBackground = styled.div`
  cursor: default;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
`

export const StyledBasketHistoryCashoutButtonCurrency = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;

  ${typography['Hint / 10 BOLD']}
`

import {
  BroadcastAvailability,
  EventsApiGetEventsRequest,
  Event
} from 'betweb-openapi-axios'

interface GetFetchLiveReqDataParams {
  sportId: string | number
  tagIds?: EventsApiGetEventsRequest['tagIds']
}

export const getFetchLiveReqData = ({
  sportId,
  tagIds
}: GetFetchLiveReqDataParams) => ({
  tagIds,
  sportId: +sportId,
  live: true
})

export const getFilteredEventsBroadcast = (
  events: Dictionary<Event>,
  broadcasts: Dictionary<BroadcastAvailability>
) =>
  Object.keys(events)
    .filter((key) => broadcasts[key] && broadcasts[key].available)
    .reduce((acc, key) => {
      acc[key] = events[key]

      return acc
    }, {})

export const LIVE_POLLING_INTERVAL = 2000
export const BROADCAST_POLLING_INTERVAL = 60000
export const BROADCAST_CHUNK_SIZE = 100

import { put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { TagFilterOperation } from 'betweb-openapi-axios'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { liveContainerActions } from './slice'
import { FetchLivePayload } from './types'

const SOURCE_KEY = 'liveEvents'

export function* fetchLiveSaga(action: PayloadAction<FetchLivePayload>) {
  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        ...action.payload,
        tagFilterOperation: TagFilterOperation.Loose
      },
      sideEffects: eventsProviderSideEffects[SOURCE_KEY],
      params: {
        withCompetitorsLogo: false,
        withLinkedEvents: false,
        includeSubsports: true
      }
    })
  )
}

export function* liveContainerSaga() {
  yield takeLatest(liveContainerActions.fetchLive.type, fetchLiveSaga)
}

import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { EAdditionalSportsCodes } from 'shared/ui/Icon/sport-icon/sport-icon.types'

export interface SportsMenuProps {}
export interface StyledSportsMenuItemProps {
  sport: ESportsCodes | EAdditionalSportsCodes
  $isActive?: boolean
}

export enum ESportsMenuTabTypes {
  LINE = 'line',
  LIVE = 'live'
}

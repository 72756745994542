import styled, { css } from 'styled-components'

import { StyledIconStateProps } from './icon-state.types'

export const StyledIconState = styled.button<StyledIconStateProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;

  ${({ theme, size, isActive }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    transition: background-color 0.25s ease;

    svg path {
      transition: fill 0.25s ease;
    }

    &:hover {
      svg path {
        fill: ${theme.tokens.colors.icon['icon-secondary']};
      }
    }

    ${size === 's' &&
    css`
      height: 12px;
      width: 12px;
    `}

    ${size === 'm' &&
    css`
      height: 16px;
      width: 16px;
    `}

    ${size === 'l' &&
    css`
      height: 24px;
      width: 24px;
    `}

    ${isActive &&
    css`
      &:hover {
        svg path {
          fill: ${theme.tokens.colors.icon['icon-primary-2']};
        }
      }
      svg path {
        fill: ${theme.tokens.colors.icon['icon-primary-2']};
      }
    `}
    
    &:disabled, &:disabled:hover, &:disabled:active {
      svg path {
        fill: ${theme.tokens.colors.icon['icon-disabled']};
      }
    }
  `}
`

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconTop: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.02 6.96441L12.51 7.47223V7.46228C12.82 7.77095 13 8.19912 13 8.63724V11.3854C13 11.8335 12.83 12.2418 12.51 12.5604L10.55 14.512C10.24 14.8207 9.81 14.9999 9.37 14.9999H6.61C6.16 14.9999 5.75 14.8307 5.43 14.512L3.49 12.5704C3.18 12.2617 3 11.8335 3 11.3954V8.64719C3 8.60604 3.00534 8.56754 3.0105 8.53032C3.01533 8.49551 3.02 8.46182 3.02 8.42813C3.13 7.11377 3.78 5.95873 4.76 5.1721C4.9693 6.46424 5.65386 7.6142 6.76 8.34848C5.92709 5.69454 6.94256 2.68921 9.15 1C9.37 3.33 10.43 5.42103 12.02 6.96441ZM9.15224 10.7003L9.29268 10.8456V10.8428C9.38154 10.9311 9.43313 11.0536 9.43313 11.179V11.9656C9.43313 12.0938 9.3844 12.2106 9.29268 12.3018L8.7309 12.8604C8.64204 12.9487 8.51879 13 8.39268 13H7.60159C7.47261 13 7.35509 12.9516 7.26337 12.8604L6.70732 12.3047C6.61846 12.2163 6.56687 12.0938 6.56687 11.9684V11.1819C6.56687 11.1701 6.5684 11.1591 6.56988 11.1484C6.57126 11.1385 6.5726 11.1288 6.5726 11.1192C6.60413 10.743 6.79044 10.4125 7.07133 10.1873C7.13132 10.5571 7.32754 10.8862 7.64458 11.0964C7.40585 10.3369 7.69691 9.47674 8.32962 8.9933C8.39268 9.66013 8.6965 10.2586 9.15224 10.7003Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { GetHistoryFetch } from 'entities/legacy-api/lib/transaction-history'

export type ProviderState = {
  data: {
    transactionCollection: StoreTransactionList
  }
  layout: {
    activeBalanceOperationTypeTab: TransactionHistoryBalanceOperationsTypes
  }
}

type GetHistoryFetchList = GetHistoryFetch['response']['data']['list'][0]
export type StoreTransactionList = Record<
  string,
  Pick<
    GetHistoryFetchList,
    'id' | 'data' | 'status' | 'sum' | 'is_back' | 'reason' | 'info'
  >
>

export interface ProviderReducerPayloads {
  setBalanceOperationTypeTab: TransactionHistoryBalanceOperationsTypes
}

export enum TransactionHistoryBalanceOperationsTypes {
  ALL = 'all',
  TOP_UP = 'top_up',
  WITHDRAWAL = 'withdrawal'
}

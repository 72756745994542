import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSearch: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.52292 1C4.18344 1 3.85786 1.13486 3.61781 1.37491L1.37491 3.61781C1.13486 3.85786 1 4.18344 1 4.52292V9.4108C1 9.75028 1.13486 10.0759 1.37491 10.3159L3.61781 12.5588C3.85786 12.7989 4.18344 12.9337 4.52292 12.9337H9.4108C9.75028 12.9337 10.0759 12.7989 10.3159 12.5588L10.7961 12.0786L13.0767 14.756C13.3394 15.0644 13.8094 15.0832 14.096 14.7967L14.7967 14.096C15.0832 13.8095 15.0644 13.3395 14.756 13.0767L12.0786 10.7961L12.5588 10.3159C12.7989 10.0759 12.9337 9.75028 12.9337 9.4108V4.52292C12.9337 4.18344 12.7989 3.85786 12.5588 3.61781L10.3159 1.37491C10.0759 1.13486 9.75028 1 9.4108 1H4.52292ZM4.81044 10.9596L2.97416 9.12327V4.81045L4.81045 2.97416H9.12327L10.9596 4.81045V9.12327L9.12327 10.9596H4.81044Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M11.6 3C11.2686 3 11 3.26863 11 3.6V5.07089C7.93431 5.5094 5.5094 7.93431 5.07089 11H3.6C3.26863 11 3 11.2686 3 11.6V12.4C3 12.7314 3.26863 13 3.6 13H5.07089C5.5094 16.0657 7.93431 18.4906 11 18.9291V20.4C11 20.7314 11.2686 21 11.6 21H12.4C12.7314 21 13 20.7314 13 20.4V18.9291C16.0657 18.4906 18.4906 16.0657 18.9291 13H20.4C20.7314 13 21 12.7314 21 12.4V11.6C21 11.2686 20.7314 11 20.4 11H18.9291C18.4906 7.93431 16.0657 5.5094 13 5.07089V3.6C13 3.26863 12.7314 3 12.4 3H11.6ZM6.69241 11C7.1007 8.8195 8.8195 7.1007 11 6.69241V7.4C11 7.73137 11.2686 8 11.6 8H12.4C12.7314 8 13 7.73137 13 7.4V6.69241C15.1805 7.1007 16.8993 8.8195 17.3076 11H16.6C16.2686 11 16 11.2686 16 11.6V12.4C16 12.7314 16.2686 13 16.6 13H17.3076C16.8993 15.1805 15.1805 16.8993 13 17.3076V16.6C13 16.2686 12.7314 16 12.4 16H11.6C11.2686 16 11 16.2686 11 16.6V17.3076C8.8195 16.8993 7.1007 15.1805 6.69241 13H7.4C7.73137 13 8 12.7314 8 12.4V11.6C8 11.2686 7.73137 11 7.4 11H6.69241ZM12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
      fill={theme.tokens.colors['sport-icon']['sport-global']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M7.7111 0C7.37973 0 7.1111 0.268629 7.1111 0.6V1.84036C4.38592 2.23019 2.23039 4.38587 1.84077 7.11111H0.6C0.268629 7.11111 3.97395e-08 7.37974 2.52548e-08 7.71111L0 8.28889C-1.44846e-08 8.62026 0.268629 8.88889 0.6 8.88889H1.84089C2.23084 11.6137 4.38619 13.769 7.11107 14.1588V15.4C7.11107 15.7314 7.3797 16 7.71107 16H8.28885C8.62022 16 8.88885 15.7314 8.88885 15.4V14.1588C11.6138 13.7691 13.7692 11.6138 14.1592 8.88889H15.4C15.7314 8.88889 16 8.62026 16 8.28889V7.71111C16 7.37974 15.7314 7.11111 15.4 7.11111H14.1593C13.7697 4.38583 11.6141 2.23014 8.88888 1.84035V0.6C8.88888 0.268629 8.62025 0 8.28888 0H7.7111ZM3.46312 7.11111C3.82137 5.27108 5.27114 3.82118 7.1111 3.46274V3.84444C7.1111 4.17582 7.37973 4.44444 7.7111 4.44444H8.28888C8.62025 4.44444 8.88888 4.17582 8.88888 3.84444V3.46272C10.7289 3.82112 12.1787 5.27105 12.537 7.11111H12.1555C11.8242 7.11111 11.5555 7.37974 11.5555 7.71111V8.28889C11.5555 8.62026 11.8242 8.88889 12.1555 8.88889H12.5368C12.1783 10.7285 10.7286 12.1781 8.88885 12.5364V12.1556C8.88885 11.8242 8.62022 11.5556 8.28885 11.5556H7.71107C7.3797 11.5556 7.11107 11.8242 7.11107 12.1556V12.5364C5.27141 12.178 3.82182 10.7285 3.46329 8.88889H3.84444C4.17582 8.88889 4.44444 8.62026 4.44444 8.28889V7.71111C4.44444 7.37974 4.17582 7.11111 3.84444 7.11111H3.46312ZM7.99995 9.33366C8.73633 9.33366 9.33328 8.7367 9.33328 8.00033C9.33328 7.26395 8.73633 6.66699 7.99995 6.66699C7.26357 6.66699 6.66661 7.26395 6.66661 8.00033C6.66661 8.7367 7.26357 9.33366 7.99995 9.33366Z"
      fill={theme.tokens.colors['sport-icon']['sport-global']}
      fillRule="evenodd"
    />
  )
}

export const IconSportShooting: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { TransactionHistoryBalanceOperationsTypes } from './types'
import { initialState } from './slice'

// transactionCollection
const getTransactionList = (
  state: RootState,
  balanceOperationTypeTab: TransactionHistoryBalanceOperationsTypes
) => {
  const { transactionCollection } = state.transactionHistoryProvider.data

  if (
    balanceOperationTypeTab === TransactionHistoryBalanceOperationsTypes.ALL
  ) {
    return Object.values(transactionCollection || {})?.reverse() || []
  }
}
export const selectTransactionList = createSelector(
  [getTransactionList],
  (data) => data
)

// activeBalanceOperationTypeTab
const getActiveBalanceOperationTypeTab = (state: RootState) =>
  state.transactionHistoryProvider.layout.activeBalanceOperationTypeTab ||
  initialState.layout.activeBalanceOperationTypeTab

export const selectActiveBalanceOperationTypeTab = createSelector(
  [getActiveBalanceOperationTypeTab],
  (data) => data
)

import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BetStatus } from 'betweb-openapi-axios'

import { Select } from 'shared/ui/select'

import { TRANSIT_BET_STATUS_OPTIONS } from '../../lib'

import { SelectWrapper } from './SelectBetStatus.styled'

interface Props {
  value: BetStatus
  onChange: (value: BetStatus) => void
}

export const SelectBetStatus: FC<Props> = ({ value, onChange }) => {
  const [t] = useTranslation()

  const optionsSelectCheckbox = useMemo(
    () => TRANSIT_BET_STATUS_OPTIONS(t),
    [t]
  )

  const handleBetsStatusChange = useCallback(
    (selectedOption) => {
      if (selectedOption.value === value) {
        return
      }
      onChange(selectedOption.value)
    },
    [value, onChange]
  )

  return (
    <SelectWrapper>
      <Select
        options={optionsSelectCheckbox}
        value={optionsSelectCheckbox.find((item) => item.value === value)!}
        onChange={handleBetsStatusChange}
      />
    </SelectWrapper>
  )
}

import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { liveContainerActions } from 'entities/live/model/slice'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { eventContainerActions } from 'widgets/EventContainer/slice'
import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'

import { searchTournamentContainerActions } from '../../../entities/search-tournament'

export const eventsProviderSideEffects = {
  mainPageTopEvents: {
    onSuccess: mainPageContainerActions.fetchTopEventsSuccess,
    onError: mainPageContainerActions.fetchTopEventsError
  },
  mainPageLiveEvents: {
    onSuccess: mainPageContainerActions.fetchLiveEventsSuccess,
    onError: mainPageContainerActions.fetchLiveEventsError
  },
  liveEvents: {
    onSuccess: liveContainerActions.fetchLiveSuccess,
    onError: liveContainerActions.fetchLiveError
  },
  lineTournaments: {
    onSuccess: lineTournamentsContainerActions.fetchLineTournamentsSuccess,
    onError: lineTournamentsContainerActions.fetchLineTournamentsError,
    onLoading: lineTournamentsContainerActions.fetchLine
  },
  lineEvents: {
    onSuccess: lineTournamentsContainerActions.fetchLineSuccess,
    onError: lineTournamentsContainerActions.fetchLineError,
    onLoading: lineTournamentsContainerActions.fetchLine
  },
  event: {
    onSuccess: eventContainerActions.fetchEventSuccess,
    onError: eventContainerActions.fetchEventError
  },
  calendarEvents: {
    onSuccess: calendarEventsContainerActions.fetchScheduledEventsSuccess,
    onError: calendarEventsContainerActions.fetchScheduledEventsError
  },
  searchTournament: {
    onSuccess: searchTournamentContainerActions.fetchSearchTournamentSuccess,
    onError: searchTournamentContainerActions.fetchSearchTournamentError
  }
}

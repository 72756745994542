import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCoefficients: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="8"
      viewBox="0 0 8 8"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.853554 2.85355C0.538571 2.53857 0.761654 2 1.20711 2H4.5C5.32843 2 6 2.67157 6 3.5V6.79289C6 7.23835 5.46143 7.46143 5.14645 7.14645L0.853554 2.85355Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

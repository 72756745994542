import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M12.0002 3C7.02968 3 3.00024 7.02944 3.00024 12C3.00024 16.9706 7.02968 21 12.0002 21C16.9708 21 21.0002 16.9706 21.0002 12C21.0002 7.02944 16.9708 3 12.0002 3ZM9.69557 4.99518L9.72654 4.40672C10.4468 4.19137 11.21 4.0757 12.0002 4.0757C12.9938 4.0757 13.9446 4.25854 14.8209 4.59241L14.7019 4.96938L12.3429 5.61809L9.69557 4.99518ZM9.32799 6.01377L6.69106 8.26481L7.09249 10.6333L9.7562 11.2L12.0208 9.20186L11.7835 6.59152L9.32799 6.01377ZM12.7671 9.97793L10.4992 11.979L10.9645 14.7123L14.0278 15.4235L15.9349 13.7345L15.487 10.8846L12.7671 9.97793ZM4.93653 8.40482L5.63503 8.47134L6.0438 10.8831L4.99482 13.2896L4.1968 13.3863C4.11737 12.9362 4.07594 12.4729 4.07594 12C4.07594 10.7057 4.38625 9.48384 4.93653 8.40482ZM5.05788 14.3655L4.45813 14.4382C4.91852 15.8634 5.77163 17.112 6.8899 18.0565L7.57791 17.9533L7.35842 16.929L5.05788 14.3655ZM12.06 19.0099L8.67153 17.923L8.45858 16.9292L10.5772 15.7267L13.5115 16.4079L13.8595 18.032L12.06 19.0099ZM14.7725 18.7601L12.6838 19.8952C13.8308 19.7972 14.9081 19.4549 15.8634 18.9205L14.7725 18.7601ZM18.8059 14.1958L16.9927 13.5365L16.5428 10.6738L17.8511 8.71131L19.4009 9.16126C19.7392 10.0426 19.9245 10.9996 19.9245 12C19.9245 12.4468 19.8876 12.8849 19.8165 13.3116L18.8059 14.1958ZM15.7412 5.25026L15.8053 5.04733C17.0153 5.71092 18.0331 6.68115 18.7544 7.85344L17.8304 7.58518L15.7412 5.25026Z"
        fill={theme.tokens.colors['sport-icon']['sport-football']}
        fillRule="evenodd"
      />
      <path
        d="M2 20.9001C2 20.6792 2.18606 20.5003 2.40695 20.4973C11.8681 20.3654 12.1319 15.6347 21.593 15.5028C21.8139 15.4997 22 15.6791 22 15.9V22.1C22 22.3209 21.8209 22.5 21.6 22.5H2.4C2.17909 22.5 2 22.3209 2 22.1V20.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sand']}
      />
      <path
        d="M22 20.9001C22 20.6792 21.8139 20.5003 21.593 20.4973C12.1319 20.3654 11.8681 15.6347 2.40695 15.5028C2.18606 15.4997 2 15.6791 2 15.9V22.1C2 22.3209 2.17908 22.5 2.4 22.5H21.6C21.8209 22.5 22 22.3209 22 22.1V20.9001Z"
        fill={theme.tokens.colors['sport-icon']['sport-sandDarker']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M8.00012 1C4.13413 1 1.00012 4.13401 1.00012 8C1.00012 11.866 4.13413 15 8.00012 15C11.8661 15 15.0001 11.866 15.0001 8C15.0001 4.13401 11.8661 1 8.00012 1ZM6.2076 2.55181L6.23169 2.09412C6.79185 1.92662 7.38548 1.83665 8.00012 1.83665C8.77287 1.83665 9.5124 1.97887 10.194 2.23854L10.1014 2.53174L8.26665 3.03629L6.2076 2.55181ZM5.9217 3.34404L3.87076 5.09485L4.18298 6.93697L6.25475 7.37777L8.01607 5.82367L7.8315 3.79341L5.9217 3.34404ZM8.59654 6.42728L6.83265 7.98365L7.19452 10.1096L9.57715 10.6627L11.0604 9.34902L10.7121 7.13245L8.59654 6.42728ZM2.50612 5.20375L3.0494 5.25549L3.36733 7.13132L2.55146 9.00304L1.93078 9.07827C1.869 8.72815 1.83678 8.36784 1.83678 8C1.83678 6.99331 2.07813 6.04299 2.50612 5.20375ZM2.6005 9.83987L2.13404 9.89641C2.49212 11.0049 3.15564 11.976 4.02541 12.7106L4.56053 12.6303L4.38982 11.8337L2.6005 9.83987ZM8.04661 13.4522L5.41112 12.6068L5.2455 11.8338L6.89331 10.8986L9.17551 11.4284L9.44618 12.6915L8.04661 13.4522ZM10.1563 13.2578L8.53175 14.1407C9.42388 14.0645 10.2618 13.7983 11.0048 13.3826L10.1563 13.2578ZM13.2934 9.70788L11.8831 9.19504L11.5332 6.96849L12.5508 5.44213L13.7562 5.79209C14.0193 6.47755 14.1635 7.22194 14.1635 8C14.1635 8.3475 14.1347 8.68829 14.0794 9.02011L13.2934 9.70788ZM10.9098 2.7502L10.9596 2.59237C11.9007 3.10849 12.6924 3.86312 13.2533 4.7749L12.5347 4.56625L10.9098 2.7502Z"
        fill={theme.tokens.colors['sport-icon']['sport-football']}
        fillRule="evenodd"
      />
      <path
        d="M0.00012207 14.8C0.00012207 14.579 0.184805 14.4003 0.405685 14.3965C7.86961 14.2659 8.13064 10.5339 15.5946 10.4034C15.8154 10.3995 16.0001 10.579 16.0001 10.7999V15.5999C16.0001 15.8208 15.821 15.9999 15.6001 15.9999H0.400122C0.179208 15.9999 0.00012207 15.8208 0.00012207 15.5999V14.8Z"
        fill={theme.tokens.colors['sport-icon']['sport-sand']}
      />
      <path
        d="M16.0001 14.8C16.0001 14.579 15.8154 14.4003 15.5946 14.3965C8.13064 14.2659 7.86961 10.5339 0.405684 10.4034C0.184804 10.3995 0.00012207 10.579 0.00012207 10.7999V15.5999C0.00012207 15.8208 0.179208 15.9999 0.400122 15.9999H15.6001C15.821 15.9999 16.0001 15.8208 16.0001 15.5999V14.8Z"
        fill={theme.tokens.colors['sport-icon']['sport-sandDarker']}
      />
    </>
  )
}

export const IconSportBeachSoccer: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { IconProps } from 'shared/ui/Icon'
import { EColorsNames, EColorsTypes } from 'shared/lib/theme'

export const ICON_PROPS: IconProps = {
  colorProps: {
    name: EColorsNames.Primary,
    type: EColorsTypes.CUSTOM,
    value: 17
  },
  size: 16
}

import styled, { css } from 'styled-components'

import { ButtonDeprecated } from 'shared/ui/ButtonDeprecated'
import { IconCalendar } from 'shared/ui/Icon/General/IconCalendar'

import { IStyledCustomButtonRange } from './CustomButtonRange.types'

export const StyledCustomButtonRange = styled.div<IStyledCustomButtonRange>`
  ${({ theme, active, customWidth, isDisabled }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-active']};
    border: none;
    border-radius: 8px;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    cursor: ${isDisabled ? 'unset' : 'pointer'};
    display: flex;
    height: 32px;
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;
    padding: 0 8px;
    transition: all 0.3s;
    width: ${customWidth ?? '100%'};

    ${active &&
    css`
      color: ${theme.tokens.colors.text['text-primary-2']};
      outline: 1px solid ${theme.tokens.colors.border['border-active']} !important;
    `}

    &:hover {
      outline: ${isDisabled
        ? 'none'
        : `1px solid ${theme.tokens.colors.border['border-settings-active']}`};
    }
  `}
`

export const StyledIconCalendar = styled(IconCalendar)<{
  isDisabled?: boolean
}>`
  ${({ theme, isDisabled }) => css`
    color: ${theme.tokens.colors.icon['icon-primary']};

    ${isDisabled &&
    css`
      path {
        fill: ${theme.tokens.colors.icon['icon-opacity']};
      }
    `}
  `};
`

export const StyledButtonResetRange = styled(ButtonDeprecated)`
  margin-left: 8px;
`

export const StyledDateValue = styled.div`
  width: 100%;
`

export const StyledCustomButtonRangeWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `};
`

import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_PERIOD_BY_TITLE } from 'astra-core'

import { PERIOD_SECTION_OPTIONS } from 'shared/ui/DatePicker/components/CustomContainerRange/constants'

import {
  StyledPeriodSelectButton,
  StyledPeriodSelectsContainer
} from './period-selects.styled'
import { IPeriodSelectProps } from './period-select.types'

export const PeriodSelects: FC<IPeriodSelectProps> = ({ onChangeSelect }) => {
  const [t] = useTranslation()

  const periodSelectOptions = useMemo(() => PERIOD_SECTION_OPTIONS(t), [t])

  const selectHandleChange = useCallback(
    (value) => {
      if (value) onChangeSelect?.(DATE_PERIOD_BY_TITLE[value], value)
    },
    [onChangeSelect]
  )

  return (
    <StyledPeriodSelectsContainer>
      {periodSelectOptions
        .filter((_, index) => index !== 0)
        .map((periodOption) => (
          <StyledPeriodSelectButton
            key={periodOption.value}
            onClick={() => selectHandleChange(periodOption.value)}
          >
            {periodOption.label}
          </StyledPeriodSelectButton>
        ))}
    </StyledPeriodSelectsContainer>
  )
}

import { useTranslation } from 'react-i18next'

import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { LineSearch } from 'features/line-search'
import { LiveTopFilter } from 'features/events-page-header'
import { LiveBroadcastFilter } from 'features/events-page-header/ui/live-broadcast-filter/live-broadcast-filter'
import { BasketButtonOpenBasketForSmallWindow } from 'features/basket'

import {
  StyledButtonsWrapper,
  StyledSearchDivider,
  StyledSearchWrapper
} from './live-content.styled'

export const LiveContent = () => {
  const { t } = useTranslation()
  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  return (
    <StyledButtonsWrapper>
      <StyledSearchWrapper>
        <LineSearch placeholder={t('search in live')} />
      </StyledSearchWrapper>

      <StyledSearchDivider />

      <LiveBroadcastFilter />

      <LiveTopFilter />

      {(isTabletOrMobile || isSmall) && (
        <BasketButtonOpenBasketForSmallWindow isSmall />
      )}
    </StyledButtonsWrapper>
  )
}

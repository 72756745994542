import { LogoEntityType } from 'betweb-openapi-axios/dist/api'
import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getLogosContainerState = (state: RootState) =>
  state.logosContainer || initialState

export const selectLogosContainerState = createSelector(
  [getLogosContainerState],
  (state) => state
)

export const getLogoByEntityTypeAndId = (
  state: RootState,
  { entityId, entityType }: { entityId?: number; entityType: LogoEntityType }
) =>
  entityId
    ? getLogosContainerState(state).entries[entityType][entityId]
    : undefined

export const selectLogoByEntityTypeAndId = createSelector(
  [getLogoByEntityTypeAndId],
  (logo) => logo
)

export const selectIsLogoRequested = (
  state: RootState,
  { entityId, entityType }: { entityId?: number; entityType: LogoEntityType }
) =>
  entityId
    ? getLogosContainerState(state).requested[entityType][entityId]
    : false

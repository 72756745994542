import styled, { css } from 'styled-components'

import { IconCalendar } from 'shared/ui/Icon/General/IconCalendar'
import { typography } from 'shared/lib/theme/typography'
import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledCalendarEvents = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px 32px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledIconCalendar = styled(IconCalendar)`
  background: ${(props) => props.theme.colors.custom.blue[6]};
  border-radius: 4px;
  padding: 5px;
`
export const StyledDataList = styled(DataListWithReduxPagination)`
  height: 100%;
  width: 100%;
`

export const StyledCalendarEventsEmptyText = styled.div`
  ${typography['Body / 14 Medium']}

  text-align: center;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};

    padding: ${theme.tokens.alias.space['space-xxl-32']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledEventsTableHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  ${({ theme }) =>
    css`
      padding: ${theme.tokens.alias.space['space-m-16']}px
        ${theme.tokens.alias.space['space-m-16']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xs-8']}px;
    `}
`

export const StyledEventsTableHeaderTime = styled.div`
  ${typography['Body / 12 SemiBold']};

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledEventsTableHeaderDate = styled.div`
  ${typography['Body / 12 Regular']};

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

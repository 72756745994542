import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M12.6391 5.09592C12.1239 5.35813 11.6387 5.65788 11.2024 5.9997L9.82778 5.63431C9.30047 5.49415 8.75741 5.80096 8.59803 6.32909L7.51351 9.92269C7.36656 10.4096 7.59383 10.9316 8.0478 11.1498L8.28839 11.2654C8.08966 12.1534 7.97208 13.0366 7.91099 13.8398L4.45517 14.6805C3.50416 14.9119 3.3949 16.2371 4.29487 16.6249L6.75785 17.686L7.72705 19.8907C8.12327 20.792 9.42466 20.665 9.64342 19.7036L10.3965 16.3943C11.9717 16.3269 13.8927 15.8957 15.6571 14.6924L16.8308 15.1869C17.5654 15.4964 18.3469 14.8902 18.2268 14.1042L17.9753 12.4578C18.3481 11.9574 18.6659 11.4353 18.9357 10.9037C16.9397 10.5945 15.4641 9.60715 14.4206 8.38777C13.5362 7.35426 12.9687 6.16383 12.6391 5.09592ZM19.4775 9.66344C17.6226 9.46846 16.3178 8.60534 15.4083 7.54255C14.6141 6.61436 14.1141 5.52263 13.838 4.56836C15.4168 3.96886 17.1248 3.65149 18.5082 3.50859C19.4938 3.40678 20.3415 4.13985 20.3223 5.13056C20.2975 6.41976 20.0798 8.0418 19.4775 9.66344ZM18.3209 5.70221C18.3209 6.17706 17.9262 6.56199 17.4393 6.56199C16.9525 6.56199 16.5578 6.17706 16.5578 5.70221C16.5578 5.22737 16.9525 4.84244 17.4393 4.84244C17.9262 4.84244 18.3209 5.22737 18.3209 5.70221Z"
      fill={theme.tokens.colors['sport-icon']['sport-fishing']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M8.59146 1.51182C8.10523 1.75567 7.64712 2.03377 7.23461 2.35017L5.91275 2.00621C5.40584 1.87431 4.88379 2.16305 4.73057 2.66007L3.688 6.04198C3.54673 6.50023 3.76521 6.99143 4.20162 7.19677L4.43301 7.30565C4.24195 8.14134 4.1289 8.9725 4.07017 9.7284L0.747953 10.5197C-0.166274 10.7374 -0.2713 11.9845 0.59386 12.3494L2.96157 13.3481L3.89329 15.4229C4.27419 16.2711 5.52524 16.1516 5.73554 15.2469L6.45942 12.1327C7.97375 12.0693 9.82056 11.6635 11.5167 10.531L12.6449 10.9963C13.3511 11.2876 14.1024 10.7172 13.987 9.97744L13.7452 8.42818C14.095 7.96848 14.3944 7.48932 14.6498 7.00141C12.7364 6.707 11.3185 5.77809 10.3134 4.62815C9.45843 3.65012 8.90987 2.52317 8.59146 1.51182ZM15.2031 5.76168C13.4164 5.58108 12.1631 4.769 11.2921 3.77258C10.5339 2.90519 10.0565 1.88542 9.79249 0.994298C11.2799 0.444668 12.8841 0.149069 14.1956 0.0124025C15.1788 -0.0900489 16.0263 0.639954 15.9992 1.6281C15.9666 2.81557 15.7567 4.28763 15.2031 5.76168ZM14.0774 2.07051C14.0774 2.51738 13.698 2.87964 13.23 2.87964C12.762 2.87964 12.3826 2.51738 12.3826 2.07051C12.3826 1.62364 12.762 1.26138 13.23 1.26138C13.698 1.26138 14.0774 1.62364 14.0774 2.07051Z"
      fill={theme.tokens.colors['sport-icon']['sport-fishing']}
      fillRule="evenodd"
    />
  )
}

export const IconSportFishing: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

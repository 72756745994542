import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { useSSR } from 'react-i18next'
import { hydrateRoot } from 'react-dom/client'
import { loadableReady } from '@loadable/component'
import axios from 'axios'
import ApiBuilder from 'astra-core/config/ApiBuilder'
import CoreConfiguration from 'astra-core/config/CoreConfiguration'

import { configureAppStore } from 'app/lib/store'
import { routes, virtualGamesRoutes } from 'pages/model'
import { addLogoutInterceptor } from 'shared/lib/axios/interceptors'

import './app/lib/i18n'
import App from './app/app'

/*
Redux Store Init
Statically injected reducers are pre-filled with initial data here,
while initial states of dynamically injected reducers are pre-filled
on behalf of each particular slice.
 */

const store = configureAppStore({
  preloadedState: window.__PRELOADED_STATE__
})

const axiosInstance = axios.create()
// @ts-ignore
addLogoutInterceptor(axiosInstance, store, virtualGamesRoutes)

// Init core configuration
CoreConfiguration.init({
  apiBuilder: ApiBuilder.create(axiosInstance)
})

const AppContainer: FC = () => {
  useSSR(window.initialI18nStore, window.initialLanguage)

  return (
    <Provider store={store}>
      <BrowserRouter>
        <App routes={routes} />
      </BrowserRouter>
    </Provider>
  )
}

const container = document.getElementById('root')!

// Load all components needed before rendering
loadableReady().then(() => {
  hydrateRoot(container, <AppContainer />)
})

if (module.hot) {
  module.hot.accept()
}

import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import uniq from 'lodash/uniq'
import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState, toIdsArray, toIdsMap } from 'shared/lib/@reduxjs'

import {
  ContainerState,
  IFetchScheduledEventsCountersSuccessPayload,
  SetCurrentSportIdFilterPayload,
  TFetchScheduledEventsPayload,
  TFetchScheduledEventsSuccessPayload,
  TSetFilterInputSearchPayload,
  TSetSelectOrderPayload,
  TSetSelectPeriodPayload
} from './types'

const REDUCER_KEY = 'calendarEventsContainer'

export const INITIAL_FILTER_PERIOD_ID = '1'

export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  data: {
    eventsIds: {},
    fetchItems: requestInitialState,
    eventsCountersBySport: {},
    fetchCountersItems: requestInitialState,
    hasMore: false
  },
  filters: {
    isStream: false,
    selectInputs: {
      order: null, //
      period: {
        id: INITIAL_FILTER_PERIOD_ID,
        scheduledFrom: '',
        scheduledTo: ''
      }
    },
    inputSearch: '',
    currentSportId: 100
  },
  layout: {
    selectInputs: {
      order: '',
      period: ''
    }
  },
  pagination: {
    limit: 20,
    offset: 0
  }
})

const calendarEventsContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    toggleFilterStream(state) {
      state.filters.isStream = !state.filters.isStream
      clearCalendarEventsPagination(state)
    },
    setSelectPeriod(
      state,
      { payload }: PayloadAction<TSetSelectPeriodPayload>
    ) {
      state.layout.selectInputs.period = payload.label
      state.filters.selectInputs.period = {
        id: payload.id,
        scheduledFrom: payload?.scheduledFrom ?? dayjs().format(),
        scheduledTo: payload?.scheduledTo ?? payload.value
      }
      clearCalendarEventsPagination(state)
    },
    setSelectOrder(state, { payload }: PayloadAction<TSetSelectOrderPayload>) {
      state.layout.selectInputs.order = payload.label
      clearCalendarEventsPagination(state)
    },
    setFilterInputSearch(
      state,
      { payload }: PayloadAction<TSetFilterInputSearchPayload>
    ) {
      state.filters.inputSearch = payload
      clearCalendarEventsPagination(state)
    },
    fetchScheduledEvents(
      state,
      _action: PayloadAction<TFetchScheduledEventsPayload>
    ) {
      state.data.fetchItems.loading = true
      state.data.fetchItems.error = null
    },
    fetchScheduledEventsSuccess(
      state,
      { payload }: PayloadAction<TFetchScheduledEventsSuccessPayload>
    ) {
      state.data.fetchItems.loading = false
      state.data.fetchItems.error = null
      state.data.hasMore = payload.resData.hasMore

      const alreadyLoadedEvents =
        state.data.eventsIds[state.filters.currentSportId] ?? []

      state.data.eventsIds[state.filters.currentSportId] = uniq([
        ...alreadyLoadedEvents,
        ...toIdsArray(payload.resData.items)
      ])
    },
    fetchScheduledEventsError(state, action: PayloadAction<ErrorPayload>) {
      state.data.fetchItems.loading = false
      state.data.fetchItems.error = action.payload
      clearCalendarEventsPagination(state)
    },
    fetchScheduledEventsCounters(state) {
      state.data.fetchCountersItems.loading = true
      state.data.fetchCountersItems.error = null
    },
    fetchScheduledEventsCountersSuccess(
      state,
      { payload }: PayloadAction<IFetchScheduledEventsCountersSuccessPayload>
    ) {
      const resultCountersBySport = toIdsMap(payload.items)

      state.data.eventsCountersBySport = resultCountersBySport
      state.data.fetchCountersItems.loading = false
      state.data.fetchCountersItems.error = null
    },
    fetchScheduledEventsCountersError(
      state,
      { payload }: PayloadAction<ErrorPayload>
    ) {
      state.data.fetchCountersItems.loading = false
      state.data.fetchCountersItems.error = payload
      clearCalendarEventsPagination(state)
    },
    setPagination(state, { payload }) {
      state.pagination = { ...state.pagination, ...payload }
    },
    setCurrentSportIdFilter(
      state,
      { payload }: PayloadAction<SetCurrentSportIdFilterPayload>
    ) {
      state.data.fetchItems.loading = true
      state.data.fetchItems.error = null
      state.filters.currentSportId = payload.sportId
      clearCalendarEventsPagination(state)
    }
  }
})

const clearCalendarEventsPagination = (state: ContainerState) => {
  state.pagination = initialState.pagination
  state.data.eventsIds = {}
}

export const { actions, reducer, name: sliceKey } = calendarEventsContainerSlice

import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'

import { EInputSize, EInputView, InputProps, InputWrapperProps } from './types'

export const StyledInputWrapper = styled.div<InputWrapperProps>`
  ${(props) => css`
    display: flex;
    position: relative;

    ${StyledIcon} {
      position: absolute;

      ${props.customSize === EInputSize.M &&
      css`
        top: 4px;
        ${props.iconRight
          ? css`
              right: 6px;
            `
          : css`
              left: 6px;
            `}
      `}

      ${props.customSize === EInputSize.L &&
      css`
        top: 8px;
        ${props.iconRight
          ? css`
              right: 8px;
            `
          : css`
              left: 8px;
            `}
      `}

      ${props.iconRight
        ? css`
            cursor: pointer;
          `
        : css`
            pointer-events: none;
          `}
    }

    ${props.iconColor &&
    css`
      path {
        fill: ${props.theme.tokens.colors.icon[props.iconColor]};
      }
    `}
  `}
`

export const StyledInput = styled.input<InputProps>`
  ${(props) => css`
    border-radius: 4px;
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.s};
    line-height: 14px;
    max-width: 100%;
    outline: none;
    padding: 6px ${props.rightPadding || 6}px 6px 8px;
    width: ${props.fullWidth ? '100%' : 'auto'};

    &::placeholder {
      color: ${props.theme.colors.fixed.primary[30]};
    }

    &:disabled {
      pointer-events: none;
    }

    ${props.customSize === EInputSize.M &&
    css`
      height: ${EInputSize.M}px;

      ${props.icon &&
      css`
        padding-left: 28px;
        ${props.iconRight &&
        css`
          padding-left: 6px;
          padding-right: 28px;
        `}
      `}
    `}

    ${props.customSize === EInputSize.L &&
    css`
      height: ${EInputSize.L}px;

      ${props.icon &&
      css`
        padding-left: 28px;
        ${props.iconRight &&
        css`
          padding-left: 6px;
          padding-right: 28px;
        `}
      `}
    `}


    ${props.view === EInputView.PRIMARY &&
    css`
      background: ${props.theme.colors.default.primary[0]};
      border: 1px solid ${props.theme.colors.default.primary[20]};

      &:hover {
        border: 1px solid ${props.theme.colors.custom.primary[14]};
      }

      &:focus {
        border: 1px solid ${props.theme.colors.default.primary[90]};
      }

      &:disabled {
        border: 1px solid ${props.theme.colors.default.primary[5]};

        &::placeholder {
          color: ${props.theme.colors.custom.primary[34]};
        }
      }
    `}

    ${props.view === EInputView.SECONDARY &&
    css`
      background: ${props.theme.colors.default.primary[5]};
      border: 1px solid transparent;

      &:hover,
      &:focus {
        border: 1px solid ${props.theme.colors.default.primary[50]};
      }

      &:focus {
        background-color: ${props.theme.colors.default.primary[0]};
      }

      &:disabled {
        background-color: ${props.theme.colors.default.primary[3]};
      }
    `}

    ${props.view === EInputView.THIRD &&
    css`
      background: ${props.theme.colors.default.primary[0]};
      border: 1px solid ${props.theme.colors.custom.primary[53]};

      &::placeholder {
        color: ${props.theme.colors.default.primary[30]};
      }

      &:hover,
      &:focus {
        border: 1px solid ${props.theme.colors.default.primary[50]};
      }

      &:focus {
        background-color: ${props.theme.colors.default.primary[0]};
      }

      &:disabled {
        background-color: ${props.theme.colors.default.primary[3]};
      }
    `}

    ${props.view === EInputView.ERROR &&
    css`
      background: ${props.theme.colors.default.primary[0]};
      border: 1px solid ${props.theme.colors.fixed.red[0]};

      &:hover {
        border: 1px solid ${props.theme.colors.fixed.red[0]};
      }

      &:focus {
        border: 1px solid ${props.theme.colors.fixed.red[0]};
      }

      &:disabled {
        border: 1px solid ${props.theme.colors.fixed.red[0]};

        &::placeholder {
          color: ${props.theme.colors.fixed.red[0]};
        }
      }
    `}
  `}
`

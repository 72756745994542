import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }
  :root {
    --page-background: ${(p) => p.theme.metaThemeColor};
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: var(--page-background);
    ${({ theme }) => css`
      color: ${theme.tokens.colors.text['text-primary']};
    `}
    font-feature-settings: 'liga', 'kern';
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-rendering: optimizelegibility;
  }

  #root {
    ${({ theme }) => css`
      background-color: ${theme.pageBackground};
    `}
  }

  * {
    font-family: Inter, sans-serif;
    font-variant-numeric: lining-nums tabular-nums;
    margin: 0;
    padding: 0;
  }

  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  .masonry:focus-visible {
    outline: none;
  }

  #chat-widget-container {
    display: none;
    z-index: 50 !important;
  }
`

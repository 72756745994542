import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M11.8834 4.5C17.2826 4.5 21.002 6.60547 21.002 8.44727C21.002 9.36865 19.9221 10.4209 18.2424 11.2104C16.6826 11.8687 14.523 12.3945 12.0034 12.3945C9.48378 12.3945 7.32411 11.8687 5.7643 11.2104C3.96461 10.4209 3.00477 9.36865 3.00477 8.44727C2.88478 6.60547 6.60426 4.5 11.8834 4.5ZM12.002 13.6411C15.0809 13.6411 17.6862 12.9897 19.5809 11.9482C20.0546 11.688 20.6467 11.2974 21.002 10.9067V14.813C20.8836 17.417 16.8572 19.5 12.002 19.5C7.14674 19.5 3.23878 17.417 3.00197 14.813V10.9067C3.20961 11.1353 3.49818 11.3638 3.79677 11.5659C4.00892 11.7095 4.22621 11.8403 4.42299 11.9482C6.43617 12.9897 9.0414 13.6411 12.002 13.6411Z"
      fill={theme.tokens.colors['sport-icon']['sport-hockey']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M14.7368 7.95377C13.0526 8.87966 10.7368 9.45834 8 9.45834C5.36842 9.45834 3.05263 8.87966 1.26316 7.95377C0.842105 7.7223 0.315789 7.37509 0 7.02789V10.5C0.210526 12.8147 3.68421 14.6665 8 14.6665C12.3158 14.6665 15.8947 12.8147 16 10.5V7.02789C15.6842 7.37509 15.1579 7.7223 14.7368 7.95377Z"
        fill={theme.tokens.colors['sport-icon']['sport-hockey']}
      />
      <path
        d="M16 4.84179C16 3.20436 12.6939 1.33301 7.8946 1.33301C3.20201 1.33301 -0.104143 3.20436 0.00250753 4.84179C0.00250753 5.6605 0.855708 6.59618 2.45546 7.29793C3.84191 7.88273 5.76161 8.35057 8.00125 8.35057C10.2409 8.35057 12.1606 7.88273 13.5471 7.29793C15.0402 6.59618 16 5.6605 16 4.84179Z"
        fill={theme.tokens.colors['sport-icon']['sport-hockey']}
      />
    </>
  )
}

export const IconSportHockey: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconSimBeeline: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 57 16" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="16"
        id="mask0_3151_37098"
        maskUnits="userSpaceOnUse"
        style={{ mask: 'mask-type:luminance' }}
        width="17"
        x="0"
        y="0"
      >
        <path
          d="M0.865479 8C0.865479 9.58198 1.33451 11.1284 2.21327 12.4439C3.09204 13.7593 4.34109 14.7847 5.80252 15.3904C7.26395 15.9961 8.87215 16.1549 10.4238 15.8468C11.9755 15.5386 13.401 14.7773 14.52 13.6591C15.6391 12.541 16.4016 11.1161 16.711 9.56468C17.0204 8.01326 16.8629 6.40493 16.2584 4.94301C15.6539 3.48108 14.6295 2.23121 13.3147 1.35138C12 0.471556 10.4539 0.00127942 8.87193 2.6059e-06C7.82082 -0.000845754 6.77983 0.205454 5.80848 0.607112C4.83713 1.00877 3.95446 1.59791 3.21091 2.34086C2.46735 3.08382 1.8775 3.96602 1.47506 4.93704C1.07262 5.90807 0.865478 6.94888 0.865479 8Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3151_37098)">
        <path
          d="M16.8719 13.1396H0.865479V9.71752H16.8719V13.1396ZM16.8719 2.87329H0.865479V6.29541H16.8719V2.87329Z"
          fill="#FFD300"
        />
        <path
          d="M16.8719 2.87328H0.865479V0H16.8719V2.87328ZM16.8719 13.1396H0.865479V15.9935H16.8719V13.1396ZM16.8719 6.2954H0.865479V9.71751H16.8719V6.2954Z"
          fill="#231F20"
        />
      </g>
      <path
        d="M40.929 4.60339C40.9266 4.68971 40.9417 4.77562 40.9734 4.85596C41.005 4.93629 41.0526 5.00939 41.1133 5.07086C41.1739 5.13233 41.2464 5.18091 41.3263 5.21366C41.4062 5.24641 41.4919 5.26267 41.5782 5.26145C41.665 5.26263 41.7511 5.24642 41.8315 5.21377C41.9118 5.18111 41.9849 5.13268 42.0462 5.07134C42.1075 5.01 42.156 4.93698 42.1886 4.85661C42.2213 4.77624 42.2375 4.69013 42.2363 4.60339C42.2375 4.51704 42.2213 4.43134 42.1885 4.35144C42.1558 4.27154 42.1072 4.19908 42.0457 4.13843C41.9842 4.07778 41.9111 4.03019 41.8308 3.99851C41.7505 3.96683 41.6646 3.95173 41.5782 3.9541C41.4926 3.95292 41.4077 3.96891 41.3284 4.00112C41.2491 4.03333 41.177 4.08111 41.1165 4.14164C41.056 4.20217 41.0082 4.27422 40.976 4.35353C40.9438 4.43284 40.9278 4.5178 40.929 4.60339Z"
        fill="currentColor"
      />
      <path
        d="M44.3481 6.75081L44.2692 6.5141C44.2556 6.47528 44.2309 6.4413 44.1982 6.41637C44.1655 6.39144 44.1262 6.37665 44.0851 6.37383H43.6643C43.6108 6.37383 43.5595 6.39507 43.5217 6.43289C43.4839 6.4707 43.4626 6.52199 43.4626 6.57547V11.976C43.4626 12.0021 43.4679 12.0279 43.4781 12.0519C43.4884 12.0759 43.5034 12.0976 43.5222 12.1156C43.541 12.1336 43.5634 12.1476 43.5878 12.1568C43.6122 12.166 43.6382 12.1701 43.6643 12.1689H44.4183C44.4694 12.1689 44.5185 12.1486 44.5546 12.1124C44.5908 12.0762 44.6111 12.0272 44.6111 11.976V7.59245C44.9911 7.39267 45.4189 7.30166 45.8473 7.32944C46.5837 7.32944 46.724 7.60122 46.724 8.04834V11.9672C46.724 12.0184 46.7443 12.0675 46.7805 12.1036C46.8167 12.1398 46.8657 12.1601 46.9169 12.1601H47.6796C47.7308 12.1601 47.7798 12.1398 47.816 12.1036C47.8522 12.0675 47.8725 12.0184 47.8725 11.9672V8.11848C47.8725 6.92615 47.1711 6.29492 45.8385 6.29492C45.3121 6.30749 44.7993 6.46525 44.3569 6.75081"
        fill="currentColor"
      />
      <path
        d="M41.9459 6.40894H41.2182C41.1069 6.40894 41.0166 6.49921 41.0166 6.61058V12.0023C41.0166 12.1137 41.1069 12.204 41.2182 12.204H41.9459C42.0573 12.204 42.1476 12.1137 42.1476 12.0023V6.61058C42.1476 6.49921 42.0573 6.40894 41.9459 6.40894Z"
        fill="currentColor"
      />
      <path
        d="M38.7021 3.70874H39.421C39.4722 3.70874 39.5213 3.72906 39.5574 3.76523C39.5936 3.8014 39.6139 3.85046 39.6139 3.90162V12.0024C39.6139 12.0559 39.5927 12.1072 39.5549 12.145C39.517 12.1828 39.4658 12.2041 39.4123 12.2041H38.6758C38.6351 12.1891 38.6004 12.1613 38.5767 12.125C38.553 12.0887 38.5417 12.0457 38.5443 12.0024V3.90162C38.5452 3.85643 38.5613 3.81286 38.5899 3.77788C38.6185 3.74291 38.658 3.71856 38.7021 3.70874Z"
        fill="currentColor"
      />
      <path
        d="M28.313 8.79355C28.3743 8.04834 28.5672 7.36451 29.6105 7.36451C30.4872 7.36451 30.6888 7.8467 30.7064 8.79355V8.83738H28.313V8.79355ZM27.1294 9.30204C27.1294 10.0472 27.1294 12.3092 29.593 12.3092C30.2415 12.3503 30.8921 12.273 31.5129 12.0812L31.6269 11.9059V11.3009L31.5392 11.1344H31.3551C30.7783 11.2513 30.19 11.3013 29.6017 11.2834C28.576 11.2834 28.3743 10.6346 28.313 9.93327V9.88943H31.662C31.688 9.89062 31.7141 9.88651 31.7385 9.87735C31.7629 9.86819 31.7852 9.85417 31.8041 9.83614C31.8229 9.81811 31.8379 9.79644 31.8482 9.77245C31.8584 9.74846 31.8637 9.72264 31.8636 9.69656V9.34587C31.8636 7.89054 31.6883 6.33876 29.6105 6.33876C27.1294 6.29492 27.1294 8.57437 27.1294 9.30204Z"
        fill="currentColor"
      />
      <path
        d="M33.9152 8.79366C33.9766 8.04845 34.1695 7.36462 35.2127 7.36462C36.0894 7.36462 36.2911 7.84681 36.3086 8.79366V8.83749H33.9152V8.79366ZM32.7492 9.30215C32.7492 10.0474 32.7492 12.3093 35.2127 12.3093C35.8438 12.344 36.476 12.2669 37.0801 12.0813L37.1941 11.906V11.301L37.1064 11.1345H36.9223C36.3455 11.2509 35.7571 11.3009 35.1689 11.2835C34.1431 11.2835 33.9415 10.6347 33.8801 9.93338V9.88954H37.2292C37.2552 9.89073 37.2813 9.88662 37.3057 9.87746C37.3301 9.8683 37.3524 9.85428 37.3713 9.83625C37.3901 9.81822 37.4051 9.79655 37.4153 9.77256C37.4256 9.74857 37.4308 9.72275 37.4308 9.69667V9.34598C37.4308 7.89064 37.2555 6.33887 35.1777 6.33887C32.7141 6.33887 32.7141 8.60078 32.7141 9.34598"
        fill="currentColor"
      />
      <path
        d="M50.0293 8.7937C50.0819 8.0485 50.2835 7.36466 51.3268 7.36466C52.2035 7.36466 52.3964 7.84686 52.414 8.7937V8.83754H50.0205L50.0293 8.7937ZM48.8633 9.30219C48.8633 10.0474 48.8633 12.3093 51.3268 12.3093C51.955 12.3436 52.5842 12.2664 53.1855 12.0814L53.2994 11.906V11.3011L53.2118 11.1345H53.0277C52.4651 11.2213 51.8946 11.2448 51.3268 11.2047C50.3011 11.2047 50.0994 10.5559 50.0293 9.85452V9.81069H53.3783C53.4295 9.81069 53.4786 9.79037 53.5147 9.75419C53.5509 9.71802 53.5712 9.66896 53.5712 9.61781V9.26713C53.5712 7.81179 53.3959 6.26001 51.3268 6.26001C48.8633 6.26001 48.8633 8.52192 48.8633 9.26713"
        fill="currentColor"
      />
      <path
        d="M23.4999 8.35528C24.666 8.35528 25.078 8.70597 25.078 9.70542C25.078 10.3805 24.8851 11.0643 23.4736 11.0643H21.7728V8.35528H23.4999ZM23.4123 4.84844C24.6396 4.84844 24.8062 5.43584 24.8062 6.0583C24.8062 6.86487 24.3766 7.25063 23.4999 7.25063H21.7465V4.81337L23.4123 4.84844ZM20.7821 3.70872C20.7537 3.70869 20.7256 3.71441 20.6995 3.72553C20.6734 3.73665 20.6498 3.75294 20.6302 3.77341C20.6105 3.79389 20.5952 3.81813 20.5851 3.84468C20.5751 3.87123 20.5705 3.89954 20.5717 3.9279V12.0199C20.5705 12.0483 20.5751 12.0766 20.5851 12.1031C20.5952 12.1297 20.6105 12.1539 20.6302 12.1744C20.6498 12.1949 20.6734 12.2112 20.6995 12.2223C20.7256 12.2334 20.7537 12.2391 20.7821 12.2391H23.4123C25.2972 12.2391 26.3405 11.3624 26.3405 9.74048C26.3653 9.32981 26.2741 8.92044 26.0772 8.55919C25.8803 8.19795 25.5857 7.89942 25.227 7.69775C25.4947 7.5135 25.7141 7.26756 25.8666 6.98069C26.0192 6.69382 26.1006 6.37445 26.1038 6.04953C26.1038 5.17282 25.7443 3.69995 23.3684 3.69995L20.7821 3.70872Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M54.667 6.27759L54.6494 6.29514V6.47939L54.667 6.49694H55.0004V7.64636L55.0267 7.66391H55.211L55.2373 7.64636V6.49694H55.5707L55.5883 6.47939V6.29514L55.5707 6.27759H54.667Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M56.8157 6.27759L56.4384 7.15501L56.0611 6.27759H55.7979V7.62881H55.9909C55.9909 7.62881 55.9909 6.84791 55.9909 6.6812L56.3243 7.45333H56.4647L56.7981 6.6812V7.69901H56.9999V6.29514L56.8157 6.27759Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import { BetOutcomeStatus } from 'betweb-openapi-axios'

import { EColorsNames, ThemeContext } from 'shared/lib/theme'

export const OUTCOME_EVENT_DATE_FORMAT = 'DD.MM.YY HH:mm'

export const getOutcomeResultIndicatorColors = (theme: ThemeContext) => ({
  [BetOutcomeStatus.Win]: theme.colors.default.green[50],
  [BetOutcomeStatus.Lost]: theme.colors.default.red[50],
  [BetOutcomeStatus.Return]: theme.colors.default[EColorsNames.Yellow][50]
})

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconChevronLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M10.2071 12.7071C10.5976 12.3166 10.5976 11.6834 10.2071 11.2929L6.91421 8L10.2071 4.70711C10.5976 4.31658 10.5976 3.68342 10.2071 3.29289C9.81658 2.90237 9.18342 2.90237 8.79289 3.29289L4.79289 7.29289C4.40237 7.68342 4.40237 8.31658 4.79289 8.70711L8.79289 12.7071C9.18342 13.0976 9.81658 13.0976 10.2071 12.7071Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconBankCenterCredit: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 76 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.55977 1.67931L0.847995 7.8656C-0.118613 9.47017 1.04132 11.5 2.8972 11.5H10.3208C12.1766 11.5 13.3366 9.47017 12.37 7.8656L8.65819 1.67931C7.73024 0.113407 5.48771 0.113407 4.55977 1.67931ZM8.21355 1.94996L11.9447 8.15558C12.4666 9.02553 11.848 10.1468 10.8234 10.1468H3.36118C2.33657 10.1468 1.69861 9.02553 2.23991 8.15558L5.97102 1.94996C6.47365 1.09935 7.71091 1.09935 8.21355 1.94996Z"
        fill="#DAAB6B"
      />
      <path
        d="M6.74433 3.24522L4.26981 7.36297C3.88317 8.00093 4.34714 8.81288 5.08176 8.81288H10.0308C10.7654 8.81288 11.2294 8.00093 10.8427 7.36297L8.36823 3.24522C8.00092 2.62659 7.11164 2.62659 6.74433 3.24522ZM8.3489 4.23116L9.9728 6.91833C10.1275 7.16965 9.93413 7.47897 9.66348 7.47897H6.41568C6.1257 7.47897 5.95171 7.16965 6.10636 6.91833L7.73027 4.23116C7.86559 3.97984 8.21357 3.97984 8.3489 4.23116Z"
        fill="#DAAB6B"
      />
      <path
        d="M18.3629 8.73554C17.551 8.73554 16.8744 8.48422 16.3524 7.96225C15.8304 7.44029 15.5791 6.78299 15.5791 5.97104C15.5791 5.15909 15.8498 4.5018 16.3717 3.97983C16.913 3.45786 17.5896 3.20654 18.4209 3.20654C18.9622 3.20654 19.4649 3.34187 19.8902 3.59319C20.3348 3.8445 20.6635 4.21182 20.8761 4.65645L19.8515 5.21709C19.5229 4.65645 19.0396 4.3858 18.4403 4.3858C17.9763 4.3858 17.609 4.54046 17.2997 4.84978C17.0097 5.13976 16.855 5.5264 16.855 5.99037C16.855 6.45435 17.0097 6.82166 17.2997 7.13097C17.5896 7.44029 17.957 7.59494 18.4016 7.59494C18.6916 7.59494 18.9622 7.51761 19.2329 7.36296C19.5035 7.2083 19.6969 7.01498 19.8128 6.76366L20.8374 7.36296C20.6055 7.78826 20.2768 8.13624 19.8322 8.38756C19.3875 8.61955 18.9042 8.73554 18.3629 8.73554Z"
        fill="#2FAD6F"
      />
      <path
        d="M26.7724 5.87429C26.7724 6.04828 26.7531 6.22227 26.7338 6.43492H22.7127C22.79 6.80223 22.9833 7.11155 23.254 7.3242C23.5246 7.53686 23.8919 7.65285 24.3366 7.65285C24.9745 7.65285 25.5545 7.47886 26.0765 7.11155L26.5791 8.02016C25.9218 8.50347 25.1485 8.75478 24.2786 8.75478C23.7953 8.75478 23.37 8.67745 23.0027 8.5228C22.6353 8.36814 22.326 8.15549 22.094 7.90417C21.8621 7.63352 21.6881 7.34353 21.5914 7.03422C21.4754 6.70557 21.4174 6.37693 21.4174 6.00962C21.4174 5.19767 21.6687 4.54037 22.1714 4.03774C22.6933 3.51577 23.3506 3.24512 24.1626 3.24512C24.9165 3.24512 25.5545 3.4771 26.0378 3.96041C26.5211 4.40505 26.7724 5.04301 26.7724 5.87429ZM24.1433 4.26972C23.7759 4.26972 23.4666 4.38571 23.196 4.59837C22.9447 4.81102 22.7707 5.101 22.6933 5.46832H25.5158C25.4578 5.101 25.3225 4.81102 25.0712 4.59837C24.8199 4.38571 24.5106 4.26972 24.1433 4.26972Z"
        fill="#2FAD6F"
      />
      <path
        d="M29.015 8.61955H27.7584V3.34187H28.9956V4.07649C29.1309 3.80584 29.3629 3.59319 29.6529 3.43853C29.9429 3.28387 30.2909 3.20654 30.6582 3.20654C31.2382 3.20654 31.7215 3.39986 32.1274 3.78651C32.5334 4.17315 32.7267 4.71445 32.7267 5.41041V8.63888H31.4508V5.75839C31.4508 5.33308 31.3541 4.9851 31.1415 4.73378C30.9288 4.48247 30.6582 4.36647 30.2909 4.36647C29.9236 4.36647 29.6143 4.5018 29.3629 4.75312C29.1309 5.02377 28.9956 5.35241 28.9956 5.77772L29.015 8.61955Z"
        fill="#2FAD6F"
      />
      <path
        d="M42.7215 5.87429C42.7215 6.04828 42.7021 6.22227 42.6828 6.43492H38.6617C38.739 6.80223 38.9324 7.11155 39.203 7.3242C39.4737 7.53686 39.841 7.65285 40.2856 7.65285C40.9236 7.65285 41.5035 7.47886 42.0255 7.11155L42.5281 8.02016C41.8708 8.50347 41.0976 8.75478 40.2276 8.75478C39.7443 8.75478 39.319 8.67745 38.9517 8.5228C38.5844 8.36814 38.2751 8.15549 38.0431 7.90417C37.8111 7.63352 37.6371 7.34353 37.5404 7.03422C37.4245 6.70557 37.3665 6.37693 37.3665 6.00962C37.3665 5.19767 37.6178 4.54037 38.1204 4.03774C38.6424 3.51577 39.2997 3.24512 40.1116 3.24512C40.8656 3.24512 41.5035 3.4771 41.9868 3.96041C42.4895 4.40505 42.7215 5.04301 42.7215 5.87429ZM40.1116 4.26972C39.7443 4.26972 39.435 4.38571 39.1643 4.59837C38.913 4.81102 38.739 5.101 38.6617 5.46832H41.4842C41.4262 5.101 41.2909 4.81102 41.0396 4.59837C40.7882 4.38571 40.4789 4.26972 40.1116 4.26972Z"
        fill="#2FAD6F"
      />
      <path
        d="M44.9833 8.61955H43.7074V3.34187H44.9253V4.07649C45.254 3.49653 45.7373 3.20654 46.3946 3.20654C46.6072 3.20654 46.8199 3.24521 47.0325 3.32254L46.9165 4.54046C46.6846 4.46313 46.4912 4.42447 46.2979 4.42447C45.8919 4.42447 45.5633 4.55979 45.3313 4.83044C45.0993 5.10109 44.964 5.50707 44.964 6.04837V8.61955H44.9833Z"
        fill="#2FAD6F"
      />
      <path
        d="M50.0484 8.73554C49.2364 8.73554 48.5598 8.48422 48.0378 7.96225C47.5158 7.44029 47.2645 6.78299 47.2645 5.97104C47.2645 5.15909 47.5352 4.5018 48.0571 3.97983C48.5984 3.45786 49.2751 3.20654 50.1064 3.20654C50.6477 3.20654 51.1503 3.34187 51.5756 3.59319C52.0202 3.8445 52.3489 4.21182 52.5615 4.65645L51.5369 5.21709C51.2083 4.65645 50.725 4.3858 50.1257 4.3858C49.6617 4.3858 49.2944 4.54046 48.9851 4.84978C48.6951 5.13976 48.5405 5.5264 48.5405 5.99037C48.5405 6.45435 48.6951 6.82166 48.9851 7.13097C49.2751 7.44029 49.6424 7.59494 50.087 7.59494C50.377 7.59494 50.6477 7.51761 50.9183 7.36296C51.189 7.2083 51.3823 7.01498 51.4983 6.76366L52.5229 7.36296C52.2909 7.78826 51.9622 8.13624 51.5176 8.38756C51.073 8.61955 50.5897 8.73554 50.0484 8.73554Z"
        fill="#2FAD6F"
      />
      <path
        d="M54.6688 8.61955H53.4122V3.34187H54.6301V4.07649C54.9587 3.49653 55.442 3.20654 56.0993 3.20654C56.312 3.20654 56.5246 3.24521 56.7373 3.32254L56.6213 4.54046C56.3893 4.46313 56.196 4.42447 56.0027 4.42447C55.5967 4.42447 55.2681 4.55979 55.0361 4.83044C54.8041 5.10109 54.6688 5.50707 54.6688 6.04837V8.61955Z"
        fill="#2FAD6F"
      />
      <path
        d="M62.305 5.87429C62.305 6.04828 62.2856 6.22227 62.2663 6.43492H58.2452C58.3225 6.80223 58.5159 7.11155 58.7865 7.3242C59.0572 7.53686 59.4245 7.65285 59.8691 7.65285C60.5071 7.65285 61.087 7.47886 61.609 7.11155L62.1116 8.02016C61.4543 8.50347 60.6811 8.75478 59.8111 8.75478C59.3278 8.75478 58.9025 8.67745 58.5352 8.5228C58.1679 8.36814 57.8586 8.15549 57.6266 7.90417C57.3946 7.63352 57.2206 7.34353 57.1239 7.03422C57.0079 6.70557 56.95 6.37693 56.95 6.00962C56.95 5.19767 57.2013 4.54037 57.7039 4.03774C58.2259 3.51577 58.8832 3.24512 59.6951 3.24512C60.4491 3.24512 61.087 3.4771 61.5703 3.96041C62.0536 4.40505 62.305 5.04301 62.305 5.87429ZM59.6951 4.26972C59.3278 4.26972 59.0185 4.38571 58.7478 4.59837C58.4965 4.81102 58.3225 5.101 58.2452 5.46832H61.0677C61.0097 5.101 60.8744 4.81102 60.6231 4.59837C60.3524 4.38571 60.0431 4.26972 59.6951 4.26972Z"
        fill="#2FAD6F"
      />
      <path
        d="M67.2927 4.01845V1.23462H68.5492V8.63884H67.312V7.94288C66.8674 8.48418 66.3067 8.75483 65.6301 8.75483C64.8761 8.75483 64.2382 8.48418 63.7549 7.96221C63.2522 7.42091 63.0009 6.76362 63.0009 5.971C63.0009 5.19771 63.2522 4.54042 63.7549 3.99912C64.2575 3.45782 64.8761 3.2065 65.6301 3.2065C66.3067 3.2065 66.848 3.47715 67.2927 4.01845ZM65.8041 7.5949C66.2681 7.5949 66.6354 7.44024 66.9253 7.13093C67.2153 6.82161 67.3507 6.43497 67.3507 5.99033C67.3507 5.54569 67.196 5.15905 66.906 4.84973C66.6354 4.54042 66.2487 4.38576 65.8041 4.38576C65.3401 4.38576 64.9728 4.54042 64.7021 4.84973C64.4122 5.15905 64.2768 5.54569 64.2768 5.99033C64.2768 6.43497 64.4122 6.82161 64.7021 7.13093C64.9728 7.44024 65.3594 7.5949 65.8041 7.5949Z"
        fill="#2FAD6F"
      />
      <path
        d="M70.5211 2.70393C70.2891 2.70393 70.0958 2.62661 69.9412 2.47195C69.7865 2.31729 69.7092 2.12397 69.7092 1.89198C69.7092 1.66 69.7865 1.46668 69.9412 1.33135C70.0958 1.17669 70.2891 1.09937 70.5211 1.09937C70.7531 1.09937 70.9464 1.17669 71.0818 1.33135C71.2364 1.48601 71.3137 1.67933 71.3137 1.89198C71.3137 2.12397 71.2364 2.31729 71.0818 2.47195C70.9464 2.62661 70.7531 2.70393 70.5211 2.70393ZM71.1397 3.3419V8.61958H69.8638V3.3419H71.1397Z"
        fill="#2FAD6F"
      />
      <path
        d="M75.4121 7.3242L75.8181 8.31014C75.4121 8.60012 74.8902 8.75478 74.2909 8.75478C73.0923 8.75478 72.4736 8.05882 72.4736 6.68624L72.493 1.46655H73.7496V3.34177H75.5281V4.40504H73.7496V6.62824C73.7496 7.2662 74.0009 7.57551 74.5228 7.57551C74.8128 7.59485 75.1028 7.49819 75.4121 7.3242Z"
        fill="#2FAD6F"
      />
      <path
        d="M36.6512 7.3242L37.0572 8.31014C36.6512 8.60012 36.1292 8.75478 35.5299 8.75478C34.3313 8.75478 33.7127 8.05882 33.7127 6.68624L33.732 1.46655H34.9886V3.34177H36.7672V4.40504H34.9886V6.62824C34.9886 7.2662 35.2399 7.57551 35.7619 7.57551C36.0519 7.59485 36.3612 7.49819 36.6512 7.3242Z"
        fill="#2FAD6F"
      />
    </svg>
  </Icon>
)

import styled, { css } from 'styled-components'

import { AppLink } from 'shared/lib/app-link'

export const SearchEventItemWrapper = styled(AppLink)`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
    &:hover,
    &:focus {
      background: ${theme.tokens.colors.surface['surface-default']};
      outline: none;
      ${StyledRowIconsWrapper} {
        opacity: 1;
      }
    }
  `}
`

export const MainInfo = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const LiveAdditionalContentWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${(p) => p.theme.tokens.alias.space['space-xxxs-4']}px;
`

export const LineAdditionalContentWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: ${(p) => p.theme.tokens.alias.space['space-xxxs-4']}px;
  grid-template-columns: auto auto auto minmax(0, 1fr);
`

export const CurrentTime = styled.div`
  align-items: center;
  display: flex;
  gap: ${(p) => p.theme.tokens.alias.space['space-xxxxs-2']}px;
`

export const StyledRowIconsWrapper = styled.div`
  align-items: center;
  display: flex;
  opacity: 0;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

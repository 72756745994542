import styled, { css } from 'styled-components'

export const PopularRequestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const PopularRequestsItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

import { FC } from 'react'
import { Event } from 'betweb-openapi-axios'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import {
  MainInfo,
  SearchEventItemWrapper,
  StyledRowIconsWrapper
} from 'features/global-search/ui/search-event-item/search-event-item.styled'
import {
  getCompetitorsNames,
  getEventHomeAwayCompetitors
} from 'shared/lib/events'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { Hotkey } from 'features/global-search/ui/hotkey'
import { IconChevronRightOutline16 } from 'shared/ui/Icon/General/icon-chevron-right-outline-16'
import {
  useGlobalSearchContext,
  SearchHighlighter,
  useGlobalSearchValueContext
} from 'features/global-search'
import { getLinkEventPage } from 'entities/event'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { LineInfo } from './line-info'
import { LiveInfo } from './live-info'

interface Props {
  event: Event
}

export const SearchEventItem: FC<Props> = ({ event }) => {
  const { setValue } = useGlobalSearchValueContext()

  const sport = useAppSelector((state) =>
    selectSport(state, event.tournament.sportId)
  )

  const { homeCompetitors, awayCompetitors } =
    getEventHomeAwayCompetitors(event)

  const link = getLinkEventPage({
    eventId: event.id,
    isEventLive: event.live,
    tournamentSportId: event.tournament.sportId,
    tournamentId: event.tournament.id
  })

  const { setIsOpen } = useGlobalSearchContext()

  const handleLinkClick = () => {
    setIsOpen(false)
    setValue('')
  }

  return (
    <SearchEventItemWrapper to={link} data-focusable onClick={handleLinkClick}>
      <SportIcon
        numericSize={14}
        size="s"
        sport={sport?.code as ESportsCodes}
      />
      <MainInfo>
        <SearchHighlighter
          text={`${getCompetitorsNames(homeCompetitors)}&nbsp;—&nbsp;
          ${getCompetitorsNames(awayCompetitors)}`}
          font="Body / 12 SemiBold"
          lineClamp={1}
        />
        {event.live ? <LiveInfo event={event} /> : <LineInfo event={event} />}
      </MainInfo>
      <StyledRowIconsWrapper>
        <Hotkey />
        <IconChevronRightOutline16 colorToken="icon-secondary-3" />
      </StyledRowIconsWrapper>
    </SearchEventItemWrapper>
  )
}

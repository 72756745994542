import styled, { css, keyframes } from 'styled-components'
import { CSSProperties } from 'react'

const skeletonLoading = keyframes`
    0% {
        background-position: -300px 0;/*  modified */
    }

    100% {
        background-position: 300px 0;/*  modified */
    }
`

export const StyledContentLoader = styled.div<{
  baseColor: string
  highlightColor: string
  animationDuration: CSSProperties['animationDuration']
  disableAnimation: boolean
}>`
  ${({ baseColor, highlightColor, animationDuration, disableAnimation }) =>
    disableAnimation
      ? css`
          background-color: ${baseColor};
        `
      : css`
          animation: ${skeletonLoading} ${animationDuration} linear infinite; /* duration to set to your needs */
          background: linear-gradient(
              to right,
              ${baseColor} 46%,
              ${highlightColor} 50%,
              ${baseColor} 54%
            )
            fixed;
          background-size: 100vw auto;
        `};
`

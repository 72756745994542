import React from 'react'

import { IconInstagram } from 'shared/ui/Icon/General/IconInstagram'
import { IconButton } from 'shared/ui/buttons/IconButton'

import { LINK_INSTAGRAM } from '../lib'

export const OpenInstagram = () => (
  <IconButton
    as="a"
    href={LINK_INSTAGRAM}
    icon={IconInstagram}
    rel="noreferrer"
    target="_blank"
    view="fill-l"
  />
)

import React, { FC, ReactNode } from 'react'

import { StepProps } from './step.types'
import * as S from './step.styled'

export const Step: FC<StepProps> & {
  ControlButtons: typeof ControlButtons
  Info: typeof Info
} = ({ numberSteps, currentStepNumber, children, Description, title }) => {
  return (
    <S.Step>
      <S.StepText>
        ШАГ {currentStepNumber}/{numberSteps}
      </S.StepText>

      <S.TitleText>{title}</S.TitleText>

      {Description && <S.DescriptionText>{Description}</S.DescriptionText>}

      <S.ContentContainer>{children}</S.ContentContainer>
    </S.Step>
  )
}

// Necessary for Formik, as it requires all data under one form component
const ControlButtons: FC<{ children: ReactNode }> = ({ children }) => (
  <S.ControlButtonsContainer>{children}</S.ControlButtonsContainer>
)

const Info: FC<{ children: string }> = ({ children }) => (
  <S.InfoText>{children}</S.InfoText>
)

Step.ControlButtons = ControlButtons
Step.Info = Info

import { useInjectSaga } from 'astra-core'

import { useInjectReducer } from 'shared/lib/@reduxjs'

import { reducer, sliceKey } from './slice'
import { searchTournamentContainerSaga } from './saga'

export const useSearchTournamentProvider = () => {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: searchTournamentContainerSaga })
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M8.78571 8.38477C12.2472 8.38477 15.7088 8.38477 19.1703 8.38477C18.6214 8.9934 18.0577 9.58544 17.5335 10.2273C17.2368 10.5925 16.9253 10.7142 16.4852 10.7087C13.9978 10.6865 11.5154 10.6976 9.02802 10.7031C8.18241 10.7031 7.40109 10.9023 6.76813 11.5995C5.90769 12.5456 4.10439 14.508 3.23406 15.4487C3.18956 15.4985 3.15494 15.5593 3.11538 15.6147C2.07692 15.6147 1.03846 15.6147 0 15.6147C0.0197802 15.587 0.0296703 15.5483 0.0543955 15.5261C1.42912 14.0101 3.72692 11.4501 5.11153 9.93956C5.88296 9.10407 6.79285 8.57289 7.88571 8.48436C8.18241 8.46223 8.48406 8.41796 8.78571 8.38477Z"
        fill={theme.tokens.colors['sport-icon']['sport-formula1']}
      />
      <path
        d="M7.49997 15.6151C6.54557 15.6151 5.58623 15.6151 4.63184 15.6151C4.68623 15.5432 4.73074 15.4713 4.79008 15.4049C5.4181 14.7022 6.08074 14.0271 6.67414 13.2912C7.35161 12.4502 8.18733 12.1293 9.1615 12.1348C11.6093 12.1404 13.1291 12.1348 15.5769 12.1348C15.6362 12.1348 15.6906 12.1514 15.7648 12.157C15.1813 12.8154 14.6175 13.4462 14.0637 14.088C13.945 14.2263 13.8214 14.2706 13.6533 14.2706C11.9077 14.2651 11.0851 14.2761 9.33953 14.2761C8.9637 14.2761 8.62249 14.3813 8.34557 14.6745C8.05381 14.9844 7.77689 15.2998 7.49997 15.6151Z"
        fill={theme.tokens.colors['sport-icon']['sport-formula1']}
      />
      <path
        d="M20.6868 8.38477C21.7895 8.38477 22.8972 8.38477 24 8.38477C23.9357 8.46776 23.8763 8.55629 23.8071 8.63375C22.1011 10.5371 19.467 13.4789 17.756 15.3878C17.6917 15.4597 17.6423 15.5372 17.5879 15.6147C16.4851 15.6147 15.3774 15.6147 14.2747 15.6147C14.3291 15.5372 14.3785 15.4542 14.4428 15.3823C16.1538 13.4678 18.7978 10.5261 20.5137 8.61162C20.573 8.53969 20.6274 8.46223 20.6868 8.38477Z"
        fill={theme.tokens.colors['sport-icon']['sport-formula1']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M5.85715 5.58984C8.16484 5.58984 10.4725 5.58984 12.7802 5.58984C12.4143 5.9956 12.0385 6.3903 11.689 6.81819C11.4912 7.06165 11.2835 7.1428 10.9901 7.13911C9.33188 7.12435 7.67693 7.13173 6.01869 7.13542C5.45495 7.13542 4.93407 7.26821 4.51209 7.73299C3.93847 8.36377 2.73627 9.67203 2.15605 10.2991C2.12638 10.3323 2.1033 10.3729 2.07693 10.4098C1.38462 10.4098 0.692308 10.4098 0 10.4098C0.0131868 10.3913 0.0197802 10.3655 0.0362638 10.3508C0.952748 9.34005 2.48462 7.6334 3.4077 6.62638C3.92198 6.06938 4.52858 5.71526 5.25715 5.65624C5.45495 5.64149 5.65605 5.61198 5.85715 5.58984Z"
        fill={theme.tokens.colors['sport-icon']['sport-formula1']}
      />
      <path
        d="M4.99998 10.4101C4.36372 10.4101 3.72416 10.4101 3.08789 10.4101C3.12415 10.3621 3.15382 10.3142 3.19339 10.2699C3.61207 9.80146 4.05383 9.35143 4.44943 8.86083C4.90108 8.30015 5.45822 8.0862 6.10767 8.08989C7.73954 8.09358 8.75273 8.08989 10.3846 8.08989C10.4242 8.08989 10.4604 8.10096 10.5099 8.10465C10.1209 8.5436 9.74504 8.96412 9.37581 9.39201C9.29669 9.48423 9.21427 9.51374 9.10218 9.51374C7.93845 9.51005 7.39009 9.51743 6.22636 9.51743C5.97581 9.51743 5.74833 9.58751 5.56372 9.78301C5.36921 9.98958 5.1846 10.1998 4.99998 10.4101Z"
        fill={theme.tokens.colors['sport-icon']['sport-formula1']}
      />
      <path
        d="M13.7912 5.58984C14.5263 5.58984 15.2648 5.58984 16 5.58984C15.9571 5.64517 15.9176 5.70419 15.8714 5.75584C14.734 7.02476 12.978 8.98593 11.8373 10.2585C11.7945 10.3065 11.7615 10.3581 11.7252 10.4098C10.9901 10.4098 10.2516 10.4098 9.51645 10.4098C9.55271 10.3581 9.58568 10.3028 9.62854 10.2549C10.7692 8.97855 12.5318 7.01738 13.6758 5.74108C13.7154 5.69313 13.7516 5.64149 13.7912 5.58984Z"
        fill={theme.tokens.colors['sport-icon']['sport-formula1']}
      />
    </>
  )
}

export const IconSportFormula1: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

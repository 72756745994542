import { IdentificationConstraints, ProviderState } from '../../store/types'

export const MAP_IDENTIFY_CONSTRAINTS_TO_STORE_PROPS: Record<
  IdentificationConstraints,
  keyof ProviderState['data']['constraints']
> = {
  [IdentificationConstraints.TOP_UP]: 'isTopUpBlocked',
  [IdentificationConstraints.WITHDRAWAL]: 'isWithdrawalBlocked',
  [IdentificationConstraints.BETS]: 'isBetsBlocked'
}

export interface TopUpMethod {
  name: string // Название
  tax: string // Комиссия
  time: string // Время на зачисление платежа
  min: string // Минимальная сумма пополнения
  max: string // Максимальная сумма пополнения
  img: string // Путь до иконки (https://olimapi.cc)
  request_money: boolean // Признак, что для данного метода посылать сумму не нужно или необязательно
  bonus_text: string | null
  order: number
  category: TopUpMethodCategories
  /*
   * 1 - Bank cards
   * 2 - SIM
   * 4 - Cash
   * */
  category_name: string
  limits: string
}

export enum TopUpMethodCategories {
  BANK_CARD = 1,
  SIM = 2,
  CASH = 3,
  CASH_2 = 4
}

export interface GetTopUpFetch {
  request: void
  response: {
    isCache: boolean //	Данные из кэша или из базы (true / false)
    error: {
      //	Объект ошибки (err_code / err_desc)
      err_code: number //	0 (поле отдается в случае успешного ответа)
      err_desc: string | null // (поле отдается в случае успешного ответа)
    }
    data: Record<LegacyPaymentTopUpVariantKeys, TopUpMethod>
  }
}
export interface GetWithdrawalListFetch {
  request: void
  response: {
    isCache: boolean //	Данные из кэша или из базы (true / false)
    error: {
      //	Объект ошибки (err_code / err_desc)
      err_code: number //	0 (поле отдается в случае успешного ответа)
      err_desc: string | null // (поле отдается в случае успешного ответа)
    }
    data: Record<LegacyPaymentWithdrawalVariantKeys, TopUpMethod>
  }
}

export enum LegacyPaymentTopUpVariantKeys {
  ACTIV = 'activekz',
  ALTEL_WOO_PAY = 'altelwoopay',
  B_BANK = 'bbank',
  BEELINE_KZ = 'beelinekz',
  VISA_MASTERCARD = 'card',
  GOOGLE_PAY = 'googlepay',
  KASPI_KZ = 'kaspi',
  KCELL_KZ = 'kcellkz',
  PAYDALA_KASSA = 'paydala_kassa',
  QIWI = 'qiwi',
  QWT = 'qwt',
  TELE2 = 'tele2',
  WOOPPAY = 'wooppay',
  KCELL_ACTIVKZ = 'kcellactivkz'
}

export enum LegacyPaymentWithdrawalVariantKeys {
  BANK_CARD = 'bankcard',
  BANK_CARD_FROM_IIN = 'bankcardfromiin',
  PAYDALA_ACTIV = 'paydala_activ',
  PAYDALA_ALTEL = 'paydala_altel',
  PAYDALA_BANK = 'paydala_bank',
  PAYDALA_BEELINE = 'paydala_beeline',
  PAYDALA_KASSA = 'paydala_kassa',
  PAYDALA_KCELL = 'paydala_kcell',
  PAYDALA_TELE2 = 'paydala_tele2',
  QIWI_CBC = 'qiwi_cbc',
  QIWI_WALLET = 'qiwiwallet',
  SIMPLY = 'simply',
  WOOPPAY = 'wooppay',
  WOOPPAY_BANKS = 'wooppay_banks',
  WP_CBC = 'wp_cbc',
  WP_MOBILE = 'wp_mobile'
}

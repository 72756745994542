import React, { FC, PropsWithChildren } from 'react'

import { Toastify } from 'shared/lib/Toastify'
import { TypeformComponents } from 'widgets/typeform'
import { Settings } from 'widgets/user-account-settings'
import { CouponSettings } from 'widgets/coupon-settings'

import * as S from './primary.styled'

export const LayoutPrimary: FC<PropsWithChildren> = ({ children }) => (
  <S.Layout>
    <Toastify />
    <Settings />
    <CouponSettings />
    <TypeformComponents />

    {children}
  </S.Layout>
)

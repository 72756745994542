const langs = {
  ru: 0,
  kz: 1, // kk
  en: 2,
  zh: 3,
  it: 5,
  es: 6,
  tr: 14,
  uz: 22,
  kg: 10
}

export const i18nLocalToLegacyLang = (locale: string): number => {
  return langs[locale]
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  HotProbsBlock,
  ProbabilitiesTable,
  Wrapper
} from 'features/hot-probabilities/hot-probabilities.styled'
import { HotProbabilityCell } from 'features/hot-probabilities/hot-probability-cell'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

import {
  HotProbabilitiesConfig,
  HotProbabilitiesProps
} from './hot-probabilities.types'

export const HotProbabilities: FC<HotProbabilitiesProps> = ({ eventId }) => {
  const { t } = useTranslation()
  const hydrated = useHydrated()

  const outcomeMainProbabilities: HotProbabilitiesConfig = [
    {
      label: t('w1'),
      mainOutcomeTypes: ['W1', 'W1_X2']
    },
    {
      label: 'X',
      mainOutcomeTypes: ['WX']
    },
    {
      label: t('w2'),
      mainOutcomeTypes: ['W2', 'W2_X2']
    },
    {
      label: '1X',
      mainOutcomeTypes: ['W1X']
    },
    {
      label: '12',
      mainOutcomeTypes: ['W12']
    },
    {
      label: 'X2',
      mainOutcomeTypes: ['WX2']
    }
  ]

  const handicapMainProbabilities: HotProbabilitiesConfig = [
    {
      label: t('hc1'),
      mainOutcomeTypes: ['HC1']
    },
    {
      label: t('hc2'),
      mainOutcomeTypes: ['HC2']
    }
  ]

  const totalMainProbabilities: HotProbabilitiesConfig = [
    {
      label: t('toc'),
      mainOutcomeTypes: ['TO']
    },
    {
      label: t('tuc'),
      mainOutcomeTypes: ['TU']
    }
  ]

  if (!hydrated) {
    return null
  }

  return (
    <Wrapper>
      <HotProbsBlock>
        <ProbabilitiesTable>
          {outcomeMainProbabilities.map((probability) => (
            <HotProbabilityCell
              eventId={eventId}
              key={probability.label}
              label={probability.label}
              mainOutcomeTypes={probability.mainOutcomeTypes}
            />
          ))}
        </ProbabilitiesTable>
      </HotProbsBlock>
      <HotProbsBlock>
        <ProbabilitiesTable>
          {handicapMainProbabilities.map((probability) => (
            <HotProbabilityCell
              eventId={eventId}
              key={probability.label}
              label={probability.label}
              mainOutcomeTypes={probability.mainOutcomeTypes}
              withParameter
              withParameterSign
            />
          ))}
        </ProbabilitiesTable>
        <ProbabilitiesTable>
          {totalMainProbabilities.map((probability) => (
            <HotProbabilityCell
              eventId={eventId}
              key={probability.label}
              label={probability.label}
              mainOutcomeTypes={probability.mainOutcomeTypes}
              withParameter
            />
          ))}
        </ProbabilitiesTable>
      </HotProbsBlock>
    </Wrapper>
  )
}

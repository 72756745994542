import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'

import { ERoutes } from 'shared/types/routes'

export const BASKET_CONTAINER_MIN_HEIGHT = 552
export const CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT = 5000
export const RIGHT_CONTAINER_WIDTH = 304
export const IFRAME_TOP_UP_URL =
  'https://olimpbet.kz/index.php?page=account&action=in'

export const BASKET_ERROR_ACTIONS = {
  [ALL_BASKET_ERROR_MESSAGES.CLIENT_BETS_BLOCK]: [
    { id: '1', action: () => window.LC_API.open_chat_window() }
  ],
  [ALL_BASKET_ERROR_MESSAGES.BETS_INSUFFICIENT_BALANCE]: [
    { id: '1', action: ERoutes.ProfileBalance }
  ]
}

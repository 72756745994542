import styled from 'styled-components'

import { AppLink } from 'shared/lib/app-link'

export const StyledCompetitorsCell = styled.td`
  padding-left: 16px;
  padding-right: 8px;
  width: 100%;
`

export const CompetitorsWrapper = styled(AppLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CompetitorName = styled.div`
  align-items: center;
  display: grid;
  gap: 4px;
  grid-template-columns: minmax(0, 1fr) auto;
`

export const CurrentServerPoint = styled.div`
  background-color: #0d80f2;
  border-radius: 50%;
  height: 4px;
  width: 4px;
`

import { Props as SelectProps } from 'react-select/src/Select'
import styled, { css } from 'styled-components'

import { Select } from 'shared/ui/select'
import { Input } from 'shared/ui/Input'
import { typography } from 'shared/lib/theme/typography'
import { SelectWrapper } from 'shared/ui/select/select.styled'

export const StyledContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledContainerBody = styled.div`
  display: flex;
  gap: 16px;
  position: relative;
`

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 50%;

  ${SelectWrapper} {
    width: 160px !important;
  }
`
export const InputRange = styled(Input)`
  ${typography['Body / 14 SemiBold']}
  cursor: pointer;
  padding: 6px 12px;
  width: 120px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-active']};
    border-color: ${theme.tokens.colors.border['border-default-gray']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    color: ${theme.tokens.colors.text['text-primary-2']};

    &::-webkit-datetime-edit-day-field:focus,
    &::-webkit-datetime-edit-month-field:focus,
    &::-webkit-datetime-edit-year-field:focus {
      background-color: ${theme.tokens.colors.surface['surface-active-4']};
      color: ${theme.tokens.colors.text['text-primary-2']};
      outline: none;
    }

    &:hover {
      border-color: ${theme.tokens.colors.border['border-settings-active']};
    }

    &:focus {
      border-color: ${theme.tokens.colors.border['border-active']};
    }
  `}

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`

export const SelectRange = styled(Select)<SelectProps>`
  position: relative;
  z-index: 5;

  &.selectRange {
    div:nth-of-type(2) {
      min-width: 100%;
      top: 36px;
    }
  }
`

export const StyledDashWrapper = styled.div`
  padding: 0 4px;

  &::after {
    color: ${(props) => props.theme.colors.default.primary[90]};
    content: '-';
    display: block;
  }
`

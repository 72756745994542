import { identificationModel } from 'entities/constration'

import { AlertPassIdentification } from '../../alert-pass-identification'

const { useGetIsBlockedConstraint, IdentificationConstraints } =
  identificationModel

export const ErrorPassIdentification = () => {
  const isBlockedWithoutIdentification = useGetIsBlockedConstraint(
    IdentificationConstraints.BETS
  )

  return isBlockedWithoutIdentification ? <AlertPassIdentification /> : null
}

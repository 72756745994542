import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconTime: FC<IconProps> = (props) => {
  const theme = useTheme()
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="12"
        viewBox="0 0 12 12"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M7.38866 1C7.83069 1 8.24771 1.17515 8.56464 1.49208L10.5079 3.4437C10.8249 3.75229 11 4.17765 11 4.61968V7.38032C11 7.82235 10.8249 8.23937 10.5079 8.5563L8.5563 10.5079C8.24771 10.8249 7.82235 11 7.38032 11H4.61968C4.17765 11 3.76063 10.8249 3.4437 10.5079L1.49208 8.5563C1.17515 8.24771 1 7.82235 1 7.38032V4.61968C1 4.17765 1.17515 3.76063 1.49208 3.4437L3.45204 1.49208C3.76063 1.17515 4.18599 1 4.62802 1H7.38866Z"
          fill={theme.tokens.colors.icon['icon-secondary-3']}
          fillRule="evenodd"
        />
        <path
          d="M6.71428 4.21441C6.71428 3.81992 6.39448 3.50012 5.99999 3.50012C5.6055 3.50012 5.28571 3.81992 5.28571 4.21441V6.00012C5.28571 6.21711 5.38434 6.42233 5.55378 6.55789L7.3395 7.98646C7.64754 8.23289 8.09703 8.18295 8.34347 7.8749C8.5899 7.56686 8.53996 7.11737 8.23192 6.87093L6.71428 5.65682V4.21441Z"
          fill={theme.tokens.colors.icon['icon-alwaysWhite']}
        />
      </svg>
    </Icon>
  )
}

import styled, { css } from 'styled-components'

import {
  IIframeVisibilityControl,
  IIframeWrapper
} from 'widgets/auth/auth.types'

export const AuthWindow = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-overlay']};
  `}
`

export const IframeWrapper = styled.div<IIframeWrapper>`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xl-16'
    ]}px;
    box-shadow: inset 0 0 0 1px ${theme.tokens.colors.border['border-primary']};
  `}

  height: fit-content;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 375px;
  z-index: 50;
`

export const IframeVisibilityControl = styled.div<IIframeVisibilityControl>`
  ${(props) =>
    props.isLoading
      ? `
        height: 0;
        overflow: hidden;
        visibility: hidden;
      `
      : ''};
`

export const Iframe = styled.iframe`
  border: none;
`

export const StyledLoaderSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
    padding: ${theme.tokens.alias.space['space-xxl-32']}px;
  `}
`

export const ModalSkeletonTitleBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const ModalSkeletonInputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const ModalSkeletonButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledLoginLoaderWrapper = styled.div`
  align-items: center;
  background: ${(p) => p.theme.tokens.colors.surface['surface-modal']};
  border-radius: 16px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

import { FC, useCallback } from 'react'
import {
  betsHistoryProviderActions,
  selectBetsHistoryFiltersDates,
  selectBetsHistorySelect
} from 'astra-core/containers/BetsHistoryProvider'

import { DatePickerRange } from 'shared/ui/DatePicker'
import { getIsResetPagination } from 'widgets/bets-history-legacy/components/History/constants'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { CURRENT_DATE } from './constants'
import { IPickerPeriodProps } from './PickerPeriod.types'

export const PickerPeriod: FC<IPickerPeriodProps> = ({ resetPagination }) => {
  const dispatch = useAppDispatch()
  const dates = useAppSelector(selectBetsHistoryFiltersDates)
  const selectValue = useAppSelector(selectBetsHistorySelect)

  const onChangeDatePicker = useCallback(
    (dateRangeArray) => {
      if (getIsResetPagination(dateRangeArray)) {
        resetPagination()
      }

      dispatch(
        betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
      )
    },
    [dispatch, resetPagination]
  )

  const onChangeInputDatePicker = useCallback(
    (data) =>
      dispatch(betsHistoryProviderActions.setDatePickerRangeInputValue(data)),
    [dispatch]
  )

  const onChangeSelect = useCallback(
    (dateRangeArray, value) => {
      if (getIsResetPagination(dateRangeArray)) {
        resetPagination()
      }

      dispatch(
        betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
      )

      dispatch(betsHistoryProviderActions.setDatePickerSelectDatePeriod(value))
    },
    [dispatch, resetPagination]
  )

  const onCalendarClose = useCallback(
    (value) => dispatch(betsHistoryProviderActions.handleCalendarClose(value)),
    [dispatch]
  )

  return (
    <DatePickerRange
      maxDate={CURRENT_DATE}
      popperPlacement="bottom-end"
      rangeCalendarDates={dates}
      selectValue={selectValue}
      // @ts-ignore
      onCalendarClose={onCalendarClose}
      onChange={onChangeDatePicker}
      onChangeInputRange={onChangeInputDatePicker}
      onChangeSelect={onChangeSelect}
    />
  )
}

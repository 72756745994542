import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledIconWrapper = styled(NavLink)`
  align-items: center;
  display: flex;
  position: relative;

  & span {
    height: 24px;
  }
`

export const StyledIconValue = styled.div`
  bottom: 5.5px;
  font-size: 9px;
  font-weight: 800;
  height: 12px;
  left: 11px;
  line-height: 12px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
  position: absolute;
  right: 4px;
  text-align: center;
  top: 6.5px;
  width: 17px;
`

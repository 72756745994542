import { BetType } from 'betweb-openapi-axios'

import { BetHistoryItem } from 'features/bet-history-card/bet-history-card.types'

export const getExpressSystemOutcomesCount = (item: BetHistoryItem) => {
  if (item.betType === BetType.System) {
    return `${item.systemSize}/${item.outcomes.length}`
  }
  if (item.betType === BetType.Express) {
    return item.outcomes.length.toString()
  }
  return ''
}

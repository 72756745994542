import React, { useEffect } from 'react'

import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { CalendarMenuList } from './components'
import { StyledSportsMenu, StyledWrapper } from './calendar-menu.styled'

export const CalendarMenu = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(calendarEventsContainerActions.fetchScheduledEventsCounters())
  }, [dispatch])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <CalendarMenuList />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}

import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useTournamentsGroupedBySubSport } from 'entities/line-tournaments/model/hooks'
import { selectLayoutIsLoading } from 'entities/line-tournaments/model/selectors'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { LineTournamentsParams } from 'entities/line-tournaments/model/types'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { StyledLoadingIndicatorWrapper } from 'widgets/tournaments-list'
import { ClearSearchResults } from 'features/clear-search-results'
import { EmptyPageFilter } from 'features/empty-page-filter'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { LineTournamentsGroups } from '../line-tournaments-group'

export const LineTournamentsContent = () => {
  const dispatch = useAppDispatch()
  const { sportId } = useParams<LineTournamentsParams>()
  const searchText = useAppSelector(selectInputSearchLine)

  const isLayoutIsLoading = useAppSelector(selectLayoutIsLoading)

  const { isGenericSport, groupedTournaments } =
    useTournamentsGroupedBySubSport({
      sportId: +sportId
    })

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetFilters())
  }, [dispatch])

  const hasResults = groupedTournaments.length > 0

  return (
    <>
      {isLayoutIsLoading ? (
        <StyledLoadingIndicatorWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorWrapper>
      ) : !isLayoutIsLoading && hasResults ? (
        <LineTournamentsGroups
          groupedTournaments={groupedTournaments}
          showHeader={isGenericSport}
        />
      ) : searchText ? (
        <ClearSearchResults />
      ) : (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </>
  )
}

import { FC } from 'react'
import { Event } from 'betweb-openapi-axios'

import { Typography } from 'shared/ui/typography'
import { IconTime } from 'shared/ui/Icon/General/icon-time'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { useTimerCountdown } from 'shared/lib/events/timer-countdown'

import {
  CurrentTime,
  LiveAdditionalContentWrapper
} from './search-event-item.styled'

interface Props {
  event: Event
}

export const LiveInfo: FC<Props> = ({ event }) => {
  const score = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.Score
  )

  const { hasTime, value } = useTimerCountdown({ event })

  return (
    <LiveAdditionalContentWrapper>
      {score?.value && (
        <Typography font="Hint / 10 Bold">{score.value}</Typography>
      )}
      {hasTime && (
        <CurrentTime>
          <IconTime size={12} />
          <Typography color="text-secondary-3" font="Hint / 10 Bold">
            {value}
          </Typography>
        </CurrentTime>
      )}
      {(score?.value || hasTime) && (
        <Typography color="text-secondary-3" font="Hint / 10 Bold">
          •
        </Typography>
      )}
      <Typography
        color="text-secondary-3"
        font="Hint / 10 SemiBold"
        lineClamp={1}
      >
        {event.tournament.name}
      </Typography>
    </LiveAdditionalContentWrapper>
  )
}

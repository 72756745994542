import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'

import {
  ContainerState,
  SetDisplayWelcomeScreenPayload,
  SetIsDisplayModalChangeLanguagePayload,
  SetIsDisplayModalCouponSettingPayload,
  SetIsDisplayModalSettingsPayload,
  SetIsShowMyBonusesToastifyNotification,
  SetOnboardingPayload
} from './types'

const REDUCER_KEY = 'layoutContainer'

// The initial state of the LayoutContainer container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  isHiddenWelcomeScreen: false,
  isShowMyBonusesToastifyNotification: false,
  isDisplayModalSettings: false,
  isDisplayModalCouponSetting: false,
  isDisplayModalChangeLanguage: false,
  onboarding: {
    isDisplay: false,
    type: EOnboardingType.MAIN_PAGE_WELCOME
  },
  isDisplayNotificationPanel: false
})

const layoutContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setHiddenWelcomeScreen(
      state,
      { payload }: PayloadAction<SetDisplayWelcomeScreenPayload>
    ) {
      state.isHiddenWelcomeScreen = payload
    },
    setIsShowMyBonusesToastifyNotification(
      state,
      { payload }: PayloadAction<SetIsShowMyBonusesToastifyNotification>
    ) {
      state.isShowMyBonusesToastifyNotification = payload
    },
    setIsDisplayModalSettings(
      state,
      { payload }: PayloadAction<SetIsDisplayModalSettingsPayload>
    ) {
      state.isDisplayModalSettings = payload
    },
    setIsDisplayModalCouponSetting(
      state,
      { payload }: PayloadAction<SetIsDisplayModalCouponSettingPayload>
    ) {
      state.isDisplayModalCouponSetting = payload
    },
    setIsDisplayModalChangeLanguage(
      state,
      { payload }: PayloadAction<SetIsDisplayModalChangeLanguagePayload>
    ) {
      state.isDisplayModalChangeLanguage = payload
    },
    setOnboarding(state, { payload }: PayloadAction<SetOnboardingPayload>) {
      state.onboarding = {
        ...state.onboarding,
        ...payload
      }
    },
    resetOnboarding(state) {
      state.onboarding = initialState.onboarding
    },
    toggleIsDisplayNotificationPanel(
      state,
      { payload }: PayloadAction<ContainerState['isDisplayNotificationPanel']>
    ) {
      state.isDisplayNotificationPanel = payload
    }
  }
})

export const {
  actions: layoutContainerActions,
  reducer,
  name: sliceKey
} = layoutContainerSlice

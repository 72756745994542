import React, { FC } from 'react'
import { BasketOutcome } from 'betweb-openapi-axios'

import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { useEvents } from 'entities/basket'

import { CardDependentList } from '../../card-dependent'
import { Styles } from '../../basket'
import { CardSystem } from '../card'

export const CardList: FC<{ outcomeList: BasketOutcome[] }> = ({
  outcomeList
}) => {
  const { events, dependentEvents } = useEvents(outcomeList)

  return (
    <Styles.StyledBasketBodyList>
      <Styles.StyledBetGroupsList>
        <BasketTooltipProvider>
          <CardDependentList dependentEvents={dependentEvents} />

          <Styles.StyledBetCardList>
            {events?.map((outcome) => (
              <CardSystem key={outcome.id} outcome={outcome} />
            ))}
          </Styles.StyledBetCardList>
        </BasketTooltipProvider>
      </Styles.StyledBetGroupsList>
    </Styles.StyledBasketBodyList>
  )
}

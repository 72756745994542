import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'shared/ui/Tooltip'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'

import * as S from './card-dependent.styled'

export const CardDependentList: FC<{ children: ReactElement[] }> = ({
  children
}) => {
  const [t] = useTranslation()

  return (
    <S.StyledBetGroup>
      <S.StyledBetGroupTitle>
        <div>{t('dependent outcomes')}</div>

        <Tooltip title={t('dependent outcomes hover text')}>
          <IconInfoCircleFilled size={16} />
        </Tooltip>
      </S.StyledBetGroupTitle>

      <S.StyledBetCardDependentList>{children}</S.StyledBetCardDependentList>
    </S.StyledBetGroup>
  )
}

import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconNotification: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.654 3c-.848 0-1.662.337-2.261.936L4.075 7.254c-.6.6-.936 1.413-.936 2.26L3.14 20.36a.64.64 0 0 0 1.08.464l1.496-1.418a.943.943 0 0 1 1.316.018l.36.362c.6.6 1.414.936 2.262.936h4.692c.848 0 1.66-.337 2.26-.936l3.318-3.318c.6-.6.937-1.413.937-2.26V9.514c0-.848-.337-1.661-.937-2.26l-3.318-3.319c-.6-.6-1.412-.936-2.26-.936zM7 14.013a1 1 0 0 1 1-1h6a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1m1-5.026a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

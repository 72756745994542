import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IconSizeMap, IIconsSportNameProps } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M16.9659 1.5674C16.7061 1.69608 16.6744 1.86229 16.8034 2.41869C17.2375 4.28946 17.4381 5.67985 17.5289 7.44667L17.5462 7.78364L17.3981 7.80046C16.5774 7.89351 13.3435 7.92091 11.9993 7.8462C11.5868 7.82327 10.9492 7.80546 10.5825 7.8066C9.93745 7.8086 9.82388 7.81702 8.67945 7.9476C7.7555 8.05304 6.58588 8.01585 6.54403 7.97843C6.50219 7.941 6.7183 7.74662 6.81118 7.65868C7.14769 7.34004 7.15145 6.94685 6.89933 6.81008C6.64721 6.67331 5.18527 6.88932 4.9706 7.91569C4.71015 9.16113 5.80143 9.89121 8.13185 10.0306C8.33871 10.043 8.41563 10.0306 8.45726 10.0807C8.51174 10.1464 8.48182 10.3428 8.51174 10.6256C8.67648 12.1829 8.74932 13.8609 8.68123 14.5306C8.52718 16.0463 7.65804 18.5557 6.30355 21.3957C5.97146 22.092 6.29614 22.6421 6.93458 22.4647C7.4536 22.3205 9.33498 19.8772 10.5153 17.8146L10.815 17.2908L10.9562 17.4811C11.5601 18.2948 12.0073 19.8423 12.1237 21.5207C12.1687 22.1711 12.2235 22.3012 12.506 22.4306C12.952 22.6349 13.3009 22.3798 13.4043 21.7735C13.7506 19.7428 13.6757 15.6317 13.2068 10.9313C13.1618 10.4804 13.1249 10.0792 13.1247 10.0398C13.1245 9.97129 13.1346 9.96665 13.3537 9.93418C14.8033 9.71928 16.5689 9.35235 17.4693 9.07883C17.6605 9.02074 17.2327 12.0849 16.7877 13.9608C16.6557 14.517 16.7866 14.7946 17.1809 14.7946C17.4763 14.7946 17.5919 14.6185 18.0286 13.5022C19.4061 9.98143 19.4138 6.37833 18.0513 2.85722C17.555 1.57479 17.3774 1.36364 16.9659 1.5674ZM10.3047 3.78984C8.80983 4.01911 8.49271 5.59352 9.76696 6.45927C11.1626 7.40748 13.0234 6.61191 12.6928 5.20838C12.4847 4.32512 11.3211 3.63395 10.3047 3.78984Z"
      fill={theme.tokens.colors['sport-icon']['sport-archery']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M11.7834 0.0513494C11.5854 0.149395 11.5613 0.276029 11.6596 0.699955C11.9903 2.1253 12.1431 3.18464 12.2123 4.5308L12.2255 4.78753L12.1127 4.80035C11.4874 4.87124 9.02347 4.89212 7.99934 4.8352C7.68501 4.81773 7.19923 4.80416 6.91983 4.80503C6.42838 4.80655 6.34185 4.81297 5.4699 4.91245C4.76594 4.9928 3.8748 4.96446 3.84292 4.93594C3.81103 4.90743 3.97569 4.75933 4.04645 4.69233C4.30285 4.44955 4.30571 4.14998 4.11362 4.04578C3.92152 3.94157 2.80767 4.10615 2.64411 4.88814C2.44567 5.83705 3.27713 6.39331 5.05268 6.4995C5.21029 6.50895 5.26889 6.4995 5.30061 6.53771C5.34212 6.5877 5.31932 6.73736 5.34212 6.95282C5.46764 8.13933 5.52314 9.41782 5.47126 9.92808C5.35389 11.0829 4.69168 12.9948 3.65969 15.1586C3.40667 15.6892 3.65404 16.1083 4.14048 15.9731C4.53592 15.8632 5.96935 14.0017 6.86865 12.4302L7.097 12.0311L7.20457 12.176C7.66467 12.796 8.00541 13.9751 8.09406 15.2539C8.12841 15.7494 8.17009 15.8485 8.38534 15.9471C8.72521 16.1028 8.99098 15.9084 9.06977 15.4465C9.33361 13.8993 9.27657 10.767 8.91931 7.18576C8.88504 6.84218 8.85691 6.5365 8.85678 6.50649C8.85659 6.45431 8.86428 6.45078 9.03122 6.42604C10.1357 6.26231 11.4809 5.98274 12.167 5.77435C12.3126 5.73009 11.9867 8.0647 11.6476 9.49397C11.5471 9.91775 11.6468 10.1293 11.9472 10.1293C12.1723 10.1293 12.2603 9.99502 12.5931 9.14456C13.6426 6.46204 13.6484 3.71682 12.6104 1.03407C12.2323 0.0569822 12.0969 -0.103893 11.7834 0.0513494ZM6.70816 1.74464C5.56924 1.91932 5.32762 3.11887 6.29848 3.77849C7.36186 4.50094 8.77961 3.89479 8.52766 2.82543C8.3691 2.15247 7.48257 1.62586 6.70816 1.74464Z"
      fill={theme.tokens.colors['sport-icon']['sport-archery']}
      fillRule="evenodd"
    />
  )
}

export const IconSportArchery: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

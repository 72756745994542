import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M0.541608 7.03115C0.205082 6.86959 0.0489046 6.47722 0.181634 6.12832C0.314364 5.77941 0.692069 5.59016 1.051 5.69271L8.98243 7.95883C9.28244 7.3174 10.0329 6.94965 10.7648 7.19362L17.0397 9.28524C17.6278 9.48128 18.1106 9.94708 18.2933 10.5684C18.6464 11.7696 18.7837 12.761 18.7837 14.0981C18.7837 14.2818 18.7103 14.4681 18.5621 14.605C18.4356 14.7219 18.2984 14.7623 18.2247 14.778C18.0832 14.8081 17.9536 14.7923 17.8922 14.7835C17.7452 14.7625 17.5699 14.7133 17.3949 14.6572C17.0312 14.5407 16.5223 14.3456 15.9137 14.0962C14.6905 13.5951 13.0042 12.8497 11.1393 12.0015C7.64734 10.4133 3.50415 8.45344 0.541608 7.03115ZM0.957101 9.38649C1.10954 9.05389 1.50275 8.90784 1.83536 9.06028L17.7305 16.3456C18.4184 16.6486 18.7692 16.5413 18.9145 16.4689C19.0014 16.4255 19.0604 16.3718 19.0958 16.3323C19.1134 16.3127 19.1241 16.2977 19.1283 16.2915C19.3048 15.9808 19.6983 15.8645 20.0158 16.0335C20.3609 16.2171 20.4527 16.6418 20.2664 16.9701C20.2558 16.9878 20.2422 17.0095 20.2254 17.0343C20.1919 17.0839 20.1449 17.1469 20.0828 17.2162C19.9589 17.3545 19.7706 17.5226 19.5055 17.6547C18.947 17.933 18.176 17.9911 17.1909 17.5557L17.1827 17.552L1.28331 10.2647C0.950707 10.1123 0.804658 9.7191 0.957101 9.38649ZM23.6756 12.8862L22.6204 11.5503C21.6803 11.5654 21.5095 12.4051 21.5095 12.4051L22.7158 13.9069C22.1373 14.215 21.4305 14.2671 20.789 13.9721C19.6998 13.4713 19.2222 12.1822 19.7231 11.0924C20.224 10.0037 21.5131 9.52622 22.6025 10.0268C23.6857 10.5249 24.1611 11.8014 23.6756 12.8862Z"
      fill={theme.tokens.colors['sport-icon']['sport-skeleton']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M0.274485 4.80591C0.0475937 4.69698 -0.0577038 4.43243 0.0317847 4.1972C0.121273 3.96196 0.375928 3.83436 0.617929 3.90351L5.96543 5.43136C6.16771 4.9989 6.67369 4.75095 7.16717 4.91544L11.3978 6.32565C11.7943 6.45782 12.1198 6.77188 12.243 7.19076C12.481 8.00063 12.5736 8.66904 12.5736 9.57054C12.5736 9.69444 12.5241 9.82003 12.4242 9.91233C12.3389 9.99116 12.2464 10.0184 12.1968 10.029C12.1014 10.0493 12.0139 10.0386 11.9726 10.0327C11.8734 10.0185 11.7553 9.98537 11.6373 9.94755C11.3921 9.86894 11.0489 9.73741 10.6386 9.56931C9.8139 9.23144 8.67701 8.72887 7.41966 8.15701C5.06529 7.08621 2.27188 5.76483 0.274485 4.80591ZM0.554584 6.39377C0.657364 6.16952 0.922472 6.07105 1.14672 6.17383L11.8635 11.0857C12.3273 11.29 12.5638 11.2176 12.6618 11.1688C12.7204 11.1396 12.7601 11.1034 12.784 11.0767C12.7959 11.0635 12.8031 11.0534 12.8059 11.0493C12.9249 10.8398 13.1902 10.7614 13.4043 10.8753C13.637 10.9991 13.6989 11.2854 13.5733 11.5068C13.5661 11.5187 13.557 11.5333 13.5456 11.5501C13.523 11.5835 13.4913 11.626 13.4495 11.6727C13.3659 11.766 13.239 11.8792 13.0602 11.9683C12.6837 12.156 12.1638 12.1951 11.4997 11.9016L11.4942 11.8991L0.77452 6.9859C0.550272 6.88312 0.451803 6.61801 0.554584 6.39377ZM15.1483 6.82545C15.8786 7.16132 16.2032 8.02068 15.8718 8.75335C15.7197 9.08945 15.5501 9.26784 15.2246 9.4415C14.7768 9.68046 14.3581 9.68437 13.9256 9.48549C13.1912 9.14778 12.8692 8.27868 13.2069 7.54393C13.5446 6.80993 14.4138 6.48797 15.1483 6.82545Z"
      fill={theme.tokens.colors['sport-icon']['sport-skeleton']}
      fillRule="evenodd"
    />
  )
}

export const IconSportSkeleton: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconRefresh: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 5L3.4265 3.0735C3.76688 2.73311 4.17098 2.46311 4.61572 2.27892C5.06046 2.09472 5.53713 1.99995 6.0185 2C6.75101 2.00005 7.4667 2.21957 8.07326 2.63024C8.67982 3.04091 9.14942 3.6239 9.4215 4.304L9.5 4.5M1.5 5H3.5M1.5 5V3M10.5 7L8.5735 8.9265C8.23312 9.26689 7.82902 9.53689 7.38428 9.72108C6.93954 9.90528 6.46287 10.0001 5.9815 10C5.24899 9.99995 4.5333 9.78043 3.92674 9.36976C3.32019 8.95909 2.85058 8.3761 2.5785 7.696L2.5 7.5M10.5 7H8.5M10.5 7V9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </Icon>
)

import { FC } from 'react'

import { Tooltip } from 'shared/ui/Tooltip'
import { IconDependentOutcomes } from 'shared/ui/Icon/General/IconDependentOutcomes'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'

import * as S from './card-dependent.styled'
import { CardDependentProps } from './card-dependent.types'

export const CardDependent: FC<CardDependentProps> = ({
  title,
  isCommonTooltipTitle,
  titleRef,
  Coupon,
  onClickDeleteOutcome
}) => {
  return (
    <S.StyledEventCouponDependentBottomWrapper>
      <S.StyledEventCouponDependentBottom>
        <IconDependentOutcomes />

        <S.StyledEventCouponInner>
          <Tooltip isCommon={!isCommonTooltipTitle} title={title}>
            <S.StyledEventCouponCoefficientName ref={titleRef}>
              {title}
            </S.StyledEventCouponCoefficientName>
          </Tooltip>
        </S.StyledEventCouponInner>

        <S.StyledEventCouponCoefficientValue>
          {Coupon}
        </S.StyledEventCouponCoefficientValue>

        <S.StyledBetCardIcon onClick={onClickDeleteOutcome}>
          <IconCloseOutline16 colorToken="icon-secondary-3" />
        </S.StyledBetCardIcon>
      </S.StyledEventCouponDependentBottom>
    </S.StyledEventCouponDependentBottomWrapper>
  )
}

import { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconDragndrop: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 8 16"
        width="8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 5C2.55228 5 3 4.55228 3 4C3 3.44772 2.55228 3 2 3C1.44772 3 1 3.44772 1 4C1 4.55228 1.44772 5 2 5Z"
          fill="currentColor"
        />
        <path
          d="M2 9C2.55228 9 3 8.55229 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8C1 8.55229 1.44772 9 2 9Z"
          fill="currentColor"
        />
        <path
          d="M3 12C3 12.5523 2.55228 13 2 13C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11C2.55228 11 3 11.4477 3 12Z"
          fill="currentColor"
        />
        <path
          d="M6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5Z"
          fill="currentColor"
        />
        <path
          d="M7 8C7 8.55229 6.55228 9 6 9C5.44772 9 5 8.55229 5 8C5 7.44772 5.44772 7 6 7C6.55228 7 7 7.44772 7 8Z"
          fill="currentColor"
        />
        <path
          d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M17.0346 16.7117C18.4166 15.1522 19.1047 12.7357 19.1047 10.0201C19.1047 5.31016 15.5647 3.00018 12.8948 3.00018C9.51604 3.00018 7.51598 4.10311 6.87862 7.36221C6.85643 7.47566 6.94859 7.57851 7.06419 7.57851C8.25799 7.57851 9.27533 8.1979 9.83361 9.27635C10.3826 10.3369 10.4631 11.7836 9.96367 13.4515C9.87455 13.7491 9.56105 13.9181 9.26345 13.829C8.96584 13.7399 8.79683 13.4264 8.88595 13.1288C9.32929 11.6483 9.21085 10.5205 8.83454 9.79354C8.46749 9.08451 7.83312 8.70351 7.06419 8.70351C6.84583 8.70351 6.55545 8.7434 6.2724 8.80961C5.35438 9.10962 4.89537 10.0827 4.89537 11.0557C4.89537 13.782 7.40622 15.9407 8.67084 16.6707C8.68866 16.6913 8.7066 16.7118 8.72466 16.7321V19.8902C8.72466 20.6702 9.35465 21.0002 10.1346 21.0002H15.6246C16.4046 21.0002 17.0346 20.6702 17.0346 19.8902V16.7117ZM14.6842 17.2671C14.9335 17.0818 14.9854 16.7295 14.8001 16.4801C14.6148 16.2308 14.2625 16.1788 14.0131 16.3641L12.8348 17.2398L11.6565 16.3641C11.4071 16.1788 11.0548 16.2308 10.8695 16.4801C10.6842 16.7295 10.7361 17.0818 10.9854 17.2671L11.8917 17.9406L10.9854 18.6141C10.7361 18.7994 10.6842 19.1518 10.8695 19.4011C11.0548 19.6505 11.4071 19.7024 11.6565 19.5171L12.8348 18.6414L14.0131 19.5171C14.2625 19.7024 14.6148 19.6505 14.8001 19.4011C14.9854 19.1518 14.9335 18.7994 14.6842 18.6141L13.7779 17.9406L14.6842 17.2671Z"
      fill={theme.tokens.colors['sport-icon']['sport-boxing']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M12.6767 12.2621C14.096 10.8759 14.8027 8.72797 14.8027 6.31414C14.8027 2.12753 11.167 0.0742188 8.42484 0.0742188C4.67 0.0742188 2.57301 1.22208 2.11309 4.70388C2.42057 4.68352 2.76521 4.69238 3.14156 4.743C4.18161 4.88288 4.92245 5.48044 5.38544 6.31392C5.8386 7.12972 6.03093 8.17369 6.03093 9.27897C6.03093 9.55524 5.80005 9.7792 5.51526 9.7792C5.23046 9.7792 4.99958 9.55524 4.99958 9.27897C4.99958 8.26855 4.82096 7.40678 4.47752 6.78851C4.1439 6.18793 3.66248 5.82309 2.99992 5.73398C2.59274 5.67921 2.24644 5.68628 1.97038 5.72306C1.19195 5.97628 0.802734 6.77185 0.802734 7.56742C0.802734 9.81567 2.96872 11.5953 4.05527 12.1941C4.08388 12.2232 4.1128 12.252 4.14201 12.2804V15.0876C4.14201 15.7809 4.78904 16.0742 5.59013 16.0742H11.2286C12.0297 16.0742 12.6767 15.7809 12.6767 15.0876V12.2621ZM9.86126 13.3921C9.8614 13.1158 9.63064 12.8918 9.34585 12.8916L7.34457 12.8907C7.05977 12.8905 6.82878 13.1144 6.82864 13.3906C6.8285 13.6669 7.05926 13.891 7.34405 13.8911L9.34533 13.8921C9.63013 13.8922 9.86112 13.6684 9.86126 13.3921Z"
      fill={theme.tokens.colors['sport-icon']['sport-boxing']}
      fillRule="evenodd"
    />
  )
}

export const IconSportBoxing: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

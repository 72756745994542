import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { StyledTooltip } from 'shared/ui/Tooltip/Tooltip.styled'
import { StyledTextInput } from 'shared/ui/text-input/text-input.styled'

export const StyledEventCouponDependentBottomWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxs-6']}px 0px;
  `}
`

export const StyledEventCouponInner = styled.div`
  flex-grow: 1;
  overflow: hidden;
`

export const StyledEventCouponCoefficientName = styled.div`
  ${typography['Body / 12 Bold']}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledEventCouponDependentBottom = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-error']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}

  ${StyledEventCouponCoefficientName} {
    width: 100%;
  }
`

export const StyledButton = styled.span<{
  oneRightButton?: boolean
}>`
  ${({ theme }) => `background-color: ${theme.colors.custom.primary[59]};`}
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  min-width: 97px;
  ${({ oneRightButton }) => oneRightButton && `margin-left: auto;`}
`

export const StyledBetCardCouponWrapper = styled.div<{
  isDependent?: boolean
  isRelated?: boolean
}>`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
    position: relative;
  `}

  ${({ theme, isDependent, isRelated }) => css`
    ${isDependent &&
    css`
      background: ${theme.tokens.colors.surface['surface-negative']};
      outline: 1px solid ${theme.tokens.colors.border['border-error-opacity']};
      outline-offset: -1px;
      padding-bottom: ${theme.tokens.alias.space['space-xxs-6']}px;

      ${StyledEventCouponTeams} {
        color: ${theme.tokens.colors.text['text-error']};
      }

      ${StyledEventCouponTitle} {
        & svg {
          height: 9px;
          width: 9px;
        }
      }
    `}

    ${isRelated &&
    css`
      ${StyledEventCouponTeamsWrapper} {
        padding-bottom: 0;
      }

      ${StyledEventCouponTitle} {
        & svg {
          height: 9px;
          width: 9px;
        }
      }
    `}
  `}
`

export const StyledBetCardIcon = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.icon['icon-secondary-3']};
    cursor: pointer;
    display: flex;
    font-size: 0;
    height: 16px;
    justify-content: center;
    width: 16px;
  `}
`

export const StyledEventCouponHeader = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    justify-content: space-between;
  `}
`

export const StyledEventCouponTitle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    overflow: hidden;

    & svg {
      height: 12px;
      width: 12px;
    }
  `}
`

export const StyledEventCouponName = styled.div`
  ${typography['Hint / 11 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`

export const StyledEventCouponTeamsWrapper = styled.span`
  ${({ theme }) => css`
    padding-bottom: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledEventCouponTeams = styled.span`
  ${typography['Body / 12 SemiBold']}

  -moz-box-orient: vertical;
  -moz-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: box;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledEventCouponCoefficientValue = styled.div`
  ${typography['Body / 14 Bold']}
`

export const StyledBetCardDependentList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const StyledInputSum = styled.div`
  ${({ theme }) => css`
    & ${StyledTextInput} {
      background-color: ${theme.tokens.colors.surface['surface-base']};
    }
  `}
`

export const StyledBetGroup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const StyledBetGroupTitle = styled.div`
  ${typography['Body / 12 EXTRABOLD']}
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-secondary-2']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0px;

    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-3']};
    }
  `}
`

export const StyledEventCouponContent = styled.div<{ isEventEnded?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isEventEnded, theme }) => css`
    ${isEventEnded &&
    css`
      &&
        ${StyledEventCouponTeams},
        ${StyledEventCouponCoefficientName},
        ${StyledEventCouponCoefficientValue}>div {
        color: ${theme.tokens.colors.text['text-secondary-3']};
      }
    `}
  `}

  & ${StyledTooltip} {
    display: block;
  }
`

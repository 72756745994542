import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconVideoFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2 11.1222C2 11.3966 2.11429 11.6538 2.32381 11.8424L3.26667 12.6999C3.47619 12.8885 3.7619 13 4.05714 13H11.9429C12.2381 13 12.5238 12.8885 12.7333 12.6999L13.6762 11.8424C13.8857 11.6538 14 11.388 14 11.1222V4.87781C14 4.60343 13.8857 4.3462 13.6762 4.15756L12.7333 3.30011C12.5238 3.11147 12.2381 3 11.9429 3H4.05714C3.7619 3 3.47619 3.11147 3.26667 3.30011L2.32381 4.15756C2.11429 4.3462 2 4.612 2 4.87781V11.1222ZM6.41113 6.41635C6.41113 6.27031 6.57597 6.17904 6.72021 6.24293L10.2952 7.83114C10.4498 7.90416 10.4498 8.10497 10.2952 8.17799L6.72021 9.75707C6.57597 9.82096 6.41113 9.72968 6.41113 9.58364V6.41635Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import styled, { css } from 'styled-components'

import { IconCheckCircleFilled16 } from 'shared/ui/Icon/General/IconCheckCircleFilled16'
import { typography } from 'shared/lib/theme/typography'

export const StyledDeleteHistoryHint = styled.div``

export const StyledToastifyCashoutNotificationTextWrapper = styled.div`
  align-items: center;
  display: flex;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledToastifyCashoutNotificationText = styled.div`
  ${typography['Body / 14 SemiBold']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-invert']};
  `}
`

export const StyledIconCheckCircleFilled16 = styled(IconCheckCircleFilled16)``

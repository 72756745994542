import { FC, PropsWithChildren, useEffect } from 'react'

import { userApi } from 'entities/legacy-api'
import { useIsAuth } from 'shared/lib/account'
import { useUserAccount } from 'shared/lib/account/use-user-account'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { constraintProviderActions } from '../../model'

const { useGetUserIdentificationStatusQuery } = userApi

const useSideEffectSetConstraints = () => {
  const dispatch = useAppDispatch()
  const { isAuthUser } = useIsAuth()
  const account = useUserAccount()

  const { data, isSuccess } = useGetUserIdentificationStatusQuery(undefined, {
    pollingInterval: 1000 * 60 * 2, // 2 minutes
    skip: !isAuthUser
  })
  const { identificationStatus } = data?.data || {}

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        constraintProviderActions.onChangeConstraints({
          onChangeConstraints: identificationStatus,
          accountConstraints: {
            isBetsBlocked: !!account?.constraints.betsBlocked,
            isWithdrawalBlocked: !!account?.constraints.withdrawalBlocked
          }
        })
      )
    }
  }, [dispatch, identificationStatus, isSuccess, account])
}

/**
 * It is necessary to use the useSideEffectSetConstraints hook at the top level of the app.
 * This way, a new request won't cause the whole app to re-render.
 * P. S. The children don't re-render because of how React works
 * */
export const SetConstraintsDecorator: FC<PropsWithChildren> = ({
  children
}) => {
  useSideEffectSetConstraints()

  return <>{children}</>
}

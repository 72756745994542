import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M20.4632 11.9944L21.9712 7.04466C22.0875 6.66312 21.8504 6.26512 21.4504 6.17052L16.2228 4.93312L12.5478 1.21583C12.2648 0.92947 11.7919 0.927849 11.5068 1.21224L7.76241 4.94725L2.55223 6.17422C2.15163 6.26848 1.91398 6.66694 2.03053 7.04883L3.54408 12.0093L2.04348 16.9697C1.92789 17.3517 2.16626 17.7494 2.56674 17.843L7.78159 19.0607L11.506 22.7869C11.7911 23.0721 12.2646 23.0708 12.5479 22.7841L16.2398 19.0484L21.45 17.8183C21.8501 17.7238 22.0874 17.3257 21.9712 16.9442L20.4632 11.9944ZM12.0258 12.7667L11.3359 13.1508V12.3843L10.6409 11.9997L11.3359 11.6132V10.8464L12.0253 11.2295L12.7153 10.8454V11.612L13.41 11.9963L13.4102 11.9966L13.408 11.998L12.7153 12.3832V13.15L12.0258 12.7667Z"
        fill="#2B0117"
      />
      <path
        d="M13.41 11.9963L13.4075 11.9977L12.7153 12.3831L12.0268 12.7664H12.0265L12.0258 12.7667L11.3359 13.1508V10.8464L12.0253 11.2295L12.7153 10.8454V11.612L13.41 11.9963Z"
        fill="#FFE25B"
      />
      <path
        d="M12.0257 1.65215V11.9982L21.3192 6.82512L15.863 5.53376L12.0257 1.65215Z"
        fill="#FFE25B"
      />
      <path
        d="M13.4102 11.9966L13.408 11.998L12.7153 12.3831V13.15L12.0258 12.7667L11.6614 12.5643L11.6571 12.5619L10.6437 11.9985L11.3359 11.6134L11.3359 11.6132L12.0247 11.23L12.0253 11.2295L12.7153 10.8454V11.612L13.41 11.9963L13.4102 11.9966Z"
        fill="#FFB820"
      />
      <path
        d="M21.3192 6.82512L12.0257 11.9982L21.3192 17.1637L19.7444 11.9945L21.3192 6.82512Z"
        fill="#FFB820"
      />
      <path
        d="M13.408 11.998L12.7153 12.3831V13.15L12.0258 12.7667L11.3359 13.1508V10.8464L12.0253 11.2295L12.3587 11.4147L12.4033 11.4396L13.4075 11.9977L13.408 11.998Z"
        fill="#C85E40"
      />
      <path
        d="M21.3192 17.1637L12.0257 11.9982V22.3479L15.8803 18.4478L21.3192 17.1637Z"
        fill="#C85E40"
      />
      <path
        d="M12.7153 10.8459V13.15L12.0258 12.7667L11.3359 13.1508V12.3843L10.6409 11.9997L11.3359 11.6132L12.0247 11.23L12.0253 11.2295L12.7153 10.8459Z"
        fill="#FFB820"
      />
      <path
        d="M12.0257 22.3479V11.9982L2.69588 17.1878L8.13855 18.4589L12.0257 22.3479Z"
        fill="#FFB820"
      />
      <path
        d="M13.4102 11.9966L13.408 11.998L12.7153 12.3831L12.0268 12.7664H12.0265L12.0258 12.7667L11.3359 13.1505L11.3359 12.3843L10.6409 11.9997L11.3359 11.6132L11.3359 10.849L12.0247 11.23L12.3587 11.4147L12.4033 11.4396L12.7153 11.612L13.41 11.9963L13.4102 11.9966Z"
        fill="#FFE25B"
      />
      <path
        d="M2.69588 17.1878L12.0257 11.9982L2.68245 6.82882L4.2629 12.0083L2.69588 17.1878Z"
        fill="#FFE25B"
      />
      <path
        d="M12.7153 10.8454V13.1474L12.0268 12.7664H12.0265L11.6614 12.5643L11.6571 12.5619L11.3359 12.3843L10.6409 11.9997L11.3359 11.6132V10.8464L12.0253 11.2295L12.7153 10.8454Z"
        fill="white"
      />
      <path
        d="M12.0257 1.65215V11.9982L2.68245 6.82882L8.11936 5.54846L12.0257 1.65215Z"
        fill="white"
      />
      <path
        d="M12.0238 18.5699C8.46361 18.5699 5.12968 15.6041 5.56565 11.838C5.56637 11.8323 5.56709 11.8262 5.56769 11.8206C5.71433 10.5859 6.07237 9.42277 6.61181 8.41569C7.76445 6.70943 9.75881 5.58181 12.0256 5.58181C14.3195 5.58181 16.3342 6.73653 17.4802 8.47672C18.001 9.46955 18.3444 10.6105 18.4799 11.8206C18.4817 11.8377 18.4836 11.855 18.4854 11.8721C18.8679 15.5781 15.6623 18.5699 12.0238 18.5699Z"
        fill="#FFE25B"
      />
      <path
        d="M18.4857 11.8206C18.4857 11.8377 18.4856 11.855 18.4854 11.8721C18.4566 15.2939 15.5758 18.0593 12.0256 18.0593C8.46386 18.0593 5.5755 15.2757 5.56554 11.8382V11.8207C5.56554 10.5643 5.94996 9.39487 6.61182 8.41581C7.76446 6.70956 9.75882 5.58193 12.0256 5.58193C14.3195 5.58193 16.3342 6.73665 17.4803 8.47684C18.1169 9.44315 18.4857 10.5901 18.4857 11.8206Z"
        fill="#A22222"
      />
      <path
        d="M12.0008 17.0306C14.9041 17.0306 17.2577 14.7576 17.2577 11.9537C17.2577 9.14986 14.9041 6.87688 12.0008 6.87688C9.09745 6.87688 6.74384 9.14986 6.74384 11.9537C6.74384 14.7576 9.09745 17.0306 12.0008 17.0306Z"
        fill="#55161F"
      />
      <path
        d="M16.4648 11.3069C16.5203 11.698 16.4961 12.0951 16.3949 12.4777C15.8991 14.3516 14.2735 15.1175 12.0085 15.1175C9.75357 15.1692 8.08331 14.4123 7.59818 12.3748C7.52204 12.055 7.50117 11.7248 7.54014 11.3989C7.7983 9.23668 9.70045 7.55787 12.0086 7.55787C14.2837 7.55775 16.1643 9.18921 16.4648 11.3069Z"
        fill="#FFBE20"
      />
      <path
        d="M14.0536 15.7613H9.94781C9.80872 15.7613 9.69601 15.6525 9.69601 15.5182V14.5735H14.3054V15.5182C14.3054 15.6525 14.1926 15.7613 14.0536 15.7613Z"
        fill="#FFBE20"
      />
      <path
        d="M12.0256 13.0472L11.3785 14.1295H12.6726L12.0256 13.0472Z"
        fill="#55161F"
      />
      <path
        d="M14.0755 13.9019C14.7929 13.9019 15.3744 13.3179 15.3744 12.5975C15.3744 11.8772 14.7929 11.2932 14.0755 11.2932C13.3581 11.2932 12.7766 11.8772 12.7766 12.5975C12.7766 13.3179 13.3581 13.9019 14.0755 13.9019Z"
        fill="#55161F"
      />
      <path
        d="M10.0293 13.9019C10.7467 13.9019 11.3283 13.3179 11.3283 12.5975C11.3283 11.8772 10.7467 11.2932 10.0293 11.2932C9.31198 11.2932 8.73043 11.8772 8.73043 12.5975C8.73043 13.3179 9.31198 13.9019 10.0293 13.9019Z"
        fill="#55161F"
      />
      <path
        d="M11.8122 11.578L11.5972 11.7855C11.5351 11.8455 11.4345 11.8455 11.3724 11.7855L10.5799 11.0202C10.5178 10.9602 10.5178 10.863 10.5799 10.8031L10.7948 10.5955C10.8569 10.5356 10.9575 10.5356 11.0196 10.5955L11.8121 11.3608C11.8743 11.4208 11.8743 11.518 11.8122 11.578Z"
        fill="#55161F"
      />
      <path
        d="M13.4617 10.8032L13.2468 10.5957C13.1847 10.5357 13.0841 10.5357 13.022 10.5957L12.2296 11.361C12.1675 11.4209 12.1675 11.5181 12.2296 11.5781L12.4446 11.7856C12.5067 11.8456 12.6073 11.8456 12.6694 11.7856L13.4618 11.0203C13.5237 10.9603 13.5237 10.863 13.4617 10.8032Z"
        fill="#55161F"
      />
      <path
        d="M10.6676 10.3449C11.4099 9.89111 11.7604 9.14001 11.4506 8.66733C11.1407 8.19464 10.2878 8.17936 9.54547 8.6332C8.80318 9.08704 8.45263 9.83814 8.7625 10.3108C9.07237 10.7835 9.92531 10.7988 10.6676 10.3449Z"
        fill="white"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M14.1553 7.99592L15.2521 4.39613C15.3367 4.11864 15.1642 3.82919 14.8733 3.76039L11.0714 2.86046L8.39865 0.15697C8.19285 -0.0512946 7.84892 -0.0524736 7.64155 0.15436L4.91838 2.87073L1.12915 3.76308C0.837807 3.83163 0.664971 4.12142 0.749732 4.39916L1.85049 8.00679L0.75915 11.6143C0.675086 11.8922 0.848446 12.1814 1.1397 12.2495L4.93233 13.1351L7.64103 15.8451C7.84831 16.0525 8.19267 16.0516 8.39873 15.843L11.0838 13.1262L14.873 12.2315C15.164 12.1628 15.3366 11.8733 15.2521 11.5958L14.1553 7.99592ZM8.01905 8.55764L7.51729 8.83698V8.27948L7.01186 7.9998L7.51729 7.71868V7.16101L8.0187 7.43968L8.52047 7.16034V7.71784L9.02572 7.99735L9.0259 7.99752L9.02424 7.99853L8.52047 8.27872V8.83639L8.01905 8.55764Z"
        fill="#2B0117"
      />
      <path
        d="M9.02572 7.99735L9.02389 7.99837L8.52047 8.27863L8.01975 8.55739H8.01958L8.01905 8.55764L7.51729 8.83698V7.16101L8.0187 7.43968L8.52047 7.16034V7.71784L9.02572 7.99735Z"
        fill="#FFE25B"
      />
      <path
        d="M8.01897 0.474295V7.9987L14.7779 4.23646L10.8097 3.29728L8.01897 0.474295Z"
        fill="#FFE25B"
      />
      <path
        d="M9.0259 7.99752L9.02424 7.99853L8.52047 8.27863V8.83639L8.01905 8.55764L7.75404 8.41043L7.7509 8.40866L7.01387 7.99896L7.51729 7.71885L7.51729 7.71868L8.01827 7.44002L8.0187 7.43968L8.52047 7.16034V7.71784L9.02572 7.99735L9.0259 7.99752Z"
        fill="#FFB820"
      />
      <path
        d="M14.7779 4.23646L8.01897 7.9987L14.7779 11.7555L13.6326 7.99601L14.7779 4.23646Z"
        fill="#FFB820"
      />
      <path
        d="M9.02424 7.99853L8.52047 8.27863V8.83639L8.01905 8.55764L7.51729 8.83698V7.16101L8.0187 7.43968L8.26113 7.57434L8.29357 7.59245L9.02389 7.99837L9.02424 7.99853Z"
        fill="#C85E40"
      />
      <path
        d="M14.7779 11.7555L8.01897 7.9987V15.5258L10.8223 12.6893L14.7779 11.7555Z"
        fill="#C85E40"
      />
      <path
        d="M8.52047 7.16067V8.83639L8.01905 8.55764L7.51729 8.83698V8.27948L7.01186 7.9998L7.51729 7.71868L8.01827 7.44002L8.0187 7.43968L8.52047 7.16067Z"
        fill="#FFB820"
      />
      <path
        d="M8.01897 15.5258V7.9987L1.23362 11.773L5.19193 12.6974L8.01897 15.5258Z"
        fill="#FFB820"
      />
      <path
        d="M9.0259 7.99752L9.02424 7.99853L8.52047 8.27863L8.01975 8.55739H8.01958L8.01905 8.55764L7.51729 8.83673L7.51729 8.27948L7.01186 7.9998L7.51729 7.71868L7.51729 7.16295L8.01827 7.44002L8.26113 7.57434L8.29357 7.59245L8.52047 7.71784L9.02572 7.99735L9.0259 7.99752Z"
        fill="#FFE25B"
      />
      <path
        d="M1.23362 11.773L8.01897 7.9987L1.22385 4.23915L2.37328 8.00603L1.23362 11.773Z"
        fill="#FFE25B"
      />
      <path
        d="M8.52047 7.16034V8.83446L8.01975 8.55739H8.01958L7.75404 8.41043L7.7509 8.40866L7.51729 8.27948L7.01186 7.9998L7.51729 7.71868V7.16101L8.0187 7.43968L8.52047 7.16034Z"
        fill="white"
      />
      <path
        d="M8.01897 0.474295V7.9987L1.22385 4.23915L5.17798 3.30798L8.01897 0.474295Z"
        fill="white"
      />
      <path
        d="M8.01757 12.7781C5.42834 12.7781 3.00367 10.6212 3.32073 7.88223C3.32126 7.87802 3.32178 7.87364 3.32222 7.86952C3.42887 6.97161 3.68925 6.12566 4.08158 5.39324C4.91986 4.15232 6.37031 3.33223 8.01888 3.33223C9.68716 3.33223 11.1524 4.17203 11.9859 5.43762C12.3646 6.15969 12.6144 6.98946 12.7129 7.86952C12.7142 7.88198 12.7156 7.89453 12.7169 7.90699C12.9951 10.6023 10.6637 12.7781 8.01757 12.7781Z"
        fill="#FFE25B"
      />
      <path
        d="M12.7171 7.86952C12.7171 7.88198 12.7171 7.89453 12.7169 7.90699C12.696 10.3956 10.6009 12.4068 8.01889 12.4068C5.42852 12.4068 3.32789 10.3823 3.32066 7.88232V7.8696C3.32066 6.95586 3.60023 6.10537 4.08159 5.39333C4.91987 4.15241 6.37032 3.33232 8.01889 3.33232C9.68717 3.33232 11.1524 4.17212 11.9859 5.43771C12.449 6.14049 12.7171 6.97464 12.7171 7.86952Z"
        fill="#A22222"
      />
      <path
        d="M8.00083 11.6586C10.1124 11.6586 11.8241 10.0055 11.8241 7.96637C11.8241 5.92719 10.1124 4.2741 8.00083 4.2741C5.88932 4.2741 4.1776 5.92719 4.1776 7.96637C4.1776 10.0055 5.88932 11.6586 8.00083 11.6586Z"
        fill="#55161F"
      />
      <path
        d="M11.2474 7.49593C11.2878 7.78041 11.2702 8.06919 11.1966 8.34744C10.836 9.7103 9.6537 10.2673 8.00643 10.2673C6.3665 10.3049 5.15176 9.75443 4.79894 8.27257C4.74356 8.04005 4.72839 7.79987 4.75673 7.56288C4.94448 5.99033 6.32787 4.76937 8.00652 4.76937C9.66111 4.76929 11.0289 5.9558 11.2474 7.49593Z"
        fill="#FFBE20"
      />
      <path
        d="M9.49377 10.7355H6.50777C6.40661 10.7355 6.32464 10.6564 6.32464 10.5587V9.87165H9.6769V10.5587C9.6769 10.6564 9.59492 10.7355 9.49377 10.7355Z"
        fill="#FFBE20"
      />
      <path
        d="M8.01891 8.76161L7.54827 9.54877H8.48945L8.01891 8.76161Z"
        fill="#55161F"
      />
      <path
        d="M9.50973 9.3832C10.0315 9.3832 10.4544 8.9585 10.4544 8.4346C10.4544 7.9107 10.0315 7.486 9.50973 7.486C8.988 7.486 8.56506 7.9107 8.56506 8.4346C8.56506 8.9585 8.988 9.3832 9.50973 9.3832Z"
        fill="#55161F"
      />
      <path
        d="M6.56707 9.3832C7.08879 9.3832 7.51173 8.9585 7.51173 8.4346C7.51173 7.9107 7.08879 7.486 6.56707 7.486C6.04534 7.486 5.6224 7.9107 5.6224 8.4346C5.6224 8.9585 6.04534 9.3832 6.56707 9.3832Z"
        fill="#55161F"
      />
      <path
        d="M7.86368 7.69308L7.70733 7.844C7.66216 7.88762 7.589 7.88762 7.54383 7.844L6.9675 7.28742C6.92233 7.24379 6.92233 7.17313 6.9675 7.12951L7.12377 6.9786C7.16894 6.93497 7.2421 6.93497 7.28727 6.9786L7.8636 7.53518C7.90886 7.5788 7.90886 7.64946 7.86368 7.69308Z"
        fill="#55161F"
      />
      <path
        d="M9.06334 7.12959L8.90707 6.97868C8.8619 6.93506 8.78873 6.93506 8.74356 6.97868L8.16724 7.53526C8.12207 7.57888 8.12207 7.64954 8.16724 7.69317L8.32359 7.84408C8.36876 7.8877 8.44193 7.8877 8.4871 7.84408L9.06342 7.2875C9.10842 7.24388 9.10842 7.17313 9.06334 7.12959Z"
        fill="#55161F"
      />
      <path
        d="M7.03125 6.79634C7.5711 6.46627 7.82605 5.92002 7.60069 5.57625C7.37533 5.23248 6.755 5.22137 6.21516 5.55143C5.67531 5.8815 5.42036 6.42775 5.64572 6.77152C5.87108 7.11529 6.49141 7.12641 7.03125 6.79634Z"
        fill="white"
      />
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        clipRule="evenodd"
        d="M16.4591 11.2735C16.5146 11.6646 16.4904 12.0617 16.3892 12.4443C16.0842 13.5971 15.3515 14.3306 14.3054 14.7244V14.5401H9.69609V14.7565C10.3571 15.0038 11.1393 15.104 12.0083 15.0841C12.8748 15.0841 13.6477 14.972 14.2996 14.7287V15.4847C14.2996 15.6191 14.1869 15.7279 14.0478 15.7279H9.94207C9.80299 15.7279 9.69028 15.6191 9.69028 15.4847V14.7564C8.63632 14.362 7.89058 13.5935 7.59244 12.3413C7.5163 12.0216 7.49544 11.6914 7.53441 11.3655C7.79256 9.20325 9.69471 7.52444 12.0029 7.52444C14.2779 7.52432 16.1586 9.15577 16.4591 11.2735ZM11.5905 11.752L11.8055 11.5445C11.8676 11.4846 11.8676 11.3874 11.8054 11.3274L11.0129 10.5621C10.9508 10.5021 10.8502 10.5021 10.7881 10.5621L10.5732 10.7696C10.5111 10.8296 10.5111 10.9268 10.5732 10.9867L11.3657 11.752C11.4278 11.812 11.5284 11.812 11.5905 11.752ZM10.0226 13.8685C10.74 13.8685 11.3215 13.2845 11.3215 12.5641C11.3215 11.8438 10.74 11.2598 10.0226 11.2598C9.30525 11.2598 8.7237 11.8438 8.7237 12.5641C8.7237 13.2845 9.30525 13.8685 10.0226 13.8685ZM15.3677 12.5641C15.3677 13.2845 14.7861 13.8685 14.0688 13.8685C13.3514 13.8685 12.7699 13.2845 12.7699 12.5641C12.7699 11.8438 13.3514 11.2598 14.0688 11.2598C14.7861 11.2598 15.3677 11.8438 15.3677 12.5641ZM11.3718 14.0961L12.0189 13.0138L12.6659 14.0961H11.3718ZM13.455 10.7697L13.2401 10.5622C13.178 10.5023 13.0774 10.5023 13.0153 10.5622L12.2229 11.3275C12.1607 11.3875 12.1607 11.4847 12.2229 11.5447L12.4378 11.7522C12.5 11.8121 12.6006 11.8121 12.6627 11.7522L13.4551 10.9869C13.517 10.9269 13.517 10.8296 13.455 10.7697Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.9721 7.04466L20.4641 11.9944L21.9721 16.9442C22.0883 17.3257 21.851 17.7238 21.4509 17.8183L16.2407 19.0484L12.5487 22.7841C12.2654 23.0709 11.7919 23.0721 11.5069 22.7869L7.78245 19.0607L2.56759 17.843C2.16712 17.7494 1.92875 17.3517 2.04433 16.9697L3.54493 12.0093L2.03138 7.04883C1.91484 6.66694 2.15249 6.26848 2.55309 6.17422L7.76326 4.94725L11.5076 1.21224C11.7927 0.927849 12.2656 0.92947 12.5486 1.21583L16.2237 4.93312L21.4512 6.17052C21.8512 6.26512 22.0884 6.66312 21.9721 7.04466ZM12.0148 18.0931C15.5677 18.0931 18.448 15.2948 18.448 11.8429C18.448 8.39099 15.5677 5.59269 12.0148 5.59269C8.46178 5.59269 5.58154 8.39099 5.58154 11.8429C5.58154 15.2948 8.46178 18.0931 12.0148 18.0931Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  s: () => (
    <>
      <path
        clipRule="evenodd"
        d="M11.2435 7.47063C11.2838 7.75511 11.2662 8.04388 11.1926 8.32213C10.9708 9.16057 10.4379 9.69401 9.67717 9.9804V9.84635H6.32492V10.0037C6.80564 10.1836 7.37451 10.2565 8.00655 10.242C8.63671 10.242 9.19884 10.1605 9.67294 9.98351V10.5334C9.67294 10.6311 9.59097 10.7102 9.48981 10.7102H6.50381C6.40266 10.7102 6.32069 10.6311 6.32069 10.5334V10.0037C5.55417 9.71685 5.01182 9.15794 4.79499 8.24726C4.73962 8.01474 4.72444 7.77456 4.75278 7.53758C4.94053 5.96502 6.32391 4.74407 8.00257 4.74407C9.65715 4.74399 11.0249 5.9305 11.2435 7.47063ZM7.70266 7.81869L7.85901 7.66778C7.90418 7.62416 7.90418 7.5535 7.85893 7.50988L7.2826 6.9533C7.23743 6.90967 7.16427 6.90967 7.1191 6.9533L6.96283 7.10421C6.91766 7.14783 6.91766 7.21849 6.96283 7.26211L7.53915 7.81869C7.58432 7.86232 7.65749 7.86232 7.70266 7.81869ZM6.5624 9.3579C7.08412 9.3579 7.50706 8.9332 7.50706 8.4093C7.50706 7.8854 7.08412 7.46069 6.5624 7.46069C6.04067 7.46069 5.61773 7.8854 5.61773 8.4093C5.61773 8.9332 6.04067 9.3579 6.5624 9.3579ZM10.4497 8.4093C10.4497 8.9332 10.0268 9.3579 9.50505 9.3579C8.98333 9.3579 8.56038 8.9332 8.56038 8.4093C8.56038 7.8854 8.98333 7.46069 9.50505 7.46069C10.0268 7.46069 10.4497 7.8854 10.4497 8.4093ZM7.5436 9.52347L8.01423 8.7363L8.48478 9.52347H7.5436ZM9.05866 7.10429L8.90239 6.95338C8.85722 6.90976 8.78406 6.90976 8.73889 6.95338L8.16257 7.50996C8.11739 7.55358 8.11739 7.62424 8.16257 7.66786L8.31892 7.81878C8.36409 7.8624 8.43725 7.8624 8.48242 7.81878L9.05875 7.2622C9.10374 7.21857 9.10375 7.14783 9.05866 7.10429Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.2529 4.39514L14.1562 7.99493L15.2529 11.5948C15.3374 11.8723 15.1648 12.1618 14.8738 12.2305L11.0846 13.1252L8.39957 15.842C8.19351 16.0506 7.84915 16.0515 7.64187 15.8441L4.93318 13.1341L1.14056 12.2485C0.8493 12.1804 0.675941 11.8912 0.760004 11.6133L1.85135 8.00579L0.750586 4.39817C0.665825 4.12043 0.838662 3.83065 1.13001 3.7621L4.91923 2.86975L7.64239 0.153383C7.84976 -0.05345 8.19369 -0.052271 8.39949 0.155994L11.0722 2.85948L14.8741 3.7594C15.165 3.82821 15.3375 4.11765 15.2529 4.39514ZM8.01122 12.4303C10.5952 12.4303 12.6899 10.3952 12.6899 7.88475C12.6899 5.37429 10.5952 3.33917 8.01122 3.33917C5.42724 3.33917 3.33251 5.37429 3.33251 7.88475C3.33251 10.3952 5.42724 12.4303 8.01122 12.4303Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  )
}

export const IconCybersportBrawlStars: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

import { IconSearch } from 'shared/ui/Icon/General/icon-search'
import { typography } from 'shared/lib/theme/typography'

export const StyledSearchIcon = styled(IconSearch)`
  margin-right: ${(p) => p.theme.tokens.alias.space['space-xs-8']}px;
`

export const StyledClearIconWrapper = styled.div`
  cursor: pointer;
  flex-shrink: 0;
`

export const ClearTooltipBody = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`

export const HotkeyWrap = styled.div`
  ${typography['Hint / 11 Medium']};
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  min-width: 16px;
  padding: 0 4px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-invert']};
    outline: 1px solid ${theme.tokens.colors.text['text-invert']};
    outline-offset: -1px;
  `}
`

import { TGetEventsBySearchParams } from 'astra-core/utils/search/types'

export const getEventsBySearch = ({
  events,
  searchText
}: TGetEventsBySearchParams) =>
  events.filter(
    (event) =>
      (event.tournament.name.toUpperCase().includes(searchText.toUpperCase()) ||
        event.competitors.some((competitor) =>
          competitor.name.toUpperCase().includes(searchText.toUpperCase())
        )) &&
      event
  )

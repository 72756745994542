import styled from 'styled-components'

export const LineContentCell = styled.td`
  padding-right: 8px;
  text-align: end;
  white-space: nowrap;
`

export const ContentCell = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

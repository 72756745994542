import { createContext, PropsWithChildren, useContext, useState } from 'react'

const BetsHistoryContext = createContext<{
  allCollapsed: boolean
  toggleCollapseAll: () => void
  collapseAllDisabled: boolean
} | null>(null)

export const BetsHistoryContextProvider = <T,>({
  children,
  betIds
}: PropsWithChildren<{ betIds: T[] }>) => {
  const [allCollapsed, setAllCollapsed] = useState(false)

  const toggleCollapseAll = () => {
    setAllCollapsed((prev) => !prev)
  }

  const collapseAllDisabled = !betIds.length

  return (
    <BetsHistoryContext.Provider
      value={{
        allCollapsed,
        toggleCollapseAll,
        collapseAllDisabled
      }}
    >
      {children}
    </BetsHistoryContext.Provider>
  )
}

export const useBetsHistoryContext = () => useContext(BetsHistoryContext)!

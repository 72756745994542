import { createSelector } from '@reduxjs/toolkit'
import { Tournament, Event } from 'betweb-openapi-axios'

import { selectEntityTags } from 'entities/event'
import { RootState } from 'shared/types/store'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { isEventTopOrSuperTop } from '../is-event-top-or-super-top'

type SelectorType = (state: RootState) => Event[] | Tournament[]

export const useIsEventListHasTopMemo = (selector: SelectorType) => {
  const isEventListHasTop = useAppSelector(
    (state: RootState) => getEventsHasTop(selector)(state),
    (left, right) => left === right
  )

  return isEventListHasTop
}

const getEventsHasTop = (selector: SelectorType) =>
  createSelector([selector, selectEntityTags], (events, entityTags) => {
    const isEventListHasTop = events.some((event) =>
      isEventTopOrSuperTop({
        eventTags: event.tags,
        allEntityTags: entityTags
      })
    )

    return isEventListHasTop
  })

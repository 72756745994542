import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { LayoutTheme } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'

export const selectTheme = (state: RootState) => {
  const config = selectConfig(state)
  const layoutTheme = selectUserSettingLayoutTheme(state)

  if (config.IS_TRANSIT) {
    return LayoutTheme.Light
  }

  return layoutTheme
}

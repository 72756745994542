import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  basketProviderActions,
  selectBasketAppliedCoupon,
  selectBasketBonusWallet,
  selectOutcomeStakeAmount
} from 'astra-core/containers/BasketProvider'
import { activeEventStatus } from 'astra-core/containers/BasketProvider/constants'
import { selectLoyaltyAmountById } from 'astra-core/containers/AccountProvider'

import { RootState } from 'shared/types/store'
import { onValidateBasketInputValue, basketModel } from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { InputBetSum } from '../../input-bet-sum'

import { InputBetSumProps } from './input-bet-sum.types'

const {
  getBasketOrdinarOutcomeErrorMessage,
  getBasketOrdinarOutcomeAmountValue
} = basketModel

export const OrdinarInputBetSum: FC<InputBetSumProps> = ({
  outcomeId,
  eventStatus,
  odd,
  maxBetAmount
}) => {
  const dispatch = useAppDispatch()
  const errorMessage = useAppSelector((state) =>
    getBasketOrdinarOutcomeErrorMessage(state, outcomeId)
  )
  const ordinarOutcomesAmountValue = useAppSelector((state) =>
    getBasketOrdinarOutcomeAmountValue(state, outcomeId)
  )
  const isBonusWallet = useAppSelector(selectBasketBonusWallet)
  const stakeAmount = useAppSelector((state: RootState) =>
    selectOutcomeStakeAmount(state, outcomeId)
  )

  const isEventActive = useMemo(
    () => activeEventStatus.includes(eventStatus),
    [eventStatus]
  )

  const winSum = useMemo(
    () => (stakeAmount !== '' && +stakeAmount * (odd - +isBonusWallet)) || 0,
    [stakeAmount, odd, isBonusWallet]
  )

  const setOutcomeAmount = useCallback(
    (value: string) => {
      dispatch(
        basketProviderActions.setOrdinarAmount({
          outcomeId,
          amount: onValidateBasketInputValue(value),
          maxBet: maxBetAmount
        })
      )
    },
    [dispatch, outcomeId, maxBetAmount]
  )

  // TODO: Remove this 'appliedCouponId' useEffect handling when astra-core fixe validation making a bet with Bonus Coupon in Ordinar
  const [ordinarIdAppliedCoupon, setOrinarIdAppliedCoupon] = useState('')
  const appliedCouponId = useAppSelector(selectBasketAppliedCoupon)
  const currentAppliedCouponAmount = useAppSelector((state: RootState) =>
    selectLoyaltyAmountById(state, appliedCouponId ?? '')
  )

  useEffect(() => {
    if (appliedCouponId) {
      setOutcomeAmount(`${currentAppliedCouponAmount}`)
      setOrinarIdAppliedCoupon(outcomeId)
    } else if (ordinarIdAppliedCoupon === outcomeId) setOutcomeAmount('')
  }, [appliedCouponId, ordinarIdAppliedCoupon])

  useEffect(() => {
    return () => {
      if (ordinarIdAppliedCoupon === outcomeId) setOutcomeAmount('')
    }
  }, [ordinarIdAppliedCoupon])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(basketProviderActions.makeBet())
      }
    },
    [dispatch]
  )

  return (
    <InputBetSum
      background="gray"
      defaultValue={ordinarOutcomesAmountValue}
      disabled={!isEventActive}
      errorMessage={errorMessage}
      maxValue={maxBetAmount}
      size="s"
      winSum={winSum}
      isOrdinar
      onChange={setOutcomeAmount}
      onKeyDown={handleKeyDown}
    />
  )
}

import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

import { IconProps } from '../types'
import { Icon } from '../Icon'

const circleFrames = () => keyframes`
  0%, 100% {
    opacity: 1;
  }
  33%, 66% {
    opacity: 0.5;
  }
`

const LeftCircle = styled.circle`
  animation: ${circleFrames} 1.5s linear 0s infinite;
`

const MiddleCircle = styled.circle`
  animation: ${circleFrames} 1.5s linear 0.5s infinite;
`

const RightCircle = styled.circle`
  animation: ${circleFrames} 1.5s linear 1s infinite;
`

export const IconLoadingSmall: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.07756 0.590492C8.69725 0.210175 8.19683 0 7.66639 0H4.35363C3.82319 0 3.31276 0.210175 2.94245 0.590492L0.590492 2.93244C0.210175 3.31276 0 3.81318 0 4.34362V7.65638C0 8.18682 0.210175 8.69725 0.590492 9.06756L2.93244 11.4095C3.31276 11.7898 3.81318 12 4.34362 12H7.65638C8.18682 12 8.69725 11.7898 9.06756 11.4095L11.4095 9.06756C11.7898 8.68724 12 8.18682 12 7.65638V4.34362C12 3.81318 11.7898 3.30275 11.4095 2.93244L9.07756 0.590492Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <LeftCircle
        cx="1"
        cy="1"
        fill="white"
        r="1"
        transform="matrix(1 0 0 -1 2 7)"
      />
      <MiddleCircle
        cx="1"
        cy="1"
        fill="white"
        r="1"
        transform="matrix(1 0 0 -1 5 7)"
      />
      <RightCircle
        cx="1"
        cy="1"
        fill="white"
        r="1"
        transform="matrix(1 0 0 -1 8 7)"
      />
    </svg>
  </Icon>
)

import styled, { css } from 'styled-components'

import { StyledTextInput } from 'shared/ui/text-input/text-input.styled'

export const StyledInputSum = styled.div`
  ${({ theme }) => css`
    & ${StyledTextInput} {
      background-color: ${theme.tokens.colors.surface['surface-base']};
    }
  `}
`

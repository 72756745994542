import React, { useCallback } from 'react'
import { accountProviderActions } from 'astra-core/containers/AccountProvider'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import {
  StyledLoaderSkeleton,
  ModalSkeletonTitleBlock,
  ModalSkeletonInputsBlock,
  ModalSkeletonButtonsBlock,
  StyledLoginLoaderWrapper
} from './unauthorized-modal.styled'

export const UnauthorizedModalLoader = () => {
  const dispatch = useAppDispatch()

  const hideModalWindow = useCallback(() => {
    dispatch(accountProviderActions.setTypeAuthModal(null))
  }, [dispatch])

  return (
    <StyledLoaderSkeleton>
      <ModalSkeletonTitleBlock>
        <ContentLoader borderRadius={8} height={24} width={160} />
        <IconCloseOutline16
          colorToken="icon-secondary-2"
          onClick={hideModalWindow}
        />
      </ModalSkeletonTitleBlock>
      <ContentLoader borderRadius={8} height={32} width="100%" />
      <ModalSkeletonInputsBlock>
        <ContentLoader borderRadius={8} height={48} width="100%" />
        <ContentLoader borderRadius={8} height={48} width="100%" />
        <ContentLoader borderRadius={4} height={20} width={160} />
      </ModalSkeletonInputsBlock>
      <ModalSkeletonButtonsBlock>
        <ContentLoader borderRadius={8} height={40} width="100%" />
        <ContentLoader borderRadius={8} height={40} width="100%" />
      </ModalSkeletonButtonsBlock>
    </StyledLoaderSkeleton>
  )
}

export const LoginModalLoader = () => {
  return (
    <StyledLoginLoaderWrapper>
      <LoaderLogo />
    </StyledLoginLoaderWrapper>
  )
}

import styled, { css } from 'styled-components'

import { IconChevronDownKf } from 'shared/ui/Icon/General/IconChevronDownKf'
import { typography } from 'shared/lib/theme/typography'

export const ExpandButton = styled.button<{ $isExpanded: boolean }>`
  ${({ theme, $isExpanded }) => css`
    align-items: center;
    border: none;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 8px 4px 8px 8px;
    transition: background-color 0.5s ease;
    width: 100%;
    ${typography['Body / 12 SemiBold']};

    ${$isExpanded
      ? css`
          background-color: ${theme.tokens.colors.surface['surface-active2']};
          color: ${theme.tokens.colors.text['text-invert']};

          &:hover {
            background-color: ${theme.tokens.colors.surface[
              'surface-active2-hover'
            ]};
          }
        `
      : css`
          background-color: ${theme.tokens.colors.surface['surface-default']};
          color: ${theme.tokens.colors.text['text-secondary-3']};

          &:hover {
            background-color: ${theme.tokens.colors.surface['surface-hover']};
            color: ${theme.tokens.colors.text['text-primary-2']};
          }
        `}

    &:disabled {
      background-color: ${theme.tokens.colors.surface['surface-disabled']};
      color: ${theme.tokens.colors.text['text-disabled']};
      cursor: default;

      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
      }
    }
  `}
`

export const Chevron = styled(IconChevronDownKf)<{
  isOpened: boolean
}>`
  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(180deg);
    `}
`

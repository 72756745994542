import styled, { css } from 'styled-components'

export const BonusInfoWithSuitableCoupons = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    outline-offset: -1px;
    overflow: hidden;
  `}
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.divider['divider-primary']};
  `}
`

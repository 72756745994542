import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs'
import { transactionHistoryApi } from 'entities/legacy-api/lib/transaction-history'

import {
  ProviderReducerPayloads,
  ProviderState,
  TransactionHistoryBalanceOperationsTypes
} from './types'

const REDUCER_KEY = 'transactionHistoryProvider'

export const initialState: ProviderState = {
  data: {
    transactionCollection: {}
  },
  layout: {
    activeBalanceOperationTypeTab: TransactionHistoryBalanceOperationsTypes.ALL
  }
}

const stateSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setBalanceOperationTypeTab(
      state,
      {
        payload
      }: PayloadAction<ProviderReducerPayloads['setBalanceOperationTypeTab']>
    ) {
      state.layout.activeBalanceOperationTypeTab = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        transactionHistoryApi.endpoints.getHistory.matchFulfilled,
        (state, { payload }) => {
          payload.data.list.forEach((item) => {
            state.data.transactionCollection = {
              [item.id]: {
                id: item.id,
                data: item.data,
                status: item.status,
                is_back: item.is_back,
                sum: item.sum,
                reason: item.reason,
                info: item.info
              },
              ...state.data.transactionCollection
            }
          })
        }
      )
      .addMatcher(
        transactionHistoryApi.endpoints.cancelTransaction.matchFulfilled,
        (state, { payload, meta }) => {
          const {
            originalArgs: { id }
          } = meta.arg || {}

          // Request can send HTTP 200 with "response" === "error"
          if (payload.data.response === 'success') {
            if (
              state.layout.activeBalanceOperationTypeTab ===
              TransactionHistoryBalanceOperationsTypes.ALL
            ) {
              const { transactionCollection } = state.data

              if (transactionCollection?.[id]) {
                const newTopUpList: typeof transactionCollection = {
                  ...transactionCollection
                }

                delete newTopUpList[id]

                state.data.transactionCollection = newTopUpList
              }
            }
          }
        }
      )
  }
})

export const {
  actions: transactionHistoryActions,
  reducer,
  name: sliceKey
} = stateSlice

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconSimAltel: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 66 12"
      width="66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.6695 11.9999C42.4027 11.7307 42.1382 11.4593 41.8678 11.1939C41.8044 11.1316 41.7817 11.0691 41.7817 10.9815C41.7838 7.67083 41.7839 4.36029 41.7815 1.04967C41.7814 0.952434 41.8107 0.88427 41.8788 0.816619C42.1221 0.57508 42.3595 0.327712 42.5986 0.0819725C42.6237 0.0561638 42.6459 0.027352 42.6694 0C45.9908 0 49.3122 0 52.6336 0C52.648 0.125013 52.6393 0.250457 52.6399 0.375728C52.6413 0.699579 52.6386 1.02343 52.6417 1.34728C52.6424 1.41373 52.6218 1.46081 52.5756 1.50677C52.3274 1.75482 52.0799 2.00373 51.8361 2.25625C51.7779 2.31652 51.7183 2.3377 51.6355 2.33761C49.3076 2.33538 46.9797 2.33564 44.6518 2.33624C44.4656 2.33624 44.486 2.31018 44.4858 2.50473C44.4851 3.1929 44.4855 3.88108 44.4855 4.56925C44.4855 4.59763 44.4872 4.62601 44.4854 4.65431C44.4824 4.70318 44.5076 4.71861 44.5515 4.71638C44.5755 4.7151 44.5997 4.7163 44.6239 4.7163C45.6853 4.71724 46.7467 4.7193 47.8082 4.71716C47.9002 4.71698 47.9661 4.74365 48.0305 4.8083C48.287 5.06519 48.547 5.31873 48.8083 5.57056C48.8592 5.6196 48.8784 5.67062 48.8779 5.74059C48.8753 6.13723 48.8767 6.53397 48.8766 6.9307C48.8765 7.05811 48.8845 7.04834 48.7586 7.04834C47.3876 7.04834 46.0166 7.04817 44.6456 7.04868C44.4699 7.04877 44.4859 7.02476 44.4858 7.21151C44.4855 7.97668 44.4856 8.74168 44.4856 9.50685C44.4856 9.53926 44.4865 9.57158 44.486 9.604C44.4855 9.64532 44.5075 9.66187 44.5459 9.66204C44.6183 9.66264 44.6906 9.6635 44.763 9.6641C46.2504 9.67696 47.7379 9.68974 49.2254 9.70277C50.0415 9.70989 50.8576 9.71881 51.6737 9.72327C51.7612 9.72378 51.8234 9.74916 51.8849 9.81218C52.113 10.0461 52.3467 10.2745 52.5788 10.5045C52.6139 10.5392 52.6416 10.5725 52.6413 10.6284C52.639 11.0777 52.6396 11.5271 52.639 11.9764C52.639 11.9843 52.6354 11.9922 52.6336 12C49.3123 11.9999 45.9909 11.9999 42.6695 11.9999Z"
        fill="#EC018C"
      />
      <path
        d="M1.992 11.9999C1.68641 11.9999 1.38082 11.9999 1.07515 11.9999C0.765285 11.6862 0.455419 11.3725 0.145707 11.0588C0.103484 11.016 0.0625821 10.9719 0.0192707 10.9302C-0.00506766 10.9068 -0.00467853 10.8838 0.0111065 10.8574C0.0321012 10.839 0.0548065 10.8223 0.0739351 10.8022C0.497018 10.3587 0.919631 9.91473 1.34248 9.47101C2.19643 8.57456 3.05084 7.67872 3.90424 6.78176C5.0745 5.55187 6.24391 4.32111 7.41401 3.09095C8.29511 2.16484 9.17741 1.23976 10.0575 0.312535C10.154 0.210844 10.2607 0.117897 10.3399 0C11.49 0 12.64 0 13.7901 0C13.7923 0.0402135 13.7966 0.0804287 13.7966 0.120728C13.7968 3.74269 13.7965 7.36473 13.7988 10.9868C13.7989 11.0759 13.7715 11.1367 13.71 11.1981C13.4447 11.4629 13.1839 11.7323 12.9215 12C12.3424 12 11.7634 12 11.1844 12C11.1866 11.9558 11.1908 11.9115 11.1908 11.8672C11.1912 11.2196 11.1914 10.572 11.1909 9.92433C11.1908 9.74582 11.2064 9.76948 11.0366 9.7694C9.33206 9.76897 7.62754 9.76914 5.92299 9.76914C5.88101 9.76914 5.83909 9.76914 5.77557 9.76914C5.8055 9.73484 5.8174 9.71975 5.83077 9.7062C6.54374 8.98733 7.25725 8.26898 7.96883 7.54865C8.01596 7.50098 8.06463 7.4846 8.1284 7.48477C8.39775 7.48546 8.66712 7.48374 8.9364 7.48151C9.64384 7.47542 10.3513 7.46891 11.0586 7.46196C11.1679 7.46085 11.1663 7.47045 11.1675 7.35813C11.1692 7.21245 11.1728 7.06686 11.1736 6.92127C11.1771 6.24938 11.1824 5.57759 11.1823 4.90571C11.1822 4.11241 11.1969 3.31903 11.1641 2.52616C11.1627 2.48938 11.1769 2.44702 11.1271 2.39832C11.0993 2.43485 11.0778 2.46992 11.0499 2.49898C10.0453 3.54754 9.03859 4.59412 8.03524 5.64387C6.99847 6.7286 5.96506 7.81668 4.92971 8.90287C4.04459 9.8313 3.15838 10.7588 2.27372 11.6876C2.17706 11.7891 2.07232 11.8834 1.992 11.9999Z"
        fill="#EC018C"
      />
      <path
        d="M39.3642 0C39.3664 0.0360978 39.3706 0.072369 39.3707 0.108553C39.3711 0.468845 39.3693 0.829136 39.3723 1.18943C39.3729 1.26308 39.3556 1.31727 39.3005 1.37069C39.0486 1.61489 38.8001 1.86285 38.554 2.11305C38.5013 2.16647 38.4483 2.18636 38.3742 2.18714C37.5701 2.19511 36.7661 2.20566 35.9621 2.21749C35.2265 2.22829 34.4909 2.2415 33.7553 2.25359C33.7433 2.25384 33.7312 2.25342 33.7192 2.25402C33.6318 2.25753 33.6316 2.25753 33.6291 2.34079C33.6282 2.3732 33.6289 2.40561 33.6289 2.43802C33.6284 5.284 33.6277 8.1299 33.6288 10.9759C33.6288 11.0653 33.6099 11.1343 33.5448 11.2022C33.3242 11.4321 33.1113 11.6695 32.8963 11.9047C32.8698 11.9337 32.8352 11.9576 32.8261 11.9999C32.4884 11.9999 32.1505 11.9999 31.8128 11.9999C31.5631 11.7479 31.3156 11.4936 31.0621 11.2455C30.9989 11.1836 30.973 11.1231 30.9732 11.0344C30.977 9.47598 30.9734 7.91752 30.9596 6.35922C30.9529 5.59834 30.944 4.83737 30.9358 4.07657C30.9297 3.51401 30.9234 2.95145 30.9171 2.38889C30.9169 2.3768 30.9169 2.36462 30.9166 2.35245C30.9141 2.2589 30.9141 2.2577 30.8248 2.25625C30.6479 2.2535 30.4711 2.25256 30.2942 2.25067C29.4943 2.24193 28.6943 2.23301 27.8944 2.22443C27.3317 2.21835 26.7688 2.21106 26.2062 2.20874C26.1267 2.2084 26.072 2.18388 26.0173 2.12806C25.783 1.88883 25.5464 1.65176 25.3067 1.41819C25.2526 1.36546 25.2309 1.31281 25.2315 1.23787C25.2347 0.869521 25.2332 0.501169 25.2327 0.132817C25.2326 0.0884878 25.2285 0.0441577 25.2263 0C29.9388 0 34.6515 0 39.3642 0Z"
        fill="#EC018C"
      />
      <path
        d="M57.5075 0C57.5119 0.0458725 57.5517 0.0651662 57.5789 0.092604C57.8174 0.333113 58.0552 0.574479 58.298 0.810529C58.3614 0.872093 58.3846 0.934515 58.3846 1.02249C58.3824 3.89548 58.3828 6.76856 58.3828 9.64155C58.3828 9.8175 58.3828 9.8175 58.5525 9.8175C60.6947 9.8175 62.8368 9.8181 64.979 9.81552C65.075 9.81544 65.1432 9.84211 65.2104 9.91181C65.4342 10.1441 65.665 10.3696 65.8928 10.5981C65.9296 10.6351 65.9644 10.6742 66.0001 10.7125C66.0001 11.1416 66.0001 11.5708 66.0001 11.9999C62.8556 11.9999 59.7111 11.9999 56.5666 11.9999C56.3 11.7309 56.0356 11.4596 55.7653 11.1944C55.7018 11.132 55.6788 11.0699 55.6789 10.9823C55.6808 7.3739 55.6806 3.76549 55.6803 0.157081C55.6803 0.104692 55.676 0.052389 55.6738 0C56.2851 0 56.8962 0 57.5075 0Z"
        fill="#EC018C"
      />
      <path
        d="M18.6634 0C18.6736 0.0427 18.7108 0.0639648 18.7383 0.0920885C18.9812 0.339543 19.2241 0.587171 19.4698 0.831881C19.5192 0.881098 19.5398 0.931772 19.54 1.00191C19.5448 3.15852 19.5517 5.31504 19.558 7.47157C19.5601 8.2201 19.5612 8.96864 19.5628 9.71726C19.5628 9.73347 19.5645 9.74985 19.5626 9.76579C19.5556 9.82479 19.5823 9.84305 19.6389 9.84271C19.8398 9.84133 20.0407 9.8439 20.2416 9.84468C22.2429 9.85188 24.2442 9.85942 26.2454 9.86457C26.3274 9.86483 26.3881 9.88481 26.4467 9.94508C26.6917 10.1962 26.9412 10.4429 27.1897 10.6904C27.2305 10.7311 27.2611 10.7702 27.2606 10.8354C27.2574 11.2076 27.259 11.5799 27.2587 11.9522C27.2587 11.9681 27.2545 11.9841 27.2524 11.9999C24.0918 11.9999 20.9311 11.9999 17.7706 11.9999C17.5097 11.7366 17.2517 11.4703 16.9862 11.2118C16.91 11.1377 16.8825 11.0627 16.8826 10.958C16.885 7.35761 16.8846 3.75743 16.8843 0.157081C16.8843 0.104692 16.8801 0.052389 16.8779 0C17.4732 0 18.0683 0 18.6634 0Z"
        fill="#EC018C"
      />
    </svg>
  </Icon>
)

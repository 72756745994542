import styled, { css } from 'styled-components'

export const StyledLoaderPointsMini = styled.div`
  align-items: center;
  display: flex;
  height: fit-content;
  width: fit-content;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

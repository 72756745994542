import styled, { css } from 'styled-components'

export const EventsTableWrapper = styled.table<{
  isTopBorderRadius?: boolean
  isBottomBorderRadius?: boolean
}>`
  border-collapse: collapse;
  width: 100%;

  ${({ theme, isTopBorderRadius, isBottomBorderRadius }) => css`
    ${isTopBorderRadius &&
    css`
      border-top-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      border-top-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;

      overflow: hidden;
    `}

    ${isBottomBorderRadius &&
    css`
      border-bottom-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      border-bottom-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;

      overflow: hidden;
    `}
  `}
`

export const EventsTableGroupsWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
  `}
`

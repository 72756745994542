import omit from 'lodash/omit'

import { baseLegacyApi } from '../api'

import { CancelTransactionFetch, GetHistoryFetch } from './types'

export const transactionHistoryApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query<
      GetHistoryFetch['response'],
      GetHistoryFetch['request']
    >({
      query: (args) => ({
        method: 'POST',
        url: 'user/inout',
        body: {
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          offset: args.offset,
          time_shift: '0'
        }
      }),
      serializeQueryArgs: ({ queryArgs }) => omit(queryArgs, ['offset']),
      providesTags: ['TransactionHistory'],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),
    cancelTransaction: builder.mutation<
      CancelTransactionFetch['response'],
      CancelTransactionFetch['request']
    >({
      query: (args) => ({
        method: 'POST',
        url: 'user/inout/back',
        body: {
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          id: args.id
        }
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          if (data.data.response === 'success') {
            dispatch(
              transactionHistoryApi.util.invalidateTags(['TransactionHistory'])
            )
          }
        } catch {
          // Errors
        }
      }
    })
  })
})

export const onResetTransactionHistoryApiCache = () =>
  transactionHistoryApi.util.invalidateTags(['TransactionHistory'])

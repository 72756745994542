import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconSimKcell: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 42 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.6867 7.68614C25.6867 7.48629 25.8408 5.56031 27.8855 5.56031C29.241 5.56031 29.7304 6.35677 29.9873 7.68614H25.6867ZM27.9582 3.49223C24.9133 3.49223 23.0413 5.89047 23.0413 8.76386C23.0413 11.2465 24.4125 14 27.9582 14C31.1758 14 32.0904 11.8579 32.4628 10.6603H30.7849C30.3982 10.6603 30.1171 10.8186 29.8545 11.1547C29.7447 11.285 29.1825 11.9526 28.0524 11.9526C25.7637 11.9526 25.7309 10.0681 25.6867 9.42414H32.6312C32.8267 6.53145 31.3527 3.49223 27.9582 3.49223ZM34.2693 0.00444633V11.242C34.2893 13.1355 34.6246 13.7928 36.8975 13.7928L36.8961 2.22058C36.8775 0.35826 36.5293 0.00444633 34.2693 0.00444633ZM39.3716 0.00444633V11.242C39.3916 13.1355 39.7286 13.7928 42.0001 13.7928L41.997 2.22058C41.9802 0.35826 41.6333 0.00444633 39.3716 0.00444633ZM20.149 9.92596C20.149 9.92596 19.5469 9.90377 19.3157 10.5611C18.9875 11.4967 18.3868 11.9526 17.3637 11.9526C15.7728 11.9526 15.1678 10.3805 15.1678 8.76536C15.1678 7.23166 15.7899 5.56031 17.4208 5.56031C18.4382 5.56031 18.9775 6.03256 19.2087 6.64545C19.3342 6.97854 19.5212 7.28053 20.1048 7.28053H21.964C21.8227 4.70169 19.6853 3.49223 17.4365 3.49223C14.1233 3.49223 12.5252 5.98521 12.5252 8.88377C12.5252 12.1288 14.6955 14 17.3809 14C19.7295 14 21.6486 12.7772 22.0167 9.92596H20.149ZM0.777832 13.7927H3.68864V9.35309L5.36234 7.5929C5.36234 7.5929 6.97899 10.0844 8.06483 11.7824C9.15498 13.4759 9.67721 13.7927 11.6235 13.7927H13.0346L7.34002 5.44929L12.5309 0.00444115H11.1084C9.73143 0.00444115 8.83963 0.0562548 7.74521 1.25833C6.64513 2.45596 3.68864 5.72169 3.68864 5.72169V2.68542C3.68438 0.685419 3.35476 0 0.777832 0V13.7927Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

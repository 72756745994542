import React from 'react'

export const IconYoutube = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6654 4.21778C15.4811 3.54128 14.9397 3.00964 14.2509 2.82855C13.0036 2.5 7.99997 2.5 7.99997 2.5C7.99997 2.5 2.99637 2.5 1.74909 2.82855C1.06026 3.00964 0.518933 3.54128 0.334541 4.21778C1.0014e-07 5.44273 0 8 0 8C0 8 1.0014e-07 10.5573 0.334541 11.7822C0.518933 12.4587 1.06026 12.9904 1.74909 13.1714C2.99637 13.5 7.99997 13.5 7.99997 13.5C7.99997 13.5 13.0036 13.5 14.2509 13.1714C14.9397 12.9904 15.4811 12.4587 15.6654 11.7822C16 10.5573 16 8 16 8C16 8 15.9987 5.44273 15.6654 4.21778Z"
        fill="#FF0000"
      />
      <path
        d="M6.39847 10.357L10.5552 8.00022L6.39847 5.64343V10.357Z"
        fill="white"
      />
    </svg>
  )
}

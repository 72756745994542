import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { TCustomToastContentProps, ToastifyContent } from 'shared/lib/Toastify'

export const NotificationClearHistorySuccess: FC<
  TCustomToastContentProps
> = () => {
  const [t] = useTranslation()

  return (
    <ToastifyContent
      leftContent={<IconCheckCircleFilled colorToken="icon-positive" />}
    >
      {t('bets history deleted')}
    </ToastifyContent>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M0.0179036 16.7594C0.152181 16.5466 0.340169 16.4669 0.599772 16.4757C2.28271 16.4846 3.95671 16.4757 5.63965 16.4757C5.80973 16.4757 5.88135 16.4491 5.86344 16.2629C5.84554 16.0236 5.85449 15.7842 5.86344 15.5448C5.8724 15.2434 6.06933 15.075 6.36475 15.0838C6.64225 15.0838 6.82129 15.27 6.83024 15.5537C6.83024 15.7931 6.83919 16.0324 6.83024 16.2718C6.82129 16.4403 6.875 16.4757 7.03613 16.4757C8.02978 16.4757 9.02344 16.4757 10.0081 16.4757C12.3267 16.4757 14.6452 16.4757 16.9637 16.4757C17.1517 16.4757 17.2233 16.4314 17.2054 16.2364C17.1875 16.0058 17.1965 15.7753 17.2054 15.5448C17.2144 15.2611 17.4023 15.0838 17.6798 15.0838C17.9663 15.0838 18.1543 15.2523 18.1632 15.536C18.1722 15.7665 18.1812 16.0058 18.1632 16.2275C18.1453 16.4491 18.2259 16.4757 18.4228 16.4757C20.0968 16.4757 21.7619 16.4757 23.4359 16.4757C23.5254 16.4757 23.606 16.4757 23.6955 16.4934C23.9193 16.5466 24.0625 16.786 23.973 16.9633C23.6686 17.5928 23.3553 18.2222 22.9793 18.8251C22.8719 18.9935 22.7466 19.0644 22.5407 19.0556C21.9588 19.0379 21.3859 19.0556 20.804 19.0556C20.5176 19.0556 20.5176 19.0556 20.6071 19.3393C20.8577 20.1549 21.0994 20.9794 21.359 21.795C21.4128 21.9635 21.3859 22.0078 21.2069 21.9989C20.813 21.9901 20.4102 21.9901 20.0163 21.9989C19.873 21.9989 19.8193 21.9635 19.7746 21.8216C19.5239 20.9617 19.2554 20.1106 18.9958 19.2506C18.951 19.0999 18.8883 19.0467 18.7272 19.0467C14.2513 19.0467 9.77539 19.0467 5.29948 19.0467C5.13835 19.0467 5.06673 19.091 5.02197 19.2418C4.77132 20.0928 4.50277 20.9439 4.25212 21.7862C4.20736 21.9369 4.15365 21.9989 3.98356 21.9901C3.59863 21.9723 3.22266 21.9812 2.83773 21.9901C2.64974 21.9901 2.59603 21.9723 2.65869 21.7684C2.92725 20.9351 3.16894 20.084 3.4375 19.2506C3.49121 19.0733 3.4554 19.0467 3.28532 19.0467C2.6945 19.0467 2.09473 19.0467 1.50391 19.0556C1.27116 19.0556 1.12793 18.9758 1.01156 18.7896C0.680338 18.2577 0.402832 17.6903 0.116374 17.1406C0.0805664 17.0697 0.0716146 16.9899 0 16.9456V16.7594H0.0179036Z"
        fill={theme.tokens.colors['sport-icon']['sport-artisticGymnastics']}
      />
      <path
        d="M14.6542 10.0855C16.1134 10.4579 16.2387 10.9543 21.4308 11.5217C22.0484 11.5926 22.9794 11.9295 23.051 12.5058C23.1137 13.0377 20.4192 13.02 20.3207 13.02C17.8053 12.9934 16.373 12.8427 13.9381 12.1955C11.53 11.5572 8.03581 9 8 9C7.39128 9.72697 7.0179 11.2376 7 12C6.9821 12.6738 6.68711 14.0041 6.23057 14.0041C5.8904 14.0041 5.6487 13.3923 5.60394 13.0466C5.48757 12.2664 5.48757 11.0962 5.51442 10.3071C5.55023 9.25214 5.57708 8.97731 5.96201 7.98438C6.10524 7.61203 6.30218 7.284 6.58864 6.99144C7.25107 6.32653 7.90456 5.65275 8.55804 4.9701C8.70127 4.81939 8.8624 4.713 9.05934 4.63321C11.0466 3.87078 13.016 3.42751 15.1108 3.03742C15.5584 2.95763 16.2118 2.79805 16.2924 3.20587C16.3103 3.33885 15.8303 3.71216 15.5 4C14.0532 5.26082 12.5807 5.37791 10.9873 5.9453C10.9694 5.9453 10.9515 5.96303 10.9246 5.97189C10.9246 6.06055 11.0052 6.08715 11.05 6.13147C11.8377 6.87618 12.2104 7.284 13.1503 7.85139C13.3383 7.96665 13.4278 7.95778 13.5711 7.7716C15.1376 5.69708 18.4229 3.01969 18.7184 2.81579C19.4435 2.31932 20.9832 1.75192 21.4755 2.11541C21.7441 2.55869 21.0369 2.87784 20.6609 3.2502C20.455 3.4541 17.5994 5.42225 14.699 10.0146C14.69 10.0323 14.6811 10.0589 14.6632 10.0944L14.6542 10.0855Z"
        fill={theme.tokens.colors['sport-icon']['sport-artisticGymnastics']}
      />
      <path
        d="M6.93773 5.95416C6.54385 6.27332 6.25739 6.31765 5.72923 6.13147C3.98363 5.51975 4.17162 2.45229 5.94408 2.56754C7.36742 2.66506 8.06566 5.03215 6.93773 5.95416Z"
        fill={theme.tokens.colors['sport-icon']['sport-artisticGymnastics']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M0.0119358 12.1887C0.101454 12.0339 0.226779 11.9759 0.399848 11.9823C1.52181 11.9888 2.6378 11.9823 3.75977 11.9823C3.87315 11.9823 3.9209 11.963 3.90896 11.8276C3.89703 11.6535 3.90299 11.4794 3.90896 11.3053C3.91493 11.0861 4.04622 10.9636 4.24316 10.9701C4.42817 10.9701 4.54753 11.1055 4.55349 11.3118C4.55349 11.4859 4.55946 11.66 4.55349 11.834C4.54753 11.9566 4.58333 11.9823 4.69075 11.9823C5.35319 11.9823 6.01562 11.9823 6.67209 11.9823C8.21777 11.9823 9.76345 11.9823 11.3091 11.9823C11.4345 11.9823 11.4822 11.9501 11.4703 11.8083C11.4583 11.6406 11.4643 11.473 11.4703 11.3053C11.4762 11.099 11.6016 10.9701 11.7866 10.9701C11.9775 10.9701 12.1029 11.0926 12.1088 11.2989C12.1148 11.4665 12.1208 11.6406 12.1088 11.8018C12.0969 11.963 12.1506 11.9823 12.2819 11.9823C13.3979 11.9823 14.5079 11.9823 15.6239 11.9823C15.6836 11.9823 15.7373 11.9823 15.797 11.9952C15.9462 12.0339 16.0417 12.208 15.982 12.337C15.7791 12.7947 15.5702 13.2525 15.3196 13.691C15.2479 13.8135 15.1644 13.8651 15.0271 13.8586C14.6392 13.8457 14.2573 13.8586 13.8694 13.8586C13.6784 13.8586 13.6784 13.8586 13.7381 14.0649C13.9052 14.6581 14.0663 15.2577 14.2394 15.8509C14.2752 15.9734 14.2573 16.0057 14.1379 15.9992C13.8753 15.9928 13.6068 15.9928 13.3442 15.9992C13.2487 15.9992 13.2129 15.9734 13.183 15.8703C13.0159 15.2448 12.8369 14.6259 12.6638 14.0005C12.634 13.8908 12.5922 13.8522 12.4848 13.8522C9.50087 13.8522 6.51693 13.8522 3.53299 13.8522C3.42556 13.8522 3.37782 13.8844 3.34798 13.994C3.18088 14.613 3.00184 15.232 2.83474 15.8445C2.8049 15.9541 2.7691 15.9992 2.65571 15.9928C2.39909 15.9799 2.14844 15.9863 1.89182 15.9928C1.76649 15.9928 1.73069 15.9799 1.77246 15.8316C1.9515 15.2255 2.11263 14.6065 2.29167 14.0005C2.32747 13.8715 2.3036 13.8522 2.19021 13.8522C1.79633 13.8522 1.39648 13.8522 1.0026 13.8586C0.847439 13.8586 0.751953 13.8006 0.674371 13.6652C0.453559 13.2783 0.268555 12.8657 0.0775824 12.4659C0.0537109 12.4143 0.047743 12.3563 0 12.3241V12.1887H0.0119358Z"
        fill={theme.tokens.colors['sport-icon']['sport-artisticGymnastics']}
      />
      <path
        d="M8.65823 6.45506C9.76012 6.77463 9.85476 7.20073 13.7756 7.68769C14.2421 7.74857 14.9451 8.0377 14.9992 8.53228C15.0465 8.98881 13.0117 8.9736 12.9374 8.9736C11.0378 8.95077 9.95617 8.82142 8.11743 8.26597C6.29896 7.71813 3.47442 5.01236 3.31321 5.70509C3.152 6.39783 3.11072 6.67727 2.96291 7.29484C2.74838 8.19117 2.64176 9.81818 2.29699 9.81818C2.04011 9.81818 1.85759 9.29317 1.82379 8.99642C1.73591 8.32684 1.73591 7.32247 1.75619 6.64528C1.78323 5.73982 1.80351 5.50395 2.09419 4.65175C2.20235 4.33218 2.35107 4.05065 2.5674 3.79956C3.06764 3.22889 3.56113 2.65062 4.05462 2.06473C4.16278 1.93538 4.28446 1.84407 4.43318 1.7756C5.93392 1.12123 7.42114 0.740787 9.003 0.405996C9.341 0.337517 9.83449 0.200557 9.89533 0.550565C9.90885 0.664698 10.0028 1.22674 9.48572 1.4467C8.26932 1.9641 7.0957 2.39951 5.89241 2.88648C5.87889 2.88648 5.86537 2.9017 5.84509 2.90931C5.84509 2.9854 5.90593 3.00822 5.93973 3.04627C6.53461 3.68541 6.81273 4.05065 7.52254 4.53762C7.6645 4.63653 7.7321 4.62893 7.84026 4.46914C9.5 2.72727 12.2819 1.7756 12.2819 1.7756C12.2819 1.7756 14.1282 0.778945 14.5 1.09091C14.6569 1.36311 14.0328 1.82886 13.7488 2.14843C13.5934 2.32343 11.7866 3.41712 8.69203 6.39419C8.68527 6.4094 8.67851 6.43223 8.66499 6.46267L8.65823 6.45506Z"
        fill={theme.tokens.colors['sport-icon']['sport-artisticGymnastics']}
      />
      <path
        d="M2.83102 2.90931C2.53357 3.18323 2.31725 3.22127 1.91841 3.06149C0.60019 2.53647 0.742151 -0.0961996 2.08065 0.00271586C3.1555 0.0864135 3.68279 2.11798 2.83102 2.90931Z"
        fill={theme.tokens.colors['sport-icon']['sport-artisticGymnastics']}
      />
    </>
  )
}

export const IconSportArtisticGymnastics: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'

import { AvailableCouponsListProps } from './bonus-coupon-can-not-use.types'
import * as S from './bonus-coupon-can-not-use.styled'

export const BonusCouponCanNotUseList: FC<AvailableCouponsListProps> = ({
  isExpanded,
  onExpand,
  FirstCoupon,
  CouponList
}) => {
  const [t] = useTranslation()

  return (
    <S.StyledAvailableCoupons>
      <Typography color="text-secondary-2" font="Body / 12 Medium">
        {t('available coupons')}
      </Typography>

      {isExpanded ? (
        <S.StyledAvailableCouponsWrapper>
          {CouponList}

          <S.StyledAvailableCouponHide onClick={() => onExpand(false)}>
            {t('curtail')}
          </S.StyledAvailableCouponHide>
        </S.StyledAvailableCouponsWrapper>
      ) : (
        <S.StyledAvailableCouponsSingle>
          {FirstCoupon}

          <S.StyledAvailableCouponsOpen onClick={() => onExpand(true)}>
            {t(
              CouponList.length === 2 ? 'one more coupon' : 'few more coupons',
              {
                num: CouponList.length - 1
              }
            )}
          </S.StyledAvailableCouponsOpen>
        </S.StyledAvailableCouponsSingle>
      )}
    </S.StyledAvailableCoupons>
  )
}

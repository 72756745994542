import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { createPortal } from 'react-dom'

import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

import { IconCloseOutline16 } from '../Icon/General/IconCloseOutline16'

import {
  ModalBody,
  ModalConfirmBackdrop,
  ModalConfirmWrapper,
  ModalText,
  ModalTitle
} from './modal.styled'
import { ModalProps, ModalRef } from './modal.types'

export const Modal = forwardRef<ModalRef, ModalProps>(
  (props, ref): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false)
    const hydrated = useHydrated()

    useEffect(() => {
      const documentBody = document.body as HTMLDivElement

      if (isOpen) {
        documentBody.classList.add('fixed')
      }
      return () => {
        documentBody.classList.remove('fixed')
      }
    }, [isOpen])

    const handleClose = () => {
      setIsOpen(false)
    }

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false)
    }))

    if (!isOpen) {
      return null
    }

    const modalContent = (
      <ModalConfirmBackdrop onClick={handleClose}>
        <ModalConfirmWrapper onClick={(event) => event.stopPropagation()}>
          <ModalBody>
            <ModalTitle>
              {props.title}
              <IconCloseOutline16
                colorToken="icon-secondary-2"
                onClick={handleClose}
              />
            </ModalTitle>
            {props.text && <ModalText>{props.text}</ModalText>}
          </ModalBody>
          {props.children}
        </ModalConfirmWrapper>
      </ModalConfirmBackdrop>
    )

    return hydrated
      ? createPortal(
          modalContent,
          document.getElementById('modal-root') as Element
        )
      : null
  }
)

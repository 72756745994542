import styled from 'styled-components'

export const StyledLoaderFullContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

export const StyledLoaderContainer = styled.div`
  height: 60px;
  width: 60px;
`

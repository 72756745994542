import { isBrowser } from 'astra-core'

export const getItemLocalStorage = (key) => {
  if (isBrowser) {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      console.log(error)

      return null
    }
  } else {
    return null
  }
}

export const setItemLocalStorage = (key, valueToStore) => {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    }
  } catch (error) {
    console.log(error)
  }
}

export const removeItemLocalStorage = (key) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    console.log(error)
  }
}

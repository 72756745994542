import React, { useEffect, useMemo, useState } from 'react'
import { BetStatus } from 'betweb-openapi-axios'
import {
  betsHistoryProviderActions,
  SetDatePickerRangeValuesPayload
} from 'astra-core/containers/BetsHistoryProvider'
import { dayjsCore } from 'astra-core'
import { Pagination } from 'astra-core/typings/api'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { betsHistoryApi, resetBetsHistory } from 'entities/legacy-api'
import { BetsHistoryContextProvider } from 'entities/bet-history/lib/bet-history.context'
import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { Filter, SectionSelect } from './components'
import {
  StyledColumn,
  StyledHistory,
  StyledListWrapper
} from './History.styled'
import { HistoryListLegacy } from './components/list'
import { getIsResetPagination } from './constants'

const { useGetBetsHistoryQuery } = betsHistoryApi

const BETS_HISTORY_PAGE_SIZE = 10

export const HistoryLegacy = () => {
  const [pagination, setPagination] = useState<Pagination>({
    limit: BETS_HISTORY_PAGE_SIZE,
    offset: 0
  })

  useFetchBetsHistoryPage()
  const [betsStatus, setBetsStatus] = useState<BetStatus>(BetStatus.Current)
  const [betsDateRange, setBetsDateRange] =
    useState<SetDatePickerRangeValuesPayload>([null, null])

  const handleResetPagination = () => {
    handleSetPagination({
      offset: 0,
      limit: BETS_HISTORY_PAGE_SIZE
    })
  }

  const handleSetBetsDateRange = (
    dateList: SetDatePickerRangeValuesPayload
  ) => {
    if (getIsResetPagination(dateList)) {
      handleResetPagination()
    }

    setBetsDateRange(dateList)
  }

  const dateFilter = useMemo(() => {
    const [startDate, endDate] = betsDateRange

    if (!startDate || !endDate) return {}

    const date = dayjsCore(startDate).format('DD-MM-YYYY')
    const dateEnd = dayjsCore(endDate).format('DD-MM-YYYY')

    return { date, date_end: dateEnd }
  }, [betsDateRange])

  const dispatch = useAppDispatch()

  const filter = useMemo(() => {
    switch (betsStatus) {
      case BetStatus.Computed:
        return '100000'
      case BetStatus.Current:
        return '010000'
      case BetStatus.Won:
        return '001000'
      case BetStatus.Lost:
        return '000100'
      case BetStatus.Returned:
        return '000010'
      default:
        return '000000'
    }
  }, [betsStatus])

  const { data, isFetching } = useGetBetsHistoryQuery({
    time_shift: 0,
    filter,
    ...pagination,
    ...dateFilter,
    offset: pagination.offset / BETS_HISTORY_PAGE_SIZE
  })

  useEffect(() => {
    dispatch(betsHistoryProviderActions.setPagination(pagination))
  }, [dispatch, pagination])

  useEffect(() => {
    return () => {
      dispatch(resetBetsHistory())
    }
  }, [dispatch])

  const betsList = data?.data.bet_list ?? []
  const totalCount = data?.data.count ?? 0

  const hasMore = totalCount > betsList.length
  const betIds = useMemo(
    () => data?.data.bet_list.map((b) => b.bet_id) ?? [],
    [data]
  )

  const handleSetPagination = (newPagination: Pagination) => {
    dispatch(betsHistoryProviderActions.setPagination(newPagination))
    setPagination(newPagination)
  }

  const handleBetStatusChange = (newStatus: BetStatus) => {
    // Set new status
    setBetsStatus(newStatus)
    dispatch(betsHistoryProviderActions.setFilterBetStatus(newStatus))

    // Reset pagination
    handleResetPagination()
  }

  useEffect(() => {
    dispatch(betsHistoryProviderActions.setFilterBetStatus(BetStatus.Unknown))
  }, [dispatch])

  return (
    <BetsHistoryContextProvider betIds={betIds}>
      <StyledHistory>
        <SectionSelect
          betsStatus={betsStatus}
          setBetsStatus={handleBetStatusChange}
        />
        <Filter
          onChangeDatePicker={handleSetBetsDateRange}
          onChangeSelect={handleSetBetsDateRange}
        />

        <StyledColumn>
          {isFetching && pagination.offset === 0 ? (
            <LoaderSpinner />
          ) : (
            <StyledListWrapper
              pagination={{
                pagination,
                onSetPagination: handleSetPagination,
                hasMore,
                isLoading: isFetching
              }}
            >
              <HistoryListLegacy bets={betsList} />
            </StyledListWrapper>
          )}
        </StyledColumn>
      </StyledHistory>
    </BetsHistoryContextProvider>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M7.54698 1.61914C8.82848 1.61914 9.9498 1.63171 11.0711 1.61286C11.4449 1.60658 11.4889 1.78561 11.4889 2.09029C11.4826 4.79464 11.4857 7.50214 11.4857 10.3384C10.845 9.68196 10.6031 8.83076 9.82416 8.3659C9.64827 8.73968 9.77391 9.0789 9.87128 9.40242C10.6911 12.1696 11.8721 14.7986 12.9588 17.4652C13.8352 19.6199 14.7366 21.7652 15.5218 24.0016C14.9094 23.4707 14.3691 22.8143 13.675 22.4374C12.1233 21.5987 11.4983 20.179 10.9204 18.6682C10.622 17.8893 10.311 17.0946 9.60744 16.3879C9.52263 17.2014 9.83359 17.8107 10.0032 18.4358C10.2136 19.2116 10.4806 19.9749 10.7539 20.7318C10.9047 21.1527 10.867 21.3317 10.3487 21.3192C9.09546 21.2878 7.84223 21.3098 6.39739 21.3098C7.33967 19.7833 7.6098 18.1908 7.32083 16.5041C7.38679 16.4947 7.44961 16.4821 7.51557 16.4727C7.99613 17.415 8.4767 18.3604 8.95726 19.3027C9.03265 19.2776 9.10803 19.2493 9.18341 19.2242C8.56778 16.0267 7.34596 12.9894 6.54501 9.82959C7.65691 11.1237 8.04639 12.8512 9.24937 14.2929C9.37815 13.561 9.16771 13.0616 9.00124 12.5716C7.77941 9.03178 6.5513 5.50136 5.33889 1.96779C5.11902 1.33332 4.94941 0.679999 4.83948 -0.00158691C6.47591 2.32586 7.25173 5.1213 9.00124 7.65605C9.09861 6.40595 8.70285 5.48251 8.44215 4.54337C8.17831 3.59794 7.87049 2.66194 7.55012 1.62228L7.54698 1.61914Z"
        fill="#4AC4F1"
      />
      <path
        d="M19.1622 17.3961C18.823 18.5928 18.5089 19.711 18.1917 20.8292C18.1791 20.8794 18.1697 20.9454 18.1351 20.9737C17.5164 21.4542 16.7971 21.1087 16.1281 21.1998C15.8485 21.2375 15.5627 21.2532 15.4213 20.8857C15.0319 19.8649 14.6204 18.8535 14.2121 17.8421C14.1053 17.5783 14.0896 17.393 14.4665 17.393C16.0056 17.3993 17.5415 17.393 19.1591 17.393L19.1622 17.3961Z"
        fill="#4AC4F1"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M4.89121 1.08035C5.78594 1.08035 6.56884 1.08872 7.35173 1.07616C7.6127 1.07197 7.6434 1.19131 7.6434 1.3944C7.63901 3.19707 7.64121 5.00183 7.64121 6.89244C7.19384 6.45485 7.02498 5.88746 6.48112 5.5776C6.35831 5.82675 6.44603 6.05287 6.51401 6.26852C7.08638 8.11306 7.91094 9.86548 8.66971 11.643C9.28156 13.0793 9.91094 14.5093 10.4592 16C10.0316 15.6462 9.65436 15.2086 9.16971 14.9573C8.08638 14.3983 7.64998 13.452 7.24647 12.4449C7.03814 11.9257 6.82103 11.396 6.3298 10.9249C6.27059 11.4672 6.4877 11.8733 6.60612 12.29C6.75305 12.8071 6.93945 13.3159 7.13024 13.8205C7.2355 14.101 7.20919 14.2204 6.84735 14.212C5.97235 14.191 5.09735 14.2057 4.08857 14.2057C4.74647 13.1882 4.93507 12.1267 4.73331 11.0024C4.77936 10.9961 4.82322 10.9877 4.86928 10.9814C5.2048 11.6095 5.54033 12.2397 5.87585 12.8678C5.92849 12.8511 5.98112 12.8322 6.03375 12.8155C5.60392 10.6841 4.75085 8.65951 4.19164 6.55326C4.96796 7.41586 5.23989 8.56739 6.0798 9.5284C6.16971 9.04056 6.02278 8.70767 5.90656 8.38105C5.05349 6.02146 4.19603 3.66815 3.34954 1.31275C3.19603 0.889819 3.07761 0.454331 3.00085 0C4.1434 1.55143 4.68506 3.41481 5.90656 5.10442C5.97454 4.27113 5.69822 3.65559 5.51621 3.02957C5.332 2.39937 5.11708 1.77545 4.8934 1.08244L4.89121 1.08035Z"
        fill="#4AC4F1"
      />
      <path
        d="M13.0009 11.597C12.764 12.3947 12.5447 13.14 12.3232 13.8854C12.3145 13.9189 12.3079 13.9628 12.2837 13.9817C11.8517 14.302 11.3495 14.0717 10.8824 14.1324C10.6873 14.1576 10.4877 14.168 10.389 13.9231C10.1171 13.2426 9.8298 12.5684 9.54471 11.8943C9.47015 11.7184 9.45919 11.5949 9.72235 11.5949C10.7969 11.5991 11.8693 11.5949 12.9987 11.5949L13.0009 11.597Z"
        fill="#4AC4F1"
      />
    </>
  )
}

export const IconCybersportLeagueOfLegendsWildRift: FC<
  IIconsSportNameProps
> = ({ sport, ...props }) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

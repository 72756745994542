import React, { FC } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { Link, useHistory } from 'react-router-dom'

import { useAppSelector } from '../@reduxjs'

import { LinkElement } from './app-link.styled'
import { AppLinkProps } from './app-link.types'

export const AppLink: FC<AppLinkProps> = ({ to, forceSelfLink, ...rest }) => {
  const config = useAppSelector(selectConfig)
  const history = useHistory()

  const handleLinkClick = () => {
    history.push(to as string)
  }

  if (config.IS_IFRAME || forceSelfLink) {
    return (
      <LinkElement className={rest.className} onClick={handleLinkClick}>
        {rest.children}
      </LinkElement>
    )
  }

  return <Link to={to} {...rest} />
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M21.7504 6.8268L22 3.16638L20.0034 3L19.4211 7.15957C19.4211 7.15957 18.8387 13.6485 14.6792 15.3955C11.9338 16.477 6.5264 14.3972 4.52981 13.5653C4.11385 13.3989 3.61471 13.5653 3.44832 14.0645L2.11726 17.0593C1.86769 17.4753 2.03407 17.9744 2.45003 18.224C4.44662 19.3887 10.7692 22.9659 16.343 19.8878C23.0815 16.2274 21.7504 6.8268 21.7504 6.8268ZM11.1019 7.6587C11.1019 10.0019 9.20236 11.9015 6.85914 11.9015C4.51593 11.9015 2.61638 10.0019 2.61638 7.6587C2.61638 5.31549 4.51593 3.41594 6.85914 3.41594C9.20236 3.41594 11.1019 5.31549 11.1019 7.6587Z"
      fill={theme.tokens.colors['sport-icon']['sport-bandy']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M15.8003 3.81828L16 0.889942L14.4027 0.756836L13.9368 4.08449C13.9368 4.08449 13.4709 9.27563 10.1433 10.6732C7.94703 11.5384 3.62108 9.87461 2.0238 9.20908C1.69104 9.07597 1.29172 9.20908 1.15861 9.6084L0.0937635 12.0043C-0.105896 12.3371 0.0272104 12.7364 0.359976 12.9361C1.95725 13.8678 7.01529 16.7296 11.4743 14.2671C16.8651 11.3388 15.8003 3.81828 15.8003 3.81828ZM7.28144 4.48386C7.28144 6.35843 5.7618 7.87806 3.88723 7.87806C2.01266 7.87806 0.493021 6.35843 0.493021 4.48386C0.493021 2.60929 2.01266 1.08965 3.88723 1.08965C5.7618 1.08965 7.28144 2.60929 7.28144 4.48386Z"
      fill={theme.tokens.colors['sport-icon']['sport-bandy']}
      fillRule="evenodd"
    />
  )
}

export const IconSportBandy: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

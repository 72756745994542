import styled, { css } from 'styled-components'

import { ESwitchView, SwitchProps } from './types'

export const StyledSwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const StyledSwitch = styled.div<SwitchProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 3px;
  transition: all 0.3s;

  ${({ active, theme, view, size }) => css`
    border-radius: ${size === 16
      ? theme.tokens.alias['border-radius']['border-radius-m-8']
      : theme.tokens.alias['border-radius']['border-radius-l-12']}px;
    height: ${size === 16 ? '16px' : '24px'};
    width: ${size === 16 ? '28px' : '40px'};

    ${!active &&
    css`
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-active-4']};
      }
    `}

    ${view === ESwitchView.PRIMARY &&
    css`
      background-color: ${active
        ? theme.tokens.colors.surface['surface-active2']
        : theme.tokens.colors.surface['surface-hover']};
    `}

     ${view === ESwitchView.BONUS &&
    css`
      background-color: ${active
        ? theme.tokens.colors.icon['icon-warning']
        : theme.tokens.colors.surface['surface-hover']};
      border-radius: 12px;
    `}
  `}
`

export const StyledSwitchPin = styled.div<SwitchProps>`
  border-radius: 50%;
  transition: all 0.3s;

  ${({ active, theme, view, size }) => css`
    height: ${size === 16 ? '10px' : '16px'};
    transform: ${active ? 'translateX(calc(100% + 2px))' : 'translateX(0)'};
    width: ${size === 16 ? '10px' : '16px'};

    ${view === ESwitchView.PRIMARY &&
    css`
      background-color: ${active
        ? theme.tokens.colors.surface['surface-invert']
        : theme.tokens.colors.surface['surface-active2']};
    `}

    ${view === ESwitchView.BONUS &&
    css`
      background-color: ${active
        ? theme.tokens.colors.surface['surface-invert']
        : theme.tokens.colors.surface['surface-active2']};
    `}
  `};
`

export const StyledSwitchLabel = styled.div<SwitchProps>`
  ${({ size, theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
    font-size: ${size === 16 ? '12px' : '14px'};
    font-weight: 600;
    line-height: ${size === 16 ? '16px' : '20px'};
  `}
`

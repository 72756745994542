import { useCallback, useEffect, useRef } from 'react'
import { EntityTag, Sport, Tournament } from 'betweb-openapi-axios'

import { usePrevious } from 'shared/lib/use-previous/usePrevious'
import { selectEntityTags } from 'entities/event/model'
import { getTopTagIds } from 'entities/event/model/constants'
import { useAppSelector } from 'shared/lib/@reduxjs'

interface UseEventsPollArgs {
  sportId?: Sport['id']
  tournamentId?: Tournament['id']
  top?: boolean
  isLoading?: boolean
  fetch: (args: {
    sportId?: Sport['id']
    tournamentId?: Tournament['id']
    tagIds?: EntityTag['id'][]
    top?: boolean
  }) => void
  pollingInterval: number
}

export const useEventsPoll = ({
  sportId,
  tournamentId,
  top,
  fetch,
  pollingInterval
}: UseEventsPollArgs) => {
  const sportIdPrev = usePrevious(sportId)
  const topPrev = usePrevious(top)
  const tournamentIdPrev = usePrevious(tournamentId)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const entityTags = useAppSelector(selectEntityTags)

  const internalFetch = useCallback(() => {
    clearTimeout(timeoutRef.current)
    fetch({
      sportId,
      tournamentId,
      top,
      tagIds: top ? getTopTagIds(entityTags) : []
    })
    timeoutRef.current = setTimeout(internalFetch, pollingInterval)
  }, [entityTags, fetch, pollingInterval, sportId, top, tournamentId])

  useEffect(() => {
    /* To handle fetch on toggle TOP */
    if (top !== topPrev) {
      internalFetch()
    }
  }, [top, topPrev, internalFetch])

  useEffect(() => {
    /* To handle fetch on sport id change */
    if (!!sportIdPrev && sportIdPrev !== sportId) {
      internalFetch()
    }
  }, [sportId, sportIdPrev, internalFetch])

  useEffect(() => {
    /* To handle fetch on tournament id change */
    if (!!tournamentIdPrev && tournamentIdPrev !== tournamentId) {
      internalFetch()
    }
  }, [tournamentId, tournamentIdPrev, internalFetch])

  useEffect(() => {
    internalFetch()
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [internalFetch])
}

import { css, styled } from 'styled-components'

export const StyledWrapperIconStar = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    & svg {
      color: ${isActive
        ? `${theme.tokens.colors.icon['icon-warning']} !important`
        : theme.tokens.colors.icon['icon-secondary-3']};
      transition: all 0.3s;
    }

    &:hover {
      & svg {
        color: ${isActive
          ? `${theme.tokens.colors.icon['icon-warningDarker']} !important`
          : theme.tokens.colors.icon['icon-secondary']};
      }
    }
  `}
`

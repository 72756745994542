import styled from 'styled-components'

import { DataListWithPagination } from 'shared/ui/DataList'
import { Scrollable } from 'shared/ui/scrollable'

export const StyledBasketHistoryWrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: calc(100% - 48px);
`

export const StylesBasketHistoryContent = styled.div`
  padding: 0 8px 8px;
`

export const StyledBasketHistoryFilters = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 1fr;
  padding: 8px;
`

export const StyledBasketHistoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledListWrapper = styled(DataListWithPagination)`
  justify-self: center;
  width: 100%;
`

export const StyledBasketHistoryScrollable = styled(Scrollable)`
  .rcs-positioning .rcs-inner-handle {
    height: calc(100% - 20px);
  }
`

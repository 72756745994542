import { ReactNode } from 'react'

export const enum ESelectView {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export interface OptionType<IDType> {
  value: IDType
  label: ReactNode
}

export interface SelectProps<IDType> {
  id?: string
  placeholder?: ReactNode
  options: OptionType<IDType>[]
  value: OptionType<IDType>
  onChange: (value: OptionType<IDType>) => void
  isDisabled?: boolean
  leftIcon?: ReactNode
  className?: string
  label?: string
  view?: ESelectView
}

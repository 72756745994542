import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M19.0133 2.00049L16.6066 14.8038C16.4407 15.3896 15.8597 15.808 15.2788 15.808H5.0779C4.24802 15.808 3.66711 16.4775 3.66711 17.2306V20.5779C3.66711 21.4147 4.33101 22.0005 5.0779 22.0005L14.5319 21.9168C16.8556 21.9168 18.8473 20.1595 19.2622 17.9001L22.0008 2.00049H19.0133ZM8.30759 9.61527C6.14991 9.61527 4.32418 9.19686 2.99637 8.5274C2.66442 8.36004 2.24948 8.10899 2.00052 7.85795V10.201V10.3684C2.08351 12.0421 4.90509 13.381 8.30759 13.381C11.7101 13.381 14.4487 12.0421 14.6147 10.3684V10.2847V7.85795C14.3657 8.10899 13.9508 8.36004 13.6188 8.5274C12.208 9.19686 10.3823 9.61527 8.30759 9.61527ZM2.02312 5.74365C2.02312 4.5721 4.59574 3.23319 8.33019 3.23319C11.9816 3.23319 14.5543 4.5721 14.4713 5.74365C14.4713 6.32942 13.8074 6.99888 12.5626 7.50097C11.4837 7.91938 9.98994 8.25411 8.2472 8.25411C6.50446 8.25411 5.01068 7.91938 3.93184 7.50097C2.77001 6.99888 2.02312 6.32942 2.02312 5.74365Z"
      fill={theme.tokens.colors['sport-icon']['sport-hockey']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M13.6101 0.00012207L11.6848 10.2428C11.552 10.7114 11.0873 11.0461 10.6226 11.0461H2.46196C1.79807 11.0461 1.33335 11.5817 1.33335 12.1842V14.862C1.33335 15.5315 1.86446 16.0001 2.46196 16.0001L10.0251 15.9332C11.884 15.9332 13.4773 14.5273 13.8093 12.7198L16.0001 0.00012207H13.6101ZM5.04562 6.09152C3.3195 6.09152 1.85894 5.75679 0.796715 5.22122C0.531159 5.08733 0.199213 4.88649 4.57764e-05 4.68566V6.56013V6.69403C0.0664349 8.03294 2.32367 9.10407 5.04562 9.10407C7.76757 9.10407 9.95842 8.03294 10.0912 6.69403V6.62708V4.68566C9.89203 4.88649 9.56008 5.08733 9.29453 5.22122C8.16591 5.75679 6.70535 6.09152 5.04562 6.09152ZM0.0180634 2.99462C0.0180634 2.05738 2.07613 0.986253 5.06364 0.986253C7.98476 0.986253 10.0428 2.05738 9.97643 2.99462C9.97643 3.46324 9.44532 3.99881 8.44948 4.40048C7.58642 4.73521 6.39142 5.00299 4.99725 5.00299C3.60308 5.00299 2.40807 4.73521 1.54501 4.40048C0.615566 3.99881 0.0180634 3.46324 0.0180634 2.99462Z"
      fill={theme.tokens.colors['sport-icon']['sport-hockey']}
      fillRule="evenodd"
    />
  )
}

export const IconSportHockeyShort: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M13.0059 8.56736C15.3936 8.57547 17.7814 8.56128 20.1711 8.5775C20.806 8.58155 21.2931 8.37472 21.6953 7.84951C22.3539 6.99174 23.0578 6.17251 23.738 5.33299C23.8583 5.18496 24.0791 5.04504 23.9727 4.82198C23.8701 4.605 23.6256 4.68409 23.4383 4.68409C21.1649 4.68003 18.8915 4.68206 16.6181 4.68206C15.0486 4.68206 13.4771 4.68206 11.9076 4.68206C11.1721 4.68206 10.4347 4.66989 9.69925 4.68611C8.93027 4.70234 8.48466 5.02679 8.3072 5.70814C6.84812 11.3151 5.39692 16.924 3.93389 22.5289C3.81953 22.9669 3.9753 23.046 4.34007 22.9791C5.40086 22.7844 6.46166 22.5877 7.52442 22.3951C8.15341 22.2815 8.52409 21.9165 8.68775 21.2696C9.24575 19.0796 9.83924 16.8976 10.3894 14.7056C10.4879 14.3122 10.6437 14.2209 11.0164 14.2229C13.4692 14.2412 15.924 14.2189 18.3769 14.2392C19.0374 14.2453 19.5284 14.0242 19.9405 13.4868C20.5872 12.6412 21.2812 11.8362 21.9497 11.0068C22.068 10.8588 22.2947 10.725 22.1961 10.4999C22.0956 10.2687 21.8491 10.3681 21.6657 10.3681C18.7219 10.362 15.7761 10.362 12.8323 10.362C12.6687 10.362 12.5031 10.3701 12.3434 10.3458C11.7834 10.2646 11.5448 9.79622 11.807 9.2771C12.0555 8.78231 12.4636 8.56736 13.0098 8.56939L13.0059 8.56736Z"
        fill="#FEBA00"
      />
      <path
        d="M9.25144 3.80204C12.211 3.80204 15.1726 3.76351 18.1302 3.82232C19.189 3.8426 20.025 3.61143 20.5791 2.64213C20.6717 2.4799 20.9241 2.3704 20.8019 2.13517C20.6994 1.93645 20.4785 2.01553 20.307 2.01553C17.9192 2.01148 15.5314 2.01351 13.1437 2.01351C10.7717 2.01351 8.40162 2.01148 6.02962 2.01351C5.32966 2.01351 4.86235 2.29943 4.6987 2.90169C4.22943 4.62939 3.79367 6.36723 3.33623 8.09899C3.24356 8.44575 3.38355 8.57147 3.69508 8.57147C4.05394 8.5735 4.41477 8.56539 4.77363 8.57553C4.91165 8.57959 5.10882 8.49239 5.17192 8.65867C5.24093 8.83915 5.02601 8.88984 4.92151 8.97501C4.27675 9.49008 3.62213 9.995 2.97343 10.506C2.8709 10.5871 2.7664 10.6723 2.68161 10.7717C2.59288 10.8751 2.5357 11.015 2.57908 11.1468C2.63232 11.3111 2.79794 11.2604 2.92019 11.2624C3.32834 11.2685 3.73846 11.2624 4.14661 11.2685C4.25111 11.2685 4.399 11.2239 4.43054 11.3841C4.45223 11.4956 4.3359 11.5463 4.26097 11.6051C3.59453 12.1202 2.92808 12.6352 2.26163 13.1483C2.1315 13.2497 1.9915 13.3429 1.93038 13.5092C1.83376 13.7708 1.81996 13.9634 2.19459 13.9452C2.61852 13.9249 3.04441 13.9391 3.47031 13.9411C3.57875 13.9411 3.72466 13.8965 3.77001 14.0405C3.81536 14.1825 3.67142 14.2352 3.58664 14.3021C2.90245 14.8476 2.22022 15.3971 1.52617 15.9284C1.3152 16.0886 1.19098 16.2833 1.12591 16.5347C0.769026 17.9116 0.406226 19.2885 0.0532853 20.6654C-0.0729059 21.1581 0.0118788 21.2514 0.492983 21.1683C1.21661 21.0405 1.94024 20.9067 2.66387 20.7749C3.31257 20.6573 3.68917 20.2781 3.85874 19.613C5.09502 14.7807 6.34707 9.95242 7.59321 5.12215C7.86334 4.0758 8.19656 3.80407 9.2475 3.80407L9.25144 3.80204Z"
        fill="#FEBA00"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M8.67085 5.71161C10.2627 5.71702 11.8546 5.70756 13.4477 5.71837C13.871 5.72108 14.1957 5.58319 14.4638 5.23305C14.9029 4.6612 15.3721 4.11504 15.8256 3.55537C15.9058 3.45668 16.053 3.3634 15.9821 3.21469C15.9137 3.07004 15.7507 3.12276 15.6258 3.12276C14.1102 3.12006 12.5946 3.12141 11.079 3.12141C10.0327 3.12141 8.98502 3.12141 7.93868 3.12141C7.44838 3.12141 6.95676 3.1133 6.46645 3.12412C5.9538 3.13493 5.65672 3.35123 5.53842 3.80546C4.5657 7.54341 3.59823 11.2827 2.62288 15.0193C2.54664 15.3113 2.65048 15.364 2.89366 15.3194C3.60086 15.1896 4.30806 15.0585 5.01657 14.9301C5.43589 14.8544 5.68301 14.611 5.79212 14.1798C6.16412 12.7198 6.55978 11.2651 6.92652 9.80375C6.99225 9.54149 7.09609 9.48065 7.34453 9.48201C8.97976 9.49417 10.6163 9.4793 12.2515 9.49282C12.6919 9.49688 13.0192 9.34952 13.2939 8.99127C13.7251 8.42754 14.1878 7.89084 14.6334 7.33792C14.7123 7.23924 14.8634 7.15001 14.7977 6.99995C14.7307 6.84584 14.5663 6.91208 14.4441 6.91208C12.4816 6.90803 10.5177 6.90803 8.55518 6.90803C8.44608 6.90803 8.33566 6.91343 8.22919 6.89721C7.85587 6.84314 7.69682 6.53085 7.87164 6.18477C8.03727 5.85491 8.30937 5.71161 8.67348 5.71297L8.67085 5.71161Z"
        fill="#FEBA00"
      />
      <path
        d="M6.16791 2.53474C8.14096 2.53474 10.1153 2.50905 12.0871 2.54825C12.793 2.56177 13.3503 2.40766 13.7197 1.76146C13.7814 1.65331 13.9497 1.58031 13.8682 1.42349C13.7999 1.29101 13.6526 1.34373 13.5383 1.34373C11.9464 1.34103 10.3546 1.34238 8.76272 1.34238C7.18139 1.34238 5.60137 1.34103 4.02003 1.34238C3.55339 1.34238 3.24185 1.53299 3.13275 1.9345C2.8199 3.0863 2.5294 4.24486 2.22444 5.39937C2.16266 5.63054 2.25598 5.71436 2.46367 5.71436C2.70291 5.71571 2.94346 5.7103 3.1827 5.71706C3.27472 5.71976 3.40616 5.66163 3.44823 5.77249C3.49424 5.8928 3.35096 5.9266 3.28129 5.98338C2.85145 6.32676 2.41504 6.66338 1.98257 7.00405C1.91422 7.05813 1.84455 7.1149 1.78803 7.18115C1.72887 7.25009 1.69075 7.34337 1.71967 7.43124C1.75516 7.54075 1.86558 7.50695 1.94708 7.5083C2.21918 7.51236 2.49259 7.5083 2.76469 7.51236C2.83436 7.51236 2.93295 7.48261 2.95398 7.58941C2.96844 7.66377 2.89088 7.69756 2.84093 7.73677C2.39664 8.08015 1.95234 8.42352 1.50804 8.76555C1.42128 8.83314 1.32795 8.89533 1.2872 9.00618C1.22279 9.18058 1.21359 9.309 1.46335 9.29684C1.74596 9.28332 2.02989 9.29278 2.31382 9.29413C2.38612 9.29413 2.48339 9.26439 2.51363 9.36038C2.54386 9.45501 2.4479 9.49016 2.39138 9.53477C1.93525 9.89842 1.48043 10.2648 1.01773 10.619C0.877083 10.7258 0.79427 10.8556 0.750892 11.0232C0.512969 11.9411 0.271102 12.859 0.0358084 13.777C-0.0483191 14.1055 0.00820403 14.1677 0.32894 14.1122C0.811358 14.0271 1.29378 13.9378 1.7762 13.85C2.20866 13.7716 2.45973 13.5188 2.57278 13.0753C3.39696 9.85381 4.23167 6.63499 5.06243 3.41481C5.24251 2.71724 5.46466 2.53609 6.16528 2.53609L6.16791 2.53474Z"
        fill="#FEBA00"
      />
    </>
  )
}

export const IconCybersportFreeFire: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

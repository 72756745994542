import { selectAccountBonus } from 'astra-core/containers/AccountProvider'
import {
  basketProviderActions,
  selectBasketBonusWallet
} from 'astra-core/containers/BasketProvider'
import { FC, PropsWithChildren, useCallback } from 'react'

import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { useGetSuitableCouponsForBetTypeTab } from 'entities/basket-bonus-coupon'
import { BasketBonus } from 'entities/basket-bonus'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './bonus.styled'

// Separate Bonus and Bonus Coupon
export const Bonus: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const bonus = useAppSelector(selectAccountBonus)
  const suitableCoupons = useGetSuitableCouponsForBetTypeTab()
  const isBonusWallet = useAppSelector(selectBasketBonusWallet)

  const handleChange = useCallback(() => {
    dispatch(basketProviderActions.setBonusWallet(!isBonusWallet))
  }, [isBonusWallet, dispatch])

  return bonus || !!suitableCoupons.length ? (
    <S.BonusInfoWithSuitableCoupons>
      <BasketBonus
        bonusAmountValue={formatMonetaryAmount(bonus)}
        isActiveSwitchChange={isBonusWallet}
        onChange={handleChange}
      />

      <S.Divider />

      {children}
    </S.BonusInfoWithSuitableCoupons>
  ) : null
}

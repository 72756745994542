import styled, { css } from 'styled-components'
import { range } from 'lodash'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'

import { StyledSportsMenuList } from './sports-menu-list.styled'

export const SportMenuListLoader = () => {
  return (
    <StyledSportsMenuList>
      {range(30).map((idx) => (
        <MenuItem key={idx} />
      ))}
    </StyledSportsMenuList>
  )
}

const MenuItem = () => (
  <StyledRow>
    <ContentLoader borderRadius={8} height={24} width={24} disableAnimation />
    <ContentLoader borderRadius={4} height={16} width={74} disableAnimation />
    <ContentLoader borderRadius={4} height={16} width={16} disableAnimation />
  </StyledRow>
)

const StyledRow = styled.div`
  ${({ theme }) => css`
    align-items: center;

    background-color: ${theme.tokens.colors.surface['surface-default-opacity']};
    display: grid;
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
    grid-template-columns: auto 1fr auto;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconTimeSelect: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8.66667 5.66667C8.66667 5.29848 8.36819 5 8 5C7.63181 5 7.33333 5.29848 7.33333 5.66667V8C7.33333 8.17681 7.40357 8.34638 7.5286 8.4714L9.5286 10.4714C9.78895 10.7318 10.2111 10.7318 10.4714 10.4714C10.7318 10.2111 10.7318 9.78895 10.4714 9.5286L8.66667 7.72386V5.66667Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M3.17117 14.4509C3.4035 15.1586 3.9577 15.5125 4.83619 15.5125H8.09846C8.80271 15.5125 9.15362 15.343 9.15362 15.0066C9.15362 14.4808 8.15897 13.4691 6.16724 11.9689C5.36377 11.3907 4.15856 10.521 2.55647 9.36225H5.10966C7.14979 10.5808 8.87289 11.8069 10.2765 13.0404C11.7746 14.3612 12.5224 15.4128 12.5224 16.1953C12.5224 16.7037 12.1812 17.0625 11.4987 17.2668H0.000854492L3.17117 14.4484V14.4509ZM13.6284 6.7332L11.9222 9.36225L10.4242 8.48757H2.55647C2.55647 7.94431 2.70893 7.51569 3.01386 7.2017C3.31879 6.88771 3.73505 6.73071 4.26263 6.73071H13.6308L13.6284 6.7332ZM13.7784 8.49006L16.3316 6.7332H24.0009L22.2947 9.36225L20.5885 8.48757H13.776L13.7784 8.49006ZM13.7784 11.1191V9.36474H16.3316V11.1191C16.3316 12.5096 16.5784 13.626 17.0721 14.4634C17.6772 15.495 18.6113 16.0109 19.8746 16.0109C20.591 16.0109 21.3968 15.8414 22.2947 15.505C20.8015 16.6812 19.4317 17.2693 18.1854 17.2693C17.2585 17.2693 16.4429 16.9504 15.7387 16.3099C15.1119 15.7442 14.6279 14.9842 14.2866 14.0248C13.9454 13.0653 13.776 12.096 13.776 11.1141L13.7784 11.1191Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M2.1144 9.63397C2.26928 10.1058 2.63875 10.3417 3.22441 10.3417H5.39926C5.86876 10.3417 6.1027 10.2287 6.1027 10.0044C6.1027 9.65391 5.4396 8.97941 4.11178 7.97929C3.57613 7.59386 2.77266 7.01406 1.7046 6.24154H3.40672C4.76681 7.05393 5.91555 7.8713 6.85131 8.69366C7.85 9.57416 8.34854 10.2752 8.34854 10.7969C8.34854 11.1358 8.12105 11.375 7.66608 11.5113H0.000854492L2.1144 9.63231V9.63397ZM9.08586 4.48884L7.94842 6.24154L6.94973 5.65842H1.7046C1.7046 5.29625 1.80624 5.0105 2.00953 4.80117C2.21281 4.59185 2.49032 4.48718 2.84204 4.48718H9.08747L9.08586 4.48884ZM9.18589 5.66008L10.888 4.48884H16.0009L14.8634 6.24154L13.726 5.65842H9.18428L9.18589 5.66008ZM9.18589 7.41278V6.2432H10.888V7.41278C10.888 8.3398 11.0526 9.08407 11.3817 9.64228C11.7851 10.3301 12.4078 10.674 13.25 10.674C13.7276 10.674 14.2648 10.561 14.8634 10.3367C13.868 11.1209 12.9548 11.5129 12.1239 11.5129C11.5059 11.5129 10.9622 11.3003 10.4927 10.8733C10.0749 10.4962 9.75219 9.98949 9.5247 9.34988C9.29721 8.71027 9.18428 8.06402 9.18428 7.40946L9.18589 7.41278Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportStarCraft: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC, PropsWithChildren } from 'react'

import { TitleProps } from './title.types'
import * as S from './title.styled'

export const Title: FC<PropsWithChildren<TitleProps>> = ({
  children,
  titleSurfaceColor,
  ...props
}) => (
  <S.ContentTitleWrapper background={titleSurfaceColor} {...props}>
    <S.ContentTitle font="Title / 24 Bold">{children}</S.ContentTitle>
  </S.ContentTitleWrapper>
)

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const ProbsWrapper = styled.td`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const CellsWrapper = styled.div`
  display: flex;
  position: relative;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const BlockWrapper = styled.div<{
  isMerged?: boolean
  isHidden?: boolean
}>`
  display: flex;
  ${({ isMerged, theme, isHidden }) =>
    css`
      gap: ${isMerged ? 1 : theme.tokens.alias.space['space-xxxxs-2']}px;
      ${isHidden &&
      css`
        opacity: 0;
      `}
    `}
`

export const SuspendedBlock = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-disabled']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

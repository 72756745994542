import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M15.5802 7.98259C14.2441 6.75173 12.4599 6 10.5 6C6.35786 6 3 9.35786 3 13.5C3 17.6421 6.35786 21 10.5 21C12.0044 21 13.4054 20.5571 14.5796 19.7945C14.1749 18.6738 13.7998 17.0738 13.7998 14.9998C13.7998 12.7703 14.3154 11.4715 14.8055 10.3824C14.8361 10.3143 14.8662 10.2478 14.8957 10.1827L14.8957 10.1827C15.2454 9.41034 15.5084 8.82935 15.5802 7.98259ZM16.5923 9.12475C16.4406 9.68315 16.2227 10.163 15.9966 10.6611C15.7677 11.1651 15.5304 11.6876 15.3448 12.329C15.5304 11.6877 15.7677 11.1652 15.9965 10.6613L15.9965 10.6613L15.9965 10.6613C16.2227 10.1632 16.4406 9.68323 16.5923 9.12475ZM7.89697 13.5625C8.03991 14.096 7.72333 14.6443 7.18986 14.7873C6.6564 14.9302 6.10806 14.6136 5.96512 14.0802C5.82218 13.5467 6.13876 12.9984 6.67222 12.8554C7.20569 12.7125 7.75403 13.0291 7.89697 13.5625ZM8.30607 11.2228C8.83954 11.0799 9.15612 10.5316 9.01318 9.99809C8.87024 9.46463 8.3219 9.14804 7.78844 9.29099C7.25497 9.43393 6.93839 9.98226 7.08133 10.5157C7.22427 11.0492 7.77261 11.3658 8.30607 11.2228ZM11.7615 12.5274C11.9044 13.0609 11.5878 13.6092 11.0544 13.7521C10.5209 13.8951 9.97256 13.5785 9.82962 13.045C9.68668 12.5116 10.0033 11.9632 10.5367 11.8203C11.0702 11.6773 11.6185 11.9939 11.7615 12.5274Z"
        fill={theme.tokens.colors['sport-icon']['sport-bowling']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.2423 6.80254H16.7572C16.7834 7.05683 16.7998 7.29194 16.7998 7.5C16.7998 7.78861 16.7831 8.05408 16.753 8.30176H19.2458C19.2161 8.05408 19.1998 7.78861 19.1998 7.5C19.1998 7.29194 19.2161 7.05683 19.2423 6.80254ZM17.9997 1C20.0188 1 19.6944 3.50091 19.3985 5.60254H16.601C16.3051 3.50091 15.9806 1 17.9997 1ZM16.4768 9.50176H19.5179C19.6552 9.91113 19.8241 10.2867 19.9981 10.6736C20.4719 11.7269 20.9837 12.8648 20.9997 15C21.0297 19 19.4997 21 19.4997 21H16.4998C16.4998 21 14.9998 19 14.9998 15C14.9998 12.8564 15.5167 11.718 15.9965 10.6613L15.9965 10.6613C16.1702 10.2787 16.3391 9.90676 16.4768 9.50176Z"
        fill={theme.tokens.colors['sport-icon']['sport-bowling']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        clipRule="evenodd"
        d="M10.8641 5.58607C9.79523 4.60139 8.36781 4 6.79993 4C3.48622 4 0.799927 6.68629 0.799927 10C0.799927 13.3137 3.48622 16 6.79993 16C8.00346 16 9.12423 15.6456 10.0636 15.0356C9.73981 14.139 9.43977 12.859 9.43977 11.1998C9.43977 9.41627 9.85224 8.37719 10.2443 7.50589L10.3165 7.34615C10.5962 6.72827 10.8067 6.26348 10.8641 5.58607ZM4.7175 10.05C4.83186 10.4768 4.57859 10.9155 4.15182 11.0298C3.72504 11.1442 3.28637 10.8909 3.17202 10.4641C3.05767 10.0374 3.31093 9.5987 3.73771 9.48435C4.16448 9.37 4.60315 9.62326 4.7175 10.05ZM5.04479 8.17827C5.47156 8.06392 5.72482 7.62525 5.61047 7.19847C5.49612 6.7717 5.05745 6.51844 4.63068 6.63279C4.2039 6.74714 3.95064 7.18581 4.06499 7.61258C4.17934 8.03936 4.61801 8.29262 5.04479 8.17827ZM7.8091 9.22191C7.92346 9.64868 7.67019 10.0874 7.24342 10.2017C6.81665 10.3161 6.37798 10.0628 6.26362 9.63602C6.14927 9.20925 6.40253 8.77058 6.82931 8.65623C7.25608 8.54187 7.69475 8.79514 7.8091 9.22191Z"
        fill={theme.tokens.colors['sport-icon']['sport-bowling']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.7938 4.64203H11.8057C11.8267 4.84546 11.8397 5.03355 11.8397 5.2C11.8397 5.43089 11.8264 5.64327 11.8023 5.84141H13.7965C13.7728 5.64327 13.7597 5.43089 13.7597 5.2C13.7597 5.03355 13.7728 4.84546 13.7938 4.64203ZM12.7997 0C14.4149 0 14.1554 2.00073 13.9187 3.68203H11.6807C11.444 2.00073 11.1844 0 12.7997 0ZM11.5814 6.80141H14.0142C14.1241 7.12891 14.2592 7.42934 14.3984 7.73892C14.7774 8.5815 15.1869 9.49182 15.1997 11.2C15.2237 14.4 13.9997 16 13.9997 16H11.5997C11.5997 16 10.3997 14.4 10.3997 11.2C10.3997 9.48512 10.8133 8.57437 11.1971 7.72901C11.3361 7.42293 11.4712 7.12541 11.5814 6.80141Z"
        fill={theme.tokens.colors['sport-icon']['sport-bowling']}
        fillRule="evenodd"
      />
    </>
  )
}

export const IconSportBowling: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

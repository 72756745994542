import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconSetting: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.25073 6.30395C5.85273 6.25547 5.46426 6.44939 5.26379 6.79662L4.1251 8.76889C3.92463 9.11611 3.95093 9.5495 4.19191 9.86993L5.342 11.3992C5.60972 11.7552 5.60972 12.2453 5.342 12.6013L4.19151 14.1311C3.95053 14.4515 3.92423 14.8849 4.1247 15.2322L5.26313 17.204C5.4636 17.5512 5.85208 17.7451 6.25007 17.6966L8.15017 17.4652C8.59231 17.4113 9.01678 17.6564 9.19121 18.0662L9.94141 19.8289C10.0984 20.1978 10.4606 20.4373 10.8615 20.4373L13.1372 20.4373C13.5381 20.4373 13.9003 20.1978 14.0573 19.8289L14.8075 18.0662C14.982 17.6564 15.4064 17.4113 15.8486 17.4652L17.751 17.6969C18.149 17.7454 18.5375 17.5515 18.738 17.2043L19.8755 15.2341C20.076 14.8868 20.0497 14.4534 19.8087 14.133L18.6567 12.6013C18.389 12.2453 18.389 11.7552 18.6567 11.3992L19.8083 9.86803C20.0493 9.54759 20.0756 9.1142 19.8751 8.76698L18.7373 6.79633C18.5369 6.4491 18.1484 6.25519 17.7504 6.30367L15.8486 6.53533C15.4064 6.58918 14.982 6.34412 14.8075 5.93428L14.0579 4.17284C13.9008 3.80392 13.5387 3.56445 13.1377 3.56445H10.861C10.4601 3.56445 10.0979 3.80392 9.94089 4.17284L9.19121 5.93428C9.01678 6.34412 8.59231 6.58918 8.15017 6.53533L6.25073 6.30395ZM11.0007 9.00033C9.8961 9.00033 9.00067 9.89576 9.00067 11.0003V13.0003C9.00067 14.1049 9.8961 15.0003 11.0007 15.0003H13.0007C14.1052 15.0003 15.0007 14.1049 15.0007 13.0003V11.0003C15.0007 9.89576 14.1052 9.00033 13.0007 9.00033H11.0007Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

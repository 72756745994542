import { HTMLAttributes } from 'react'

import { TComponentColorProps, ThemeContext } from 'shared/lib/theme'

export type TCustomSize = { width: number; height: number }

export interface IconProps {
  size?: number | TCustomSize // px
  twist?: number // rotate using deg
  colorProps?: TComponentColorProps
  onClick?: HTMLAttributes<HTMLSpanElement | HTMLButtonElement>['onClick']
  isMonochrome?: boolean
  testId?: string
  colorToken?: NestedKeyOf<ThemeContext['tokens']['colors']['icon']>
}

export type TIconPropsAdditionalCssProps = {
  withHover?: boolean
  withActive?: boolean
  $isActiveState?: boolean
}

export interface IIconPropsButton {
  shape: EIconButtonShape
  size: EIconButtonSize
  withBackground: boolean
  isActiveState: boolean
}

export type IconWithAdditionalCssProps = IconProps &
  TIconPropsAdditionalCssProps

export enum EIconButtonSize {
  M = '24px',
  L = '32px'
}

export enum EIconButtonShape {
  Circle = 'circle',
  Default = 'default'
}

export interface IStyledIconButtonProps extends IIconPropsButton {}

import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { Tooltip } from 'shared/ui/Tooltip'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'

import {
  StyledBetCardCouponWrapper,
  StyledBetCardIcon,
  StyledEventCouponBottom,
  StyledEventCouponChildrenWrapper,
  StyledEventCouponCoefficientName,
  StyledEventCouponCoefficientValue,
  StyledEventCouponContent,
  StyledEventCouponError,
  StyledEventCouponHeader,
  StyledEventCouponName,
  StyledEventCouponTeams,
  StyledEventCouponTitle
} from './card.styled'
import { CardEventStatus, CardProps } from './card.types'

export const Card: FC<CardProps> = memo(
  ({
    CoefficientCoupon,
    outcomeName,
    teamsName,
    tournamentName,
    sportCode,
    eventStatus,
    Input,
    onDeleteOutcome,
    onMouseLeave,
    onMouseEnter,
    isTooltipCommonTeamsName,
    isTooltipCommonTournamentName,
    isTooltipCommonOutcomeName,
    isCardHover,
    tournamentNameRef,
    teamsNameRef,
    outcomeNameRef
  }) => {
    const [t] = useTranslation()

    return (
      <StyledBetCardCouponWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledEventCouponContent
          isEventEnded={eventStatus === CardEventStatus.ENDED}
        >
          <StyledEventCouponHeader>
            <StyledEventCouponTitle>
              <SportIcon size="s" sport={sportCode as ESportsCodes} />

              <Tooltip
                isCommon={isTooltipCommonTournamentName}
                title={tournamentName}
              >
                <StyledEventCouponName ref={tournamentNameRef}>
                  {tournamentName}
                </StyledEventCouponName>
              </Tooltip>
            </StyledEventCouponTitle>

            {(isCardHover || eventStatus === CardEventStatus.ENDED) && (
              <StyledBetCardIcon onClick={onDeleteOutcome}>
                <IconCloseOutline16 colorToken="icon-secondary-3" />
              </StyledBetCardIcon>
            )}
          </StyledEventCouponHeader>

          <Tooltip isCommon={isTooltipCommonTeamsName} title={teamsName}>
            <StyledEventCouponTeams ref={teamsNameRef}>
              {teamsName}
            </StyledEventCouponTeams>
          </Tooltip>

          <Tooltip isCommon={isTooltipCommonOutcomeName} title={outcomeName}>
            <StyledEventCouponBottom>
              <StyledEventCouponCoefficientName ref={outcomeNameRef}>
                {outcomeName}
              </StyledEventCouponCoefficientName>

              <StyledEventCouponCoefficientValue>
                {CoefficientCoupon || <div>-</div>}
              </StyledEventCouponCoefficientValue>
            </StyledEventCouponBottom>
          </Tooltip>
        </StyledEventCouponContent>

        {eventStatus === CardEventStatus.ENDED ||
        eventStatus === CardEventStatus.CANCELLED ? (
          <StyledEventCouponChildrenWrapper>
            <StyledEventCouponError>
              {eventStatus === CardEventStatus.ENDED && t('event is over')}
              {eventStatus === CardEventStatus.CANCELLED && t('event canceled')}
            </StyledEventCouponError>
          </StyledEventCouponChildrenWrapper>
        ) : (
          <>
            {(Input || eventStatus === CardEventStatus.PENDING) && (
              <StyledEventCouponChildrenWrapper>
                {Input}

                {eventStatus === CardEventStatus.PENDING && (
                  <StyledEventCouponError>
                    {t('event suspended')}
                  </StyledEventCouponError>
                )}
              </StyledEventCouponChildrenWrapper>
            )}
          </>
        )}
      </StyledBetCardCouponWrapper>
    )
  }
)

import styled, { css } from 'styled-components'

export const StyledEmptyPageFilterWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    padding: ${theme.tokens.alias.space['space-xxl-32']}px 0;
  `}
`

export const StyledEmptyPageTextsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  white-space: break-spaces;
`

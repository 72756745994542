import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { StyledIcon } from 'shared/ui/Icon'

export const Tabs = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    column-gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
    display: flex;
    flex-direction: row;
    height: 3.5rem;
    max-height: 3.5rem;
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;

    ${Tab} {
      width: calc(100% / 3);
    }
  `}
`

export const Tab = styled.div<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    align-items: center;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3px;
    text-transform: uppercase;
    ${typography['Hint / 10 BOLD']}
    transition: bacground-color 150ms;

    ${$isActive
      ? css`
          background-color: ${theme.tokens.colors.surface['surface-base']};

          &:not(${StyledIcon}) {
            color: ${theme.tokens.colors.text['text-primary']};
          }
        `
      : css`
          background-color: transparent;

          &:not(${StyledIcon}) {
            color: ${theme.tokens.colors.text['text-secondary-3']};
          }

          &:hover {
            background-color: ${theme.tokens.colors.surface['surface-hover']};
          }
        `}

    &:active {
      background-color: ${theme.tokens.colors.surface['surface-base']};
    }
  `}
`

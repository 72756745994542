import styled, { css } from 'styled-components'

import { TabsDeprecated, TabsItem } from 'shared/ui/TabsDeprecated'

export const StyledTabs = styled(TabsDeprecated)`
  margin-bottom: 24px;
`

export const StyledTabsItem = styled(TabsItem)`
  border-radius: 8px;
  height: 32px;
  ${(props) =>
    !props.active &&
    css`
      background-color: ${props.theme.colors.default.primary[5]};
      border-color: transparent;
      color: ${props.theme.colors.default.primary[90]};
    `}

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  &:hover {
    ${(props) =>
      props.active
        ? ''
        : ` background-color: ${props.theme.colors.default.primary[10]};`}
  }
`

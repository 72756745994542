import { FC } from 'react'
import { BetStatus } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { BetHistoryTagWrapper } from 'features/bet-history-card/bet-history-card.styled'

import { BetStatusText } from './bet-history-card.constants'

export const BetHistoryTag: FC<{ status: BetStatus; className?: string }> = ({
  status,
  className
}) => {
  const { t } = useTranslation()

  return (
    <BetHistoryTagWrapper className={className} status={status}>
      {t(BetStatusText[status])}
    </BetHistoryTagWrapper>
  )
}

import { ScrollableBase } from './scrollable.styled'
import { ScrollableProps } from './scrollable.types'

export const Scrollable = ({
  children,
  className,
  onScrollCallback = () => {}
}: ScrollableProps) => {
  return (
    <ScrollableBase
      className={className}
      onScroll={(e) => {
        onScrollCallback(e.target as HTMLDivElement)
      }}
    >
      <>{children}</>
    </ScrollableBase>
  )
}

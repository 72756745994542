import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconClearSearch: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 14.6673C4.3168 14.6673 1.33203 11.6825 1.33203 8.00065C1.33203 4.31875 4.3168 1.33398 7.9987 1.33398C11.6806 1.33398 14.6654 4.31875 14.6654 8.00065C14.6654 11.6825 11.6806 14.6673 7.9987 14.6673ZM7.9987 7.05785L6.11308 5.17222L5.17027 6.11503L7.0559 8.00065L5.17027 9.88625L6.11308 10.8291L7.9987 8.94345L9.8843 10.8291L10.8271 9.88625L8.9415 8.00065L10.8271 6.11503L9.8843 5.17222L7.9987 7.05785Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)

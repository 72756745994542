import { selectDuplicateEvents } from 'astra-core/containers/BasketProvider'
import { useMemo } from 'react'
import { BasketOutcome } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'

import { useAppSelector } from 'shared/lib/@reduxjs'

export const useEvents = (outcomes) => {
  const duplicateEventsId = useAppSelector(selectDuplicateEvents, isEqual)

  const { events, dependentEvents } = useMemo(() => {
    const duplicateEventsSet = new Set(duplicateEventsId)

    const dependentEvents: { eventId: number; outcomes: BasketOutcome[] }[] = [
      ...duplicateEventsSet
    ].map((id) => {
      return { eventId: id, outcomes: [] }
    })

    const events: BasketOutcome[] = []

    outcomes.forEach((outcome) => {
      const eventId = outcome.event.id

      if (duplicateEventsSet.has(eventId)) {
        dependentEvents
          .find((item) => item.eventId === eventId)
          ?.outcomes.push(outcome)
      } else {
        events.push(outcome)
      }
    })

    return { events, dependentEvents }
  }, [duplicateEventsId, outcomes])

  return { events, dependentEvents }
}

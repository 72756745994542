import { FC } from 'react'
import { Event } from 'betweb-openapi-axios'

import { useToggleDisplayTableOutcomes } from 'entities/event-row'

import { Probs } from '../probs'

import * as S from './coefficients.styled'

export const Coefficients: FC<{
  event: Event
  onToggleExpand: (isDisplay: boolean) => void
}> = ({ event, onToggleExpand }) => {
  const {
    isDisplay: isDisplayTableOutcomes,
    setIsDisplay: setIsDisplayTableOutcomes,
    isDisabledToggleDisplay: isDisabledToggleDisplayTableOutcomes
  } = useToggleDisplayTableOutcomes({
    eventStatus: event.status,
    outcomesCount: event.outcomesCount
  })

  return (
    <Probs
      renderRight={
        <S.ExpandButton
          $isExpanded={isDisplayTableOutcomes}
          disabled={isDisabledToggleDisplayTableOutcomes}
          onClick={() => {
            setIsDisplayTableOutcomes((prev) => {
              const newState = !prev

              onToggleExpand(newState)
              return newState
            })
          }}
        >
          <span>{event.outcomesCount}</span>

          <S.Chevron isOpened={isDisplayTableOutcomes} />
        </S.ExpandButton>
      }
      event={event}
    />
  )
}

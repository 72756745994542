/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'

import { InjectedReducersType } from 'shared/lib/@reduxjs'
import { baseLegacyApi, basePromoAPi } from 'entities/legacy-api/lib/api'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return combineReducers({
      [baseLegacyApi.reducerPath]: baseLegacyApi.reducer,
      [basePromoAPi.reducerPath]: basePromoAPi.reducer
    })
  } else {
    // @ts-ignore
    return combineReducers({
      ...injectedReducers,
      [baseLegacyApi.reducerPath]: baseLegacyApi.reducer,
      [basePromoAPi.reducerPath]: basePromoAPi.reducer
      //  Statically injected reducers should be placed here
    })
  }
}

import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { Typography } from 'shared/ui/typography'

import * as S from './message-nothing-found.styled'

export const MessageNothingFound: FC<{
  onClickResetButton: () => void
  className?: string
}> = ({ onClickResetButton, className }) => {
  const { t } = useTranslation()

  return (
    <S.MessageNothingFound className={className}>
      <Typography font="Body / 16 SemiBold">
        {t('search has not given any results')}
      </Typography>

      <Typography
        dangerouslySetInnerHTML={{
          __html: t('try other keywords')
        }}
        color="text-secondary-3"
        font="Body / 14 Medium"
      />

      <S.ButtonReset view="secondary-large" onClick={onClickResetButton}>
        {t('reset')}
      </S.ButtonReset>
    </S.MessageNothingFound>
  )
}

import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { selectSports } from 'astra-core/containers/CommonDataProvider'
import { useGroupedTournaments } from 'astra-core/hooks/useEvents'
import {
  getSelectedPeriodOptionValue,
  useLineFilterPeriodTimesComparison
} from 'astra-core'
import isEqual from 'react-fast-compare'

import { useEventsPoll } from 'entities/event/api'
import { RootState } from 'shared/types/store'
import {
  getScheduleTime,
  OPTIONS_SELECT_PERIOD
} from 'entities/line-tournaments/ui/select-filter-period/constants'
import { LineParams } from 'entities/line/model/types'
import {
  getFetchLineReqData,
  LINE_POLLING_INTERVAL
} from 'entities/line/model/utils'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  getFilterPeriod,
  selectEventsLayoutIsLoading,
  selectFilterIsTop,
  selectFilterSportId,
  selectLayoutIsLoading,
  selectLineTournaments
} from './selectors'
import {
  getFetchLineTournamentsReqData,
  LINE_TOURNAMENTS_POLLING_INTERVAL
} from './utils'
import {
  LineTournamentsParams,
  UseTournamentsGroupedBySubSportProps
} from './types'
import { lineTournamentsContainerActions } from './slice'

export const useLineContainerData = () => {
  const dispatch = useAppDispatch()
  const { tournamentId } = useParams<LineParams>()
  const isLayoutLoading = useAppSelector(selectEventsLayoutIsLoading)
  const top = useAppSelector(selectFilterIsTop)
  const filterPeriod = useAppSelector(getFilterPeriod, isEqual)
  const periodOptions = OPTIONS_SELECT_PERIOD()

  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(periodOptions, filterPeriod),
    [filterPeriod, periodOptions]
  )

  const setFilterPeriod = useCallback(() => {
    if (selectedPeriodValue) {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(selectedPeriodValue)
        })
      )
    }
  }, [dispatch, selectedPeriodValue])

  const fetchLine = useCallback(
    ({ tournamentId, tagIds }) => {
      if (tournamentId) {
        dispatch(
          lineTournamentsContainerActions.fetchLine(
            getFetchLineReqData({
              tournamentId,
              tagIds
            })
          )
        )
      }
    },
    [dispatch]
  )

  /* Update Line if the current time does not match the filter */
  useLineFilterPeriodTimesComparison({
    filterPeriod,
    callback: setFilterPeriod,
    selectedPeriodValue
  })

  useEventsPoll({
    isLoading: isLayoutLoading,
    tournamentId: +tournamentId,
    fetch: fetchLine,
    top,
    pollingInterval: LINE_POLLING_INTERVAL
  })
}

export const useLineTournamentsContainerData = () => {
  const dispatch = useAppDispatch()
  const { sportId } = useParams<LineTournamentsParams>()
  const top = useAppSelector(selectFilterIsTop)
  const isLayoutLoading = useAppSelector(selectLayoutIsLoading)
  const filterPeriod = useAppSelector(getFilterPeriod, isEqual)
  const periodOptions = OPTIONS_SELECT_PERIOD()

  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(periodOptions, filterPeriod),
    [filterPeriod, periodOptions]
  )

  const setFilterPeriod = useCallback(() => {
    if (selectedPeriodValue) {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(selectedPeriodValue)
        })
      )
    }
  }, [dispatch, selectedPeriodValue])

  const fetchLineTournaments = useCallback(
    ({ sportId }) => {
      if (sportId) {
        dispatch(
          lineTournamentsContainerActions.fetchLineTournaments(
            getFetchLineTournamentsReqData({ sportId })
          )
        )
      }
    },
    [dispatch]
  )

  /* Update Line if the current time does not match the filter */
  useLineFilterPeriodTimesComparison({
    filterPeriod,
    callback: setFilterPeriod,
    selectedPeriodValue
  })

  useEventsPoll({
    isLoading: isLayoutLoading,
    sportId: +sportId,
    fetch: fetchLineTournaments,
    top,
    pollingInterval: LINE_TOURNAMENTS_POLLING_INTERVAL
  })
}

export const useTournamentsGroupedBySubSport = ({
  sportId
}: UseTournamentsGroupedBySubSportProps) => {
  const allTournaments = useAppSelector(
    (state: RootState) => selectLineTournaments(state, sportId),
    isEqual // TODO: selector waits sportId as string
  )

  const sportIdFilter = useAppSelector(selectFilterSportId)
  const sports = useAppSelector(selectSports)
  const allGroupedTournaments = useGroupedTournaments(allTournaments, sports)

  const isGenericSport = useMemo(() => {
    return !!(
      allGroupedTournaments.length &&
      allGroupedTournaments.every((t) => t.genericSportId === sportId)
    )
  }, [allGroupedTournaments, sportId])

  let groupedTournaments = allGroupedTournaments

  if (isGenericSport && sportIdFilter) {
    groupedTournaments = groupedTournaments.filter(
      ({ id: sportId }) => sportId === sportIdFilter
    )
  }

  return { isGenericSport, allGroupedTournaments, groupedTournaments }
}

import { FC } from 'react'

import {
  EventsPageHeader,
  EventsPageHeaderTypes
} from 'widgets/events-page-header'
import { LineTournamentsContent } from 'widgets/line-tournaments'

import { LineTournamentsWrapper } from './line-tournaments.styled'

export const LineTournaments: FC = () => {
  return (
    <LineTournamentsWrapper>
      <EventsPageHeader type={EventsPageHeaderTypes.LineTournaments} />
      <LineTournamentsContent />
    </LineTournamentsWrapper>
  )
}

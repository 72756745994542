import styled, { css } from 'styled-components'

export const StyledWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    border-radius: ${tokens.alias['border-radius']['border-radius-m-8']}px;
  `}
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  max-height: 100%;
  overflow: hidden;
  position: relative;
`

export const StyledSportsMenu = styled.div`
  display: grid;
  grid-area: sports-menu;
  ${({ theme: { tokens } }) => css`
    box-shadow: inset -2px 0px 0px -1px
      ${tokens.colors.border['border-primary']};
    gap: ${tokens.alias.space['space-xs-8']}px;
    padding: ${tokens.alias.space['space-xs-8']}px;
  `};
`

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const SearchItemsBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px 0;

    &:last-of-type {
      padding-bottom: ${theme.tokens.alias.space['space-xs-8']}px;
    }
  `}
`

export const SearchItemBlockTitle = styled.div`
  ${typography['Hint / 10 BOLD']};

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const SearchItemsList = styled.div`
  overflow: auto;
`

export const AllResultsBottom = styled.div`
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  left: 0;
  position: sticky;
  right: 0;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-modal']};
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const SearchValueBlock = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const HotkeyBlock = styled.div`
  display: flex;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
    border: 1px solid ${theme.tokens.colors.border['border-primary']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const EmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  > * {
    text-align: center;
  }
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxl-32']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M8.83333 15.9583H15.1667C15.1667 17.7073 16.5845 19.125 18.3333 19.125C20.0822 19.125 21.5 17.7073 21.5 15.9583V8.83333C21.5 6.64717 19.7278 4.875 17.5417 4.875H6.45833C4.27217 4.875 2.5 6.64717 2.5 8.83333V15.9583C2.5 17.7073 3.91779 19.125 5.66667 19.125C7.41554 19.125 8.83333 17.7073 8.83333 15.9583ZM18.0694 8.96543C18.0694 9.47553 17.6559 9.88904 17.1458 9.88904C16.6357 9.88904 16.2222 9.47553 16.2222 8.96543C16.2222 8.45534 16.6357 8.04182 17.1458 8.04182C17.6559 8.04182 18.0694 8.45534 18.0694 8.96543ZM16.2222 10.8127C16.2222 11.3228 15.8087 11.7363 15.2986 11.7363C14.7885 11.7363 14.375 11.3228 14.375 10.8127C14.375 10.3026 14.7885 9.88904 15.2986 9.88904C15.8087 9.88904 16.2222 10.3026 16.2222 10.8127ZM17.1458 13.5835C17.6559 13.5835 18.0694 13.17 18.0694 12.6599C18.0694 12.1498 17.6559 11.7363 17.1458 11.7363C16.6357 11.7363 16.2222 12.1498 16.2222 12.6599C16.2222 13.17 16.6357 13.5835 17.1458 13.5835ZM19.9167 10.8127C19.9167 11.3228 19.5032 11.7363 18.9931 11.7363C18.483 11.7363 18.0694 11.3228 18.0694 10.8127C18.0694 10.3026 18.483 9.88904 18.9931 9.88904C19.5032 9.88904 19.9167 10.3026 19.9167 10.8127ZM7.64591 8.83349C7.64591 8.39626 7.29147 8.04182 6.85424 8.04182C6.41702 8.04182 6.06258 8.39626 6.06258 8.83349V10.021H4.87508C4.43785 10.021 4.08341 10.3754 4.08341 10.8127C4.08341 11.2499 4.43785 11.6043 4.87508 11.6043H6.06258V12.7918C6.06258 13.229 6.41702 13.5835 6.85424 13.5835C7.29147 13.5835 7.64591 13.229 7.64591 12.7918V11.6043H8.83341C9.27064 11.6043 9.62508 11.2499 9.62508 10.8127C9.62508 10.3754 9.27064 10.021 8.83341 10.021H7.64591V8.83349Z"
      fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M10.6667 11.3333H5.33333C5.33333 12.8062 4.1394 14 2.66667 14C1.19393 14 0 12.8062 0 11.3333V5.33333C0 3.49235 1.49235 2 3.33333 2H12.6667C14.5077 2 16 3.49235 16 5.33333V11.3333C16 12.8062 14.8061 14 13.3333 14C11.8606 14 10.6667 12.8062 10.6667 11.3333ZM4.25 8C5.07843 8 5.75 7.32843 5.75 6.5C5.75 5.67157 5.07843 5 4.25 5C3.42157 5 2.75 5.67157 2.75 6.5C2.75 7.32843 3.42157 8 4.25 8ZM11.75 8.00008C12.5784 8.00008 13.25 7.32851 13.25 6.50008C13.25 5.67166 12.5784 5.00008 11.75 5.00008C10.9216 5.00008 10.25 5.67166 10.25 6.50008C10.25 7.32851 10.9216 8.00008 11.75 8.00008Z"
      fill={theme.tokens.colors['sport-icon']['sport-cybersport']}
      fillRule="evenodd"
    />
  )
}

export const IconSportCybersport: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

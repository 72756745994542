import styled, { css } from 'styled-components'

export const GlobalSearchLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px 0;
  `}
`

export const GlobalSearchTitleLoader = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const GlobalSearchItemLoader = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const GlobalSearchItemInfoLoader = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
  `}
`

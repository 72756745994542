import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'
import { typography } from 'shared/lib/theme/typography'
import { StyledIcon } from 'shared/ui/Icon'
import { StyledBasketTitleItemWrapperProps } from 'widgets/basket/ui/header/header.types'

export const StyledBasketTitleWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-default-opacity']};
    box-shadow: inset 1px -1px ${theme.tokens.colors.border['border-hover']};
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    padding-right: ${theme.tokens.alias.space['space-m-16']}px;
    position: relative;
  `}
`

export const StyledBasketTitleItemWrapper = styled.div<StyledBasketTitleItemWrapperProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  ${({ theme, active }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: 0 ${theme.tokens.alias.space['space-l-20']}px;

    ${active &&
    css`
      box-shadow: inset 0px -2px 0px 0px ${theme.tokens.colors.border['border-active']};

      ${BasketTitleItemTitle} {
        color: ${theme.tokens.colors.text['text-primary']};
      }
    `}
  `};
`

export const StyledBasketToolbar = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${theme.tokens.alias.space['space-s-12']}px;
    justify-content: space-between;
  `}
`

export const BasketTitleTabs = styled.div`
  display: flex;
  height: 100%;
`

export const BasketTitleItemTitle = styled.span<StyledBasketTitleItemWrapperProps>`
  ${typography['Body / 12 BOLD']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
  `}
`

export const BasketTitleItemCounter = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.surface['surface-counter']};
  `}

  & ${StyledIcon} {
    height: 20px;
    width: 4px;
  }
`

export const BasketTitleItemNum = styled.div`
  ${typography['Body / 12 Bold']}
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-counter']};
    color: ${theme.tokens.colors.text['text-primary']};
    display: flex;
    height: 20px;
    padding: 0px ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledCloseButtonWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  justify-self: end;
  width: 16px;
  z-index: 111111;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-secondary-2']};
  `}

  ${customMedia.small} {
    display: flex;
  }
`

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledOpenModalWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-3']};
    }
  `}
`

export const StyledModalTextWrapper = styled.div`
  -ms-overflow-style: none;
  height: 546px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  overflow: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ theme }) => css`
    .rcs-custom-scroll .rcs-inner-handle {
      background-color: ${theme.tokens.colors.surface[
        'surface-active-4'
      ]} !important;
      border-radius: ${theme.tokens.alias.space['space-m-16']}px;
      width: ${theme.tokens.alias.space['space-xxs-6']}px;
    }
  `}
`

export const StyledBonusModalText = styled.div`
  ${typography['Body / 14 Medium']}

  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: 0 ${theme.tokens.alias.space['space-xxl-32']}px;

    & a {
      color: ${theme.tokens.colors.text['text-linkAccent-default']};
      text-decoration: none;
    }
  `}

  & ol > li {
    list-style-position: inside;
    list-style-type: auto;

    & > ul > li {
      list-style-type: disc;
      padding-left: 2em;
    }
  }
`

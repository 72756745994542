import { useCallback } from 'react'
import { activeEventStatus } from 'astra-core/containers/BasketProvider/constants'
import { basketProviderActions } from 'astra-core/containers/BasketProvider'
import { Event, EventProbability } from 'betweb-openapi-axios'

import { useAppDispatch } from 'shared/lib/@reduxjs'

export const useHandleAddCoefficientToBasket = ({
  eventId,
  eventStatus,
  eventProbability
}: {
  eventId: Event['id']
  eventStatus: Event['status']
  eventProbability: EventProbability | undefined
}) => {
  const dispatch = useAppDispatch()

  return useCallback(() => {
    if (eventProbability && activeEventStatus.includes(eventStatus)) {
      dispatch(
        basketProviderActions.addOutcomeToBasket({
          eventProbability,
          eventId
        })
      )
    }
  }, [eventProbability, dispatch, eventId, eventStatus])
}

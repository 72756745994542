import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  useFeatureFlag,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'

import { Button } from 'shared/ui/Button'
import {
  getFilterIsBroadcast,
  selectIsExistBroadcastForCurrentEvents
} from 'entities/live/model/selectors'
import { liveContainerActions } from 'entities/live/model/slice'
import { RootState } from 'shared/types/store'
import { LiveEventsParams } from 'entities/live/model/types'
import { IconVideoFilled } from 'shared/ui/Icon/General/IconVideoFilled'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const LiveBroadcastFilter = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isBroadcast = useAppSelector(getFilterIsBroadcast)

  const isFeatureFlagBroadcastEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )

  const { sportId } = useParams<LiveEventsParams>()

  const isBroadcastFilterButtonEnabled = useAppSelector((state: RootState) =>
    selectIsExistBroadcastForCurrentEvents(state, { sportId })
  )

  const toggleFilterBroadcast = useCallback(
    () => dispatch(liveContainerActions.toggleFilterIsBroadcast()),
    [dispatch]
  )

  return (
    <>
      {isFeatureFlagBroadcastEnabled && (
        <Button
          disabled={!isBroadcastFilterButtonEnabled}
          icon={IconVideoFilled}
          isActive={isBroadcast}
          view="filter"
          onClick={toggleFilterBroadcast}
        >
          {t('with broadcast')}
        </Button>
      )}
    </>
  )
}

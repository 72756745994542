import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/Button'

import { IEmptyPageFilterProps } from './empty-page-filter.types'
import {
  StyledEmptyPageFilterWrapper,
  StyledEmptyPageTextsWrapper
} from './empty-page-filter.styled'

export const EmptyPageFilter: FC<IEmptyPageFilterProps> = ({
  onButtonClickResetFilter,
  isSearch,
  isLive
}) => {
  const [t] = useTranslation()

  return (
    <StyledEmptyPageFilterWrapper>
      <StyledEmptyPageTextsWrapper>
        <Typography font="Body / 16 SemiBold">
          {t(isSearch ? 'no matching events' : 'nothing found')}
        </Typography>
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {t(
            isSearch
              ? isLive
                ? 'try to change query'
                : 'try to change query or search in another tournament'
              : 'try selecting other filters'
          )}
        </Typography>
      </StyledEmptyPageTextsWrapper>
      <Button view="secondary-large" onClick={onButtonClickResetFilter}>
        {t('reset filter')}
      </Button>
    </StyledEmptyPageFilterWrapper>
  )
}

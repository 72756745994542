import styled, { css } from 'styled-components'

import {
  ButtonDeprecated,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/ButtonDeprecated'

import { getPositionData } from './utils'

export const StyledOnboardingContent = styled.div<{
  positionCoords?: ReturnType<typeof getPositionData>
}>`
  ${(props) => css`
    ${props.positionCoords
      ? css`
          ${props.positionCoords};
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
    background-color: ${props.theme.colors.default.primary[90]};
    border-radius: 4px;
    padding: 16px;
    position: absolute;
    width: 240px;
  `}
`

export const StyledOnboardingContentHeader = styled.h3`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[3]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
    margin-bottom: 10px;
  `}
`

export const StyledOnboardingContentText = styled.p`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[9]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.regular};
    line-height: 16px;
    margin-bottom: 10px;
  `}
`

export const StyledOnboardingContentButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledOnboardingContentButtonNext = styled(ButtonDeprecated).attrs(
  {
    view: EButtonViews.SECONDARY,
    size: EButtonSizes.S
  }
)``

export const StyledOnboardingContentButtonSkip = styled(ButtonDeprecated).attrs(
  {
    view: EButtonViews.SECONDARY,
    size: EButtonSizes.S
  }
)`
  background: transparent;

  &:not(:hover) {
    color: ${(props) => props.theme.colors.custom.primary[17]};
  }
`

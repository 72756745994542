import { EBetTypes, EMakeBetStatus } from 'astra-core/containers/BasketProvider'
import React, { ReactElement } from 'react'
import { BasketOutcome } from 'betweb-openapi-axios'

import {
  BasketStatusError,
  BasketStatusSuccess,
  BasketStatusWait
} from 'entities/basket'

import { Ordinar } from '../../ui/ordinar'
import { Express } from '../../ui/express'
import { System } from '../../ui/system'

export const BET_TYPE_CONTENT_MAP: Record<
  EBetTypes,
  (outcomes: BasketOutcome[]) => ReactElement
> = {
  [EBetTypes.Ordinar]: (outcomes) => <Ordinar outcomes={outcomes} />,
  [EBetTypes.Express]: (outcomes) => <Express outcomes={outcomes} />,
  [EBetTypes.System]: (outcomes) => <System outcomes={outcomes} />
}

export const BET_STATUS_MAP: Partial<Record<EMakeBetStatus, ReactElement>> = {
  [EMakeBetStatus.loading]: <BasketStatusWait />,
  [EMakeBetStatus.success]: <BasketStatusSuccess />,
  [EMakeBetStatus.error]: <BasketStatusError />
}

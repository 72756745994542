/* --- STATE --- */
import {
  Event,
  EventList,
  EventsApiGetEventsRequest,
  Sport,
  TournamentList,
  TournamentsApiGetTournamentsRequest
} from 'betweb-openapi-axios'
import { ReactNode } from 'react'

export interface LineTournamentsContainerState {
  fetchItems: RequestState
  fetchEventsItems: RequestState
  tournamentsIdsBySport: Dictionary<Event['id'][]>
  eventsIdsByTournament: Dictionary<Event['id'][]>
  filters: {
    [Filters.IS_TOP]: boolean
    [Filters.SPORT_ID]?: Sport['id']
    [Filters.PERIOD]: {
      id: string
      applicationTime: string
      scheduledFrom: string
      scheduledTo: string
    }
    [Filters.SEARCH]: string
    [Filters.SEARCH_EVENTS]: string
  }
  layouts: {
    isLoading: boolean
    isEventsLoading: boolean
  }
}

export type ContainerState = LineTournamentsContainerState

export enum Filters {
  IS_TOP = 'isTop',
  SPORT_ID = 'sportId',
  PERIOD = 'period',
  SEARCH = 'search',
  SEARCH_EVENTS = 'eventsSearch'
}

export type FetchLineTournamentsPayload = TournamentsApiGetTournamentsRequest
export type FetchLineTournamentsSuccessPayload = {
  resData: TournamentList
  reqData: {
    sportId?: number
  }
}

export interface LineTournamentsParams {
  sportId: string
}

export interface LineTournamentsProps {
  children: ReactNode
}

export type SetFilterPeriodPayload = Partial<LineTournamentFilterPeriod>

export type LineTournamentFilterPeriod = Pick<
  EventsApiGetEventsRequest,
  'scheduledFrom' | 'scheduledTo'
> & { id: string; applicationTime: string }

export type FetchLinePayload = EventsApiGetEventsRequest
export type FetchLineSuccessPayload = {
  resData: EventList
  reqData: {
    tournamentId?: number
  }
}

export type TFormatLineTournamentsFiltersParams =
  LineTournamentsContainerState['filters']

export type UseTournamentsGroupedBySubSportProps = {
  sportId: Sport['id']
}

export type SetFilterSportIdPayload = { sportId?: Sport['id'] }

export interface Payloads {
  resetLineFilter: { excludedFilters: Array<Filters> } | undefined
}

import { Alert } from 'shared/ui/alert'

import { ValueWarningWrapper } from './value-warning.styled'

export const ValueWarning = () => {
  return (
    <ValueWarningWrapper>
      <Alert>Введите 3 или более буквы для поиска</Alert>
    </ValueWarningWrapper>
  )
}

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconInfoCircleFilled: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.92243 13.4095C5.30275 13.7898 5.80317 14 6.33361 14H9.64637C10.1768 14 10.6872 13.7898 11.0575 13.4095L13.4095 11.0676C13.7898 10.6872 14 10.1868 14 9.65638V6.34362C14 5.81318 13.7898 5.30275 13.4095 4.93244L11.0676 2.59049C10.6872 2.21018 10.1868 2 9.65638 2L6.34362 2C5.81318 2 5.30275 2.21017 4.93244 2.59049L2.59049 4.93244C2.21018 5.31276 2 5.81318 2 6.34362L2 9.65638C2 10.1868 2.21017 10.6972 2.59049 11.0676L4.92243 13.4095ZM9 5C9 5.55228 8.55229 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5ZM9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V8C7 7.44771 7.44772 7 8 7C8.55229 7 9 7.44772 9 8V11Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

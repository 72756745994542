import styled from 'styled-components'

export const StyledBasketBonusWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  padding: 8px;
`

export const StyledGapRow = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`

import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { StyledTooltip } from 'shared/ui/Tooltip/Tooltip.styled'

export const StyledWinSum = styled.div<{ isCouponApplied: boolean }>`
  ${typography['Body / 12 Bold']}
  ${({ isCouponApplied }) => css`
    align-items: flex-end;
    color: ${isCouponApplied ? '#7845E7' : '#5cd65c'};
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    white-space: nowrap;
  `}
`

export const StyledWinSumContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  ${StyledTooltip} {
    cursor: default;
  }
`

export const StyledMaxSum = styled.div`
  ${typography['Body / 12 SemiBold']}
`

export const StyledCouponSum = styled.span`
  ${typography['Body / 12 Bold']}
`

export const StyledIconWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 4px;
`

export const StyledAlertWraper = styled.div`
  padding-top: 6px;
`

import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCheckCircleFilled16: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M11.9441 3C12.563 3 13.1468 3.2452 13.5905 3.68891L16.3111 6.42118C16.7548 6.85321 17 7.44871 17 8.06756V11.9324C17 12.5513 16.7548 13.1351 16.3111 13.5788L13.5788 16.3111C13.1468 16.7548 12.5513 17 11.9324 17H8.06756C7.44871 17 6.86489 16.7548 6.42118 16.3111L3.68891 13.5788C3.2452 13.1468 3 12.5513 3 11.9324L3 8.06756C3 7.44871 3.2452 6.86489 3.68891 6.42118L6.43286 3.68891C6.86489 3.2452 7.46038 3 8.07923 3L11.9441 3ZM13.6588 9.74956C14.0743 9.38575 14.1162 8.75398 13.7524 8.33845C13.3886 7.92293 12.7568 7.88101 12.3413 8.24482L9.53282 10.7038L7.61411 9.21078C7.17824 8.87162 6.54995 8.95001 6.21078 9.38589C5.87162 9.82176 5.95001 10.4501 6.38589 10.7892L8.82591 12.6879C9.27985 13.0411 9.92058 13.0225 10.3533 12.6437L13.6588 9.74956Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React from 'react'

import { StyledFooterBottomMenuListLinkA } from 'entities/footer-of-page'
import { IconPhoneFilled } from 'shared/ui/Icon/General/icon-phone-filled'

export const FooterOfPagePhoneItems = () => (
  <StyledFooterBottomMenuListLinkA href="tel:7757">
    <IconPhoneFilled />
    7757
  </StyledFooterBottomMenuListLinkA>
)

import styled, { css } from 'styled-components'

export const EventRow = styled.tr<{
  $isDisplayOutcomes: boolean
  $isDarkMode: boolean
  entityTagColor: string
  bgImageUrl: string
}>`
  ${({
    theme,
    $isDisplayOutcomes,
    entityTagColor,
    bgImageUrl,
    $isDarkMode
  }) => css`
    ${entityTagColor &&
    css`
      & > td:first-child {
        position: relative;
      }

      & > td:first-child::before {
        content: '';
        width: 400px;
        left: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(
            90deg,
            ${entityTagColor}1F 0%,
            rgba(13, 128, 242, 0) 100%
          ),
          url(${bgImageUrl});
        background-repeat: no-repeat;
        background-size: 400px auto, contain;
        position: absolute;
        pointer-events: none;
      }

      &:hover {
        & > td:first-child::before {
          background-image: linear-gradient(
              90deg,
              ${entityTagColor}26 0%,
              rgba(13, 128, 242, 0) 100%
            ),
            url(${bgImageUrl});
        }
      }
    `}

    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px 0 ${theme.tokens.colors.divider['divider-primary']};
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-hover-light']};
    }

    ${$isDisplayOutcomes &&
    css`
      box-shadow: ${$isDarkMode
        ? '0px -4px 8px 2px #00000029, 0px -2px 4px 0px #0000001F'
        : '0px -4px 8px 2px #6c79931f, 0px -2px 4px 0px #6c799314'};
    `}
  `}
`

export const OutcomesContainer = styled.tr`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-transparent']};
  `}
`

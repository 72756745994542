import React, { FC, PropsWithChildren } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { Auth } from 'widgets/auth'
import { Header } from 'widgets/header'
import { useIframeLocation } from 'app/lib/use-iframe-messages'
import { LayoutPrimary } from 'layouts/primary'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { StyledAppLayout, StyledHeaderWrapper } from './app-layout.styled'

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const config = useAppSelector(selectConfig)
  useIframeLocation()

  return (
    <StyledAppLayout>
      <LayoutPrimary>
        {!config.IS_IFRAME && (
          <>
            <Auth />
            <StyledHeaderWrapper>
              <Header />
            </StyledHeaderWrapper>
          </>
        )}
        {children}
      </LayoutPrimary>
    </StyledAppLayout>
  )
}

import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SegmentedControl } from 'shared/ui/segmented-control'
import { GlobalSearchTabs } from 'features/global-search/model/global-search-tabs'

interface Props {
  value: GlobalSearchTabs
  onChange: (value: GlobalSearchTabs) => void
}

export const GlobalSearchSegmentedControl: FC<Props> = (props) => {
  const { t } = useTranslation()

  const items = useMemo(
    () => [
      {
        label: t('all'),
        value: GlobalSearchTabs.All
      },
      {
        label: t('events'),
        value: GlobalSearchTabs.Events
      },
      {
        label: t('tournaments'),
        value: GlobalSearchTabs.Tournaments
      }
    ],
    [t]
  )

  return (
    <SegmentedControl
      items={items}
      {...props}
      view="secondary"
      equalItems
      fullWidth
    />
  )
}

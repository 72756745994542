import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatAmountLocaleString, useGetCurrencyIcon } from 'astra-core'

import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'
import { TOASTIFY_CASHOUT_ID } from 'widgets/bets-history-legacy/components/Cashout/components/ToastifyCashoutNotification/WithdrawalFormMethods.types'
import { IconWarningTriangleFilled16 } from 'shared/ui/Icon/General/IconWarningTriangleFilled16'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { getItemLocalStorage } from 'shared/lib/@system'
import { MAKE_CASHOUT_LS } from 'widgets/bets-history-legacy/components'

export const useCashoutToast = () => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()

  const cashoutData = getItemLocalStorage(MAKE_CASHOUT_LS)

  const cashoutText = useMemo(
    () =>
      cashoutData?.betId && cashoutData?.amount
        ? t('betid sold for amount', {
            betId: cashoutData.betId,
            betAmount: `${formatAmountLocaleString({
              value: cashoutData?.amount,
              currency: currencyIcon
            })}`
          })
        : t('bet sold'),
    [cashoutData?.amount, cashoutData?.betId, currencyIcon, t]
  )

  const showSuccessToast = usePopUpNotification({
    content: {
      leftContent: <IconCheckCircleFilled colorToken="icon-positive" />,
      children: cashoutText
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      toastId: TOASTIFY_CASHOUT_ID
    }
  })

  const showErrorToast = usePopUpNotification({
    content: {
      leftContent: <IconWarningTriangleFilled16 colorToken="icon-warning" />,
      children: t('failed to sell bet')
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      toastId: TOASTIFY_CASHOUT_ID
    }
  })

  return { showSuccessToast, showErrorToast }
}

import styled from 'styled-components'

export const StyledTabsWrapper = styled.div`
  padding: 0 8px;
`

export const LiveTab = styled.div`
  display: flex;
  gap: 6px;
`

import React, { FC } from 'react'

import { IconProps } from 'shared/ui/Icon/types'
import { Icon } from 'shared/ui/Icon/Icon'

export const IconPaymentSim: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 11 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M0.166504 13.3592C0.166504 13.7642 0.350624 14.1473 0.666911 14.4003L1.46795 15.0412C1.70437 15.2303 1.99812 15.3333 2.30088 15.3333H6.28089C6.63451 15.3333 6.97365 15.1929 7.2237 14.9428L10.4426 11.7239C10.6927 11.4738 10.8332 11.1347 10.8332 10.7811V2.55229C10.8332 2.19867 10.6927 1.85953 10.4426 1.60948L9.89036 1.0572C9.64031 0.807148 9.30117 0.666672 8.94755 0.666672H2.30088C1.99812 0.666672 1.70437 0.769713 1.46795 0.958847L0.666911 1.59968C0.350624 1.85271 0.166504 2.23579 0.166504 2.64084V13.3592ZM2.83317 2.66667C2.46498 2.66667 2.1665 2.96515 2.1665 3.33334V7.33334C2.1665 7.70153 2.46498 8.00001 2.83317 8.00001H3.49984C3.86803 8.00001 4.1665 7.70153 4.1665 7.33334V3.33334C4.1665 2.96515 3.86803 2.66667 3.49984 2.66667H2.83317ZM5.49984 2.66667C5.13165 2.66667 4.83317 2.96515 4.83317 3.33334V4.00001C4.83317 4.3682 5.13165 4.66667 5.49984 4.66667H8.1665C8.53469 4.66667 8.83317 4.3682 8.83317 4.00001V3.33334C8.83317 2.96515 8.53469 2.66667 8.1665 2.66667H5.49984ZM4.83317 6.00001C4.83317 5.63182 5.13165 5.33334 5.49984 5.33334H8.1665C8.53469 5.33334 8.83317 5.63182 8.83317 6.00001V7.33334C8.83317 7.70153 8.53469 8.00001 8.1665 8.00001H5.49984C5.13165 8.00001 4.83317 7.70153 4.83317 7.33334V6.00001Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

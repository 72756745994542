/**
 * Create the store with dynamic reducers
 */

import { configureStore, StoreEnhancer } from '@reduxjs/toolkit'
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'

import { RootState } from 'shared/types/store'
import { baseLegacyApi, basePromoAPi } from 'entities/legacy-api/lib/api'

import { createReducer } from './reducers'

type ConfigureAppStoreParams = {
  preloadedState?: RootState
}

export function configureAppStore({ preloadedState }: ConfigureAppStoreParams) {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middlewares = [
    sagaMiddleware,
    baseLegacyApi.middleware,
    basePromoAPi.middleware
  ] as any

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga
    }) as StoreEnhancer
  ]

  const store = configureStore({
    reducer: () => createReducer(),
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        middlewares
      )
    },
    devTools: process.env.NODE_ENV !== 'production' && {
      trace: true,
      traceLimit: 25
    },
    enhancers(getDefaultEnhancers) {
      return getDefaultEnhancers().concat(enhancers)
    },
    preloadedState: preloadedState ?? undefined
  })

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store)
    })
  }

  return store
}

export const serverStore = configureAppStore({})

declare global {
  type AppDispatch = typeof serverStore.dispatch
}

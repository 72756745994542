import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconDependentOutcomes: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0008 2C9.76606 1.99924 9.54079 2.09209 9.37487 2.258L5.28849 6.24947C5.12258 6.41538 5.02972 6.64066 5.03049 6.87541L5.03376 7.87357C5.03452 8.10661 5.12749 8.32995 5.29235 8.49481L6.81933 10.0218C6.88824 10.0907 6.99997 10.0907 7.06888 10.0218L7.81101 9.27962C7.87992 9.21071 7.87992 9.09898 7.81101 9.03007L6.48444 7.7035C6.43499 7.65404 6.4071 7.58704 6.40687 7.51713L6.40596 7.23882C6.40573 7.1684 6.43358 7.10081 6.48336 7.05104L10.1764 3.45287C10.2262 3.40309 10.2938 3.37524 10.3642 3.37547L10.6425 3.37638C10.7124 3.37661 10.7794 3.4045 10.8289 3.45396L12.5467 5.17171C12.5961 5.22118 12.624 5.28821 12.6242 5.35814L12.6251 5.63633C12.6253 5.70673 12.5974 5.77429 12.5477 5.82405L11.8468 6.52684C11.5857 6.80285 11.5905 7.23854 11.8611 7.50916C12.1317 7.77978 12.5674 7.7846 12.8434 7.52346L13.7421 6.62511C13.908 6.45925 14.0009 6.23405 14.0001 5.99938L13.9971 5.0016C13.9964 4.7685 13.9034 4.54507 13.7385 4.38016L11.6202 2.26186C11.4554 2.097 11.232 2.00403 10.999 2.00327L10.0008 2Z"
        fill="#E83030"
      />
      <path
        d="M5.99934 14C6.23409 14.0008 6.45937 13.9079 6.62528 13.742L10.7211 9.75053C10.8871 9.58462 10.9799 9.35934 10.9791 9.12459L10.9759 8.12643C10.9751 7.89339 10.8821 7.67005 10.7173 7.50519L9.19031 5.97824C9.1214 5.90933 9.00967 5.90933 8.94076 5.97825L8.19862 6.72038C8.12971 6.78929 8.12971 6.90102 8.19862 6.96993L9.52519 8.2965C9.57465 8.34596 9.60254 8.41296 9.60277 8.48287L9.60368 8.76118C9.60391 8.8316 9.57605 8.89919 9.52628 8.94896L5.82371 12.5471C5.77394 12.5969 5.70635 12.6248 5.63593 12.6245L5.35762 12.6236C5.28771 12.6234 5.22071 12.5955 5.17125 12.546L3.4535 10.8283C3.40403 10.7788 3.37614 10.7118 3.37592 10.6419L3.37508 10.3637C3.37486 10.2933 3.40272 10.2257 3.45248 10.1759L4.14376 9.48275C4.40489 9.20674 4.40007 8.77105 4.12945 8.50043C3.85636 8.22733 3.41008 8.23156 3.13532 8.49757C2.86056 8.76357 2.258 9.37488 2.258 9.37488C2.09214 9.54075 1.99929 9.76594 2 10.0006L2.00304 10.9984C2.00375 11.2315 2.09673 11.4549 2.26163 11.6198L4.37994 13.7381C4.54479 13.903 4.76814 13.996 5.00118 13.9967L5.99934 14Z"
        fill="#E83030"
      />
    </svg>
  </Icon>
)

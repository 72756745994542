import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { StyledIconSvgFromServer } from 'shared/ui/Icon/IconSvgFromServer.styled'
import { typography } from 'shared/lib/theme/typography'

export const StyledFooter = styled.footer`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 1px 0 0 ${theme.tokens.colors.border['border-header']};
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    height: fit-content;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledFooterRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${StyledIconSvgFromServer} {
    margin-right: auto;
  }
`

export const StyledFooterContacts = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
  `}

  & > * {
    cursor: pointer;
  }
`

export const StyledFooterNav = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
  `}

  & > * {
    cursor: pointer;
  }
`

export const StyledFooterSocials = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}

  & > * {
    cursor: pointer;
  }

  & a {
    font-size: 0;
  }
`

export const StyledFooterDivider = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.divider['divider-secondary']};
    height: 1px;
    width: 100%;
  `}
`

export const StyledFooterLicence = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-grow: 1;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledFooterLicenceText = styled.div`
  color: ${({ theme }) => theme.tokens.colors.text['text-description']};
  ${typography['Body / 12 Medium']};
  max-width: 431px;
  white-space: pre-wrap;
`

export const StyledFooterTopHelpSupport = styled.div`
  align-items: center;
  display: flex;

  & > :first-child {
    margin-right: 10px;
  }
`

export const StyledFooterBottomSecurity = styled.div``

export const StyledFooterBottomMenuListLinkA = styled.a`
  ${typography['Body / 14 Medium']};
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;

    &:hover {
      color: ${theme.tokens.colors.text['text-primary-2']};
    }
  `}
`

export const StyledLicenceLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-linkAccent-default']};
    &:hover {
      color: ${theme.tokens.colors.text['text-linkAccent-hover']};
    }
  `}
`

export const StyledFooterBottomMenuListLink = styled(NavLink)`
  ${typography['Body / 14 Medium']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};

    &:hover {
      color: ${theme.tokens.colors.text['text-primary-2']};
    }
  `}
`

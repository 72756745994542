import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M11.9169 20.7986L4.44208 16.1823V5.65228H9.57637L12.1111 3.11751L14.9721 5.58753L19.4589 5.65228V16.7026L11.9169 20.7986Z"
        fill="#12A1E7"
      />
      <path
        d="M13.5356 8.05276L15.8881 10.6307L16.1735 8.82014L18.7035 11.3477L19.3246 15.8225L15.3533 19.1223L13.1615 20.1487L12.8857 20.3453L8.37253 15.6882L8.14711 14.777L10.0224 13.3933L13.6963 10.6091L13.5356 8.05276Z"
        fill="url(#paint0_linear_2276_11517)"
      />
      <path
        d="M15.6939 10.5228L14.255 10.0192L13.5356 8.05276L12.0008 7.90887L10.4661 8.05276L9.74663 10.0192L8.30778 10.5228L7.82817 8.82014L7.92409 12.0096C7.92409 12.0096 8.2838 11.2902 12.0008 11.2662C15.7179 11.2662 16.0776 12.0096 16.0776 12.0096L16.1735 8.82014L15.6939 10.5228Z"
        fill="#FFCE37"
      />
      <path
        d="M12.0728 11.6739H11.9049C8.49963 11.6978 8.02001 12.5132 8.02001 12.5132C8.02001 12.5132 7.75623 15.7026 8.45167 15.7026C8.45167 15.7026 8.2838 15.6787 8.2838 15.4388C8.2838 15.199 9.02721 14.7674 11.9768 14.7674C14.9265 14.7674 15.6699 15.199 15.6699 15.4388C15.6699 15.6547 15.502 15.7026 15.502 15.7026C16.1975 15.7026 15.9337 12.5132 15.9337 12.5132C15.9337 12.5132 15.478 11.6978 12.0728 11.6739ZM12.0008 14.3597L10.9217 13.3046L12.0008 12.2254L13.08 13.3046L12.0008 14.3597Z"
        fill="#FFCE37"
      />
      <path
        d="M12.0008 22L3.60754 16.964V4.58993H8.97925L12.0968 2L15.2143 4.58993H20.3941V16.964L12.0008 22ZM5.2862 15.9329L12.0008 19.9137L18.7155 15.9568V6.26858H14.5668L12.0968 4.23022L9.62673 6.26858H5.2862V15.9329Z"
        fill="#FFCE37"
      />
      <path
        d="M12.0012 12.1942L10.8923 13.3032L12.0012 14.4122L13.1102 13.3032L12.0012 12.1942Z"
        fill="#FFCE37"
      />
      <path
        d="M12.0021 13.7732L12.4718 13.3035L12.0021 12.8338L11.5324 13.3035L12.0021 13.7732Z"
        fill="#FFC412"
      />
      <path
        d="M12.0012 12.1942L12.0008 12.8321L11.5308 13.3046H10.8929L12.0012 12.1942Z"
        fill="#FFD948"
      />
      <path
        d="M13.1087 13.3046H12.4709L12.0008 12.8321L12.0012 12.1942L13.1087 13.3046Z"
        fill="#FFB60B"
      />
      <path
        d="M12.0008 13.7746L12.4709 13.3046H13.1087L12.0012 14.4122L12.0008 13.7746Z"
        fill="#FF930A"
      />
      <path
        d="M10.8929 13.3046H11.5308L12.0008 13.7746L12.0012 14.4122L10.8929 13.3046Z"
        fill="#FFA40A"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2276_11517"
          x1="10.1226"
          x2="16.4845"
          y1="8.66407"
          y2="16.3143"
        >
          <stop stopColor="#126AE7" />
          <stop offset="1" stopColor="#12A1E7" />
        </linearGradient>
      </defs>
    </>
  ),
  s: () => (
    <>
      <path
        d="M7.93365 15.0388L1.95387 11.3458V2.92191H6.06125L8.08905 0.894116L10.3777 2.87011L13.9671 2.92191V11.7621L7.93365 15.0388Z"
        fill="#12A1E7"
      />
      <path
        d="M9.2286 4.84227L11.1106 6.90459L11.3389 5.45617L13.3628 7.4782L13.8597 11.058L10.6828 13.6978L8.92932 14.5189L8.7087 14.6762L5.0982 10.9506L4.91786 10.2216L6.41808 9.11463L9.35714 6.88733L9.2286 4.84227Z"
        fill="url(#paint0_linear_2276_11518)"
      />
      <path
        d="M10.9552 6.81826L9.80413 6.41539L9.2286 4.84227L8.0008 4.72716L6.773 4.84227L6.19746 6.41539L5.0464 6.81826L4.66271 5.45617L4.73945 8.0077C4.73945 8.0077 5.02722 7.43216 8.0008 7.41298C10.9744 7.41298 11.2621 8.0077 11.2621 8.0077L11.3389 5.45617L10.9552 6.81826Z"
        fill="#FFCE37"
      />
      <path
        d="M8.05835 7.73911H7.92406C5.19987 7.7583 4.81619 8.41057 4.81619 8.41057C4.81619 8.41057 4.60516 10.9621 5.16151 10.9621C5.16151 10.9621 5.02722 10.9429 5.02722 10.7511C5.02722 10.5592 5.62193 10.2139 7.98161 10.2139C10.3413 10.2139 10.936 10.5592 10.936 10.7511C10.936 10.9237 10.8017 10.9621 10.8017 10.9621C11.3581 10.9621 11.147 8.41057 11.147 8.41057C11.147 8.41057 10.7825 7.7583 8.05835 7.73911ZM8.0008 9.88777L7.1375 9.04365L8.0008 8.18035L8.8641 9.04365L8.0008 9.88777Z"
        fill="#FFCE37"
      />
      <path
        d="M8.0008 15.9999L1.28625 11.9712V2.07204H5.58356L8.07754 0.00012207L10.5715 2.07204H14.7153V11.9712L8.0008 15.9999ZM2.62916 11.1463L8.0008 14.3309L13.3724 11.1654V3.41495H10.0535L8.07754 1.78427L6.10154 3.41495H2.62916V11.1463Z"
        fill="#FFCE37"
      />
      <path
        d="M8.00113 8.15538L7.11396 9.04255L8.00113 9.92973L8.88831 9.04255L8.00113 8.15538Z"
        fill="#FFCE37"
      />
      <path
        d="M8.00182 9.41859L8.37758 9.04283L8.00182 8.66707L7.62606 9.04283L8.00182 9.41859Z"
        fill="#FFC412"
      />
      <path
        d="M8.0008 8.15541V8.66572L7.62478 9.04365H7.11448L8.0008 8.15541Z"
        fill="#FFD948"
      />
      <path
        d="M8.88712 9.04365H8.37681L8.0008 8.66572V8.15541L8.88712 9.04365Z"
        fill="#FFB60B"
      />
      <path
        d="M8.0008 9.41967L8.37681 9.04365H8.88712L8.0008 9.92997V9.41967Z"
        fill="#FF930A"
      />
      <path
        d="M7.11448 9.04365H7.62478L8.0008 9.41967V9.92997L7.11448 9.04365Z"
        fill="#FFA40A"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2276_11518"
          x1="6.49827"
          x2="11.5877"
          y1="5.33131"
          y2="11.4514"
        >
          <stop stopColor="#126AE7" />
          <stop offset="1" stopColor="#12A1E7" />
        </linearGradient>
      </defs>
    </>
  )
}

const iconsSizeMapMono: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M15.6948 10.5228L14.2559 10.0192L13.5365 8.05276L12.0017 7.90887L10.4669 8.05276L9.74751 10.0192L8.30865 10.5228L7.82903 8.82014L7.92496 12.0096C7.92496 12.0096 8.28467 11.2902 12.0017 11.2662C15.7187 11.2662 16.0785 12.0096 16.0785 12.0096L16.1744 8.82014L15.6948 10.5228Z"
        fill="currentColor"
      />
      <path
        d="M12.0737 11.6739H11.9058C8.5005 11.6978 8.02088 12.5132 8.02088 12.5132C8.02088 12.5132 7.75709 15.7026 8.45254 15.7026C8.45254 15.7026 8.28467 15.6787 8.28467 15.4388C8.28467 15.199 9.02808 14.7674 11.9777 14.7674C14.9274 14.7674 15.6708 15.199 15.6708 15.4388C15.6708 15.6547 15.5029 15.7026 15.5029 15.7026C16.1984 15.7026 15.9346 12.5132 15.9346 12.5132C15.9346 12.5132 15.4789 11.6978 12.0737 11.6739ZM12.0017 14.3597L10.9226 13.3046L12.0017 12.2254L13.0808 13.3046L12.0017 14.3597Z"
        fill="currentColor"
      />
      <path
        d="M12.0017 22L3.6084 16.964V4.58993H8.98012L12.0976 2L15.2151 4.58993H20.395V16.964L12.0017 22ZM5.28706 15.9329L12.0017 19.9137L18.7164 15.9568V6.26859H14.5677L12.0976 4.23022L9.6276 6.26859H5.28706V15.9329Z"
        fill="currentColor"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M10.9561 6.8172L9.80506 6.41432L9.22952 4.84119L8.00171 4.72609L6.77389 4.84119L6.19836 6.41432L5.04728 6.8172L4.66359 5.4551L4.74033 8.00664C4.74033 8.00664 5.0281 7.4311 8.00171 7.41192C10.9753 7.41192 11.2631 8.00664 11.2631 8.00664L11.3398 5.4551L10.9561 6.8172Z"
        fill="currentColor"
      />
      <path
        d="M8.05926 7.73805H7.92497C5.20076 7.75724 4.81707 8.40951 4.81707 8.40951C4.81707 8.40951 4.60604 10.9611 5.16239 10.9611C5.16239 10.9611 5.0281 10.9419 5.0281 10.75C5.0281 10.5582 5.62282 10.2129 7.98252 10.2129C10.3422 10.2129 10.9369 10.5582 10.9369 10.75C10.9369 10.9227 10.8027 10.9611 10.8027 10.9611C11.359 10.9611 11.148 8.40951 11.148 8.40951C11.148 8.40951 10.7835 7.75724 8.05926 7.73805ZM8.00171 9.88672L7.1384 9.0426L8.00171 8.1793L8.86501 9.0426L8.00171 9.88672Z"
        fill="currentColor"
      />
      <path
        d="M8.00171 15.9989L1.28711 11.9702V2.07095H5.58445L8.07845 -0.000976562L10.5724 2.07095H14.7163V11.9702L8.00171 15.9989ZM2.63003 11.1452L8.00171 14.3298L13.3734 11.1644V3.41387H10.0545L8.07845 1.78318L6.10244 3.41387H2.63003V11.1452Z"
        fill="currentColor"
      />
    </>
  )
}

export const IconCybersportClashRoyale: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {props.isMonochrome
        ? ({ theme }) =>
            React.createElement(iconsSizeMapMono[props.size], { theme })
        : ({ theme }) =>
            React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

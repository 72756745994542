import React, { FC, useMemo } from 'react'

import { invertArrayObject } from 'shared/lib/object'

import { IconProps } from '../Icon'
import { LoaderPointsMini } from '../LoaderPointsMini'

import {
  ButtonGeneralView,
  ButtonProps,
  ButtonSize,
  ButtonView
} from './Button.types'
import * as S from './Button.styled'

const smallSizeIconButtons: ButtonView[] = ['filter', 'outline']

const invertedSizes: Record<ButtonSize, ButtonView[]> = {
  m: ['primary-medium', 'secondary-medium'],
  l: ['primary-large', 'secondary-large', 'filter', 'ghost', 'outline'],
  xl: ['primary-extra-large', 'secondary-extra-large']
}

const invertedGeneralViews: Record<ButtonGeneralView, ButtonView[]> = {
  primary: ['primary-medium', 'primary-large', 'primary-extra-large'],
  secondary: ['secondary-medium', 'secondary-large', 'secondary-extra-large'],
  filter: ['filter'],
  ghost: ['ghost'],
  outline: ['outline']
}

const viewWithIcon: ButtonView[] = ['filter', 'ghost', 'outline']

const sizes = invertArrayObject(invertedSizes)
const generalViews = invertArrayObject(invertedGeneralViews)

// TODO: Discard props of StyledButton and replace all type of Button to "FC<ButtonProps>"
export const Button: FC<ButtonProps> = ({
  view = 'primary-large',
  isLoading,
  icon: Icon = null,
  children,
  ...props
}) => {
  const iconProps: IconProps = useMemo(() => {
    if (smallSizeIconButtons.includes(view)) {
      return {
        size: 16
      }
    }
    return {
      size: 24
    }
  }, [view])

  const internalSize = sizes[view]
  const internalView = generalViews[view]
  const showIcon = viewWithIcon.includes(view)

  return (
    <S.StyledButton
      internalSize={internalSize}
      internalView={internalView}
      view={view}
      withContent={!!children}
      withIcon={!!Icon && showIcon}
      {...props}
    >
      {isLoading && (
        <S.StyledLoadingOverlay
          internalSize={internalSize}
          internalView={internalView}
          view={view}
          withContent={!!children}
          withIcon={!!Icon && showIcon}
        >
          <LoaderPointsMini />
        </S.StyledLoadingOverlay>
      )}
      {showIcon && Icon && <Icon {...iconProps} />}
      {children}
    </S.StyledButton>
  )
}

import styled, { css } from 'styled-components'
import { ColumnType } from 'astrabet-templates-kit'

import {
  StyledTableCellProps,
  StyledHeaderCellProps
} from 'widgets/outcomes-grid/odds-table/odds-table.types'
import { typography } from 'shared/lib/theme/typography'

interface OddsTableProps {
  columns: ColumnType[]
  isPlain?: boolean
  labelInButton?: boolean
  isFirst?: boolean
  isLast?: boolean
}

const columnTypeToWidth: Partial<Record<ColumnType, '1fr' | 'min-content'>> = {
  label: '1fr',
  odd: 'min-content'
}

export const StyledTableWrapper = styled.div<{
  roundTop?: boolean
  roundBottom?: boolean
}>`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  ${({ theme, roundTop, roundBottom }) => css`
    ${roundTop &&
    css`
      border-top-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-l-12'
      ]}px;
      border-top-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-l-12'
      ]}px;
    `}

    ${roundBottom &&
    css`
      border-bottom-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-l-12'
      ]}px;
      border-bottom-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-l-12'
      ]}px;
    `}
  `}
`

export const StyledOddsTableHeader = styled.div<OddsTableProps>`
  display: grid;

  ${({ columns, theme }) =>
    css`
      background-color: ${theme.tokens.colors.surface['surface-base']};
      gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
      grid-template-columns: ${columns
        .map((c, index) => (index === 0 ? '1fr' : '55px'))
        .join(' ')};
      padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
    `}
`
export const StyledOddsTable = styled.div<OddsTableProps>`
  ${({ isPlain, labelInButton, columns, theme }) =>
    !isPlain &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-base']};
      display: grid;
      ${labelInButton
        ? css`
            gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
            grid-template-columns: ${columns
              .map((c) => (c === 'odd' ? '1fr' : ''))
              .join(' ')};
            padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
              ${theme.tokens.alias.space['space-xs-8']}px
              ${theme.tokens.alias.space['space-xs-8']}px;
          `
        : css`
            grid-template-columns: ${columns
              .map((c) => columnTypeToWidth[c])
              .join(' ')};
          `}
    `}
`

export const CoefficientTableCellStyled = styled.div<StyledTableCellProps>`
  align-items: center;
  display: flex;
  overflow: hidden;
  transition: background-color 0.5s ease;

  ${({ isPlain, hideBorder, labelInButton, isOdd, isLastInRow, theme }) =>
    css`
      ${!isPlain &&
      css`
        ${hideBorder &&
        css`
          border-bottom: none;
        `}
        ${labelInButton &&
        css`
          border: none;
        `}
      `}

      ${isOdd &&
      css`
        justify-content: center;
      `}
      
      ${!labelInButton &&
      css`
        box-shadow: inset 0 1px ${theme.tokens.colors.border['border-primary']};
        padding: ${theme.tokens.alias.space['space-xxs-6']}px 0;
        ${isOdd &&
        css`
          padding: ${theme.tokens.alias.space['space-xxs-6']}px
            ${theme.tokens.alias.space['space-xxxxs-2']}px;
          ${isLastInRow &&
          css`
            padding-right: ${theme.tokens.alias.space['space-xs-8']}px;
          `}
        `}
      `}
    `}
`

export const StyledTextCell = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledPlainHeaderCell = styled.div`
  ${typography['Body / 12 SemiBold']};
  padding: 0;
`

export const StyledHeaderCell = styled.div<StyledHeaderCellProps>`
  ${({ isFirstInRow, theme, columns }) => css`
    ${typography['Body / 12 ExtraBold']};
    color: ${theme.tokens.colors.text['text-primary']};
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
    ${columns &&
    css`
      grid-column: 1 / ${columns + 1};
    `}
    ${!isFirstInRow &&
    css`
      ${typography['Body / 12 SemiBold']};
      color: ${theme.tokens.colors.text['text-secondary-2']};
      display: flex;
      justify-content: center;
    `}
    > span {
      background: #ffbf0040;
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-xs-4'
      ]}px;
      margin: 0 -${theme.tokens.alias.space['space-xxxxs-2']}px;
      padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
    }
  `}
`

export const StyledTablePlainRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`

export const StyledPlainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`

export const StyledOddsDivider = styled.div`
  height: ${({ theme }) => theme.tokens.alias.space['space-xs-8']}px;
`

export const StyledOddsDividerCenter = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[36]};
  height: 8px;
`

export const StyledOddsDividerTop = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 8px;
  margin-top: -8px;
`

export const StyledOddsDividerBottom = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 8px;
  margin-bottom: -8px;
`

export const MockOdd = styled.div`
  ${typography['Body / 12 SemiBold']};
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 55px;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-disabled']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    box-shadow: inset 0 0 0 1px ${theme.tokens.colors.border['border-primary']};
    color: ${theme.tokens.colors.text['text-disabled']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useRouteMatch } from 'react-router-dom'
import { EPostMessageIdEvent } from 'astra-core/containers/AuthMessageProvider'
import { useTranslation } from 'react-i18next'

import { ADVERT_INFO_COOKIE } from 'constants/cookies.constants'
import { ERoutes } from 'shared/types/routes'
import { EAdditionalPostMessageIdEvent } from 'entities/auth/model'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

import {
  StyledSuccessPage,
  StyledSuccessPageWrapper,
  GlobalStyleHideLiveChatPatch
} from './success-page.styled'

export const SuccessPages = () => {
  const { t } = useTranslation()
  const hydrated = useHydrated()

  useEffect(() => {
    Cookies.remove(ADVERT_INFO_COOKIE)
  }, [])

  const isPageSignUp = useRouteMatch([
    ERoutes.SuccessFastSignUp,
    ERoutes.SuccessFullSignUp
  ])
  const isPageLogin = useRouteMatch(ERoutes.SuccessLoginPage)

  useEffect(() => {
    if (isPageLogin && hydrated) {
      window.parent.postMessage(
        {
          event_id: EAdditionalPostMessageIdEvent.SUCCESS_PAGE
        },
        '*'
      )
    }
  }, [hydrated, isPageLogin])

  // inside the iframe calls a modal window about successful registration
  useEffect(() => {
    if (isPageSignUp && hydrated) {
      window.parent.postMessage(
        {
          event_id: EPostMessageIdEvent.pageChange,
          event_payload: { isSuccessSignUp: true }
        },
        '*'
      )
    }
  }, [hydrated, isPageSignUp])

  useEffect(() => {
    if (isPageLogin && hydrated) {
      window.parent.postMessage({
        event_id: EPostMessageIdEvent.close,
        event_payload: {
          notification: {
            type: 'SUCCESS',
            text: t('login completed')
          }
        }
      })
    }
  }, [hydrated, isPageLogin, t])

  return (
    <StyledSuccessPageWrapper>
      <GlobalStyleHideLiveChatPatch />
      <StyledSuccessPage>
        <LoaderLogo />
      </StyledSuccessPage>
    </StyledSuccessPageWrapper>
  )
}

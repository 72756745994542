import { useCallback, useMemo } from 'react'
import {
  ClientFilteredProbsMapWithOutcomeIds,
  MainOutcomeType,
  selectClientFilteredProbsMap,
  selectMainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'
import { useProbs } from 'astra-core/hooks/useEvents'
import { Event } from 'betweb-openapi-axios'

import { getMainOutcomeProbability } from 'shared/lib/outcomes'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const useGetEventProbability = ({
  event,
  outcomeTypesName
}: {
  event: Event
  outcomeTypesName: Array<MainOutcomeType>
}) => {
  const mainOutcomeTypes = useAppSelector(selectMainOutcomeTypes)
  const clientFilteredProbsMap = useAppSelector(selectClientFilteredProbsMap) // To filter Handicaps and Totals on client side to show in the events list as "main probs"

  const probs = useProbs(
    event,
    clientFilteredProbsMap as ClientFilteredProbsMapWithOutcomeIds
  )

  const getOutcomeProbability = useCallback(
    (outcomeTypeName) =>
      getMainOutcomeProbability({
        probs,
        mainOutcomeTypes,
        mainOutcomeType: outcomeTypeName
      }),
    [mainOutcomeTypes, probs]
  )

  const eventProbability = useMemo(() => {
    const matchingMainOutcomeType = outcomeTypesName.find((outcomeTypeName) =>
      getOutcomeProbability(outcomeTypeName)
    )
    return getOutcomeProbability(matchingMainOutcomeType)
  }, [getOutcomeProbability, outcomeTypesName])

  return { eventProbability }
}

import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSuccess: FC<IconProps> = (props) => {
  const theme = useTheme()

  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={
            props.colorToken
              ? 'currentColor'
              : theme.tokens.colors.icon['icon-positive']
          }
          clipRule="evenodd"
          d="M25.5475 0C27.3041 0 28.9717 0.688889 30.239 1.95556L38.0211 9.75556C39.2885 11 40 12.6889 40 14.4667V25.5111C40 27.2889 39.2885 28.9556 38.0211 30.2222L30.2168 38.0222C28.9717 39.2889 27.2818 40 25.5031 40H14.4747C12.6959 40 11.0283 39.2889 9.76098 38.0222L1.95664 30.2222C0.689272 28.9778 0 27.2889 0 25.5111V14.4889C0 12.7111 0.689272 11.0444 1.95664 9.77778L9.80545 1.95556C11.0283 0.688889 12.7404 0 14.4969 0H25.5475ZM28.9571 15.9778C29.3476 15.5873 29.3476 14.9541 28.9571 14.5636C28.5666 14.1731 27.9334 14.1731 27.5429 14.5636L18.75 23.3565L12.4571 17.0636C12.0666 16.6731 11.4334 16.6731 11.0429 17.0636C10.6524 17.4541 10.6524 18.0873 11.0429 18.4778L17.9722 25.4071C18.4018 25.8367 19.0982 25.8367 19.5278 25.4071L28.9571 15.9778Z"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}

import { PrimaryNavigation } from 'features/primary-navigation/ui/primary-navigation'

import { HeaderLogo, PersonalData } from './components'
import * as S from './header.styled'

export const Header = () => {
  return (
    <S.StyledMainHeader>
      <S.StyledMainHeaderLeft>
        <HeaderLogo />
        <PrimaryNavigation />
      </S.StyledMainHeaderLeft>

      <PersonalData />
    </S.StyledMainHeader>
  )
}

import React, { PropsWithChildren, useCallback } from 'react'
import { selectAccountBonus } from 'astra-core/containers/AccountProvider'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { ETabTypes } from 'astra-core/containers/BalanceProvider'
import { useHistory } from 'react-router-dom'
import { formatAmountLocaleString } from 'astra-core'

import { RootState } from 'shared/types/store'
import { ERoutes } from 'shared/types/routes'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { Typography } from 'shared/ui/typography'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { StyledUserAccountBonus } from './user-account-bonus.styles'

export const UserAccountBonus = ({ ...props }: PropsWithChildren) => {
  const bonus = useAppSelector(selectAccountBonus)
  const history = useHistory()

  const isBalanceHidden = useAppSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  const handleRedirectList = useCallback(
    () => history.push(`${ERoutes.ProfileBalance}/${ETabTypes.Bonus}`),
    [history]
  )

  if (isBalanceHidden) {
    return null
  }

  return (
    <StyledUserAccountBonus onClick={handleRedirectList} {...props}>
      <IconBonus />
      <Typography color="text-primary" font="Body / 12 Bold">
        {formatAmountLocaleString({
          placeholder: '...',
          value: bonus
        })}
      </Typography>
    </StyledUserAccountBonus>
  )
}

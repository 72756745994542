import { PayloadAction } from '@reduxjs/toolkit'
import { Event } from 'betweb-openapi-axios'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import { ContainerState } from './types'

const REDUCER_KEY = 'liveMatchBroadcastContainer'
export const MAX_NUMBER_OF_BROADCASTS = 4

export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  broadcastsEventIds: [],
  isShownToastifyNotification: false,
  options: {
    isMutedBroadcastList: [],
    isPlayingBroadcastList: []
  }
})

const liveMatchBroadcastSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    addBroadcast(
      state,
      { payload: { eventId } }: PayloadAction<{ eventId: number }>
    ) {
      if (state.broadcastsEventIds.length < MAX_NUMBER_OF_BROADCASTS) {
        if (!state.broadcastsEventIds.includes(eventId)) {
          state.broadcastsEventIds.push(eventId)

          isPlaying(state, eventId)

          // we does not mute first added broadcast in the list
          if (state.broadcastsEventIds.length !== 1) {
            mute(state, eventId)
          }
        }
      } else {
        state.isShownToastifyNotification = true
      }
    },
    updateBroadcastList(
      state,
      action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>
    ) {
      const { sourceIndex, destinationIndex } = action.payload

      const removed = state.broadcastsEventIds[sourceIndex]
      state.broadcastsEventIds.splice(sourceIndex, 1)
      state.broadcastsEventIds.splice(destinationIndex, 0, removed)
    },
    removeBroadcast(state, { payload }: PayloadAction<{ eventId: number }>) {
      state.broadcastsEventIds = state.broadcastsEventIds.filter(
        (broadcast) => broadcast !== payload.eventId
      )

      unmute(state, payload.eventId)
      unsetIsPlaying(state, payload.eventId)
    },
    changeToastifyNotification(
      state,
      { payload }: PayloadAction<{ isShown: boolean }>
    ) {
      state.isShownToastifyNotification = payload.isShown
    },
    setIsPlayingBroadcast(
      state,
      { payload }: PayloadAction<{ id: Event['id'] }>
    ) {
      isPlaying(state, payload.id)
    },
    unsetIsPlayingBroadcast(
      state,
      { payload }: PayloadAction<{ id: Event['id'] }>
    ) {
      unsetIsPlaying(state, payload.id)
    },
    muteBroadcast(state, { payload }: PayloadAction<{ id: Event['id'] }>) {
      mute(state, payload.id)
    },
    unmuteBroadcast(state, { payload }: PayloadAction<{ id: Event['id'] }>) {
      unmute(state, payload.id)
    }
  }
})

const mute = (state: ContainerState, eventId: Event['id']) => {
  state.options.isMutedBroadcastList.push(eventId)
}

const unmute = (state: ContainerState, eventId: Event['id']) => {
  state.options.isMutedBroadcastList =
    state.options.isMutedBroadcastList.filter((id) => id !== eventId)
}

const isPlaying = (state: ContainerState, eventId: Event['id']) => {
  state.options.isPlayingBroadcastList.push(eventId)
}

const unsetIsPlaying = (state: ContainerState, eventId: Event['id']) => {
  state.options.isPlayingBroadcastList =
    state.options.isPlayingBroadcastList.filter((id) => id !== eventId)
}

export const { actions, reducer, name: sliceKey } = liveMatchBroadcastSlice
export const liveMatchBroadcastActions = actions

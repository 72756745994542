import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCouponSetting: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.8673 0.906577C8.33056 0.586805 7.66927 0.586805 7.13254 0.906577L2.20063 3.84486C1.6639 4.16463 1.33325 4.75559 1.33325 5.39513V10.605C1.33325 11.2446 1.6639 11.8355 2.20063 12.1553L7.13254 15.0936C7.66927 15.4134 8.33056 15.4134 8.8673 15.0936L13.7992 12.1553C14.3359 11.8355 14.6666 11.2446 14.6666 10.605V5.39513C14.6666 4.75559 14.3359 4.16463 13.7992 3.84486L8.8673 0.906577ZM8 10.6669C9.47276 10.6669 10.6667 9.473 10.6667 8.00024C10.6667 6.52748 9.47276 5.33358 8 5.33358C6.52724 5.33358 5.33333 6.52748 5.33333 8.00024C5.33333 9.473 6.52724 10.6669 8 10.6669Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

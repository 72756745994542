import styled, { css } from 'styled-components'

import { AppLink } from 'shared/lib/app-link'

export const SearchTournamentRowWrapper = styled(AppLink)`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px ${theme.tokens.colors.border['border-default-gray']};
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: 10px ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const SportIconWrapper = styled.div`
  display: flex;
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

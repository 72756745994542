import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { Tab, Tabs } from 'shared/ui/tabs'
import { IPageSportGamesParams } from 'pages/page-sport-games/page.types'

import { PrimaryNavigationLink } from './primary-nagivation.styled'

export const PrimaryNavigation = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation<IPageSportGamesParams>()

  const pathLocation =
    pathname === ERoutes.BetsGames ? ERoutes.SportGamesLobby : ERoutes.BetsGames

  return (
    <Tabs>
      <PrimaryNavigationLink to={ERoutes.LineRedirect}>
        <Tab type="text">{t('line')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to={ERoutes.LiveRedirect}>
        <Tab type="text">{t('live')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to={ERoutes.Calendar}>
        <Tab type="text">{t('live schedule')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to={ERoutes.CyberSport}>
        <Tab type="text">{t('cybersport')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink
        isActive={(match, location) =>
          !!(
            match ||
            location.pathname.includes(ERoutes.SportGamesLobby) ||
            location.pathname.includes(ERoutes.BetsGames)
          )
        }
        to={pathLocation}
      >
        <Tab type="text">{t('bets 24/7')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to={ERoutes.Results}>
        <Tab type="text">{t('results')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to={ERoutes.LiveResults}>
        <Tab type="text">{t('live results')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to={ERoutes.Statistics}>
        <Tab type="text">{t('statistics')}</Tab>
      </PrimaryNavigationLink>

      <PrimaryNavigationLink to="/profile/documents?name=rules">
        <Tab type="text">{t('rules')}</Tab>
      </PrimaryNavigationLink>
    </Tabs>
  )
}

import { FC } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import {
  checkIfCurrentYear,
  EVENT_DATE_DAY_FORMAT,
  EVENT_DATE_TIME_FORMAT,
  EVENT_DATE_DAY_WITH_YEAR_FORMAT
} from 'entities/date/lib'
import { FavoriteButton } from 'features/favorite-button'
import { Typography } from 'shared/ui/typography'
import { useDateToString } from 'hooks'

import { LineContentProps } from './line-content.types'
import * as S from './line-content.styled'

export const LineContent: FC<LineContentProps> = ({ event }) => {
  const { checkDate } = useDateToString()

  const dateFormat = checkIfCurrentYear(event.eventDate)
    ? EVENT_DATE_DAY_WITH_YEAR_FORMAT
    : EVENT_DATE_DAY_FORMAT

  const additionalDate =
    checkDate(event.eventDate) || dayjs(event.eventDate).format(dateFormat)

  return (
    <S.LineContentCell colSpan={2}>
      <S.ContentCell>
        <Typography color="text-secondary-2" font="Hint / 10 SemiBold">
          {`${additionalDate}${dayjs(event.eventDate).format(
            EVENT_DATE_TIME_FORMAT
          )}`}
        </Typography>

        <FavoriteButton eventId={event.id} />
      </S.ContentCell>
    </S.LineContentCell>
  )
}

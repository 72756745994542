import { EDatePeriods } from 'astra-core'

export const PERIOD_SECTION_OPTIONS = (t) => [
  {
    value: EDatePeriods.AllTime,
    label: t('for all time')
  },
  { value: EDatePeriods.Day, label: t('today') },
  { value: EDatePeriods.Week, label: t('per week') },
  { value: EDatePeriods.Month, label: t('per month') },
  {
    value: EDatePeriods.ThreeMonths,
    label: t('per 3 months')
  }
]

import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import useMeasure from 'react-use-measure'

import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { Typography } from 'shared/ui/typography'
import { isTodayOrYesterday } from 'shared/lib/format/bets-history-date'

import { SELECTED_DATE } from './constants'
import { ICustomButtonRange } from './CustomButtonRange.types'
import {
  StyledCustomButtonRange,
  StyledCustomButtonRangeWrapper,
  StyledIconCalendar
} from './CustomButtonRange.styled'

export const CustomButtonRange: FC<ICustomButtonRange> = memo(
  ({
    onClick,
    startDate,
    endDate,
    onReset,
    isSingleDate,
    singleDateFormat,
    active,
    isVisible,
    customWidth,
    isDisabled
  }) => {
    const [t] = useTranslation()

    const dateValue = useMemo(
      () =>
        startDate && endDate
          ? `${dayjs(startDate).format(SELECTED_DATE)} - ${dayjs(
              endDate
            ).format(SELECTED_DATE)}`
          : '',
      [startDate, endDate]
    )

    const shortDateValue = useMemo(
      () =>
        startDate && endDate
          ? `${dayjs(startDate).format('DD.MM')} - ${dayjs(endDate).format(
              'DD.MM'
            )}`
          : '',
      [startDate, endDate]
    )

    const singleDate = useMemo(
      () =>
        isSingleDate &&
        startDate &&
        `${
          isTodayOrYesterday(startDate, t) ||
          dayjs(startDate).format(singleDateFormat)
        }`,
      [isSingleDate, startDate, t, singleDateFormat]
    )

    const handleOnReset = useCallback(
      (e) => {
        e.stopPropagation()
        if (onReset) {
          // @ts-ignore
          onReset([null, null], e)
        }
      },
      [onReset]
    )

    const [ref, { width }] = useMeasure()

    const typographyColor = isDisabled ? 'text-disabled' : 'text-primary-2'

    return (
      <StyledCustomButtonRange
        active={!!active}
        customWidth={customWidth}
        isDisabled={isDisabled}
        isSelected={!!(singleDate && !!dateValue)}
        onClick={onClick}
      >
        <StyledCustomButtonRangeWrapper>
          <StyledIconCalendar isDisabled={isDisabled} />

          <Typography color={typographyColor} font="Body / 12 Medium" ref={ref}>
            {singleDate ||
              (width >= 145 ? dateValue : shortDateValue) ||
              t('per period')}
          </Typography>

          {(!!dateValue || isVisible) && (
            <IconCloseOutline16 onClick={handleOnReset} />
          )}
        </StyledCustomButtonRangeWrapper>
      </StyledCustomButtonRange>
    )
  }
)

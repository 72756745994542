import { FC, useCallback, useMemo } from 'react'
import {
  basketProviderActions,
  EBetTypes,
  EMakeBetStatus,
  selectBasketAppliedCoupon,
  selectBasketBonusWallet,
  selectBasketMakeBetStatus
} from 'astra-core/containers/BasketProvider'
import { useTranslation } from 'react-i18next'

import { basketModel } from 'entities/basket'
import { identificationModel } from 'entities/constration'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './button-make-bet.styled'

const {
  selectBasketExpressIsDisabledBetButton,
  selectBasketOrdinarIsDisabledBetButton,
  selectBasketSystemIsDisabledBetButton
} = basketModel

const { useGetIsBlockedConstraint, IdentificationConstraints } =
  identificationModel

export const ButtonMakeBet: FC<{ betType: EBetTypes }> = ({ betType }) => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const makeBetStatus = useAppSelector(selectBasketMakeBetStatus)
  const bonusWallet = useAppSelector(selectBasketBonusWallet)
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)
  const isDisabledButton = useAppSelector(
    {
      [EBetTypes.Ordinar]: selectBasketOrdinarIsDisabledBetButton,
      [EBetTypes.Express]: selectBasketExpressIsDisabledBetButton,
      [EBetTypes.System]: selectBasketSystemIsDisabledBetButton
    }[betType]
  )

  const isBlockedWithoutIdentification = useGetIsBlockedConstraint(
    IdentificationConstraints.BETS
  )

  const isLoadingBasket = useMemo(
    () => EMakeBetStatus.loading === makeBetStatus,
    [makeBetStatus]
  )
  const makeBet = useCallback(() => {
    dispatch(basketProviderActions.makeBet())
  }, [dispatch])

  return (
    <S.Button
      disabled={isDisabledButton || isBlockedWithoutIdentification}
      isBonus={bonusWallet && !isDisabledButton}
      isCoupon={!!appliedCoupon}
      isLoading={isLoadingBasket}
      view="primary-extra-large"
      onClick={makeBet}
    >
      {t('make a bet')}
    </S.Button>
  )
}

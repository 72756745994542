import { useGetCurrencyIcon } from 'astra-core/hooks'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import React, { FC, useCallback } from 'react'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { formatAmountLocaleString } from 'astra-core'

import { useAppSelector } from 'shared/lib/@reduxjs'

import { useRouteRedirect } from '../../../../../../hooks/useRouteRedirect'
import { ERoutes } from '../../../../../../shared/types/routes'
import { IconWallet } from '../../../../../../shared/ui/Icon/General/IconWallet'
import { EColorsNames, EColorsTypes } from '../../../../../../shared/lib/theme'
import { RootState } from '../../../../../../shared/types/store'

import { StyledBalanceButton } from './CasinoComponentOfAuthorized.styled'

export const BalanceButton: FC = () => {
  const balance = useAppSelector(selectAccountBalance)
  const isBalanceHidden = useAppSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )
  const currencyIcon = useGetCurrencyIcon()
  const routeRedirect = useRouteRedirect()

  const handleRedirectList = useCallback(
    () => routeRedirect({ route: ERoutes.ProfileBalance }),
    [routeRedirect]
  )

  return (
    <StyledBalanceButton onClick={handleRedirectList}>
      {isBalanceHidden
        ? '***'
        : formatAmountLocaleString({
            placeholder: '...',
            currency: currencyIcon,
            value: balance
          })}

      <IconWallet
        colorProps={{
          name: EColorsNames.Primary,
          type: EColorsTypes.FIXED,
          value: 90
        }}
      />
    </StyledBalanceButton>
  )
}

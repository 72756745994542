import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBroadcast: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0.32381 9.84244C0.114286 9.6538 0 9.39657 0 9.12219V2.87781C0 2.612 0.114286 2.3462 0.32381 2.15756L1.26667 1.30011C1.47619 1.11147 1.7619 1 2.05714 1H9.94286C10.2381 1 10.5238 1.11147 10.7333 1.30011L11.6762 2.15756C11.8857 2.3462 12 2.60343 12 2.87781V9.12219C12 9.388 11.8857 9.6538 11.6762 9.84244L10.7333 10.6999C10.5238 10.8885 10.2381 11 9.94286 11H2.05714C1.7619 11 1.47619 10.8885 1.26667 10.6999L0.32381 9.84244ZM8.61703 6.32503C8.85703 6.18069 8.85703 5.81985 8.61703 5.67551L4.96109 3.47681C4.72109 3.33247 4.42109 3.51289 4.42109 3.80157V8.19898C4.42109 8.48765 4.72109 8.66807 4.96109 8.52374L8.61703 6.32503Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Tab } from '../../../shared/ui/tabs'

export const PrimaryNavigationLink = styled(NavLink)`
  ${({ theme }) => css`
    ${Tab} {
      transition: all 0.15s;
    }

    &.active {
      ${Tab} {
        background-color: ${theme.tokens.colors.surface['surface-active2']};
        color: ${theme.tokens.colors.text['text-invert']};
        transition: all 0.15s;
        svg {
          fill: ${theme.tokens.colors.text['text-invert']};
        }

        &:hover {
          background-color: ${theme.tokens.colors.surface['surface-active2']};
          color: ${theme.tokens.colors.text['text-invert']};
          svg {
            fill: ${theme.tokens.colors.text['text-invert']};
          }
        }
      }
    }
  `}
`

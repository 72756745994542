import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLiveSmall: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM5.49636 4.48486H6.50345C7.05966 4.48486 7.51055 4.93706 7.51055 5.49486V6.50486C7.51055 7.06267 7.05966 7.51486 6.50345 7.51486H5.49636C4.94015 7.51486 4.48926 7.06267 4.48926 6.50486V5.49486C4.48926 4.93706 4.94015 4.48486 5.49636 4.48486Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: () => (
    <>
      <path
        d="M1.11432 11.4796L8.14559 20.7623C8.25875 20.881 8.37221 21 8.59883 21H15.29C15.4032 21 15.5166 20.881 15.4032 20.7623L1.22748 2.07819C1.11432 1.95919 1.00085 1.95919 1.00085 2.19687V11.1232C1.00085 11.2419 1.00085 11.3609 1.11432 11.4796ZM23.0009 11.1232V2.19687C23.0009 1.95919 22.7742 1.95919 22.6611 2.07819L13.3639 14.3314V14.4577C13.3639 14.5368 13.4016 14.5764 13.4771 14.5764H20.2745C20.3949 14.5764 20.6215 14.4577 20.7346 14.3314L22.8871 11.4796C22.8871 11.3609 23.0009 11.2419 23.0009 11.1232Z"
        fill="#FF4655"
      />
    </>
  ),
  s: () => (
    <>
      <path
        d="M0.744464 7.6531L5.43198 13.8416C5.50742 13.9207 5.58306 14 5.73414 14H10.1949C10.2704 14 10.346 13.9207 10.2704 13.8416L0.819904 1.3855C0.744464 1.30617 0.668823 1.30617 0.668823 1.46462V7.41553C0.668823 7.49465 0.668823 7.57398 0.744464 7.6531ZM15.3355 7.41553V1.46462C15.3355 1.30617 15.1844 1.30617 15.109 1.3855L8.91085 9.55432V9.63849C8.91085 9.69124 8.93599 9.71762 8.98629 9.71762H13.5179C13.5982 9.71762 13.7492 9.63849 13.8247 9.55432L15.2596 7.6531C15.2596 7.57398 15.3355 7.49465 15.3355 7.41553Z"
        fill="#FF4655"
      />
    </>
  )
}

export const IconCybersportValorant: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

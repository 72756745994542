import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledHistoryEmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 400px;
  justify-content: center;
  padding: 0 8px;
`

export const StyledHistoryEmptyTitle = styled.div`
  ${typography['Body / 16 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledHistoryEmptyText = styled.div`
  ${typography['Body / 14 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    text-align: center;
  `}
`

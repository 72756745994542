import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M19.1268 14.2599C20.2384 14.5285 20.7568 15.1558 20.9328 15.7757C21.5634 16.0443 22.0001 16.6173 22.0001 17.2793C22.0001 18.198 21.1592 18.9454 20.1256 18.9454H5.89924C5.58645 18.9454 5.33279 18.72 5.33279 18.4419C5.33279 18.1639 5.58645 17.9384 5.89924 17.9384H7.02564V16.7536C6.97316 16.6205 6.91226 16.4899 6.84898 16.3542C6.55554 15.7251 6.21109 14.9866 6.41943 13.3783C6.57501 12.9935 6.7028 11.0027 6.76266 10.0702C6.77544 9.87099 6.78513 9.72011 6.79133 9.64346C6.87662 8.58038 6.64033 6.49112 6.59305 6.07307C6.58821 6.03026 6.58535 6.00498 6.58502 6.00011C6.58215 5.9567 6.59231 5.89142 6.65722 5.8269C6.88165 5.60349 7.44006 5.05469 9.36598 5.05469C9.82175 5.05469 10.5876 5.05955 10.5876 5.05955L11.3517 5.08181C11.3517 5.08181 12.3758 5.19993 12.3801 5.39162L12.0548 7.3641C11.2928 12.2657 16.7893 13.6123 16.7893 13.6123C17.6756 13.9006 18.5478 14.12 19.1268 14.2599ZM8.15851 17.9384H14.2509V17.0906L8.15851 17.0802V17.9384ZM15.3838 17.9384H20.1256C20.5345 17.9384 20.8673 17.6427 20.8673 17.2792C20.8673 17.2136 20.856 17.1503 20.8359 17.0906H15.3838V17.9384ZM4.11193 8.29579C3.86647 8.29579 3.66748 8.49477 3.66748 8.74023C3.66748 8.98569 3.86647 9.18468 4.11193 9.18468H5.72314C5.9686 9.18468 6.16758 8.98569 6.16758 8.74023C6.16758 8.49477 5.9686 8.29579 5.72314 8.29579H4.11193ZM3.27696 9.77799C3.0315 9.77799 2.83252 9.97698 2.83252 10.2224C2.83252 10.4679 3.0315 10.6669 3.27696 10.6669H5.72154C5.967 10.6669 6.16599 10.4679 6.16599 10.2224C6.16599 9.97698 5.967 9.77799 5.72154 9.77799H3.27696ZM2 11.7036C2 11.4581 2.19898 11.2591 2.44444 11.2591H5.72239C5.96785 11.2591 6.16684 11.4581 6.16684 11.7036C6.16684 11.949 5.96785 12.148 5.72239 12.148H2.44444C2.19898 12.148 2 11.949 2 11.7036Z"
      fill={theme.tokens.colors['sport-icon']['sport-speedSkating']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      d="M14.9753 11.6246C14.8065 11.0296 14.3088 10.4274 13.2417 10.1696C12.6859 10.0352 11.8486 9.82468 10.9977 9.54785C10.9977 9.54785 5.72134 8.25525 6.45289 3.54986L6.76512 1.65636C6.76103 1.47234 5.77786 1.35895 5.77786 1.35895L5.04442 1.33759C5.04442 1.33759 4.30921 1.33292 3.87169 1.33292C2.02288 1.33292 1.48683 1.85974 1.27138 2.07421C1.20907 2.13615 1.19932 2.19882 1.20207 2.24049C1.20548 2.29078 1.49038 4.61295 1.40012 5.73796C1.36633 6.15604 1.22437 8.87495 1.04311 9.32329C0.799985 11.2001 1.34106 11.843 1.62506 12.5634V13.7008H0.543754C0.243485 13.7008 -1.52588e-05 13.9172 -1.52588e-05 14.1841C-1.52588e-05 14.4511 0.243485 14.6674 0.543754 14.6674H14.2005C15.1927 14.6674 16 13.9499 16 13.0681C16 12.4326 15.5807 11.8825 14.9753 11.6246ZM8.56103 13.7008H2.71256V12.8769L8.56103 12.8869V13.7008ZM14.2005 13.7008H9.64855V12.8869H14.8824C14.9017 12.9443 14.9125 13.005 14.9125 13.068C14.9125 13.4169 14.593 13.7008 14.2005 13.7008Z"
      fill={theme.tokens.colors['sport-icon']['sport-speedSkating']}
    />
  )
}

export const IconSportSpeedSkating: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

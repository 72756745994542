import { FC } from 'react'

import { Tooltip } from 'shared/ui/Tooltip'

import { CardDependentListProps } from './card-dependent.types'
import * as S from './card-dependent.styled'

export const CardDependentGroup: FC<CardDependentListProps> = ({
  children,
  SportIcon,
  tournamentName,
  tournamentNameRef,
  isCommonTooltipTournamentName,
  isCommonTooltipTeamsName,
  teamsNameRef,
  teamsName
}) => (
  <S.StyledBetCardCouponWrapper isDependent>
    <S.StyledEventCouponContent>
      <S.StyledEventCouponHeader>
        <S.StyledEventCouponTitle>
          {SportIcon}

          <Tooltip
            isCommon={isCommonTooltipTournamentName}
            title={tournamentName}
          >
            <S.StyledEventCouponName ref={tournamentNameRef}>
              {tournamentName}
            </S.StyledEventCouponName>
          </Tooltip>
        </S.StyledEventCouponTitle>
      </S.StyledEventCouponHeader>

      <S.StyledEventCouponTeamsWrapper>
        <Tooltip isCommon={isCommonTooltipTeamsName} title={teamsName}>
          <S.StyledEventCouponTeams ref={teamsNameRef}>
            {teamsName}
          </S.StyledEventCouponTeams>
        </Tooltip>
      </S.StyledEventCouponTeamsWrapper>

      {children}
    </S.StyledEventCouponContent>
  </S.StyledBetCardCouponWrapper>
)

import { useMemo } from 'react'
import {
  IBasketError,
  selectMakeBetErrorsTop
} from 'astra-core/containers/BasketProvider'
import { EAllBasketErrors } from 'astra-core/containers/BasketProvider/constants'

import { useAppSelector } from 'shared/lib/@reduxjs'

export const useBetErrors = () => {
  const errors: IBasketError[] = useAppSelector(selectMakeBetErrorsTop)

  const minAmountError = useMemo(() => {
    return errors.find(
      (err) => err.code === EAllBasketErrors.MIN_AMOUNT_MUST_EXCEED
    )
  }, [errors])

  const maxAmountError = useMemo(() => {
    return errors.find(
      (err) => err.code === EAllBasketErrors.BETS_PAY_MAX_EXCEEDED
    )
  }, [errors])

  return { minAmountError, maxAmountError }
}

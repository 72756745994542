import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const LiveSportHeader = styled.div`
  ${typography['Body / 14 SemiBold']};
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

import React, { FC } from 'react'

import * as S from './tabs.styled'
import { TabsItemProps, TabsProps } from './tabs.types'

const Tabs: FC<TabsProps> & { Item: typeof TabsItem } = ({ children }) => (
  <S.Tabs>{children}</S.Tabs>
)

const TabsItem: FC<TabsItemProps> = ({ onClick, isActive, title, Icon }) => (
  <S.Tab $isActive={isActive} key={title} onClick={onClick}>
    {Icon}

    {title}
  </S.Tab>
)

Tabs.Item = TabsItem

export { Tabs }

import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'
import { typography } from 'shared/lib/theme/typography'
import { ESportsMenuTypes } from 'widgets/events-table/ui/events-table/events-table.types'

export const StyledHeaderWrapper = styled.div<{
  withBorder: boolean
  gapSize: number
}>`
  display: flex;
  grid-area: filter-container;
  justify-content: space-between;

  ${({ theme, withBorder, gapSize }) => css`
    gap: ${gapSize}px;
    padding: ${theme.tokens.alias.space['space-xl-24']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-l-20']}px;

    ${withBorder
      ? css`
          box-shadow: inset 0 -1px ${theme.tokens.colors.border['border-primary']};
        `
      : css`
          box-shadow: none;
        `}
  `}
  width: 100%;

  ${customMedia.tabletOrMobile} {
    flex-direction: column;
    justify-content: start;
  }
`

export const StyledTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

export const StyledTitleTextWrapper = styled.div`
  align-items: center;
  display: flex;
  min-width: 212px;
`

export const StyledTitle = styled.h2<{
  type?: ESportsMenuTypes
  titleFont: keyof typeof typography
}>`
  ${({ theme, titleFont }) => css`
    ${typography[titleFont]}
    color: ${theme.tokens.colors.text['text-primary']};
  `}

  ${({ type }) => css`
    ${type === ESportsMenuTypes.CALENDAR &&
    css`
      ${typography['Title / 24 Bold']}
    `}
  `}
`

import React, { FC, ReactElement, useEffect } from 'react'
import {
  getBasketLoading,
  selectBasketOutcomes
} from 'astra-core/containers/BasketProvider'
import {
  selectAccountUser,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'
import isEqual from 'react-fast-compare'

import { useCompleted } from 'hooks'
import {
  rightBasketContainerActions,
  selectIsBasketLoaded
} from 'entities/basket/model'
import { selectBroadcastsEventIdsLength } from 'entities/match-broadcast/model'
import { Skeleton } from 'shared/ui/skeleton'
import { BasketEmptyScreen, BasketSkeletonLoader } from 'entities/basket'
import { BetTypeNavigation } from 'features/basket/ui/bet-type-navigation'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './content.styled'

export const ContentInstance: FC<{ children: ReactElement }> = ({
  children
}) => {
  const dispatch = useAppDispatch()
  const outcomes = useAppSelector(selectBasketOutcomes, isEqual)
  const isLoading = useAppSelector(getBasketLoading)
  const isCompleted = useCompleted(!!isLoading)
  const user = useAppSelector(selectAccountUser)
  const userFetch = useAppSelector(selectFetchUserRequest)
  const isBasketLoaded = useAppSelector(selectIsBasketLoaded)
  const isUserLoaded = useCompleted(!!userFetch.loading)
  const broadcastsEventIdsLength = useAppSelector(
    selectBroadcastsEventIdsLength
  )

  useEffect(() => {
    if ((isUserLoaded && !user) || isCompleted) {
      dispatch(rightBasketContainerActions.setBasketLoaded(true))
    }
  }, [dispatch, isCompleted, isUserLoaded, user])

  return (
    <Skeleton
      isLoading={!isBasketLoaded}
      styledFallback={<BasketSkeletonLoader />}
    >
      {outcomes.length ? (
        <S.ContentInstanceContainer
          broadcastsEventIdsLength={broadcastsEventIdsLength}
        >
          <BetTypeNavigation />

          {children}
        </S.ContentInstanceContainer>
      ) : (
        <BasketEmptyScreen />
      )}
    </Skeleton>
  )
}

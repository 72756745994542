import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M24 12.2218C23.6354 12.8446 23.2708 13.4674 22.905 14.0896C22.6688 14.4919 22.3268 14.6409 21.8902 14.5487C21.758 14.5209 21.8307 14.4442 21.8388 14.3894C21.9528 13.6512 21.8482 12.949 21.5087 12.2882C20.8021 10.9129 19.2197 10.2398 17.7771 10.6969C16.3225 11.1579 15.3848 12.6157 15.5501 14.1599C15.5627 14.2792 15.579 14.3984 15.5934 14.5203C15.5339 14.5667 15.4612 14.5448 15.3935 14.5454C13.1359 14.5467 10.8789 14.5441 8.62124 14.5499C8.43895 14.5499 8.40199 14.5209 8.4283 14.3185C8.66259 12.5055 7.58326 10.9613 5.84993 10.608C3.80716 10.1915 1.87025 12.0567 2.13961 14.1818C2.14275 14.2057 2.14713 14.2295 2.14901 14.2534C2.1559 14.3456 2.19912 14.4913 2.16154 14.519C2.06382 14.5899 1.93728 14.5351 1.83016 14.4861C1.67481 14.4152 1.5608 14.2991 1.48437 14.1418C1.01831 13.1811 0.547239 12.2231 0.0868158 11.2604C-0.104244 10.862 0.0273054 10.459 0.410052 10.2611C1.91974 9.48031 3.4313 8.70468 4.94412 7.93098C5.57431 7.60861 6.26588 7.57637 6.94681 7.49771C8.30427 7.34039 9.66236 7.19081 11.0186 7.02769C11.7484 6.94001 12.428 7.05477 13.0613 7.45516C13.8651 7.96257 14.6807 8.45065 15.4837 8.95871C15.6485 9.06315 15.8045 9.08572 15.9899 9.07025C16.8537 9.00126 17.7157 8.89294 18.5833 8.88392C19.8512 8.87102 20.9913 9.27657 22.0023 10.0574C22.5642 10.4913 23.1105 10.9458 23.6686 11.3849C23.8227 11.5061 23.911 11.6686 23.9994 11.8362L24 12.2218Z"
        fill={theme.tokens.colors['sport-icon']['sport-rallying']}
      />
      <path
        d="M5.31246 11.6454C4.13729 11.6363 3.18199 12.6131 3.18136 13.824C3.18136 15.0283 4.11661 15.9948 5.28803 16C6.45569 16.0051 7.40535 15.0367 7.40911 13.8369C7.41287 12.6338 6.47511 11.6544 5.31246 11.6454ZM5.28552 14.5461C4.89965 14.5429 4.59207 14.225 4.59082 13.8278C4.58957 13.4255 4.91531 13.0902 5.30181 13.096C5.67892 13.1018 5.99652 13.4326 5.9984 13.8207C6.00028 14.2256 5.68268 14.5499 5.28552 14.5461Z"
        fill={theme.tokens.colors['sport-icon']['sport-rallying']}
      />
      <path
        d="M18.6892 11.6454C17.5265 11.6531 16.5881 12.6338 16.5913 13.8356C16.595 15.0354 17.5434 16.0038 18.7105 16C19.8831 15.9955 20.8184 15.0309 20.8184 13.8253C20.8184 12.6138 19.865 11.637 18.6892 11.6454ZM18.7123 14.5461C18.3152 14.5487 17.9976 14.225 18.0007 13.8194C18.0032 13.4307 18.3221 13.1006 18.6992 13.096C19.0857 13.0915 19.4108 13.4274 19.4089 13.8291C19.407 14.2256 19.0988 14.5435 18.7123 14.5461Z"
        fill={theme.tokens.colors['sport-icon']['sport-rallying']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M16 8.48118C15.7569 8.89639 15.5139 9.31161 15.27 9.7264C15.1126 9.99461 14.8845 10.0939 14.5935 10.0324C14.5053 10.014 14.5538 9.9628 14.5592 9.92627C14.6352 9.43411 14.5655 8.96603 14.3391 8.52545C13.8681 7.60862 12.8132 7.15988 11.8514 7.46463C10.8817 7.77196 10.2565 8.7438 10.3668 9.77325C10.3751 9.85277 10.386 9.93229 10.3956 10.0135C10.3559 10.0445 10.3075 10.0299 10.2624 10.0303C8.75726 10.0311 7.25259 10.0294 5.7475 10.0333C5.62597 10.0333 5.60133 10.014 5.61887 9.87899C5.77506 8.6703 5.0555 7.64086 3.89996 7.40531C2.53811 7.12764 1.24683 8.37114 1.42641 9.78786C1.4285 9.80377 1.43142 9.81967 1.43267 9.83557C1.43727 9.89704 1.46608 9.99418 1.44103 10.0127C1.37588 10.0599 1.29152 10.0234 1.22011 9.99074C1.11654 9.94346 1.04053 9.86609 0.989582 9.76121C0.678874 9.12077 0.364826 8.48204 0.0578772 7.8403C-0.0694961 7.57466 0.0182036 7.30602 0.273368 7.17406C1.27983 6.65354 2.28754 6.13645 3.29608 5.62065C3.71621 5.40574 4.17725 5.38425 4.6312 5.33181C5.53618 5.22693 6.44158 5.12721 7.34572 5.01846C7.83224 4.96 8.28536 5.03651 8.70757 5.30344C9.24337 5.64172 9.78711 5.9671 10.3225 6.3058C10.4323 6.37544 10.5363 6.39048 10.6599 6.38016C11.2358 6.33417 11.8105 6.26196 12.3889 6.25594C13.2341 6.24735 13.9942 6.51771 14.6682 7.03824C15.0428 7.32751 15.407 7.63054 15.7791 7.92326C15.8818 8.00407 15.9407 8.11238 15.9996 8.22414L16 8.48118Z"
        fill={theme.tokens.colors['sport-icon']['sport-rallying']}
      />
      <path
        d="M3.54164 8.09691C2.75819 8.09089 2.12132 8.74208 2.12091 9.54931C2.12091 10.3522 2.74441 10.9965 3.52535 11C4.30379 11.0034 4.9369 10.3578 4.93941 9.5579C4.94191 8.75584 4.31674 8.10293 3.54164 8.09691Z"
        fill={theme.tokens.colors['sport-icon']['sport-rallying']}
      />
      <path
        d="M12.4594 8.09691C11.6843 8.10207 11.0588 8.75584 11.0608 9.55704C11.0633 10.357 11.6956 11.0026 12.4736 11C13.2554 10.997 13.8789 10.354 13.8789 9.55017C13.8789 8.74251 13.2433 8.09132 12.4594 8.09691Z"
        fill={theme.tokens.colors['sport-icon']['sport-rallying']}
      />
    </>
  )
}

export const IconSportRally: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export const useBannerScroll = ({
  headEventClassName,
  mainHeaderHeight
}: {
  headEventClassName: string
  mainHeaderHeight: number
}) => {
  const [isScrolled, setIsScrolled] = useState<null | boolean>(null)
  const containerScrolledRef = useRef<HTMLDivElement>(null)
  const handleScrollRef = useRef<() => void>(() => {})
  const { setEventBannerRefData, eventBannerDataRef } = useGetEventBannerRef()
  const refHeightTopFixedPanelWithMiniBanner = useRef<number | null>(null)

  useEffect(() => {
    const { current: eventBanner } = eventBannerDataRef
    const { current: containerScrolledNode } = containerScrolledRef
    const { current: heightTopFixedPanelWithMiniBanner } =
      refHeightTopFixedPanelWithMiniBanner

    if (
      isScrolled === false &&
      containerScrolledNode &&
      heightTopFixedPanelWithMiniBanner
    ) {
      // scroll without jerks if banner was Mini and now is Full. This conditional don't need for first render of Full banner that why we use "isScrolled === false"

      const bottomEventBannerPx =
        eventBanner.offsetTop + eventBanner.offsetHeight

      containerScrolledNode.scroll({
        top: bottomEventBannerPx - (heightTopFixedPanelWithMiniBanner + 10)
      })
    }
  }, [eventBannerDataRef, isScrolled])

  useEffect(() => {
    // using function as ref for correct update "State"

    handleScrollRef.current = () => {
      const { current: eventBanner } = eventBannerDataRef
      const { current: containerScrolledNode } = containerScrolledRef

      if (containerScrolledNode) {
        const documentScrollTopPx =
          containerScrolledNode.scrollTop + mainHeaderHeight

        if (
          documentScrollTopPx >
            eventBanner.offsetTop + eventBanner.offsetHeight &&
          !isScrolled
        ) {
          // Display mini banner
          setIsScrolled(true)
        }

        if (
          containerScrolledRef.current &&
          containerScrolledRef.current.scrollTop <= 30 &&
          isScrolled
        ) {
          // Display full banner

          const node = document.querySelector(headEventClassName) // Get node of a top fixed panel with mini banner

          if (node && !refHeightTopFixedPanelWithMiniBanner.current) {
            // Set height once, because the height of mini will not change within lifecycle, and we defend the accidental values of Full banned
            refHeightTopFixedPanelWithMiniBanner.current = node.clientHeight
          }

          setIsScrolled(false)
        }
      }
    }
  }, [eventBannerDataRef, headEventClassName, isScrolled, mainHeaderHeight])

  useEffect(() => {
    // Set scroll listener
    const containerScrolledNode = containerScrolledRef?.current
    const { current: handleScroll } = handleScrollRef

    containerScrolledNode?.addEventListener('scroll', handleScroll)

    return () => {
      containerScrolledNode?.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled])

  return { setEventBannerRefData, isScrolled, containerScrolledRef }
}

type TComponentOffsetTop = Pick<HTMLElement, 'offsetTop' | 'offsetHeight'>

const useGetEventBannerRef = () => {
  const eventBannerDataRef = useRef<TComponentOffsetTop>({
    offsetTop: 0,
    offsetHeight: 0
  })

  const setEventBannerRefData = useCallback((ref: RefObject<HTMLElement>) => {
    const { offsetTop, offsetHeight } = ref.current || {}

    if (offsetTop && offsetHeight) {
      eventBannerDataRef.current = { offsetTop, offsetHeight }
    }
  }, [])

  return {
    setEventBannerRefData,
    eventBannerDataRef
  }
}

import { FC, useMemo, useState } from 'react'
import { BasicScore } from 'betweb-openapi-axios'
import range from 'lodash/range'
import { useTranslation } from 'react-i18next'

import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { IconCloseOutline12 } from 'shared/ui/Icon/General/IconCloseOutline12'
import { betsHistoryApi, LegacyBetStatusEnum } from 'entities/legacy-api'
import { IconLiveSmall } from 'shared/ui/Icon/General/IconLiveSmall'

import { IconRefresh } from '../../../shared/ui/Icon/General/icon-refresh'
import { Typography } from '../../../shared/ui/typography'
import { Tooltip } from '../../../shared/ui/Tooltip'

import { BasicStatisticsProps } from './basic-statistics.types'
import {
  GetLegacyStatistics,
  IconWrap,
  ScoresByPeriodsModal,
  ScoresByPeriodsWrapper,
  ScoresByPeriodText,
  ShowMoreButton,
  ShowMoreDot,
  StyledStatisticsWrapper
} from './basic-statistics.styled'

const { useLazyGetEventStatisticsQuery } = betsHistoryApi

const renderScore = (score: BasicScore) =>
  `${score.homeScore}:${score.awayScore}`

// eslint-disable-next-line unused-imports/no-unused-vars
const ScoresByPeriods: FC<{ scoreByPeriods: BasicScore[] }> = ({
  scoreByPeriods
}) => {
  const [ref, isOverflowing] = useTextOverflow<HTMLDivElement>()
  const [isOpen, setIsOpen] = useState(false)

  const buttonContent = useMemo(
    () =>
      isOpen ? (
        <IconCloseOutline12 colorToken="icon-secondary" size={12} />
      ) : (
        <>
          {range(0, 3).map((index) => (
            <ShowMoreDot key={index} />
          ))}
        </>
      ),
    [isOpen]
  )

  const renderedScore = `(${scoreByPeriods.map(renderScore).join(', ')})`

  return (
    <ScoresByPeriodsWrapper>
      <ScoresByPeriodText ref={ref}>{renderedScore}</ScoresByPeriodText>
      <ShowMoreButton
        isVisible={isOverflowing}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {buttonContent}
      </ShowMoreButton>
      {isOpen && (
        <ScoresByPeriodsModal>
          {renderedScore}
          <ShowMoreButton isVisible onClick={() => setIsOpen((prev) => !prev)}>
            {buttonContent}
          </ShowMoreButton>
        </ScoresByPeriodsModal>
      )}
    </ScoresByPeriodsWrapper>
  )
}

export const BasicStatisticsLegacy: FC<BasicStatisticsProps> = ({
  outcome
}) => {
  const { t } = useTranslation()
  const [getStatistics, { data, isFetching }] = useLazyGetEventStatisticsQuery()

  return (
    <StyledStatisticsWrapper>
      {outcome.status === LegacyBetStatusEnum.LIVE && (
        <IconLiveSmall colorToken="icon-negative" size={12} />
      )}
      {data && (
        <Tooltip title={data.data.score}>
          <Typography lineClamp={1}>{data.data.score}</Typography>
        </Tooltip>
      )}

      {!data ? (
        <GetLegacyStatistics
          onClick={() => getStatistics({ id: outcome.matchid })}
        >
          <IconWrap isLoading={isFetching}>
            <IconRefresh />
          </IconWrap>
          {t('find out the score')}
        </GetLegacyStatistics>
      ) : null}
    </StyledStatisticsWrapper>
  )
}

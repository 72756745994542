import get from 'lodash/get'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  Competitor,
  Event,
  Tournament,
  Sport,
  EventSourceType,
  EventProbabilityTradingStatus,
  EventProbability
} from 'betweb-openapi-axios'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import {
  CommonDataProviderState,
  MainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'
import { getLineTournamentLink } from 'astra-core'

import { toIdsArray } from 'shared/lib/@reduxjs'
import { TUseProbs } from 'hooks'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'

import { IGetIsBroadcastFilterButtonEnabled } from './types'

export function getEventHomeAwayCompetitors(event: Event): {
  homeCompetitors: Array<Competitor>
  awayCompetitors: Array<Competitor>
} {
  return {
    homeCompetitors: event.competitors.filter((competitor) =>
      event.homeCompetitorIds?.includes(competitor.id)
    ),
    awayCompetitors: event.competitors.filter(
      (competitor) => !event.homeCompetitorIds?.includes(competitor.id)
    )
  }
}

export function getTournamentLink(tournament?: Tournament): string {
  if (!tournament) {
    return ''
  }

  return `/line/${tournament.sportId}/${tournament.id}`
}

export const getSearchTournamentLink = (tournament: Tournament) =>
  `/search/${tournament.id}`

export function getEventBackLink(event, prevPath?): string {
  if (event) {
    if (prevPath) return prevPath

    if (event.live) return `/live/${event.tournament.sportId}`

    return getLineTournamentLink(event.tournament)
  } else {
    return ''
  }
}
export function getEventSport(sports, event?: Event): Sport {
  return get(sports, `${event?.tournament.sportId}`)
}

export function formatDate(date: string): string {
  const format = {
    en: 'MMMM DD [at] HH:mm',
    ru: 'DD.MM.YY, HH:mm'
  }
  const currentFormat = format[dayjs().locale()]
  return dayjs(date).format(currentFormat)
}

export function formatEventBannerDate(date: string): string {
  const format = {
    en: 'MMMM DD',
    ru: 'DD MMMM'
  }
  const currentFormat = format[dayjs().locale()] ?? format.ru
  return dayjs(date).format(currentFormat)
}

export function formatEventBannerTime(date: string): string {
  const formattedTime = dayjs(date).format('HH:mm')
  return formattedTime
}

export function getLastYearEvent(date: string): string | null {
  const islastYear = Number(dayjs(date).format('YYYY')) < Number(dayjs().year())
  return islastYear ? dayjs(date).format('YYYY') : null
}

export const groupEventItemsBySport = (items: Event[]) =>
  mapValues(
    groupBy(items, (item) => item.tournament.sportId),
    (events) => toIdsArray(events)
  )

export const getCompetitorsNames = (competitors: Competitor[]) =>
  competitors.map((c) => c.name).join(', ')

export const getEventBetradarSourceId = (event: Event) =>
  event.sources
    ?.find((source) => source.sourceType === EventSourceType.Betradar)
    ?.sourceEventId.split(':')[2]

export const getMainOutcomeTypeProbability = ({
  outcomeType,
  probs,
  mainOutcomeTypes,
  eventId
}: {
  outcomeType: MainOutcomeTypes
  probs: TUseProbs
  mainOutcomeTypes: CommonDataProviderState['entries']['mainOutcomeTypes']
  eventId: Event['id']
}): EventProbability & { eventId: number } => {
  const probability = probs[mainOutcomeTypes[outcomeType]!]
  const outcomeTypeId = mainOutcomeTypes[outcomeType]!

  return probability
    ? {
        ...probability,
        eventId
      }
    : {
        odd: 0,
        outcomeTypeId,
        parameters: [],
        suspended: true,
        tradingStatus: EventProbabilityTradingStatus.Suspended,
        eventId
      }
}

export const getBackLinkBuilder = (
  type: ESportsMenuTabTypes,
  tournamentSportId: Pick<Tournament, 'sportId'>['sportId'],
  genericSportId?: Sport['genericSportId']
) => {
  const sportId = genericSportId ?? tournamentSportId

  return `/${type}/${sportId}`
}

export const getIsBroadcastFilterButtonEnabled = ({
  eventsIdsBySport,
  broadcastsMap
}: IGetIsBroadcastFilterButtonEnabled) =>
  eventsIdsBySport.some((eventId) => broadcastsMap[eventId]?.available)

import { range } from 'lodash'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'

import {
  GlobalSearchItemInfoLoader,
  GlobalSearchItemLoader,
  GlobalSearchLoaderWrapper,
  GlobalSearchTitleLoader
} from './global-search-loader.styled'

export const GlobalSearchLoader = ({ count = 1 }: { count?: number }) => (
  <GlobalSearchLoaderWrapper>
    <GlobalSearchTitleLoader>
      <ContentLoader borderRadius={4} height={12} width={48} />
    </GlobalSearchTitleLoader>
    {range(0, count).map((index) => (
      <GlobalSearchItemLoader key={index}>
        <ContentLoader borderRadius={4} height={14} width={14} />
        <GlobalSearchItemInfoLoader>
          <ContentLoader borderRadius={4} height={16} width={211} />
          <ContentLoader borderRadius={4} height={12} width={110} />
        </GlobalSearchItemInfoLoader>
      </GlobalSearchItemLoader>
    ))}
  </GlobalSearchLoaderWrapper>
)

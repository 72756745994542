import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M14.7309 3.28969C14.7309 4.27804 13.9328 5.07938 12.9485 5.07938C11.9642 5.07938 11.1662 4.27804 11.1662 3.28969C11.1662 2.30134 11.9642 1.5 12.9485 1.5C13.9328 1.5 14.7309 2.30134 14.7309 3.28969ZM12.0574 16.777L12.1205 16.7469C12.3241 16.6486 12.617 16.5066 12.9749 16.33C13.6907 15.9766 14.6674 15.4859 15.7111 14.9345C17.5879 13.9429 19.7369 12.7281 20.9434 11.7632C21.1415 11.6047 21.179 11.3231 21.0434 11.1087C20.9069 10.8931 20.6347 10.8082 20.3986 10.9048C19.3902 11.3172 17.9456 11.8614 16.3977 12.3238C14.6184 12.8555 12.7381 13.2684 11.2347 13.2684C10.1514 13.2684 9.34138 12.8822 8.74219 12.2635C8.13298 11.6352 7.71916 10.7429 7.49353 9.71046C7.0757 7.7979 7.32947 5.52469 8.0267 3.89539C8.12481 3.66612 8.04694 3.39496 7.83217 3.26823C7.61625 3.14083 7.33739 3.20468 7.20386 3.41686C5.39368 6.29319 4.96772 8.87853 5.28886 11.0248C5.59825 13.0937 6.59626 14.7191 7.65106 15.7756L6.62153 17.8327C8.2429 17.5375 10.0716 17.4973 12.0565 17.8602L12.0574 16.777ZM12.9205 12.2185C13.4059 11.5176 13.0082 10.2964 12.0317 9.49068C11.0554 8.68497 9.87031 8.59977 9.38468 9.30017C8.89928 10.001 9.29722 11.2223 10.2735 12.028C11.2499 12.8341 12.4351 12.9193 12.9205 12.2185ZM1.17729 22.2588C0.827505 22.3599 1.04104 22.1404 1.42125 21.7845C3.29462 20.0308 7.3194 18.1819 12.5109 19.3838C13.05 19.5086 13.5625 19.6349 14.056 19.7564C17.1052 20.5075 19.4315 21.0804 22.8478 20.0407C23.0188 19.9886 23.1146 20.0283 22.7527 20.2795C19.2303 22.7241 15.6261 22.915 12.0536 21.7845C8.48112 20.654 5.51999 21.0039 1.17729 22.2588Z"
      fill={theme.tokens.colors['sport-icon']['sport-waterPolo']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M9.98606 1.68342C9.98606 2.40222 9.40566 2.98501 8.68981 2.98501C7.97395 2.98501 7.39355 2.40222 7.39355 1.68342C7.39355 0.964626 7.97395 0.381836 8.68981 0.381836C9.40566 0.381836 9.98606 0.964626 9.98606 1.68342ZM8.04168 11.4923L8.08757 11.4704C8.23567 11.3989 8.44866 11.2956 8.70895 11.1673C9.22954 10.9102 9.93986 10.5533 10.6989 10.1523C12.0638 9.43117 13.6267 8.54769 14.5042 7.84592C14.6483 7.73068 14.6756 7.52586 14.5769 7.36996C14.4777 7.21318 14.2797 7.15138 14.108 7.22163C13.3746 7.52159 12.324 7.91734 11.1983 8.25363C9.90426 8.64036 8.53679 8.94065 7.44339 8.94065C6.65554 8.94065 6.06643 8.65974 5.63066 8.20978C5.1876 7.75283 4.88664 7.10394 4.72255 6.35305C4.41867 4.96211 4.60323 3.30887 5.11031 2.12393C5.18166 1.95719 5.12503 1.75998 4.96883 1.66782C4.8118 1.57516 4.60899 1.6216 4.51188 1.77591C3.19539 3.86778 2.8856 5.74801 3.11916 7.3089C3.34417 8.81354 4.06999 9.99565 4.83711 10.764L4.08837 12.2601C5.26755 12.0454 6.59753 12.0162 8.04103 12.2801L8.04168 11.4923ZM8.66939 8.17705C9.02242 7.66735 8.73316 6.77918 8.02301 6.19321C7.31301 5.60725 6.4511 5.54528 6.09792 6.05467C5.7449 6.56437 6.03431 7.45254 6.74431 8.0385C7.45447 8.62479 8.31637 8.68675 8.66939 8.17705ZM0.128937 15.4791C-0.125451 15.5526 0.0298454 15.393 0.306364 15.1341C1.66881 13.8587 4.59591 12.5141 8.37156 13.3882C8.76362 13.479 9.13632 13.5708 9.49525 13.6592C11.7128 14.2054 13.4046 14.6221 15.8892 13.8659C16.0136 13.828 16.0833 13.8569 15.8201 14.0396C13.2584 15.8174 10.6371 15.9563 8.03895 15.1341C5.44079 14.312 3.28725 14.5665 0.128937 15.4791Z"
      fill={theme.tokens.colors['sport-icon']['sport-waterPolo']}
      fillRule="evenodd"
    />
  )
}

export const IconSportWaterPolo: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

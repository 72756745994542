import { RootState } from 'shared/types/store'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  selectActivePaymentMethodKey,
  BalanceOperationsTypes
} from '../../../model'

export const useGetActivePaymentMethodVariantKey = (
  balanceOperationType: BalanceOperationsTypes
) => {
  return useAppSelector((state: RootState) =>
    selectActivePaymentMethodKey(state, balanceOperationType)
  )
}

import { FC } from 'react'

import { EventRowProps } from './event-row.types'
import * as S from './event-row.styled'

export const EventRow: FC<EventRowProps> = ({
  Outcomes,
  Coefficients,
  backgroundImageUrl,
  entityTagColor,
  isDisplayTableOutcomes,
  isDarkMode,
  CompetitorsInfo,
  AdditionalInfo
}) => {
  return (
    <>
      <S.EventRow
        $isDarkMode={!!isDarkMode}
        $isDisplayOutcomes={isDisplayTableOutcomes}
        bgImageUrl={backgroundImageUrl || ''}
        entityTagColor={entityTagColor || ''}
      >
        {CompetitorsInfo}

        {AdditionalInfo}

        {Coefficients}
      </S.EventRow>

      {isDisplayTableOutcomes && !!Outcomes && (
        <S.OutcomesContainer>
          <td colSpan={4}>{Outcomes}</td>
        </S.OutcomesContainer>
      )}
    </>
  )
}

import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import invariant from 'invariant'

import type { PropsWithChildren } from 'react'

export interface SkeletonProps {
  /**
   * Количество предполагаемых элементов для отображения скелетонов
   */
  count?: number
  /**
   * Ваш скелетон обернутый в ContentLoader со стилями компонента, сгенерировать можно тут
   * https://skeletonreact.com/ (пропсы кроме width, height, viewBox не нужны)
   */
  styledFallback: ReactElement
  /**
   * Состояние загрузки данных
   */
  isLoading: boolean
}

export const SKELETON_EXIT_DELAY_MS = 300
export const SKELETON_APPEARANCE_DELAY_MS = 400

/**
 *
 * @example
 *
 * const CardSkeleton = () => (
 *   <Card>
 *     <ContentLoader width={272} height={129} viewBox="0 0 272 129">
 *       <rect x="2" y="0" rx="8" ry="8" width="40" height="45" />
 *       <rect x="0" y="61" rx="8" ry="8" width="272" height="24" />
 *       <rect x="0" y="89" rx="8" ry="8" width="184" height="40" />
 *     </ContentLoader>
 *   </Card>
 * );
 *
 * const YourPage = () => {
 *  const [isLoading, setIsLoading] = useState(true);
 *
 *  return (
 *   <Skeleton count={3} isLoading={isLoading} styledFallback={<CardSkeleton />}>
 *      <Card />
 *      <Card />
 *      <Card />
 *   </Skeleton>
 *   );
 * }
 */
export const Skeleton: React.FC<PropsWithChildren<SkeletonProps>> = ({
  children,
  count,
  styledFallback,
  isLoading
}) => {
  const [isSkeletonActive, setIsSkeletonActive] = useState(isLoading)
  useEffect(() => {
    const timeout = setTimeout(
      () => setIsSkeletonActive(isLoading),
      isLoading ? SKELETON_APPEARANCE_DELAY_MS : SKELETON_EXIT_DELAY_MS
    )
    return () => {
      clearTimeout(timeout)
    }
  }, [isLoading])

  /**
   * skeletonInstance нужен если потребуются обёртки
   */
  const skeletonInstance = useMemo(() => {
    invariant(
      React.isValidElement(styledFallback),
      'Skeleton styledFallback не является валидным React элементом'
    )
    return styledFallback
  }, [styledFallback])

  const multipleSkeleton = useMemo(() => {
    return (
      <>
        {Array(count || 1)
          .fill(false)
          .map((_, index) =>
            React.cloneElement(skeletonInstance, {
              // eslint-disable-next-line react/no-array-index-key
              key: index
            })
          )}
      </>
    )
  }, [count, skeletonInstance])

  if (count && isSkeletonActive) {
    return <>{multipleSkeleton}</>
  }

  if (isSkeletonActive) {
    return <>{skeletonInstance}</>
  }

  return <>{children}</>
}

import {
  Event,
  EventProbability,
  OutcomeType,
  ParameterType
} from 'betweb-openapi-axios'
import { useEffect, useMemo, useState } from 'react'
import {
  getBasketOutcomeKey,
  selectBasketOutcomeByKey
} from 'astra-core/containers/BasketProvider'

import { RootState } from 'shared/types/store'
import { usePrevious } from 'hooks'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { OddModes, UseOddHighlightType } from '../model/odd-mode'

const ODD_HIGHLIGHT_TIMEOUT = 2000

export function useOddHighlight(
  probability?: EventProbability
): UseOddHighlightType {
  const prevOdd = usePrevious(probability?.odd)
  const [mode, setMode] = useState<OddModes>(OddModes.Default)

  useEffect(() => {
    if (prevOdd && probability && prevOdd !== probability.odd) {
      if (prevOdd! < probability?.odd) {
        setMode(OddModes.Increase)
      } else if (prevOdd! > probability?.odd) {
        setMode(OddModes.Decrease)
      }
    }
  }, [prevOdd, probability])

  useEffect(() => {
    if (mode !== OddModes.Default) {
      const timerId = setTimeout(
        () => setMode(OddModes.Default),
        ODD_HIGHLIGHT_TIMEOUT
      )
      return () => clearTimeout(timerId)
    }
    return () => null
  }, [mode])

  return { mode, prevOdd }
}

export function useEventMode({
  eventProbability,
  eventId
}: {
  eventProbability?: EventProbability
  eventId: Event['id']
}): {
  mode: OddModes
  isInBasket: boolean
} {
  const { mode } = useOddHighlight(eventProbability)

  const basketOutcomeKey = useMemo(() => {
    if (!eventProbability) {
      return ''
    }

    return getBasketOutcomeKey({
      probability: {
        outcomeTypeId: eventProbability.outcomeTypeId,
        parameters: eventProbability.parameters
      },
      event: { id: eventId }
    })
  }, [eventId, eventProbability])

  const isInBasket = !!useAppSelector((state: RootState) =>
    selectBasketOutcomeByKey(state, basketOutcomeKey)
  )

  return { mode, isInBasket }
}

export const useTitleMergedColumns = (
  eventProbability: EventProbability & { eventId: Event['id'] } & {
    outcomeTypeData: OutcomeType
  }
) => {
  const pointRange = eventProbability.parameters.find(
    (param) => param.type === ParameterType.PointRange
  )

  if (pointRange) {
    let title = `${pointRange.value}`

    if (eventProbability?.outcomeTypeData.name) {
      title = `${eventProbability?.outcomeTypeData.name} ${title}`
    }

    return title
  }

  const winningMarginObj = eventProbability.parameters.find(
    (param) => param.type === ParameterType.WinningMargin
  )

  if (winningMarginObj) {
    return `${eventProbability.outcomeTypeData.name} ${winningMarginObj.value}`
  }

  const goalsAndTimeParams = eventProbability.parameters.filter(
    (param) =>
      param.type === ParameterType.GoalsRange ||
      param.type === ParameterType.ExactGoals ||
      param.type === ParameterType.TimeRange
  )

  if (eventProbability?.outcomeTypeData.name.trim()) {
    return eventProbability?.outcomeTypeData.name
  }

  if (goalsAndTimeParams.length > 0) {
    return goalsAndTimeParams[0].value
  }

  return ''
}

import { VIRTUAL_GAMES_ENDPOINTS } from './constants'

export const isResponseFromVirtualGames = (url?: string): boolean => {
  if (url !== undefined) {
    return VIRTUAL_GAMES_ENDPOINTS.some((endpoint) => url.endsWith(endpoint))
  }
  return false
}

export const isOnVirtualGamePage = (
  path: string,
  virtualGamesRoutes: Set<string>
): boolean => {
  return Array.from(virtualGamesRoutes).some(
    (route) => route !== '' && path.startsWith(route)
  )
}

import { FC, memo } from 'react'

import * as CellStyles from '../cell/cell.styled'

import { CellWithoutProbabilityProps } from './cell-without-probability.types'

export const CellWithoutProbability: FC<CellWithoutProbabilityProps> = memo(
  ({ isFirst, isLast, withParam, paramCellWidth, isCompact }) => (
    <CellStyles.CellWrapper isCompact={isCompact} isDisabled>
      {withParam && (
        <CellStyles.BlockParamCell
          cellWidth={paramCellWidth}
          isCompact={isCompact}
          isFirst
        >
          –
        </CellStyles.BlockParamCell>
      )}

      <CellStyles.BlockCell
        isCompact={isCompact}
        isFirst={isFirst && !withParam}
        isLast={isLast}
      >
        –
      </CellStyles.BlockCell>
    </CellStyles.CellWrapper>
  )
)

import { FC, PropsWithChildren } from 'react'

import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'

import { BonusCouponCanNotUseList } from '../bonus-coupon-can-not-use'
import { BonusCouponCanUseList } from '../bonus-coupon-can-use'
import { ButtonMakeBet } from '../button-make-bet'
import { Bonus } from '../bonus'
import {
  ErrorClientList,
  ErrorMakeBetList,
  ErrorPassIdentification
} from '../error'

import { StyledBasketBottomWrapper } from './footer.styled'
import { FooterProps } from './footer.types'

export const BasketFooter: FC<PropsWithChildren<FooterProps>> = ({
  children,
  betType
}) => (
  <StyledBasketBottomWrapper>
    <BasketTooltipProvider>
      {children}

      <ErrorMakeBetList />

      <ErrorClientList />

      <Bonus>
        <BonusCouponCanUseList />
      </Bonus>

      <ErrorPassIdentification />

      <ButtonMakeBet betType={betType} />

      <BonusCouponCanNotUseList />
    </BasketTooltipProvider>
  </StyledBasketBottomWrapper>
)

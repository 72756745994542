import React, { useCallback } from 'react'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { IconSetting } from 'shared/ui/Icon/General/IconSetting'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { useAppDispatch } from 'shared/lib/@reduxjs'

export const SettingsButton = () => {
  const dispatch = useAppDispatch()
  const handleClickSettings = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalSettings(true))
  }, [dispatch])

  return (
    <IconButton
      icon={IconSetting}
      view="ghost-l"
      onClick={handleClickSettings}
    />
  )
}

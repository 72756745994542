import { FC } from 'react'

import { IconMaxTag } from 'shared/ui/Icon/General/IconMaxTag'
import { Tooltip } from 'shared/ui/Tooltip'

import * as S from './tag-max.styled'

export const TagMax: FC<{ tooltipText: string }> = ({ tooltipText }) => (
  <Tooltip title={tooltipText} topPlacement>
    <S.MaxTagContainer>
      <IconMaxTag />
    </S.MaxTagContainer>
  </Tooltip>
)

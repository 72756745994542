import { LayoutTheme } from 'betweb-openapi-axios'

export const foldersThemesMap = {
  [LayoutTheme.Dark]: 'darkTheme',
  [LayoutTheme.Light]: 'lightTheme'
}

// export function useDynamicImageImport(
//   scope: string,
//   name: `${string}.${string}`
// ) {
//   const themeType = useSelector(selectUserSettingLayoutTheme)
//   const [source, setSource] = useState('')
//
//   useEffect(() => {
//     const importIcon = async () => {
//       try {
//         const module = await import(
//           `assets/images/${scope}/${foldersThemesMap[themeType]}/${name}`
//         )
//         setSource(module.default)
//       } catch {
//         console.error(`Couldn't resolve image ${scope}/${name}`)
//       }
//     }
//     importIcon()
//   }, [name, scope, themeType])
//
//   return source
// }

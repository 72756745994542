import { FC } from 'react'
import { EventStatus } from 'betweb-openapi-axios'

import { useHotProbsMarketRowConfig } from '../../lib'
import { Cell } from '../cell'

import { ProbsProps } from './probs.types'
import * as S from './probs.styled'

export const Probs: FC<ProbsProps> = ({ event, renderRight }) => {
  const isEventSuspended = event.status === EventStatus.Suspended

  const { rowConfig } = useHotProbsMarketRowConfig({
    sportId: event.tournament.sportId
  })

  return (
    <S.ProbsWrapper>
      <S.CellsWrapper>
        {rowConfig?.map((block, blockIndex, blockSelf) => (
          <S.BlockWrapper isMerged={block.isMerged} key={block.id}>
            {block.config.map((item, index, self) => (
              <Cell
                isFirst={
                  block.isMerged ? blockIndex === 0 && index === 0 : true
                }
                isLast={
                  block.isMerged
                    ? blockIndex === blockSelf.length - 1 &&
                      index === self.length - 1
                    : true
                }
                cellConfig={item}
                event={event}
                isEventSuspended={isEventSuspended}
                isMerged={!!block.isMerged}
                key={item.mainOutcomeType}
                outcomeTypesName={item.outcomeTypesName}
              />
            ))}
          </S.BlockWrapper>
        ))}
      </S.CellsWrapper>

      {renderRight}
    </S.ProbsWrapper>
  )
}

import { FC, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectExpressRate,
  selectMaxBetExpressSystem
} from 'astra-core/containers/BasketProvider'
import { getCommonPossibleWinSum } from 'astra-core'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { onValidateBasketInputValue, useBetErrors } from 'entities/basket'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { ExpressBonus } from '../express/express-bonus'
import { InputBetSum } from '../../input-bet-sum'

import * as S from './input-bet-sum.styled'

export const InputBetSumExpress: FC = () => {
  const dispatch = useAppDispatch()
  const { minAmountError, maxAmountError } = useBetErrors()
  const maxExpressBet = useAppSelector(selectMaxBetExpressSystem)
  const expressRate = useAppSelector(selectExpressRate)
  const isBonusWallet = useAppSelector(selectBasketBonusWallet)

  const bonusOnExpressEnabled = useFeatureFlag(
    EFeatureFlags.BONUS_ON_EXPRESS_ENABLED
  )

  const [inputValue, setInputValue] = useState('')

  const winSum = useMemo(
    () =>
      (inputValue !== '' &&
        getCommonPossibleWinSum({
          coefficient: expressRate,
          bet: +inputValue
        })) ||
      0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expressRate, inputValue, isBonusWallet]
  )

  const setOutcomeAmount = useCallback(
    (value: string) => {
      setInputValue(value)
      dispatch(
        basketProviderActions.setExpressAmount({
          amount: onValidateBasketInputValue(value)
        })
      )
    },
    [dispatch]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(basketProviderActions.makeBet())
      }
    },
    [dispatch]
  )

  return (
    <>
      {bonusOnExpressEnabled && <ExpressBonus winSumValue={winSum} />}

      <S.InputBetSumContainer>
        <InputBetSum
          errorMessage={maxAmountError?.message || minAmountError?.message}
          maxValue={maxExpressBet}
          winSum={winSum}
          onChange={setOutcomeAmount}
          onKeyDown={handleKeyDown}
        />
      </S.InputBetSumContainer>
    </>
  )
}

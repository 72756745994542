import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M17.0687 19.438C15.6251 20.4236 13.8799 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C13.9835 3 15.8172 3.64167 17.3047 4.72876C16.1382 4.36768 14.6745 4.07439 12.9964 4.07439C8.61595 4.07439 6.09515 7.92407 6.09515 10.9756C6.09515 14.2017 8.51336 17.5924 12.6363 18.0042L12.6373 18.0043C14.1882 18.1567 15.4011 18.5881 16.2215 18.9776C16.5817 19.1486 16.8657 19.3112 17.0687 19.438ZM18.0956 18.6215C18.0304 18.5745 17.9403 18.512 17.826 18.4383C17.5801 18.2796 17.2216 18.0686 16.7576 17.8484C15.8297 17.4079 14.4774 16.9292 12.7601 16.7603C9.33131 16.4176 7.34515 13.616 7.34515 10.9756C7.34515 8.45866 9.4524 5.32439 12.9964 5.32439C14.9901 5.32439 16.6551 5.77799 17.8209 6.23166C18.4033 6.45828 18.8589 6.68405 19.1658 6.85114C19.3192 6.93464 19.4352 7.00333 19.511 7.04992C19.5148 7.05226 19.5185 7.05454 19.5221 7.05677C20.4564 8.47551 21 10.1743 21 12C21 14.619 19.8813 16.9768 18.0956 18.6215Z"
      fill={theme.tokens.colors['sport-icon']['sport-tennis']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M12.4424 14.6542C11.1716 15.5043 9.64371 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C9.68519 0 11.2487 0.521053 12.5381 1.41084C11.5298 1.11606 10.292 0.885568 8.8857 0.885568C4.94549 0.885568 2.6818 4.34726 2.6818 7.08946C2.6818 9.9896 4.85531 13.0363 8.55871 13.4061L8.55968 13.4062C9.92906 13.5408 10.9994 13.9217 11.7227 14.265C12.025 14.4085 12.2661 14.5452 12.4424 14.6542ZM13.4702 13.8376C13.4106 13.7943 13.3257 13.7351 13.2163 13.6646C12.9955 13.5221 12.6742 13.333 12.2587 13.1358C11.428 12.7415 10.2182 12.3133 8.68245 12.1623C5.67326 11.8615 3.9318 9.40389 3.9318 7.08946C3.9318 4.88185 5.78193 2.13557 8.8857 2.13557C10.6474 2.13557 12.1187 2.53641 13.149 2.93731C13.6635 3.13755 14.0658 3.33693 14.3364 3.48423C14.4716 3.55784 14.5737 3.61828 14.6401 3.6591C14.6733 3.67951 14.6976 3.69499 14.7127 3.70474L14.7283 3.71496L14.7301 3.71618C14.7301 3.71615 14.73 3.71611 14.7442 3.69515C15.5392 4.93801 16 6.41519 16 8C16 10.3026 15.0272 12.378 13.4702 13.8376Z"
      fill={theme.tokens.colors['sport-icon']['sport-tennis']}
      fillRule="evenodd"
    />
  )
}

export const IconSportTennis: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

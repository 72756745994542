import {
  selectSport,
  ESportsCodes
} from 'astra-core/containers/CommonDataProvider'
import { FC } from 'react'
import { getEventTitle } from 'astra-core'

import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { RootState } from 'shared/types/store'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { BasketCardDependentGroup } from 'entities/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { CardDependent } from './card-dependent'
import { CardDependentGroupProps } from './card-dependent.types'

export const CardDependentGroup: FC<CardDependentGroupProps> = ({
  outcomes
}) => {
  const { event } = outcomes[0]

  const { code: sportCode } = useAppSelector(
    (state: RootState) => selectSport(state, event.tournament.sportId)!
  )

  const eventTitle = getEventTitle(event)

  const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
  const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()

  return (
    <BasketCardDependentGroup
      isCommonTooltipTeamsName={!isTeamsOverflowing}
      isCommonTooltipTournamentName={!isNamesOverflowing}
      SportIcon={<SportIcon size="s" sport={sportCode as ESportsCodes} />}
      teamsName={eventTitle}
      teamsNameRef={teamsRef}
      tournamentName={event.tournament.name}
      tournamentNameRef={namesRef}
    >
      {outcomes.map((outcome) => (
        <CardDependent key={outcome.id} outcome={outcome} />
      ))}
    </BasketCardDependentGroup>
  )
}

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M21.9975 5.25222C21.9649 5.82216 21.7835 6.08968 21.2328 6.37895C19.4075 7.33787 17.0137 4.84844 18.453 3.48828C19.6092 2.3957 22.09 3.63034 21.9975 5.25222ZM14.2484 5.82954C14.8096 5.72469 15.1917 5.6533 15.4575 5.56538C16.8501 5.10467 18.0382 5.53457 18.0316 6.82073C18.0275 7.62209 17.7582 7.91685 16.3042 8.71161C14.2487 9.83515 13.4871 10.5333 11.92 11.9698C11.8916 11.9959 11.8628 12.0223 11.8338 12.0489C10.9985 12.8144 10.3604 13.4353 9.81477 14.2602C9.4846 14.7595 8.80842 15.5368 8.32478 16.0928C7.99518 16.4717 7.755 16.7478 7.77465 16.7631C7.78223 16.7689 8.5753 16.319 9.74823 15.6536C11.0791 14.8986 12.8989 13.8662 14.6153 12.9073L14.8575 12.7729C20.5395 9.61922 20.7534 9.50051 20.7963 10.1596C20.8484 10.9582 20.844 10.9607 11.8896 15.9597L11.5353 16.1575C3.41471 20.6834 2.68033 21.0943 2.36463 20.8822C2.33184 20.8602 2.30357 20.8314 2.27201 20.7999C1.70179 20.2296 1.9195 19.9789 3.97895 18.8344L5.72812 17.8623L5.7282 17.5175C5.72836 16.93 6.75467 14.8656 7.93427 13.0802C8.32543 12.4881 8.62489 11.9394 8.93425 11.3726C9.25154 10.7912 9.57923 10.1908 10.0269 9.50485C10.0873 9.41241 10.2072 9.26237 10.3304 9.10821C10.5582 8.82322 10.7973 8.52412 10.692 8.5487C10.0176 8.7061 8.25871 8.97921 8.25871 8.97921C8.25871 8.97921 6.27124 9.25704 6.39143 8.46101C6.48275 7.85615 8.85767 7.03258 11.2808 6.45343C12.6124 6.13516 13.5636 5.95745 14.2484 5.82954Z"
      fill={theme.tokens.colors['sport-icon']['sport-skiJumping']}
      fillRule="evenodd"
    />
  ),
  s: ({ theme }) => (
    <path
      clipRule="evenodd"
      d="M15.998 2.6017C15.9719 3.05766 15.8267 3.27167 15.3862 3.50309C13.926 4.27022 12.0109 2.27868 13.1624 1.19055C14.0874 0.316485 16.072 1.3042 15.998 2.6017ZM9.79864 3.06356C10.2476 2.97968 10.5533 2.92257 10.7659 2.85223C11.88 2.48366 12.8305 2.82759 12.8252 3.85651C12.8219 4.4976 12.6065 4.73341 11.4433 5.36922C9.79889 6.26805 9.18961 6.82658 7.93599 7.97577C7.9132 7.99666 7.89019 8.01775 7.86697 8.03904C7.19879 8.65145 6.68826 9.14819 6.25177 9.80812C5.98763 10.2075 5.44669 10.8294 5.05977 11.2742C4.7961 11.5773 4.60395 11.7981 4.61968 11.8104C4.62574 11.8151 5.2602 11.4551 6.19855 10.9228C7.26322 10.3188 8.7191 9.49286 10.0922 8.72578L10.2859 8.61825C14.8315 6.09531 15.0026 6.00033 15.037 6.52762C15.0787 7.16652 15.0752 7.16849 7.91164 11.1677L7.6282 11.3259C1.13173 14.9466 0.544219 15.2754 0.291655 15.1057C0.265425 15.0881 0.242807 15.0651 0.217558 15.0398C-0.238614 14.5836 -0.0644447 14.383 1.58312 13.4675L2.98245 12.6898L2.98252 12.4139C2.98264 11.944 3.80369 10.2924 4.74737 8.86411C5.0603 8.39037 5.29987 7.95143 5.54736 7.49797C5.80118 7.03291 6.06334 6.55259 6.4215 6.00381C6.46977 5.92985 6.56571 5.80983 6.66429 5.6865C6.84654 5.4585 7.03781 5.21922 6.95355 5.23889C6.41406 5.3648 5.00692 5.58329 5.00692 5.58329C5.00692 5.58329 3.41695 5.80556 3.5131 5.16874C3.58615 4.68485 5.48609 4.02599 7.42457 3.56267C8.48987 3.30805 9.25087 3.16589 9.79864 3.06356Z"
      fill={theme.tokens.colors['sport-icon']['sport-skiJumping']}
      fillRule="evenodd"
    />
  )
}

export const IconSportSkiJumping: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

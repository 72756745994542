import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M14.6208 16.9616C14.3392 16.8865 14.0902 17.0367 13.8411 17.3049C12.6172 18.6568 11.231 19.8263 9.5956 20.6739C8.93495 21.0172 8.24182 21.2854 7.52702 21.4678C7.18045 21.5536 6.92053 21.4678 6.76891 21.2103C6.61728 20.9635 6.64977 20.706 6.87721 20.4378C8.79416 18.1418 10.6678 15.8028 12.379 13.3566C12.9205 12.5841 13.462 11.7901 13.8735 10.9318C13.9385 10.803 13.9818 10.6743 14.036 10.5455C14.0902 10.4275 14.0793 10.3739 13.9277 10.3632C13.5486 10.3417 13.1804 10.4061 12.8122 10.4704C11.9891 10.6314 11.2093 10.9103 10.4079 11.1142C10.1155 11.1893 9.8122 11.2429 9.50896 11.2215C8.98911 11.1786 8.70752 10.7279 8.8808 10.2451C8.99994 9.9018 9.22737 9.63357 9.49813 9.3868C10.8627 8.14222 12.4331 7.26243 14.2743 6.9191C14.9133 6.80108 15.5414 6.82254 16.1696 7.02639C17.1876 7.34826 18.2056 7.64868 19.2129 7.97055C19.5811 8.08857 19.8952 8.03493 20.2092 7.79889C20.8482 7.31608 21.498 6.86545 22.2453 6.53285C22.5161 6.41483 22.7977 6.31827 23.1009 6.31827C23.7182 6.31827 24.0973 6.75816 23.9782 7.35899C23.924 7.65941 23.8049 7.93837 23.6533 8.19587C23.0576 9.26878 22.2995 10.2237 21.4006 11.0605C21.3789 11.082 21.3572 11.0927 21.3464 11.1142C20.8482 11.597 20.2634 11.7472 19.5811 11.6506C18.9963 11.5648 18.4114 11.4683 17.8266 11.3288C17.7183 11.2966 17.6533 11.318 17.6316 11.4468C17.4692 12.2408 17.1551 12.9703 16.6786 13.6248C16.5919 13.7428 16.5919 13.7965 16.7544 13.8501C17.545 14.1076 18.3139 14.4188 18.9854 14.923C19.0504 14.9767 19.1154 15.0303 19.1804 15.084C19.7652 15.5775 19.9818 16.1998 19.8302 16.9401C19.5269 18.4422 18.8446 19.7619 17.8591 20.9314C17.7183 21.0923 17.5667 21.2425 17.3825 21.3498C16.8194 21.6931 16.1912 21.4142 16.0721 20.7704C15.9854 20.2876 16.0937 19.8155 16.1696 19.3434C16.2562 18.7963 16.3753 18.2491 16.3428 17.6804C16.3212 17.3049 16.2562 17.2298 15.8988 17.1118C15.5089 16.983 15.1082 16.9723 14.6317 16.9401L14.6208 16.9616Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
      />
      <path
        clipRule="evenodd"
        d="M18.5414 6.69379C19.2237 6.65087 19.5269 6.46847 19.8085 5.92129C20.6316 4.35483 18.7038 2.02661 17.0576 2.58453C15.0107 3.28192 16.2887 6.82254 18.5414 6.69379Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M2.82669 14.4939C2.51262 14.4939 2.26352 14.2471 2.26352 13.936C2.26352 13.6248 2.51262 13.378 2.82669 13.378H9.61726C9.93134 13.378 10.1804 13.6248 10.1804 13.936C10.1804 14.2471 9.93134 14.4939 9.61726 14.4939H2.82669Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 16.8543C0 17.1654 0.249096 17.4122 0.563173 17.4122H6.21656C6.53064 17.4122 6.77974 17.1654 6.77974 16.8543C6.77974 16.5431 6.53064 16.2964 6.21656 16.2964H0.563173C0.249096 16.2964 0 16.5431 0 16.8543Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.96387 11.1249C3.64979 11.1249 3.4007 10.8782 3.4007 10.567C3.4007 10.2559 3.64979 10.0091 3.96387 10.0091H6.22739C6.54147 10.0091 6.79057 10.2559 6.79057 10.567C6.79057 10.8782 6.54147 11.1249 6.22739 11.1249H3.96387Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M7.37904 12.1782C7.13523 12.1149 6.91955 12.2414 6.70387 12.4673C5.64423 13.6057 4.44392 14.5905 3.02794 15.3043C2.45592 15.5934 1.85577 15.8193 1.23686 15.9729C0.936784 16.0452 0.711727 15.9729 0.580444 15.7561C0.449161 15.5482 0.477292 15.3314 0.674217 15.1055C2.33401 13.172 3.9563 11.2024 5.43793 9.14238C5.9068 8.49186 6.37566 7.82326 6.732 7.10046C6.78827 6.99204 6.82578 6.88362 6.87266 6.7752C6.91955 6.67581 6.91017 6.63064 6.77889 6.6216C6.45068 6.60353 6.13185 6.65774 5.81302 6.71195C5.10034 6.84748 4.42517 7.08239 3.73124 7.25406C3.47805 7.3173 3.21549 7.36248 2.95292 7.34441C2.50281 7.30827 2.25899 6.92879 2.40903 6.52222C2.51218 6.23309 2.70911 6.00722 2.94354 5.79941C4.12509 4.75134 5.48481 4.01047 7.07897 3.72135C7.63223 3.62196 8.17612 3.64003 8.72001 3.8117C9.60148 4.08275 10.483 4.33573 11.3551 4.60678C11.6739 4.70617 11.9458 4.66099 12.2178 4.46222C12.771 4.05564 13.3337 3.67617 13.9807 3.39608C14.2152 3.2967 14.459 3.21538 14.7215 3.21538C15.256 3.21538 15.5842 3.58582 15.4811 4.09178C15.4342 4.34476 15.3311 4.57968 15.1998 4.79652C14.684 5.70002 14.0276 6.50415 13.2493 7.20888C13.2305 7.22695 13.2118 7.23599 13.2024 7.25406C12.771 7.66063 12.2647 7.78712 11.6739 7.70581C11.1675 7.63353 10.6611 7.55221 10.1547 7.43476C10.061 7.40765 10.0047 7.42572 9.98595 7.53414C9.84529 8.20274 9.57335 8.81712 9.16074 9.36826C9.08572 9.46765 9.08572 9.51282 9.22639 9.558C9.91093 9.77484 10.5767 10.0369 11.1581 10.4615C11.2144 10.5067 11.2707 10.5519 11.3269 10.597C11.8333 11.0126 12.0208 11.5367 11.8896 12.1601C11.627 13.425 11.0362 14.5363 10.1829 15.5211C10.061 15.6567 9.92969 15.7832 9.77027 15.8735C9.28265 16.1626 8.73876 15.9277 8.63561 15.3856C8.56059 14.979 8.65437 14.5815 8.72001 14.184C8.79503 13.7232 8.89818 13.2624 8.87005 12.7835C8.85129 12.4673 8.79503 12.404 8.48557 12.3047C8.14799 12.1962 7.80103 12.1872 7.38842 12.1601L7.37904 12.1782Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
      />
      <path
        clipRule="evenodd"
        d="M10.7737 3.53161C11.3644 3.49547 11.627 3.34187 11.8708 2.88108C12.5835 1.56196 10.9143 -0.398643 9.48895 0.0711799C7.71663 0.658459 8.82316 3.64003 10.7737 3.53161Z"
        fill={theme.tokens.colors['sport-icon']['sport-athletics']}
        fillRule="evenodd"
      />
    </>
  )
}

export const IconSportAthletics: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import styled, { css } from 'styled-components'

export const StyledButtonsWrapper = styled.div`
  align-items: center;
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledSearchWrapper = styled.div`
  flex: 1;
`

export const StyledSearchDivider = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.divider['divider-secondary']};
  `}
  height: 16px;
  width: 1px;
`

import React, { FC } from 'react'

import { TableRow } from '../table-row'
import { TableHeader } from '../table-header'

import { TableProps } from './table.types'
import * as S from './table.styled'

export const Table: FC<TableProps> & {
  Row: typeof TableRow
  Header: typeof TableHeader
} = ({ Header, Rows }) => {
  return (
    <S.TableStyled>
      <thead>{Header}</thead>
      <tbody>{Rows}</tbody>
    </S.TableStyled>
  )
}

Table.Row = TableRow
Table.Header = TableHeader

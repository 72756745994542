import { FC } from 'react'

import {
  CellWithoutProbability,
  useGetEventProbability
} from 'entities/event-probability'

import { CellWithProbability } from '../cell-with-probability'

import { CellOriginalComponentProps } from './cell.types'

export const Cell: FC<CellOriginalComponentProps> = ({
  event,
  outcomeTypesName,
  ...cellProps
}) => {
  const { eventProbability } = useGetEventProbability({
    event,
    outcomeTypesName
  })

  return eventProbability ? (
    <CellWithProbability
      cellConfig={cellProps.cellConfig}
      event={{ id: event.id, status: event.status }}
      eventProbability={eventProbability}
      isEventSuspended={cellProps.isEventSuspended}
      isFirst={cellProps.isFirst}
      isLast={cellProps.isLast}
      isMerged={cellProps.isMerged}
    />
  ) : (
    <CellWithoutProbability
      isCompact={cellProps.cellConfig.isCompact}
      isFirst={cellProps.isFirst}
      isLast={cellProps.isLast}
      paramCellWidth={cellProps.cellConfig.paramCellWidth}
      withParam={cellProps.cellConfig.withParam}
    />
  )
}

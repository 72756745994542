import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { Typography } from 'shared/ui/typography'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { Modal } from 'shared/ui/modal'

import { BonusCouponAvailableProps } from './bonus-coupon-can-not-use.types'
import * as S from './bonus-coupon-can-not-use.styled'

export const BonusCouponCanNotUse: FC<BonusCouponAvailableProps> = ({
  onOpenModal,
  value,
  modalRef,
  description
}) => {
  const [t] = useTranslation()

  return (
    <>
      <S.StyledBasketAvailableCouponWrapper>
        <IconTicketNo />

        <S.StyledBonusText>
          <Trans
            components={{
              bold: <Typography color="text-primary-2" font="Body / 12 Bold" />
            }}
            i18nKey="coupon for sum"
            values={{ sum: value }}
          />
        </S.StyledBonusText>

        <S.StyledAvailableCouponInfo onClick={onOpenModal}>
          <IconInfoCircleFilled colorToken="icon-primary-2" />

          <Typography color="text-primary-2" font="Body / 12 Bold">
            {t('conditions')}
          </Typography>
        </S.StyledAvailableCouponInfo>
      </S.StyledBasketAvailableCouponWrapper>

      <Modal ref={modalRef} title={t('conditions for receiving a bonus')}>
        <S.StyledCouponModalInner>
          <Typography
            dangerouslySetInnerHTML={{
              __html: description
            }}
            color="text-primary-2"
            font="Body / 14 Medium"
          />
        </S.StyledCouponModalInner>
      </Modal>
    </>
  )
}

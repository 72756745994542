import { FC, Fragment, memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { Tooltip } from 'shared/ui/Tooltip'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { useHotProbsMarketHeaderConfig } from 'features/events-table'
import { Typography } from 'shared/ui/typography'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { EventTableHeaderProps } from './event-table-header.types'
import * as S from './event-table-header.styled'

export const EventTableHeader: FC<EventTableHeaderProps> = memo(
  ({
    withIcon,
    backLinkBuilder,
    hideOutcomeCodes,
    isHideHeader,
    tournamentSportId,
    tournamentName
  }) => {
    const { t } = useTranslation()

    const sport = useAppSelector((state) =>
      selectSport(state, tournamentSportId)
    )
    const [nameRef, isNameOverflowing] = useTextOverflow<HTMLDivElement>()
    const config = useAppSelector(selectConfig)

    const { headerConfig } = useHotProbsMarketHeaderConfig({
      sportId: tournamentSportId
    })

    return (
      <S.HeaderWrapper hideHeader={config.IS_IFRAME || !!isHideHeader}>
        <S.StyledTitleCell colSpan={3}>
          <S.MainInfoWrapper>
            <S.MainInfoIconsBlock>
              {backLinkBuilder && (
                <S.BackButton
                  to={backLinkBuilder(tournamentSportId, sport.genericSportId)}
                >
                  <IconChevronLeft colorToken="icon-secondary-2" />
                </S.BackButton>
              )}
              {withIcon && sport?.code && (
                <S.SportIconWrapper>
                  <SportIcon size="s" sport={sport?.code as ESportsCodes} />
                </S.SportIconWrapper>
              )}
            </S.MainInfoIconsBlock>
            <Tooltip
              isCommon={!isNameOverflowing}
              placement="left"
              title={tournamentName}
            >
              <Typography
                color="text-secondary-2"
                font="Body / 12 Medium"
                lineClamp={1}
                ref={nameRef}
              >
                {tournamentName}
              </Typography>
            </Tooltip>
          </S.MainInfoWrapper>
        </S.StyledTitleCell>

        <S.StyledBlocksCell colSpan={2}>
          <S.StyledBlocksWrapper>
            {headerConfig?.map((block) => (
              <S.BlockWrapper isMerged={block.isMerged} key={block.id}>
                {block.config.map((item) => (
                  <Fragment key={item.localeKey || item.label}>
                    {!hideOutcomeCodes ? (
                      <S.HeaderCell customWidth={item.customWidth}>
                        {item.localeKey ? t(item.localeKey) : item.label}
                      </S.HeaderCell>
                    ) : (
                      <S.HeaderCell customWidth={item.customWidth} />
                    )}
                  </Fragment>
                ))}
              </S.BlockWrapper>
            ))}
          </S.StyledBlocksWrapper>
        </S.StyledBlocksCell>
      </S.HeaderWrapper>
    )
  }
)

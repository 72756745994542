import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconBankHalyk: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 52 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.62047 12.9029C6.37946 12.9029 6.13528 12.8109 5.95135 12.627L4.66702 11.3426H2.84993C2.32668 11.3426 1.90492 10.9177 1.90492 10.3976V8.58054L0.617415 7.29304C0.249558 6.92518 0.249558 6.32266 0.617415 5.9548L1.90175 4.67047V2.85338C1.90175 2.33014 2.32668 1.90837 2.84676 1.90837H4.66385L5.94818 0.624038C6.31603 0.25618 6.91856 0.25618 7.28642 0.624038L8.57075 1.90837H10.3878C10.9111 1.90837 11.3328 2.33331 11.3328 2.85338V4.67047L12.6172 5.9548C12.985 6.32266 12.985 6.92518 12.6172 7.29304L11.3328 8.57737V10.3945C11.3328 10.9177 10.9079 11.3395 10.3878 11.3395H8.57709L7.29276 12.6238C7.10566 12.8109 6.86465 12.9029 6.62047 12.9029Z"
        fill="#F8AE00"
      />
      <path
        d="M6.16732 1.58804L1.58813 6.16723C1.33761 6.41776 1.33761 6.82684 1.58813 7.08053L2.7139 8.2063C3.07859 7.76234 3.63989 7.24543 4.45488 6.86172C6.19904 6.04355 7.25504 6.78561 8.33641 5.5108C9.01188 4.71483 8.85649 3.85544 8.67256 3.35756C8.59962 3.16729 8.48546 2.99605 8.34276 2.85017L7.08062 1.58804C6.82693 1.33752 6.41785 1.33752 6.16732 1.58804Z"
        fill="#008669"
      />
      <path
        d="M7.07725 11.6566L11.6564 7.0774C11.907 6.82688 11.907 6.4178 11.6564 6.1641L10.5307 5.03833C10.166 5.4823 9.60468 5.9992 8.78969 6.38291C7.04554 7.20108 5.98953 6.45902 4.90816 7.73384C4.2327 8.5298 4.38808 9.3892 4.57201 9.88707C4.64495 10.0773 4.75911 10.2486 4.90182 10.3945L6.16395 11.6566C6.41764 11.9071 6.82673 11.9071 7.07725 11.6566Z"
        fill="#008669"
      />
      <path
        d="M12.9852 5.99592L11.5645 4.57523V2.5647C11.5645 2.07633 11.1681 1.67994 10.6798 1.67994H8.66923L7.24854 0.259244C6.90288 -0.0864148 6.34158 -0.0864148 5.99592 0.259244L4.57523 1.67994H2.5647C2.07633 1.67994 1.67994 2.07633 1.67994 2.5647V4.57523L0.259244 5.99592C-0.0864148 6.34158 -0.0864148 6.90288 0.259244 7.24854L1.67994 8.66923V10.6798C1.67994 11.1681 2.07633 11.5645 2.5647 11.5645H4.57523L5.99592 12.9852C6.34158 13.3309 6.90288 13.3309 7.24854 12.9852L8.66923 11.5645H10.6798C11.1681 11.5645 11.5645 11.1681 11.5645 10.6798V8.66923L12.9852 7.24854C13.3309 6.90288 13.3309 6.34158 12.9852 5.99592ZM12.3954 7.06144L11.0159 8.4409V10.3944C11.0159 10.7368 10.7368 11.0159 10.3944 11.0159H8.4409L7.06144 12.3954C6.81726 12.6396 6.42403 12.6396 6.18302 12.3954L4.80355 11.0159H2.8501C2.50762 11.0159 2.22855 10.7368 2.22855 10.3944V8.4409L0.849085 7.06144C0.604904 6.81726 0.604904 6.42403 0.849085 6.18302L2.22855 4.80355V2.8501C2.22855 2.50762 2.50762 2.22855 2.8501 2.22855H4.80355L6.18302 0.849085C6.4272 0.604904 6.82043 0.604904 7.06144 0.849085L8.4409 2.22855H10.3944C10.7368 2.22855 11.0159 2.50762 11.0159 2.8501V4.80355L12.3954 6.18302C12.6396 6.4272 12.6396 6.82043 12.3954 7.06144Z"
        fill="#008669"
      />
      <path
        d="M45.0525 11.5899H46.6698V8.00643L49.5714 11.5899H51.5312L48.2395 7.50221L51.268 4.38811H49.3748L46.6729 7.16924V1.65771H45.0556V11.5899H45.0525ZM36.8042 4.38811L39.5505 11.2981L39.4807 11.4884C39.1763 12.297 38.6847 12.4683 38.282 12.4683C38.1329 12.4683 37.9554 12.4556 37.8127 12.4112V13.8731C37.9554 13.9302 38.2598 14 38.6372 14C39.9627 14 40.616 13.1216 41.1773 11.6723L43.9933 4.38493H42.2777L40.4416 9.44932L38.5389 4.38493H36.8042V4.38811ZM33.8836 9.53177C33.8836 10.9081 34.5019 11.7167 35.8275 11.7167C36.2049 11.7167 36.4934 11.6469 36.6361 11.5899V10.1216C36.5156 10.1565 36.3951 10.185 36.2397 10.185C35.799 10.185 35.5104 9.89328 35.5104 9.3193V1.65771H33.8867V9.53177H33.8836ZM26.7928 7.9874C26.7928 6.56037 27.7727 5.7866 28.7779 5.7866C29.7864 5.7866 30.7631 6.56037 30.7631 7.9874C30.7631 9.41443 29.7832 10.1882 28.7779 10.1882C27.7695 10.185 26.7928 9.41126 26.7928 7.9874ZM25.1089 7.9874C25.1089 10.3721 26.7706 11.7199 28.4862 11.7199C29.6564 11.7199 30.4079 11.111 30.7441 10.6068V11.593H32.3677V4.38811H30.7441V5.368C30.4111 4.87013 29.6595 4.26126 28.4862 4.26126C26.7706 4.26126 25.1089 5.59633 25.1089 7.9874ZM15.8807 11.5899H17.5551V7.25486H22.258V11.5899H23.9324V1.65771H22.258V5.72317H17.5551V1.65771H15.8807V11.5899Z"
        fill="#008669"
      />
    </svg>
  </Icon>
)

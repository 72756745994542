import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <path
      d="M7.95028 14.016C6.80168 10.5926 6.54294 7.33687 7.19655 4.37475C6.02545 5.11838 5.0006 6.14325 4.24574 7.41562C2.71464 10.029 2.63589 13.0923 3.78449 15.6608L3.88574 15.6383C5.49558 15.1882 6.84668 14.6134 7.96153 14.0385C7.95028 14.0284 7.95028 14.0283 7.95028 14.016ZM11.4973 3C10.4612 3.05625 9.46 3.28125 8.51389 3.68737L8.42389 4.0485C7.67016 6.92063 7.86141 10.0864 8.96388 13.476C10.1012 12.7887 10.8775 12.1586 11.3173 11.8088C11.3511 11.7863 11.3623 11.775 11.3961 11.7525C11.3848 11.7075 11.3736 11.6625 11.3623 11.6063C11.1148 10.389 10.4612 7.19063 11.4973 3ZM12.2522 12.6874C12.1959 12.6649 12.1509 12.6536 12.0947 12.6311C12.0722 12.6536 12.0609 12.6649 12.0272 12.6874C11.0698 13.4535 8.57014 15.4808 4.32561 16.6868C4.8206 17.5092 5.46296 18.264 6.22794 18.9053L6.63293 18.7928C9.56012 17.9817 12.386 16.2592 14.852 13.8146C13.7045 13.2172 12.7809 12.8787 12.2522 12.6874ZM16.5754 4.2285C15.3369 3.49612 13.997 3.10125 12.6572 3L12.6234 3.12375C12.2297 4.656 12.0834 6.04088 12.0609 7.24688C16.4742 8.29425 19.3114 10.209 21 11.7638C20.9325 8.75663 19.3451 5.86088 16.5754 4.2285ZM19.3001 17.0805C18.1066 15.9093 16.9354 15.042 15.8667 14.3884C13.3557 16.968 10.4398 18.804 7.40017 19.7277C7.41142 19.7389 7.42267 19.7388 7.43392 19.7501C8.86263 20.595 10.4387 21 11.9934 21C14.8531 21 17.6453 19.6377 19.3789 17.1593L19.3001 17.0805ZM20.6063 12.9349C19.1201 11.4589 16.4292 9.5115 12.0722 8.418C12.1397 9.78038 12.3534 10.8176 12.4659 11.3801C12.4884 11.4589 12.4997 11.5151 12.5109 11.5826C12.5447 11.5939 12.5897 11.6051 12.6347 11.6276C13.7945 12.045 16.8454 13.1374 19.9751 16.167C20.4701 15.2322 20.7863 14.241 20.9213 13.2499L20.6063 12.9349Z"
      fill={theme.tokens.colors['sport-icon']['sport-volleyball']}
    />
  ),
  s: ({ theme }) => (
    <path
      d="M4.40025 9.792C3.37927 6.749 3.14928 3.855 3.73026 1.222C2.68929 1.883 1.77831 2.794 1.10732 3.925C-0.253651 6.248 -0.323649 8.97096 0.697328 11.254L0.787326 11.234C2.2183 10.834 3.41927 10.323 4.41025 9.812C4.40025 9.80304 4.40025 9.80296 4.40025 9.792ZM7.55318 0C6.6322 0.05 5.74222 0.25 4.90124 0.611L4.82124 0.932C4.15125 3.485 4.32125 6.299 5.30123 9.312C6.31221 8.70104 7.00219 8.14096 7.39318 7.83C7.42318 7.81 7.43318 7.8 7.46318 7.78C7.45318 7.74 7.44318 7.7 7.43318 7.65C7.21319 6.568 6.6322 3.725 7.55318 0ZM8.22417 8.61104C8.17417 8.59104 8.13417 8.58096 8.08418 8.56096C8.06418 8.58096 8.05418 8.59104 8.02418 8.61104C7.17319 9.292 4.95124 11.094 1.17832 12.166C1.61831 12.897 2.1893 13.568 2.86928 14.138L3.22927 14.038C5.83122 13.317 8.34313 11.786 10.5351 9.61296C9.5151 9.082 8.69416 8.78104 8.22417 8.61104ZM12.067 1.092C10.9661 0.441 9.7751 0.09 8.58416 0L8.55417 0.11C8.20417 1.472 8.07418 2.703 8.05418 3.775C11.977 4.706 14.499 6.408 16 7.79C15.94 5.117 14.529 2.543 12.067 1.092ZM14.489 12.516C13.4281 11.475 12.387 10.704 11.4371 10.123C9.20511 12.416 6.6132 14.048 3.91126 14.869C3.92126 14.879 3.93126 14.879 3.94126 14.889C5.21123 15.64 6.6122 16 7.99417 16C10.5361 16 13.0181 14.789 14.559 12.586L14.489 12.516ZM15.65 8.83104C14.329 7.519 11.937 5.788 8.06418 4.816C8.12417 6.027 8.31417 6.949 8.41417 7.449C8.43417 7.519 8.44417 7.569 8.45417 7.629C8.48417 7.639 8.52417 7.649 8.56417 7.669C9.5951 8.04 12.307 9.01104 15.089 11.704C15.529 10.873 15.81 9.992 15.93 9.11104L15.65 8.83104Z"
      fill={theme.tokens.colors['sport-icon']['sport-volleyball']}
    />
  )
}

export const IconSportVolleyball: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

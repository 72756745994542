import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const EventsPageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const TitleWrapper = styled.h2`
  ${typography['Title / 20 Bold']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

import React, { FC } from 'react'

import { SportIconWrapper } from '../sport-icon-wrapper/sport-icon-wrapper'
import { IIconsSportNameProps, IconSizeMap } from '../sport-icon.types'

const iconsSizeMap: IconSizeMap = {
  m: ({ theme }) => (
    <>
      <path
        d="M18.2082 5.66733L16.754 4.18352L15.1925 2.632H8.80915L5.83391 5.66733H10.4876V19.0696L12.0212 20.5937L13.5545 19.0696V5.66733H18.2082Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M15.2368 7.11758V13.0975L13.7073 14.6163L15.8721 16.729L18.2129 14.4028L18.2135 7.11758H15.2368ZM8.80592 13.0975V7.11758H5.82888V14.4028L8.17059 16.729L10.3348 14.6163L8.80592 13.0975Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M8.57972 1.81496L4.81254 5.59915V14.7719L12.001 21.8615L19.1893 14.7719V5.59942L15.4223 1.81496H8.57972ZM4.12114 5.14268L8.12785 1.11995L8.24637 1H15.7551L15.8736 1.11995L19.8806 5.14268L20.0009 5.26374V15.1106L19.8791 15.2304L12.0011 23L11.7172 22.7205L4.12295 15.2305L4.00085 15.1107V5.26381L4.12114 5.14268Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  ),
  s: ({ theme }) => (
    <>
      <path
        d="M12.5153 3.39451L11.4576 2.31539L10.3221 1.18702H5.67965L3.51586 3.39451H6.90032V13.1415L8.01566 14.25L9.13078 13.1415V3.39451H12.5153Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M10.3542 4.44923V8.7982L9.24187 9.90279L10.8163 11.4393L12.5187 9.74751L12.5191 4.44923H10.3542ZM5.6773 8.7982V4.44923H3.5122V9.74751L5.21525 11.4393L6.78921 9.90279L5.6773 8.7982Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
      <path
        d="M5.51279 0.592812L2.77305 3.34492V10.016L8.001 15.1719L13.2287 10.016V3.34512L10.4891 0.592812H5.51279ZM2.27022 3.01295L5.18416 0.0873572L5.27035 0.00012207H10.7312L10.8174 0.0873572L13.7315 3.01295L13.819 3.10099V10.2623L13.7304 10.3494L8.00101 16L7.79454 15.7967L2.27153 10.3495L2.18274 10.2623V3.10104L2.27022 3.01295Z"
        fill={theme.tokens.colors['sport-icon']['sport-global']}
      />
    </>
  )
}

export const IconCybersportWorldOfTanks: FC<IIconsSportNameProps> = ({
  sport,
  ...props
}) => {
  return (
    <SportIconWrapper sport={sport} {...props}>
      {({ theme }) => React.createElement(iconsSizeMap[props.size], { theme })}
    </SportIconWrapper>
  )
}

import { FC } from 'react'

import { IconProps } from 'shared/ui/Icon'
import { IconPaymentSim } from 'shared/ui/Icon/General/icon-payment-sim'
import { IconPaymentCard } from 'shared/ui/Icon/General/icon-payment-card'
import { IconPaymentCash } from 'shared/ui/Icon/General/icon-payment-cash'

import { PaymentMethods } from '../store'

export const PAYMENT_METHODS: {
  paymentMethod: PaymentMethods
  iconActiveToken: IconProps['colorToken']
  title: string
  Icon: FC<IconProps>
}[] = [
  {
    paymentMethod: PaymentMethods.CARD,
    iconActiveToken: 'icon-accent',
    title: 'payment.tab.bank_card',
    Icon: IconPaymentCard
  },
  {
    paymentMethod: PaymentMethods.SIM,
    iconActiveToken: 'icon-warning',
    title: 'payment.tab.sim',
    Icon: IconPaymentSim
  },
  {
    paymentMethod: PaymentMethods.CASH,
    iconActiveToken: 'icon-positive',
    title: 'payment.tab.cash',
    Icon: IconPaymentCash
  }
]

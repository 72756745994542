import { FC, useMemo } from 'react'
import { Tournament } from 'betweb-openapi-axios'

import { getTournamentLink } from 'shared/lib/events'
import {
  ETagsTop,
  entityTagsColorsSchema,
  selectEntityTags,
  sortByEntityTags,
  useGetEntityTagName
} from 'entities/event'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledTournament,
  StyledTournamentAdditional,
  StyledTournamentLeft,
  StyledTournamentText
} from './tournaments-list.styled'
import {
  LineTournamentProps,
  TournamentsListProps
} from './tournaments-list.types'

// TODO remove and return old logic
const highPositionTournaments = [
  29448, 29572, 29579, 29580, 27891, 25307, 29233, 29577, 29634, 29635, 29636,
  29412, 25410
]

const byPriorityPosition = (a: Tournament, b: Tournament): number => {
  if (
    highPositionTournaments.includes(a.id) &&
    !highPositionTournaments.includes(b.id)
  ) {
    return -1
  }
  if (
    !highPositionTournaments.includes(a.id) &&
    highPositionTournaments.includes(b.id)
  ) {
    return 1
  }
  if (
    !highPositionTournaments.includes(a.id) &&
    !highPositionTournaments.includes(b.id)
  ) {
    return 0
  }
  return (
    highPositionTournaments.indexOf(a.id) -
    highPositionTournaments.indexOf(b.id)
  )
}

export const TournamentsList: FC<TournamentsListProps> = ({ tournaments }) => {
  const entityTags = useAppSelector(selectEntityTags)

  const sortByPriority = tournaments.sort(byPriorityPosition)
  const sortedTournaments = useMemo(
    () => sortByEntityTags(sortByPriority, entityTags),
    [sortByPriority, entityTags]
  )

  return (
    <>
      {sortedTournaments.map((tournament) => (
        <TournamentRow key={tournament.id} tournament={tournament} />
      ))}
    </>
  )
}

export const TournamentRow: FC<LineTournamentProps> = ({ tournament }) => {
  const tournamentLink = getTournamentLink(tournament)

  const entityTagName = useGetEntityTagName({ tags: tournament.tags })

  return (
    <StyledTournament
      bgImageUrl={
        entityTagName === ETagsTop.SUPERTOP
          ? '/images/shapes/SUPERTOP_tag_bg.svg'
          : ''
      }
      entityTagColor={
        entityTagName ? entityTagsColorsSchema[entityTagName] : ''
      }
      to={tournamentLink}
    >
      <StyledTournamentLeft>
        <StyledTournamentText>{tournament.name}</StyledTournamentText>
      </StyledTournamentLeft>
      <StyledTournamentAdditional>
        {tournament.lineEventCount}
      </StyledTournamentAdditional>
    </StyledTournament>
  )
}

import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { typography } from 'shared/lib/theme/typography'

export const StyledCellEventCashoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
`

export const StyledCellEventCashoutText = styled(Button)`
  align-items: center;
  display: flex;
  gap: 2px;
  height: 24px;
  justify-content: center;
  padding: 4px 6px;
  text-transform: uppercase;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      color: ${theme.tokens.colors.text['text-secondary-3']};

      ${typography['Hint / 10 BOLD']}
    `}
`

export const CurrentCashoutModalWholeBackground = styled.div`
  cursor: default;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
`

import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconGooglePay: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 39 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M18.3407 12.274H16.9185V1.20728H20.6888C21.6444 1.20728 22.4592 1.5258 23.1259 2.16283C23.8074 2.79987 24.1481 3.57765 24.1481 4.49617C24.1481 5.43691 23.8074 6.21469 23.1259 6.84432C22.4666 7.47395 21.6518 7.78506 20.6888 7.78506H18.3407V12.274ZM18.3407 2.57765V6.4295H20.7185C21.2814 6.4295 21.7555 6.23691 22.1259 5.85913C22.5037 5.48136 22.6962 5.02209 22.6962 4.50358C22.6962 3.99246 22.5037 3.54061 22.1259 3.16284C21.7555 2.77024 21.2888 2.57765 20.7185 2.57765H18.3407Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M27.8666 4.45174C28.9185 4.45174 29.7481 4.73322 30.3555 5.29618C30.9629 5.85915 31.2666 6.62952 31.2666 7.6073V12.274H29.9111V11.2221H29.8518C29.2666 12.0888 28.4814 12.5184 27.5036 12.5184C26.6666 12.5184 25.9703 12.274 25.4074 11.7777C24.8444 11.2814 24.5629 10.6666 24.5629 9.92582C24.5629 9.14063 24.8592 8.51841 25.4518 8.05915C26.0444 7.59248 26.837 7.36285 27.8222 7.36285C28.6666 7.36285 29.3629 7.51841 29.9037 7.82952V7.50359C29.9037 7.0073 29.7111 6.59248 29.3185 6.24433C28.9259 5.89618 28.4666 5.72581 27.9407 5.72581C27.1481 5.72581 26.5185 6.05915 26.0592 6.73322L24.8073 5.94803C25.4962 4.94803 26.5185 4.45174 27.8666 4.45174ZM26.0296 9.94804C26.0296 10.3184 26.1851 10.6295 26.5036 10.874C26.8148 11.1184 27.1851 11.2443 27.6074 11.2443C28.2074 11.2443 28.7407 11.0221 29.2074 10.5777C29.674 10.1332 29.9111 9.61471 29.9111 9.01471C29.4666 8.66656 28.8518 8.48878 28.0592 8.48878C27.4814 8.48878 26.9999 8.62952 26.6148 8.9036C26.2222 9.19248 26.0296 9.54063 26.0296 9.94804Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M34.2592 15.5999L39 4.69616H37.4518L35.2592 10.1332H35.2296L32.9777 4.69616H31.4296L34.5555 11.7851L32.7925 15.5999H34.2592Z"
        fill="currentColor"
      />
      <path
        d="M12.6838 6.88876C12.6838 6.42506 12.6423 5.98135 12.5652 5.55469H6.60376V7.99914L10.0371 7.99988C9.89784 8.81321 9.44969 9.50654 8.76302 9.96877V11.5547H10.8067C12.0001 10.4502 12.6838 8.81766 12.6838 6.88876Z"
        fill="#4285F4"
      />
      <path
        d="M8.76372 9.96888C8.19483 10.3526 7.46224 10.577 6.6052 10.577C4.94964 10.577 3.5452 9.46147 3.04223 7.95776H0.934082V9.59332C1.97853 11.6659 4.1252 13.0881 6.6052 13.0881C8.31928 13.0881 9.75928 12.5244 10.8074 11.5541L8.76372 9.96888Z"
        fill="#34A853"
      />
      <path
        d="M2.84372 6.74441C2.84372 6.32219 2.91409 5.91404 3.04224 5.53033V3.89478H0.934092C0.50224 4.75181 0.259277 5.71922 0.259277 6.74441C0.259277 7.76959 0.50298 8.737 0.934092 9.59404L3.04224 7.95849C2.91409 7.57478 2.84372 7.16663 2.84372 6.74441Z"
        fill="#FABB05"
      />
      <path
        d="M6.6052 2.91102C7.54076 2.91102 8.37853 3.23324 9.04002 3.86287L10.8511 2.05324C9.75113 1.02879 8.31705 0.399902 6.6052 0.399902C4.12594 0.399902 1.97853 1.82213 0.934082 3.89472L3.04223 5.53028C3.5452 4.02657 4.94964 2.91102 6.6052 2.91102Z"
        fill="#E94235"
      />
    </svg>
  </Icon>
)

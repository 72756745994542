import styled from 'styled-components'

export const ScrollableBase = styled.div`
  -ms-overflow-style: none;
  min-height: 100%;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  overflow: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
